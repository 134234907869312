import React from "react"
import {
  useVideo,
  selectIsPeerVideoEnabled,
  useHMSStore,
  selectDominantSpeaker,
  selectIsPeerAudioEnabled,
  selectConnectionQualityByPeerID,
} from "@100mslive/react-sdk"
import wifiNone from "../../assets/images/wifi-no-signal.webp"
import wifi1 from "../../assets/images/wifi-1-bar.webp"
import wifi2 from "../../assets/images/wifi-2-bar.webp"
import wifi3 from "../../assets/images/wifi-3-bar.webp"
import wifi4 from "../../assets/images/wifi-4-bar.webp"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { SvgMic, SvgMicOff } from "../icons"

interface Props {
  peer: any
  peerType: string
  peerCount: number
  currentPage: number | null
}

const Peer: React.FC<Props> = ({ peer, peerType, peerCount, currentPage }) => {
  const [qualityScore, setQualityScore] = React.useState<number>(-1)
  const { videoRef } = useVideo({
    trackId: peer.videoTrack,
  })
  const { mobileView, fullScreen } = React.useContext(SessionContext)
  const videoOn = useHMSStore(selectIsPeerVideoEnabled(peer.id))
  const audioOn = useHMSStore(selectIsPeerAudioEnabled(peer.id))
  const totalPages = Math.ceil(peerCount / 6)
  const totalMobilePages = Math.ceil(peerCount / 4)
  const dominantSpeaker = useHMSStore(selectDominantSpeaker)
  const downlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(peer.id)
  )?.downlinkQuality

  React.useEffect(() => {
    if (downlinkQuality) {
      setQualityScore(downlinkQuality)
    }
  }, [downlinkQuality])

  const isHost = peer.roleName === "host"
  const isDominantSpeaker = dominantSpeaker && peer.id === dominantSpeaker.id

  const getGalleryPeerDimensions = () => {
    let height
    let width
    let currentPagePeerCount
    let currentMobilePagePeerCount
    if (mobileView) {
      if (currentPage === totalMobilePages) {
        currentMobilePagePeerCount = peerCount % 4
      } else {
        currentMobilePagePeerCount = peerCount >= 4 ? 4 : peerCount
      }
      if ([2, 3, 4].includes(currentMobilePagePeerCount)) {
        height = "h-[284px]"
        width = "w-[160px]"
      } else if (currentMobilePagePeerCount === 1) {
        height = "h-[569px]"
        width = "w-[320px]"
      } else {
        height = "h-[284px]"
        width = "w-[160px]"
      }
    } else {
      if (currentPage === totalPages) {
        currentPagePeerCount = peerCount % 6
      } else {
        currentPagePeerCount = peerCount >= 6 ? 6 : peerCount
      }
      if (currentPagePeerCount % 6 === 0) {
        if (fullScreen) {
          height = "h-[190.13px]"
          width = "w-[338px]"
        } else {
          height = "h-[168.75px]"
          width = "w-[300px]"
        }
      } else if (currentPagePeerCount % 5 === 0) {
        height = "h-[190.13px]"
        width = "w-[338px]"
      } else if (
        currentPagePeerCount % 4 === 0 ||
        currentPagePeerCount % 3 === 0
      ) {
        if (fullScreen) {
          height = "h-[225px]"
          width = "w-[400px]"
        } else {
          height = "h-[213.75px]"
          width = "w-[380px]"
        }
      } else if (currentPagePeerCount % 2 === 0) {
        height = "h-[281.25px]"
        width = "w-[500px]"
      } else if (currentPagePeerCount % 1 === 0) {
        height = "h-[281.25px]"
        width = "w-[500px]"
      }
    }

    return `${height} ${width}`
  }

  const getQualityScoreImg = () => {
    if (qualityScore === 0) {
      return (
        <img
          src={wifiNone}
          alt="no signal"
          className="max-w-[60%] max-h-[50%]"
        />
      )
    } else if (qualityScore === 1) {
      return (
        <img src={wifi1} alt="no signal" className="max-w-[60%] max-h-[50%]" />
      )
    } else if (qualityScore === 2) {
      return (
        <img
          src={wifi2}
          alt="minimal signal"
          className="max-w-[60%] max-h-[50%]"
        />
      )
    } else if (qualityScore === 3) {
      return (
        <img
          src={wifi3}
          alt="decent signal"
          className="max-w-[60%] max-h-[50%]"
        />
      )
    } else if ([4, 5].includes(qualityScore)) {
      return (
        <img
          src={wifi4}
          alt="good signal"
          className="max-w-[60%] max-h-[50%]"
        />
      )
    }
  }

  return (
    <>
      {peerType === "full-screen" && (
        <div className={`absolute w-full h-full bg-black`}>
          {videoOn ? (
            <>
              <video
                className="-scale-x-100 w-full h-full -scale-x-100"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <div
                className={`absolute font-bold bg-blur-md left-[50%] -translate-x-[50%] flex gap-[10px]
                                text-white px-[12px] py-[8px] rounded-[10px] bg-black/[0.64] z-[5] items-center
                                justify-center ${
                                  mobileView
                                    ? "w-[80%] top-[90px]"
                                    : "top-[40px]"
                                }`}
              >
                {peer.name} {isHost ? "(Host)" : ""}
                {!audioOn ? (
                  <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                    <SvgMicOff fill="#CF2E2E" className="w-[16px] h-[16px]" />
                  </div>
                ) : (
                  <div className="w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                    <SvgMic fill="white" className="w-[20px] h-[20px]" />
                  </div>
                )}
                {videoOn && qualityScore > -1 && (
                  <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full text-black text-[16px]">
                    {getQualityScoreImg()}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white">
              {peer.name} {isHost ? "(Host)" : ""}
            </div>
          )}
        </div>
      )}
      {peerType === "double-self" && (
        <div
          className={`bg-black z-[51] rounded-[10px]
                    ${videoOn ? "" : "border border-1 border-white"}
                    ${
                      mobileView
                        ? "left-[24px] bottom-[135px] w-[112.5px] h-[200px] fixed flex justify-center border border-1 border-gray"
                        : "left-[24px] bottom-[104px] w-[300px] h-[160px] absolute"
                    }`}
        >
          {videoOn ? (
            <>
              <video
                className="-scale-x-100 rounded-[10px]"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <div
                className="absolute bottom-[4px] left-[4px] bg-black/[0.64]
                                text-white px-[4px] py-[2px] rounded-[6px] font-bold bg-blur-md"
              >
                {`${peer.name.slice(0, 20)}${
                  peer.name.length >= 20 ? "..." : ""
                }`}{" "}
                {isHost ? "(Host)" : ""}
              </div>
            </>
          ) : (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white w-full text-center">
              {`${peer.name.slice(0, 24)}${
                peer.name.length >= 24 ? "..." : ""
              }`}{" "}
              {isHost ? "(Host)" : ""}
            </div>
          )}
          <div className="flex items-center gap- absolute bottom-[4px] right-[4px] gap-[4px]">
            {!audioOn ? (
              <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full">
                <SvgMicOff fill="#CF2E2E" className="w-[16px] h-[16px]" />
              </div>
            ) : (
              <div className="w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                <SvgMic fill="white" className="w-[20px] h-[20px]" />
              </div>
            )}
            {videoOn && qualityScore > -1 && (
              <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full text-black text-[16px]">
                {getQualityScoreImg()}
              </div>
            )}
          </div>
        </div>
      )}
      {peerType === "speaker" && (
        <div
          className={`absolute ${!videoOn ? "bg-black" : ""}
                    ${
                      mobileView
                        ? "top-0 left-0 w-full h-full"
                        : fullScreen
                        ? "left-[50%] bottom-0 -translate-x-[50%] w-[90%] h-[85%]"
                        : "left-[50%] bottom-0 -translate-x-[50%] w-[920px] h-[511px]"
                    } `}
        >
          {videoOn ? (
            <>
              <video
                className="-scale-x-100 w-full h-full"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <div
                className={`absolute left-[50%] -translate-x-[50%] font-bold bg-blur-md
                                text-white px-[12px] py-[8px] rounded-[6px] bg-black/[0.64] z-[5]
                                ${
                                  mobileView
                                    ? "top-[90px] w-[80%]"
                                    : "top-[10px]"
                                } flex items-center justify-center gap-[10px]`}
              >
                {peer.name} {isHost ? "(Host)" : ""}
                {!audioOn ? (
                  <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full">
                    <SvgMicOff fill="#CF2E2E" className="w-[16px] h-[16px]" />
                  </div>
                ) : (
                  <div className="w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                    <SvgMic className="w-[20px] h-[20px]" fill="white" />
                  </div>
                )}
                {videoOn && qualityScore > -1 && (
                  <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full text-black text-[16px]">
                    {getQualityScoreImg()}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white">
              {peer.name} {isHost ? "(Host)" : ""}
            </div>
          )}
        </div>
      )}
      {peerType === "non-speaker" && (
        <div
          className={`${fullScreen ? "w-[142px]" : "w-[110px]"} relative
                    h-full bg-black rounded-[10px] flex justify-center`}
        >
          {videoOn ? (
            <>
              <video
                className="-scale-x-100 rounded-[10px] max-h-full w-full"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <div
                className="absolute bottom-[1px] left-[50%] -translate-x-[50%] w-[98%] font-bold bg-blur-md
                                text-white text-base py-[2px] px-[8px] rounded-[6px] bg-black/[0.64] z-[5] text-center"
              >
                {`${peer.name.slice(0, isHost ? 9 : 17)}${
                  peer.name.length >= (isHost ? 9 : 17) ? "..." : ""
                }`}{" "}
                {isHost ? "(Host)" : ""}
              </div>
            </>
          ) : (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white text-base w-[95%] text-center">
              {`${peer.name.slice(0, isHost ? 9 : 17)}${
                peer.name.length >= (isHost ? 9 : 17) ? "..." : ""
              }`}{" "}
              {isHost ? "(Host)" : ""}
            </div>
          )}
          <div className="absolute top-[4px] right-[4px] flex items-center gap-[4px]">
            {!audioOn ? (
              <div className="bg-white w-[14px] h-[14px] flex items-center justify-center rounded-full">
                <SvgMicOff fill="#CF2E2E" className="w-[12px] h-[12px]" />
              </div>
            ) : (
              <div className="w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                <SvgMic fill="white" className="w-[16px] h-[16px]" />
              </div>
            )}
            {videoOn && qualityScore !== -1 && (
              <div className="bg-white w-[14px] h-[14px] flex items-center justify-center rounded-full text-black text-base">
                {getQualityScoreImg()}
              </div>
            )}
          </div>
        </div>
      )}
      {peerType === "gallery" && (
        <div
          className={`${getGalleryPeerDimensions()} bg-black rounded-[10px] relative
          ${isDominantSpeaker ? "border-[2px] border-yellow" : ""}`}
        >
          {videoOn ? (
            <>
              <video
                className="-scale-x-100 rounded-[10px] w-full h-full"
                ref={videoRef}
                autoPlay
                muted
                playsInline
              />
              <div
                className="absolute bottom-[4px] left-[4px] font-bold bg-blur-md
                                text-white text-base py-[4px] px-[8px] rounded-[6px] bg-black/[0.64] z-[5] text-center"
              >
                {peer.name} {isHost ? "(Host)" : ""}
              </div>
            </>
          ) : (
            <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white text-[16px] text-center">
              {peer.name} {isHost ? "(Host)" : ""}
            </div>
          )}
          <div className="absolute flex items-center gap-[6px] bottom-[4px] right-[4px]">
            {!audioOn ? (
              <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full">
                <SvgMicOff fill="#CF2E2E" className="w-[16px] h-[16px]" />
              </div>
            ) : (
              <div className="w-[32px] h-[32px] flex items-center justify-center rounded-full z-[6]">
                <SvgMic fill="white" className="w-[20px] h-[20px]" />
              </div>
            )}
            {videoOn && qualityScore > -1 && (
              <div className="bg-white w-[32px] h-[32px] flex items-center justify-center rounded-full text-black text-[16px]">
                {getQualityScoreImg()}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Peer
