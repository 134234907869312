import type { SVGProps } from "react";
export const SvgZoeeLogoColored = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="auto"
    height="24"
    fill="none"
    viewBox="0 0 81 16"
    {...props}
  >
    <path
      fill="#4750F5"
      d="M14.053 6.907v-5.39c0-.556-.453-1.005-1.013-1.005H1.08C.52.512.069.962.069 1.518V14.99c0 .556.453 1.006 1.013 1.006h11.962c.56 0 1.013-.45 1.013-1.006v-1.948q0-.027.015-.049l4.339-5.75a.159.159 0 0 0-.128-.254h-4.147a.08.08 0 0 1-.082-.082"
    />
    <path
      fill="#000"
      d="M38.148.512c-4.39 0-7.828 3.4-7.828 7.742 0 4.34 3.438 7.742 7.828 7.742s7.828-3.402 7.828-7.742c0-4.341-3.438-7.742-7.828-7.742m0 11.56c-2.174 0-3.753-1.606-3.753-3.818 0-2.213 1.579-3.818 3.753-3.818s3.753 1.605 3.753 3.818-1.578 3.817-3.753 3.817M63.254 8.254c0-4.415-3.221-7.742-7.493-7.742-4.553 0-7.859 3.256-7.859 7.742 0 4.556 3.37 7.742 8.194 7.742 2.645 0 4.715-.89 6.158-2.647a.16.16 0 0 0-.047-.241l-2.98-1.702a.16.16 0 0 0-.184.02c-.714.635-1.768 1.008-2.886 1.008-2.026 0-3.333-.767-3.89-2.28l-.123-.333h10.833c.08 0 .149-.056.162-.135q.114-.687.115-1.432M52.082 6.928l.087-.313c.458-1.658 1.725-2.573 3.561-2.573 1.753 0 3.016.938 3.472 2.573l.087.313zM80.532 8.254c0-4.415-3.221-7.742-7.493-7.742-4.554 0-7.859 3.256-7.859 7.742 0 4.556 3.37 7.742 8.194 7.742 2.645 0 4.715-.89 6.157-2.647a.16.16 0 0 0-.046-.241l-2.98-1.702a.16.16 0 0 0-.184.02c-.714.635-1.768 1.008-2.886 1.008-2.026 0-3.333-.767-3.89-2.28l-.123-.333h10.833c.08 0 .149-.056.161-.135q.115-.687.115-1.432M69.36 6.928l.087-.313c.458-1.658 1.724-2.573 3.561-2.573 1.752 0 3.016.938 3.472 2.573l.086.313zM28.714.672a.16.16 0 0 0-.162-.16H17.109a.16.16 0 0 0-.162.16v3.604c0 .089.072.16.162.16h6.474c.067 0 .105.076.064.127l-6.666 8.753a.16.16 0 0 0-.034.096v2.421c0 .089.072.16.162.16h11.445a.16.16 0 0 0 .16-.16v-3.602a.16.16 0 0 0-.16-.16h-6.23c-.066 0-.104-.076-.064-.127l6.424-8.42a.16.16 0 0 0 .033-.096V.672z"
    />
  </svg>
);
