import type { SVGProps } from "react";
export const SvgZoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 40 41"
    {...props}
  >
    <path
      fill="#4A8CFF"
      d="M20 40.134c11.046 0 20-8.955 20-20s-8.954-20-20-20-20 8.954-20 20 8.955 20 20 20"
    />
    <path
      fill="#fff"
      d="M7.168 13.872v9.399a3.846 3.846 0 0 0 3.862 3.827h13.7a.7.7 0 0 0 .703-.694v-9.4a3.846 3.846 0 0 0-3.862-3.827H7.875a.7.7 0 0 0-.707.695m19.137 3.666 5.656-4.132c.491-.407.872-.305.872.432v12.6c0 .838-.466.736-.872.43l-5.656-4.122z"
    />
  </svg>
);
