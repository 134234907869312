import React, { useState, useEffect, useContext } from "react"
import SecuredByPP from "../../assets/images/securedByPayPal.svg"
import TakeMeToStripeModal from "./TakeMeToStripeModal"
import useLoading from "../../hooks/useLoading"
import { AnimatePresence } from "framer-motion"
import { useLocation } from "react-router-dom"
import { Button } from "../ui/button"
import { ClientPaymentRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgShieldFill, SVGwarningsign } from "../icons"
import { UserCtx } from "../../context/userContext"

type Props = {
  connectAccountData: any
}

const syncAccountSteps = []

export default function PaymentsSetupInstructions({
  connectAccountData,
}: Props) {
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [showTakeMeToStripeModal, setShowTakeMeToStripeModal] =
    useState<boolean>(false)
  const [paypalStatus, setPaypalStatus] = useState<any>(null)
  const query = new URLSearchParams(useLocation().search)
  const merchantIdInPayPal = query.get("merchantIdInPayPal")

  useEffect(() => {
    getPaypalOnboardingStatus()
  }, [])

  const getPaypalOnboardingStatus = async () => {
    if (user) {
      startLoading()
      try {
        const onboardingStatus =
          await ClientPaymentRequests.getPaypalOnboardingStatus({
            seller_payer_id: merchantIdInPayPal,
            seller_user_id: parseInt(user?.user_id),
          })
        setPaypalStatus(onboardingStatus)
      } catch (error) {
        renderError(
          "An error occurred trying to fetch your PayPal onboarding status"
        )
      } finally {
        stopLoading()
      }
    }
  }
  const getPaypalOnboardingLink = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (user) {
        startLoading()
        ClientPaymentRequests.getPaypalOnboardingLink({
          user_id: user.user_id,
        })
          .then((data) => {
            const links = data.links
            if (links && links.length > 1) {
              resolve(links[1].href)
            } else {
              renderError("Cannot find PayPal Link")
              reject("Cannot find PayPal Link")
            }
          })
          .catch((ex) => {
            renderError(ex.response.data.message)
            reject(ex.response.data.message)
          })
          .finally(() => {
            stopLoading()
          })
      } else {
        reject("User not found")
      }
    })
  }

  const handleSignUpClick = async () => {
    try {
      const paypalLink = await getPaypalOnboardingLink()
      if (paypalLink) {
        window.open(paypalLink, "_self")
      }
    } catch (error) {
      console.error("Error getting PayPal link:", error)
    }
  }

  const handleDisconnectClick = async () => {
    try {
      const response =
        await ClientPaymentRequests.deletePaypalOnboardingAccount({
          seller_payer_id: merchantIdInPayPal,
          seller_user_id: parseInt(user.user_id),
        })
      console.log(`response:`, response)
      alert("Disconnecting PayPal will disable all transactions")
      renderSuccess(`You have successfully disconnect PayPal account`)
      window.location.href = "/coach/client-payments"
    } catch (error) {
      console.log(`error:`, error)
    }
  }

  const paypalAccountConncted = paypalStatus?.data.merchant_id
  const emailVerified = paypalStatus?.data.primary_email_confirmed
  const payPalOnboardingStatus = paypalStatus?.data?.onboarding_completed
  const paymentsReceivable = paypalStatus?.data?.payments_receivable

  // TODO: Do not erase the below commented block(s)

  // const paypalProtectionLinks = [
  //   {
  //     href: "https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-stripe",
  //     text: "Zoee protects your payment information",
  //   },
  //   {
  //     href: "https://intercom.help/zoee/en/articles/8187172-how-do-i-get-paid-in-zoee#h_112090f95a",
  //     text: "fees",
  //   },
  // ]

  // const renderLink = ({ href, text }: { href: string; text: string }) => (
  //   <a
  //     href={href}
  //     target="_blank"
  //     rel="noopener noreferrer"
  //     className="text-blurple"
  //   >
  //     {text}
  //   </a>
  // )

  return (
    <>
      <div className="box-shadow-1 flex min-h-[234px] flex-col justify-between rounded-[16px] bg-white px-[16px] py-[32px] md:p-[40px]">
        <div className="mb-[40px] flex w-full flex-col justify-between md:flex-row md:items-center">
          <div className="flex flex-col gap-[8px] md:mb-0">
            <h6 className="text-[22px] font-bold">Getting paid on Zoee</h6>
            {/* <p className="text-graySlate text-[16px]">
              Learn more about how {renderLink(paypalProtectionLinks[0])} and{" "}
              {renderLink(paypalProtectionLinks[1])}.
            </p> */}
          </div>
        </div>
        <div className="bg-grayCloud flex w-full flex-col gap-[24px] rounded-[16px] px-[8px] py-[24px] md:gap-[32px] md:px-[40px] md:py-[40px]">
          <div className="flex flex-col items-center justify-between gap-[8px] md:flex-row md:gap-[24px]">
            {syncAccountSteps.length === 0 && (
              <div
                className="card-hover-shadow relative flex min-h-[180px] w-full flex-col items-center
                            justify-between gap-4 rounded-[12px] bg-white px-10 py-8"
              >
                <img
                  src={SecuredByPP}
                  alt="paypal secured"
                  className="w-[159px]"
                />
                <div className="flex flex-col items-center">
                  <div className="flex justify-center items-center mb-4 gap-1">
                    {paypalAccountConncted && !emailVerified && (
                      <SVGwarningsign className="mr-2" />
                    )}
                    {paypalAccountConncted && !paymentsReceivable && (
                      <SVGwarningsign className="mr-2" />
                    )}
                    {payPalOnboardingStatus && emailVerified && paymentsReceivable &&(
                      <SvgShieldFill className="mr-1" />
                    )}
                    <h3 className="text-center text-[18px] font-bold ">
                      {!paypalAccountConncted &&
                        "Connect your PayPal account to get paid"}
                      {paypalAccountConncted &&
                        !emailVerified &&
                        "Verify PayPal email"}
                       {paypalAccountConncted &&
                        !paymentsReceivable &&
                        "Cannot receive payments"}
                      {payPalOnboardingStatus && emailVerified && paymentsReceivable && "Verified"}
                    </h3>
                  </div>
                  {!paypalAccountConncted && (
                    <Button
                      onClick={handleSignUpClick}
                      variant="default"
                      size="lg"
                    >
                      Connect a PayPal Account
                    </Button>
                  )}
                  {paypalAccountConncted && !emailVerified && (
                    <p className="text-[16px] text-center">
                      Please confirm you email address on{" "}
                      <Button
                        variant="link"
                        size="sm"
                        onClick={handleSignUpClick}
                        className="underline p-0 text-[16px]"
                      >
                        Paypal Business settings
                      </Button>{" "}
                      in order to recieve payments! You currently cannot receive
                      payments.
                    </p>
                  )}
                   {paypalAccountConncted && !paymentsReceivable && (
                    <>
                    <p className="text-[16px] text-center">
                      You currently cannot receive payments due to possible restriction
                      on your PayPal account. Make sure your bank account is connected in your 
                      <a
                      href="https://www.paypal.com/myaccount"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline p-0 text-[16px]"
                      >
                        PayPal Account
                    </a>.
                    </p>
                    <p> If issue persists, please reach out to <b>Paypal Customer Support</b></p>
                    </>
                  )}
                  {payPalOnboardingStatus && emailVerified && paymentsReceivable &&(
                    <>
                      <p className="mb-6 text-[16px] text-center">
                        Your verified email will be securely used for payouts
                        which will automatically be deposited 2 weeks after a
                        commission is earned.
                      </p>
                      <Button
                        variant="destructive"
                        size="lg"
                        onClick={handleDisconnectClick}
                      >
                        Disconnect PayPal
                      </Button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showTakeMeToStripeModal && (
          <TakeMeToStripeModal
            setShowTakeMeToStripeModal={setShowTakeMeToStripeModal}
            onClickCallback={getPaypalOnboardingLink}
          />
        )}
      </AnimatePresence>
    </>
  )
}
