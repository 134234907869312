import React from "react"
import CloseModal from "./CloseModal"
import { Button } from "./ui/button"

interface Props {
  setShowModal: any
  confirmCallback: () => void
}

const CodeOfEthicsModal: React.FC<Props> = ({
  setShowModal,
  confirmCallback
}) => {
  return (
    <div
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
      z-[3001] flex items-center justify-center"
    >
      <div
        className="md:p-[48px] p-[24px] max-h-[95vh] w-[950px] max-w-[90%] overflow-y-auto bg-white 
        main-shadow rounded-[16px] flex flex-col items-center relative"
      >
        <CloseModal
          styling="absolute top-[24px] right-[24px]"
          callback={setShowModal}
        />
        <div className="container">
          <h3 className="text-3xl font-bold mb-4">Code of Ethics</h3>
          <p className="mb-8">Welcome to Zoee! Our community is built on principles of trust, respect,
            and collaboration. As a user of our platform, you agree to adhere to the following Code of Ethics to ensure a
            positive and secure environment for coaching and related activities.
          </p>
          <ol className="list-decimal pl-6 mb-8">
            <li className="mb-4">
              <strong>Respectful Conduct:</strong>
              <ul className="list-disc pl-6">
                <li>Treat all coaches, clients, and platform members with respect and courtesy.</li>
                <li>Embrace diversity and create an inclusive space for individuals from various backgrounds.</li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Professionalism:</strong>
              <ul className="list-disc pl-6">
                <li>Maintain professionalism in all interactions, communications, and transactions.</li>
                <li>Uphold the highest standards of integrity and ethical conduct.</li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Privacy and Confidentiality:</strong>
              <ul className="list-disc pl-6">
                <li>Respect the privacy of all users.</li>
                <li>Maintain confidentiality in all coaching sessions and interactions.</li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Compliance with Laws:</strong>
              <ul className="list-disc pl-6">
                <li>Adhere to all applicable laws and regulations governing coaching services.</li>
                <li>Report any illegal or unethical activities observed on the platform.</li>
              </ul>
            </li>
            <li className="mb-4">
              <strong>Responsible Use of Platform Features:</strong>
              <ul className="list-disc pl-6">
                <li>Use platform features, including video, messaging, and marketplace functionalities, responsibly and ethically.</li>
                <li>Refrain from engaging in any form of harassment or abusive behavior.</li>
              </ul>
            </li>
          </ol>
          <p>
            By accepting this Code of Ethics, you commit to upholding these principles and contributing to a positive coaching community on Zoee.
          </p>
          <p className="mt-8">Zoee Team</p>
        </div>
        <div className="flex items-center justify-center gap-[16px] mt-[32px]">
          <button
            className="btn-primary btn-secondary"
            onClick={() => setShowModal((prev: boolean) => !prev)}
          >
            <span className="text-graySlate font-bold text-base">Cancel</span>
          </button>
          <Button onClick={confirmCallback} className="text-md" size={"lg"}>
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CodeOfEthicsModal