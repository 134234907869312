import type { SVGProps } from "react";
export const SvgLearn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.184 3.648a2.55 2.55 0 0 0-1.9-.633c-.02 0-.096 0-.115.003-2.054.168-5.145 1.162-6.895 2.219l-.147.091a.4.4 0 0 1-.262-.005l-.221-.127c-1.75-1.047-4.836-2.029-6.878-2.187-.73-.057-1.423.164-1.948.627A2.39 2.39 0 0 0 2 5.451v10.78c0 1.194 1 2.285 2.221 2.432l.273.035c1.917.246 4.976 1.208 6.713 2.118.23.123.511.184.79.184.28 0 .556-.062.777-.184 1.702-.902 4.768-1.872 6.686-2.117l.31-.036C21 18.516 22 17.425 22 16.232V5.46c0-.704-.29-1.348-.816-1.812m-9.881 15.716c-1.894-.888-4.718-1.748-6.621-1.992l-.28-.037c-.536-.064-1.007-.58-1.007-1.103V5.45c0-.326.13-.62.368-.829.208-.185.481-.284.778-.284q.054 0 .11.004c1.826.142 4.69 1.053 6.265 1.996l.222.127c.05.03.11.044.165.067zm9.302-3.132c0 .523-.47 1.039-1.005 1.103l-.315.037c-1.895.242-4.688 1.093-6.587 1.98V6.533c.057-.025.12-.04.173-.072l.152-.094c1.56-.941 4.429-1.864 6.204-2.012.02 0 .096-.001.115-.003.345-.024.66.073.897.282.236.208.366.502.366.828zM5.255 8.869c0-.37.313-.67.699-.67h2.093c.385 0 .697.3.697.67s-.312.67-.697.67H5.954c-.386 0-.698-.3-.698-.67m4.187 2.678c0 .369-.313.669-.698.669h-2.79c-.386 0-.698-.3-.698-.67 0-.369.312-.669.698-.669h2.79c.386 0 .698.3.698.67"
    />
  </svg>
);
