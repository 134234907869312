import React from "react";
import { Meeting } from "../../../types/meetingTypes";
import { format } from "date-fns";
import ContactAvatar from "../../cards/ContactAvatar";
import useScrollToTop from "../../../hooks/useScrollToTop";

type Props = {
    meeting: Meeting | undefined;
};
const OtherCalendarSessionDetailView: React.FC<Props> = ({ meeting }) => {
    useScrollToTop();

    if (!meeting) {
        return <p>Session not found</p>;
    }

    return (
        <div className="mx-auto md:w-[744px] px-[18px] md:px-0 w-full flex flex-col">
            <div className="promotion-shadow rounded-[16px] min-w-[340px]">
                <div
                    className={`
                    ${meeting.is_managed && "non-active-profile-session-detail-gradient"}
                    ${!meeting.is_managed && "non-zoee-managed-session-detail-gradient"}
                    h-[100px] px-[24px] md:px-[32px] flex items-center justify-between rounded-t-[16px]`}
                >
                    <div className="flex items-center">
                        <h3 className="text-[16px] font-bold text-white">Calendar Event</h3>
                    </div>
                    {meeting.endTime && meeting.endTime < new Date() ? (
                        <>
                            <div className="hidden md:block text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                                This meeting occured in the past
                            </div>
                            <div className="w-fit md:hidden text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                                Past meeting
                            </div>
                        </>
                    ) : (
                        <></>
                        // TODO add conver to Zoee Managed feature
                        // <button className="btn-primary h-[32px] bg-white text-mainBlack">
                        //     Convert to Zoee Managed
                        // </button>
                    )}
                </div>
                <div className="flex flex-col px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
                    <h4 className="text-base font-bold text-graySlate mb-[8px]">EVENT DETAILS</h4>
                    <h2 className="text-[18px] md:text-[32px] font-bold mb-[12px]">
                        {meeting.meetingName}
                    </h2>

                    {/* <div className="flex items-center mb-[32px]">
                        <div className="pr-[16px] border-r border-grayMist">
                            {meeting.status && <StatusIcon status={meeting.status} />}
                        </div> */}
                    {/* TODO make dynamic */}
                    {/* <p className="font-bold text-black text-base pl-[16px]">
                            Conference Room 123 in the Zoee Building
                        </p> */}
                    {/* </div> */}
                    {/* <div className="flex flex-col"> */}
                    {/* TODO: Make dynamic */}
                    {/* <h4 className="text-base font-bold text-graySlate mb-[8px]">
                            Notifications
                        </h4> */}
                    {/* <div className="flex gap-[8px]">
                            <img
                                src={notificationBell}
                                alt="notification bell icon"
                                className="w-[24px] h-[24px]"
                            />
                            <p className="text-base font-bold">
                                Email, 30 minutes
                            </p>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>

            <div className="mt-[12px] promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px]">
                <h5 className="mb-[16px] text-base font-bold text-graySlate">WHEN</h5>
                <div>
                    {meeting.date && meeting.startTime && meeting.endTime && (
                        <>
                            <p className="font-bold text-[16px] mb-[8px]">
                                {format(meeting?.date, "MMMM dd, yyyy")}
                            </p>
                            <div className="flex items-center text-graySlate text-base">
                                <p>Starts at</p>
                                <p className="text-black font-bold">
                                    &nbsp;
                                    {format(meeting.startTime, "h:mm aa")}
                                </p>
                                <p className="mx-[12px]">-</p>
                                <p>Ends at</p>
                                <p className="text-black font-bold">
                                    &nbsp;{format(meeting.endTime, "h:mm aa")}
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="mt-[12px] promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px] flex flex-col">
                <div className="flex items-center gap-[12px] mb-[16px]">
                    <h4 className="text-base font-bold text-graySlate">ATTENDEES</h4>
                </div>
                <div className="flex flex-col gap-[12px]">
                    {meeting.contacts.map((contact, idx) => {
                        if (meeting.is_managed) {
                            return (
                                <div className="flex flex-col gap-[12px]">
                                    <div key={idx} className="flex items-center">
                                        <ContactAvatar
                                            contact={contact}
                                            width={32}
                                            height={32}
                                            bold={false}
                                            fontSize={"text-base"}
                                        />
                                        <p className="ml-[16px] mr-[12px] text-base font-bold">
                                            {contact.first_name} {contact.last_name}
                                        </p>
                                        <div className="text-base font-bold">
                                            {/* TODO Hidden for 2.0 launch. Add back in later. */}
                                            {/* <AttendeeStatusTag status="Accepted" /> */}
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div key={idx} className="flex items-center">
                                    {/* TODO Add contact avatar */}
                                    {/* <img
                                        src={avatar}
                                        alt="avatar"
                                        className="w-[32px] mr-[16px]"
                                    /> */}
                                    <p className="mr-[12px] text-base font-bold">
                                        {contact.email}
                                    </p>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default OtherCalendarSessionDetailView;
