import React from "react"
import Peer from "./Peer"
import {
  useHMSStore,
  selectPeers,
  selectDominantSpeaker,
} from "@100mslive/react-sdk"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { SvgChevronRight } from "../icons"

interface Props {}
interface VideoRange {
  start: number
  end: number
}

const GalleryView: React.FC<Props> = () => {
  const { mobileView, fullScreen } = React.useContext(SessionContext)
  const peers = useHMSStore(selectPeers)
  const dominantSpeaker = useHMSStore(selectDominantSpeaker)
  const participantsPerPage = 8
  const participantsPerPageMobile = 4
  const totalPages = Math.ceil(peers.length / participantsPerPage)
  const totalMobilePages = Math.ceil(peers.length / participantsPerPageMobile)
  const [currentPage, setCurrentPage] = React.useState<number>(1)
  const [mobileVideoRange, setMobileVideoRange] = React.useState<VideoRange>({
    start: 0,
    end: participantsPerPageMobile,
  })
  const [currentMobilePage, setCurrentMobilePage] = React.useState<number>(1)
  const [speakerOrder, setSpeakerOrder] = React.useState(peers)

  React.useEffect(() => {
    if (dominantSpeaker) {
      setSpeakerOrder((prevOrder) => {
        const existingIndex = prevOrder.findIndex(
          (peer) => peer.id === dominantSpeaker.id
        )
        if (existingIndex === 0) return prevOrder
        if (existingIndex > 0) {
          const updatedOrder = [...prevOrder]
          updatedOrder.splice(existingIndex, 1)
          updatedOrder.unshift(dominantSpeaker)
          return updatedOrder
        }
        return [dominantSpeaker, ...prevOrder]
      })
    }
  }, [dominantSpeaker])

  React.useEffect(() => {
    setSpeakerOrder(peers)
  }, [peers])

  const videoRange: VideoRange = {
    start: (currentPage - 1) * participantsPerPage,
    end: currentPage * participantsPerPage,
  }

  const handlePageLeft = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1))
  }

  const handlePageRight = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
  }

  const mobileRange: VideoRange = {
    start: (currentMobilePage - 1) * participantsPerPageMobile,
    end: currentMobilePage * participantsPerPageMobile,
  }

  const handleMobilePageLeft = () => {
    setMobileVideoRange((prev: any) => ({
      start: prev.start - participantsPerPageMobile,
      end: prev.end - participantsPerPageMobile,
    }))
    setCurrentMobilePage((prev) => Math.max(prev - 1, 1))
  }

  const handleMobilePageRight = () => {
    setMobileVideoRange((prev: any) => ({
      start: prev.start + participantsPerPageMobile,
      end: prev.end + participantsPerPageMobile,
    }))
    setCurrentMobilePage((prev) => Math.min(prev + 1, totalMobilePages))
  }

  React.useEffect(() => {
    if (dominantSpeaker) {
      setCurrentPage(1) // switch to first page for desktop
      setCurrentMobilePage(1) // switch to first page for mobile
      setMobileVideoRange({ start: 0, end: participantsPerPageMobile })
    }
  }, [dominantSpeaker])

  return (
    <>
      {mobileView ? (
        <>
          {peers.length > participantsPerPageMobile && (
            <div className="fixed right-[24px] top-[42px] flex items-center gap-[16px] z-[20]">
              <button
                className="mr-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray"
                disabled={mobileVideoRange.start === 0}
                onClick={handleMobilePageLeft}
              >
                <SvgChevronRight stroke="#3395FF" className="rotate-[180deg]" />
              </button>
              <p className="text-white font-bold text-base">{`${currentMobilePage}/${totalMobilePages}`}</p>
              <button
                className="ml-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray"
                disabled={mobileVideoRange.end >= speakerOrder.length}
                onClick={handleMobilePageRight}
              >
                <SvgChevronRight stroke="#3395FF" className="" />
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          {peers.length > participantsPerPage && (
            <div className="absolute left-[50%] -translate-x-[50%] top-[28px] flex items-center gap-[16px]">
              <button
                className="mr-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray"
                disabled={videoRange.start === 0}
                onClick={handlePageLeft}
              >
                <SvgChevronRight stroke="#3395FF" className="rotate-[180deg]" />
              </button>
              <p className="text-white font-bold text-base">{`${currentPage}/${totalPages}`}</p>
              <button
                className="ml-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray"
                disabled={videoRange.end >= speakerOrder.length}
                onClick={handlePageRight}
              >
                <SvgChevronRight stroke="#3395FF" className="" />
              </button>
            </div>
          )}
        </>
      )}
      {speakerOrder.length === 1 && (
        <Peer
          peer={speakerOrder[0]}
          peerType="full-screen"
          peerCount={speakerOrder.length}
          currentPage={currentPage}
        />
      )}
      {speakerOrder.length > 1 && (
        <div
          className={`absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]
                    w-full flex flex-wrap items-center justify-center gap-[12px]
                    ${fullScreen ? "max-w-[95%]" : ""}`}
        >
          {mobileView ? (
            <>
              {speakerOrder
                .slice(mobileVideoRange.start, mobileVideoRange.end)
                .map((peer) => (
                  <Peer
                    peer={peer}
                    peerType="gallery"
                    peerCount={speakerOrder.length}
                    currentPage={currentMobilePage}
                  />
                ))}
            </>
          ) : (
            <>
              {speakerOrder
                .slice(videoRange.start, videoRange.end)
                .map((peer) => (
                  <Peer
                    peer={peer}
                    peerType="gallery"
                    peerCount={speakerOrder.length}
                    currentPage={currentPage}
                  />
                ))}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default GalleryView
