import React from "react"
import Loader from "../ui/loader"
import ToClientImage from "../../assets/images/marketplace/MailToClient.png"
import ToCoachImage from "../../assets/images/marketplace/MailToCoach.png"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests } from "../../api/app.service"
import { SvgClose } from "../icons"
import { UserCtx } from "../../context/userContext"
interface Props {
  setShowConfirmInviteModal: any
  invites: any
  setInvites: any
  // getContacts: any
  contacts: any
}
const CoachDirectoryInvite: React.FC<Props> = ({
  setShowConfirmInviteModal,
  invites,
  setInvites,
  // getContacts,
  contacts,
}) => {
  const [validInvites, setValidInvites] = React.useState<any>([])
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [submitObject, setSubmitObject] = React.useState<any>({
    invites: [],
    subject: "Connect with me on Zoee!",
    description: `Please log in to the Zoee coaching portal. It serves as a hub and central location for the coaching work we will do together.`,
  })
  const getMemberName = () => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    } else {
      return user?.email
    }
  }
  const [customMessage, setCustomMessage] = React.useState<string>("")
  const generalMessage =
    user?.activeProfile === "coach"
      ? `Hi, I'm a Coach ${user?.firstName} ${user?.lastName}. Let's connect on Zoee Client Portal`
      : `Hi, my name is ${getMemberName()}. Let's connect on Zoee Client Portal`
  const submitInvitesForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsSubmit(true)
    let emailArray: any[] = []
    validInvites.forEach((invite: any) => emailArray.push(invite.email))
    setSubmitObject({ ...submitObject, invites: emailArray })
  }
  const closeAndReset = () => {
    setValidInvites([])
    setInvites([{ name: "", email: "" }])
    setShowConfirmInviteModal(false)
    // getContacts()
  }
  const removeInvite = (invite: any) => {
    const inviteCount = validInvites.length
    if (inviteCount <= 1) {
      closeAndReset()
    } else {
      const filtered = validInvites.filter((item: any) => {
        return invite.email !== item.email
      })
      setValidInvites(filtered)
      setInvites(filtered)
    }
  }
  // Send invite function
  const sendInvites = () => {
    if (user?.coachProfile || user?.memberProfile) {
      setLoading(true)
      startLoading()
      const existingContacts = contacts
        .filter((contact: any) => contact.contact_type !== "prospect")
        .map((contact: any) => contact.email?.toLocaleLowerCase())
      const inviteEmails = validInvites.map((invite: any) =>
        invite.email?.toLocaleLowerCase()
      )
      if (
        inviteEmails.some((email: string) => existingContacts.includes(email))
      ) {
        renderError("The email you entered is already in your contacts.")
        setLoading(false)
        stopLoading()
        setIsSubmit(false)
      } else {
        ContactRequests.createContactRequest({
          profile_id: user.activeProfileId,
          contact_requests: inviteEmails,
          description: submitObject.description,
          subject: submitObject.subject,
          custom_message: customMessage || generalMessage,
          avatar_url: user?.avatar_url,
          sender_email: user?.email,
          has_avatar: user?.hasAvatar,
        })
          .then(() => {
            setLoading(false)
            closeAndReset()
            renderSuccess("Invites sent successfully!")
            window.location.reload()
          })
          .catch((ex) => {
            console.log(ex)
            if (ex.response?.data?.error_detail) {
              renderError(ex.response.data.error_detail)
            } else {
              renderError(ex.response?.data?.message || "An error occurred.")
            }
            setLoading(false)
            stopLoading()
          })
      }
    }
    setIsSubmit(false)
  }
  React.useEffect(() => {
    if (isSubmit) {
      sendInvites()
    }
  }, [isSubmit, submitObject])
  React.useEffect(() => {
    const filledInvites = invites.filter((invite: { email: string }) => {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/
      return invite.email && invite.email.match(mailformat)
    })
    setValidInvites(filledInvites)
    console.log("Valid Invites:", filledInvites)
  }, [])
  return (
    <div className="fixed top-0 left-0 w-screen h-screen pb-[120px] zoee-backdrop-filter z-[801] flex items-center justify-center">
      <form action="#" onSubmit={submitInvitesForm}>
        <div
          className="py-[16px] sm:py-[32px] px-[24px] sm:px-[48px] w-[351px] sm:w-auto bg-white 
                    main-shadow rounded-[16px] flex flex-col items-center relative mt-[50px] md:mt-[100px]"
        >
          <SvgClose
            className="absolute top-[24px] right-[24px] cursor-pointer"
            onClick={closeAndReset}
          />
          {validInvites.map((invite: any, index: any) => (
            <h2
              key={index}
              className="text-[18px] md:text-[22px] font-bold mb-[24px] text-center"
            >
              Connect with
              <br />
              <span className="text-[16px] md:text-[18px] font-bold text-gray-600 break-all">
                {invite.name || invite.email}
              </span>
            </h2>
          ))}
          <div className="w-full flex items-center justify-center mt-[16px]">
            {loading ? (
              <Loader />
            ) : (
              <Button type="submit">
                {`Add Coach${validInvites.length > 1 ? "s" : ""}`}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
export default CoachDirectoryInvite
