import React from "react"
import { Button } from "../../components/ui/button"
import ContactAvatar from "../../components/contacts/ContactAvatar"
import { Link, useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import classes from "../../pages/dashboard/CoachDashboard/CoachDashboardBento.module.css"
import { SvgChat, SvgUsers } from "../../components/icons/"
interface Props {
  userContacts: any
}

export const DashboardContacts: React.FC<Props> = ({ userContacts }) => {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()

  const startMeetingChat = (contactId: string) => {
    const participantIds = [
      contactId.toString(),
      user.activeProfileId.toString(),
    ]
    navigate(`/${user.activeProfile}/inbox`, {
      state: {
        participantIds: participantIds,
      },
    })
  }
  const getHeader = () => {
    if (user.activeProfile === "coach") {
      return "My Contacts"
    } else {
      return "My Coaches"
    }
  }

  const getContactLink = (contact: any) => {
    if (user.activeProfile === 'coach') {
      return `/coach/contacts/${contact.profile_id}`;
    } else{
      return `/member/contacts/${contact.profile_id}`;
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between -mt-12 mb-[10px] p-2">
        <h4 className={"text-lg font-bold flex flex-row gap-2"}>
          <SvgUsers />
          {getHeader()}
        </h4>
        <Link to={user.activeProfile === 'coach' ? "/coach/contacts" : "/member/contacts"}>
          <Button variant="link">See all</Button>
        </Link>
      </div>
      <div className={`max-h-[315px] overflow-y-auto pl-4 pr-1 ${classes.scrollBar}`}>
        {userContacts?.map((contact: any, index: number) => (
          contact.contact_type === 'connected' && (
          <div
          key={index}
            className={`p-[14px] flex items-center justify-between cursor-pointer border-b last:border-0 border-grayMist`}
          >
            <Link to={getContactLink(contact)}>
            <div className="flex items-center">
              <div className="mr-[16px]">
                <ContactAvatar
                  contact={contact}
                  width={40}
                  height={40}
                  border="border-none"
                />
              </div>
              <div className="flex flex-col">
                <h6 className="font-bold text-[16px]">
                {contact.contact_name.length > 20
                ? `${contact.contact_name.slice(0, 20)}…`
                : contact.contact_name}
                </h6>
              </div>
            </div>
            </Link>
            <div
              className={`flex items-center justify-center`}
              onClick={() => {
                startMeetingChat(contact.profile_id)
              }}
            >
              <Button variant="outline">
                <SvgChat />
              </Button>
            </div>
          </div>
          )
        ))}
      </div>
    </div>
  )
}
