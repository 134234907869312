import React from "react"
import { DateRangePicker as ReactDateRangePicker } from "react-date-range"
import {
  startOfYear,
  endOfYear,
  format,
  addYears,
  isSameDay,
  sub,
  startOfMonth,
  endOfMonth,
} from "date-fns"
import { SvgCalendar, SvgChevronDown } from "../icons"

type Props = {
  setActiveDates: React.Dispatch<React.SetStateAction<Date[] | null>>
  defaultStartDate?: Date
  defaultEndDate?: Date
}

const DateRangePicker: React.FC<Props> = ({
  setActiveDates,
  defaultStartDate,
  defaultEndDate,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [dateRangePickerRanges, setDateRangePickerRanges] = React.useState([
    {
      startDate: defaultStartDate || startOfYear(new Date()),
      endDate: defaultEndDate || endOfYear(new Date()),
      key: "selection",
    },
  ])

  const handleDateRangePickerChange = (item: any) => {
    setDateRangePickerRanges([item.selection])

    if (setActiveDates) {
      const { startDate, endDate } = item.selection

      if (startDate && endDate) {
        setActiveDates([startDate, endDate])
      } else {
        setActiveDates(null)
      }
    }
  }

  React.useEffect(() => {
    let startDate = startOfYear(new Date())
    let endDate = endOfYear(new Date())
    if (setActiveDates) {
      setActiveDates([startDate, endDate])
    }
  }, [])

  return (
    <div className="relative">
      <button
        onClick={() => setIsModalOpen(!isModalOpen)}
        className=" flex font-bold"
      >
        <SvgCalendar className="mr-[16px]" />

        <div className="hidden md:block">
          <span className="text-graySlate">from&nbsp;</span>
          {format(dateRangePickerRanges[0].startDate, "MMMM d, yyyy")}
          <span className="text-graySlate">&nbsp;to&nbsp;</span>
          {format(dateRangePickerRanges[0].endDate, "MMMM d, yyyy")}
        </div>

        <div className="md:hidden">
          <span className="text-graySlate">from&nbsp;</span>
          {format(dateRangePickerRanges[0].startDate, "MMM d, yyyy")}
          <span className="text-graySlate">&nbsp;to&nbsp;</span>
          {format(dateRangePickerRanges[0].endDate, "MMM d, yyyy")}
        </div>

        <SvgChevronDown
          className={`${isModalOpen ? "transform rotate-180" : ""} ml-[8px]`}
        />
      </button>
      {isModalOpen ? (
        <>
          {/* MOBILE */}
          <div className="md:hidden z-50 rounded-[16px] overflow-hidden absolute top-[44px] -left-[24px] box-shadow-1">
            <ReactDateRangePicker
              onChange={handleDateRangePickerChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRangePickerRanges}
              direction="vertical"
              editableDateInputs={true}
              monthDisplayFormat={"MMMM yyyy"}
              inputRanges={[]}
              staticRanges={[
                {
                  label: "Last 30 Days",
                  range: () => ({
                    startDate: sub(new Date(), { days: 30 }),
                    endDate: new Date(),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "This Month",
                  range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: endOfMonth(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last Month",
                  range: () => ({
                    startDate: startOfMonth(sub(new Date(), { months: 1 })),
                    endDate: endOfMonth(sub(new Date(), { months: 1 })),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last 3 Months",
                  range: () => ({
                    startDate: startOfMonth(sub(new Date(), { months: 2 })),
                    endDate: endOfMonth(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last Year",
                  range: () => ({
                    startDate: startOfYear(addYears(new Date(), -1)),
                    endDate: endOfYear(addYears(new Date(), -1)),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "This Year",
                  range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfYear(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
              ]}
            />
          </div>

          {/* DESKTOP */}
          <div className="hidden md:block z-50 rounded-[16px] overflow-hidden absolute top-[44px] -left-[24px] box-shadow-1">
            <ReactDateRangePicker
              onChange={handleDateRangePickerChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRangePickerRanges}
              direction="horizontal"
              editableDateInputs={true}
              monthDisplayFormat={"MMMM yyyy"}
              inputRanges={[]}
              staticRanges={[
                {
                  label: "Last 30 Days",
                  range: () => ({
                    startDate: sub(new Date(), { days: 30 }),
                    endDate: new Date(),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "This Month",
                  range: () => ({
                    startDate: startOfMonth(new Date()),
                    endDate: endOfMonth(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last Month",
                  range: () => ({
                    startDate: startOfMonth(sub(new Date(), { months: 1 })),
                    endDate: endOfMonth(sub(new Date(), { months: 1 })),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last 3 Months",
                  range: () => ({
                    startDate: startOfMonth(sub(new Date(), { months: 2 })),
                    endDate: endOfMonth(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "Last Year",
                  range: () => ({
                    startDate: startOfYear(addYears(new Date(), -1)),
                    endDate: endOfYear(addYears(new Date(), -1)),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
                {
                  label: "This Year",
                  range: () => ({
                    startDate: startOfYear(new Date()),
                    endDate: endOfYear(new Date()),
                  }),
                  isSelected(range: any) {
                    const definedRange = this.range()
                    return (
                      isSameDay(range.startDate, definedRange.startDate) &&
                      isSameDay(range.endDate, definedRange.endDate)
                    )
                  },
                },
              ]}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default DateRangePicker
