"use client";

import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TestimonialSlider } from "./testimonial-slider";
import { Badges } from "./badges";
import { cn } from "../../../../utils/cn";
import Group6 from "../../../../assets/images/coach-testimonials/Group 6.png";
import Group7 from "../../../../assets/images/coach-testimonials/Group 7.png";
import Group8 from "../../../../assets/images/coach-testimonials/Group 8.png";
import Group9 from "../../../../assets/images/coach-testimonials/Group-9.png";
import Group10 from "../../../../assets/images/coach-testimonials/Group-10.png";

export function Testimonials() {
  const containerRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="min-h-screen lg:min-h-auto md:p-16 md:pt-24 md:pl-16 md:pb-20 justify-center text-center font-bold text-black relative bg-gradient-to-t from-[#BACDFF] to-background"
    >
      <div className="max-w-[720px] mx-auto md:px-0 px-[32px]">
        <h3 className="md:text-[42px] text-[30px] md:mb-28">
          What our coaches are saying...
        </h3>
      </div>

      <TestimonialSlider />

      <AnimatePresence>
        <div
          className={cn(
            "hidden lg:grid lg:grid-cols-3 lg:justify-items-center",
            {
              "animate": isVisible,
            },
          )}
        >
          <motion.img
            className="mr-6"
            src={Group6}
            alt="Zoee coach conversation bubble"
            style={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              scale: isVisible ? 1 : 0.5,
              transition: {
                delay: isVisible ? 0.5 : 0,
                duration: 0.25,
              },
            }}
          />
          <motion.img
            src={Group8}
            alt="Zoee coach conversation bubble"
            className="mt-16"
            style={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              scale: isVisible ? 1 : 0.5,
              transition: {
                delay: isVisible ? 1.5 : 0,
                duration: 0.25,
              },
            }}
          />
          <motion.img
            src={Group10}
            alt="Zoee coach conversation bubble"
            style={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              scale: isVisible ? 1 : 0.5,
              transition: {
                delay: isVisible ? 2.5 : 0,
                duration: 0.25,
              },
            }}
          />
        </div>
      </AnimatePresence>

      <AnimatePresence>
        <div
          className={cn(
            "hidden lg:grid lg:grid-cols-2 lg:gap-96 lg:place-items-center",
            {
              "animate": isVisible,
            },
          )}
        >
          <motion.img
            src={Group9}
            alt="Zoee coach conversation bubble"
            style={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              scale: isVisible ? 1 : 0.5,
              transition: {
                delay: isVisible ? 3.5 : 0,
                duration: 0.25,
              },
            }}
          />
          <motion.img
            src={Group7}
            alt="Zoee coach conversation bubble"
            className="-mt-32"
            style={{ opacity: 0, scale: 0.5 }}
            animate={{
              opacity: isVisible ? 1 : 0,
              scale: isVisible ? 1 : 0.5,
              transition: {
                delay: isVisible ? 4.5 : 0,
                duration: 0.25,
              },
            }}
          />
        </div>
      </AnimatePresence>
      <Badges />
    </div>
  );
}
