import React from "react"
import { EmailLogin } from "../../api/auth.service"
import { UserInfo } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import moment from "moment-timezone"
import { getUserStats } from "../../utils/getUserStats"
import { Button } from "../ui/button"
import { type View } from "./AuthModal"
import { SvgLock, SvgWarning, SvgZoeeLogo } from "../icons"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import Recaptcha from "../auth/register-form/Recaptcha"

interface Props {
  emailLoginObject: any
  isModalVariant?: boolean
  setModalLoading?: any
  modalLoading?: boolean | string
  successRedirectPathname?: string
  successRedirectSearchParams?: any
  preRedirectActions?: any
  setView: React.Dispatch<React.SetStateAction<View>>
}

export default function LoginPassword(props: Props) {
  const { renderError, hideAlert, getUser } =
    React.useContext(CommonFunctionCtx)
  const [values, setValues] = React.useState<any>({
    email: props?.emailLoginObject?.email,
  })
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false)
  const [formErrors, setFormErrors] = React.useState<any>({})
  const [stats, setStats] = React.useState<any>({})
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  React.useEffect(() => {
    getUserStats()
  }, [])

  const handlePasswordTypeToggle = () => {
    const field = document.getElementById("loginPassword") as HTMLInputElement
    const showHideButtonText = document.querySelector(
      ".show-hide-button"
    ) as HTMLBodyElement

    if (field) {
      if (field.type === "password") {
        field.type = "text"
        showHideButtonText.innerHTML = "HIDE"
      } else {
        field.type = "password"
        showHideButtonText.innerHTML = "SHOW"
      }
    }
  }

  const validateForm = (values: any) => {
    const errors: any = {}
    !values.password && (errors.password = "Password is required")
    return errors
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let target = e.target as HTMLInputElement
    setValues({ ...values, [target.name]: target.value })
  }

  const handleForgotPassword = () => {
    props.setView("forgot-pass")
  }

  const updateTimezone = async (userId: any, access_token: string) => {
    props.setModalLoading("Updating your timezone")
    await UserInfo.editTimezone({
      user_id: parseInt(userId),
      access_token: access_token,
      timezone: { value: moment.tz.guess(true) },
    })
      .then((data) => {
        if (data) {
          getUser({
            successRedirectPathname: props.successRedirectPathname,
            successRedirectSearchParams: props.successRedirectSearchParams,
            preRedirectActions: props.preRedirectActions,
            redirectToDashboard: true,
          })
        }
      })
      .catch((ex: { response: { data: any } }) => {
        console.log(ex)
        renderError(ex.response.data.message)
        props.setModalLoading(false)
      })
  }

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      setIsSubmit(false)
      props.setModalLoading("Logging you in")
      const requestObject = { ...values, stats: stats }
      EmailLogin.loginEmail(requestObject)
        .then((data) => {
          if (data) {
            console.log(data)
            if (isLocalStorageAvailable()) {
              localStorage.setItem("user", JSON.stringify(data))
            }
            updateTimezone(data.user_id, data.access_token)
          }
        })
        .catch((ex: { response: { data: any } }) => {
          console.log(ex)
          renderError(ex.response.data.message)
          props.setModalLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors, isSubmit, props, values])

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    hideAlert()
    setIsSubmit(true)
    setFormErrors(validateForm(values))
  }

  return (
    <div
      className={`flex flex-col items-center justify-start gap-6 ${
        props.isModalVariant ? "mt-[32px]" : "mt-[190px] pb-[200px]"
      }`}
    >
      <SvgZoeeLogo className="w-[121px]" />
      <div className="font-normal">
        Welcome back, {props.emailLoginObject.first_name}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <div
            className={`default-input ${formErrors.password && "input-error"}`}
          >
            <input
              type="password"
              placeholder="Enter your password"
              id="loginPassword"
              onChange={handleChange}
              name="password"
              autoFocus={true}
            />
            <div className="overlay">Password</div>
            <div
              className="show-hide-button"
              onClick={handlePasswordTypeToggle}
            >
              SHOW
            </div>
          </div>
          {formErrors.password && (
            <div className="field-error">
              <SvgWarning />
              {formErrors.password}
            </div>
          )}
        </div>
        <Recaptcha setIsNotRobot={setIsNotRobot} />
        <div className="flex flex-col items-center gap-3">
          <Button variant="ghost" onClick={handleForgotPassword} type="button">
            <SvgLock className="mr-[12px]" />
            Forgot Password?
          </Button>
          <Button type="submit" size="lg" disabled={!isNotRobot}>
            Continue
          </Button>
          <span className="text-graySlate">or</span>
          <Button variant="outline" onClick={() => props.setView("otp")}>
            Send me a one-time passcode
          </Button>
        </div>
      </form>
    </div>
  )
}
