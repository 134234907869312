import React from "react";
import Maximize from "../ui/Maximize";
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext";
import {
  SvgMaximize,
  SvgEdit,
  SvgPlay,
  SvgPause,
  SvgVideoIcon,
} from "../icons/";

interface Props {
  video: any;
}

export default function PortraitVideoPreview({ video }: Props) {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [profileVideoPlaying, setProfileVideoPlaying] =
    React.useState<boolean>(false);
  const [maximize, setMaximize] = React.useState<boolean>(false);
  const { setEditPortraitVideo, coachPublicProfileData } =
    React.useContext(CoachPrivateProfileContext);
  const [videoDuration, setVideoDuration] = React.useState<number | null>(null);

  const videoHandler = (control: any) => {
    if (control === "play") {
      videoRef?.current?.play();
      setProfileVideoPlaying(true);
    } else if (control === "pause") {
      videoRef?.current?.pause();
      setProfileVideoPlaying(false);
    }
  };

  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4";
    } else {
      return extension;
    }
  };

  React.useEffect(() => {
    const currentVideo = videoRef.current as HTMLVideoElement;
    if (currentVideo) {
      currentVideo.addEventListener("loadedmetadata", () => {
        setVideoDuration(currentVideo.duration);
      });
    }
  }, []);

  const formatDuration = (duration: number | null) => {
    if (duration === null) {
      return "Loading...";
    }
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      <div className="w-full h-full bg-black rounded-[16px] flex items-center relative group shadow-custom">
        <SvgVideoIcon
          fill="white"
          className="absolute top-0 right-[8px] w-[32px]"
        />
        <video
          ref={videoRef}
          className="w-full h-full object-cover object-center rounded-[16px]"
          preload="metadata"
        >
          <source
            src={`https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.coach_public_profile_id}_about_${video.id}.${video.video_extension}#t=0.1`}
            type={`video/${getVideoType(video.video_extension)}`}
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <Button
            onClick={() => setEditPortraitVideo({ show: true, video: video })}
            variant="utility"
            size="lg"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white border z-20 border-[1px] shadow-md opacity-100 hidden group-hover:flex"
          >
            <SvgEdit />
            Edit
        </Button>
        <div className="h-[100%] flex flex-col justify-between z-[20]">
          <div className="w-full text-center flex items-center absolute top-[8px] left-[8px]">
            <span className="font-bold bg-white bg-opacity-80 rounded-[15px] p-1 px-3">
              Video
            </span>
          </div>
          <div className="absolute bottom-[12px] left-[6px] flex flex-col gap-[12px] text-left bg-white/[0.8] p-[12px] rounded-[15px] min-w-[95%] group-hover:hidden">
            <h2 className="font-bold text-[16px] max-w-[208px] text-black line-clamp-2">
              {video.title}
            </h2>
            <div className="flex items-center gap-[8px] font-bold text-base">
              <span className="text-black">
                {formatDuration(videoDuration)} min video
              </span>
            </div>
          </div>
        </div>
        <div
          className="absolute bottom-[8px] left-[50%] -translate-x-[50%] flex hidden group-hover:flex z-[20] items-center w-full sm:justify-evenly gap-[8px] justify-center sm:gap-0"
        >
          <button
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-black/[0.8] text-white"
            onClick={() => {
              if (profileVideoPlaying) {
                videoHandler("pause");
              } else {
                videoHandler("play");
              }
            }}
          >
            {profileVideoPlaying ? (
              <SvgPause className="w-[32px] text-white" />
            ) : (
              <SvgPlay />
            )}
          </button>
          <button
            className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-black/[0.8] text-white"
            onClick={() => setMaximize(true)}
          >
            <SvgMaximize />
          </button>
        </div>
      </div>
      {maximize && (
        <Maximize
          videoSrc={video}
          onClose={setMaximize}
          coachPublicProfileId={coachPublicProfileData?.coach_public_profile_id}
        />
      )}
    </>
  );
};
