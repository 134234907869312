import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import { Providers } from "./components/providers";
import "../src/css/index.css";
import "react-image-crop/dist/ReactCrop.css";

const rootElement = document.getElementById("root")
if (!rootElement) throw new Error("Failed to find the root element")
const root = ReactDOMClient.createRoot(rootElement)

root.render(
  <Providers>
    <App />
  </Providers>,
);
