import type { SVGProps } from "react";
export const SvgStarRating = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="215"
    height="35"
    viewBox="0 0 215 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5489 2.92705C16.8483 2.00574 18.1517 2.00574 18.4511 2.92705L21.2045 11.4012C21.3384 11.8132 21.7223 12.0922 22.1555 12.0922L31.0658 12.0922C32.0345 12.0922 32.4373 13.3318 31.6536 13.9012L24.445 19.1385C24.0945 19.3932 23.9479 19.8446 24.0818 20.2566L26.8352 28.7308C27.1345 29.6521 26.0801 30.4182 25.2963 29.8488L18.0878 24.6115C17.7373 24.3568 17.2627 24.3568 16.9122 24.6115L9.70365 29.8488C8.91994 30.4182 7.86546 29.6521 8.16481 28.7307L10.9182 20.2566C11.0521 19.8446 10.9055 19.3932 10.555 19.1385L3.34641 13.9012C2.56269 13.3318 2.96547 12.0922 3.93419 12.0922L12.8445 12.0922C13.2777 12.0922 13.6616 11.8132 13.7955 11.4012L16.5489 2.92705Z"
      fill="#FCB900"
    />
    <path
      d="M61.5489 2.92705C61.8483 2.00574 63.1517 2.00574 63.4511 2.92705L66.2045 11.4012C66.3384 11.8132 66.7223 12.0922 67.1555 12.0922L76.0658 12.0922C77.0345 12.0922 77.4373 13.3318 76.6536 13.9012L69.445 19.1385C69.0945 19.3932 68.9479 19.8446 69.0818 20.2566L71.8352 28.7308C72.1345 29.6521 71.0801 30.4182 70.2963 29.8488L63.0878 24.6115C62.7373 24.3568 62.2627 24.3568 61.9122 24.6115L54.7037 29.8488C53.9199 30.4182 52.8655 29.6521 53.1648 28.7307L55.9182 20.2566C56.0521 19.8446 55.9055 19.3932 55.555 19.1385L48.3464 13.9012C47.5627 13.3318 47.9655 12.0922 48.9342 12.0922L57.8445 12.0922C58.2777 12.0922 58.6616 11.8132 58.7955 11.4012L61.5489 2.92705Z"
      fill="#FCB900"
    />
    <path
      d="M106.549 2.92705C106.848 2.00574 108.152 2.00574 108.451 2.92705L111.204 11.4012C111.338 11.8132 111.722 12.0922 112.156 12.0922L121.066 12.0922C122.035 12.0922 122.437 13.3318 121.654 13.9012L114.445 19.1385C114.095 19.3932 113.948 19.8446 114.082 20.2566L116.835 28.7308C117.135 29.6521 116.08 30.4182 115.296 29.8488L108.088 24.6115C107.737 24.3568 107.263 24.3568 106.912 24.6115L99.7037 29.8488C98.9199 30.4182 97.8655 29.6521 98.1648 28.7307L100.918 20.2566C101.052 19.8446 100.905 19.3932 100.555 19.1385L93.3464 13.9012C92.5627 13.3318 92.9655 12.0922 93.9342 12.0922L102.844 12.0922C103.278 12.0922 103.662 11.8132 103.796 11.4012L106.549 2.92705Z"
      fill="#FCB900"
    />
    <path
      d="M151.549 2.92705C151.848 2.00574 153.152 2.00574 153.451 2.92705L156.204 11.4012C156.338 11.8132 156.722 12.0922 157.156 12.0922L166.066 12.0922C167.035 12.0922 167.437 13.3318 166.654 13.9012L159.445 19.1385C159.095 19.3932 158.948 19.8446 159.082 20.2566L161.835 28.7308C162.135 29.6521 161.08 30.4182 160.296 29.8488L153.088 24.6115C152.737 24.3568 152.263 24.3568 151.912 24.6115L144.704 29.8488C143.92 30.4182 142.865 29.6521 143.165 28.7307L145.918 20.2566C146.052 19.8446 145.905 19.3932 145.555 19.1385L138.346 13.9012C137.563 13.3318 137.965 12.0922 138.934 12.0922L147.844 12.0922C148.278 12.0922 148.662 11.8132 148.796 11.4012L151.549 2.92705Z"
      fill="#FCB900"
    />
    <mask
      id="mask0_996_1533"
      maskUnits="userSpaceOnUse"
      x="182"
      y="2"
      width="31"
      height="29"
    >
      <path
        d="M196.549 2.92705C196.848 2.00574 198.152 2.00574 198.451 2.92705L201.204 11.4012C201.338 11.8132 201.722 12.0922 202.156 12.0922L211.066 12.0922C212.035 12.0922 212.437 13.3318 211.654 13.9012L204.445 19.1385C204.095 19.3932 203.948 19.8446 204.082 20.2566L206.835 28.7308C207.135 29.6521 206.08 30.4182 205.296 29.8488L198.088 24.6115C197.737 24.3568 197.263 24.3568 196.912 24.6115L189.704 29.8488C188.92 30.4182 187.865 29.6521 188.165 28.7307L190.918 20.2566C191.052 19.8446 190.905 19.3932 190.555 19.1385L183.346 13.9012C182.563 13.3318 182.965 12.0922 183.934 12.0922L192.844 12.0922C193.278 12.0922 193.662 11.8132 193.796 11.4012L196.549 2.92705Z"
        fill="#FCB900"
      />
    </mask>
    <g mask="url(#mask0_996_1533)">
      <rect
        x="183"
        width="22"
        height="35"
        fill="#FCB900"
      />
    </g>
  </svg>
);
