import React from 'react';
import ActivityItem from './ActivityItem';
import avatar from "../../assets/images/avatars.png";

interface Props {}

const activityItems = [
    {
        name: "Mark Wahlberg",
        action: "made a monthly payment of $120 for",
        invoiceNumber: "0002",
        date: Date.now(),
        image: avatar
    },
    {
        name: "Brittany Spears",
        action: "made a monthly payment of $120 for",
        invoiceNumber: "0002",
        date: Date.now(),
        image: avatar
    },
    {
        name: "Christian Bale",
        action: "made a monthly payment of $120 for",
        invoiceNumber: "0002",
        date: Date.now(),
        image: avatar
    }
]

const InboxActivity: React.FC<Props> = (props: any) => {
    return(
        <div className="w-[340px] md:w-[744px] flex flex-col mt-[24px] pb-[158px]">
            <div className="flex flex-col">
                <h5 className="text-graySlate text-base font-bold mb-[24px]">Today</h5>
                <div className="flex flex-col gap-[24px]">
                    {activityItems.map(item => (
                        <div className="" key={item.name}>
                            <ActivityItem item={item} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default InboxActivity;
