import React, { FC } from "react"
import PayPalSubscriptionComponent from "../subscription/PayPalSubscriptionComponent"
import StepsLayout from "../../pages/registration/components/StepsLayout"
import { StepType } from "../../pages/registration"

interface Props {
  appliedDiscount: {
    amount: number
    promo_code: string
  }
  createSetupIntent: any
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  selectedPlanFromChild: any
  isIIN: boolean
}

const IINCheckout: FC<Props> = ({
  appliedDiscount,
  createSetupIntent,
  isTermsAgreed,
  setIsTermsAgreed,
  selectedPlanFromChild,
  isIIN,
}) => {
  const redirectUrl = "/IINBusinessBlueprint/success"

  return (
    <StepsLayout isIIN={isIIN} step={StepType.IINCheckout}>
      <div className="bg-white">
        <div>
          <p className="text-4xl mb-2">Checkout</p>
          <p className="text-2xl mb-8">Program begins January 13, 2025</p>
          <PayPalSubscriptionComponent
            appliedDiscount={appliedDiscount}
            createSetupIntent={createSetupIntent}
            isTermsAgreed={isTermsAgreed}
            setIsTermsAgreed={setIsTermsAgreed}
            redirectUrl={redirectUrl}
            selectedPlanFromChild={selectedPlanFromChild}
            isIIN={isIIN}
          />
          <div className="mb-[24px] flex gap-[16px] mt-2">
            <div className="text-graySlate text-base">
              By proceeding, you agree agree to the{" "}
              <a
                href="https://zoee.com/terms-and-conditions"
                className="text-blurple"
                target="_blank"
                rel="noreferrer"
              >
                Agreement Disclosures
              </a>{" "}
              and {/* TODO add href */}
              <a href="#" className="text-blurple">
                Payment Authorization
              </a>
              , and the
              {/* TODO add href */}
              <a href="#" className="text-blurple">
                {" "}
                refund policy{" "}
              </a>
              provided by Zoee
            </div>
          </div>
        </div>
      </div>
    </StepsLayout>
  )
}

export default IINCheckout
