import {
  SvgBundle,
  SvgConsultation,
  SvgRecurring,
  SvgUserCheck,
} from "../../components/icons";

export function getSessionTypeImage(type: string) {
  if (type === "individual") {
    return <SvgUserCheck />;
  } else if (type === "consultation") {
    return <SvgConsultation />;
  } else if (type === "recurring") {
    return <SvgRecurring />;
  } else if (type === "bundle") {
    return <SvgBundle />;
  }
}
