export function getPrice(amount: number, symbol?: string) {
  if (amount === 0) {
    return "Free"
  }

  return `${getCurrencySymbol(symbol)}${parseInt(amount.toString(), 10)}`
}

function getCurrencySymbol(hex?: string) {
  if (hex === undefined) {
    return "$"
  }

  return String.fromCharCode(parseInt(hex.slice(2), 16))
}
