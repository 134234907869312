import type { SVGProps } from "react";
export const SvgMarketplace = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m21.48 8.539-.254-2.586C20.846 3.146 19.596 2 16.913 2H9.895q-.007 0-.014.003L9.87 2H7.086C4.404 2 3.156 3.145 2.77 5.979l-.25 2.56a3.8 3.8 0 0 0 .647 2.55c-.015.06-.035.117-.035.182v4.18c0 4.59 1.922 6.549 6.43 6.549h4.924c4.507 0 6.43-1.959 6.43-6.55v-4.18c0-.082-.02-.159-.047-.232a3.82 3.82 0 0 0 .612-2.5m-11.513.445.548-5.588h2.996l.576 5.862c-.009.517-.177.997-.507 1.368-.775.872-2.332.872-3.107 0-.388-.436-.567-1.02-.506-1.642m-6.085-.307.248-2.534c.29-2.13.954-2.748 2.957-2.748h2.025l-.565 5.757c-.114 1.166-1.183 2.15-2.332 2.15-.695 0-1.32-.272-1.758-.766-.44-.495-.644-1.155-.575-1.859m9.74 11.927h-3.197v-1.627c0-1.172.449-1.628 1.599-1.628s1.598.456 1.598 1.628zm5.924-5.154c0 3.627-1.208 5.019-4.554 5.142v-1.616c0-1.949-1.054-3.023-2.968-3.023s-2.968 1.074-2.968 3.023v1.616c-3.347-.123-4.554-1.516-4.554-5.142v-3.174a3.7 3.7 0 0 0 1.713.422c1.172 0 2.263-.614 2.955-1.538.09.137.177.277.288.402a3.39 3.39 0 0 0 2.569 1.136 3.39 3.39 0 0 0 2.569-1.136c.1-.113.176-.24.26-.364.693.904 1.773 1.5 2.93 1.5.636 0 1.23-.166 1.76-.45zm-.002-4.914c-.44.494-1.064.766-1.759.766-1.15 0-2.217-.984-2.332-2.15l-.004-.038c-.003-.09.009-.177 0-.268l-.535-5.45h2c2.003 0 2.666.616 2.952 2.72l.252 2.56c.07.705-.135 1.364-.574 1.86"
    />
  </svg>
);
