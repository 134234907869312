import React, { useRef } from "react"
import Loader from "../ui/loader"
import PublicPortraitVideo from "./PublicPortraitVideo"
import { Button } from "../ui/button"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { SvgChevronLeft, SvgChevronRight } from "../icons"

interface Props {}

const PublicPortraitVideoDeck: React.FC<Props> = (props) => {
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [videos, setVideos] = React.useState<any[]>([])
  const [loadingVideos, setLoadingVideos] = React.useState<boolean>(false)
  const { coachPublicProfileData } = React.useContext(CoachPublicProfileContext)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -320,
        behavior: "smooth",
      })
    }
  }

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 320,
        behavior: "smooth",
      })
    }
  }

  const getVideos = () => {
    setLoadingVideos(true)
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data) => {
        setVideos(data.videos)
        setLoadingVideos(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingVideos(false)
      })
  }

  React.useEffect(() => {
    getVideos()
  }, [])

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="hidden sm:flex w-[5%]">
          <div
            className="flex h-full items-center justify-center cursor-pointer"
            onClick={scrollLeft}
          >
            <Button size="sm" variant="ghost">
              <SvgChevronLeft />
            </Button>
          </div>
        </div>
        <div className="w-full sm:w-[90%] p-4 md:p-0">
          <div className="relative w-full overflow-hidden">
            <div
              className="scroll-container w-full flex items-center gap-[21px] py-6 px-6 sm:px-2"
              ref={containerRef}
            >
              {loadingVideos ? (
                <Loader />
              ) : (
                <>
                  {videos?.map((video, index) => (
                    <div
                      key={index}
                      className="min-w-[300px] max-w-[301px] h-[462px]"
                    >
                      <PublicPortraitVideo video={video} videos={videos} />
                    </div>
                  ))}
                  {videos?.length === 0 && <div>No videos</div>}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="hidden sm:flex w-[5%]">
          <div
            className="flex h-full items-center justify-center cursor-pointer"
            onClick={scrollRight}
          >
            <Button size="sm" variant="ghost">
              <SvgChevronRight />
            </Button>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex">
        <div
          className="flex h-full w-1/2 items-center justify-center cursor-pointer"
          onClick={scrollLeft}
        >
          <Button size="sm" variant="ghost">
            <SvgChevronLeft />
          </Button>
        </div>
        <div
          className="flex h-full w-1/2 items-center justify-center cursor-pointer"
          onClick={scrollRight}
        >
          <Button size="sm" variant="ghost">
            <SvgChevronRight />
          </Button>
        </div>
      </div>
    </>
  )
}

export default PublicPortraitVideoDeck
