import React from "react";
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { SvgEdit } from "../icons";

export default function ProfileBannerImage() {
  const { setEditBannerImage, previewImgUrl, coachPublicProfileData } =
    React.useContext(CoachPrivateProfileContext);

  return (
    <div className="relative">
      {coachPublicProfileData?.has_banner_image ? (
        <>
          {previewImgUrl ? (
            <div className="relative aspect-[3.5/1] w-full">
              <img
                src={previewImgUrl}
                alt={previewImgUrl}
                className="h-full w-full rounded-xl object-cover"
              />
              <button
                onClick={() => setEditBannerImage(true)}
                className="absolute right-[8px] top-[8px] flex h-[40px] w-[40px] items-center justify-center rounded-[8px] bg-white"
              >
                <SvgEdit />
              </button>
            </div>
          ) : (
            <div className="bg-grayMist relative aspect-[3.5/1] w-full rounded-xl">
              <img
                src={`${
                  coachPublicProfileData?.banner_image
                }?timestamp=${new Date().getTime()}`}
                alt="banner"
                className="h-full w-full rounded-xl object-cover"
              />

              <button
                onClick={() => setEditBannerImage(true)}
                className="absolute right-[8px] top-[80px] flex h-[40px] w-[40px] items-center justify-center rounded-[8px] bg-white md:top-[8px]"
              >
                <SvgEdit />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className="bg-grayMist border-1 border-gray relative mb-16 h-[59px] w-full rounded-[16px] border">
          <span className="absolute right-[70px] top-[50%] -translate-y-[50%] text-[16px] font-bold">
            Add Header Image
          </span>
          <button
            onClick={() => setEditBannerImage(true)}
            className="absolute right-[11px] top-[50%] flex h-[40px] w-[40px] -translate-y-[50%] items-center justify-center rounded-[8px] bg-white"
          >
            <SvgEdit />
          </button>
        </div>
      )}
    </div>
  );
};
