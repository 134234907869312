import {
  SvgBundle,
  SvgConsultation,
  SvgRecurring,
  SvgUserCheck,
} from "../components/icons";
import { SVGProps } from "react";

export const ServiceTypes: Record<
  string,
  {
    title: string;
    description: string;
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  }
> = {
  consultation: {
    title: "Consultation Session",
    description:
      "This is a discovery call, exploration call, or “get to know you” session. These are the calls where you get to know potential clients to see if you're a good fit.",
    Icon: SvgConsultation,
  },
  individual: {
    title: "Single Session",
    description:
      "This is your pay-by-the-hour approach to coaching. It's great for one-off meetings with new or existing clients.",
    Icon: SvgUserCheck,
  },
  bundle: {
    title: "Multiple Sessions",
    description:
      "It's like a bundle, or group of sessions that clients purchase. Think of it like offering a bunch of individual sessions together for clients to prepay and use whenever they want.",
    Icon: SvgBundle,
  },
  recurring: {
    title: "Recurring Sessions",
    description:
      "This is a traditional, outcome-driven program where clients meet with you on a recurring basis. Be sure to outline how frequently and how long you'll meet with clients. (i.e. A 10 week coaching journey where we'll meet once a week.)",
    Icon: SvgRecurring,
  },
};
