import * as React from "react"
import { UserInfo } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { UserCtx } from "../../context/userContext"
import useLoading from "../../hooks/useLoading"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { AuthContext, OnSuccessfulAuthCallback } from "./context"

export function useAuthModal() {
  const { user, clearUser } = React.useContext(UserCtx)
  const { endActiveSession, renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()

  const {
    setOpen,
    setAuthAction,
    setEmail,
    setAccountType,
    setOnSuccessfulAuth,
    setPhone,
    setFirstName,
    setLastName,
  } = React.useContext(AuthContext)

  const showLogin = (options?: {
    email?: string
    onSuccess?: OnSuccessfulAuthCallback
  }) => {
    if (options?.email) {
      setEmail(options.email)
    } else {
      setEmail("")
    }

    if (options?.onSuccess) {
      setOnSuccessfulAuth(() => options.onSuccess)
    } else {
      setOnSuccessfulAuth(undefined)
    }

    setAuthAction("login")
    setOpen(true)
  }

  interface CoachRegisterOptions {
    accountType: "coach"
    email?: string
    onSuccess?: OnSuccessfulAuthCallback
    phone?: string
    firstName?: string
    lastName?: string
  }

  interface RegisterOptions {
    accountType?: "member" | null
    email?: string
    onSuccess?: OnSuccessfulAuthCallback
  }

  const showRegister = (options?: CoachRegisterOptions | RegisterOptions) => {
    if (options?.email) {
      setEmail(options.email)
    } else {
      setEmail("")
    }

    if (options?.accountType) {
      setAccountType(options.accountType)
    } else {
      setAccountType(null)
    }

    if (options?.onSuccess) {
      setOnSuccessfulAuth(() => options.onSuccess)
    } else {
      setOnSuccessfulAuth(undefined)
    }

    if (options?.accountType === "coach") {
      if (options?.phone) {
        setPhone(options.phone)
      } else {
        setPhone("")
      }

      if (options?.firstName) {
        setFirstName(options.firstName)
      } else {
        setFirstName("")
      }

      if (options?.lastName) {
        setLastName(options.lastName)
      } else {
        setLastName("")
      }
    }

    setAuthAction("register")
    setOpen(true)
  }

  const logout = async () => {
    if (user) {
      startLoading()
      await UserInfo.updateNextStepsValues({
        user_id: parseInt(user.user_id),
        active_profile_id: user.activeProfileId,
        created_chat: false,
        profile_access_status: "",
      })
        .then(() => {
          endActiveSession()
        })
        .then(() => {
          if (isLocalStorageAvailable()) {
            localStorage.removeItem("user")
            localStorage.removeItem("welcomeViewed")
            localStorage.removeItem("isNewFeaturesAlertDisplayed")
          }
          clearUser()
          window.location.replace("/")
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  return { showLogin, showRegister, logout }
}
