import type { SVGProps } from "react";
export const SvgVisa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0072FF"
      d="m9.676 8.142-1.233 7.494h1.944l1.234-7.494zM20.917 8.142c-.474 0-.806.143-.996.617l-2.893 6.925h2.04s.331-.949.426-1.138h2.466c.048.284.238 1.138.238 1.138H24l-1.565-7.542zm-.901 4.838c.142-.427.759-2.087.759-2.087l.237-.711.142.664s.38 1.802.474 2.182h-1.612zM15.51 11.178c-.664-.332-1.091-.57-1.091-.949 0-.332.332-.664 1.09-.664.617 0 1.092.143 1.471.285l.19.095.284-1.613A5 5 0 0 0 15.7 8c-1.898 0-3.273 1.043-3.273 2.466 0 1.091.949 1.708 1.707 2.04.76.38.996.617.996.948 0 .522-.616.712-1.185.712-.76 0-1.186-.095-1.85-.38l-.237-.142-.285 1.66c.474.19 1.28.38 2.182.38 2.04 0 3.367-.996 3.367-2.561.048-.76-.474-1.423-1.612-1.945M6.83 8.142l-1.897 5.123-.19-1.044L4.08 8.76c-.095-.474-.474-.617-.901-.617H.095c-.048 0-.048.048-.095.048 0 .047 0 .095.047.095.475.142.902.284 1.281.474.427.19.712.57.854.996l1.565 5.881h2.04l3.035-7.494z"
    />
  </svg>
);
