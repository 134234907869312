import React, { FC } from "react"
import ServiceCardPreview from "./ServiceCardPreview"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests } from "../../api/app.service"
import { useNavigate } from "react-router-dom"
import useScrollToTop from "../../hooks/useScrollToTop"
import useLoading from "../../hooks/useLoading"

interface Props {
  contact: any
}

const EnrollInService: FC<Props> = ({ contact }) => {
  const [services, setServices] = React.useState<any[]>([])
  const { user } = React.useContext(UserCtx)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const navigate = useNavigate()

  const getServices = () => {
    if (user) {
      startLoading()
      ServiceRequests.getServiceListClient({
        profile_id: parseInt(contact.profile_id),
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setServices(data.service_list)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const goToProgramCheckout = (service: any) => {
    if (localStorage.getItem("meetingToSchedule")) {
      localStorage.removeItem("meetingToSchedule")
    }
    navigate(
      `/${user.activeProfile}/contacts/${contact?.profile_id}/services/${service.service_id}`,
      {
        state: { service: service, contact: contact },
      }
    )
  }

  React.useEffect(() => {
    getServices()
  }, [])

  useScrollToTop()

  return (
    <div className="px-[20px] md:px-0 w-full md:w-[1080px] mx-auto flex flex-col">
      <h1 className="hidden md:block font-bold text-[36px] mb-[32px]">
        {contact.contact_name}'s Programs
      </h1>
      <div className="flex flex-wrap justify-center md:justify-start gap-[24px]">
        {services
          ?.filter(
            (service) =>
              service.service_details.status === "active" &&
              service.service_details.visibility.profile.service
          )
          .map((service, idx) => (
            <div className="w-full md:w-[48%]">
              <ServiceCardPreview
                contact={contact}
                key={idx}
                service={service}
                buttonText="Preview Program"
                buttonCallback={() => goToProgramCheckout(service)}
                secondaryButtonCallback={null}
              />
            </div>
          ))}
        {services?.filter(
          (service) =>
            service.service_details.status === "active" &&
            service.service_details.visibility.profile.service
        ).length === 0 && (
          <div
            className="w-full h-[296px] flex items-center justify-center 
                    rounded-[16px] border border-grayMist bg-[#f8f8f8]"
          >
            No Active Services
          </div>
        )}
      </div>
    </div>
  )
}

export default EnrollInService
