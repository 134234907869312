import type { SVGProps } from "react";
export const SvgGrid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.75C3 3.33579 3.33579 3 3.75 3H19.75C20.1642 3 20.5 3.33579 20.5 3.75C20.5 4.16421 20.1642 4.5 19.75 4.5H3.75C3.33579 4.5 3 4.16421 3 3.75ZM3 11.75C3 11.3358 3.33579 11 3.75 11H19.75C20.1642 11 20.5 11.3358 20.5 11.75C20.5 12.1642 20.1642 12.5 19.75 12.5H3.75C3.33579 12.5 3 12.1642 3 11.75ZM3.75 19C3.33579 19 3 19.3358 3 19.75C3 20.1642 3.33579 20.5 3.75 20.5H19.75C20.1642 20.5 20.5 20.1642 20.5 19.75C20.5 19.3358 20.1642 19 19.75 19H3.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 20.0215C3.33579 20.0215 3 19.6857 3 19.2715L3 3.72863C3 3.31441 3.33579 2.97863 3.75 2.97863C4.16421 2.97863 4.5 3.31441 4.5 3.72863L4.5 19.2715C4.5 19.6857 4.16421 20.0215 3.75 20.0215ZM11.75 20.0215C11.3358 20.0215 11 19.6857 11 19.2715L11 3.72863C11 3.31441 11.3358 2.97863 11.75 2.97863C12.1642 2.97863 12.5 3.31441 12.5 3.72863L12.5 19.2715C12.5 19.6857 12.1642 20.0215 11.75 20.0215ZM19 19.2715C19 19.6857 19.3358 20.0215 19.75 20.0215C20.1642 20.0215 20.5 19.6857 20.5 19.2715V3.72863C20.5 3.31441 20.1642 2.97863 19.75 2.97863C19.3358 2.97863 19 3.31441 19 3.72863V19.2715Z"
      fill="currentColor"
    />
  </svg>
);
