import React from "react";
import { Helmet } from "react-helmet";

type Props = {};

const LandingMainVideo = (props: Props) => {
    return (
        <>
            <Helmet>
                <script src="https://fast.wistia.com/embed/medias/da7jerndvs.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
            </Helmet>
            <div className="mx-auto bg-white w-[379px] md:w-[893px] h-[204px] md:h-[481px] rounded-[16px] main-shadow">
                <div
                    className="wistia_responsive_padding rounded-[16px] overflow-hidden"
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                >
                    <div
                        className="wistia_responsive_wrapper"
                        style={{
                            height: "100%",
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: "100%",
                        }}
                    >
                        <div
                            className="wistia_embed wistia_async_da7jerndvs videoFoam=true"
                            style={{ height: "100%", position: "relative", width: "100%" }}
                        >
                            <div
                                className="wistia_swatch"
                                style={{
                                    height: "100%",
                                    left: 0,
                                    opacity: 0,
                                    overflow: "hidden",
                                    position: "absolute",
                                    top: 0,
                                    transition: "opacity 200ms",
                                    width: "100%",
                                }}
                            >
                                <img
                                    src="https://fast.wistia.com/embed/medias/da7jerndvs/swatch"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "contain",
                                        filter: "blur(5px)",
                                    }}
                                    alt=""
                                    aria-hidden="true"
                                    // onLoad="this.parentNode.style.opacity=1;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LandingMainVideo;
