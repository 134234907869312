import type { SVGProps } from "react";
export const SvgInviteSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.413 27.574c-.593 0-1.073-.48-1.073-1.087V13.07l9.636 8.637a4.03 4.03 0 0 0 2.717 1.042c.972 0 1.943-.352 2.69-1.042l9.646-8.636v4.368a8.9 8.9 0 0 1 3.007.62v-5.646c0-1.101-.452-2.146-1.27-2.95a4.1 4.1 0 0 0-2.796-1.13H7.413c-1.045 0-2.047.41-2.823 1.144-.805.79-1.257 1.835-1.257 2.936v14.074a4.08 4.08 0 0 0 4.08 4.08h14.71a9.3 9.3 0 0 1-.777-2.993z"
    />
    <path
      fill="currentColor"
      d="M30.676 20.248c-3.614 0-6.536 2.95-6.536 6.535a6.534 6.534 0 0 0 6.536 6.55 6.534 6.534 0 0 0 6.536-6.55c0-3.585-2.922-6.535-6.536-6.535m2.908 6.296-3.289 2.202a1.1 1.1 0 0 1-.593.169 1.06 1.06 0 0 1-.861-.452l-1.13-1.609a1.06 1.06 0 0 1 .269-1.468 1.044 1.044 0 0 1 1.468.254l.537.762 2.427-1.623a1.066 1.066 0 0 1 1.468.296 1.05 1.05 0 0 1-.296 1.469"
    />
  </svg>
);
