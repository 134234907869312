import React, { useState, useCallback } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor } from "slate";
import Element from "./Editor/Element";
import Leaf from "./Editor/Leaf";
import { UserCtx } from "../../context/userContext";
import moment from "moment";
import { useParams, useLocation } from "react-router-dom";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { NoteRequests, ContactRequests } from "../../api/app.service";
import { Note } from "../../models/app.interface";

const NotesPDFPreview: React.FC = () => {
    const { user } = React.useContext(UserCtx);
    const renderElement = useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
    const [editor] = useState(() => withReact(createEditor()));
    const location = useLocation();
    const [notes, setNotes] = React.useState<Note[]>([]);
    const [contact, setContact] = React.useState<any>({});
    const { renderError } = React.useContext(CommonFunctionCtx);

    // Get contact profile ID from route slug
    const { contact_id } = useParams();

    // Get notes and contact from useLocation if user navigated from contact profile
    let state: any = null;
    if (location && location.state) {
        state = location.state;
    }
    React.useEffect(() => {
        if (state && state.notes) {
            setNotes(state.notes);
        } else {
            // Fetch notes if no notes data were passed from navigation (i.e. user navigates directly to route url)
            getMemberNotes();
        }

        if (state && state.contact) {
            setContact(state.contact);
        } else {
            // Fetch contact info if no contact data were passed from navigation (i.e. user navigates directly to route url)
            if (contact_id) {
                getContact(contact_id);
            }
        }
    }, [state]);

    const getContact = (profileId: string) => {
        if (user?.coachProfile || user?.memberProfile) {
            ContactRequests.getContactList({
                profile_id: user.activeProfileId,
                profile_type: user.activeProfile,
            })
                .then((data) => {
                    const foundContact = data.contact_list.find((c: any) => {
                        return c.profile_id === parseInt(profileId);
                    });

                    if (foundContact.contact_type === "connected") {
                        setContact(foundContact);
                    }
                })
                .catch((ex) => {
                    renderError(ex.response.data.message);
                });
        }
    };

    const getMemberNotes = () => {
        if (user?.coachProfile && contact_id) {
            NoteRequests.getMemberNotes({
                writer_profile_id: user.activeProfileId,
                relates_to_profile_id: parseInt(contact_id),
            })
                .then((data) => {
                    setNotes(data.notes);
                })
                .catch((ex) => {
                    renderError(ex.response.data.message);
                });
        }
    };

    const openSaveAsPDFDialogue = () => {
        window.print();
    };

    return (
        <div className="absolute top-0 left-0 w-full min-w-screen min-h-screen z-[8000] bg-white px-[12px] md:px-[24px] py-[24px] md:py-[40px]">
            <div className="mb-[16px] flex w-full items-center gap-[20px]">
                <h1 className="px-[20px] font-bold text-[20px]">
                    {user.firstName} {user.lastName}'s notes for {contact.contact_name}
                </h1>
                <button
                    onClick={openSaveAsPDFDialogue}
                    className="print:hidden btn-primary btn-blue"
                >
                    Save as PDF
                </button>
            </div>
            {notes.map((note) => {
                return (
                    <>
                        <div key={note.id} className="mb-[32px] px-[20px] w-full">
                            <h3 className="font-bold mb-[4px]">{note.title}</h3>
                            <div className="mb-[24px] flex gap-[10px]">
                                <p className="text-graySlate text-base">
                                    {moment
                                        .utc(note.created_at)
                                        .tz(user.timezone.value)
                                        .format("MM/DD/YYYY")}
                                </p>
                                {!(note.created_at === note.updated_at) && (
                                    <p className="text-graySlate text-base">
                                        (updated:{" "}
                                        {moment
                                            .utc(note.updated_at)
                                            .tz(user.timezone.value)
                                            .format("MM/DD/YYYY")}
                                        )
                                    </p>
                                )}
                            </div>
                            <Slate editor={editor} value={note.content}>
                                <Editable
                                    readOnly={true}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                />
                            </Slate>
                            <div className="mt-[24px] w-full h-[1px] bg-grayCloud px-[20px]" />
                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default NotesPDFPreview;
