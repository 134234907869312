import type { SVGProps } from "react";
export const SvgSpeakerView = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.3 2h1.4v5.7H2V6.3C2 3.9 3.9 2 6.3 2M14.4 2H9.7v5.7h4.7zM22 6.3v1.4h-5.7V2h1.4C20.1 2 22 3.9 22 6.3M22 10H2v8a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4z"
    />
  </svg>
);
