const createIdGenerator = (() => {
  let currentId = 1

  return () => currentId++
})()

export const faqs = [
  {
    id: createIdGenerator(),
    title: "Do I need a website to be on Zoee?",
    content: (
      <div>
        <p className="mb-4 text-left">
          Not at all – Zoee's got you covered! No need to wrestle with website
          builders or burn the midnight oil coding. Your Zoee profile is like a
          Swiss Army knife for your coaching biz.
        </p>
        <div className="space-y-2">
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Seamless booking system?
            Check!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Built-in messaging? You
            bet!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Your own blog space? Of
            course!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Video uploads? Upload
            away!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Showcase your
            credentials? Flaunt 'em!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Client testimonials? Let
            'em sing your praises!
          </p>
          <p className="flex items-start">
            <span className="text-[18px] mr-2">•</span> Highlight your skills
            and expertise? Show off, coach!
          </p>
        </div>
        <p className="mt-4 text-left">
          With Zoee, you're not just getting a profile – you're getting a
          full-blown digital storefront for your coaching business, minus the
          eye-watering web design bills. Say goodbye to domain fees, hosting
          costs, and pricey developers. Who needs a separate website when you've
          got all this awesomeness in one place?
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "I already have a website. Can I still use Zoee?",
    content: (
      <div>
        <p>
          You bet! Zoee plays nice with others – use us to supercharge your
          online presence, fill schedule gaps, and reel in more clients. It's
          like having a turbo boost for your coaching business!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Show me the money! How do I get paid?",
    content: (
      <div>
        <p>
          Cha-ching! We've partnered with PayPal, so you can accept payments
          from over 45 countries. New to PayPal? Your first payout might take a
          week, but after that, you could be rolling in dough as fast as 2 days!
          Check out PayPal's support page for the nitty-gritty details.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "What can I sell on Zoee?",
    content: (
      <div>
        <p>
          If it's coaching, we're all ears! Physical, financial, mental,
          emotional, business, spiritual health – you name it, we've got space
          for it. One-on-one sessions, group classes, whatever floats your
          coaching boat!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How can I become a client magnet on Zoee?",
    content: (
      <div>
        <p>
          Time to flex those coaching muscles! Beef up your profile with all
          your awesome services, certifications, and secret sauce. Spread your
          offerings across categories to cast a wider net. Want to really turn
          heads? Try some promotional deals. Grab our onboarding guide for more
          pro tips!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Can I mix it up with virtual and in-person services?",
    content: (
      <div>
        <p>
          Absolutely! Zoee's all about flexibility. Cook up a storm of
          consultations, appointments, groups, and events – serve them virtual,
          in-person, or even hybrid-style. Whatever works for you and your
          clients!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Does Zoee put coaches through the wringer?",
    content: (
      <div>
        <p>
          We trust you've got the goods! Given the wild world of varying
          licenses, certifications, and regulations, we don't set a bar for
          minimum certification. You do you, coach!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How long can I test drive Zoee for free?",
    content: (
      <div>
        <p>
          Take Zoee for a 14-day joy ride, on the house! You get the full VIP
          experience during your trial. After that, it's time to join the club
          officially.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title:
      "I've got sessions booked with current clients. Can I bring them to the Zoee party?",
    content: (
      <div>
        <p>
          Bring 'em all! Zoee plays well with others – we'll sync up with your
          calendar (Google, Outlook, Office 365, Apple iCalendar – we speak
          their language). Invite your clients to the Zoee playground and
          schedule new sessions right in your account. It's a piece of cake!{" "}
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Can someone hold my hand while I set up my account?",
    content: (
      <div>
        <p>
          We've got you covered! Our team's ready to be your personal
          cheerleader. Kick off your free trial, and we'll set you up with a
          one-on-one onboarding call. Need more? Email hello@zoee.com or chat
          with us anytime.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title:
      "I'm a coaching newbie with no clients yet. Can I still join the Zoee family?",
    content: (
      <div>
        <p>
          Welcome to the coaching world, rookie! Whether you're a seasoned pro
          or fresh off the bench, Zoee's doors are wide open. Our CEO's a
          certified coach too, so you're in for some grade-A insider tips,
          webinars, and resources. You'll be coaching like a pro in no time!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "What if I need to cancel?",
    content: (
      <div>
        <p>
          We'd miss you, but we get it – life happens. You can cancel anytime,
          and your current plan will stick around until the end of your billing
          cycle. No hard feelings, promise!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "What's the deal with refunds?",
    content: (
      <div>
        <p>
          Your satisfaction is our jam! While you can cancel anytime, we know
          sometimes life throws curveballs. If you're in a pickle, reach out to
          our concierge at support@zoee.com, and we'll see about hooking you up
          with a full refund.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How can I get in touch?",
    content: (
      <div>
        <p>
          We're all ears! Shoot us an email at support@zoee.com. We can't wait
          to chat!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How do fees work on Zoee?",
    content: (
      <div>
        <p>
          We keep it simple and packed with value! For just $49/month, you get
          unlimited access to Zoee's full suite of tools to streamline your
          coaching practice 24/7:
        </p>
        <ul className="list-disc list-inside mb-4 mt-4">
          <li>Book private and group sessions automatically</li>
          <li>Two-way sync calendars effortlessly</li>
          <li>Send forms with ease</li>
          <li>Conduct video calls and centralize communication</li>
          <li>Manage clients securely</li>
          <li>Create a professional web presence</li>
          <li>Sell your services</li>
          <li>Join a Community of Coaching Stars</li>
        </ul>
        <p>
          No sneaky fees, no contracts, no sign-up or cancellation costs. It's
          all about empowering your coaching journey!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "What can I bring to the Zoee table?",
    content: (
      <div>
        <p>
          Sky's the limit! Whether you're a virtual guru or an in-person wizard,
          Zoee's got your back. We'll showcase your talents to a world of eager
          clients!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Is Zoee always open for business?",
    content: (
      <div>
        <p>
          You bet! Our booking system never sleeps, so your clients can
          booktheir "aha!" moments anytime, anywhere.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How does Zoee help me win clients' hearts?",
    content: (
      <div>
        <p>
          Time to shine! Your Zoee profile is your stage. Flaunt your skills,
          share your story, and let your testimonials do the talking. Clients
          can watch, read, chat, and book – all in one place!
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How can I steal the spotlight on Zoee?",
    content: (
      <div>
        <p>
          Get ready for your close-up! Our featured spots put you center stage,
          connecting you with hundreds of potential clients who are actively
          seeking your expertise.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "How does Zoee keep things crystal clear?",
    content: (
      <div>
        <p>
          We're all about the real deal! Showcase your services, prices, and
          credentials with pride. Add a dash of personality with welcome videos
          and glowing testimonials. We give clients all the info they need to
          make smart choices.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "Can clients shop with peace of mind?",
    content: (
      <div>
        <p>
          Absolutely! When clients book with a Zoee coach, they're not just
          getting a service – they're embarking on a transformative journey with
          a passionate pro.
        </p>
      </div>
    ),
  },
  {
    id: createIdGenerator(),
    title: "What's in it for me as a coach?",
    content: (
      <div>
        <p>
          Say goodbye to scheduling headaches! Zoee's smart tech syncs your
          calendar, zaps timezone confusion, and banishes double bookings. Plus,
          clients come prepared with pre-filled intake forms. It's smooth
          sailing all the way!
        </p>
      </div>
    ),
  },
  {
    id: 22,
    title: "How does Zoee handle the money stuff?",
    content: (
      <div>
        <p>
          We've got your back (and your wallet)! With PayPal integration, every
          transaction is fortress-level secure. Plus, we offer flexible payment
          options to keep your clients smiling.
        </p>
      </div>
    ),
  },
]
