import React, { useState } from "react"
import { UserCtx } from "../../context/userContext"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { Button } from "../ui/button"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import CountDownTimer from "../CountDownTimer"
import { SvgClose } from "../icons/"
import { pluralize } from "../../utils/pluralize"

export default function SubscriptionCtaBanner() {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const {
    setShowSubscriptionCheckout,
    setShowSubscribeBanner,
    activeSubscriptionType,
  } = React.useContext(SubscriptionCtx)
  const [timerExpired, setTimerExpired] = useState(false)

  const getTrialDaysRemaining = () => {
    if (!user) return 0

    const expiration = moment(user?.trialExpiration)
    const now = moment(Date.now())

    if (expiration.diff(now, "days") <= 0) {
      return 0
    }

    return expiration.diff(now, "days")
  }

  const showQuickCheckoutModal = () => {
    navigate("/coach/subscription")
  }
  //  COMMENTED OUT FOR LATER USE

  // const setBannerText = () => {
  //   if (activeSubscriptionType === 'trial') {
  //     return `Special Offer! Get your first month for only $1!`
  //   } else if (activeSubscriptionType === 'paid'|| activeSubscriptionType === 'promoMonth') {
  //     return `Get a lifetime subscription for $500!`
  //   }
  //   return `2-DAY VIRTUAL COACHING BUSINESS BOOTCAMP MAY 29-30th. Early Bird Special available only now - May 12th`
  // }

  // const setBannerButtonText = () => {
  //   if (activeSubscriptionType === 'trial') {
  //     return `Subscribe Now for $1`
  //   }
  //   return ``
  // }

  return (
    <>
      {user && user.activeProfile === "coach" && (
        <section className="subscription-cta-gradient relative py-4 pr-4">
          <div className="flex flex-col lg:flex-row items-center gap-4 lg:gap-32 justify-center">
            {activeSubscriptionType === "trial" &&
              getTrialDaysRemaining() > 0 && (
                <div className="flex flex-col sm:flex-row items-center justify-center gap-2 sm:gap-4 text-center sm:text-left">
                  <span className="text-white rounded-full bg-black font-bold px-3 py-2 min-w-[40px] sm:min-w-[50px] text-center">
                    {pluralize(getTrialDaysRemaining(), "day")}
                  </span>
                  <span className="text-sm sm:text-base">
                    {`Hurry! Only ${pluralize(
                      getTrialDaysRemaining(),
                      "day"
                    )} left to unlock all of Zoee's premium features!`}
                  </span>
                  <Button
                    className="primary bg-magenta"
                    size="sm"
                    onClick={showQuickCheckoutModal}
                  >
                    Upgrade Now
                  </Button>
                </div>
              )}
          </div>
          {/* <Button
            className="right-2.5 top-1/2 -translate-y-1/2 absolute rounded-full"
            onClick={() => setShowSubscribeBanner(false)}
            size="icon"
            variant="ghost"
          ></Button> */}
        </section>
      )}
    </>
  )
}
