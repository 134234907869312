import React from "react";
import { Meeting } from "../../../types/meetingTypes";
import ZoeeSessionDetailView from "./ZoeeSessionDetailView";
import OtherCalendarSessionDetailView from "./OtherCalendarSessionDetailView";
import useScrollToTop from "../../../hooks/useScrollToTop";
import BackToButton from "../../BackToButton";
import { useLocation, useNavigate } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";

type Props = {
  meeting: Meeting | undefined;
  setActiveSessionDetailViewId: any;
  getMeetings: any;
  setNewMeetingType: any;
  showRescheduleMeeting: boolean;
  setShowRescheduleMeeting: any;
  clickReschedule: boolean;
  setClickReschedule: any;
  clickTodayReschedule: boolean;
  backToContacts: boolean;
  contact: any;
  keepBackToButton: boolean;
};

const SessionDetail: React.FC<Props> = ({
  meeting,
  setActiveSessionDetailViewId,
  getMeetings,
  setNewMeetingType,
  showRescheduleMeeting,
  setShowRescheduleMeeting,
  clickReschedule,
  setClickReschedule,
  clickTodayReschedule,
  backToContacts,
  contact,
  keepBackToButton,
}) => {
  const [hideBackToLink, setHideBackToLink] = React.useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = React.useContext(UserCtx);


  useScrollToTop();

  const handleBackClick = () => {
    setActiveSessionDetailViewId(null);
    navigate(`/${user.activeProfile}/scheduling`);
  };

  return (
    <div className="mb-[64px]">
      {meeting === undefined ? (
        <div className="flex flex-col w-[744px] mt-[40px]">
          <BackToButton
            onClick={handleBackClick}
            current="Calendar"
            prev="Calendar"
          />
          <h3>Session Not Found</h3>
        </div>
      ) : (
        <div className="flex flex-col -mt-0 md:-mt-[55px]">
          {!hideBackToLink && (
            <BackToButton
              onClick={handleBackClick}
              current="Calendar"
              prev="Calendar"
            />
          )}
          {meeting.is_managed && meeting.belongs_to_profile ? (
            <ZoeeSessionDetailView
              setHideBackToLink={setHideBackToLink}
              meeting={meeting}
              setActiveSessionDetailViewId={setActiveSessionDetailViewId}
              getMeetings={getMeetings}
              setNewMeetingType={setNewMeetingType}
              showRescheduleMeeting={showRescheduleMeeting}
              setShowRescheduleMeeting={setShowRescheduleMeeting}
              clickReschedule={clickReschedule}
              setClickReschedule={setClickReschedule}
              clickTodayReschedule={clickTodayReschedule}
              backToContacts={backToContacts}
              contact={contact}
              keepBackToButton={keepBackToButton}
            />
          ) : (
            <OtherCalendarSessionDetailView meeting={meeting} />
          )}
        </div>
      )}
    </div>
  );
};

export default SessionDetail;
