import type { SVGProps } from "react";
export const SvgUserCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#8ED1FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3087 12.4392C19.3087 14.3483 17.8369 15.879 16 15.879C14.1637 15.879 12.6913 14.3483 12.6913 12.4392C12.6913 10.5301 14.1637 9 16 9C17.8369 9 19.3087 10.5301 19.3087 12.4392ZM16 21.9999C13.289 21.9999 11 21.5417 11 19.7737C11 18.005 13.3034 17.563 16 17.563C18.7116 17.563 21 18.0212 21 19.7893C21 21.5579 18.6966 21.9999 16 21.9999Z"
      fill="#130F26"
    />
  </svg>
);
