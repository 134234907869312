import React, { useEffect, useRef } from "react"

interface ButtonProps {
  createOrder: (data: any, actions: any) => Promise<any>
  onApprove: (data: any, actions: any) => Promise<any>
}

export const PayPalButton: React.FC<ButtonProps> = ({
  createOrder,
  onApprove,
}) => {
  const paypalButtonRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (window.paypal && paypalButtonRef.current) {
      paypalButtonRef.current.innerHTML = ""

      window.paypal
        .Buttons({
          createOrder: (data: any) => createOrder(data, "paypal"),
          onApprove: (data: any) => onApprove(data, "paypal"),
        })
        .render(paypalButtonRef.current)
    }
  }, [createOrder, onApprove])

  return (
    <div
      id="paypal-button-container"
      className="paypal-button-container"
      ref={paypalButtonRef}
    ></div>
  )
}
