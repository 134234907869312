import * as React from "react";
import * as z from "zod";
import { EmailLogin, VerifyConfirmationCode } from "../../../api/auth.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { getUserStats } from "../../../utils/getUserStats";
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable";
import { Button } from "../../ui/button";
import { LoginFormContext } from "./context";
import Loader from "../../ui/loader";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../ui/input-otp";
import { Label } from "../../ui/label";
import { FieldError } from "../../ui/field-error";

export const OneTimePasswordForm: React.FC = () => {
  const {
    oneTimePassword,
    setOneTimePassword,
    setView,
    email,
    getIdentityStatus,
    getSetupInfo,
    updateTimezone,
    onSuccess,
  } = React.useContext(
    LoginFormContext,
  );

  const [error, setError] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [sessionData, setSessionData] = React.useState<any>({});
  const [disableResend, setDisableResend] = React.useState(false);

  const { renderError, getUser } = React.useContext(CommonFunctionCtx);

  const sendOneTimePasscode = () => {
    setOneTimePassword("");
    setDisableResend(true);
    setLoading(true);
    EmailLogin.loginOTP({ email })
      .then((data) => {
        setSessionData(data);
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => {
        setLoading(false);

        setTimeout(() => {
          setDisableResend(false);
        }, 15000);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const oneTimePasswordSchema = z.string().length(6, {
      message: "Must be exactly 6 digits long",
    }).regex(/^\d+$/, {
      message: "Must contain only digits",
    }).safeParse(oneTimePassword);

    if (!oneTimePasswordSchema.success) {
      setError(oneTimePasswordSchema.error.issues[0].message);
      setLoading(false);
      return;
    }

    await login();
    setLoading(false);
  };

  const login = async () => {
    try {
      const data = await VerifyConfirmationCode.verifyConfirmationCode({
        confirmation_code: oneTimePassword,
        session: sessionData.session,
        user_id: sessionData.user_id,
        stats: getUserStats(),
      });

      if (data) {
        if (isLocalStorageAvailable()) {
          localStorage.setItem("user", JSON.stringify(data));
        } else {
          renderError("Local storage is not available");
        }

        await getIdentityStatus(data.user_id);
        await getSetupInfo(data.user_id);
        await updateTimezone(data.user_id, data.access_token);
        const user = await getUser();

        onSuccess(user);
      }
    } catch (error: any) {
      console.error(error);
      if (
        error.response.data.message ===
          "Error: OTP authentication. User login failed."
      ) {
        setError("Incorrect password");
        setOneTimePassword("");
      } else {
        console.error(error);
        renderError(error.response.data.message);
      }
    }
  };

  React.useEffect(() => {
    if (!disableResend) {
      sendOneTimePasscode();
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
    >
      <div className="space-y-2 mx-auto flex flex-col">
        <Label htmlFor="otp" className="text-center">
          Enter the code sent to your email
        </Label>
        <InputOTP
          id="otp"
          maxLength={6}
          value={oneTimePassword}
          onChange={(value) => setOneTimePassword(value)}
          disabled={loading}
          autoFocus
        >
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
            <InputOTPSlot index={3} />
            <InputOTPSlot index={4} />
            <InputOTPSlot index={5} />
          </InputOTPGroup>
        </InputOTP>

        <FieldError error={error} />
      </div>

      <Button className="w-full" disabled={loading}>
        {loading ? <Loader /> : "Sign In"}
      </Button>

      <div className="flex flex-col gap-2">
        <Button
          variant="link"
          type="button"
          onClick={() => sendOneTimePasscode()}
          disabled={disableResend}
        >
          Resend One-time Password
        </Button>
        <div className="flex items-center gap-4">
          <div className="h-px bg-border flex-1"></div>
          <span className="text-sm">or</span>
          <div className="h-px bg-border flex-1"></div>
        </div>
        <Button
          variant="link"
          type="button"
          onClick={() => setView("password")}
        >
          Enter Password
        </Button>
      </div>
    </form>
  );
};
