import { FC } from "react";

type Props = {
    variation:string;
};

const LandingCallToAction:FC<Props> = ({variation}) => {
    const handleClickScroll = () => {
        const element = document.getElementById("coachCreateForm");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }

        const emailElement = document.getElementById("createEmailInput");
        if (emailElement) {
            setTimeout(() => {
                emailElement.focus();
            }, 1000);
        }
    };

    const getButtonBackground = () => {
        switch (variation) {
            case "normal":
                return "#FCB900"
            case "moneyflow":
                return "#FCB900"
            default:
                break;
        }
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <h2 className="xl:w-[912px] lg:w-[660px] lg:h-auto h-[188px] flex flex-col lg:flex-row lg:justify-start justify-between lg:items-start items-center font-bold xl:text-[88px] md:text-[64px] text-[58px] leading-[103%] mb-[24px]">
                    <div>YOU</div>
                    <div className="translate-x-[16px] hidden xl:block">
                        <span className="inverted-text-stroke italic">DESERVE</span>
                        <span className="inverted-text-no-stroke italic">DESERVE</span>
                    </div>
                    <div className="translate-x-[16px] hidden lg:block xl:hidden">
                        <span className="inverted-text-stroke-medium italic">DESERVE</span>
                        <span className="inverted-text-no-stroke-medium italic">DESERVE</span>
                    </div>
                    <div className="lg:hidden translate-y-[-32px] translate-x-[-136px]">
                        <span className="inverted-text-stroke-medium italic">DESERVE</span>
                        <span className="inverted-text-no-stroke-medium italic">DESERVE</span>
                    </div>
                    <div className="xl:translate-x-[410px] lg:translate-x-[310px]">MORE</div>
                </h2>

                <h3 className="text-[28px] md:text-[32px] leading-[32px] md:leading-[36px] mb-[32px] md:mb-[48px] font-bold text-center max-w-[666px]">
                    We specifically designed the Zoee platform with you, the coach, in mind.
                </h3>
                <div className="flex justify-center">
                    {/* TODO: Smooth scroll to top form, focus form email */}
                    <button
                        onClick={handleClickScroll}
                        className="cursor-pointer mx-auto text-white font-semibold text-base px-[49px] py-[13px] flex items-center justify-center rounded-[100px]"
                        style={{backgroundColor: getButtonBackground()}}
                    >
                        {variation === "moneyflow" ? (
                            <>Start free trial now</>
                        ) : (
                            <>Try Zoee Now</>
                        )}
                        
                    </button>
                </div>
            </div>
        </>
    );
};

export default LandingCallToAction;
