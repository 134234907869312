import type { SVGProps } from "react";
export const SvgMinimize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.3 22c-.6 0-1-.4-1-1v-2.5c0-2.4 1.9-4.3 4.3-4.3H21c.6 0 1 .4 1 1s-.4 1-1 1h-2.5c-1.3 0-2.3 1-2.3 2.3V21c.1.6-.4 1-.9 1M8.7 22c-.6 0-1-.4-1-1v-2.5c0-1.3-1-2.3-2.3-2.3H3c-.6 0-1-.4-1-1s.4-1 1-1h2.5c2.4 0 4.3 1.9 4.3 4.3V21c-.1.6-.5 1-1.1 1M5.5 9.7H3c-.6 0-1-.4-1-1s.4-1 1-1h2.5c1.3 0 2.3-1 2.3-2.3V3c0-.6.4-1 1-1s1 .4 1 1v2.5c-.1 2.3-2 4.2-4.3 4.2M21 9.7h-2.5c-2.4 0-4.3-1.9-4.3-4.3V3c0-.6.4-1 1-1s1 .4 1 1v2.5c0 1.3 1 2.3 2.3 2.3H21c.6 0 1 .4 1 1s-.4.9-1 .9"
    />
  </svg>
);
