import type { SVGProps } from "react";
export const SvgSolidCheckCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#E1F0EA"
    />
    <path
      d="M21.7781 9.11933C21.5413 8.94448 21.1862 8.94448 20.9494 9.2359L19.233 11.3341C18.2861 10.693 17.1615 10.3433 15.9186 10.3433C12.6634 10.3433 10 12.966 10 16.1716C10 19.3772 12.6634 22 15.9186 22C19.1739 22 21.8372 19.3772 21.8372 16.1716C21.8372 15.006 21.4821 13.9569 20.8903 13.0243L20.476 13.4906L20.0617 13.9569L16.6289 18.2116C16.5105 18.3281 16.3329 18.4447 16.1554 18.4447C15.9778 18.4447 15.8594 18.3864 15.7411 18.2699L13.1961 15.5888C13.0185 15.414 13.0185 15.1225 13.0777 14.9477C13.0777 14.8894 13.1369 14.8311 13.1961 14.7728C13.4328 14.5397 13.7879 14.5397 14.0247 14.7728L16.0962 16.9876L19.3514 12.966L19.7065 12.4998L20.0617 12.0335L21.7781 9.9353C22.074 9.70216 22.074 9.35246 21.7781 9.11933Z"
      fill="black"
    />
  </svg>
);
