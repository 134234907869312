import type { SVGProps } from "react";
export const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.25 7.124 14.363 4.02c-1.311-.691-3.37-.694-4.685-.005L3.758 7.12C2.657 7.697 2 8.672 2 9.728s.657 2.031 1.758 2.609l1.24.65-.009 3.573c-.002 1.261 1.162 2.562 2.65 2.96l3.134.84c.35.094.787.14 1.225.14s.876-.046 1.225-.14l3.138-.84c1.486-.4 2.65-1.696 2.65-2.956v-3.578l1.24-.653c.09-.048.173-.103.258-.156v2.195c0 .327.33.593.738.593.407 0 .738-.266.738-.593V9.92c.005-.064.015-.128.015-.193 0-1.053-.654-2.027-1.75-2.604m-2.714 9.44c0 .74-.767 1.596-1.641 1.83l-3.137.84c-.398.107-1.121.107-1.518 0l-3.134-.84c-.875-.233-1.642-1.09-1.64-1.831l.007-2.802 3.205 1.68c.656.344 1.496.516 2.337.516.845 0 1.69-.173 2.347-.52l3.174-1.673zm2.974-6.737c-.038.62-.415 1.17-1.068 1.514l-5.89 3.104c-.817.431-2.25.433-3.068.003l-5.92-3.104c-.691-.363-1.088-.952-1.088-1.616s.397-1.252 1.088-1.615l5.92-3.105c.408-.214.969-.32 1.53-.32.564 0 1.13.108 1.539.323l5.888 3.104c.654.345 1.03.894 1.069 1.515z"
    />
  </svg>
);
