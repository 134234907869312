import type { SVGProps } from "react";
export const SvgBusiness = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.64 7.101c-.812-.929-2.159-1.38-4.118-1.38h-.226v-.046c0-1.637 0-3.675-3.573-3.675h-1.447C7.705 2 7.705 4.038 7.705 5.675v.046h-.226c-1.96 0-3.307.451-4.118 1.38-.942 1.079-.913 2.52-.81 3.5l.678 7.452C3.431 20.004 4.203 22 8.383 22h7.235c4.18 0 4.95-1.996 5.155-3.957l.677-7.432c.104-.99.132-2.431-.81-3.51M9.06 5.675c0-1.66 0-2.28 2.217-2.28h1.446c2.216 0 2.216.62 2.216 2.28v.046H9.061zm10.363 12.228c-.154 1.478-.547 2.701-3.806 2.701H8.382c-3.259 0-3.652-1.223-3.805-2.691l-.492-5.406a15.3 15.3 0 0 0 5.447 1.987c.075.895.463 1.925 2.468 1.925s2.393-1.033 2.468-1.941a15.4 15.4 0 0 0 5.453-2.039zm-8.554-4.015v-.958l.001-.231.226-.001h2.033l.001.232v.95c0 .99 0 1.143-1.13 1.143s-1.13-.173-1.13-1.135m9.232-3.417-.02.207a14.1 14.1 0 0 1-5.594 2.385v-.133c0-1.308-.31-1.628-1.583-1.628h-1.809c-1.272 0-1.582.32-1.582 1.628v.152a14 14 0 0 1-5.587-2.323l-.027-.297c-.12-1.151.029-1.923.47-2.428.532-.609 1.578-.918 3.11-.918h9.042c1.532 0 2.578.31 3.11.918.44.505.59 1.277.47 2.437"
    />
  </svg>
);
