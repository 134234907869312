import React from "react"
import {
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom"
import { UserCtx } from "../../context/userContext"

const PrivateRouteMember = () => {
  const { user } = React.useContext(UserCtx)
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const keys = Array.from(searchParams.keys())
  const params: any = {}
  keys.forEach((key) => {
    params[key] = searchParams.get(key)
  })

  return user ? (
    !user?.isCoach ? (
      <Outlet />
    ) : (
      <Navigate to="/coach" />
    )
  ) : (
    <Navigate
      to="/direct-routes"
      state={{
        storageAvailable: true,
        pathname: location.pathname,
        searchParams: params,
      }}
    />
  )
}

export default PrivateRouteMember
