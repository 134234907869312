import type { SVGProps } from "react";
export const SvgImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        d="M2.75 12c0 6.937 2.313 9.25 9.25 9.25s9.25-2.313 9.25-9.25S18.937 2.75 12 2.75 2.75 5.063 2.75 12"
        clipRule="evenodd"
      />
      <path
        d="M10.599 8.784a1.76 1.76 0 1 1-3.521.001 1.76 1.76 0 0 1 3.52 0"
        clipRule="evenodd"
      />
      <path d="M21.12 14.667c-.88-.906-2.127-2.737-4.416-2.737s-2.339 4.038-4.675 4.038c-2.337 0-3.278-1.371-4.8-.655-1.523.715-2.763 3.56-2.763 3.56" />
    </g>
  </svg>
);
