import type { SVGProps } from "react";
export const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.999 12.135H9.903M18.302 9.448 21 12.135l-2.698 2.687M15.994 7.997c-.304-3.3-1.539-4.497-6.45-4.497C3 3.5 3 5.629 3 12.024s0 8.524 6.544 8.524c4.911 0 6.146-1.198 6.45-4.497"
    />
  </svg>
);
