import React, { FC } from "react";
import LinkedFormsItem from "./LinkedFormsItem";
import { FormLink } from "../../models/app.interface";
import { Button } from "../ui/button";

interface Props {
  formList: FormLink[];
  deleteForm: (formId: number) => void;
  setCreateFormModal: (statement: boolean) => void;
  getFormList: () => void;
}

const LinkedForms: FC<Props> = ({
  formList,
  deleteForm,
  getFormList,
  setCreateFormModal,
}) => {
  return (
    <div className="w-full mb-[50px] flex flex-col items-center rounded-[16px] gap-[8px]">
      {formList.length > 0 ? (
        formList.map((form) => (
          <LinkedFormsItem
            key={form.form_link_id}
            formLink={form}
            deleteForm={deleteForm}
            getFormList={getFormList}
          />
        ))
      ) : (
        <p className="text-center">
          You have no linked forms. Click{" "}
          <Button
            variant="link"
            size="sm"
            className="text-[#038cfc] px-0 underline"
            onClick={() => setCreateFormModal(true)}
          >
            here
          </Button>{" "}
          to create a form link
        </p>
      )}
    </div>
  );
};

export default LinkedForms;
