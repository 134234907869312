import previewImage from "../../../assets/blog/coachesAreEntrepreneurs.webp";
import { Post } from "../../../types/postTypes";

export const coachesAreEntrepreurs: Post = {
    metadata: {
        id: 3,
        title: "ICYMI: Coaches Are Entrepreneurs",
        author: "Zoee",
        date: "September 5, 2022",
        urlSlug: "coaches-are-entrepreneurs",
        description:
            "If you're a coach, then you know the entrepreneurial spirit it takes to start a practice. Here's our take on why coaches are entrepreneurs.",
        previewImg: previewImage,
        tags: ["Coach", "Entrepreneur", "Business"]
    },
    content: [
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "The word"
                },
                {
                    type: "italic",
                    text: "coach"
                },
                {
                    type: "normal",
                    text: "has many meanings. It can be a form of transportation (think: horse and carriage or a large bus with a sports team onboard), an athletic trainer, or (Zoee’s favorite definition) a trained professional who helps you navigate various seasons of your life or business."
                }
            ]
        },
        {
            type: "paragraph",
            text: "But let's stop a moment to recognize the practical realities of running a coaching business. Besides being responsible for guiding clients through whatever life throws at them, coaches are also responsible for keeping their own business afloat, including everything from marketing to accounting to scheduling and billing. In short, running a coaching business is like running any other business.",
        },
        {
            type: "paragraph",
            text: "It's not a get-rich-quick scheme, it's not multilevel marketing, it's not volunteer work by people who have “been there.” Coaches have spent years sharpening their focus and mastering their craft to help clients create lives they love, even in the most difficult times. Coaching is one of the industries that can truly help our society through stress and uncertainty. (You know, kind of like the last two and a half years.) So, at a time when the world really needs coaching, we want the world to know: coaches are entrepreneurs.",
        },
        {
            type: "paragraph",
            text: "Think about going to a coffee shop. When you walk inside, you're interested in purchasing a cup of coffee. Maybe you love the fancy doubleshot mocha iced latte, or maybe you're more European and take your café au lait with a scone on the side. Either way, when you walk into a coffee shop, you know what you want.",
        },
        {
            type: "paragraph",
            text: "You saunter over to the barista standing behind the cash register. You place your order for a decaf americano, a latte, a cappuccino…whatever your heart desires. You pay for your drink (an exchange of goods and services), wait for the coffee to be made, and then head out to your car or walk to your next destination, thrilled with the purchase you've just made.",
        },
        {
            type: "paragraph",
            text: "Now, think of your coffee experience in terms of a coaching experience. A client knows they want “one 16-oz. serving of guidance in the form of career coaching, please,” and as a coach, you're prepared to deliver. But coaching is much more intricate than operating an espresso machine, even with its fancy knobs and buttons.",
        },
        {
            type: "paragraph",
            text: "From family-of-origin “stuff” to negative self-talk, bad habits to break, and goals to set, humans are complicated. We always bring baggage, and we may not always want what we say we want. Think about it: coaching is an entire industry dedicated to humans helping other humans navigate…being human. And this stuff didn't come with a handbook.",
        },
        {
            type: "paragraph",
            text: "Coaches have the arduous task of parsing out their client's words, reading between the lines, and helping people see their blind spots in order to navigate a difficult season or make a life change. And while most coaches reading this already know how hard their jobs are, they do it because they love it. This is their passion, their purpose, their “why I am here.” They coach because they were called to it.",
        },
        {
            type: "paragraph",
            text: "But make no mistake. When you pull back the curtain on a “coaching business,” you'll see it's a lot more than just clicking a link and joining someone for a virtual call. Running a coaching business is like keeping that coffee shop alive. Sure, coaches don't have to order paper cups, but they do have to deal with all aspects of running a business—and mostly alone. Between tracking customers, posting on social media, billing clients, rescheduling appointments, email communications, accounting, and all the other little admin tasks, it's obvious: coaches are business owners.",
        },
        {
            type: "paragraph",
            text: "Yes, their craft is coaching. But everything else about being a coach is entrepreneurial. And it's time we collectively recognize this spirit, this fire, this work that goes into keeping a coaching business successful. Here are a few ways Zoee is encouraging coaches to keep up that entrepreneurial spirit this season.",
        },
        {
            type: "header1",
            text: "Find Constant Inspiration",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "As an entrepreneur, you’re often running a business solo. This means there’s no water cooler chat, no popping into your co-worker’s office with a quick idea, no brainstorming session with a bunch of colleagues in a brightly lit conference room."
                },
                {
                    type: "italic",
                    text: "You"
                },
                {
                    type: "normal",
                    text: "are running the show—full stop—which means you may need to regularly keep yourself inspired."
                }
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "Listening to podcasts like"
                },
                {
                    type: "italic",
                    text: "How I Built This, Views from the Top, Business Casual, "
                },
                {
                    type: "normal",
                    text: "and"
                },
                {
                    type: "italic",
                    text: "Earn Your Leisure"
                },
                {
                    type: "normal",
                    text: "can help you feel like you have colleagues to chat with. The hosts and guests offer insights into problem solving, how to navigate the unexpected, growth and scaling, and everything else it takes to run a successful business. This is the type of advice that coaches often need at 3 p.m. on a Tuesday when all their coaching friends are in sessions. While a podcast isn’t like a two-way conversation with you, it will provide inspiration to keep you going."
                }
            ]
        },
        {
            type: "paragraph",
            text: "If there's a real lull in your workday, finding a TED talk related to your business may work for a quick dose of inspiration as well! And it never hurts to rewatch the ones you really love.",
        },
        {
            type: "header1",
            text: "Hold Yourself Accountable",
        },
        {
            type: "paragraph",
            text: "Coaches are the first advocates of accountability. But they need someone to hold them accountable too. Finding a fellow entrepreneur friend that you check in with once or twice a week can help you stay on track and check off items on your to-do list.",
        },
        {
            type: "paragraph",
            text: "For the really dry tasks you keep putting off, like bookkeeping or filing taxes, we suggest scheduling a specific date on your calendar. Set up a day with a fellow business owner to comb through your own finances and get your books in order. If you're able to outsource something like this, fantastic! But if you're still the “chief of everything officer” for your business, blocking off time with a friend can motivate you to roll up your sleeves and get it done.",
        },
        {
            type: "paragraph",
            text: "For something like a sales goal, start a little friendly competition or even a pool. Each time you and a fellow coaching friend get a new client, put $1 in a jar. Once you hit $100, take yourselves out for a celebratory dinner!",
        },
        {
            type: "header1",
            text: "Don't Give Up",
        },
        {
            type: "paragraph",
            text: "Jay-Z, the famous rapper and business magnate, said “The genius thing that we did was, we didn't give up.” This famous quote resonates with the Zoee team every day.",
        },
        {
            type: "paragraph",
            text: "Even when times get tough and you're unsure of the path forward, don't give up. If you're a coach, chances are you have a calling for this work. One tough morning, day, week, or even season doesn't mean you need to throw in the towel; it just means you may need a break or a reset. Some of our favorite quick and easy ways to reset: wash your face, drink a glass of water, go for a long walk, take a weekend off, or try a new activity. Coming back to your work with a slightly new perspective (or even a little more energy) can change the way you do business.",
        },
        {
            type: "paragraph",
            text: "Coaches, we see you! We see your hard work, your hours of training and education, your constant support for your clients and one another, your business skills, your drive, and your spirit. Zoee is here to support your coaching business, cultivating a community of coaches who are ready to grow. Let's do life together!",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office."
                },
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Written by Zoee."
                },
            ]
        },
    ],
};
