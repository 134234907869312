import React from "react";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ReferralRequests } from "../../api/app.service";
import useLoading from "../../hooks/useLoading";
import { SvgClose } from "../icons";

interface Props {
    setShowInviteModal: any;
    setShowConfirmInviteModal: any;
    invites: any;
    setInvites: any;
    getReferrals: any;
}

const ConfirmReferralInvite: React.FC<Props> = ({
    setShowInviteModal,
    setShowConfirmInviteModal,
    invites,
    setInvites,
    getReferrals
}) => {
    const [validInvites, setValidInvites] = React.useState<any>([]);
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const { user } = React.useContext(UserCtx);
    const {
        renderError, renderSuccess 
    } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const [submitObject, setSubmitObject] = React.useState<any>({
        invites: [],
        subject: "Try coaching with Zoee!",
        description: "Sign up for Zoee with a referral from me."
    });

    const submitInvitesForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        let emailArray: any[] = [];
        validInvites?.forEach((invite: any) => emailArray.push(invite.email));
        setSubmitObject({ ...submitObject, invites: emailArray });
    };

    const closeAndReset = () => {
        setValidInvites([]);
        setInvites([{ name: "", email: "" }]);
        setShowConfirmInviteModal(false);
        getReferrals();
    };

    const removeInvite = (invite: any) => {
        const inviteCount = validInvites?.length;
        if (inviteCount <= 1) {
            setValidInvites([]);
            setInvites([{ name: "", email: "" }]);
            setShowConfirmInviteModal(false);
            setShowInviteModal(true);
        } else {
            const filtered = validInvites?.filter((item: any) => {
                return invite?.email !== item?.email;
            });
            setValidInvites(filtered);
            setInvites(filtered);
        }
    };

    const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target as HTMLInputElement;
        setSubmitObject({ ...submitObject, [target.name]: target.value });
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        let target = e.target as HTMLTextAreaElement;
        setSubmitObject({ ...submitObject, [target.name]: target.value });
    };

    const sendInvites = () => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            ReferralRequests.createReferralRequest({
                sender_user_id: parseInt(user?.user_id),
                referral_requests: submitObject?.invites,
                description: submitObject?.description,
                subject: submitObject?.subject
            })
                .then(() => {
                    closeAndReset();
                    renderSuccess("Referral invites sent!");
                })
                .catch((ex) => {
                    console.log(ex);
                    if (ex.response.data.error_detail) {
                        renderError(ex.response.data.error_detail);
                    } else {
                        renderError(ex.response.data.message);
                    }
                })
                .finally(() => {
                    stopLoading();
                });
        }
        setIsSubmit(false);
    };

    React.useEffect(() => {
        if (isSubmit) {
            sendInvites();
        }
    }, [submitObject]);

    React.useEffect(() => {
        const filledInvites = invites?.filter((invite: { email: string }) => {
            const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
            return invite?.email?.match(mailformat);
        });
        setValidInvites(filledInvites);
    }, []);

    return (
        <div className="fixed top-0 left-0 w-screen h-screen pb-[120px] zoee-backdrop-filter z-[801] flex items-center justify-center">
            <form action="#" onSubmit={submitInvitesForm}>
                <div
                    className="py-[16px] sm:py-[32px] px-[24px] sm:px-[48px] w-[351px] sm:w-auto bg-white 
                    main-shadow rounded-[16px] flex flex-col items-center relative"
                >
                    <SvgClose
                        className="absolute top-[24px] right-[24px] cursor-pointer"
                        onClick={closeAndReset}
                    />
                    <h2 className="text-[18px] md:text-[22px] font-bold mb-[24px]">
                        Send Referral Invite
                    </h2>
                    <div className="w-[298px] md:w-[420px] overflow-y-auto pb-[16px]">
                        <p className="mb-[24px]">
                            Zoee will send invite emails to the following email addresses with your
                            referral code.
                        </p>
                        <div className="w-full bg-[#f8f8f8] p-[16px] rounded-[14px] flex flex-col sm:flex-row">
                            <h5 className="font-bold text-base mb-[8px] sm:mr-[10px]">To:</h5>
                            <div className="flex flex-wrap w-full gap-[4px]">
                                {validInvites.map((invite: any, index: any) => (
                                    <div
                                        className="py-[4px] px-[8px] bg-[#e8e7e4] flex items-center
                                        text-grayCharcoal text-base font-bold gap-[12px] rounded-[8px]"
                                        key={index}
                                    >
                                        <span>{invite.email}</span>
                                        <SvgClose
                                            fill="#3A434A"
                                            className="cursor-pointer"
                                            onClick={() => removeInvite(invite)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="default-input w-full mt-[16px]">
                            <input
                                type="text"
                                placeholder="Subject"
                                onChange={handleSubjectChange}
                                name="subject"
                                value={submitObject?.subject}
                                disabled
                            />
                            <div className="overlay">Subject</div>
                        </div>
                        <p className="ml-auto mt-[16px] text-gray text-base">300 Characters</p>
                        <div className="relative w-full h-[180px] md:h-[160px]">
                            <textarea
                                className="w-full h-full border
                                border-grayCloud rounded-[10px] focus:outline-none focus:border-royalBlue 
                                py-[24px] px-[20px] resize-none mt-[8px] text-graySlate focus:text-black"
                                onChange={handleDescriptionChange}
                                name="description"
                                value={submitObject?.description}
                            ></textarea>
                            <div className="absolute top-[16px] left-[20px] text-graySlate text-base">
                                Description
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between mt-[16px]">
                        <input
                            type="submit"
                            className="btn-primary btn-blue text-base mx-auto md:mx-0"
                            value={`Send Invite${validInvites?.length > 1 ? "s" : ""}`}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ConfirmReferralInvite;
