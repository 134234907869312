import { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SvgChevronLeft } from "./icons";

/**
 * Props for the Link component.
 */
interface LinkProps {
  /**
   * The target URL for the link.
   */
  to: string;
  /**
   * The current page name.
   */
  current: string;
  /**
   * The previous page name.
   */
  prev: string;
}

/**
 * Props for the Button component.
 */
interface ButtonProps {
  /**
   * The click event handler for the button.
   */
  onClick: () => void;
  /**
   * The current page name.
   */
  current: string;
  /**
   * The previous page name.
   */
  prev: string;
}

interface BackProps {
  /**
   * The current page name.
   */
  current: string;
}

/**
 * CombinedProps represents the combined set of props that can be passed to the BackToButton component.
 * It can either accept LinkProps, ButtonProps, or BackProps, but not both.
 */
type CombinedProps =
  | (LinkProps & { onClick?: never })
  | (ButtonProps & { to?: never })
  | (BackProps & { to?: never; onClick?: never; prev?: never });

/**
 * BackToButton is a React component that renders a back button as a link or a button.
 * It provides navigation functionality by using react-router-dom's Link component or a click event handler.
 * @param {CombinedProps} props - The props for the BackToButton component.
 * @returns {JSX.Element} The rendered BackToButton component.
 *
 * @example
 * // Example usage with Link:
 * <BackToButton to="/account-settings" current="Personal Info" prev="Account Settings" />
 *
 * @example
 * // Example usage with onClick:
 * <BackToButton onClick={handleBackButtonClick} current="Personal Info" prev="Account Settings" />
 *
 * @example
 * // Example usage with only BackProps:
 * <BackToButton current="Personal Info" />
 */
const BackToButton: FC<CombinedProps> = ({ to, onClick, current, prev }) => {
  const navigate = useNavigate();
  /**
   * The common JSX content shared by both Link and button elements.
   */
  const commonContent = (
    <>
      <SvgChevronLeft className="md:rounded-full md:border md:border-grayCloud h-6 w-6 md:h-8 md:w-8 md:p-1 md:group-hover:bg-grayCloud transition-colors" />
      <span className="md:hidden group-hover:underline">{current}</span>
      {prev ? (
        <span className="hidden md:inline">
          back to <span className="text-black">{prev}</span>
        </span>
      ) : (
        <span className="hidden md:inline text-black">Back</span>
      )}
    </>
  );

  /**
   * The common class names shared by both Link and button elements.
   */
  const commonClasses =
    "inline-flex items-center gap-5 md:gap-4 text-[16px] md:text-graySlate md:font-bold md:text-base my-5 md:my-8 focus-visible:outline-2 rounded-full md:pr-4 pr-2 group";

  /**
   * Handles the click event for the back button, navigating back to the previous page.
   */
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  if (to) {
    return (
      <Link to={to} className={commonClasses}>
        {commonContent}
      </Link>
    );
  }

  return (
    <button
      onClick={onClick || handleBackButtonClick}
      className={commonClasses}
    >
      {commonContent}
    </button>
  );
};

export default BackToButton;
