import type { SVGProps } from "react";
export const SvgZoeeLogoTM = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="auto"
    height="24"
    fill="none"
    viewBox="0 0 121 24"
    {...props}
  >
    <g fill="currentColor" clipPath="url(#zoee-logo_svg__a)">
      <path d="M20.18 9.597V1.505C20.18.682 19.523 0 18.724 0H1.458C.658 0 0 .682 0 1.505V21.71c0 .823.659 1.505 1.458 1.505h17.265c.8 0 1.458-.682 1.458-1.505v-2.917c0-.023 0-.047.023-.07l6.257-8.633a.235.235 0 0 0-.188-.376h-5.974c-.071 0-.118-.047-.118-.117M54.945 0c-6.327 0-11.29 5.104-11.29 11.62s4.963 11.619 11.29 11.619 11.29-5.104 11.29-11.62S61.272 0 54.945 0m0 17.335c-3.129 0-5.41-2.4-5.41-5.716s2.282-5.715 5.41-5.715 5.41 2.399 5.41 5.715c0 3.317-2.258 5.716-5.41 5.716M91.167 11.62C91.167 5.01 86.51 0 80.347 0 73.785 0 69.01 4.892 69.01 11.62c0 6.844 4.87 11.619 11.831 11.619 3.81 0 6.798-1.341 8.891-3.975.094-.118.07-.283-.07-.353l-4.305-2.564c-.094-.047-.188-.047-.258.023-1.035.941-2.54 1.506-4.164 1.506-2.916 0-4.821-1.153-5.621-3.41l-.188-.495h15.641c.118 0 .212-.094.235-.211a13 13 0 0 0 .165-2.14m-16.112-2 .118-.47c.658-2.494 2.493-3.858 5.127-3.858 2.54 0 4.352 1.411 5.01 3.858l.118.47zM116.099 11.62c0-6.61-4.657-11.62-10.82-11.62-6.562 0-11.337 4.892-11.337 11.62 0 6.844 4.87 11.619 11.831 11.619 3.811 0 6.798-1.341 8.891-3.975.094-.118.071-.283-.07-.353l-4.305-2.564c-.094-.047-.188-.047-.258.023-1.035.941-2.541 1.506-4.164 1.506-2.916 0-4.798-1.153-5.621-3.41l-.188-.495h15.641c.118 0 .212-.094.235-.211.118-.682.165-1.412.165-2.14m-16.112-2 .118-.47c.658-2.494 2.493-3.858 5.127-3.858 2.541 0 4.352 1.411 5.01 3.858l.118.47z" />
      <path d="M41.326.235c0-.14-.094-.235-.235-.235H24.58a.25.25 0 0 0-.235.235v5.41c0 .141.094.235.235.235h9.338c.094 0 .141.118.094.188l-9.62 13.125c-.023.047-.047.094-.047.141v3.622c0 .142.094.236.235.236h16.512a.253.253 0 0 0 .235-.236v-5.41c0-.14-.094-.235-.235-.235h-8.985c-.094 0-.141-.117-.094-.188l9.267-12.63a.3.3 0 0 0 .047-.142zM118.027.259h-.799v2.21h-.33V.26h-.799V0h1.905v.259zM118.804 0l.799 2.023.824-2.023h.423v2.47h-.329v-.965l.023-1.035-.823 2h-.235l-.8-2 .024 1.035v.965h-.33V0z" />
    </g>
    <defs>
      <clipPath id="zoee-logo_svg__a">
        <path fill="#fff" d="M0 0h120.85v23.215H0z" />
      </clipPath>
    </defs>
  </svg>
);
