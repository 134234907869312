import * as React from "react"
import Loader from "../ui/loader"
import PublicProfileServiceCard from "./PublicProfileServiceCard"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"

const PublicProfileServices: React.FC = () => {
  const [clientServices, setClientServices] = React.useState<any[]>([])
  const [servicesLoading, setServicesLoading] = React.useState<boolean>(false)

  const { loadingServices, services, getServices } = React.useContext(
    CoachPublicProfileContext
  )

  const { user } = React.useContext(UserCtx)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = React.useContext(CoachPublicProfileContext)

  React.useEffect(() => {
    if (coachPublicProfileData) {
      getProfileServiceList()
    }
  }, [coachPublicProfileData])

  const getProfileServiceList = () => {
    if (user && coachPublicProfileData) {
      setServicesLoading(true)
      const coachProfileId = parseInt(coachPublicProfileData.coach_profile_id)
      const memberProfileId = parseInt(user.activeProfileId)
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coachProfileId,
        member_profile_id: memberProfileId,
      })
        .then((data) => {
          if (data && data.service_list) {
            setClientServices(data.service_list)
          } else {
            setClientServices([])
          }
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
        .finally(() => {
          setServicesLoading(false)
        })
    } else {
      console.warn("User or Coach Public Profile Data is missing.")
    }
  }

  const filteredServices = services.filter((service: any) => {
    if (service.service_details.type !== "consultation") {
      return true
    }
    const isEnrolled = clientServices.some(
      (clientService: any) =>
        clientService.service_details.type === "consultation" &&
        clientService.service_status === "active" &&
        clientService.service_id === service.service_id
    )
    return !isEnrolled
  })

  return (
    <>
      <h1 className="font-bold text-xl">Services & Programs</h1>
      <div className="w-full md:max-w-[1200px] flex items-center flex-wrap justify-start gap-[20px]">
        {loadingServices ? (
          <Loader />
        ) : (
          <>
            {filteredServices &&
              filteredServices.map((service: any, index: number) => (
                <PublicProfileServiceCard
                  key={index}
                  service={service}
                  getServices={getServices}
                />
              ))}
            {filteredServices.length === 0 && (
              <div className="flex flex-col items-center gap-[12px]">
                <div className="text-graySlate">No services</div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default PublicProfileServices
