import type { SVGProps } from "react";
export const SvgConsultation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.107 7.956v-.023A7.254 7.254 0 0 0 6.763 3.884h-.032A7.1 7.1 0 0 0 5.28 4.951h-.033a7.24 7.24 0 0 0 .51 10.929.47.47 0 0 1 .042.697 8.4 8.4 0 0 1-2.036 1.404 7.9 7.9 0 0 0 5.266-.108q.418-.161.814-.37a.5.5 0 0 1 .239-.06h.173a7.26 7.26 0 0 0 7.226-7.19 7.2 7.2 0 0 0-.374-2.297m-3.37 2.532h-3.042v3.042a.468.468 0 0 1-.936 0v-3.042H6.717a.468.468 0 1 1 0-.936h3.042V6.51a.468.468 0 0 1 .936 0v3.042h3.042a.468.468 0 0 1 0 .936"
    />
    <path
      fill="currentColor"
      d="M19.279 19.742a.32.32 0 0 1 .037-.468 5.017 5.017 0 0 0 .356-7.568 5 5 0 0 0-1.04-.75h-.027l-.187-.135a8.205 8.205 0 0 1-6.319 7.404 5 5 0 0 0 4.105 2.134h.037q.121-.015.234.033a5.45 5.45 0 0 0 4.212.332 5.7 5.7 0 0 1-1.404-.936zm-2.911-2.626a.323.323 0 1 1 0-.646.323.323 0 0 1 0 .646m1.296 0a.327.327 0 0 1-.328-.323.323.323 0 1 1 .323.323zm1.297 0a.33.33 0 0 1-.328-.323.323.323 0 1 1 .323.323z"
    />
  </svg>
);
