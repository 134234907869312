import type { SVGProps } from "react";
export const SvgNewWindow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M9.583 4.34H3.5a1 1 0 0 0-1 1v10.5a1 1 0 0 0 1 1h12.167a1 1 0 0 0 1-1v-5.007M16.5 7.77l-.057-3.585m0 0h-3.526m3.526 0L10.417 10"
    />
  </svg>
);
