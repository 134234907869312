import React from "react";

type MeetingStatusIconProps = {
    status: "busy" | "tentative" | "free";
};


const MeetingStatusIcon: React.FC<MeetingStatusIconProps> = ({ status }) => {
    let statusTextStyles = "";
    let statusDivStyles = "";
    let statusText = "";

    if (status === "busy") {
        statusTextStyles = "text-green";
        statusDivStyles = "bg-green";
        statusText = "Busy";
    } else if (status === "tentative") {
        statusTextStyles = "text-luminousAmber";
        statusDivStyles = "bg-luminousAmber";
        statusText = "Tentative";
    } else if (status === "free") {
        statusTextStyles = "text-green";
        statusDivStyles = "bg-green";
        statusText = "Free";
    }

    return (
        <div className="text-base font-bold flex items-center">
            <div
                className={`${statusDivStyles} rounded-full h-[5px] w-[5px] mr-[7px]`}
            ></div>
            <p className={statusTextStyles}>{statusText}</p>
        </div>
    );
};

export default MeetingStatusIcon