import type { SVGProps } from "react";
export const SvgNotifications = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m5.63 7.98.723.197zm4.406-4.598-.23-.713zM5.582 8.156l-.724-.197zm-.206 4.32.739-.127zm.024.14-.74.127zm.507 4.733.149-.735zm.392.08-.15.735zm11.402 0 .15.735zm.392-.08-.149-.735zm.512-4.76.74.128zm.026-.147-.74-.128zm-.186-4.256-.726.192zm-.067-.252.725-.191zm-4.28-4.544.234-.712zm4.678 9.547-.364.655zm-13.53-.013.358.66zM12.75 2a.75.75 0 0 0-1.5 0zm-1.5 1.067a.75.75 0 0 0 1.5 0zm-2.357 14.01a.75.75 0 0 0-.167 1.49zm6.38 1.49a.75.75 0 1 0-.166-1.49zm-8.92-10.39a5.96 5.96 0 0 1 3.913-4.08l-.46-1.428a7.46 7.46 0 0 0-4.9 5.114zm-.047.175.047-.175-1.447-.394-.048.175zm-.191 3.996a9.25 9.25 0 0 1 .19-3.996l-1.447-.394a10.75 10.75 0 0 0-.222 4.645zm.024.14-.024-.14-1.479.255.024.14zM4.75 15.016c0-.618.344-1.156.854-1.434l-.716-1.318a3.13 3.13 0 0 0-1.638 2.752zm1.306 1.597a1.63 1.63 0 0 1-1.306-1.597h-1.5a3.13 3.13 0 0 0 2.508 3.067zm.392.08-.392-.08-.298 1.47.392.08zm11.104 0c-3.664.743-7.44.743-11.104 0l-.298 1.47c3.86.783 7.84.783 11.7 0zm.392-.08-.392.08.298 1.47.392-.08zm1.306-1.597a1.63 1.63 0 0 1-1.306 1.597l.298 1.47a3.13 3.13 0 0 0 2.508-3.067zm-.838-1.425c.501.28.838.814.838 1.425h1.5a3.13 3.13 0 0 0-1.608-2.735zm-.52-1.278-.026.148 1.478.255.026-.148zm-.173-3.936a9.3 9.3 0 0 1 .172 3.936l1.479.255c.261-1.522.193-3.081-.2-4.574zm-.066-.253.066.253 1.45-.383-.066-.252zm-3.789-4.022a5.79 5.79 0 0 1 3.789 4.022l1.45-.382a7.29 7.29 0 0 0-4.77-5.065zm-3.598-.006a5.83 5.83 0 0 1 3.598.006l.468-1.425a7.33 7.33 0 0 0-4.527-.008zm8.876 8.185c.135.075.236.24.202.435l-1.478-.255c-.082.475.163.917.546 1.13zm-14.482.461a.45.45 0 0 1 .228-.478l.716 1.319c.376-.205.614-.635.535-1.095zM11.25 2v1.067h1.5V2zM8.726 18.567c2.176.244 4.372.244 6.548 0l-.167-1.49a28 28 0 0 1-6.214 0z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.05 20.233c-1.15 1.285-2.945 1.3-4.107 0"
    />
  </svg>
);
