import type { SVGProps } from "react";
export const SvgZoeeLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="24"
    fill="none"
    viewBox="0 0 111 22"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.164 9.087V1.43c0-.79-.62-1.429-1.389-1.429H1.389C.62 0 0 .639 0 1.43v19.14C0 21.362.62 22 1.388 22H17.78c.768 0 1.388-.639 1.388-1.43v-2.767a.12.12 0 0 1 .021-.069l5.945-8.17c.109-.148.004-.361-.175-.361h-5.682a.11.11 0 0 1-.112-.116M52.18 0c-6.015 0-10.726 4.832-10.726 11s4.711 11 10.726 11 10.727-4.832 10.727-11S58.195 0 52.18 0m0 16.424c-2.98 0-5.142-2.28-5.142-5.424 0-3.143 2.163-5.424 5.142-5.424s5.143 2.28 5.143 5.424c0 3.143-2.163 5.424-5.143 5.424M86.582 11c0-6.272-4.414-11-10.267-11-6.24 0-10.769 4.627-10.769 11 0 6.474 4.617 11 11.228 11 3.624 0 6.46-1.263 8.437-3.76a.232.232 0 0 0-.063-.343l-4.084-2.418a.21.21 0 0 0-.252.029c-.978.902-2.422 1.432-3.954 1.432-2.776 0-4.568-1.09-5.332-3.24l-.168-.473h14.845c.109 0 .203-.08.22-.192q.159-.977.159-2.035M71.274 9.116l.119-.444c.627-2.356 2.363-3.656 4.88-3.656 2.4 0 4.132 1.332 4.756 3.656l.12.444zM110.256 11c0-6.272-4.413-11-10.267-11-6.24 0-10.768 4.627-10.768 11 0 6.474 4.617 11 11.228 11 3.624 0 6.46-1.263 8.437-3.76a.232.232 0 0 0-.063-.343l-4.084-2.418a.21.21 0 0 0-.252.029c-.978.902-2.423 1.432-3.954 1.432-2.777 0-4.568-1.09-5.332-3.24l-.168-.473h14.845c.108 0 .203-.08.221-.192q.157-.977.157-2.035M94.949 9.116l.119-.444c.627-2.356 2.362-3.656 4.88-3.656 2.4 0 4.132 1.332 4.756 3.656l.119.444z"
    />
    <path
      fill="currentColor"
      d="M39.253.227A.223.223 0 0 0 39.032 0H23.349a.223.223 0 0 0-.22.227v5.121c0 .127.097.228.22.228h8.872c.091 0 .144.108.088.18l-9.135 12.437a.24.24 0 0 0-.046.137v3.439c0 .126.098.227.221.227h15.683c.123 0 .22-.1.22-.227v-5.117a.223.223 0 0 0-.22-.228h-8.536a.113.113 0 0 1-.087-.18L39.21 4.28a.24.24 0 0 0 .045-.137V.227z"
    />
  </svg>
);
