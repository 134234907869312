import type { SVGProps } from "react";
export const SvgAmericanExpress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#016FD0"
      d="M20.256 15.431v-.168c-.404.209-.146.168-5.863.168 0-.943.003-.894 0-.943-.064-.004-.12-.004-.352-.004 0 .67-.004.255-.004.947h-1.481c0-.453.003-.572.003-1.093-.374.225-.853.24-1.283.232 0 .55-.004.31-.004.86h-1.83c-.19-.213-.1-.115-.575-.65-.12.13-.48.52-.603.65H5.196v-3.453h3.11c.186.21.104.116.58.644.119-.131.456-.502.587-.644h2.17c.366 0 .673.072.909.21v-.21c2.032 0 2.406-.052 2.832.191v-.19h2.926v.194c.427-.258.733-.195 2.428-.195v.188c.386-.221.621-.195 2.032-.188V5.415c0-.992-.804-1.796-1.796-1.796H3.014c-.99 0-1.795.804-1.795 1.796v4.108l.864-2.017h1.485l.337.79v-.79h1.721c.109.232.416.898.52 1.123.217-.509.378-.894.472-1.123h3.854c0-.003.43 0 .434 0 1.635.008 2.005-.03 2.41.199v-.199h1.492v.348c.284-.228.67-.348 1.149-.348h1.032c0 .02.072.012.086.012h1.213c.157.366.097.224.329.77v-.77h1.62c.183.299-.037-.068.419.688v-.688h1.493v3.442h-1.557c-.202-.337-.052-.083-.493-.82v.82h-1.976c-.24-.554-.004-.011-.247-.573h-.71c-.158.374-.083.195-.24.573h-1.003c-.46 0-.835-.112-1.111-.333v.333h-2.488c-.012-.52-.004-.928-.004-.928-.068-.011-.127-.008-.367-.008v.94h-5.07v-.427c-.093.21-.1.22-.19.427H5.589c-.15-.333-.109-.24-.19-.427v.427h-1.98c-.157-.378-.083-.199-.24-.573h-.718l-.24.573h-.995v7.62c0 .993.805 1.797 1.796 1.797h17.963c.992 0 1.796-.804 1.796-1.796v-3.382c-.475.31-1.223.228-2.525.228"
    />
    <path
      fill="#016FD0"
      d="M14.378 8.004h-.633v2.593h.633zM13.39 8.7c0-.614-.528-.688-1.025-.688L10.902 8v2.593h.613v-.939h.674c.688 0 .542.385.554.94h.62v-.506c0-.344-.055-.565-.41-.688.276-.113.44-.4.437-.7m-1.1.423h-.774V8.55h.785c.191 0 .4.038.4.277 0 .247-.198.296-.411.296M10.475 8.55v-.546H8.4v2.593h2.076v-.535H9.02v-.517h1.414v-.527H9.02V8.55zM7.939 10.597V8.004H6.906l-.737 1.758-.812-1.758H4.343v2.458L3.292 8.004h-.917l-1.111 2.593h.67l.239-.573h1.29l.24.573h1.265V8.569l.898 2.028h.546l.898-2.028v2.028zM2.393 9.485l.42-1.032.43 1.032zM11.665 12.471H9.693l-.785.853-.767-.853H5.652l-.003 2.593h2.447l.797-.86.763.86h1.205l.003-.871c.707 0 1.845.172 1.845-.872 0-.648-.46-.85-1.044-.85m-3.884 2.047H6.262v-.516H7.62v-.528H6.262v-.468h1.56l.67.756zm2.462.307-.946-1.051.946-1.026zm1.415-1.16h-.794v-.659h.805c.21 0 .381.086.381.315 0 .24-.172.344-.392.344M14.68 9.321c0 .83.382 1.272 1.235 1.272h.719l.24-.572h1.283l.247.572h1.26V8.651l1.168 1.942h.883V8.012h-.633v1.8l-1.088-1.8h-.947v2.447l-1.044-2.447h-.928l-.879 2.039h-.277c-.498 0-.602-.303-.602-.745 0-.89.587-.748 1.238-.737V8h-.602c-.88 0-1.272.45-1.272 1.321m2.848-.868.42 1.033h-.854zM19.481 14.507h-1.205l-.003.553h1.204c.554 0 .98-.21.98-.823 0-1.242-1.604-.419-1.604-.984 0-.21.183-.24.344-.24h1.137v-.546h-1.242c-.479 0-.88.248-.88.775 0 1.235 1.598.468 1.598 1.025-.003.202-.176.24-.329.24M15.496 13.171c0-.614-.527-.7-1.014-.7h-1.474l-.003 2.593h.62l.004-.946h.659c.412 0 .554.075.554.516l-.004.43h.621l.004-.516c0-.333-.068-.565-.412-.688.288-.117.442-.404.445-.689m-1.092.42h-.775v-.584h.786c.191 0 .4.037.4.276 0 .258-.201.307-.411.307M17.902 13.006v-.535h-2.065l-.004 2.593h2.065l.004-.535-1.444-.011v-.516h1.418v-.528h-1.414v-.468zM21.614 13.018h1.126v-.547h-1.231c-.479 0-.89.247-.89.775 0 1.235 1.597.479 1.597 1.025 0 .19-.16.24-.314.24h-1.197l-.004.553h1.197c.314 0 .659-.067.842-.333v-.965c-.393-.516-1.47-.049-1.47-.505 0-.217.172-.243.344-.243"
    />
  </svg>
);
