import type { SVGProps } from "react";
export const SvgArchive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.233 10.052a.795.795 0 0 0 .06 1.126l2.695 2.393 2.694-2.393a.78.78 0 0 0 .06-1.126.78.78 0 0 0-1.125-.06l-.825.724V4.804A.806.806 0 0 0 11.988 4a.806.806 0 0 0-.804.804v5.912l-.825-.724a.78.78 0 0 0-1.126.06"
    />
    <path
      fill="currentColor"
      d="M20.916 13.25 18.22 8.605c-.361-.644-1.166-1.086-1.91-1.086h-2.574v1.609h2.594c.161 0 .442.16.503.301l2.393 4.122h-5.068a2 2 0 0 1-2.01 1.99 2 2 0 0 1-2.011-1.99H4.87L7.263 9.43c.08-.14.362-.301.502-.301h2.433V7.519H7.786c-.744 0-1.529.462-1.91 1.086L3.18 13.25C3 13.57 3 13.873 3 14.195v4.001c0 .442.362.804.804.804h16.488a.807.807 0 0 0 .805-.804v-3.981c0-.342 0-.644-.181-.966"
    />
  </svg>
);
