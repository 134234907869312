import { UAParser } from "ua-parser-js";

export const getUserStats = () => {
  const windowWidth = window.innerWidth ||
    document.documentElement.clientWidth || document.body.clientWidth;

  const windowHeight = window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const parser = new UAParser(window.navigator.userAgent);
  const browser = parser.getBrowser();
  const os = parser.getOS();
  const device = parser.getDevice();

  const stats = { browser, os, device, windowWidth, windowHeight };
  return stats;
};
