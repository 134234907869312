import type { SVGProps } from "react";
export const SvgSend = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.847 6.458c.754-2.154-1.299-3.931-3.288-3.345l-.035.012L3.758 7.838c-2.396.77-2.326 4.143.105 4.875l.024.007 4.62 1.241.029.007c.705.164 1.247.709 1.509 1.55l1.232 4.614v.002c.646 2.452 4.017 2.461 4.863.159l.007-.02zm-2.783-1.692c.806-.227 1.382.47 1.152 1.123l-4.7 13.817c-.314.832-1.388.675-1.568-.013l-1.239-4.637-.008-.03c-.398-1.299-1.334-2.4-2.76-2.737L4.35 11.056c-.855-.267-.785-1.345-.06-1.574l.019-.006z"
      clipRule="evenodd"
    />
  </svg>
);
