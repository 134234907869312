import React, { useState, useEffect } from "react"
import { Button } from "../ui/button"
import {
  SvgChevronLeft,
  SvgChevronRight,
} from "../icons"
import ZoeeProfilePreview from "../../assets/images/Profile.png"
import automatedInvoicing from "../../assets/images/feature-billing-payment.png"
import simplifiedScheduling from "../../assets/images/feature-scheduling.png"
import featurePayment from "../../assets/images/feature-payments.png"
import communicationHub from "../../assets/images/featureCommunication.png"
import clientPortals from "../../assets/images/Client-Portal.png"
import { useNavigate } from "react-router-dom"

const IINMeetZoee: React.FC<{ pathname: string }> = ({ pathname }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const navigate = useNavigate()
  const slides = [
    {
      image: ZoeeProfilePreview,
      feature: "Simplified Scheduling and Booking",
    },
    {
      image: automatedInvoicing,
      feature: "Automated Invoicing and Payments",
    },
    {
      image: simplifiedScheduling,
      feature: "Simplified Scheduling and Booking",
    },
    {
      image: featurePayment,
      feature: "Effortless Client Management",
    },
    {
      image: communicationHub,
      feature: "Client Communication Hub",
    },
    {
      image: clientPortals,
      feature: "Client Portals",
    },
  ]

  // rotate the carousel every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))
    }, 5000)

    return () => clearInterval(interval)
  }, [slides.length])

  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1))
  }

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1))
  }


  const handleCreateAccount = () => {
    navigate("/registration", { state: { from: pathname } })
  }

  return (
    <div
      className="py-[56px] md:py-[96px] flex flex-col items-center justify-center overflow-hidden md:px-[144px] px-4 sm:px-8"
    >
      <div className="flex flex-col lg:flex-row items-center gap-16 w-full">
        {/* Left Section */}
        <div className="flex flex-col gap-6 lg:w-1/2 text-left">
          <h2 className="text-black text-[48px] font-bold leading-tight">
            Meet Zoee
          </h2>
          <p className="text-black text-[22px] font-normal">
            Your program registration includes a three-month subscription to
            Zoee, an all-in-one online platform to build and manage your
            business.
          </p>
          <p className="text-black text-[22px] font-normal">
            Built for coaches, by coaches—an IIN graduate, in fact—Zoee was
            designed with features to make running your business easier. Less
            admin, More coaching.
          </p>
          <div className="mt-4 w-full flex justify-start">
            <Button
              variant="default"
              onClick={handleCreateAccount}
              className="px-4 py-2 bg-[#4750F5] text-white  items-center gap-2 rounded-lg"
            >
              REGISTER NOW
            </Button>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-col items-center lg:w-1/2 gap-6">
          {/* Carousel */}
          <div className="relative w-full max-w-lg">
            <img
              className="w-[600px] max-w-full rounded-lg"
              src={slides[currentSlide].image}
              alt={`Slide ${currentSlide + 1}`}
            />
          </div>

          <div className="relative flex items-center gap-4">
            <button onClick={handlePrevSlide} className="rounded-full p-3">
              <SvgChevronLeft className=" text-black" />
            </button>

            {/* Feature Box */}
            <div className=" px-6 py-4 rounded-lg text-center flex items-center gap-3">
              <h2 className="text-black text-[24px] font-bold">
                {slides[currentSlide].feature}
              </h2>
            </div>

            <button onClick={handleNextSlide} className="rounded-full p-3">
              <SvgChevronRight className=" text-black" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IINMeetZoee
