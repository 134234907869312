import type { SVGProps } from "react";
export const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9.6 14.286 4.93-2.786L9.6 8.714zm10.982-7.271c.123.436.209 1.021.266 1.764.067.743.095 1.384.095 1.941l.057.78c0 2.034-.152 3.529-.418 4.485-.238.836-.788 1.374-1.643 1.606-.447.121-1.264.205-2.518.26a65 65 0 0 1-3.41.093L11.5 18c-3.98 0-6.46-.149-7.438-.409-.856-.232-1.407-.77-1.644-1.606-.123-.436-.209-1.021-.266-1.764a22 22 0 0 1-.095-1.941L2 11.5c0-2.034.152-3.529.418-4.485.237-.836.789-1.374 1.643-1.606.447-.121 1.264-.205 2.518-.26a65 65 0 0 1 3.41-.093L11.5 5c3.98 0 6.46.149 7.439.409.855.232 1.406.77 1.643 1.606"
    />
  </svg>
);
