import React, { FC, useContext, useEffect, useState } from "react"
import { Button } from "../../../components/ui/button"
import StepsLayout from "./StepsLayout"
import { AccountType } from "../../../components/auth/register-form/context"
import { sendEmailLinkRegister } from "../../../api/auth.service"
import Loader from "../../../components/ui/loader"
import { useLocation } from "react-router-dom"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { StepType } from ".."

interface VerifyEmailStepProps {
  email: string
  accountType: AccountType
  setLoading: (loading: boolean) => void
  loading: boolean
  isIIN: boolean
}

const VerifyEmailStep: FC<VerifyEmailStepProps> = ({
  email,
  accountType,
  loading,
  setLoading,
  isIIN,
}) => {
  const location = useLocation()
  const [timer, setTimer] = useState(30)
  const { renderError } = useContext(CommonFunctionCtx)

  const resendEmail = async () => {
    setLoading(true)
    try {
      const pathname = location.pathname.slice(1)
      const response = await sendEmailLinkRegister({
        email: email,
        profile_type: accountType,
        pathname: isIIN ? `${pathname}?&flow=IIN&` : pathname,
      })
      localStorage.setItem("email_verify_token", response.verify_token)
      if (response?.message === "complete") {
        setLoading(false)
        setTimer(30)
      }
    } catch (error: any) {
      setLoading(false)
      renderError("Resending Verification Email Faild. Please Try Again")
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <StepsLayout
      isIIN={isIIN}
      step={StepType.VerifyEmail}
    >
      <h3 className="text-[32px] font-bold text-center">
        {isIIN ? "Verify your email" : "You’ve got mail"}
      </h3>
      <p className="text-[22px] text-center break-words">
        {isIIN
          ? "To complete your purchase, click on the link in your inbox"
          : "To continue, click on the link in your inbox"}
        <br />
        <b>{email}</b>
      </p>
      <Button
        variant="outline"
        size="lg"
        onClick={resendEmail}
        disabled={timer > 0}
      >
        {timer > 0 ? (
          `Resend in ${timer} seconds`
        ) : loading ? (
          <Loader />
        ) : (
          "Resend Email"
        )}
      </Button>
      <p className="text-base text-center">
        Not getting an email from us? Check your spam folder.
      </p>
    </StepsLayout>
  )
}

export default VerifyEmailStep
