import React, { FC, useContext, useEffect, useState } from "react"
import ContactAvatar from "../../cards/ContactAvatar"
import moment from "moment"
import { UserCtx } from "../../../context/userContext"
import { useNavigate } from "react-router-dom"
import { Button } from "../../ui/button"
import useLoading from "../../../hooks/useLoading"
import { SvgChat, SvgRecord, SvgVideoSolid } from "../../icons/"
import { cn } from "../../../utils/cn"
import { Badge } from "../../ui/badge"
import { useLocation } from "react-router-dom"

type Props = {
  id: number | null
  title: string | null
  status: "busy" | "tentative" | "free" | null
  medium: string
  isActiveInvite: boolean
  isGuestOnline?: boolean
  startTime: Date | null
  endTime: Date | null
  setActiveSessionDetailViewId: any
  activeSessionDetailViewId: any
  participants: Array<any>
  is_managed: boolean | undefined
  belongs_to_profile: boolean | undefined
  duration: number | null
  type: string | null
  inProgress: boolean
  setShowRescheduleMeeting?: any
  isTodayPage?: boolean
  calendar?: string
  setClickReschedule: any
}

const MeetingPreviewCard: FC<Props> = ({
  id,
  title,
  isActiveInvite,
  startTime,
  endTime,
  setActiveSessionDetailViewId,
  participants,
  is_managed,
  medium,
  belongs_to_profile,
  duration,
  type,
  inProgress,
  isTodayPage,
  calendar,
}) => {
  const { user } = useContext(UserCtx)
  const navigate = useNavigate()
  const { startLoading } = useLoading()
  const [isHovering, setIsHovering] = useState<boolean>(false)

  const handleMouseOver = () => {
    setIsHovering(true)
  }

  const handleMouseOut = () => {
    setIsHovering(false)
  }

  const location = useLocation()

  useEffect(() => {
    if (location.state?.id) {
      setActiveSessionDetailViewId(location.state.id)
    }
  }, [location.state])

  let accentColor =
    is_managed && belongs_to_profile
      ? "bg-blurple"
      : is_managed
      ? "bg-mainBlack"
      : "bg-grayMist"
  let reschedule = Boolean(is_managed && belongs_to_profile)

  const formatTime = (
    startTime: Date | null,
    endTime: Date | null,
    duration: number | null
  ) => {
    if (!startTime || !duration) return

    if (duration < 1440) {
      return (
        <>
          <p>{moment(startTime).format("h:mma")}</p>
          <p>&nbsp;-&nbsp;</p>
          <p>{endTime ? moment(endTime).format("h:mma") : ""}</p>
        </>
      )
    } else if (duration === 1440) {
      return <p>All day</p>
    } else if (duration > 1440) {
      const offset = moment.tz
        .zone(user.timezone.value)
        ?.utcOffset(1403465838805)
      return (
        <>
          <p>
            {moment(startTime)
              .add(offset, "minutes")
              .add(1, "second")
              .format("MMM DD")}
          </p>
          <p>&nbsp;-&nbsp;</p>
          <p>
            {endTime
              ? moment(endTime)
                  .add(offset, "minutes")
                  .add(-1, "second")
                  .format("MMM DD")
              : ""}
          </p>
        </>
      )
    }
  }

  const formatDuration = (duration: number | null) => {
    if (duration === null) return

    if (duration >= 1440) {
      const days = duration / 1440
      const dayWord = days === 1 ? `day` : `days`
      return `${days} ${dayWord}`
    }

    return `${duration} min`
  }

  const formatMobileStartTime = (
    startTime: Date | null,
    duration: number | null
  ): string | undefined => {
    if (!startTime || !duration) return

    const offset = moment.tz.zone(user.timezone.value)?.utcOffset(Date.now())

    if (duration >= 1440 && offset !== undefined) {
      return moment(startTime)
        .add(offset, "minutes")
        .add(1, "second")
        .format("MM/DD")
    } else {
      return moment(startTime).format("h:mm")
    }
  }

  const handleMeetingSelect = (id: any) => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { id },
    })
  }

  const startMeetingChat = (participants: any) => {
    const participantIds = participants.map(
      (participant: any) => participant.profile_id
    )
    navigate(`/${user.activeProfile}/inbox`, {
      state: {
        participantIds: participantIds,
      },
    })
  }

  const isPastMeeting = () => {
    return endTime && endTime < new Date()
  }

  // May need more logic
  // For example if I have a calendar connected under a different email
  //
  // If my zoee email is bob@domain.com
  // and my calendar email is bob@yahoo.com
  // Maybe get all emails from cronofy profile_name and check aginst that?
  const isMeetingDeclined =
    participants.find((p) => p?.email === user.email)?.status === "declined"

  return (
    <div
      className={cn(
        "shadow-outline relative mt-4 flex min-h-[110px] w-full min-w-[310px] items-center rounded-xl bg-white md:min-h-[128px]",
        {
          "bg-grayMist": !is_managed && !belongs_to_profile,
        }
      )}
    >
      <div
        className={cn("flex w-full items-center pl-0 pr-[16px] md:px-[25px]")}
      >
        <div
          className={`${accentColor} absolute bottom-6 left-0 top-6 w-1 rounded-r-full`}
        />

        {/* MOBILE */}
        <div className="flex items-center justify-between gap-2 md:hidden">
          <div className="bg-hover ml-[16px] flex h-[54px] w-[50px] flex-col items-center justify-center rounded-[10px] md:hidden md:h-[126px] md:w-[128px]">
            <h5 className="text-grayCharcoal text-base font-bold leading-none md:text-[28px] md:font-semibold md:leading-normal">
              {formatMobileStartTime(startTime, duration)}
            </h5>
            <h5 className="text-grayCharcoal text-base font-bold leading-none md:leading-normal">
              {startTime && duration && duration < 1440
                ? moment(startTime).format("a")
                : ""}
            </h5>
          </div>

          <div className="flex flex-col">
            <div className="flex items-center gap-[12px]">
              <h4
                className={cn(
                  "mb-1 w-[240px] truncate text-base font-bold md:w-auto md:text-[18px]",
                  {
                    "line-through": isMeetingDeclined,
                  }
                )}
              >
                {!is_managed && !belongs_to_profile
                  ? "Non-Zoee Meeting"
                  : title &&
                    (title.length > 28 ? `${title.slice(0, 25)}...` : title)}
              </h4>
            </div>
            <div className={`flex flex-col ${reschedule ? "" : ""}`}>
              <div className="flex items-center gap-[16px]">
                <p className="text-base font-bold">
                  {formatDuration(duration)}
                </p>

                <div className="text-graySlate flex -translate-x-[5px] items-center text-base">
                {is_managed && belongs_to_profile
                      ? `${participants.length} guests`
                      : ""}
                  {!!is_managed && (
                    <div className="ml-[4px] flex items-center gap-2">
                      {participants.slice(0, 3).map((participant, i) => {
                        return (
                          <ContactAvatar
                            contact={participant}
                            width={24}
                            height={24}
                            bold={false}
                            fontSize={"text-base"}
                            key={i}
                          />
                        );
                      })}
                      {participants.length > 3 && (
                        <div>+{participants.length - 3}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {reschedule && type !== "quick" && (
                <div className="-ml-[16px] -mt-[7px]">
                  {isTodayPage ? (
                    <Button
                      variant="link"
                      className="font-thin"
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(`/${user.activeProfile}/scheduling`, {
                          state: { id },
                        })
                      }}
                    >
                      Reschedule Session
                    </Button>
                  ) : (
                    <Button
                      variant="link"
                      className="font-thin"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleMeetingSelect(id)
                      }}
                    >
                      Manage Sessions
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Message and Join Session Buttons */}
          <div>
            {Boolean(is_managed && belongs_to_profile) && (
              <div className="absolute bottom-4 right-4 flex flex-col items-center gap-[8px]">
                {!isPastMeeting() && inProgress ? (
                  <Button
                    variant="default"
                    size="sm"
                    onClick={(e: any) => {
                      startLoading()
                      window.location.href = `/${user.activeProfile}/sessions`
                    }}
                  >
                    Join
                  </Button>
                ) : (
                  <>
                    {!isPastMeeting() && (
                      <div className="relative">
                        <Button
                          variant="utility"
                          disabled={true}
                          onFocus={handleMouseOver}
                          onMouseOver={handleMouseOver}
                          onBlur={handleMouseOut}
                          onMouseOut={handleMouseOut}
                        >
                          Join
                        </Button>
                        {isHovering && (
                          <div
                            className="absolute bottom-full right-0 w-[250px] rounded-md border-2 border-black bg-white p-2 text-center text-black shadow-md"
                            data-popover
                            id="popover-description"
                            role="tooltip"
                          >
                            <p className="text-base font-bold">
                              The link to your session will be available shortly
                              before your session begins.
                            </p>
                            <p className="text-graySlate mt-2 text-sm">
                              Click join to view your future sessions details.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
                {calendar === "zoee" && (
                  <Button
                    className="-ml-[15px] flex w-[50px] items-center justify-center px-[6px] py-[4px] font-bold sm:-ml-0 sm:w-[80px]"
                    onClick={() => startMeetingChat(participants)}
                    variant="outline"
                  >
                    <SvgChat />
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>

        {/* DESKTOP */}
        <div className="hidden w-full md:block">
          <div className="flex w-full items-center justify-between">
            <div>
              <div className="mb-[2px] flex items-center gap-[12px] md:mb-[12px]">
                <h4
                  className={cn(
                    "max-w-[560px] truncate text-base font-bold md:text-[18px]",
                    {
                      "line-through": isMeetingDeclined,
                    }
                  )}
                >
                  {!is_managed && !belongs_to_profile
                    ? "Non-Zoee Meeting"
                    : title &&
                      (title.length > 28 ? `${title.slice(0, 25)}...` : title)}
                </h4>
                {medium === "zoee_video" && (
                  <Badge variant="notDefault">
                    <SvgVideoSolid className="mr-2 h-5 w-5" />
                    <span className="hidden md:inline-block">Video</span>
                  </Badge>
                )}
              </div>
              <div className="flex">
                <div className="-mb-[58px] flex flex-col">
                  <div className="text-graySlate border-grayMist hidden items-center border-r pr-[16px] text-base font-bold md:flex">
                    {startTime && (
                      <>{formatTime(startTime, endTime, duration)}</>
                    )}
                  </div>
                  {reschedule &&
                    type !== "quick" &&
                    (isTodayPage ? (
                      <Button
                        variant="link"
                        className="-ml-[16px] -mt-[12px] font-thin"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleMeetingSelect(id)
                        }}
                      >
                        Manage Sessions
                      </Button>
                    ) : (
                      <Button
                        variant="link"
                        className="-ml-[16px] -mt-[12px] font-thin"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleMeetingSelect(id)
                        }}
                      >
                        Manage Sessions
                      </Button>
                    ))}
                </div>
                <div className="text-graySlate flex items-center px-[16px] text-base">
                  <p className="text-base font-bold min-h-[20px]">
                    {is_managed && belongs_to_profile
                      ? `${participants.length} guests`
                      : ""}
                  </p>
                  {!!is_managed && (
                    <>
                      <div className="ml-[12px] flex items-center gap-2">
                        {participants.slice(0, 5).map((participant, i) => {
                          return (
                            <ContactAvatar
                              contact={participant}
                              width={24}
                              height={24}
                              bold={false}
                              fontSize={"text-base"}
                              key={i}
                            />
                          )
                        })}

                        {participants.length > 5 && (
                          <div>+{participants.length - 5}</div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {inProgress && !isPastMeeting() ? (
                  <>
                    <div className="bg-grayCloud my-auto h-6 w-px" />
                    <div className="ml-[16px] flex items-center gap-[8px]">
                      <SvgRecord className="text-vividRed h-5 w-5" />
                      Live
                    </div>
                  </>
                ) : (
                  <>
                    {isPastMeeting() && (
                      <>
                        <div className="bg-grayCloud my-auto mr-4 h-6 w-px" />
                        <Badge variant="secondary" className="rounded-sm">
                          Past
                        </Badge>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* Message and Join Session Buttons */}
            <div className="justfy-evenly flex flex-col items-center gap-2">
              {Boolean(is_managed && belongs_to_profile) && (
                <div>
                  {!isPastMeeting() && inProgress ? (
                    <Button
                      variant="default"
                      size="lg"
                      className={cn("", {
                        "absolute right-6 top-4":
                          participants.length > 4 && !isTodayPage,
                      })}
                      onClick={() => {
                        startLoading()
                        window.location.href = `/${user.activeProfile}/sessions`
                      }}
                    >
                      Join
                    </Button>
                  ) : (
                    <>
                      {!isPastMeeting() && (
                        <div className="relative">
                          <Button
                            variant="utility"
                            size="lg"
                            disabled={true}
                            onFocus={handleMouseOver}
                            onMouseOver={handleMouseOver}
                            onBlur={handleMouseOut}
                            onMouseOut={handleMouseOut}
                          >
                            Join
                          </Button>
                          {isHovering && (
                            <div
                              className="absolute bottom-full right-0 w-[250px] rounded-md border-2 border-black bg-white p-2 text-center text-black shadow-md"
                              data-popover
                              id="popover-description"
                              role="tooltip"
                            >
                              <p className="text-base font-bold">
                                The link to your session will be available
                                shortly before your session begins.
                              </p>
                              <p className="text-graySlate mt-2 text-sm">
                                Click join to view your future sessions details.
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
              {calendar === "zoee" && (
                <Button
                  className="flex items-center"
                  variant="outline"
                  size="lg"
                  onClick={() => startMeetingChat(participants)}
                >
                  <SvgChat />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isActiveInvite && (
        <div className="border-grayMist mt-[8px] flex items-center justify-between border-t px-[32px] pb-[16px] pt-[16px]">
          <p className="text-grayCharcoal text-base font-bold">
            You have been invited to attend
          </p>
          <div className="flex items-center gap-[6px]">
            <button className="btn-primary btn-secondary h-[32px] px-[24px]">
              Decline
            </button>
            <button className="btn-primary btn-blue h-[32px] px-[24px]">
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default MeetingPreviewCard
