import type { SVGProps } from "react";
export const SvgMaximize = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="M17.7 22h-2.5c-.6 0-1-.4-1-1s.4-1 1-1h2.5c1.3 0 2.3-1 2.3-2.3v-2.5c0-.6.4-1 1-1s1 .4 1 1v2.5c0 2.4-1.9 4.3-4.3 4.3M8.7 22H6.3C3.9 22 2 20.1 2 17.7v-2.5c0-.6.4-1 1-1s1 .4 1 1v2.5C4 19 5 20 6.3 20h2.5c.6 0 1 .4 1 1s-.5 1-1.1 1M3 9.7c-.6 0-1-.4-1-1V6.3C2 3.9 3.9 2 6.3 2h2.5c.6 0 1 .4 1 1s-.4 1-1 1H6.3C5 4 4 5 4 6.3v2.5c0 .5-.4.9-1 .9M21 9.7c-.6 0-1-.4-1-1V6.3C20 5 19 4 17.7 4h-2.5c-.6 0-1-.4-1-1s.4-1 1-1h2.5C20.1 2 22 3.9 22 6.3v2.5c0 .5-.4.9-1 .9" />
  </svg>
);
