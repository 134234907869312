export const accountSettingsLinks = [
    {
        id: 7,
        title: "Personal Info",
        text: "You know the drill. This is where you’ll update your username, contact information, and time zone.",
        link: "/account-settings/personal-info",
        soon: false,
        memberOnly: false
    },
    {
        id: 8,
        title: "Login & Security",
        text: "Set it but don’t forget it. This is where you’ll update your password.",
        link: "/account-settings/login-security",
        soon: false,
        memberOnly: false
    },
    {
        id: 9,
        title: "Payment Methods",
        text: "This is where you'll view and manage your Zoee payment methods.",
        link: "/account-settings/payment-methods",
        soon: false,
        memberOnly: false
    },
    {
        id: 10,
        title: "Calendar Settings",
        text: "Get connected. This is where you’ll connect your various calendar accounts for seamless scheduling.",
        link: "/account-settings/calendars",
        soon: false,
        memberOnly: true
    },
    {
        id: 11,
        title: "Notifications",
        text: "Want to change how we communicate? This is where you’ll decide when and how you’d like Zoee to ping you.",
        link: "/account-settings/notifications",
        soon: false,
        memberOnly: false
    }
]

export const resourceLinks = [
    {
        id: 12,
        title: "Request a Feature",
        text: "We know you have good ideas–so let’s hear it. This is where you’ll share what you want to see from Zoee.",
        link: "/feature-request",
        soon: false
    },
    {
        id: 13,
        title: "Report a Bug",
        text: "If you find a bug, let us know. We’ll squish it for you.",
        link: "/report-bug",
        soon: false
    },
]