import React, { useContext } from "react"
import CharLimit from "../ui/charLimit"
import CloseModal from "../CloseModal"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { SvgArrowLeftRight } from "../icons/arrow-left-right"
import { SvgQuoteRight2 } from "../icons"

interface Props {
  handleClick: any
  bentoSelection: number
  updateBento: (
    bentoSpot: number | null,
    selection: string,
    contentId?: number,
    content?: string,
    url?: string
  ) => void
}

export default function BentoImageFlow({
  handleClick,
  updateBento,
  bentoSelection,
}: Props) {
  const [urlToImageFile] = React.useState<string>(
    `https://blog-images-development.s3.amazonaws.com/1000003`
  )
  const { coachPublicProfileData } = useContext(CoachPrivateProfileContext)
  const [quoteText, setQuoteText] = React.useState<string>(() => {
    if (bentoSelection === 3) {
      return coachPublicProfileData?.bento_box_three?.data?.quote
    } else if (bentoSelection === 4) {
      return coachPublicProfileData?.bento_box_four?.data?.quote
    } else if (bentoSelection === 5) {
      return coachPublicProfileData?.bento_box_five?.data?.quote
    }
  })

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
      <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto">
        <CloseModal
          callback={() => {
            handleClick("cancel")
          }}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
          Featured Content
        </h2>
        <div className="flex gap-x-2 justify-center items-center">
          <SvgQuoteRight2 height="40" width="40" />
          <p className="font-bold text-[20px] sm:mt-4 md:-translate-y-2">
            Quote
          </p>
        </div>
        <Button
          variant={"link"}
          onClick={() => {
            handleClick("switch content")
          }}
        >
          <SvgArrowLeftRight />
          Switch content type
        </Button>

        <div className="flex flex-col justify-center items-center w-full mt-8">
          <span className="w-full flex justify-between">
            <p>
              <b>Quote</b>
            </p>
            <CharLimit inputText={quoteText} charNumber={250} />
          </span>
          <textarea
            name="quote"
            value={quoteText}
            maxLength={250}
            onChange={(e) => setQuoteText(e.target.value)}
            className={`whitespace-pre-line resize-none border-[1px] border-solid text-graySlate text-[16px] bg-white w-full h-[150px] py-[16px] px-[20px] rounded-[10px] focus:outline-0 focus:border focus:border-solid focus:border-black focus:text-black border-grayCloud mb-6`}
            placeholder={quoteText || "Enter your quote here..."}
          ></textarea>
        </div>
        <div className="flex gap-x-2">
          <Button
            size="lg"
            variant={"ghost"}
            onClick={() => {
              handleClick("cancel")
            }}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            variant="secondary"
            onClick={() => {
              updateBento(
                bentoSelection,
                "image",
                undefined,
                quoteText,
                urlToImageFile
              )
              handleClick("cancel")
            }}
            disabled={!quoteText?.trim()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
