import React, { FC } from "react";
import ServiceCardPreview from "./ServiceCardPreview";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { ServiceRequests, SessionsRequests } from "../../../api/app.service";
import useScrollToTop from "../../../hooks/useScrollToTop";
import BackToButton from "../../BackToButton";
import useLoading from "../../../hooks/useLoading";

interface Props {
    setShowAttachService: any;
    setDetailedPastSession: any;
    detailedPastSession: any;
    serviceChangeType: string;
    setServiceChangeType: any;
}

const AttachService: FC<Props> = ({
    setShowAttachService,
    setDetailedPastSession,
    detailedPastSession,
    serviceChangeType,
    setServiceChangeType
}) => {
    const { user } = React.useContext(UserCtx);
    const [services, setServices] = React.useState<any[]>([]);
    const {
        renderError, renderSuccess 
    } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();

    const goBack = () => {
        setShowAttachService(false);
        setDetailedPastSession({...detailedPastSession, show: true})
    };

    const attachServiceToSession = (service:any) => {
        if(user){
            startLoading();
            if(serviceChangeType === "update"){
                SessionsRequests.sessionUpdateService({
                    room_id: detailedPastSession.session.room_id,
                    service_usage_id: parseInt(service?.service_usage_id)
                }).then(() => {
                    renderSuccess("Attached service to the session");
                    setShowAttachService(false);
                    setDetailedPastSession({...detailedPastSession, show: true})
                }).catch(ex => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                }).finally(() => {
                    stopLoading();
                })
            } else if (serviceChangeType === "attach"){
                SessionsRequests.sessionAddService({
                    room_id: detailedPastSession.session.room_id,
                    service_usage_id: parseInt(service?.service_usage_id)
                }).then(() => {
                    renderSuccess("Attached service to the session");
                    setShowAttachService(false);
                    setDetailedPastSession({...detailedPastSession, show: true})
                }).catch(ex => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                }).finally(() => {
                    stopLoading();
                })
            }
        }
    }

    const getProfileServiceList = () => {
        if (user) {
            startLoading();
            const member = detailedPastSession.session.participant_list.filter((participant:any) => participant.profile_id !== user.activeProfileId)
            ServiceRequests.getServiceListProfile({
                coach_profile_id: user.activeProfileId,
                member_profile_id: parseInt(member[0].profile_id),
            })
                .then((data) => {
                    setServices(data.service_list);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                })
                .finally(() => {
                    stopLoading(); 
                });
        }
    };

    React.useEffect(() => {
        getProfileServiceList();
    }, []);

    useScrollToTop();

    return (
        <div className="px-[20px] md:px-0 pb-[140px] w-full md:w-[1080px] mx-auto flex flex-col -mt-0 md:-mt-[43px]">
            <BackToButton onClick={goBack} current="Session Details" prev="Session Details" />
            <h1 className="hidden md:block font-bold text-[36px] mb-[32px]">
                Services
            </h1>
            <div className="flex flex-wrap justify-center md:justify-start gap-[24px]">
                {services
                    ?.filter((service) => service.service_details.status === "active" && service.available_session_count > 0)
                    .map((service, idx) => (
                        <ServiceCardPreview
                            key={idx}
                            service={service}
                            showFeatures={false}
                            buttonText="Attach Service"
                            buttonCallback={() => attachServiceToSession(service)}
                            secondaryButtonCallback={null}
                            serviceChangeType={serviceChangeType}
                            setServiceChangeType={setServiceChangeType}
                        />
                    ))}
                {services?.filter((service) => service.service_details.status === "active" && service.available_session_count > 0)
                    .length === 0 && (
                    <div
                        className="w-full h-[296px] flex items-center justify-center 
                    rounded-[16px] border border-grayMist bg-[#f8f8f8]"
                    >
                        No Services Available to Attach
                    </div>
                )}
            </div>
        </div>
    );
};

export default AttachService;
