import type { SVGProps } from "react";
export const SvgVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 50 50"
    {...props}
  >
    <path fill="currentColor" d="M13.34 4.13 20.26 16H4v-1C4 9.48 8.05 4.92 13.34 4.13M33.26 16H22.57l-7-12h10.69zM46 15v1H35.57l-7-12H35c6.08 0 11 4.92 11 11M4 18v17c0 6.08 4.92 11 11 11h20c6.08 0 11-4.92 11-11V18zm27 14.19-7.99 4.54c-1.33.76-3.01-.18-3.01-1.69v-9.08c0-1.51 1.68-2.45 3.01-1.69L31 28.81a1.93 1.93 0 0 1 0 3.38" />
  </svg>
);
