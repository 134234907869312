import * as React from "react"
import { useNavigate } from "react-router"
import * as z from "zod"
import { EmailLogin } from "../../../api/auth.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { getUserStats } from "../../../utils/getUserStats"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import { LoginFormContext } from "./context"
import Loader from "../../ui/loader"
import useLoading from "../../../hooks/useLoading"
import Recaptcha from "../register-form/Recaptcha"
import { useLocation } from "react-router-dom"
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../../components/ui/dialog"
import { SvgClose } from "../../icons"

export const PasswordForm: React.FC = () => {
  const {
    password,
    setPassword,
    setView,
    email,
    getIdentityStatus,
    getSetupInfo,
    updateTimezone,
    onSuccess,
  } = React.useContext(LoginFormContext)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [loading, setLoading] = React.useState(false)
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const { renderError, getUser } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [showBbhcModal, setShowBbhcModal] = React.useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const passwordSchema = z
      .string()
      .min(8, "Invalid password")
      .regex(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/, {
        message: "Invalid password",
      })
      .safeParse(password)

    if (!passwordSchema.success) {
      setError(passwordSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    await login()
    setLoading(false)
  }

  const login = async () => {
    try {
      const data = await EmailLogin.loginEmail({
        email,
        password,
        stats: getUserStats(),
      })

      if (data.status === "info") {
        setShowBbhcModal(true)
      } else if (data) {
        if (isLocalStorageAvailable()) {
          localStorage.setItem("user", JSON.stringify(data))

          localStorage.setItem("user", JSON.stringify(data))
          localStorage.setItem(
            "isFoundingCoach",
            data.is_founding_coach ? "true" : "false"
          )
        } else {
          renderError("Local storage is not available")
        }

        await getIdentityStatus(data.user_id)
        await getSetupInfo(data.user_id)
        await updateTimezone(data.user_id, data.access_token)
        const user = await getUser()

        onSuccess(user)
      }
    } catch (error: any) {
      console.error(error)
      if (error.response.data.message === "Incorrect password.") {
        setError("Incorrect password")
      } else {
        console.error(error)
        renderError(error.response.data.message)
      }
    }
  }

  const forgotPassword = async () => {
    startLoading("Sending instructions")
    const pathname = location.pathname
    try {
      const resetPassword = await EmailLogin.resetPasswordSendLink({
        email,
        pathname,
      })
      if (resetPassword.message === "complete") {
        navigate("/?reset=true")
        setTimeout(() => navigate(pathname), 0)
      }
    } catch (error) {
      console.error(error)
      renderError("Something went wrong. Please try again.")
    } finally {
      stopLoading()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4"
      >
        <Input
          label="Password"
          placeholder="Enter your password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error}
          disabled={loading}
          autoFocus
        />

        <Recaptcha setIsNotRobot={setIsNotRobot} />

        <Button
          className="w-full"
          disabled={loading || !isNotRobot}
        >
          {loading ? <Loader /> : "Sign In"}
        </Button>

        <div className="flex flex-col gap-2">
          <Button
            variant="link"
            type="button"
            onClick={forgotPassword}
          >
            Forgot Password
          </Button>
          <div className="flex items-center gap-4">
            <div className="h-px bg-border flex-1"></div>
            <span className="text-sm">or</span>
            <div className="h-px bg-border flex-1"></div>
          </div>
          <Button
            variant="link"
            type="button"
            onClick={() => setView("one-time-password")}
          >
            Send One-time Password
          </Button>
        </div>
      </form>
      <Dialog
        open={showBbhcModal}
        onOpenChange={setShowBbhcModal}
      >
        <DialogContent className="">
          <button
            onClick={() => setShowBbhcModal(false)}
            className="absolute top-6 right-6 text-gray-500 hover:text-gray-800"
            aria-label="Close"
          >
            <SvgClose />
          </button>
          <DialogHeader>
            <h3 className="font-bold text-[28px] text-center mb-2">
              Wellcome to BBHC! 🎉
              <br />
              Your program access begins January 13
            </h3>
            <p>
              Your program access begins January 13th. We'll send you an email
              prior to your start date with additional details about getting
              started. We're thrilled to have you join our program and can't
              wait to begin this journey with you!
              <br />
              <br />
              Have questions? Contact IIN program support at
              <br />
              <a
                className="underline font-bold"
                href="mailto:businessblueprint@integrativenutrition.com"
                target="_blank"
                rel="noreferrer"
              >
                businessblueprint@integrativenutrition.com
              </a>
            </p>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  )
}
