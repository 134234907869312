import * as React from "react"
import PhoneInput from "react-phone-input-2"
import * as z from "zod"

import { UserExists } from "../../../api/auth.service"
import { Button } from "../../ui/button"
import { FieldError } from "../../ui/field-error"
import Loader from "../../ui/loader"
import { RegisterFormContext } from "./context"
import Recaptcha from "./Recaptcha"

const doesPhoneExist = async (phone: string | null) => {
  if (!phone) {
    return { found: false }
  }

  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        phone_number: phone,
      },
    })

    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const PhoneForm: React.FC = () => {
  const { phone, setPhone, createAccount } =
    React.useContext(RegisterFormContext)

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const phoneSchema = z
      .string()
      .min(7, "Must be at least 7 digits")
      .max(15, "Must be shorter than 15 digits")
      .safeParse(phone)

    if (!phoneSchema.success) {
      setError(phoneSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    const phoneExists = await doesPhoneExist(phone)

    if (phoneExists?.found) {
      setError("Phone number is already in use")
      setLoading(false)
      return
    }

    setError(undefined)

    await createAccount()
    setLoading(false)
  }

  const formRef = React.useRef<HTMLFormElement | null>(null)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      if (formRef.current) {
        formRef.current.requestSubmit() // Submit the form
      }

      const inputElement = event.currentTarget as HTMLInputElement
      inputElement.blur() // Blur the input
    }
  }

  const phoneError = !phone || phone.length < 11

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4"
        ref={formRef}
      >
        <div>
          <PhoneInput
            placeholder="Phone number"
            country="us"
            value={phone}
            onChange={setPhone}
            containerClass="default-input w-full"
            inputClass="w-full h-full rounded-lg focus:py-0"
            dropdownClass="rounded-md"
            inputProps={{
              "aria-label": "Phone number",
              autoFocus: true,
            }}
            onKeyDown={handleKeyPress}
          />
          <FieldError error={error} />
        </div>
        <Recaptcha setIsNotRobot={setIsNotRobot} />
        <Button
          className="w-full"
          disabled={loading || phoneError || !isNotRobot}
        >
          {loading ? <Loader /> : "Create Account"}
        </Button>
      </form>
    </>
  )
}
