import React, { FC, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Button } from "../../../../components/ui/button"
import Recaptcha from "../../../../components/auth/register-form/Recaptcha"
import { Input } from "../../../../components/ui/input"
import { useAuthModal } from "../../../../components/auth/use-auth-modal"
import { z } from "zod"
import { InputErrors } from "../../page"
import { doesEmailExist } from "../../../../components/auth/register-form/registration-form"
import Loader from "../../../../components/ui/loader"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { sendEmailLinkRegister } from "../../../../api/auth.service"
import { AccountType } from "../../../../components/auth/register-form/context"
import { useLocation } from "react-router-dom"
import { StepType } from "../.."
import RegularFlow from "./components/RegularFlow"
import IINFlow from "./components/IINFlow"

interface EmailStepProps {
  email: string
  emailError: string
  setInputErrors: (callback: (prev: InputErrors) => InputErrors) => void
  setEmail: (email: string) => void
  setStep: (step: StepType.VerifyEmail) => void
  setLoading: (loading: boolean) => void
  loading: boolean
  accountType: AccountType
  isIIN: boolean
}

const EmailsStep: FC<EmailStepProps> = ({
  email,
  emailError,
  setInputErrors,
  setEmail,
  setStep,
  setLoading,
  loading,
  accountType,
  isIIN,
}) => {
  const [isNotRobot, setIsNotRobot] = useState(false)
  const [showRecaptcha, setShowRecaptcha] = useState(false)
  const { showLogin } = useAuthModal()
  const { renderError } = useContext(CommonFunctionCtx)
  const location = useLocation()

  const emailSchema = z.string().email().safeParse(email)

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value
    setEmail(inputEmail)

    if (inputEmail && !showRecaptcha) {
      setShowRecaptcha(true)
    }

    setInputErrors((prev) => ({
      ...prev,
      emailError: "",
    }))
  }

  const handleBlur = () => {
    if (!emailSchema.success) {
      setInputErrors((prev) => ({
        ...prev,
        emailError: "Invalid Entry",
      }))
    }
  }

  const continueToNextStep = async () => {
    setLoading(true)
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setInputErrors((prev) => ({
        ...prev,
        emailError: "Email is already in use",
      }))
      setLoading(false)
      return
    } else {
      try {
        const pathname = location.pathname.slice(1)
        const response = await sendEmailLinkRegister({
          email: email,
          profile_type: accountType,
          pathname: isIIN ? `${pathname}?&flow=IIN&` : pathname,
        })
        localStorage.setItem("email_verify_token", response.verify_token)
        setLoading(false)
        setStep(StepType.VerifyEmail)
      } catch (error: any) {
        console.error(error.message)
        renderError(error.message)
        setLoading(false)
      }
    }
  }

  return (
    <div className="h-[100%] md:h-[100vh] flex flex-col md:flex-row">
      <section className="bg-[#F2F5F7] md:w-[50%] flex md:justify-end md:items-top md:pr-[35px] md:pt-[133.5px]">
        {isIIN ? <IINFlow /> : <RegularFlow />}
      </section>
      <section className="bg-[#FCFCFC] md:w-[50%] flex md:justify-start md:items-top md:pl-[35px] md:pt-[133.5px]">
        <div className="w-full h-fit md:w-[500px] md:p-10 px-4 py-10 flex flex-col">
          <div className="flex flex-col gap-2">
            <h1 className={`font-bold text-[32px] ${isIIN && "mb-6"}`}>Get Started</h1>
            {!isIIN && (
              <p className="text-sm mb-6">
                Already have an account?
                <Button
                  variant="link"
                  size="sm"
                  onClick={() => showLogin()}
                >
                  Sign In
                </Button>
              </p>
            )}
          </div>
          <form className="flex flex-col gap-6 w-full h-fit mb-4">
            <Input
              label="Business or Personal Email Address "
              placeholder="Business or Personal Email Address"
              value={email}
              error={emailError}
              onChange={handleEmailInputChange}
              onBlur={handleBlur}
            />
            {isIIN && (
              <p className="italic text-base mt-[-16px]">
                *This is not your IIN Learning Center account.
              </p>
            )}
            {showRecaptcha && <Recaptcha setIsNotRobot={setIsNotRobot} />}
            <Button
              type="submit"
              size="lg"
              disabled={!isNotRobot || emailError !== ""}
              onClick={(e) => {
                e.preventDefault()
                continueToNextStep()
              }}
            >
              {loading ? <Loader /> : "Continue"}
            </Button>
          </form>
          <p className="text-[#7D878F] text-sm">
            By continuing, you agree to Zoee`s{" "}
            <Link
              to="/terms-and-conditions"
              target="_blank"
              className="text-[#000]"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="/privacy-policy"
              target="_blank"
              className="text-[#000]"
            >
              Privacy Policy
            </Link>
          </p>
        </div>
      </section>
    </div>
  )
}

export default EmailsStep
