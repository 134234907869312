import React from "react"
import MeetingPreviewItem from "./MeetingPreviewItem"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { MeetingRequests, CalendarRequests } from "../../../api/app.service"
import moment from "moment"
import { parseISO, differenceInMinutes } from "date-fns"
import { useNavigate } from "react-router-dom"
import { Button } from "../../ui/button"
import { SvgCalendar } from "../../icons"

interface Props {
  contact: any
  setOpenUpcomingDetails: React.Dispatch<React.SetStateAction<boolean>>
  setUpcomingSessionDetailViewId: any
  filteredMeetings: any
  getMeetings: any
  setMeetings: any
  selectedState: string
  setSelectedState: any
  setShowRescheduleMeeting: any
  setBackToContacts: any
}

const MeetingsPreviewElement: React.FC<Props> = ({
  contact,
  setOpenUpcomingDetails,
  setUpcomingSessionDetailViewId,
  filteredMeetings,
  getMeetings,
  setMeetings,
  selectedState,
  setSelectedState,
  setShowRescheduleMeeting,
  setBackToContacts,
}) => {
  const { user } = React.useContext(UserCtx)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [showCount, setShowCount] = React.useState<number>(2)
  const navigate = useNavigate()

  const loadMore = () => {
    setShowCount(showCount + 2)
  }

  const loadLess = () => {
    setShowCount(2)
  }

  const handleStartCreateMeeting = (contact: any) => {
    navigate(`/${user.activeProfile}/scheduling`, {
      state: { contact },
    })
  }

  const checkForUserCalendars = () => {
    if (user) {
      CalendarRequests.getCalendarList({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          getMeetings()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  React.useEffect(() => {
    checkForUserCalendars()
  }, [])

  return (
    <div className="w-full main-shadow rounded-[16px] p-[16px] md:p-[40px] relative">
      <div className="flex items-center justify-between mb-[20px]">
        <h2 className="font-bold md:font-semibold text-[18px] md:text-[28px]">
          My Sessions
        </h2>
        <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
          <button
            className={`flex items-center justify-center font-bold text-base rounded-[8px]
                        ${
                          selectedState === "upcoming"
                            ? "text-mainBlack bg-white attendee-toggle-shadow"
                            : "text-gray"
                        } py-[4px] px-[24px]`}
            onClick={() => setSelectedState("upcoming")}
          >
            Upcoming
          </button>
          <button
            className={`flex items-center justify-center font-bold text-base rounded-[8px]
                        ${
                          selectedState === "past"
                            ? "text-mainBlack bg-white attendee-toggle-shadow"
                            : "text-gray"
                        } py-[4px] px-[24px]`}
            onClick={() => setSelectedState("past")}
          >
            Past
          </button>
        </div>
      </div>
      <div className="flex flex-col mb-[24px] gap-[8px] w-full">
        {filteredMeetings.length > 0 ? (
          <>
            {filteredMeetings.slice(0, showCount).map((meeting: any) => (
              <MeetingPreviewItem
                key={meeting.id}
                id={meeting.id}
                meeting={meeting}
                title={meeting.meetingName}
                is_managed={meeting.is_managed}
                belongs_to_profile={meeting.belongs_to_profile}
                inProgress={meeting.inProgress}
                endTime={meeting.endTime || null}
                participants={meeting.contacts}
                selectedState={selectedState}
                setOpenUpcomingDetails={setOpenUpcomingDetails}
                setUpcomingSessionDetailViewId={setUpcomingSessionDetailViewId}
                setShowRescheduleMeeting={setShowRescheduleMeeting}
                setBackToContacts={setBackToContacts}
              />
            ))}
          </>
        ) : (
          <div className="flex flex-col gap-[20px] pt-[90px] pb-[100px] text-center w-full font-bold text-[16px]">
            <p className="max-w-[214px] md:max-w-[428px] mx-auto">
              You don't have any meetings scheduled, send {contact.contact_name}{" "}
              an invite.
            </p>
            <Button
              onClick={() => handleStartCreateMeeting(contact)}
              className="w-fit mx-auto flex items-center gap-[10px]"
            >
              Schedule Session
              <SvgCalendar />
            </Button>
          </div>
        )}
      </div>
      <div className="flex items-center justify-center gap-[12px] mx-auto">
        {showCount > 2 && (
          <button
            className="btn-primary btn-secondary h-[32px]"
            onClick={loadLess}
          >
            Show Less
          </button>
        )}
        <Button
          className={`${
            filteredMeetings.length === 0 && "hidden"
          } h-[32px] text-md`}
          onClick={loadMore}
          disabled={showCount >= filteredMeetings.length}
        >
          Load More
        </Button>
      </div>
    </div>
  )
}

export default MeetingsPreviewElement
