import type { SVGProps } from "react";
export const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="currentColor" d="M22 10.362C22 5.752 18.002 2 13.088 2c-4.913 0-8.912 3.752-8.912 8.362 0 .126.014.245.02.367a5.258 5.258 0 0 0-.195 8.402l-.182 1.436A1.29 1.29 0 0 0 5.094 22c.229 0 .453-.064.648-.184l2.269-1.346a5.86 5.86 0 0 0 4.105-1.807c.258.025.514.055.777.06l3.73 2.216a1.624 1.624 0 0 0 2.45-1.593l-.313-2.535A8.1 8.1 0 0 0 22 10.362m-10.706 7.136a4.53 4.53 0 0 1-3.48 1.58.7.7 0 0 0-.356.098l-2.223 1.32.202-1.59a.7.7 0 0 0-.295-.662 3.92 3.92 0 0 1-1.746-3.24 3.99 3.99 0 0 1 1.897-3.35 4.6 4.6 0 0 1 2.521-.734 4.266 4.266 0 0 1 4.419 4.084 3.8 3.8 0 0 1-.939 2.494m6.324-1.578a.7.7 0 0 0-.291.657l.362 2.938a.23.23 0 0 1-.105.228.21.21 0 0 1-.241 0l-3.898-2.315a.7.7 0 0 0-.374-.099q.117-.237.21-.486.013-.026.024-.054a5 5 0 0 0 .25-.973q.069-.403.073-.812a5.66 5.66 0 0 0-5.814-5.48 6 6 0 0 0-.968.082q-.375.056-.739.16-.04.012-.08.026a6 6 0 0 0-.441.15 7.31 7.31 0 0 1 7.502-6.547c4.145 0 7.517 3.126 7.517 6.967a6.76 6.76 0 0 1-2.987 5.559" />
  </svg>
);
