import type { SVGProps } from "react";
export const SvgBlackSolidCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M35 13.8358V16.5H5V13.8358C5 11.0149 7.25 8.66418 9.95 8.66418H11.15V11.3284C11.15 12.1119 11.75 12.5821 12.35 12.5821C12.95 12.5821 13.55 11.9552 13.55 11.3284V7.25373C13.7 6.62687 14.3 6 15.05 6C15.8 6 16.25 6.62687 16.25 7.25373V8.50746H22.55V11.1716C22.55 11.9552 23.15 12.4254 23.75 12.4254C24.35 12.4254 24.95 11.7985 24.95 11.1716V7.25373C24.95 6.47015 25.55 6 26.15 6C26.9 6 27.5 6.62687 27.5 7.25373V8.50746H30.05C32.75 8.66418 35 11.0149 35 13.8358Z"
      fill="black"
    />
    <path
      d="M5 19.5H35V28.545C35 30.975 32.75 33 30.05 33H9.95C7.25 33 5 30.975 5 28.545V19.5Z"
      fill="black"
    />
  </svg>
);
