import type { SVGProps } from "react";
export const SvgBank = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      fill="currentColor"
      d="m7.313 11.69 12.94-6.513a1.66 1.66 0 0 1 1.49 0l12.94 6.513c1.573.792 1.014 3.178-.745 3.178H8.058c-1.759 0-2.319-2.386-.745-3.178M11 31.645V18.223h3.334v13.422h5V18.223h3.333v13.422h5V18.223H31v13.422h3.334c.92 0 1.666.751 1.666 1.677C36 34.25 35.254 35 34.334 35H7.667C6.747 35 6 34.25 6 33.322c0-.926.746-1.677 1.667-1.677z"
    />
  </svg>
);
