import React from "react";
import moment from "moment"
import { UserCtx } from "../../../context/userContext";

interface Props {
    service: any;
    serviceUsageHistory: any[];
}

const ServiceUsageHistory: React.FC<Props> = ({
    service,
    serviceUsageHistory
}) => {
    const { user } = React.useContext(UserCtx);
    const timezone = user.timezone.value;

    const getSessionDuration = (session:any) => {
        const end = moment(session.meeting_end_time);
        const start = moment(session.meeting_start_time);
        const duration = moment.duration(end.diff(start));
        const asMinutes = duration.asMinutes();
        return `${Math.ceil(asMinutes)} mins`
    }

    return (
        <>
            {/* SUMMARY TABLE */}
            <table className="w-full">
                {/* HEADER ROW */}
                <thead>
                    <tr className="text-graySlate text-base">
                        <th className="text-left pb-[8px]">DATE</th>
                        <th className="text-left pb-[8px]">TIME</th>
                        <th className="text-left pb-[8px]">STATUS</th>
                        <th className="text-left pb-[8px]">DURATION</th>
                        {/* <th className="text-left pb-[8px]">LINK</th> */}
                    </tr>
                </thead>
                {/* DATA ROWS */}
                <tbody>
                    {serviceUsageHistory.map((session:any) => (
                        <tr className="text-base [&:not(:last-child)]:border-b-[1px] border-grayCloud">
                            <td className="text-left py-[8px]">{moment(session?.meeting_start_time).tz(timezone).format("dddd, MMMM DD, YYYY")}</td>
                            <td className="text-left py-[8px]">
                                {moment.utc(session?.meeting_start_time).tz(timezone).format("hh:mm a")}
                                &nbsp;-&nbsp;
                                {moment.utc(session?.meeting_end_time).tz(timezone).format("hh:mm a")}
                            </td>
                            <td className="py-[8px] flex justify-start">
                                <div
                                    className={`rounded-[8px] py-[4px] px-[8px] flex items-center justify-center font-bold
                                    ${session.meeting_status === "Completed" ? "bg-green text-white" : "bg-grayMist"}`}
                                >{session?.meeting_status}</div>
                            </td>
                            <td className="text-left py-[8px]">{getSessionDuration(session)}</td>
                            {/* <td 
                                className="text-left py-[8px]"
                            >
                                <button>View Session</button>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {serviceUsageHistory?.length === 0 && (
                <div className="text-grayCharcoal font-bold text-base mt-[12px]">No sessions exist for this service.</div>
            )}
        </>
    );
};

export default ServiceUsageHistory;
