import type { SVGProps } from "react";
export const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.707 18.97c.151 0 3.596-.556 3.596-.556.593-.099 1.35-.49 1.763-.91l8.004-8.147c1.996-2.032 1.938-4.037-.176-5.958-2.111-1.92-4.195-1.864-6.192.169l-8.004 8.147c-.412.419-.775 1.169-.844 1.745l-.361 3.04c-.089.746.153 1.44.662 1.903.406.37.956.567 1.552.567m8.057-14.436c.664-.677 1.315-1.128 2.062-1.128.607 0 1.276.298 2.064 1.015 1.762 1.601 1.322 2.745.118 3.97l-1.064 1.084c-2.105-.332-3.78-1.855-4.244-3.859zM5.305 13.62c.034-.274.258-.74.455-.94l5.781-5.886c.725 1.846 2.289 3.267 4.245 3.858l-5.782 5.885c-.197.2-.667.443-.949.49l-3.135.518c-.32.055-.586-.006-.761-.165-.174-.158-.25-.415-.214-.721zM21.5 21.297a.72.72 0 0 1-.73.703H3.23a.72.72 0 0 1-.73-.703c0-.388.327-.703.73-.703h17.54c.403 0 .73.315.73.703"
    />
  </svg>
);
