import React, {ChangeEvent} from "react";
import { Notification } from "../../types/notificationTypes";
import { UserCtx } from "../../context/userContext";

type Props = {
    notifications: Notification[];
    handleSelect: (e: ChangeEvent<HTMLInputElement>, profileNotificationId: number, type: string) => void;
};

const NotificationItem: React.FC<Props> = ({ notifications, handleSelect }) => {
    const hasBothCoachAndMemberSettings = notifications.length > 1;
    const { user } = React.useContext(UserCtx)
    const [userIsCoach, setUserIsCoach] = React.useState<boolean>(false);
    let profileNotification: Notification | undefined;
    let hasPush = false;
    let hasEmail = false;
    let hasSMS = false;
    let coachProfileNotification: Notification | undefined;
    let coachHasPush = false;
    let coachHasEmail = false;
    let coachHasSMS = false;
    let memberProfileNotification: Notification | undefined;
    let memberHasPush = false;
    let memberHasEmail = false;
    let memberHasSMS = false;
    if (hasBothCoachAndMemberSettings) {
        coachProfileNotification = notifications.find((n) => n.profile_type === "coach");
        if (coachProfileNotification?.notification_types.includes("push")) {
            coachHasPush = true;
        }
        if (coachProfileNotification?.notification_types.includes("email")) {
            coachHasEmail = true;
        }
        if (coachProfileNotification?.notification_types.includes("sms")) {
            coachHasSMS = true;
        }

        memberProfileNotification = notifications.find((n) => n.profile_type === "member");
        if (memberProfileNotification?.notification_types.includes("push")) {
            memberHasPush = true;
        }
        if (memberProfileNotification?.notification_types.includes("email")) {
            memberHasEmail = true;
        }
        if (memberProfileNotification?.notification_types.includes("sms")) {
            memberHasSMS = true;
        }
    } else {
        profileNotification = notifications[0];
        if (notifications[0]?.notification_types.includes("push")) {
            hasPush = true;
        }
        if (notifications[0]?.notification_types.includes("email")) {
            hasEmail = true;
        }
        if (notifications[0]?.notification_types.includes("sms")) {
            hasSMS = true;
        }
    }

    React.useEffect(()=>{
        if (user.activeProfile === "coach") {
            setUserIsCoach(true)
        }
    })

    return (
    <div className="flex justify-between w-full lg:gap-[32px] gap-[2px]">
        <div className="w-full">
            <h4 className="font-bold text-base text-left">{notifications[0].name}</h4>
        </div>
        {!hasBothCoachAndMemberSettings ? (
            <div className="min-w-fit">
                <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
                    {hasPush && (
                        <label className="min-w-[90px] flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                            <input
                                type="checkbox"
                                className="accent-blurple"
                                name={`push-${profileNotification?.profile_notification_id}`}
                                checked={profileNotification?.push_on}
                                onChange={(e) =>
                                    handleSelect(
                                        e,
                                        profileNotification?.profile_notification_id || 0,
                                        "push"
                                    )
                                }
                            />
                            In-app
                        </label>
                    )}
                    {hasEmail && (
                        <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                            <input
                                type="checkbox"
                                className="accent-blurple"
                                name={`email-${profileNotification?.profile_notification_id}`}
                                checked={profileNotification?.email_on}
                                onChange={(e) =>
                                    handleSelect(
                                        e,
                                        profileNotification?.profile_notification_id || 0,
                                        "email"
                                    )
                                }
                            />
                            Email
                        </label>
                    )}
                    {hasSMS && (
                        <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                            <input
                                type="checkbox"
                                className="accent-blurple"
                                name={`sms-${profileNotification?.profile_notification_id}`}
                                checked={profileNotification?.sms_on}
                                onChange={(e) =>
                                    handleSelect(
                                        e,
                                        profileNotification?.profile_notification_id || 0,
                                        "sms"
                                    )
                                }
                            />
                            SMS
                        </label>
                    )}
                    <label className="hidden md:flex min-w-fit items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                        <input
                            type="checkbox"
                            className="accent-blurple"
                            name={`none-${profileNotification?.profile_notification_id}`}
                            checked={!profileNotification?.push_on && !profileNotification?.sms_on && !profileNotification?.email_on}
                            onChange={(e) =>
                                handleSelect(
                                    e,
                                    profileNotification?.profile_notification_id || 0,
                                    "none"
                                )
                            }
                        />
                        None
                    </label>
                </div>
            </div>
        ) : (
            <div className="min-w-fit flex flex-col gap-[15px]">
                <div className={`flex flex-col ${userIsCoach? "" : "hidden"}`}>
                    <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
                        {coachHasPush && (
                            <label className="min-w-[90px] flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    className="accent-blurple"
                                    name={`push-${coachProfileNotification?.profile_notification_id}`}
                                    checked={coachProfileNotification?.push_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            coachProfileNotification?.profile_notification_id || 0,
                                            "push"
                                        )
                                    }
                                />
                                In-app
                            </label>
                        )}
                        {coachHasEmail && (
                            <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    className="accent-blurple"
                                    name={`email-${coachProfileNotification?.profile_notification_id}`}
                                    checked={coachProfileNotification?.email_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            coachProfileNotification?.profile_notification_id || 0,
                                            "email"
                                        )
                                    }
                                />
                                Email
                            </label>
                        )}
                        {coachHasSMS && (
                            <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    className="accent-blurple"
                                    name={`sms-${coachProfileNotification?.profile_notification_id}`}
                                    checked={coachProfileNotification?.sms_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            coachProfileNotification?.profile_notification_id || 0,
                                            "sms"
                                        )
                                    }
                                />
                                SMS
                            </label>
                        )}
                        <label className="hidden md:flex min-w-fit items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                            <input
                                type="checkbox"
                                className="accent-blurple"
                                name={`none-${coachProfileNotification?.profile_notification_id}`}
                                checked={!coachProfileNotification?.push_on && !coachProfileNotification?.sms_on && !coachProfileNotification?.email_on}
                                onChange={(e) =>
                                    handleSelect(
                                        e,
                                        coachProfileNotification?.profile_notification_id || 0,
                                        "none"
                                    )
                                }
                            />
                            None
                        </label>
                    </div>
                </div>

                <div className={`flex flex-col ${userIsCoach? "hidden" : ""}`}>
                    <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
                        {memberHasPush && (
                            <label className="min-w-[90px] flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    className="accent-blurple"
                                    name={`push-${memberProfileNotification?.profile_notification_id}`}
                                    checked={memberProfileNotification?.push_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            memberProfileNotification?.profile_notification_id || 0,
                                            "push"
                                        )
                                    }
                                />
                                In-app
                            </label>
                        )}
                        {memberHasEmail && (
                            <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    className="accent-blurple"
                                    name={`email-${memberProfileNotification?.profile_notification_id}`}
                                    checked={memberProfileNotification?.email_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            memberProfileNotification?.profile_notification_id || 0,
                                            "email"
                                        )
                                    }
                                />
                                Email
                            </label>
                        )}
                        {memberHasSMS && (
                            <label className="min-w-fit flex items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                                <input
                                    type="checkbox"
                                    
                                    name={`sms-${memberProfileNotification?.profile_notification_id}`}
                                    checked={memberProfileNotification?.sms_on}
                                    onChange={(e) =>
                                        handleSelect(
                                            e,
                                            memberProfileNotification?.profile_notification_id || 0,
                                            "sms"
                                        )
                                    }
                                />
                                SMS
                            </label>
                        )}
                        <label className="hidden md:flex min-w-fit items-center justify-center font-bold text-base rounded-[8px] py-[2px] px-[12px] mx-[2px] gap-2">
                            <input
                                type="checkbox"
                                className="accent-blurple"
                                name={`none-${memberProfileNotification?.profile_notification_id}`}
                                checked={!memberProfileNotification?.push_on && !memberProfileNotification?.sms_on && !memberProfileNotification?.email_on}
                                onChange={(e) =>
                                    handleSelect(
                                        e,
                                        memberProfileNotification?.profile_notification_id || 0,
                                        "none"
                                    )
                                }
                            />
                            None
                        </label>
                    </div>
                </div>
            </div>
        )}
    </div>
);

};

export default NotificationItem;
