import { FC, useState } from "react";
import { useAuthModal } from "../../auth/use-auth-modal";
import { SvgLightning } from "../../icons";
import { Button } from "../../ui/button";
import { Switch } from "../../ui/switch";

const PricingToggleSection: FC = () => {
  const [isToggled, setIsToggled] = useState<boolean>(true);

  const handleToggle = () => {
    setIsToggled(() => !isToggled);
  };

  const { showRegister } = useAuthModal();

  return (
    <section className="px-[24px] pb-[188px] md:pb-[212px]">
      <div className="max-w-[862px] bg-white  mx-auto flex flex-col justify-center items-center py-[64px] rounded-[16px] border border-grayMist">
        <h3 className="font-bold text-[56px] text-grayCharcoal mb-[24px]">
          Pricing
        </h3>
        <div className="flex flex-col md:flex-row md:gap-[44px] gap-[8px] mb-[32px]">
          <div className="flex items-center gap-[10px]">
            <h4 className="font-bold text-[16px]">
              Annual
              <span className="text-green">&nbsp;(Save 16%)</span>
            </h4>
            <Switch defaultChecked={isToggled} onCheckedChange={handleToggle} />
            <h4 className="font-bold text-[16px]">Monthly</h4>
          </div>
          <div className="text-center">
            {isToggled
              ? (
                <>
                  <h3 className="font-bold text-[48px]">
                    $49
                    <span className="text-base text-graySlate font-normal">
                      /month
                    </span>
                  </h3>
                </>
              )
              : (
                <>
                  <h3 className="font-bold text-[48px]">
                    $490
                    <span className="text-base text-graySlate font-normal">
                      /year
                    </span>
                  </h3>
                </>
              )}
          </div>
        </div>
        <div className="max-w-[596px] bg-[#DEC8FB] bg-opacity-40 flex items-center gap-[20px] rounded-[16px] px-[24px] py-[20px] mx-[36px] mb-[32px]">
          <div className="flex items-center justify-center bg-white rounded-full h-[40px] w-[40px] min-w-[40px]">
            <SvgLightning />
          </div>
          <div className="flex flex-col justify-center gap-[4px]">
            <h5 className="font-bold">Let&apos;s Do Life Together</h5>
            <p className="gray-charcoal text-base">
              With a Zoee membership, you&apos;ll get back to coaching clients
              and away from juggling your back office.
            </p>
          </div>
        </div>
        <Button
          onClick={() => showRegister({ accountType: "coach" })}
          size="lg"
        >
          Start Now
        </Button>
      </div>
    </section>
  );
};

export default PricingToggleSection;
