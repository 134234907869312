import type { SVGProps } from "react";
export const SvgUsersSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.6 10.4c0 .8-.4 1.6-.9 2.1-.8.8-1.5 1.8-1.9 2.9-.5 1.2-.4 2.6.4 3.7.1.1.3.4.3.4h-5c-.5 0-.9-.1-1.3-.4-1-.6-1.3-1.8-.9-2.7.5-1.5 1.7-2.6 3.1-3.3-.9-.8-1.4-2.1-1.1-3.5S4.7 7 6.2 6.7c1.7-.3 3.2.5 4 1.9.2.5.4 1.1.4 1.8"
    />
    <path
      fill="currentColor"
      d="M22.8 16c-.8-2-2.4-3.4-4.3-4.1 1.1-.8 1.8-2.1 1.8-3.5C20.3 5.9 18.4 4 16 4s-4.3 1.9-4.3 4.3c0 1.4.7 2.7 1.8 3.5-2 .7-3.6 2.2-4.4 4.2-.3.8-.2 1.6.2 2.3.5.7 1.3 1.2 2.2 1.2h8.9c.9 0 1.7-.4 2.2-1.2.4-.7.5-1.6.2-2.3"
    />
  </svg>
);
