export const handleOutsideClick = (
  closeModalFn: (statement: boolean) => void,
  closeModalState: boolean
) => {
  const outsideClick = (e: MouseEvent) => {
    setTimeout(() => {
      const dialogElement = document.querySelector('[data-state="open"]');
      const recaptchaElement = document.querySelector(".rc-imageselect");

      if (recaptchaElement && recaptchaElement.contains(e.target as Node)) {
        return;
      } else if (dialogElement && !dialogElement.contains(e.target as Node)) {
        closeModalFn(false);
      }
    }, 0);
  };

  if (closeModalState) {
    document.addEventListener("mousedown", outsideClick);
  } else {
    document.removeEventListener("mousedown", outsideClick);
  }

  return () => {
    document.removeEventListener("mousedown", outsideClick);
  };
};
