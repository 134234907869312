import * as React from "react";
import { VerifyEmail } from "../../api/auth.service";
import { UserInfo } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../components/LoadingPage";
import useLoading from "../../hooks/useLoading";

export default function ConfirmEmail() {
  const { user } = React.useContext(UserCtx);
  const { renderError, getUser, hideAlert } = React.useContext(
    CommonFunctionCtx,
  );
  const navigate = useNavigate();
  const { stopLoading } = useLoading();
  const params = new URLSearchParams(window.location.search);
  const redirectPath = params.get("redirectPath") || "/"
  const code = params.get("t");

  const sendConfirmationCode = (code: any) => {
    VerifyEmail.verifyEmailCode({
      verification_code: code,
    })
      .then((data) => {
        if (data?.message === "complete") {
          checkForVerification()
        }
      })
      .catch((err) => {
        console.error(err);
        renderError(err.response.data.message);
      });
    stopLoading();
  };

  const checkForVerification = async () => {
    hideAlert()
    await getUser()
    
    const userToValidateEmail = await UserInfo.getUser({
      user_id: parseInt(user.user_id),
    })

    if (!userToValidateEmail.user_data.email_verified) {
      renderError("Please check your email to verify")
    } else {
      navigate(redirectPath)
    }
  }

  React.useEffect(() => {
    if (code) {
      sendConfirmationCode(code); 
    } else {
      checkForVerification()
    }
  }, []);

  return <LoadingPage />;
}
