import React from "react";
import CloseModal from "../CloseModal";

interface Props {
  setShowIndividualDetails: any;
}

const IndividualDetails: React.FC<Props> = ({ setShowIndividualDetails }) => {

  return (
   <div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setShowIndividualDetails}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Individual Services
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
          One-on-One Coaching Services involve working with an individual client to achieve their goals. 
          At Zoee, we offer multiple service workflows, including consultation, single session, packages, 
          and recurring programs to cater to your unique needs. You can choose the frequency of sessions 
          and payments depending on your preferences, ranging from single sessions to weekly, bi-weekly, 
          or monthly schedules.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IndividualDetails;
