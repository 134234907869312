import React from "react"
import { Button } from "../ui/button"
import { SvgCheckCircle, SvgClose } from "../icons"
import { planFeatures } from "../PricingPage/PricingPlanCard"

const TrialSubscriptionCard: React.FC = () => {
  const [cardColor, setCardColor] = React.useState<string>("")

  return (
    <div
      className={`${cardColor} md:w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div className="absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px]"></div>
      <div className="h-[848px] px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            Free Trial
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            For coaches who want to try out Zoee
          </div>
        </div>
        <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
          <div className="self-stretch h-[76px] flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch h-[76px] flex-col justify-center items-start gap-0.5 flex">
              <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
                $0/mo
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[12px] justify-center w-full">
          <div className="flex flex-col gap-[12px]">
            <Button
              variant="secondary"
              size={"lg"}
              className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
              disabled
            >
              Current Plan
            </Button>
          </div>
          <div className="flex flex-col gap-[16px]">
            {planFeatures.map((feature, idx) => (
              <div
                className="flex items-center gap-[16px]"
                key={`${idx}${feature}`}
              >
                {idx > 8 ? (
                  <SvgClose stroke="red" />
                ) : (
                  <SvgCheckCircle stroke="#4750F5" />
                )}
                <p className="text-large">{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialSubscriptionCard
