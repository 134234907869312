import type { SVGProps } from "react";
export const SVGwarningsign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <g id="Frame">
      <path
        id="Vector"
        fill="#FCB900"
        stroke="#FCB900"
        strokeWidth="1.5"
        d="M5.822 9.683c2.413-4.271 3.62-6.406 5.276-6.956a4.45 4.45 0 0 1 2.804 0c1.656.55 2.863 2.685 5.276 6.956 2.414 4.27 3.62 6.406 3.259 8.146-.2.958-.69 1.826-1.402 2.48C19.741 21.5 17.327 21.5 12.5 21.5s-7.241 0-8.535-1.19a4.66 4.66 0 0 1-1.402-2.48c-.362-1.74.845-3.876 3.259-8.147Z"
      ></path>
      <path
        id="Vector_2"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.492 16h.01"
      ></path>
      <path
        id="Vector_3"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.5 13V9"
      ></path>
    </g>
  </svg>

)
