import type { SVGProps } from "react";
export const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 19c-.803 0-1.506-.305-2.008-.916l-5.521-6.719c-.502-.61-.603-1.527-.302-2.24.302-.712 1.004-1.22 1.807-1.22h2.31V4.34C8.285 3.018 9.39 2 10.694 2h2.71c1.306 0 2.31 1.018 2.31 2.341v3.563h2.308c.804 0 1.506.407 1.808 1.222a2.07 2.07 0 0 1-.302 2.138l-5.521 6.82c-.502.61-1.205.916-2.008.916m-6.024-8.958 5.522 6.719c.2.203.402.203.502.203s.301 0 .502-.203l5.521-6.72h-3.312c-.603 0-1.004-.406-1.004-1.017V4.34c0-.203-.201-.305-.302-.305h-2.71c-.2 0-.402.204-.402.305v4.581c0 .61-.401 1.018-1.004 1.018H5.976z"
    />
    <path
      fill="currentColor"
      d="M18.418 22H5.483C3.493 22 2 20.5 2 18.5V16c0-.6.398-1 .995-1s.995.4.995 1v2.5c0 .8.697 1.5 1.493 1.5h13.034c.796 0 1.493-.7 1.493-1.5V16c0-.6.398-1 .995-1s.995.4.995 1v2.5c-.1 2-1.592 3.5-3.582 3.5"
    />
  </svg>
);
