import type { SVGProps } from "react";
export const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.148 11.4a.6.6 0 1 0 0 1.2h6.26v6.25a.6.6 0 0 0 1.2 0V12.6h6.258a.6.6 0 1 0 0-1.2h-6.258V5.144a.6.6 0 1 0-1.2 0V11.4z"
      clipRule="evenodd"
    />
  </svg>
);
