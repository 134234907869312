export const getLightTextColor = (color: string): string => {
  if (color === "#4750F5") {
    return "#DCE6FF";
  } else if (color === "#7291FF") {
    return "#ECF0FF";
  } else if (color === "#1DB685") {
    return "#D0EEE4";
  } else if (color === "#FFAF43") {
    return "#FBE7CC";
  } else if (color === "#9057BD") {
    return "#ECDCF9";
  } else if (color === "#63696F") {
    return "#F0F0F0";
  }
  return "#FFFFFF";
};

export const getDarkColor = (color: string): string => {
  if (color === "#4750F5") {
    return "#B0C5FF";
  } else if (color === "#7291FF") {
    return "#B5CAFF";
  } else if (color === "#1DB685") {
    return "#8CF5C9";
  } else if (color === "#FFAF43") {
    return "#FFC777";
  } else if (color === "#9057BD") {
    return "#C797EB";
  } else if (color === "#63696F") {
    return "#B6BAC1";
  }
  return "#4750F5";
};