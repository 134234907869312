import previewImage from "../../../assets/blog/whatIsZoee.webp";
import { Post } from "../../../types/postTypes";

export const whatIsZoee: Post = {
    metadata: {
        id: 1,
        title: "The Next Big Thing for Coaches? A Revolutionary Virtual Office",
        author: "Zoee",
        date: "September 26, 2022",
        urlSlug: "what-is-zoee",
        description:
            "We offer so much more than a future virtual office for coaches. We’re redefining the way you connect, streamlining your online business, and cultivating a community of coaches—just like you.",
        previewImg: previewImage,
        tags: ["Coach", "Mental Health"],
    },
    content: [
        {
            type: "paragraph",
            text: "A future virtual office and community for coaches and members.",
        },
        {
            type: "paragraph",
            text: "We're redefining the way you connect, streamlining your virtual business, and cultivating a community of coaches—just like you.",
        },
        {
            type: "header1",
            text: "Let's Face it: We're Disconnected",
        },
        {
            type: "paragraph",
            text: "Over the past decade, many challenges have caused loneliness to creep into our lives, workplaces, and homes. With the COVID-19 pandemic and more of our life being lived online, we're connecting faster and more frequently, driving a culture of always being ‘on” and never taking time away from the devices we're addicted to. In short, we feel disconnected. We feel sad. We feel stressed. We feel like there's something missing in our lives—because there is.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "Though you may live in a city with millions of people, a household with five kids, or work at a company with hundreds of peers, we have never been more stressed, anxious, depressed, diseased, and unwell as a community. Just because we",
                },
                {
                    type: "italic",
                    text: "should",
                },
                {
                    type: "normal",
                    text: "be connected doesn't mean we are connected. It means we're connected to the device that we're hoping will ease the pain. Spoiler alert: It doesn't.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Scrolling through hours of TikTok videos, endlessly sharing content with our friends on Instagram, and reading comment after comment on a Facebook post, we're all searching for the same thing: Someone that will understand us.",
        },
        {
            type: "paragraph",
            text: "While that world—our world—has been on a one-way train to “Disconnection-ville,” coaches have been getting certified, studying, and practicing how to get us off the train and back on a path to love, authentic connection, and benevolence. Coaches have seen our world begin to turn gray, sad, and lonely. They've been training to help us—and now it's time we help them.",
        },
        {
            type: "header1",
            text: "Where We Come In",
        },
        {
            type: "paragraph",
            text: "Before Zoee, there were only a few resources for supporting solopreneur coaches and consultants. Which means there's an entire industry of professionals looking for support to run their businesses. This is where Zoee comes in as a tool for building an ecosystem where coaches can find support, resources, clients, and a solution.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "To put it simply, we offer technology that supports every aspect of running a coaching business. We offer a one-stop shop for coaches to thrive as entrepreneurs to sync and schedule their calendars, automate their billing and invoicing, set up programs and services, and communicate with their clients. Because that's what you are—",
                },
                {
                    type: "italic",
                    text: "business owners.",
                },
                {
                    type: "normal",
                    text: "And it's high time you were treated as such.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "But we aren't stopping there. Zoee is elevating the way humans connect and communicate, ensuring a future where no one has to do life alone. How do we do that? It's through technology for coaches, but we're also bringing coaches together to support one another, and encouraging a community of members and coaches to do life together.",
        },
        {
            type: "paragraph",
            text: "As reported by PwC, coaching is the second-fastest growing industry in the world. That means coaches are in desperate need of someone to say, “I know what you're going through, and here's what you can do next.” Coaches are called to the work. Their purpose is to guide and help fellow humans navigate through change, difficult times, and hard seasons. But now, someone has to help them.",
        },
        {
            type: "header1",
            text: "We're Building Community",
        },
        {
            type: "paragraph",
            text: "As a brand-new coach or a seasoned professional, you're appreciated and accepted at Zoee. While we're currently welcoming coaches and their clients to the platform, soon, we'll be opening our doors to members (those seeking coaching). This will allow coaches to connect with other coaches, and members and coaches can get matched and make new connections—all on Zoee.",
        },
        {
            type: "paragraph",
            text: "Through life's struggles, evolutions, hard seasons, and celebrations, Zoee provides a transformative space for people to connect, feel seen, be heard, and have their lives changed for the better. We're building a community where people can do life together, accessing support and fostering genuine connection on demand. No other platform has offered such a robust solution for entrepreneurs while also providing our world with what it needs most: Authentic human connection.",
        },
        {
            type: "paragraph",
            text: "What would be possible if we created a world where technology served us and not the other way around? What would be possible if we set coaches up for success instead of challenging them to stay afloat? What would be possible if we could call our coach whenever we needed support, rather than feel isolated with nowhere to turn?",
        },
        {
            type: "paragraph",
            text: "At Zoee, we're thinking about what's possible. We're creating technology that serves us, supports our need for connection, and offers coaches a way to run their businesses with ease. We relieve the struggle of juggling multiple systems—to the coaches holding their businesses together with duct tape, chewing gum, and hours of administrative work every day.",
        },
        {
            type: "paragraph",
            text: "We're here to uplift humanity because we're called to the work, just as coaches are called to theirs. In sum, we think our tagline says it best: Let's “Do Life Together.”",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office."
                },
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Written by Zoee."
                },
            ]
        },
    ],
};
