import React, { useRef } from "react";
import NotificationCenterItem from "./NotificationCenterItem";
import { Link } from "react-router-dom";
import { NotificationCtx } from "../../context/notificationContext";
import { NotificationRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import useOutsideClick from "../../hooks/useOutsideClick";
import moment from "moment";
import { Button } from "../ui/button";
import classes from "../../pages/dashboard/CoachDashboard/CoachDashboardBento.module.css"
import { SvgCheckCircle, SvgNotifications } from "../icons/";
import { Badge } from "../ui/badge";

type Props = {
    notifications: any[];
    setNotifications: any;
    setShowNotificationCenter?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    showNotificationCenter?: any;
    isTodayPage?:boolean
};

const NotificationCenter: React.FC<Props> = ({
    notifications,
    setNotifications,
    setShowNotificationCenter,
    showNotificationCenter,
    isTodayPage = false
}) => {
    const { hideNotificationAlert } = React.useContext(NotificationCtx);
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { user } = React.useContext(UserCtx);
    const notifRef = useRef(null);
    const [outsideClickCounter, setOutsideClickCounter] = React.useState<number>(0);

    const markRead = (notification_id: number) => {
        NotificationRequests.markRead({
            profile_id: user.activeProfileId,
            notification_ids: [notification_id],
        })
            .then((data) => {
                // Set as viewed locally so that user sees notification as viewed without refetching data
                const updatedNotifications = notifications.map((n) => {
                    if (n.id === notification_id) {
                        n.viewed = true;
                    }
                    return n;
                });
                setNotifications(updatedNotifications);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            });
    };

    const markUnread = (notification_id: number) => {
        NotificationRequests.markUnread({
            profile_id: user.activeProfileId,
            notification_ids: [notification_id],
        })
            .then((data) => {
                // Set as viewed locally so that user sees notification as viewed without refetching data
                const updatedNotifications = notifications.map((n) => {
                    if (n.id === notification_id) {
                        n.viewed = 0;
                    }
                    return n;
                });
                setNotifications(updatedNotifications);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            });
    };

    const markAllAsRead = () => {
        const notificationIds = notifications.map((n) => n.id);
        NotificationRequests.markRead({
            profile_id: user.activeProfileId,
            notification_ids: notificationIds,
        })
            .then((data) => {
                // Set as viewed locally so that user sees notification as viewed without refetching data
                const allReadNotifications = notifications.map((n) => {
                    n.viewed = true;
                    return n;
                });
                setNotifications(allReadNotifications);
            })
            .catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
            });
    };

    useOutsideClick(notifRef, () => {
        setOutsideClickCounter(1);
        if (outsideClickCounter > 0 && setShowNotificationCenter) {
            setShowNotificationCenter(false);
        }
    });

    React.useEffect(() => {
        hideNotificationAlert();
    }, []);

    const sortedNotifications = notifications.sort(
        (a, b) =>
            moment.utc(b.created_at).toDate().getTime() -
            moment.utc(a.created_at).toDate().getTime()
    );

    return (
        <div
            ref={notifRef}
            className={`${isTodayPage ? "max-h-[350px]" : "absolute top-[64px] right-0 max-h-[88vh] w-[330px] sm:w-[416px] bg-white rounded-[16px] main-shadow py-[24px] px-[16px]"}`}
        >
            <div className={`flex justify-between items-center gap-[4px] ${isTodayPage ? "-mt-12 mb-[13px] p-2" :"mb-[32px]"}`}>
                <div className="flex items-center gap-[12px]">
                    <h3 className={`${isTodayPage ? "font-bold text-lg flex flex-row gap-2" : "font-semibold"}`}>
                        {isTodayPage && <SvgNotifications />}
                        {`${isTodayPage ? "My Notifications" : "Notifications"}`}
                    </h3>
                    {isTodayPage ? (
                        <></>
                    ):(
                        notifications.filter((n) => !n.viewed).length > 0 && (
                            <Badge variant="alert">
                                {notifications.filter((n) => !n.viewed).length}
                            </Badge>
                        )
                    )}
                  
                </div>
                <div>
                    <Button
                        variant="link"
                        size="sm"
                        onClick={markAllAsRead}
                        disabled={!notifications.filter((n) => !n.viewed).length}
                    >
                        <SvgCheckCircle />
                        Mark all as read
                    </Button>
                </div>
            </div>

            <div className={`${isTodayPage ? "mb-0 max-h-[315px] overflow-y-auto pl-4 pr-1": "flex flex-col mb-[24px] max-h-[54vh] overflow-y-auto"} ${classes.scrollBar}`}>
                {notifications.length > 0 ? (
                    <>
                        {sortedNotifications.slice(0, 20).map((n) => (
                            <NotificationCenterItem
                                key={n.id}
                                notification={n}
                                markRead={markRead}
                                markUnread={markUnread}
                            />
                        ))}
                    </>
                ) : (
                    <p className="text-base font-bold text-graySlate text-center py-[12px]">
                        No notifications yet
                    </p>
                )}
            </div>
            {isTodayPage ? (
                <></>
            ):(
                <div className="w-full flex justify-center">
                <Button variant="secondary" size="sm" asChild>
                  <Link
                      to="/notifications"
                  >
                      View all notifications
                  </Link>
                </Button>
            </div>
            )}

        </div>
    );
};

export default NotificationCenter;
