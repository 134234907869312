import type { SVGProps } from "react";
export const SvgDollar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 6 10"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2.522 10h.887a.37.37 0 0 0 .37-.37v-.854l.183-.05C5.467 8.349 6 7.312 6 6.54 6 5.112 4.771 4.52 3.567 4.125c-1.131-.375-1.492-.601-1.492-.936 0-.3.346-.6 1.123-.6.855 0 1.391.243 1.62.349q.074.033.152.034a.37.37 0 0 0 .334-.211l.352-.747a.36.36 0 0 0 .012-.28.36.36 0 0 0-.19-.21 4.5 4.5 0 0 0-1.4-.389l-.216-.027V.367A.37.37 0 0 0 3.492 0H2.62a.37.37 0 0 0-.368.367v.825l-.185.047C.853 1.546.127 2.327.127 3.324c0 1.315 1.301 1.901 2.577 2.303 1.177.384 1.337.716 1.337.992 0 .426-.55.736-1.308.736-.644 0-1.337-.165-1.853-.44a.36.36 0 0 0-.293-.025.38.38 0 0 0-.216.2l-.34.755a.367.367 0 0 0 .156.47c.43.25 1.069.434 1.747.51l.219.022v.783c0 .205.165.37.369.37"
    />
  </svg>
);
