import { FC } from "react";
import { SvgCheckCircle } from "../icons";

type Props = {
  isUpdate: boolean;
};

const NoteSavedToast: FC<Props> = ({ isUpdate }) => {
  let text = "Updated";

  if (!isUpdate) {
    text = "Saved";
  }

  return (
    <div className="w-[123px] h-[48px] px-[16px] py-[12px] rounded-[8px] flex items-center justify-center gap-[10px] box-shadow-1 bg-mainBlack text-white font-[14px]">
      <p>{text}</p>
      <SvgCheckCircle
        fill="#5DC488"
        className="w-[24px] h-[24px]"
      />
    </div>
  );
};

export default NoteSavedToast;
