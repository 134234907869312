import type { SVGProps } from "react";
export const SvgVideoSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 12 8"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.75 0H1.861C.822 0 0 .821 0 1.862v3.723c0 1.04.821 1.862 1.862 1.862h3.887c1.04 0 1.862-.821 1.862-1.862V1.862C7.666.822 6.79 0 5.75 0M11.499.767a1.29 1.29 0 0 0-1.26 0l-.054.055-1.424.985a.5.5 0 0 0-.219.438v3.067c0 .164.11.328.219.438l1.369.985.055.055c.164.11.383.164.602.164s.493-.054.657-.164c.329-.219.548-.602.548-.986V1.752c.054-.383-.165-.766-.493-.985"
    />
  </svg>
);
