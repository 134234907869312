import React from "react";
import { motion } from "framer-motion";
import CloseModal from "../CloseModal";
import { SvgPoweredByStripe } from "../icons";

type Props = {
  setShowTakeMeToStripeModal: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCallback: () => void;
};

export default function TakeMeToStripeModal({ setShowTakeMeToStripeModal, onClickCallback }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="zoee-backdrop-filter fixed left-0 top-0 z-[801] flex h-screen w-screen items-center justify-center"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="main-shadow relative flex flex-col items-center rounded-[16px] bg-white md:h-[442px] md:w-[600px]"
      >
        <CloseModal
          callback={() => setShowTakeMeToStripeModal(false)}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <div className="bg-grayCloud mb-[58px] flex h-[110px] w-full items-center justify-center rounded-t-[16px]">
          <SvgPoweredByStripe />
        </div>

        <div className="mb-[62px] max-w-[390px] text-center">
          <h3 className="mb-[8px] text-[22px] font-bold">
            Zoee partners with Stripe for secure financial services.
          </h3>
          <p className="text-graySlate mb-[32px]">
            You will be taken to Stripe’s website to connect all of you bank
            information. Stripe powers online business for millions of companies
            around the world.
          </p>
          <button onClick={onClickCallback} className="btn-primary mx-auto">
            Take me to Stripe
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
