import React from "react"
import AvailabilityRulesSelect from "../AvailabilityRulesSelect"
import currencyCodesData from "./currencyCodesData.json"
import Select from "react-select"
import useManageServices from "../hooks/useManageServices"
import { Button } from "../../ui/button"
import { numbers, timeTypes } from "../../../objects/createServiceObjects"
import { Label } from "../../ui/label"
import { Switch } from "../../ui/switch"
import { SvgWarning } from "../../icons"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu"

interface Props {
  formErrors: any
  serviceValues: any
  setServiceValues: any
  handleSessionCostChange: any
  handleAvailabilityRulesChange: any
  handleExpiresToggle: any
  serviceType: any
  isGroupService: boolean
  services: {}
  availabilities: Array<any>
  service: any
}

const ConsultationDetailsForm: React.FC<Props> = ({
  formErrors,
  serviceValues,
  setServiceValues,
  handleAvailabilityRulesChange,
  handleSessionCostChange,
  handleExpiresToggle,
  serviceType,
  isGroupService,
  services,
  availabilities,
  service,
}) => {
  const { getAvailableTimeTypes } = useManageServices()
  const showSessionCost = () => {
    let showCost = true
    if (serviceValues?.group?.isFree || serviceValues.isFree) {
      showCost = false
    }
    return showCost
  }

  interface CurrencyCodes {
    code: string
    symbol: string
  }

  React.useEffect(() => {
    if (!serviceValues.currency) {
      const defaultCurrency = {
        code: "USD",
        symbol: "\\u0024",
      }
      setServiceValues((prevValues: typeof serviceValues) => ({
        ...prevValues,
        currency: defaultCurrency.code,
        symbol: defaultCurrency.symbol,
      }))
      handleCurrencySelect(defaultCurrency)
    }
  }, [serviceValues.currency, setServiceValues])

  React.useEffect(() => {
    if (serviceValues.isFree || serviceValues.group.isFree) {
      setServiceValues({ ...serviceValues, sessionCost: 0.0 })
    }
  }, [serviceValues.isFree, serviceValues.group.isFree])

  React.useEffect(() => {
    const sessionCostInput = document.getElementById("sessionCostInput") || null
    if (sessionCostInput) {
      sessionCostInput.addEventListener("keydown", function (e) {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault()
        }
      })
    }
  }, [])

  interface CurrencyCodes {
    code: string
    symbol: string
  }

  const [currencyCodes, setCurrencyCodes] = React.useState<CurrencyCodes[]>([])

  React.useEffect(() => {
    const data: CurrencyCodes[] = currencyCodesData.codes
    setCurrencyCodes(data)
  }, [])

  const decodeUnicodeSymbol = (unicodeStr: string | undefined | null) => {
    if (!unicodeStr) {
      return ""
    }
    return unicodeStr.replace(/\\u([\dA-F]{4})/gi, (match, grp) => {
      return String.fromCharCode(parseInt(grp, 16))
    })
  }

  const handleCurrencySelect = (code: CurrencyCodes) => {
    setServiceValues({
      ...serviceValues,
      currency: code.code,
      symbol: code.symbol,
    })
  }

  return (
    <>
      <h4 className="font-bold text-base mb-[16px]">
        What is the length of each session?
      </h4>
      <div
        className={`${
          formErrors?.sessionLength && "input-error"
        } flex items-center gap-[12px] mb-[20px]`}
      >
        <input
          type="number"
          min="0"
          max="60"
          className="w-[54px] h-[54px] bg-white focus:outline-0 border
                    border-1 border-grayCloud rounded-[10px] text-graySlate text-center"
          value={serviceValues.sessionLength.minutes}
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          name="sessionMinutes"
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              sessionLength: { minutes: e.target.value },
            })
          }
        />
        <span className="font-bold text-base">minutes</span>
      </div>
      {formErrors.sessionLength && (
        <div className="field-error w-[100%]">
          <SvgWarning />
          {formErrors.sessionLength}
        </div>
      )}

      {/* SELECT WITH EXISTING AVAILABILITY RULES */}
      {availabilities?.length > 0 && (
        <AvailabilityRulesSelect
          availabilities={availabilities}
          serviceType={serviceType}
          service={serviceValues}
          handleAvailabilityRulesChange={handleAvailabilityRulesChange}
        />
      )}

      <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      {/* GROUP IS FREE TOGGLE */}
      {isGroupService && (
        <>
          <div className="flex flex-col mb-[20px]">
            <h4 className="font-bold text-base mb-[8px]">
              Is this a free group?
            </h4>
            <div className="flex items-center gap-2">
              <Switch
                onCheckedChange={() =>
                  setServiceValues({
                    ...serviceValues,
                    group: {
                      ...serviceValues.group,
                      isFree: !serviceValues.group.isFree,
                    },
                  })
                }
                checked={serviceValues.group.isFree}
                id="free"
              />
              <Label htmlFor="free">Free</Label>
            </div>
          </div>
        </>
      )}
      {!isGroupService && (
        <div className="flex flex-col mb-[20px]">
          <h4
            className={`font-bold text-base mb-[8px] ${
              service.enrolled_count >= 1 ? "text-graySlate" : ""
            }`}
          >
            Is this a free consultation?
          </h4>
          <div className="flex items-center gap-2">
            <Switch
              onCheckedChange={() =>
                setServiceValues({
                  ...serviceValues,
                  isFree: !serviceValues.isFree,
                })
              }
              checked={serviceValues.isFree}
              id="free"
              disabled={service.enrolled_count >= 1}
            />
            <Label htmlFor="free">Free</Label>
          </div>
        </div>
      )}

      {showSessionCost() && (
        <div className={`flex flex-col mb-[20px]`}>
          <h4
            className={`font-bold text-base mb-[8px] ${
              service.enrolled_count >= 1 ? "text-graySlate" : ""
            }`}
          >
            Session cost
          </h4>
          <div
            className={`relative ${
              formErrors?.sessionCost && "input-error"
            } default-input w-full`}
          >
            <div className="flex">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button size="icon" variant="utility">
                    {serviceValues.currency}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel className="text-graySlate text-xs p-2">
                    Currency
                  </DropdownMenuLabel>
                  <DropdownMenuGroup>
                    {currencyCodes.map((code) => (
                      <>
                        <DropdownMenuItem
                          onClick={() => handleCurrencySelect(code)}
                        >
                          {code.code}
                        </DropdownMenuItem>
                      </>
                    ))}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>

              {/* Input Box */}
              <input
                id="sessionCostInput"
                type="number"
                step="1"
                min="0"
                autoComplete="off"
                name="sessionCost"
                placeholder="Cost"
                value={serviceValues.sessionCost}
                onWheel={() => (document.activeElement as HTMLElement)?.blur()}
                onChange={handleSessionCostChange}
                className="ml-2 flex-1 peer border border-gray-300 py-2 focus:outline-none focus:border-blue-500"
                disabled={service.enrolled_count >= 1}
              />
            </div>
            {/* Error message */}
            {formErrors.sessionCost && (
              <div className="field-error w-full">
                <SvgWarning />
                {formErrors.sessionCost}
              </div>
            )}
          </div>
        </div>
      )}

      {showSessionCost() && (
        <div className="flex flex-col">
          <h4 className="font-bold text-base">
            Enable clients to use your promo codes?
          </h4>
          <div className="flex items-center gap-2">
            <Switch
              onCheckedChange={() =>
                setServiceValues({
                  ...serviceValues,
                  allowPromo: !serviceValues.allowPromo,
                })
              }
              checked={serviceValues.allowPromo}
              id="promo"
            />
            <Label htmlFor="free">Enabled</Label>
          </div>
        </div>
      )}
      {showSessionCost() && (
        <>
          {/* BILLING PREVIEW */}
          <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>
          <h4 className="font-bold text-base mb-[8px]">Preview</h4>
          <div className="w-full border border-1 border-gray rounded-[16px] p-[20px] flex flex-col">
            <h5 className="text-graySlate text-base mb-[32px]">
              Payment Details
            </h5>
            <div className="flex w-full items-center justify-between mb-[16px]">
              <div className="flex items-center gap-[16px] min-w-fit">
                <div className="w-[24px] h-[24px] bg-blurple rounded-full text-white font-bold justify-center items-center flex shrink-0">
                  {decodeUnicodeSymbol(serviceValues.symbol)}
                </div>
                <p className=" whitespace-nowrap">Payment at checkout</p>
              </div>
              <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
              <span className="text-base">
                {decodeUnicodeSymbol(serviceValues.symbol)}
                {serviceType === "consultation" &&
                serviceValues?.consultation?.isFree
                  ? 0.0
                  : isNaN(serviceValues?.sessionCost)
                  ? "0.00" // or handle the NaN case as desired
                  : serviceValues?.sessionCost.toFixed(2)}
              </span>
            </div>
            <div
              className="text-graySlate w-full bg-grayMist rounded-[12px]
                        p-[26px] flex items-center justify-center text-base"
            >
              <p className="text-center">
                Payment will be charged{" "}
                <strong className="text-black">immediately</strong> at checkout.
              </p>
            </div>
          </div>
        </>
      )}

      {/* EXPIRATION toggle and selector */}
      {!isGroupService && (
        <>
          <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>
          <div className="flex flex-col mb-[32px]">
            <h4 className="font-bold text-base mb-[8px]">
              {`When does this 
                            ${
                              serviceType === "consultation"
                                ? "consultation"
                                : ""
                            } 
                            ${serviceType === "individual" ? "service" : ""}
                            ${serviceType === "bundle" ? "package" : ""}
                            expire?`}
            </h4>
            <div className="flex items-center gap-2 mb-[24px]">
              <Switch
                onCheckedChange={handleExpiresToggle}
                checked={!serviceValues?.expiration?.expires}
                id="no-set-date"
              />
              <Label htmlFor="no-set-date">No set date</Label>
            </div>
            {serviceValues?.expiration?.expires && (
              <>
                <div className="flex items-center justify-between">
                  <Select
                    className="basic-single w-[49%]"
                    classNamePrefix="select"
                    options={numbers}
                    menuPlacement="bottom"
                    value={numbers.filter(
                      (number) =>
                        number.value === serviceValues?.expiration?.expireTime
                    )}
                    onChange={(e: any) =>
                      setServiceValues({
                        ...serviceValues,
                        expiration: {
                          ...serviceValues.expiration,
                          expireTime: e.value,
                        },
                      })
                    }
                  />
                  <Select
                    className="basic-single w-[49%]"
                    classNamePrefix="select"
                    options={getAvailableTimeTypes(
                      serviceType,
                      timeTypes,
                      serviceValues
                    )}
                    menuPlacement="bottom"
                    value={getAvailableTimeTypes(
                      serviceType,
                      timeTypes,
                      serviceValues
                    ).filter(
                      (type: any) =>
                        type.value === serviceValues?.expiration?.expireType
                    )}
                    onChange={(e: any) =>
                      setServiceValues({
                        ...serviceValues,
                        expiration: {
                          ...serviceValues.expiration,
                          expireType: e.value,
                        },
                      })
                    }
                  />
                </div>
                {formErrors.expires && (
                  <div className="field-error w-full">
                    <SvgWarning />
                    {formErrors.expires}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ConsultationDetailsForm
