import React, { useState } from "react"
import BentoSelectedContent from "./BentoSelectedContent"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { SvgEdit } from "../icons"

interface Props {
  endpointSlug: any
  setEditBento?: any
  editBento?: boolean
  coachData: any
  coachColor: any
  setBentoBoxSelection?: any
  setActiveTab?: any
}

export default function BentoBoxFive({
  endpointSlug,
  setEditBento,
  coachData,
  coachColor,
  setBentoBoxSelection,
  setActiveTab,
}: Props) {
  const [boxType, setBoxType] = useState<
    "quote" | "blog" | "image" | "video" | "testimonial"
  >()
  const { loadingBento } = React.useContext(CoachPrivateProfileContext)
  const pathname = window.location.pathname
  let isPublicProfileView = false
  if (pathname === `/find-a-coach/${coachData.endpoint_slug}`) {
    isPublicProfileView = true
  }

  return (
    <>
      {loadingBento ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="relative h-[300px] w-[300px] group">
          {
            <BentoSelectedContent
              coachData={coachData}
              endpointSlug={endpointSlug}
              coachColor={coachColor}
              bentoBoxNumber={"five"}
              setBoxType={setBoxType}
              isPublicProfileView={isPublicProfileView}
              boxType={boxType}
              setActiveTab={setActiveTab}
            />
          }
          {isPublicProfileView ? (
            <></>
          ) : (
            <Button
              className="absolute right-2 top-2 z-[20] invisible group-hover:visible"
              variant={"utility"}
              size={"sm"}
              onClick={() => {
                setEditBento(true)
                setBentoBoxSelection(5)
              }}
            >
              <SvgEdit />
              Edit
            </Button>
          )}
        </div>
      )}
    </>
  )
}
