import React, { useState } from "react"
import { SvgPlus } from "../icons"

const IINFAQs: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const toggleFAQ = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const faqs = [
    {
      question:
        "I’m new to coaching and haven’t worked with paid clients yet – is this program right for me?",
      answer:
        "Yes! Whether you have many years of coaching experience or are preparing to work with your first client, this program will help you get your practice up and running fast, while supporting you with business strategy, systems, and processes that you can use to connect with, enroll, and coach clients.",
    },
    {
      question: "Can I join the program if I’m not an IIN graduate?",
      answer:
        "This program is designed for IIN alumni by IIN alumni. However, we will not turn away coaches from other training programs who are motivated to take this program.",
    },
    {
      question: "What is Zoee, and how does it help with my practice?",
      answer:
        "Zoee is an all-in-one practice management platform included in the Business Blueprint for Health Coaches. It allows you to: set up and promote your coaching services, schedule sessions with clients, collect payments securely, meet with clients via video telehealth, share notes and forms with clients, and more. Zoee is designed to streamline the day-to-day management of your coaching practice, saving you time and effort.​",
    },
    {
      question: "I already have a business – will I benefit from this program?",
      answer:
        "Absolutely. You’re constantly evolving, and your coaching practice and business should be, too! This program will help existing entrepreneurs streamline their business operations, increase their impact, and revisit their brand and business strategies to achieve maximum results, increase their income, and scale with ease.​",
    },
    {
      question: "When does this program start and end?",
      answer:
        "The Business Blueprint for Health Coaches starts January 13, 2025, and concludes April 13, 2025.​",
    },
    {
      question: "What is the investment for this program?",
      answer:
        "This hands-on three-month business program is available for an investment of $695. Please note that payment plans are not available.​",
    },
    {
      question: "What is the refund policy for this program?",
      answer:
        "There is a five-day refund window from the start date of the program. After five days, no refunds will be issued.​",
    },
    {
      question:
        "How many hours per week will I need to dedicate to this program?",
      answer:
        "We recommend setting aside 4–5 hours per week to fully immerse yourself in this program. This includes time for completing the weekly workbook activities and challenges and participating in accountability pods and discussion threads. During weeks 1, 6, and 12, you’ll also attend a 90-minute webinar.​",
    },
    {
      question: "When will the live webinars be held?",
      answer:
        "There are three 90-minute live group interactive webinars in this program. They are led by our expert coaches and will be held during weeks 1, 6, and 12. Please see the Program Schedule for call dates and times. If you’re unable to attend live, a recording will be provided.​",
    },
    {
      question: "How do the weekly challenges work?",
      answer:
        "Each week, you’ll be given a challenge designed to help you take concrete steps toward building your business. These challenges are complemented by discussion prompts to share your progress and receive feedback from the community. Completing the challenges will ensure you stay focused and motivated throughout the program.​",
    },
    {
      question: "Do I need to complete all the tasks in each chapter?",
      answer:
        "While participation is not graded, completion of all tasks is required if you want to experience the full benefit of this program. The weekly tasks and challenges are designed to help you take actionable steps toward building your business and will give you the hands-on experience you need to move forward with your health coaching practice. However, if you’re unable to complete something, don’t worry – you can revisit it later as you progress.​",
    },
    {
      question: "Will I receive any live support in this program?",
      answer:
        "Yes! You’ll have access to three live calls led by an expert IIN coach, accountability pods for peer-to-peer support and feedback, moderated discussion boards, and weekly emails with key actions to keep you on track.​",
    },
    {
      question: "How do I connect with other coaches in this program?",
      answer:
        "Throughout the program, you’ll be introduced to other coaches on Zoee with opportunities for collaboration, referrals, and partnership.​",
    },
    {
      question:
        "Is there a transfer option available if I can’t complete the program? Can I receive a refund if needed?",
      answer:
        "Once enrolled in the program, there are no transfer options available. You’re required to complete the program by April 13, 2025. Please note that refunds are not available after January 17, 2025.",
    },
    {
      question: "What happens after I complete this program?",
      answer:
        "After completing the program, you’ll have the tools, knowledge, and community support to move forward with your business. You’ll also be encouraged to continue evolving your practice, maintaining ongoing business development, and taking the next steps toward long-term success.​",
    },
  ]

  return (
    <div
      className="flex flex-col md:flex-row items-start justify-between w-full py-[56px] md:py-[96px] gap-10 mx-auto md:px-[144px] px-4 sm:px-8"
    >
      {/* Left Section - Title and Contact */}
      <div className="md:w-[400px] flex flex-col gap-4"> 
        <h2 className="text-black text-[48px] font-bold leading-tight">
          Frequently Asked Questions
        </h2>
        <p className="text-black text-[18px] font-normal">
          Not seeing your question here? Contact IIN program support at{" "}
          <a
            href="mailto:businessblueprint@integrativenutrition.com"
            className="underline font-bold break-words text-vividRed"
          >
            businessblueprint@integrativenutrition.com
          </a>
        </p>
      </div>

      {/* Right Section - FAQs */}
      <div className="flex flex-col w-full lg:w-2/3 gap-6">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="flex flex-col"
          >
            <div className="flex justify-between items-center">
              <p className="text-black text-[24px] font-bold">{faq.question}</p>
              <button
                onClick={() => toggleFAQ(index)}
                aria-label={`Toggle answer for: ${faq.question}`}
                className="w-6 h-6"
              >
                <SvgPlus
                  className={`transform ${
                    expandedIndex === index ? "rotate-45" : ""
                  }`}
                />
              </button>
            </div>
            {expandedIndex === index && (
              <p className="text-black text-[24px] font-normal mt-4">
                {faq.answer}
              </p>
            )}
            <hr className="border-t border-[#383838] mt-4" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default IINFAQs
