import * as React from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SubscriptionRequests } from "../../api/app.service"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import useScrollToTop from "../../hooks/useScrollToTop"
import AffiliatePromoPage from "./AffiliatePromoPage"
import AffiliateLandingCTA from "./AffiliateLandingCTA"
import { CeoQuote } from "../../pages/(marketing)/_components/ceo-quote"
import { FeaturedArticles } from "../../pages/(marketing)/_components/articles/featured-articles"
import { Features } from "../../pages/(marketing)/_components/features/features"
import { ShowcaseVideo } from "../../pages/(marketing)/_components/showcase-video"
import { SubscribeToNewsletterForm } from "../../pages/(marketing)/_components/subscribe-to-newsletter-form"
import { Testimonials } from "../../pages/(marketing)/_components/testimonials/testimonials"
import { ThriveTogether } from "../../pages/(marketing)/_components/thrive-together"
import { useAuthModal } from "../auth/use-auth-modal"
import Loader from "../ui/loader"
import axios from "axios"

function AffiliateHomePage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { promocode } = useParams<{ promocode: string }>()

  const { showRegister } = useAuthModal()
  const { setPopupNotification, setShowAuthModal, renderError, hideAlert } =
    React.useContext(CommonFunctionCtx)

  const [loading, setLoading] = React.useState(true)
  const [validationResult, setValidationResult] = React.useState<
    boolean | null
  >(null)

  const validatePromoCode = async () => {
    console.log("Validating promo code:", promocode)
    if (promocode && promocode !== "") {
      try {
        setLoading(true)
        const data = await SubscriptionRequests.publicValidatePromoCode({
          promo_code: promocode,
        })
        if (!data.promo_valid) {
          renderError(data.promo_invalid_reason || "Invalid promo code.")
          setTimeout(() => {
            hideAlert()
          }, 5000)
          setLoading(false)
          navigate("*", { replace: true })
        } else {
          setValidationResult(true)
          // store the valid promo code in local storage
          setLocalStorageReferrerPromoCode()
          setLoading(false)
        }
      } catch (ex) {
        console.error("Error validating promo code:", ex)
        let errorMessage = "Oops! Something went wrong."
        renderError(errorMessage)
        setTimeout(() => {
          hideAlert()
        }, 5000)
        setLoading(false)
        navigate("*", { replace: true })
      }
    } else {
      navigate("*", { replace: true })
    }
  }

  const checkUrlParams = () => {
    const message = location?.state?.message

    const reset = searchParams.get("reset")
    const emailParams = searchParams.get("email")
    const phoneParams = searchParams.get("phone")
    const firstNameParams = searchParams.get("firstname")
    const lastNameParams = searchParams.get("lastname")
    const signupType = searchParams.get("signuptype")
    const login = searchParams.get("login")

    if (message === "no-code" || message === "wrong-code") {
      setPopupNotification({
        show: true,
        title: "Invalid Email Verification!",
        message:
          "Oops! Looks like your email verification link didn't work properly. Try sending a new verification link by clicking the 'Verify Email' button on this page.",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }
    if (login) {
      setShowAuthModal(true)
    }
    if (message === "success") {
      setPopupNotification({
        show: true,
        title: "Email Verified!",
        message:
          "Your email address is now verified with Zoee. You can change your primary email address whenever you'd like, but will need to verify it after each change.",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }

    if (reset === "true") {
      setPopupNotification({
        show: true,
        title: "Reset Password Link Sent!",
        message:
          "An email has been sent to the email address associated with your account. Please check your email for a link to reset your password. 🎉",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    } else if (reset === "complete") {
      setPopupNotification({
        show: true,
        title: "Reset Password Complete!",
        message: "You can now log in with your email and your new password. 🎉",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }

    if (emailParams && phoneParams && firstNameParams && lastNameParams) {
      showRegister({
        accountType: "coach",
        email: emailParams,
        phone: phoneParams,
        firstName: firstNameParams,
        lastName: lastNameParams,
      })
      if (signupType) {
        localStorage.setItem("signupType", signupType)
      }
    }

    if (signupType === "member" && emailParams) {
      showRegister({ accountType: "member", email: emailParams })
    }
  }

  const setLocalStorageReferrerPromoCode = () => {
    if (promocode) {
      if (isLocalStorageAvailable()) {
        localStorage.setItem("referrerPromoCode", promocode)
      }
    }
  }

  React.useEffect(() => {
    if (promocode) {
      console.log("Promocode:", promocode)
      const timer = setTimeout(() => {
        validatePromoCode()
      }, 500)
      return () => clearTimeout(timer)
    }
  }, [promocode])

  React.useEffect(() => {
    checkUrlParams()
    // setLocalStorageReferrerPromoCode()

    const coachRegisterParam = searchParams.get("coachRegister")
    if (coachRegisterParam === "true") {
      // showRegister({ accountType: "coach" });
    }

    if (location?.state?.storageAvailable === false) {
      setPopupNotification({
        show: true,
        title: "Oh no! Browser Storage is Disabled",
        message:
          "You'll need to enable cookies in your browser to login or sign up for the Zoee application.",
      })
      navigate(location.pathname, { replace: true })
    }
  }, [
    location,
    navigate,
    searchParams,
    setPopupNotification,
    setShowAuthModal,
    showRegister,
    promocode,
  ])

  useScrollToTop()

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Loader />
      </div>
    )
  }

  if (!validationResult) {
    return null
  }

  return (
    <>
      <AffiliatePromoPage />
      <ThriveTogether />
      <ShowcaseVideo />
      <Features />
      <CeoQuote />
      <Testimonials />
      <FeaturedArticles />
      <SubscribeToNewsletterForm />
      <AffiliateLandingCTA />
    </>
  )
}

export default AffiliateHomePage
