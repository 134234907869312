import moment from "moment";

type TimeOfDay = "morning" | "afternoon" | "evening";

export default function useDate() {
  // ex:	8:30 PM
  const fmtTime = (date: Date) => {
    return moment(date).format("LT");
  };

  // ex: 9/4/1986
  const smDate = (date: Date) => {
    return moment(date).format("l");
  };

  // ex: September 4, 1986
  const mdDate = (date: Date) => {
    return moment(date).format("LL");
  };

  // ex: Thursday, September 4, 1986
  const lgDate = (date: Date) => {
    return moment(date).format("dddd, MMMM D, YYYY");
  };

  // "2014-09-08T08:02:17-05:00" (ISO 8601, no fractional seconds)
  const now = moment().format();
  const utc = moment.utc().format();
  const iso = (date: Date) => {
    return moment(date).format();
  };

  const currentHour = new Date().getHours();
  let timeOfDay: TimeOfDay;

  if (currentHour >= 4 && currentHour < 12) {
    timeOfDay = "morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    timeOfDay = "afternoon";
  } else {
    timeOfDay = "evening";
  }

  const calcDuration = (endTime: any, startTime: any) => {
    const calculated = moment.utc(endTime).diff(
      moment.utc(startTime),
      "minutes",
    );
    let duration;
    if (calculated >= 1) {
      duration = calculated;
    } else {
      duration = 1;
    }
    return duration;
  };

  return {
    fmtTime,
    smDate,
    mdDate,
    lgDate,
    now,
    iso,
    utc,
    timeOfDay,
    calcDuration
  };
}
