"use client"

import * as React from "react"
import productImage from "./product-image.png"
import { Button } from "../ui/button"
import { useAuthModal } from "../auth/use-auth-modal"
import { useScroll, useTransform } from "framer-motion"
import { useState, useEffect } from "react"

function AffiliatePromoPage() {
  const strings = ["starting", "growing", "managing", "enjoying"]

  const [index, setIndex] = React.useState(0)

  const [referrerName, setReferrerName] = useState<string>("Zoee") // fallback name
  const { scrollYProgress } = useScroll()
  const { showRegister } = useAuthModal()
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "-300%"])

  React.useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= strings.length - 1) return 0
        return state + 1
      })
    }, 2500)
    return () => clearInterval(id)
  }, [])

  // use local storage to check if the user has logged in or signed up for 14-day trial
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const checkUserInLocalStorage = () => {
    const user = localStorage.getItem("user")
    setIsUserLoggedIn(!!user)
  }

  useEffect(() => {
    const referrerPromoCode = localStorage.getItem("referrerPromoCode")
    if (referrerPromoCode) {
      setReferrerName(referrerPromoCode)
    }
    checkUserInLocalStorage()
  }, [isUserLoggedIn])
  console.log("referrer: ", referrerName)

  return (
    <div
      className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 bg-gradient-to-t from-blueVibrant/20 to-white"
      style={{
        fontFamily: "BeausiteClassic",
      }}
    >
      <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
        <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
          <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
            <div className="text-black text-[32px] md:text-[56px] font-bold font-['Beausite Classic'] leading-tight md:leading-[64px]">
              {referrerName} gave you 20% off your first year or month with Zoee
            </div>
            <div className="text-black text-[18px] md:text-[22px] font-normal font-['Beausite Classic']">
              Zoee handles all the details of managing and enjoying your
              coaching business.
            </div>
          </div>

          <Button
            className=" flex justify-center items-center px-5 py-4"
            variant="default"
            size="lg"
            onClick={() => showRegister({ accountType: "coach" })}
          >
            Claim your discount
          </Button>
        </div>

        {/* image */}
        <div className="md:w-1/2 flex justify-center md:justify-end">
          <img
            className="w-full h-auto md:w-[584px] md:h-[475px]"
            src={productImage}
            alt="product contact cards and woman on laptop"
          />
        </div>
      </div>

      <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
      <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>

      <div className="bg-gradient-to-t from-white to-transparent w-full flex justify-center"></div>
    </div>
  )
}

export default AffiliatePromoPage
