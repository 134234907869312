import type { SVGProps } from "react";
export const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10 12.908c2.333 0 6.445.914 7.133 4.57C18.911 15.648 20 13.09 20 10.28 20 4.592 15.533 0 10 0S0 4.615 0 10.28c0 2.81 1.089 5.37 2.867 7.22.689-3.655 4.8-4.592 7.133-4.592m0-10.372c2.578 0 4.69 2.147 4.69 4.82 0 2.65-2.09 4.82-4.69 4.82s-4.689-2.147-4.689-4.82c0-2.65 2.111-4.82 4.69-4.82"
    />
  </svg>
);
