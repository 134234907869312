import useScrollToTop from "../../../hooks/useScrollToTop";

export default function PrivacyPolicyPage() {
  useScrollToTop();

  return (
    <div className="mx-auto mt-[88px] max-w-screen-2xl overflow-hidden">
      <div className="bg-grayFlash flex items-center justify-center py-16">
        <h1 className="px-6 text-center text-4xl font-bold md:max-w-prose">
          Zoee Privacy Policy
        </h1>
      </div>

      <div className="mx-auto mb-[64px] mt-[64px] px-[24px] text-[18px] md:max-w-[880px]">
        <p>Effective Date: Oct 1, 2022</p>
        <div className="h-[32px]" />
        <p>
          SoleLife, Inc. d/b/a Zoee (“Zoee”, “we”, “us” or “our”) respects your
          privacy. This Privacy Policy describes our practices with respect to
          information we may collect when you visit our website, zoee.com (the
          “Website”), or use our software applications and platform
          (collectively, the “Zoee Platform”). The Website and the Zoee Platform
          are collectively referred to in this Privacy Policy as the “Services.”
        </p>
        <div className="h-[16px]" />
        <p>
          Please note that the Zoee Platform enables users of the Services who
          seek coaching and other similar services (who we refer to in this
          Privacy Policy as “Members”) to be matched with, and to interact with,
          independent coaches, consultants, and similar providers who use the
          Zoee Platform to deliver their services and manage their practices
          (who we refer to in this Privacy Policy as “Providers”). While this
          Privacy Policy describes Member information that we collect and share
          with Providers through the Zoee Platform, we do not control, and this
          Privacy Policy does not apply to, the Providers’ use of that
          information. If you are a Member and have questions about a Provider’s
          use of your information, please contact that Provider directly.
        </p>
        <div className="h-[16px]" />
        <p>
          By using the Services, you agree to this Privacy Policy. If you do not
          agree, do not visit the Website or use the Zoee Platform.
        </p>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">
          The Information We Collect
        </h2>
        <div className="h-[16px]" />
        <p>
          We collect and receive information through the Services in three ways:
          (1) when you voluntarily provide information to us, such as when you
          submit information through forms on our Website, register to create an
          account on the Zoee Platform, or fill out assessments and
          questionnaires through the Zoee Platform; (2) when you participate in
          online coaching sessions; and (3) when we collect information
          automatically about your use of the Services, including through
          cookies and other similar technologies. Each of these methods are
          described in more detail below.
        </p>
        <div className="h-[16px]" />
        <h3 className="font-bold">1. Information You Choose To Provide</h3>
        <div className="h-[16px]" />
        <p>
          You may choose to provide certain information to us when you use the
          Services. The information we collect through this method will vary
          depending on the Services you are using, and how you access those
          Services. The following are examples of situations in which you may
          provide information to us through the Services.
        </p>
        <div className="h-[16px]" />
        <h3 className="font-bold italic">
          Information you provide through the Website
        </h3>
        <div className="h-[16px]" />
        <p>
          When you visit our Website, we may collect information that you
          provide when you fill out contact forms, sign up for newsletters or
          other communications, complete questionnaires or surveys, or create an
          account to use the Zoee Platform. This information may include:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>your first and last name;</li>
          <li>
            contact information such as your email address, telephone number,
            and mailing address;
          </li>
          <li>
            the name of any persons or organizations that referred you to the
            Zoee Platform;
          </li>
          <li>
            if you are a Provider, professional information including the name
            of your practice, your years of experience, your coaching type or
            category, your area(s) of specialty, information about your training
            and certifications, Member and other client testimonials, and media
            about your practice;
          </li>
          <li>
            transaction information, such as billing information and transaction
            history;
          </li>
          <li>
            payment information, such as payment card numbers, expiration dates,
            and security codes; and
          </li>
          <li>
            any other information you submit, such as through comments or
            questions that you submit through the Website.
          </li>
        </ul>
        <div className="h-[16px]" />
        <h3 className="font-bold italic">
          Information you provide through the Zoee Platform
        </h3>
        <div className="h-[16px]" />
        <p>
          When you use the Zoee Platform, we may in addition to the information
          set forth above collect various other types of information, including:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>
            if you are a Provider, account credentials including your email
            address and password, information about your practice, information
            that you communicate to your client Members through the Zoee
            Platform such as scheduling information, messages, and session notes
            you exchange through the Zoee Platform; and billing and other
            administrative information you input into the Zoee Platform to
            manage your practice; and{" "}
          </li>
          <li>
            if you are a Member, account credentials including your email
            address and password, your responses to questionnaires and surveys,
            information that you exchange with your Provider through the Zoee
            Platform, such as scheduling information, messages, and session
            notes you exchange through the Zoee Platform, and your reviews that
            you choose to provide for your Provider.
          </li>
        </ul>
        <div className="h-[16px]" />
        <h3 className="font-bold">2. Coaching Session Data</h3>
        <div className="h-[16px]" />
        <p>
          When you participate in online coaching sessions through the Zoee
          Platform, Zoee may collect and receive information about the session
          and information exchanged between Providers and their client Members.
          This information may include:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>audio and video of live coaching sessions;</li>
          <li>transcripts of chats and messages;</li>
          <li>
            the contents of documents and other files shared between Providers
            and client Members during coaching sessions;
          </li>
          <li>the contents of Providers’ session notes; and</li>
          <li>
            other information about the coaching session, including platform
            performance metrics (such as audio and video quality) and the number
            and identity of participants in the coaching session.
          </li>
        </ul>
        <div className="h-[16px]" />
        <p>
          We analyze and use this coaching session data internally to provide
          and improve the Services, including through diagnostic tools that we
          use to help Providers deliver their coaching services. We do not sell
          or otherwise share the content of coaching sessions with other third
          parties for their own commercial purposes.
        </p>
        <div className="h-[16px]" />
        <h3 className="font-bold">
          3. Usage and Other Information We Collect Automatically
        </h3>
        <div className="h-[16px]" />
        <p>
          We collect some information automatically when you use the Services
          through technologies such as cookies, log files, and web beacons. The
          types of information that we automatically collect may include:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>
            technical information about your computer, device, hardware, or
            software you use to access the Internet or our Services, such as IP
            address or other transactional or identifier information for your
            device (such as device make and model, unique device identifier,
            information about device operating systems and browsers, or other
            device or system related specifications);
          </li>
          <li>
            usage information and statistics about your interaction with the
            Services, which may include the URLs of the Services that you have
            visited, URLs of referring and exiting pages, page views, time spent
            on a page, number of clicks, the features you used, how long you
            used the application and when, and other usage statistics;
          </li>
          <li>
            information that facilitates a safer and more personalized
            experience, such as user identification information, saved
            preferences, and device identifiers or usage information for
            authentication and fraud prevention purposes; and
          </li>
          <li>
            information about your approximate location, which we may derive
            from device information such as IP address.
          </li>
        </ul>
        <div className="h-[16px]" />
        <p>
          We collect some of this information through tracking technologies such
          as cookies and web beacons. For more information please see the&nbsp;
          <a href="#howWeUseCookies" className="text-blueVibrant underline">
            How We Use Cookies and Other Tracking
          </a>
          &nbsp;section of this Privacy Policy.
        </p>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">
          How We Use Your Information
        </h2>
        <div className="h-[16px]" />
        <p>
          We may use the information we collect or receive for the following
          purposes:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>
            administering accounts and subscriptions and performing our
            obligations under our terms of service and our subscription
            agreements with Providers and Members;
          </li>
          <li>
            operating the Services, including matching Members with Providers,
            sharing client reviews of Providers with other users, analyzing
            Member-Provider interactions to help Providers more effectively
            deliver their coaching services, and providing other services to
            Providers;
          </li>
          <li>developing and improving the Services;</li>
          <li>
            analyzing usage trends and patterns and measuring the effectiveness
            of the Services and their features;
          </li>
          <li>
            providing support, responding to questions and communications, and
            obtaining feedback about our Services;
          </li>
          <li>
            communicating with you about the Services, including preparing and
            delivering announcements about features, functionality, terms of
            use, or other aspects of the Services;
          </li>
          <li>
            for marketing and promotional purposes, such as to send marketing,
            advertising, and promotional communications and to show you
            advertisements for products and/or services tailored to your
            interests on social media and other websites;
          </li>
          <li>
            securing the Services, including protecting against and responding
            to fraud, illegal activity (such as incidents of hacking or misuse
            of the Services), and claims and other liabilities, including by
            enforcing our Terms of Use;
          </li>
          <li>
            complying with legal or regulatory requirements, judicial process,
            and our company policies (including due diligence and contracting
            activities);
          </li>
          <li>
            evaluating or conducting a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of our assets, including as part of bankruptcy, liquidation, or
            similar proceeding; and
          </li>
          <li>
            for any other purpose that you may authorize or direct at the time
            we collect your information.
          </li>
        </ul>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">
          How We Share Your Information
        </h2>
        <div className="h-[16px]" />
        <p>
          To accomplish the purposes set out above, we may share your
          information with third parties that may include:
        </p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>
            if you are a Member, your Provider, when you are matched with a
            Provider through the Zoee Platform;
          </li>
          <li>
            if you are a Provider, your client Members with whom you are matched
            through the Zoee Platform;
          </li>
          <li>
            when you choose, with other users of the Zoee Platform, such as when
            you provide reviews and feedback on a Provider (if you are a client
            of that Provider) or establish a profile to promote your coaching
            services (if you are a Provider);
          </li>
          <li>
            third-party applications, platforms, or services that you choose to
            allow to access to your information in the Zoee platform, such as
            calendar applications that you connect to the platform to sync your
            session scheduling information from the Zoee platform with those
            applications;
          </li>
          <li>
            our vendors and service providers that perform services on our
            behalf and under our direction;
          </li>
          <li>payment processors and transaction fulfillment Providers;</li>
          <li>
            advertising, marketing, and data analytics providers with whom we
            work;
          </li>
          <li>
            other third parties as necessary to (a) protect or defend our
            interests and the legal rights or property; (b) protect the rights,
            interest and safety and security of the Services, our organization,
            or members of the public; (c) protect against fraud, and (d)
            investigate and prosecute users who engage in behavior that is
            illegal or harmful to others or to others’ property;
          </li>
          <li>
            a buyer or other successor or organization in the event of an actual
            or potential merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of our assets,
            including as part of bankruptcy, liquidation, or similar proceeding;
            and
          </li>
          <li>
            with such other parties, or for such other purposes, as you may
            authorize or direct when we collect the information.
          </li>
        </ul>
        <div className="h-[16px]" />
        <p>
          In addition, and as described in the&nbsp;
          <a href="#howWeUseCookies" className="text-blueVibrant underline">
            How We Use Cookies and Other Tracking
          </a>
          &nbsp;Technologies section of this Privacy Policy, we may allow third
          parties, including social networking sites and advertising and
          analytics partners, to use the Services to collect personal
          information about your online activities over time and across
          different websites, applications, and other online products or
          services. These third parties may use that information for measurement
          services or to deliver interest-based advertising.
        </p>
        <div className="h-[16px]" />
        <div className="h-[32px]" />
        <h2
          id="howWeUseCookies"
          className="snap-start scroll-mt-24 text-[20px] font-bold underline"
        >
          How We Use Cookies and other Tracking Technologies
        </h2>
        <div className="h-[16px]" />
        <p>
          We collect certain information about your use of the Services through
          tracking technologies such as cookies and web beacons. A “cookie” is a
          unique numeric code that is transferred to your computer to track your
          interests and preferences and to recognize you as a return visitor. A
          “Web beacon” is a transparent file placed on a Web site that enables
          the monitoring of things such as user activity and site traffic.
        </p>
        <div className="h-[16px]" />
        <p>
          We and third-parties with whom we work use cookies, web beacons and
          other similar tracking technologies on the Services enable the
          functionality of the Services, to improve user experience, to help
          remember your preferences and allow us to bring you the content and
          features that are likely to be of interest to you, and to analyze the
          use of our Services and to improve the Services’ functionality. One of
          the ways we do this is through the use of Google Analytics. For more
          information about how Google Analytics uses and processes data, please
          visit&nbsp;
          <a
            href="https://www.google.com/policies/privacy/partners/"
            target="_blank"
            rel="noreferrer"
            className="text-blueVibrant underline"
          >
            https://www.google.com/policies/privacy/partners/
          </a>
          .
        </p>
        <div className="h-[16px]" />
        <p>
          We and third parties with whom we work may also use cookies, web
          beacons, and other similar technologies to collect or receive
          information about your use the Services for the purpose of serving you
          targeted advertising (also referred to as “online behavioral
          advertising” or “interest-based advertising”) and to measure the
          performance of our advertisements. For information about
          interest-based advertising, and to opt out of this type of advertising
          by third parties that participate in self-regulatory programs, please
          visit the Network Advertising Initiative (NAI) at&nbsp;
          <a
            href="https://optout.networkadvertising.org"
            target="_blank"
            rel="noreferrer"
            className="text-blueVibrant underline"
          >
            https://optout.networkadvertising.org
          </a>
          , and/or the Digital Advertising Alliance (DAA) Self-Regulatory
          Program for Online Behavioral Advertising at&nbsp;
          <a
            href="https://optout.aboutads.info"
            target="_blank"
            rel="noreferrer"
            className="text-blueVibrant underline"
          >
            https://optout.aboutads.info
          </a>
          . Please note that any opt-out choice you exercise through these
          programs will still allow the collection of data about your use of the
          Services for other purposes, including research, analytics, and
          internal operations. You may continue to receive advertising, but that
          advertising may be less relevant to your interests.
        </p>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">Your Choices</h2>
        <div className="h-[16px]" />
        <p>
          We provide you with choices with regard to your information. For
          example, you can choose not to give us the personal information we
          request, as described in the “Information You Choose to Provide”
          section of this Privacy Policy. However, in some cases, if you decide
          not to provide the information we request, we will not be able to
          provide the service or information you requested.
        </p>
        <div className="h-[16px]" />
        <p>Other examples of your choices include:</p>
        <div className="h-[16px]" />
        <ul className="ml-[32px] list-disc">
          <li>
            You can browse our website without registering or directly
            submitting any information to us. We may collect some limited
            information automatically, as described above.
          </li>
          <li>
            if you are a Provider, your client Members with whom you are matched
            through the Zoee Platform;
          </li>
          <li>
            You may opt out of receiving our commercial email messages at any
            time by using the opt-out mechanism provided in the message, or by
            adjusting your email preferences within the Zoee platform.
          </li>
          <li>
            You may change your browser settings or take other steps to block,
            manage, or delete cookies. Our Services do not currently respond to
            browser “do not track” signals, so you will need to use your browser
            settings to effectively manage cookies. In some cases, blocking or
            disabling cookies may cause the Services not to work as intended and
            some features may not be available.
          </li>
          <li>
            You may elect to receive text messages from us. When you elect to
            receive text messages, we may send you messages to verify your phone
            number, and to send passcodes to authenticate your access to the
            Services. You may opt out of receiving text messages at any time by
            replying "STOP" to our text messages.
          </li>
        </ul>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">
          Links to Other Websites and Services
        </h2>
        <div className="h-[16px]" />
        <p>
          The Services may contain links to other third-party websites and
          services. These websites and services are not subject to this Privacy
          Policy. They are not controlled by Zoee and Zoee is not responsible
          for their content or their privacy policies, which may differ from
          ours. We have not reviewed and make no representations about the
          accuracy of information on third-party websites, or about their
          information handling practices. Visits you make to these websites are
          at your own risk, and we encourage you to review their privacy
          policies.
        </p>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">How to Contact Us</h2>
        <div className="h-[16px]" />
        <p>
          If you have any questions or comments about this Privacy Policy, if
          you need to report a problem, or if you would like to exercise one of
          your rights under this policy, please contact us at hello@zoee.com.
          Please include your name, contact information, and the nature of your
          request so that we can respond appropriately and promptly to your
          communication.
        </p>
        <div className="h-[32px]" />
        <h2 className="text-[20px] font-bold underline">
          Changes to this Privacy Policy
        </h2>
        <div className="h-[16px]" />
        <p>
          We reserve the right to change the terms of this Privacy Policy at any
          time. Any changes to this Privacy Policy will be reflected on this
          page with a new effective date. We will continue to use and/or
          disclose information in accordance with the policy in effect at the
          time the data was collected. We encourage you to review this Privacy
          Policy regularly for any changes. Your continued use of the Services
          after we post changes is deemed to be acceptance to those changes.
        </p>
      </div>
    </div>
  );
}
