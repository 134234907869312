import type { SVGProps } from "react";
export const SvgChatSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
    <g clipPath="url(#chat-solid-black_svg__a)">
      <path
        fill="currentColor"
        d="m23.626 21.444.52 4.213a1.328 1.328 0 0 1-2 1.307l-4.615-2.74a.67.67 0 0 1-.229-.91 8.35 8.35 0 0 0 1.031-4.004A9.115 9.115 0 0 0 9 10.457a9.8 9.8 0 0 0-3.039.476.667.667 0 0 1-.851-.8A11.62 11.62 0 0 1 16.56 1.67c6.507 0 11.773 4.92 11.773 10.987a10.74 10.74 0 0 1-4.707 8.787M9 12.457a7.6 7.6 0 0 0-4.16 1.213 6.58 6.58 0 0 0-.24 11.107l-.333 2.627a.833.833 0 0 0 1.253.813L9 26.15a7.45 7.45 0 0 0 5.76-2.626 6.48 6.48 0 0 0 1.573-4.214A7.103 7.103 0 0 0 9 12.456"
      />
    </g>
    <defs>
      <clipPath id="chat-solid-black_svg__a">
        <path fill="currentColor" d="M0 0h30v30H0z" />
      </clipPath>
    </defs>
  </svg>
);
