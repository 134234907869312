import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as z from "zod"

import { UserExists } from "../../../api/auth.service"
import PasswordRequirements from "../../account-settings/PasswordRequirements"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import Loader from "../../ui/loader"
import { RegisterFormContext } from "./context"
import { validateFirstAndLastName } from "./name-form"
import GoogleRegister from "./google-register"
import Recaptcha from "./Recaptcha"
import { useAuthModal } from "../use-auth-modal"
import { useLocation } from "react-router-dom"

export const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })

    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const RegistrationForm: React.FC = () => {
  const {
    email,
    setEmail,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    createAccount,
  } = React.useContext(RegisterFormContext)
  const [loadingCreateAccount, setLoadingCreateAccount] = React.useState(false)
  const [loadingGoogle, setLoadingGoogle] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  )
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  )
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false)
  const { password, setPassword, accountType } = useContext(RegisterFormContext)
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false)
  const [confUserPassword, setConfUserPassword] = useState<string>("")
  const [isNotRobot, setIsNotRobot] = React.useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  )
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isGoogleSignUp, setIsGoogleSignUp] = useState<boolean>(false)
  const { showLogin } = useAuthModal()
  const location = useLocation()

  useEffect(() => {
    if (email && firstName && lastName && isGoogleSignUp) {
      handleSubmit()
    }
  }, [email, firstName, lastName, isGoogleSignUp])

  useEffect(() => {
    const validateForm = () => {
      const emailIsValid = z.string().email().safeParse(email).success
      const firstNameIsValid =
        firstName.trim() !== "" && validateFirstAndLastName(firstName).success
      const lastNameIsValid =
        lastName.trim() !== "" && validateFirstAndLastName(lastName).success
      const passwordsMatch = isEntreeValid && password === confUserPassword

      setIsFormValid(
        emailIsValid &&
          firstNameIsValid &&
          lastNameIsValid &&
          passwordsMatch &&
          isNotRobot
      )
    }

    validateForm()
  }, [
    email,
    firstName,
    lastName,
    password,
    confUserPassword,
    isNotRobot,
    accountType,
  ])

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    setLoadingCreateAccount(true)
    setLoadingGoogle(false)

    const emailSchema = z.string().email().safeParse(email)

    if (!emailSchema.success) {
      setError(emailSchema.error.issues[0].message)
      setLoadingCreateAccount(false)
      return
    }
    const emailExists = await doesEmailExist(email)
    if (emailExists?.found) {
      setError("Email is already in use")
      setLoadingCreateAccount(false)
      return
    }
    try {
      await createAccount()
      setLoadingCreateAccount(false)
    } catch (error) {
      console.error("Error creating account:", error)
      setError("Failed to create account. Please try again.")
      setLoadingCreateAccount(false)
    }
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    let inputValue = e.target.value
    const inputSuccess = validateFirstAndLastName(inputValue).success
    const errorMsg = "Invalid Input"

    if (inputName === "first" || inputName === "last") {
      inputValue = capitalizeFirstLetter(inputValue)
    }

    if (inputName === "email") {
      setError(undefined)
      setEmail(inputValue)
    }

    if (inputName === "first") {
      setFirstNameError(undefined)
      setFirstName(inputValue)
      if (!inputSuccess) {
        setFirstNameError(errorMsg)
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined)
      setLastName(inputValue)
      if (!inputSuccess) {
        setLastNameError(errorMsg)
      }
    }

    if (inputName === "password") {
      setPassword(inputValue)
    }

    if (inputName === "conf-pass") {
      setConfUserPassword(inputValue)
    }
  }
  useEffect(() => {
    if (password !== confUserPassword) {
      setPasswordError("Passwords must match")
    } else {
      setPasswordError(undefined)
    }
  }, [confUserPassword])

  const searchParams = new URLSearchParams(location.search)
  const emailFromLocation = searchParams.get("email")
  const requestedCoachName = `${searchParams.get("fname")} ${searchParams.get(
    "lname"
  )}`
  const coachEndpoint = searchParams.get("endpoint")

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-2"
    >
      {accountType !== "coach" && (
        <div>
          <h3 className="font-bold text-lg text-center">
            {emailFromLocation
              ? "Activate Secure Client Portal"
              : "Create Account"}
          </h3>
          <p className="text-sm text-center">
            Please provide your information below to establish a secure, private
            messaging account.
          </p>
        </div>
      )}

      <Input
        label="Email"
        placeholder="Email"
        value={email}
        onChange={(e) => handleChange(e, "email")}
        error={error}
        disabled={loadingCreateAccount || loadingGoogle}
        autoFocus
      />
      <Input
        label="First Name"
        placeholder="First Name"
        value={firstName}
        onChange={(e) => handleChange(e, "first")}
        error={firstNameError}
        disabled={loadingCreateAccount || loadingGoogle}
      />
      <Input
        label="Last Name"
        placeholder="Last Name"
        value={lastName}
        onChange={(e) => handleChange(e, "last")}
        error={lastNameError}
        disabled={loadingCreateAccount || loadingGoogle}
      />
      {showPaswReqs && (
        <PasswordRequirements
          validEntree={password}
          setIsEntryValid={setIsEntryValid}
        />
      )}
      <Input
        label="Password"
        placeholder="New password"
        value={password}
        onFocus={() => setShowPassReq(true)}
        onChange={(e) => handleChange(e, "password")}
        type="password"
        autoComplete="off"
        disabled={loadingCreateAccount || loadingGoogle}
      />
      <Input
        label="Confirm your password"
        placeholder="Confirm new password"
        value={confUserPassword}
        error={passwordError}
        onChange={(e) => handleChange(e, "conf-pass")}
        type="password"
        onFocus={() => setShowPassReq(false)}
        autoComplete="off"
        disabled={loadingCreateAccount || loadingGoogle}
      />
      <Recaptcha setIsNotRobot={setIsNotRobot} />
      <Button
        type="submit"
        className="w-full"
        disabled={loadingCreateAccount || !isFormValid}
      >
        {loadingCreateAccount ? (
          <Loader />
        ) : emailFromLocation ? (
          "Continue"
        ) : (
          "Create Account"
        )}
      </Button>
      {/* <GoogleRegister
        setEmail={setEmail}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setIsGoogleSignUp={(isGoogleSignUp) => {
          setLoadingGoogle(true)
          setLoadingCreateAccount(false)
          setIsGoogleSignUp(isGoogleSignUp)
        }}
        loading={loadingGoogle}
      /> */}

      <p className="text-muted-foreground text-xs text-pretty w-full text-center mx-auto">
        By clicking continue, I agree{" "}
        {emailFromLocation ? (
          <>
            to connect with Coach{" "}
            <Link
              to={`/find-a-coach/${coachEndpoint}`}
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {requestedCoachName}
            </Link>{" "}
            and to Zoee{" "}
          </>
        ) : (
          "to Zoee "
        )}
        <Link
          to="/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Terms
        </Link>{" "}
        &{" "}
        <Link
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          Privacy Policy
        </Link>
      </p>
      <p className="text-muted-foreground text-sm text-center">
        Already have an account?{" "}
        <Button
          className="w-fit mx-auto"
          variant="link"
          size="default"
          onClick={(e) => {
            e.preventDefault()
            showLogin()
          }}
        >
          Login
        </Button>
      </p>
    </form>
  )
}
