import { FC, useEffect, useState } from "react"
import { Link, LinkProps } from "react-router-dom"
import { Button } from "../../ui/button"
import footerBackground from "../../../assets/images/footer-background-new.png"
import {
  SvgFacebook,
  SvgInstagram,
  SvgLinkedin,
  SvgZoeeLogoTM,
} from "../../icons/"
import IcfLogo from "../../icons/logos/icf"
interface Social {
  href: string
  icon: JSX.Element
}

const socials: Social[] = [
  { href: "https://www.facebook.com/zoeeapp", icon: <SvgFacebook /> },
  { href: "https://www.instagram.com/zoeeapp/", icon: <SvgInstagram /> },
  {
    href: "https://linkedin.com/company/solelifeinc",
    icon: <SvgLinkedin />,
  },
]
interface FooterGroupLink {
  header: string
  links: LinkProps[]
}

const footerGroupLinks: FooterGroupLink[] = [
  {
    header: "About",
    links: [
      { to: "/features", children: "Features" },
      { to: "/pricing", children: "Pricing" },
      { to: "/why-zoee", children: "Our Story" },
      { to: "/blog", children: "Blog" },
      { to: "/find-a-coach", children: "Find a Coach" },
      { to: "/pricing#faq", children: "FAQs" },
      {
        to: "https://intercom.help/zoee/en",
        target: "_blank",
        rel: "noopener, noreferrer",
        children: "Help Center",
      },
    ],
  },
  {
    header: "Connect",
    links: [{ to: "/contact", children: "Contact Us" }],
  },
]

const Footer: FC = () => {
  return (
    <footer
      className="relative h-[615px] bg-cover bg-no-repeat px-4 pt-[20px] sm:pt-[64px] md:px-16"
      style={{
        backgroundImage: `url(${footerBackground})`,
      }}
    >
      <SvgZoeeLogoTM className="inline h-[20px] w-fit md:h-[30px] lg:h-[50px]" />

      <div className="mb-[15px] flex items-center gap-2 pt-4 sm:mb-[20px] sm:justify-start">
        {socials.map(({ href, icon }) => (
          <Button variant="ghost" size="icon" key={href} asChild>
            <a href={href} target="_blank" rel="noreferrer">
              {icon}
            </a>
          </Button>
        ))}
      </div>
      <IcfLogo />

      <div className="relative z-30 flex flex-wrap items-start justify-start space-x-10 text-[15px] font-bold md:justify-evenly md:text-[25px]">
        {footerGroupLinks.map(({ header, links }) => (
          <div className="mt-6 lg:-mt-32 lg:ml-44" key={header}>
            <h2 className="text-md -mb-6 md:text-xl">{header}</h2>
            <ul className="m-8 ml-0 flex flex-col gap-4 text-sm font-normal md:text-[17px]">
              {links.map(({ children, ...rest }, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="hover:text-blurple transition-colors"
                      {...rest}
                    >
                      {children}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>

      <div className="flex flex-col justify-start text-left">
        <div className="text-grayCharcoal w-fit text-base">
          <div className="relative z-40">
            Made with &#x2764; in North Carolina
          </div>
          <p>&copy; SoleLife, Inc D/B/A Zoee</p>
          <a
            style={{ whiteSpace: "nowrap" }}
            className="text-blurple"
            href="/terms-and-conditions"
          >
            Terms &amp; Conditions
          </a>
          &nbsp;|&nbsp;
          <a
            style={{ whiteSpace: "nowrap" }}
            className="text-blurple"
            href="/privacy-policy"
          >
            Privacy Policy
          </a>
          &nbsp;|&nbsp;
          <a
            style={{ whiteSpace: "nowrap" }}
            className="text-blurple"
            href="/cookie-policy"
          >
            Cookie Policy
          </a>
        </div>
      </div>
      <div
        id="termly-embed"
        data-id="43904e5f-c5ed-4170-800a-74e5246a8675"
        data-type="iframe"
      ></div>
    </footer>
  )
}

export default Footer
