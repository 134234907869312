import { FC } from "react";

import {
  SvgCheckCircle,
  SvgCheckCircleSolid,
  SvgZoeeLogo,
  SvgQuickBooks,
  SvgMonday,
  SvgGoogleColored,
  SvgCalendly,
  SvgLeadgenCompetitor,
  SvgZoom,
} from "../../icons";

type Props = {
  variation: string;
};

const features = [
  {
    id: 1,
    title: "Client Management",
    competitorImg: <SvgMonday />,
    competitorCost: "$360",
  },
  {
    id: 2,
    title: "Scheduling",
    competitorImg: <SvgCalendly />,
    competitorCost: "$96",
  },
  {
    id: 3,
    title: "Video Communication",
    competitorImg: <SvgZoom />,
    competitorCost: "$150",
  },
  {
    id: 4,
    title: "Billing & Payment Automation",
    competitorImg: <SvgQuickBooks />,
    competitorCost: "$264",
  },
  {
    id: 5,
    title: "Inbox & Digital Note Taking",
    competitorImg: <SvgGoogleColored />,
    competitorCost: "$144",
  },
  {
    id: 6,
    title: "Lead Generation",
    competitorImg: <SvgLeadgenCompetitor />,
    competitorCost: "$948",
  },
];

const moneyflowFeatures = [
  {
    id: 1,
    title: "Business Page",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Client Management",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Scheduling",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Billing & Payment Automation",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Inbox and Digital Notetaking",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Video Communication",
    comingSoon: false,
  },
  {
    id: 1,
    title: "Lead Generation",
    comingSoon: true,
  },
];

const LandingComparison: FC<Props> = ({ variation }) => {
  const scrollTop = () => {
    window.scroll(0, 0);
  };

  return (
    <>
      {variation === "normal" && (
        <>
          <div className="flex flex-col items-center mx-auto">
            <div className="max-w-[609px] text-grayCharcoal font-bold text-center mb-[26px] md:mb-[46px] mx-[29px] md:mx-0">
              <h3 className="text-[40px] md:text-[56px] mb-[6px]">
                Increase Your Margins
              </h3>
              <p className="text-[24px]">
                We created our own bundled suite of coaching services into one
                affordable price.
              </p>
            </div>
            <div className="md:w-[1034px] mx-[8px] bg-white box-shadow-2 rounded-[16px] md:mx-0 px-[9px] md:px-[60px] pt-[49px] md:pt-[29px] pb-[36px] md:pb-[46px]">
              <div className="grid grid-cols-3 md:grid-cols-4">
                <div className="col-span-2 md:col-span-3"></div>
                <div className="flex justify-center">
                  <SvgZoeeLogo className="w-[75px] h-[15px]" />
                </div>
              </div>
              <div>
                {features.map((feature, idx) => {
                  return (
                    <div
                      key={idx}
                      className="grid grid-cols-3 md:grid-cols-4 py-[28px] border-b last:border-b-0 border-b-grayFlash"
                    >
                      <div className="flex items-center gap-[4px] md:gap-[8px] md:col-span-2">
                        <SvgCheckCircle className="md:block hidden" />
                        <div className="flex flex-col md:flex-row gap-[4px]">
                          <p className="text-[15px] md:text-[16px] text-left">
                            {feature.title}
                          </p>
                          {feature.title === "Lead Generation" && (
                            <div className="bg-grayMist text-grayCharcoal px-[4px] md:px-[8px] font-semibold text-base md:text-[13px] rounded-[4px] min-w-fit w-fit">
                              Coming soon
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-[8px] text-center font-bold">
                        {feature.competitorImg}
                        {feature.competitorCost}
                      </div>
                      <div className="flex justify-center">
                        <SvgCheckCircleSolid className="w-[22px] h-[22px]" />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4 items-center">
                <div className="md:col-span-2"></div>
                <div className="flex justify-center font-bold text-[20px] md:text-[28px]">
                  $1962/yr
                </div>
                <div className="mx-auto flex items-center justify-center font-bold text-[20px] md:text-[28px] text-white bg-green w-[106px] md:w-[158px] h-[44px] md:h-[56px] rounded-[8px]">
                  $490/yr
                </div>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-4">
                <div className="md:col-span-3 col-span-2"></div>
                <div className="font-semibold text-base md:text-[15px] flex justify-center mt-[8px] md:mt-[15px]">
                  or $49/monthly
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {variation === "moneyflow" && (
        <div className="flex flex-col lg:flex-row items-center w-full justify-between gap-[70px] sm:gap-[140px]">
          <div className="flex flex-col gap-[40px]">
            {moneyflowFeatures?.map((feature) => (
              <div className="flex items-center gap-[24px] sm:gap-[64px]">
                <p
                  className="max-w-[675px] w-[300px] sm:min-w-[500px] xl:min-w-[600px] h-[70px] bg-white feature-shadow
                                    rounded-[20px] flex items-center sm:px-[40px] px-[24px]
                                    font-bold text-[18px] sm:text-[24px] xl:text-[28px] font-bold uppercase"
                >
                  {feature.title}
                </p>
                <SvgCheckCircleSolid
                  fill="#FBBA0C"
                  className="w-[40px] sm:w-auto"
                />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-[36px] items-start">
            <h4 className="font-bold text-[40px]">Increase your margins.</h4>
            <p className="font-bold text-[24px]">
              We built our own suite of coaching services into one affordable
              price.
            </p>
            <p className="font-bold text-[24px]">
              Money Flow guests get a special rate of just{" "}
              <span className="text-green">$24/month</span> with an annual
              subscription.
            </p>
            <div className="flex items-center gap-[16px]">
              <button className="btn-primary bg-primaryBlue text-white text-[28px] relative btn-primary-disabled">
                $490/yr
                <div className="absolute h-[12px] w-full rotate-[30deg] bg-vividRed/[0.7] rounded-[4px]"></div>
                <div className="absolute h-[12px] w-full rotate-[150deg] bg-vividRed/[0.7] rounded-[4px]"></div>
              </button>
              <button
                className="btn-primary bg-green text-white text-[28px]"
                onClick={() => {
                  scrollTop();
                }}
              >
                $288/yr
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingComparison;
