import type { SVGProps } from "react";
export const SvgShield = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m16.056 19.363-.341-.494zm-.18.124-.342-.493zm-7.751 0 .34-.493zm-.18-.124-.342.493zM19.868 8.65l-.597.062zm.036.35.597-.062zm.029 3.391.598.052zm-.03.353-.598-.051zm-15.807 0 .597-.051zm-.03-.353-.598.052zM4.094 9l-.597-.061zm.036-.349.597.062zm12.533-4.574-.263.539zm-9.328 0 .263.539zm-.492 14.49.383-.462zM4.24 14.091l.591-.104zm10.097 6.378-.26-.541zm-4.676 0 .26-.541zm7.494-1.902-.383-.462zm2.603-4.476.59.103zm-4.044 4.778-.18.125.682.987.18-.125zm-7.25.125-.18-.125-.682.987.18.125zM19.273 8.713l.036.349 1.194-.123-.036-.35zm.064 3.628-.03.353 1.195.102.03-.352zm-14.642.353-.03-.353-1.195.103.03.352zm-.002-3.632.036-.349-1.194-.123-.036.349zm-.028 3.279a17.4 17.4 0 0 1 .028-3.28L3.498 8.94a18.6 18.6 0 0 0-.03 3.505zm14.644-3.28c.113 1.09.122 2.188.028 3.28l1.196.103c.1-1.167.09-2.34-.03-3.505zm-2.38-5.524a11.24 11.24 0 0 0-9.856 0L7.6 4.616a10.04 10.04 0 0 1 8.802 0zM8.284 18.87c-.573-.397-.83-.575-1.058-.764l-.766.924c.277.23.586.442 1.142.827zM3.5 12.796c.058.672.09 1.044.151 1.398l1.182-.207c-.05-.29-.078-.6-.138-1.293zm3.728 5.31a6.9 6.9 0 0 1-2.395-4.119l-1.182.207a8.1 8.1 0 0 0 2.81 4.835zm8.307.888c-.785.543-1.123.775-1.455.934l.519 1.082c.438-.21.868-.51 1.619-1.03zm-7.75.987c.75.52 1.18.819 1.618 1.029l.52-1.082c-.333-.16-.671-.391-1.456-.934zm6.295-.053a4.81 4.81 0 0 1-4.158 0l-.519 1.082a6.01 6.01 0 0 0 5.196 0zm2.318-.072c.556-.385.865-.597 1.142-.827l-.766-.924c-.228.189-.485.367-1.058.764zm2.909-7.163c-.06.693-.087 1.004-.138 1.294l1.182.207c.062-.354.093-.726.151-1.398zm-1.767 6.336a8.1 8.1 0 0 0 2.81-4.835l-1.181-.207a6.9 6.9 0 0 1-2.395 4.118zM16.401 4.616a5.15 5.15 0 0 1 2.87 4.097l1.195-.123a6.35 6.35 0 0 0-3.539-5.053zM7.073 3.537A6.35 6.35 0 0 0 3.533 8.59l1.194.123a5.15 5.15 0 0 1 2.87-4.097zM9.674 11.326a.6.6 0 1 0-.848.848zm1.576 2.424-.424.424a.6.6 0 0 0 .863-.015zm3.939-3.34a.6.6 0 0 0-.878-.82zm-6.363 1.764 2 2 .848-.848-2-2zm2.863 1.985 3.5-3.75-.878-.818-3.5 3.75z"
    />
  </svg>
);
