import React from "react";
import ContactAvatar from "./ContactAvatar";
import ContactStatusTag from "../contacts/ContactStatusTag";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import useDate from "../../hooks/useDate";
import { Button } from "../ui/button";
import { SvgCheckCircle } from "../icons";

interface Props {
    variant: "view" | "select";
    contact: any;
    parentHandleSelect?: any;
    isSelected?: boolean;
    reachedMaxInvitedContacts: boolean;
}

export default function ConnectedContactCard({ variant, contact, parentHandleSelect, isSelected, reachedMaxInvitedContacts }: Props) {
    const { contact_type, contact_name, tagline, contact_date, profile_id } = contact;
    const { renderError } = React.useContext(CommonFunctionCtx);

    const [selected, setSelected] = React.useState<boolean>(isSelected || false);
    const { smDate } = useDate()

    const handleSelectContact = () => {
        if (!reachedMaxInvitedContacts) {
            setSelected(() => !selected);
            parentHandleSelect(profile_id);
        } else {
            if (selected) {
                setSelected(false);
                parentHandleSelect(profile_id);
            } else {
                renderError(
                    "You have reached the maximum number of contacts that you can invite to a meeting."
                );
            }
        }
    };

    const getTagline = () => {
        let value = "";
        if (tagline) {
            value = tagline;
        } else if (contact_type === "invited") {
            value = `Invited ${smDate(contact_date)}`;
        } else {
            value = contact.profile_type === 'member' ?
             "Zoee Member" : "Professional Coach"

        }
        return value;
    };

    const getStatus = () => {
        let status = "Unknown";
        if (contact_type === "connected") {
            status = "active";
        } else if (contact_type === "prospect") {
            status = "prospect";
        } else if (contact_type === "inactive") {
            status = "inactive";
        }
        return status;
    };

    return (
        <div
            className="w-[258px] h-[302px] promotion-shadow flex flex-col items-center justify-between rounded-[16px] relative"
        >
            <div
                className="absolute top-[4px] left-[50%] -translate-x-[50%] w-[250px] h-[78px] bg-grayFlash rounded-[12px]"
            >
                <ContactStatusTag
                    contactStatus={getStatus()}
                    origin=""
                    contactDate={contact_date}
                />
                <div className="absolute top-[52px] left-[50%] -translate-x-[50%]">
                    <ContactAvatar
                        contact={contact}
                        width={80}
                        height={80}
                        border="border border-[4px] border-white"
                        bold={true}
                    />
                </div>
            </div>
            <div className="text-center">
                <h3
                    className={`font-bold mt-[160px] ${
                        contact_type === "invited" ? "text-base" : "text-[18px]"
                    }`}
                >
                    {contact_name?.length > 20 ? `${contact_name?.slice(0, 20)}...` : contact_name}
                </h3>
                <p className="text-graySlate text-base mt-[2px]">{getTagline()}</p>
            </div>

            {variant === "select" && (
                <>
                    {selected ? (
                        <Button
                            className="mb-[24px] h-[32px] flex items-center gap-[10px] text-md"
                            onClick={handleSelectContact}
                        >
                            Selected
                            <SvgCheckCircle />
                        </Button>
                    ) : (
                        <button
                            className="mb-[24px] btn-primary btn-secondary h-[32px]"
                            onClick={handleSelectContact}
                        >
                            Select
                        </button>
                    )}
                </>
            )}

            {variant === "view" && (
                <button className="mb-[24px] text-base font-bold text-blurple">
                    View Profile
                </button>
            )}
        </div>
    );
};
