import React, { useState } from "react"
import * as Testimonials from '../Testimonials'
import OverviewBento from "./OverviewBento"
import PrivateProfileFeatureTab from "./PrivateProfileFeatureTab"
import ProfileAboutTab from "./ProfileAboutTab"
import ProfileServices from "./ProfileServices"
import ShareModal from "./ShareModal"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { SvgArticle, SvgBusiness, SvgGrid, SvgHelp, SvgList } from "../icons/"

interface Props {
  post?: any
  service?: string
}

const TilesCarousel: React.FC<Props> = ({ post, service }) => {
  const [activeTab, setActiveTab] = useState("overview")
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const [filledButton, setfilledButton] = useState<boolean>(false)
  const endPoint = coachPublicProfileData?.endpoint_slug

  const tabLabels = [
    { name: "Overview", activeTab: "overview", icon: <SvgGrid /> },
    { name: "About", activeTab: "about", icon: <SvgList /> },
    { name: "Services", activeTab: "services", icon: <SvgBusiness /> },
    { name: "Featured Content", activeTab: "featured", icon: <SvgArticle /> },
    { name: "Testimonials", activeTab: "testimonials", icon: <SvgHelp /> },
  ]

  const renderServices = () => {
    setActiveTab("services")
  }

  return (
    <>
      <div className="flex flex-col gap-[12px] w-full relative">
        <div className="flex items-stretch gap-8 overflow-x-auto">
          {tabLabels.map((tab, index) => (
            <Button
              className={`h-[50px] mb-2 ml-2 ${
                (filledButton || tab.activeTab === "overview") &&
                activeTab === tab.activeTab
                  ? "bg-white"
                  : ""
              }`}
              key={index}
              variant="utility"
              onClick={() => {
                setActiveTab(tab.activeTab)
                setfilledButton(true)
              }}
            >
              {tab.icon}
              <h3>{tab.name}</h3>
            </Button>
          ))}
        </div>
      </div>
      {shareModalOpen && (
        <>
          <ShareModal
            endpoint_slug={endPoint}
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      )}
      {activeTab === "overview" && (
        <OverviewBento renderServices={renderServices} />
      )}
      {activeTab === "about" && (
        <ProfileAboutTab coachPublicProfileData={coachPublicProfileData} />
      )}
      {activeTab === "services" && <ProfileServices />}
      {activeTab === "featured" && <PrivateProfileFeatureTab />}
      {activeTab === "testimonials" && (
        <Testimonials.ProfileTestimonialTab
          isEditable
          coachPublicProfileData={coachPublicProfileData}
        />
      )}
    </>
  )
}

export default TilesCarousel
