import previewImage from "../../../assets/blog/doYouNeedInsuranceToBeACoach.webp";
import { Post } from "../../../types/postTypes";

export const DoYouNeedInsuranceToBeACoach: Post = {
    metadata: {
        id: 21,
        title: "Do You Need Insurance to Be a Coach?",
        author: "Zoee",
        date: "March 30, 2023",
        urlSlug: "do-you-need-insurance-to-be-a-coach",
        description:
            "Protect your coaching business and your clients with insurance! As a coach, do you really need it? Find out the answer today.",
        previewImg: previewImage,
        tags: ["Coach", "Business", "Entrepreneur"],
    }, 
    content: [
        {
            type: "paragraph",
            text: "If you're considering becoming a coach, one of the key questions that you may want to ask yourself is whether or not you need insurance. After all, as with any profession, there are certain risks associated with coaching – and having adequate insurance coverage can help protect you from potential financial losses in case something goes wrong.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Professional Liability Insurance:",
                },
                {
                    type: "normal",
                    text: "One of the most important types of insurance for coaches is professional liability insurance (also known as 'errors & omissions' or 'E&O' insurance). This type of policy covers legal costs related to third-party claims against you arising out of your negligence or the advice you provide while acting as a coach. It's designed to provide coverage in the event that you are accused of making an error or breach of duty in providing services to your clients.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "General Liability Insurance:",
                },
                {
                    type: "normal",
                    text: "Another type of insurance that coaches should consider is general liability insurance (or 'GL'). This policy provides coverage for damages caused by accidents or other mishaps on your premises and can help cover medical expenses if someone gets injured while visiting your office. Additionally, GL policies may also provide protection if someone brings a lawsuit against you due to false advertising claims or copyright infringement allegations resulting from content published on your website or social media pages.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Business Owners Policy (BOP):",
                },
                {
                    type: "normal",
                    text: "Business owners policies (also referred to as BOPs) are comprehensive policies that include both professional liability and general liability coverage under one umbrella policy for a set premium. Depending on the size and scope of activities conducted by your business, this option could be an ideal way for coaches to save money on their premiums while still getting adequate coverage.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Before deciding whether or not to purchase any kind of insurance plan, it's wise for coaches to discuss their needs with professionals who have experience in this field; they'll be able to offer tailored advice based on your particular situation. Ultimately, though, having appropriate levels of protection can give peace-of-mind knowing that if something unexpected happens – you’ll be covered!",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Written by Nichole Lowe, Founder & CEO of Zoee"
                },
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Zoee is a turnkey coaching platform that lets you automate, optimize, and scale your online coaching business."
                },
            ]
        },
    ], 
};
