import type { SVGProps } from "react";
export const SvgApplication = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 49 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.5 44h30a5 5 0 0 0 5-5V9a5 5 0 0 0-5-5h-30a5 5 0 0 0-5 5v30a5 5 0 0 0 5 5m-1.667-5V20.667h8.334v20H9.5A1.666 1.666 0 0 1 7.833 39m33.334-18.333V29H19.5v-8.333zm-1.667 20h-20v-8.334h21.667V39a1.666 1.666 0 0 1-1.667 1.667M7.834 9A1.666 1.666 0 0 1 9.5 7.334h30A1.666 1.666 0 0 1 41.167 9v8.333H7.833z"
    />
    <path
      fill="currentColor"
      d="M14.5 12.33a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0M21.167 12.33a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0M27.833 12.33a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0"
    />
  </svg>
);
