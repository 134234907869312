import React, { useContext, useState, useEffect} from "react"
import BillingPreviewItem from "./BillingPreviewItem"
import { Button } from "../../ui/button"
import { ClientPaymentRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { ProfileClientPaymentsItem } from "../../../types/clientPaymentTypes"
import { UserCtx } from "../../../context/userContext"

interface Props {
  contact: any
  serviceState: string
}

const BillingPreviewElement: React.FC<Props> = (props: any) => {
  const { user } = useContext(UserCtx)
  const { renderError } = useContext(CommonFunctionCtx)
  const [showCount, setShowCount] = useState<number>(3)
  const [invoices, setInvoices] = useState<
    Array<ProfileClientPaymentsItem>
  >([])
  interface GroupedInvoices {
    [currency: string]: Array<ProfileClientPaymentsItem>
  }
  const [groupedInvoices, setGroupedInvoices] = useState<GroupedInvoices>(
    {}
  )
  const sortPayments = (payments: any) => {
    const sorted = payments.sort((a: any, b: any) => {
      const bDate: any = new Date(b.transaction_date)
      const aDate: any = new Date(a.transaction_date)
      return bDate - aDate
    })
    return sorted
  }

  const getPaymentsProfileClients = () => {
    if (user) {
      if (user.activeProfile === "member") {
        ClientPaymentRequests.getPaymentsProfileClients({
          member_profile_id: user.activeProfileId,
          coach_profile_id: props.contact.profile_id,
        })
          .then((data) => {
            setInvoices(sortPayments(data.client_payments_list))
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
          })
      } else if (
        user.activeProfile === "coach" &&
        props.contact.profile_type === "member"
      ) {
        ClientPaymentRequests.getPaymentsProfileClients({
          member_profile_id: props.contact.profile_id,
          coach_profile_id: user.activeProfileId,
        })
          .then((data) => {
            setInvoices(sortPayments(data.client_payments_list))
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
          })
      } else {
        ClientPaymentRequests.getPaymentsProfileClients({
          member_profile_id:
            props.serviceState === "coach"
              ? user.activeProfileId
              : props.contact.profile_id,
          coach_profile_id:
            props.serviceState === "coach"
              ? props.contact.profile_id
              : user.activeProfileId,
        })
          .then((data) => {
            setInvoices(sortPayments(data.client_payments_list))
          })
          .catch((ex) => {
            console.log(ex)
            renderError(ex.response.data.message)
          })
      }
    }
  }

  useEffect(() => {
    const groupedInvoices: GroupedInvoices = invoices.reduce((acc, invoice) => {
      // Convert currency to lowercase for consistency
      const currency = invoice.currency?.toLowerCase()

      // Initialize the array for this currency if it doesn't exist
      if (!acc[currency]) {
        acc[currency] = []
      }

      // Push the current invoice into the correct currency array
      acc[currency].push(invoice)

      return acc
    }, {} as GroupedInvoices)
    setGroupedInvoices(groupedInvoices)
  }, [invoices])

  useEffect(() => {
    getPaymentsProfileClients()
  }, [])

  const calculateTotal = (invoices: any[]): any => {
    const allInvoices = Object.values(invoices).flat()
    const invoiceAmountTotals = allInvoices?.map((o: any) => parseInt(o.amount))
    const invoicesTotal = invoiceAmountTotals.reduce((sum, curr) => sum + curr)
    return invoicesTotal
  }

  const loadMore = () => {
    setShowCount(showCount + 2)
  }

  const loadLess = () => {
    setShowCount(3)
  }

  const handleRefundSuccess = () => {
    getPaymentsProfileClients() // Trigger this after a refund
  }

  return (
    <div className="w-full md:main-shadow rounded-[16px] md:p-[40px] relative">
      <div className="flex items-center justify-between mb-[20px]">
        <div className="flex justify-between items-center mb-[20px] w-full">
          <h2 className="font-semibold text-[28px]">Billing</h2>
        </div>
      </div>

      {Object.keys(groupedInvoices).map((currency) => (
        <div key={currency} className="mb-[24px]">
          {/* Display Total */}
          {groupedInvoices[currency].length > 0 && (
            <div className="mb-[24px]">
              <h5 className="text-base font-bold text-graySlate mb-[8px]">
                Total
              </h5>
              <p className="text-[32px] font-bold">
                {String.fromCharCode(
                  parseInt(groupedInvoices[currency][0]?.symbol?.slice(2), 16)
                )}
                {calculateTotal(groupedInvoices[currency]).toLocaleString()}
              </p>
            </div>
          )}

          {/* Display Invoices */}
          <div className="flex flex-col gap-[8px]">
            {invoices.length === 0 ? (
              <p className="px-[24px] pt-[12px] md:pt-0 pb-[20px] text-center font-bold">
                You don't have any past payments with this contact.
              </p>
            ) : (
              groupedInvoices[currency]
                .slice(0, showCount)
                .map((invoice, index) => (
                  <div key={index}>
                    <BillingPreviewItem
                      invoice={invoice}
                      serviceState={props.serviceState}
                      onRefundSuccess={handleRefundSuccess}
                    />
                  </div>
                ))
            )}
          </div>

          {/* Group-Specific Button */}
          {/* {groupedInvoices[currency].length > 0 && (
            <div className="flex justify-center mt-[16px]">
              <Button
                onClick={() =>
                  alert(`Button for currency group "${currency}" clicked!`)
                }
              >
                Action for {currency.toUpperCase()}
              </Button>
            </div>
          )} */}
        </div>
      ))}

      {/* Load More/Less Buttons */}
      {invoices.length > 3 && (
        <div className="flex items-center justify-center gap-[12px] mx-auto mt-[24px]">
          {showCount > 2 && (
            <Button onClick={loadLess} variant="secondary" size="sm">
              Show Less
            </Button>
          )}
          <Button
            onClick={loadMore}
            disabled={showCount >= invoices.length}
            size="sm"
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  )
}

export default BillingPreviewElement
