import React from "react"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { SvgPlus } from "../icons"

export default function AddPortraitVideoButton() {
  const { setEditPortraitVideo } = React.useContext(CoachPrivateProfileContext)

  return (
    <Button
      onClick={() => setEditPortraitVideo(true)}
      className="bg-white px-[20px] hover:bg-grayFlash gap-1"
      variant={"utility"}
      size={"lg"}
    >
      <SvgPlus />
      Add
    </Button>
  )
}
