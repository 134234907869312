import { useState, useRef, useEffect, memo, useContext } from "react"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { SvgCheckbox, SvgCheckboxOff } from "../icons"

interface CardFieldsProps {
  isCheckedRef: React.MutableRefObject<boolean>
  createOrder: (data: any, actions: any) => Promise<any>
  onApprove: (data: any, actions: any) => Promise<any>
  isChecked: boolean
  setIsChecked: (checked: boolean) => void
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  redirectUrl: string
  selectedPlanFromChild: any
}

interface CardFieldInstance {
  submit: () => Promise<void>
  isEligible: () => boolean
  NameField: () => { render: (element: string) => void }
  NumberField: () => { render: (element: string) => void }
  CVVField: () => { render: (element: string) => void }
  ExpiryField: () => { render: (element: string) => void }
}

const PayPalCardFields: React.FC<CardFieldsProps> = ({
  isCheckedRef,
  onApprove,
  createOrder,
  isChecked,
  setIsChecked,
  isTermsAgreed,
  setIsTermsAgreed,
  redirectUrl,
  selectedPlanFromChild
}) => {
  const [loading, setLoading] = useState(false)
  const checkboxRef = useRef<HTMLInputElement | null>(null)
  const [cardFieldInstance, setCardFieldInstance] =
    useState<CardFieldInstance | null>(null)

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked)

    isCheckedRef.current = event.target.checked
  }

  useEffect(() => {
    isCheckedRef.current = isChecked
      }, [isChecked])

  useEffect(() => {
    if (window.paypal) {
      console.log("SDK loaded")
      const cardField = window.paypal.CardFields({
        createOrder: (data: any) => createOrder(data, "card"),
        onApprove: (data: any) => onApprove(data, "card"),
      })
      if (cardField.isEligible()) {
        const nameField = cardField.NameField()
        nameField.render("#card-name-field-container")

        const numberField = cardField.NumberField()
        numberField.render("#card-number-field-container")

        const cvvField = cardField.CVVField()
        cvvField.render("#card-cvv-field-container")

        const expiryField = cardField.ExpiryField()
        expiryField.render("#card-expiry-field-container")

        setCardFieldInstance(cardField)
      }
    }
  }, [])

  const handleSubmit = () => {
    if (cardFieldInstance) {
      setLoading(true)
      cardFieldInstance
        .submit()
        .then(() => {
          setLoading(false)
        })
        .catch((error: any) => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {}, [isChecked])

  return (
    <>
      <div id="checkout-form">
        <div id="card-name-field-container"></div>
        <div id="card-number-field-container"></div>
        <div id="card-expiry-field-container"></div>
        <div id="card-cvv-field-container"></div>
      </div>
      <div className="flex flex-col items-center justify-center w-full space-y-2">
        <div className="flex w-[95%] mb-2 mt-2">
          <input
            id="checkbox-input"
            ref={checkboxRef}
            onChange={handleChange}
            checked={isChecked}
            type="checkbox"
            className="hidden"
          />
          <label
            htmlFor="checkbox-input"
            className="self-start mr-4 cursor-pointer flex items-center"
          >
            {isChecked ? (
              <SvgCheckbox className="cursor-pointer" />
            ) : (
              <SvgCheckboxOff className="cursor-pointer" fill="#CCCCCC" />
            )}
          </label>

          <div className="text-graySlate text-base">
            Save card for future payments
          </div>
        </div>
      </div>
      <div>
        <Button
          id="card-field-submit-button"
          type="button"
          className="w-[95%] text-md ml-2"
          onClick={handleSubmit}
        >
          {loading ? <Loader /> : "Pay Now"}
        </Button>
      </div>
    </>
  )
}

export default memo(PayPalCardFields)
