import React from "react";
import CloseModal from "../CloseModal";

interface Props {
  setShowGroupDetails: any;
}

const GroupDetails: React.FC<Props> = ({ setShowGroupDetails }) => {

  return (
   <div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setShowGroupDetails}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Group Services
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
          Group Coaching Service involves working with a group of clients. 
          This features offers multiple group service options, including group programs, 
          webinars, consultation, single session, packages, and recurring programs 
          to cater to your unique needs. You can choose the frequency of sessions 
          and payments depending on your preferences, ranging from single sessions 
          to weekly, bi-weekly, or monthly schedules.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GroupDetails;
