import { useContext, useEffect, useState } from "react"
import Loader from "../ui/loader"
import OverviewPortraitVideoPreview from "./OverviewPortraitVideoPreview"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { ProfileRequests } from "../../api/app.service"
import { SvgPlus } from "../icons/"

interface Props {
  coachData?: any
  isEditable?: boolean
  videoIdToDisplay?: number | null
}

export default function OverviewVideo({
  coachData,
  isEditable = true,
  videoIdToDisplay,
}: Props) {
  const { renderError } = useContext(CommonFunctionCtx)
  const [firstVideo, setFirstVideo] = useState<any | null>(null)
  const [loadingVideos, setLoadingVideos] = useState<boolean>(false)
  const { setEditPortraitVideo } = useContext(CoachPrivateProfileContext)
  const [showAddButton, setShowAddButton] = useState(false)
  const textColor = getLightTextColor(
    coachData?.profile_information?.profileColor
  )

  const getVideos = async () => {
    setLoadingVideos(true)
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachData?.coach_public_profile_id,
    })
      .then((data) => {
        const indexOfSelectedVideo = data.videos.findIndex(
          (video: any) => video.id === videoIdToDisplay
        )
        let firstVideo = data.videos[0]
        if (indexOfSelectedVideo !== -1) {
          firstVideo = data.videos[indexOfSelectedVideo]
        }
        setFirstVideo(firstVideo)
        setLoadingVideos(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingVideos(false)
      })
  }

  useEffect(() => {
    getVideos()
  }, [coachData, videoIdToDisplay])

  return (
    <div
      className="w-full h-full flex items-center justify-center relative"
      onMouseEnter={() => setShowAddButton(true)}
      onMouseLeave={() => setShowAddButton(false)}
    >
      {loadingVideos ? (
        <Loader />
      ) : firstVideo ? (
        <div className="w-full h-full">
          <OverviewPortraitVideoPreview
            video={firstVideo}
            coachPublicProfileId={coachData?.coach_public_profile_id}
          />
        </div>
      ) : isEditable ? (
        <div className="text-graySlate font-bold flex justify-center items-center">
          Add a video to get started
        </div>
      ) : (
        <div
          className="w-full h-full rounded-[20px]"
          style={{ backgroundColor: textColor }}
        ></div>
      )}
      {showAddButton && isEditable && (
        <Button
          onClick={() => {
            setEditPortraitVideo(true)
          }}
          variant={"utility"}
          size={"lg"}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white z-3 border-[1px] shadow-md opacity-100"
        >
          <SvgPlus />
          Add
        </Button>
      )}
    </div>
  )
}
