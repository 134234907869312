import type { SVGProps } from "react";
export const SvgVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m22.508 11.006-1.017-.762a1.26 1.26 0 0 1-.393-1.456l.485-1.179a1.256 1.256 0 0 0-.994-1.71l-1.272-.162a1.23 1.23 0 0 1-1.064-1.063l-.162-1.27a1.257 1.257 0 0 0-1.711-.994l-1.18.485a1.26 1.26 0 0 1-1.457-.393l-.786-1.017a1.238 1.238 0 0 0-1.966 0l-.74 1.04c-.347.44-.948.601-1.457.393l-1.18-.508a1.257 1.257 0 0 0-1.711.993l-.162 1.271c-.07.555-.509.994-1.064 1.064l-1.272.161a1.256 1.256 0 0 0-.994 1.71l.485 1.179c.209.508.047 1.11-.393 1.456l-1.017.786a1.236 1.236 0 0 0 0 1.964l1.04.762c.44.347.602.948.393 1.456l-.508 1.179c-.3.762.185 1.594.994 1.71l1.272.162c.555.069.995.508 1.064 1.063l.162 1.27a1.257 1.257 0 0 0 1.712.994l1.18-.485a1.26 1.26 0 0 1 1.456.393l.786 1.017c.486.647 1.48.647 1.966 0l.787-1.017c.346-.44.948-.6 1.457-.393l1.18.485c.762.3 1.595-.184 1.71-.993l.162-1.271c.07-.555.51-.994 1.064-1.063l1.272-.162a1.256 1.256 0 0 0 .995-1.71l-.486-1.179a1.26 1.26 0 0 1 .393-1.456l1.018-.785c.6-.486.6-1.456-.047-1.965m-6.498-.254-4.187 4.183a1.19 1.19 0 0 1-.855.346c-.324 0-.625-.115-.856-.346l-2.104-2.103a1.2 1.2 0 0 1 0-1.687 1.2 1.2 0 0 1 1.688 0l1.249 1.248 3.376-3.328a1.2 1.2 0 0 1 1.688 0 1.2 1.2 0 0 1 0 1.687"
    />
  </svg>
);
