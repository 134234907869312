import React from "react"
import { Button } from "../ui/button"
import { SubscriptionContext } from "../subscription/SubscriptionContext"
import { SvgCheckCircle, SvgClose } from "../icons"
import { UserCtx } from "../../context/userContext"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useAuthModal } from "../auth/use-auth-modal"

export const planFeatures = [
  "Integrated Scheduling and billing",
  "Custom Coaching Offers",
  "HIPAA Video conferencing",
  "Personalized landing page",
  "Secure client portal",
  "SMS messaging",
  "File sharing",
  "Client testimonials",
  "Digital notetaking system",
  "Network of coaches and community members",
  "Access to Coach Market Listing",
  "Member only events and perks",
  "Priority Zoee team support",
]

type Props = {
  planType: "annual" | "monthly" | "freeTrial"
  handleChoosePlanClick: (planType: string) => void
}

const PricingPlanCard: React.FC<Props> = ({
  planType,
  handleChoosePlanClick,
}) => {
  const { user } = React.useContext(UserCtx)
  const { subscription } = React.useContext(SubscriptionContext)
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false)

  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const { showLogin, showRegister } = useAuthModal()
  const [searchParams, setSearchParams] = useSearchParams()
  const register = searchParams.get("register")
  const coachregister = searchParams.get("coachregister")
  const navigate = useNavigate()

  const getPrice = () => {
    if (planType === "annual") return "$41/mo"
    if (planType === "monthly") return "$49/mo"
    return "$0/mo"
  }

  const getStrikethroughPrice = () => {
    // if (planType === "annual") return "$55.00/mo"
    // if (planType === "monthly") return "$69.00/mo"
    return <span style={{ visibility: "hidden" }}>freeTrial</span>
  }

  const getBillingCycleText = () => {
    if (planType === "annual") return "Billed annually at $490"
    if (planType === "monthly")
      return <span style={{ visibility: "hidden" }}>Monthly</span>
    return <span style={{ visibility: "hidden" }}>freeTrial</span>
  }

  const determineButtonVariant = () => {
    if (user && !subscription && planType === "freeTrial") return "disable"
    if (subscription?.recurring_interval === planType) return "disable"
    if (planType === "freeTrial") return "outline"
    return "default"
  }

  const determineButtonText = () => {
    if (!user) return "Get Started"
    if (user && !subscription && planType === "freeTrial") return "Current Plan"
    if (user && subscription?.recurring_interval === planType)
      return "Current Plan"
    return "Get Started"
  }

  const handleButtonClick = () => {
    setShowMobileNav(false)
    if (user) {
      navigate("/coach/subscription/plans")
    } else {
      showRegister({
        accountType: "coach",
        onSuccess: () => {
          navigate("/coach/subscription/plans")
        },
      })
    }
  }

  const openCreateAccountModal = () => {
    setModalOpen(true)
    showRegister()

    searchParams.set("register", "true")
    setSearchParams(searchParams)
  }

  React.useEffect(() => {
    if (!modalOpen) {
      searchParams.delete("register")
      setSearchParams(searchParams)
    }
  }, [modalOpen])

  React.useEffect(() => {
    if (coachregister === "true") {
      searchParams.set("register", "coach")
      searchParams.delete("coachregister")
    }

    if (register === "true") {
      openCreateAccountModal()
    } else if (register === "coach") {
      setShowMobileNav(false)
      showRegister({ accountType: "coach" })
    }
  }, [register, coachregister])

  return (
    <div className="sm:w-[406px] h-full flex flex-col rounded-[32px] main-shadow">
      <div
        className={`w-full h-[35px] ${
          planType === "annual"
            ? "bg-blurple"
            : planType === "monthly" || planType === "freeTrial"
            ? "bg-black"
            : "bg-gray-400"
        } rounded-t-[32px] flex items-center justify-center`}
      ></div>
      <div className="pb-[32px] px-8 py-10 pt-[24px]">
        <div>
          <h2 className="text-black text-2xl font-semibold font-['Beausite Classic']">
            {planType === "annual"
              ? "Annual Pro"
              : planType === "monthly"
              ? "Monthly Pro"
              : "Free Trial"}
          </h2>
          <p className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            {planType === "freeTrial"
              ? "For coaches who want to try out Zoee"
              : "Zoee: One login, infinite possibilities"}
          </p>
          <div className="flex font-bold items-start gap-[4px] leading-none">
            <h4 className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
              {getPrice()}
            </h4>
          </div>
          <div className="mb-0">
            <p className="text-[18px] text-graySlate line-through">
              {getStrikethroughPrice()}
            </p>
            <p className="text-[18px] text-graySlate">
              {getBillingCycleText()}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-[12px]">
          <Button
            size="lg"
            variant={determineButtonVariant()}
            className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
            onClick={handleButtonClick}
            disabled={determineButtonVariant() === "disable"}
          >
            {determineButtonText()}
          </Button>
          {planFeatures.map((feature, index) => (
            <div
              className="flex items-center gap-[16px]"
              key={`${index}${feature}`}
            >
              <div className="flex-shrink-0">
                {planType === "freeTrial" && index > 8 ? (
                  <SvgClose stroke="red" />
                ) : (
                  <SvgCheckCircle stroke="#4750F5" />
                )}
              </div>
              <span className="text-large">{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PricingPlanCard
