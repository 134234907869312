import type { SVGProps } from "react";
export const SvgNotificationCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#FFF8AF"
    />
    <path
      d="M18.4843 20.0493C18.7931 20.7529 18.4736 21.5743 17.7692 21.8823C17.0656 22.1911 16.245 21.8712 15.9366 21.1668C15.8536 20.9791 15.8199 20.7824 15.8224 20.5893L18.1368 19.5745C18.2802 19.7027 18.4014 19.8608 18.4843 20.0493H18.4843Z"
      fill="black"
    />
    <path
      d="M11.3382 16.3719C10.6852 14.729 11.0175 13.1629 11.9937 12.1387C11.9535 12.0771 11.9128 12.0196 11.8824 11.9489C11.5735 11.2457 11.8934 10.426 12.5971 10.1175C13.3007 9.80865 14.1213 10.1298 14.43 10.8326C14.4592 10.8991 14.4752 10.9681 14.4925 11.0367C15.955 11.0072 17.3987 11.8976 18.1532 13.4124C18.1532 13.4124 19.2708 15.9782 20.498 16.1824C20.5358 16.1845 21.1942 16.2009 21.395 16.6806C21.5844 17.1344 21.1946 17.7418 20.5165 18.0338L13.1735 21.1983C12.4962 21.4903 11.7479 21.3732 11.5876 20.9087C11.4295 20.4508 11.7766 20.0249 11.8095 19.9871C12.5023 18.9225 11.3384 16.372 11.3384 16.372L11.3382 16.3719Z"
      fill="black"
    />
  </svg>
);
