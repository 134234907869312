import React from "react"
import { SvgVerified } from "../icons"
import ProductImage from "./iStock-1484110897 1.png"

const IINThriving: React.FC = () => {
  return (
    <div className="py-[56px] md:py-[96px] flex flex-col items-center justify-center overflow-hidden md:px-[144px] px-4 sm:px-8">
      <div className="mb-10 w-full">
        <h2 className="text-black text-[48px] font-bold leading-tight mb-0 text-left">
          Learn How to Build a Thriving Practice
        </h2>
        <p className="text-black text-[22px] font-normal text-left">
          In just three months, you'll walk away with a fully operational
          coaching practice, plus learn how to...
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-start gap-12">
        <div className="flex flex-col gap-6 flex-1">
          {[
            {
              title: "ADOPT AN ENTREPRENEURIAL MINDSET:",
              description:
                "Learn how to shift your vision, habits, and mindset to support your success.",
            },
            {
              title: "DESIGN AN AUTHENTIC BRAND:",
              description:
                "Clarify your unique value and ideal client to build an aligned brand identity that's an extension of you.",
            },
            {
              title: "CRAFT YOUR SIGNATURE COACHING PROGRAM:",
              description:
                "Structure your services with a high-value, adaptable offer that meets the needs of your ideal client and generates consistent revenue.",
            },
            {
              title: "FINETUNE YOUR OPERATIONS:",
              description:
                "Implement proven systems for upleveling key business activities like enrollment flow, content creation, and practice management.",
            },
            {
              title: "DEVELOP YOUR PRICING STRATEGY:",
              description:
                "Enhance the value of your coaching, calculate your pricing strategy, and break through limiting financial beliefs to confidently set rates that allow you to thrive.",
            },
            {
              title: "BUILD YOUR BUSINESS BLUEPRINT:",
              description:
                "Experience continued growth and success long after the program ends by establishing the core components of your business and processes you can follow for years to come.",
            },
          ].map((item, index) => (
            <div key={index} className="flex items-start gap-3">
              <SvgVerified className="w-5 h-5 text-[#4750f5] flex-shrink-0 mt-1" />
              <div>
                <p className="text-black text-[16px] font-bold inline">
                  {item.title}
                </p>{" "}
                <span className="text-black text-[16px] font-normal">
                  {item.description}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="relative flex-shrink-0">
          <div
            className="absolute bg-[#FFD700]"
            style={{
              width: "470px",
              height: "518px",
              top: "0px",
              left: "100%",
              bottom: "20px",
            }}
          ></div>
          <div
            className="absolute bg-[#FFD700]"
            style={{
              width: "470px",
              height: "470px",
              top: "48px",
              left: "0px",
            }}
          ></div>

          {/* Image */}
          <div className="relative z-10 overflow-hidden">
            <img
              src={ProductImage}
              alt="Coaching session visual"
              className="w-[450px] h-[450px] object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default IINThriving
