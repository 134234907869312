import type { SVGProps } from "react";
export const SvgTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 126 126"
    {...props}
  >
    <path fill="currentColor" d="M8.2 2C4.9 3.5 3.5 4.9 2 8.2c-1.9 4.1-2 6.9-2 54.7 0 48.9.1 50.6 2.1 54.7 1.5 3.1 3.2 4.8 6.3 6.4 4.3 2 5.4 2 55.9 1.8 50.3-.3 51.6-.4 54.3-2.4 1.5-1.1 3.7-3.3 4.8-4.8 2-2.7 2.1-4 2.4-54.3.2-50.5.2-51.6-1.8-55.9-1.6-3.1-3.3-4.8-6.4-6.3-4.1-2-5.8-2.1-54.7-2.1C15.1 0 12.3.1 8.2 2m70.4 20.7c3 9.4 7.3 14.1 15.4 17l5 1.8V55h-3.4c-4.4 0-9.7-2.3-14.1-6.1L78 45.8v19.3c-.1 20.9-.7 24.2-5.4 30.4-5.7 7.4-17.5 11.2-27.1 8.6-5.7-1.5-12.1-6.5-15.3-11.9-2.3-4.1-2.7-5.8-2.7-12.7 0-9 1.3-12.2 7-18C39.1 57 43 55 49.1 54.3l4.9-.6V67h-3c-6.1 0-11 5.6-11 12.5C40 87.3 45.1 92 53.5 92c3.8 0 5.2-.6 8.3-3.4l3.7-3.4.3-32.1.3-32.1h6c4.8 0 6.1.3 6.5 1.7" />
  </svg>
);
