import { type FC } from "react";
import styles from "./loader.module.css"

const Loader: FC = () => {
  return (
      <div className={styles.loaderWrapper}>
        <div className={styles.loadingIndicator}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
  );
};

export default Loader;
