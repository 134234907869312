import type { SVGProps } from "react";
export const SvgPolygon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="3"
    viewBox="0 0 9 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.5 0H8.5L4.5 3L0.5 0Z"
      fill="#0693E3"
    />
  </svg>
);
