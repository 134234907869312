import previewImage from "../../../assets/blog/coachesCornerJoanneWhiteside.webp";
import { Post } from "../../../types/postTypes";

export const coachesCornerJoanneWhiteside: Post = {
    metadata: {
        id: 8,
        title: "From RN, to Coach",
        subtitle: "Coach's Corner: An Interview with Joanne Whiteside",
        imgTag: "Coach's Corner",
        author: "Zoee",
        date: "November 16, 2022",
        urlSlug: "coaches-corner-joanne-whiteside",
        description:
            "When it comes to health and wellness in the United States, we often want the path of least resistance. But for registered nurse and health coach Joanne Whiteside, her approach to health and wellness is to play the long game.",
        previewImg: previewImage,
        tags: ["Coach's Corner", "Health Coaching"],
    },
    content: [
        {
            type: "paragraph",
            text: "When it comes to health and wellness in the United States, we're sold many things: pills that burn fat, diet programs with prepared meals, and books that end up harming us more than helping us. We live in a culture based on instant gratification and quick fixes. We want the path of least resistance, always. But for registered nurse and health coach Joanne Whiteside, her approach to health and wellness is to play the long game.",
        },
        {
            type: "paragraph",
            text: "After a 28-year career as a nurse—working in the OB ICU, emergency room, and on the frontlines of COVID-19 for the first two years of the pandemic—Joanne hung up her scrubs and stepped away from hospital beds. However, it wasn’t because she had too much time treating people. Instead, she stepped away from nursing because she didn’t have enough time with people.",
        },
        {
            type: "paragraph",
            text: "By the end of her tenure in hospitals, she ached for more time with patients. She wanted hours to learn more about their health history and discuss disease prevention in their life moving forward. Her role as a nurse was a busy one and didn’t leave much time for deeper conversation.",
        },
        {
            type: "paragraph",
            text: "This craving for more time with patients blossomed into a coaching business, serving as a foundation for understanding people’s health needs and guiding them on a holistic wellness journey. When she transitioned from full-time nurse to full-time coach, this quest for more time with patients was achieved, and luckily, she gets much more than 60 minutes with them.",
        },
        {
            type: "paragraph",
            text: "“Health is so much more than diet and exercise, and I saw that firsthand,” Joanne told Zoee about her experience as a new coach when we interviewed her. “I found myself saying, if I could just get an hour with this patient to show them there’s another way, they could be leading their lives. If I could just get some time to tell them there’s a way to prevent illness and disease, I know I could help them.”",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "Overall, when we think about treatments for illness, they’re just that—",
                },
                {
                    type: "italic",
                    text: "treatments",
                },
                {
                    type: "normal",
                    text: "for symptoms. But what about getting to the root cause or, better yet, preventing an issue so that no treatment is required at all? What about preventing common illnesses like diabetes, heart disease, high blood pressure, and mental health issues? What about focusing on the health and well-being of our whole selves, not just the version that needs fixing today?",
                },
            ],
        },
        {
            type: "header1",
            text: "Prevention is Possible, But You Have to Want It",
        },
        {
            type: "paragraph",
            text: "“Health promotion and disease prevention are the foundations of nursing,” Joanne says. Although we often think of nurses providing care when we’re sick, they’re here to help us prevent getting sick.",
        },
        {
            type: "paragraph",
            text: "For many, this is a shift in mindset, requiring us to think (and act) outside of what’s easy. Health is not merely the absence of disease. Instead, it’s a practice of well-being focused on the physical, mental, social, and financial health of ourselves as human beings. “We have to consider people as whole human beings because coaches aren’t just treating the physical body, we’re treating the human spirit as well,” Joanne says.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "This sounds really powerful, and for Joanne, it is. Humans are complex, and working with a coach means working with someone who understands our multi-faceted lives. But to cultivate true wellness for our whole selves, we’ve got to want it—",
                },
                {
                    type: "italic",
                    text: "really",
                },
                {
                    type: "normal",
                    text: "want it..",
                },
            ],
        },
        {
            type: "paragraph",
            text: "“This is a huge hurdle, convincing people that they are capable of health,” Joanne says. “There’s a difference between knowing you want to be healthy and actually being healthy.” She works with people to uncover their intrinsic motivation—a powerful discovery when setting out to make a change.",
        },
        {
            type: "paragraph",
            text: "Intrinsic motivation is what we’re motivated by on the inside. This could be anything from wanting to be healthy for your children to losing weight for swimsuit season. It doesn’t matter how selfish it seems so long as it truly motivates you. Once that core motivation is established, Joanne’s role is to help people set up and maintain a healthy lifestyle, making adjustments along the way.",
        },
        {
            type: "paragraph",
            text: "“It’s very difficult for some people to even realize what their intrinsic motivation is,” Joanne says. “We are often influenced by outside sources making us think we ‘should’ do something. But if they don’t find it within themselves or seek help in finding their intrinsic motivation, lifestyle changes don’t usually work.”",
        },
        {
            type: "paragraph",
            text: "Even if you really do want to change your behavior to support a healthier lifestyle, the catalyst for change is different for everybody. Joanne takes the approach of “Let’s start where you’re at because it’s different for everybody.” We’ve all had different upbringings, life experiences, and backstories, all of which contribute to our relationship with health and wellness. Whether that’s your relationship with food, money, a romantic partner, or something else entirely, our life experiences shape the people we are today and, more importantly, the choices we continue to make. Some people are ready to make different choices. Others are not. That isn’t something a coach can help you with, but rather, something you have to decide for yourself.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "This sounds really powerful, and for Joanne, it is. Humans are complex, and working with a coach means working with someone who understands our multi-faceted lives. But to cultivate true wellness for our whole selves, we’ve got to want it—",
                },
                {
                    type: "italic",
                    text: "really",
                },
                {
                    type: "normal",
                    text: "want it..",
                },
            ],
        },
        {
            type: "paragraph",
            text: "“It’s very difficult for some people to even realize what their intrinsic motivation is,” Joanne says. “We are often influenced by outside sources making us think we ‘should’ do something. But if they don’t find it within themselves or seek help in finding their intrinsic motivation, lifestyle changes don’t usually work.”",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "Coaches",
                },
                {
                    type: "italic",
                    text: "will",
                },
                {
                    type: "normal",
                    text: "help you transform your life, from how you view yourself in the world to your daily habits. This journey takes a tremendous amount of vulnerability, but once you decide you want to make a change and establish trust with a coach, opening up to self-discovery is the linchpin for a whole new life.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "“We can’t change what was done in the past,” Joanne says. “We can only grow into the future. But the human body has an amazing ability to heal, given the right conditions.” This tells us that there’s hope, even if you’re disappointed in your past decisions.",
        },
        {
            type: "header1",
            text: "She’s Been There",
        },
        {
            type: "paragraph",
            text: "One of the most welcoming parts about Joanne is that she’s lived a full life, and she wants her clients to know that about her. “I learned early on in my coaching practice that it’s important for people to feel a connection and to get to know me before they open up about themselves,” Joanne adds. This establishes trust and allows clients to relate to her human-to-human.",
        },
        {
            type: "paragraph",
            text: "Her experiences are big, bold, and diverse. Joanne described herself as middle-aged with three young kids. “I work outside the home, have changed jobs, and advanced my education,” Joanne says. “I exercise regularly, eat plant-based, and nourish social relationships.” She makes it clear that all the advice she gives in her coaching sessions is tried and tested by her. “Sometimes, clients have a hard time relating, as if the coach doesn’t know what it’s like to transition a family of five over to a plant-based diet. I’ve been there, and I know how hard it is.”",
        },
        {
            type: "paragraph",
            text: "While Joanne is a busy working mom, she’s also lived through a health crisis. Her heart condition has required invasive surgery. She’s also undergone fertility treatments but has successfully given birth to three babies. In sum, it adds credibility to her resume. “I lived through those things because I am a nurse and knew better. I knew how to support myself, and I want to educate others so they can know better too.”",
        },
        {
            type: "header1",
            text: "Let’s Do Better, Together",
        },
        {
            type: "paragraph",
            text: "That’s what coaching is all about, creating a better tomorrow. It’s forward motion, growth, and evolution. It’s moving away from the idea that people are sick and broken and toward the idea that they’re powerful, strong, and resilient. So, how do we create that positive thinking alongside working with a coach like Joanne?",
        },
        {
            type: "paragraph",
            text: "It’s important to know where your influences come from and whether or not you’re letting yourself be influenced. Considering whom you follow on social media, whom you listen to on a podcast, or whom you speak to on the telephone can help uncover negative influences. What are you accepting without pause, and what could you adjust to foster well-being?",
        },
        {
            type: "paragraph",
            text: "“I am the only person responsible for myself, regardless of what’s going on around me in society, in my household, or within my circle of friends,” Joanne says. This is a choice that many people forget. Choosing to be influenced by passively receiving instead of taking active personal responsibility takes effort.",
        },
        {
            type: "paragraph",
            text: "A coach will step in and ask, “What are your values? What are your thought processes? How do you see yourself in the world? What are your resources?” The long and rewarding journey of working with a coach will uncover what experiences have led you to this point in your life, the change(s) you want to make moving forward, and how to make change happen in a way that’s healthy and sustainable.",
        },
        {
            type: "paragraph",
            text: "It takes a lot of support to think about every habit and decision you make. It takes even more support to approach those choices differently. While living on autopilot is easy, the deliberate choices of health are what build a better tomorrow. The education and support from a coach like Joanne will support a client in knowing better, therefore making different choices.",
        },
        {
            type: "paragraph",
            text: "There are daily choices we can make. We can choose to meditate, which for Joanne is the “practice of making your thoughts healthy.” We can choose to eat whole foods, fruits, and vegetables, drink water, and nourish our bodies by eating a rainbow of nutrients. We can choose to engage in physical exercise, deepen our social relationships, find financial freedom, and speak to ourselves with kindness. We have choices, and we create our own realities through our mindsets.",
        },
        {
            type: "paragraph",
            text: "“When our thoughts are in alignment with how we want to live our lives, a healthier state of being is created,” Joanne says. There’s no one singular way of achieving a healthy lifestyle centered around proactive choices, but the important thing is that you find your way. Usually, that’s with a coach by your side.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Joanne Whiteside is a registered nurse and health coach. She received a certification in plant-based nutrition from Cornell University.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Ready to simplify your back-office? Join Zoee today. Interested in finding a coach? Sign up for the member waitlist here.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This interview was conducted and written by Valerie Lane.",
                },
            ],
        },
    ],
};
