import type { SVGProps } from "react";
export const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="10 11 20 22"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 24.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5m-14.25 2h11.604a2.751 2.751 0 0 0 5.294-.007 1 1 0 0 0 .102.007h2a.75.75 0 0 0 0-1.5h-2a1 1 0 0 0-.102.007 2.751 2.751 0 0 0-5.294-.007H10.75a.75.75 0 0 0 0 1.5M15.5 16.75a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5m14.25 2H18.147a2.751 2.751 0 0 1-5.295-.007 1 1 0 0 1-.102.007h-2a.75.75 0 0 1 0-1.5h2q.051 0 .102.007a2.751 2.751 0 0 1 5.294-.007H29.75a.75.75 0 0 1 0 1.5"
      clipRule="evenodd"
    />
  </svg>
);
