import * as React from "react";
import { Link } from "react-router-dom";
import { SvgArrowLeft, SvgCalendar, SvgUser3, SvgCheckCircleSolid, SvgVideoOutline, SvgWebsite } from "../../icons/";
import ContactAvatar from "../../contacts/ContactAvatar";
import { Button } from "../../ui/button";

interface Props {
  setShowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  consultation: any;
  startTime: Date;
  endTime: Date;
}

const SuccessModal: React.FC<Props> = ({
  setShowConfirm,
  consultation,
  startTime,
  endTime,
}) => {
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(endTime);
  const formattedDayOfWeek = startTime.toLocaleDateString(undefined, { weekday: "long" });
  const formattedMonth = startTime.toLocaleDateString(undefined, { month: "long" });
  const formattedDay = startTime.toLocaleDateString(undefined, { day: "2-digit" });
  const formattedYear = startTime.toLocaleDateString(undefined, { year: "numeric" });
  const coachPublicProfileData = consultation.coachPublicProfileData;

  const getUserTimezone = () => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const longTimezoneName = new Date().toLocaleTimeString("en", {
      timeZoneName: "long",
    });
    const extractedTimezone = longTimezoneName.split(" ")[2];
    const timezoneWithTime = `${extractedTimezone} Time`;
    let timezoneDisplay = timezoneWithTime.replace("_", " ");
    timezoneDisplay = timezoneDisplay.replace("/", " & ");
    timezoneDisplay = `${timezoneDisplay} - ${timezone}`;
    return timezoneDisplay;
  };
  const userTimezone = getUserTimezone();

  function formatTime(date: Date): string {
    return date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
  }

  return (
    <div className="fixed inset-0 z-50 bg-black/80 backdrop-blur-sm">
      <div className="bg-background fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-6 md:p-12 max-w-xl w-full rounded-xl border shadow-lg grid gap-4">
        <div className="flex flex-col items-center">
          <div className="mb-8">
            <ContactAvatar
              contact={{
                contact_name: `${coachPublicProfileData.first_name} ${coachPublicProfileData.last_name}`,
                has_avatar: coachPublicProfileData.has_avatar,
                user_color: coachPublicProfileData.profile_information.profileColor,
                user_id: coachPublicProfileData.user_id,
              }}
              width={50}
              height={50}
              border=""
            />
          </div>
          <div className="flex items-center justify-center">
            <SvgCheckCircleSolid />
            <span className="ml-1">You are scheduled!</span>
          </div>
        </div>
        <div className="text-center">
          <p className="text-muted-foreground">
            A calendar invitation has been sent to your email address.
          </p>
        </div>
        <div className="border-2 border-gray p-4 rounded-lg">
          <div className="text-muted-foreground grid grid-cols-[auto_1fr] gap-3 my-3">
            <SvgUser3 />
            <p>
              {`${coachPublicProfileData.first_name} ${coachPublicProfileData.last_name}, `}
              {coachPublicProfileData.profile_information.title}
            </p>
            <SvgCalendar />
            <p>
              {formattedStartTime} - {formattedEndTime},{" "}
              {formattedDayOfWeek}, {formattedMonth} {formattedDay},{" "}
              {formattedYear}
            </p>
            <SvgWebsite />
            <p>{userTimezone}</p>
            <SvgVideoOutline />
            <p className="capitalize">Web conferencing details to follow.</p>
          </div>
        </div>
        <div className="items-center grid justify-center gap-6 mx-auto">
          <Button size="lg">
            <Link to="/member/scheduling">View My Schedule</Link>
          </Button>
          <Button onClick={() => setShowConfirm(false)} variant="ghost" size="lg">
            <SvgArrowLeft /> Return to {`${coachPublicProfileData.first_name}'s`} Profile
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
