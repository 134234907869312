import React from "react";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";

const PrivateRouteUser = ({ authPath = "/home" }) => {
    const { user } = React.useContext(UserCtx);
    const authenticatedUser = useAuthenticatedUser(user)
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const keys = Array.from(searchParams.keys());
    const params: any = {};
    keys.forEach((key) => {
        params[key] = searchParams.get(key);
    });
    let route;
    if (authenticatedUser) {
        route = <Outlet />;
    } else {
        route = <Navigate
            to="/direct-routes"
            state={{ storageAvailable: true, pathname: location.pathname, searchParams: params }}
        />;
    }
    return route;
};

export default PrivateRouteUser;
