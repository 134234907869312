import React, { ChangeEvent, FC, FormEvent, useContext, useState } from "react";
import * as z from "zod";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import Loader from "../../ui/loader";
import { RegisterFormContext } from "./context";

export const validateFirstAndLastName = (name: string) => {
  const schema = z.string().refine((data) => {
    const nameFormat = /^[a-zA-Z\s]*$/;
    return nameFormat.test(data.trim());
  });

  return schema.safeParse(name);
};

export const NameForm: FC = () => {
  const {
    setFirstName,
    setLastName,
    setView,
    accountType,
    createAccount,
    firstName,
    lastName,
  } = useContext(RegisterFormContext);
  const [loading, setLoading] = useState(false);
  const [firstNameError, setFirstNameError] = useState<string | undefined>(
    undefined
  );
  const [lastNameError, setLastNameError] = useState<string | undefined>(
    undefined
  );

  const lengthError = !firstName.length || !lastName.length;
  const inputError =
    firstNameError !== undefined || lastNameError !== undefined;
  const isDisabled = lengthError || inputError;

  const chandleChange = (
    e: ChangeEvent<HTMLInputElement>,
    inputName: string
  ) => {
    const inputValue = e.target.value;
    const inputSuccess = validateFirstAndLastName(inputValue).success;
    const errorMsg = "Invalid Input";

    if (inputName === "first") {
      setFirstNameError(undefined);
      setFirstName(inputValue);
      if (!inputSuccess) {
        setFirstNameError(errorMsg);
      }
    }

    if (inputName === "last") {
      setLastNameError(undefined);
      setLastName(inputValue);
      if (!inputSuccess) {
        setLastNameError(errorMsg);
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (isDisabled) {
      setLoading(false);
      return;
    }

    if (accountType === "coach") {
      setView("password");
    } else {
      await createAccount();
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
    >
      <Input
        label="First Name"
        placeholder="Enter your first name"
        value={firstName}
        onChange={(e) => chandleChange(e, "first")}
        error={firstNameError}
        disabled={loading}
        autoFocus
      />

      <Input
        label="Last Name"
        placeholder="Enter your last name"
        value={lastName}
        disabled={loading}
        onChange={(e) => chandleChange(e, "last")}
        error={lastNameError}
      />
      <Button
        className="w-full"
        disabled={isDisabled}
      >
        {loading ? (
          <Loader />
        ) : accountType === "coach" ? (
          "Continue"
        ) : (
          "Create Account"
        )}
      </Button>
    </form>
  );
};
