import React, { type FC, type ReactNode } from "react";

export interface NotificationContext {
  setNotificationAlert: React.Dispatch<
    React.SetStateAction<
      {
        message: string;
        show: boolean;
        link: string;
        profile_type: "member" | "coach" | null;
      }
    >
  >;
  notificationAlert: {
    message: string;
    show: boolean;
    link: string;
    profile_type: "member" | "coach" | null;
  };
  hideNotificationAlert: () => void;
  lastWebsocketMessage: any;
  setLastWebsocketMessage: any;
  contactsNotifications: any;
  setContactsNotifications: any;
  inboxNotifications: any;
  setInboxNotifications: any;
}

export const NotificationCtx = React.createContext<NotificationContext>({
  setNotificationAlert: () => {},
  notificationAlert: { message: "", show: false, link: "", profile_type: null },
  hideNotificationAlert: () => {},
  lastWebsocketMessage: {},
  setLastWebsocketMessage: {},
  contactsNotifications: {},
  setContactsNotifications: {},
  inboxNotifications: {},
  setInboxNotifications: {},
});

interface Props {
  children: ReactNode;
}

export const NotificationCtxProvider: FC<Props> = ({ children }) => {
  const [notificationAlert, setNotificationAlert] = React.useState<
    {
      message: string;
      show: boolean;
      link: string;
      profile_type: "coach" | "member" | null;
    }
  >({
    message: "",
    show: false,
    link: "",
    profile_type: null,
  });

  const hideNotificationAlert = () => {
    setNotificationAlert({
      message: "",
      show: false,
      link: "",
      profile_type: null,
    });
  };

  const [lastWebsocketMessage, setLastWebsocketMessage] = React.useState<any>(
    null,
  );

  const [contactsNotifications, setContactsNotifications] = React.useState([]);

  const [inboxNotifications, setInboxNotifications] = React.useState([]);

  return (
    <NotificationCtx.Provider
      value={{
        setNotificationAlert,
        hideNotificationAlert,
        notificationAlert,
        lastWebsocketMessage,
        setLastWebsocketMessage,
        contactsNotifications,
        setContactsNotifications,
        inboxNotifications,
        setInboxNotifications,
      }}
    >
      {children}
    </NotificationCtx.Provider>
  );
};
