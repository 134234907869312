import React from "react"
import { cn } from "../../../utils/cn"
import DragDropArea from "../../DragDropArea"
import { SvgTrash, SvgUploadImage } from "../../icons"
import { Button } from "../../ui/button"

interface Props {
  serviceValues: any
  setServiceValues: any
  previewImgUrl: any
  setPreviewImgUrl: any
  awaitNewImage: boolean
  setAwaitNewImage: any
  serviceImgFile: any
  setServiceImgFile: any
}

const ServiceColorImageForm: React.FC<Props> = ({
  serviceValues,
  setServiceValues,
  previewImgUrl,
  setPreviewImgUrl,
  awaitNewImage,
  serviceImgFile,
  setServiceImgFile,
  setAwaitNewImage,
}) => {
  const colors = [
    "bg-blurple",
    "bg-oceanBlue",
    "bg-grassGreen",
    "bg-fallOrange",
    "bg-darkPurple",
    "bg-charcoalBlack",
  ]

  return (
    <>
      <div className="md:w-[620px] main-shadow flex flex-col rounded-xl bg-white p-[32px] md:p-[40px] gap-4">
        <h2 className="font-bold text-[22px]">Choose Header</h2>
        {previewImgUrl ? (
          <>
            {awaitNewImage ? (
              <>
                <DragDropArea
                  styling="aspect-[3/1] rounded-xl"
                  mediaFile={serviceImgFile}
                  setMediaFile={setServiceImgFile}
                  mediaType="image"
                />
                <ul className="mx-auto text-muted-foreground text-base flex flex-col list-disc">
                  <li>Images should be of file type .jpg, .jpeg, or .png</li>
                  <li>Images should be less than 5 megabytes</li>
                </ul>
                <Button
                  variant="secondary"
                  onClick={() => setAwaitNewImage(false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <img
                  src={`${previewImgUrl}`}
                  alt="preview"
                  className="aspect-[3/1] rounded-xl object-cover"
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                  <Button
                    variant="destructive"
                    onClick={() => {
                      setServiceImgFile(undefined)
                      setPreviewImgUrl("")
                      setServiceValues({
                        ...serviceValues,
                        image: {
                          exists: false,
                          base64: "",
                          base64extension: "",
                        },
                      })
                    }}
                  >
                    Delete Image
                    <SvgTrash />
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setAwaitNewImage(true)
                    }}
                  >
                    New Image
                    <SvgUploadImage />
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <DragDropArea
              styling={`w-full aspect-[3/1] rounded-xl`}
              mediaFile={serviceImgFile}
              setMediaFile={setServiceImgFile}
              mediaType="image"
            />
            <ul className="mx-auto text-muted-foreground text-base flex flex-col list-disc">
              <li>Images should be of file type .jpg, .jpeg, or .png</li>
              <li>Images should be less than 5 megabytes</li>
            </ul>
            <div
              className={cn("aspect-[3/1] rounded-xl", serviceValues.color)}
            ></div>
            <p className="text-center text-muted-foreground text-base">
              or choose your color
            </p>
            <div className="flex items-center justify-center gap-2">
              {colors.map((color: string) => (
                <button
                  className={cn(
                    "flex justify-center items-center rounded-full w-6 h-6",
                    color,
                    {
                      "border-px border-solid border-white outline-muted-foreground":
                        color === serviceValues.color,
                    }
                  )}
                  onClick={() =>
                    setServiceValues({ ...serviceValues, color: color })
                  }
                  key={color}
                ></button>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ServiceColorImageForm
