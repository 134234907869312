import React from "react";
import useDragAndDrop from "../hooks/useDragDrop";
import { CommonFunctionCtx } from "../context/commonFunctionContext";

interface Props {
    styling: string;
    setCsvEmails: React.Dispatch<React.SetStateAction<string[] | undefined>>;
    csvEmails: string[] | undefined;
}

const DragDropCsvArea: React.FC<Props> = ({ styling, setCsvEmails, csvEmails }) => {
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();
    const [csvFile, setCsvFile] = React.useState<File | null>(null);

    const onDropCsv = (e: React.DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        setDragOver(false);
        const selectedFile = e?.dataTransfer?.files[0];

        if (selectedFile.type.split("/")[1] !== "csv") {
            return renderError("Please provide a .csv file to upload!");
        }
        setCsvFile(selectedFile);
        parseCsv(selectedFile);
    };

    const fileSelectCsv = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let selectedFile = e?.target?.files[0];

            if (selectedFile.type.split("/")[1] !== "csv") {
                return renderError("Please provide a .csv file to upload!");
            }
            setCsvFile(selectedFile);
            parseCsv(selectedFile);
        }
    };

    const parseCsv = (file: File) => {
        const reader = new FileReader();

        // read csv file and filter all email addresses into an array
        reader.onload = function (e: any) {
            e.preventDefault();
            const csvString = e.target.result;
            const csvArr = csvString.split(/,|\r\n/)
            const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
            const emails = csvArr.filter((value: string) => {
                return value.match(mailformat);
            });
            setCsvEmails(emails);
        };

        reader.readAsText(file);
    };

    return (
        <>
            <form>
                <label
                    htmlFor="csvFile"
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDropCsv}
                    className={`bg-cyanBlue/10 flex justify-center items-center 
                    text-base font-bold border border-dashed ${styling} 
                    ${
                      dragOver
                        ? "border-2 border-vividCyanGreen text-HighlightGreen1"
                        : "border-1 border-cyanBlue text-blurple"
                    }`}
                >
                    {csvFile && (
                        <div className="flex flex-col gap-[8px] items-center">
                            {csvEmails && csvEmails.length > 0 ? (
                                <div className="flex flex-col gap-[4px]">
                                    <p className="text-center">
                                        Found {csvEmails.length} email{" "}
                                        {csvEmails.length === 1 ? "address" : "addresses"} in
                                    </p>
                                    <p className="text-center">
                                        {`${csvFile?.name.substring(0, 14)}${
                                            csvFile?.name.length > 14 ? "..." : ""
                                        }`}
                                    </p>
                                </div>
                            ) : (
                                <div className="flex flex-col gap-[4px]">
                                    <p className="text-center">
                                        Found no email addresses in{" "}
                                        {`${csvFile?.name.substring(0, 14)}${
                                            csvFile?.name.length > 14 ? "..." : ""
                                        }`}
                                    </p>
                                    <p>Please upload a .csv file with valid email addresses.</p>
                                </div>
                            )}
                        </div>
                    )}
                    {!csvFile && (
                        <div className="flex flex-col gap-[8px] items-center px-[10px]">
                            <p className="text-center ">
                                {dragOver ? (
                                    <span className="text-center">Drop here!</span>
                                ) : (
                                    <span className="text-center">
                                        Drop or select a .csv file that contains{" "}
                                        <br className="hidden sm:block" /> the email addresses of
                                        contacts to add.
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </label>
                <input
                    type="file"
                    name="csvFile"
                    id="csvFile"
                    className="hidden"
                    onChange={fileSelectCsv}
                />
            </form>
        </>
    );
};

export default DragDropCsvArea;
