import { createContext, type FC, type ReactNode, useState } from "react";
import LoadingPage from "../components/LoadingPage";

interface LoadingContextType {
  loading: boolean;
  loadingMessage: string | null;
  startLoading: (message?: string) => void;
  stopLoading: () => void;
}

export const LoadingContext = createContext<LoadingContextType>(
  {} as LoadingContextType,
);

interface Props {
  children: ReactNode;
}

export const LoadingProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);

  const startLoading = (message?: string) => {
    setLoading(true);
    setLoadingMessage(message || null);
  };

  const stopLoading = () => {
    setLoading(false);
    setLoadingMessage(null);
  };

  return (
    <LoadingContext.Provider
      value={{
        loading,
        loadingMessage,
        startLoading,
        stopLoading,
      }}
    >
      {loading && <LoadingPage message={loadingMessage} />}
      {children}
    </LoadingContext.Provider>
  );
};
