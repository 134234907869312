import { add, format } from "date-fns"
import moment from "moment"
import React, { FC, Fragment, useContext, useEffect, useState } from "react"
// import TimezoneSelect from "react-timezone-select";
import Select from "react-select"
import TimeOptions from "./TimeOptions"
import { CalendarRequests } from "../../../../api/app.service"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import { UserCtx } from "../../../../context/userContext"
import useScrollToTop from "../../../../hooks/useScrollToTop"
import { GetAvailability } from "../../../../models/app.interface"
import { defaultAvailabilityOption } from "../../../../objects/availabilityObjects"
import { durations } from "../../../../objects/timeObjects"
import { AvailabilityRulesData } from "../../../../types/availabilityTypes"
import { Meeting } from "../../../../types/meetingTypes"
import CustomDayPicker from "../../../date-time/CustomDayPicker"
import { SvgInformationCircle } from "../../../icons"
import { Button } from "../../../ui/button"
import { Label } from "../../../ui/label"
import Loader from "../../../ui/loader"
import { Switch } from "../../../ui/switch"

type Props = {
  setStep: any
  newMeetingData: Meeting
  setNewMeetingData: any
  /** Availabilities for current logged in user */
  availabilities: Array<AvailabilityRulesData>
  coachDetails: any
}

const CheckAvailability: FC<Props> = ({
  newMeetingData,
  setNewMeetingData,
  setStep,
  availabilities,
  coachDetails,
}) => {
  const [activeDate, setActiveDate] = useState<Date>(
    newMeetingData.date || new Date()
  )
  const [startTime, setStartTime] = useState<Date | null>(null)
  const [availabilityOptions, setAvailabilityOptions] = useState<Array<any>>(
    defaultAvailabilityOption
  )
  const [activeAvailability, setActiveAvailability] =
    useState<AvailabilityRulesData | null>(null)
  const { user } = useContext(UserCtx)
  const { renderError, setPopupNotification } = useContext(CommonFunctionCtx)
  const [availablePeriods, setAvailablePeriods] = useState<Array<any>>([])

  const [avail, setAvail] = useState<Array<string>>([])
  const [clientHasCalendar, setClientHasCalendar] = useState<boolean>(false)
  const [loadingAvailablePeriods, setLoadingAvailablePeriods] =
    useState<boolean>(false)
  const [showAllTimesMode, setShowAllTimesMode] = useState<boolean>(false)
  const [isAvailChange, setisAvailChange] = useState<boolean>(false)
  const [coachIsScheduling, setCoachIsScheduling] = useState<boolean>(false)
  const [userCalendars, setUserCalendars] = useState<any>({})
  const defaultColor = "#4750F5"

  const durationDefault = 60
  const [timeSelected, setTimeSelected] = useState<boolean>(false)
  /**
   * Sets availabile periods based on user availability and coach availability attached to a service
   */
  const getAvailability = () => {
    setLoadingAvailablePeriods(true)
    let activeAvailabilityIdArr: Array<string> = []
    if (activeAvailability && activeAvailability.id) {
      activeAvailabilityIdArr = [activeAvailability.id.toString()]
    } else if (activeAvailability) {
      activeAvailabilityIdArr = [activeAvailability?.toString()]
    } else {
      console.log("no active availability")
    }
    // gets recipient
    const profileIds = newMeetingData?.contacts?.map(
      (contact) => contact.profile_id
    )
    // pushes current user into array
    if (!profileIds.includes(parseInt(user.activeProfileId))) {
      profileIds.push(parseInt(user.activeProfileId))
    }
    // if activeDate is today, make sure that the time sent to GetAvailability is not before now, otherwise the request will fail
    let now = new Date()
    let start_date: any = activeDate
    if (activeDate < now) {
      start_date = moment.utc(now).toISOString()
    } else {
      start_date = moment.utc(activeDate).toISOString()
    }

    const m = moment.tz(activeDate, user.timezone.value)
    const end = m.clone().endOf("day").utc().toISOString()

    const getAvailabilityRequest: GetAvailability = {
      profile_ids: profileIds,
      creator_profile_id: parseInt(coachDetails.coachProfileId),
      availability_rule_ids: activeAvailabilityIdArr,
      meeting_duration: newMeetingData.duration || durationDefault,
      start_date: start_date,
      end_date: end,
      coach_user_id: coachDetails.coachUserId,
      show_all_times: true,
    }

    CalendarRequests.getAvailability(getAvailabilityRequest)
      .then((data) => {
        setAvailablePeriods(data.available_periods)
        setLoadingAvailablePeriods(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingAvailablePeriods(false)
      })
  }
  // const fetchClientCalendars = () => {
  //   if (user) {
  //     CalendarRequests.getCalendarList({
  //       user_id: parseInt(newMeetingData?.contacts[0]?.user_id),
  //     })
  //       .then((data: any) => {
  //         setUserCalendars({
  //           profileCount: data.profile_count,
  //           calendars: data.calendars,
  //         })
  //         if (data.profile_count > 0) {
  //           setClientHasCalendar(true)
  //         }
  //       })
  //       .catch((ex: any) => {
  //         console.log(ex)
  //         renderError(ex.response.data.message)
  //       })
  //   }
  // }
  useEffect(() => {
    if (user.user_id === newMeetingData.coach_user_id.toString()) {
      setCoachIsScheduling(true)
    }
  }, [])

  useEffect(() => {
    setNewMeetingData(() => {
      return { ...newMeetingData, date: activeDate }
    })
  }, [activeDate])

  useEffect(() => {
    if (startTime) {
      setNewMeetingData(() => {
        return {
          ...newMeetingData,
          startTime: startTime,
          endTime: add(startTime, {
            minutes: newMeetingData.duration || durationDefault,
          }),
        }
      })
    }
  }, [startTime])

  useEffect(() => {
    const defaultAvailability = availabilities.find((a) => a.is_default)
    const options = availabilities?.map((a) => ({
      value: a.name,
      label: a.name,
    }))
    // coach avail set for this particular new meeting
    const existingServiceAvailability =
      newMeetingData?.service_details?.availability_rule_id
    !isAvailChange &&
      setActiveAvailability(
        // Default Availability & Availabilities[0] applies to Calendar Syncing only, if none exist, then utilizes existingServiceAvailability (which is the Zoee Calendar)
        existingServiceAvailability || defaultAvailability || availabilities[0]
      )
    setAvailabilityOptions(options || defaultAvailabilityOption)
  }, [availabilities])

  useScrollToTop()

  const handleAvailabilityOptionSelect = (e: any) => {
    const matched = availabilities.find((a) => a.name === e.value)
    if (matched) {
      setisAvailChange(true)
      setActiveAvailability(matched)
    }
  }

  const showShowAllTimesModeDescription = () => {
    if (!clientHasCalendar) {
      setPopupNotification({
        show: true,
        title: "Joint Availability Disabled",
        message:
          "Joint availability option is disabled when the member you are scheduling with does not have a calendar connected.",
        callback: null,
      })
    } else {
      setPopupNotification({
        show: true,
        title: "Disable Joint Availability",
        message:
          "Turning off the joint availability mode will show you all available meeting periods based on your availability only. Availability of meeting attendees will be disregarded.",
        callback: null,
      })
    }
  }

  // useEffect(() => {
  //   fetchClientCalendars()
  // }, [])

  useEffect(() => {
    if (activeAvailability) {
      getAvailability()
    }
  }, [
    activeDate,
    activeAvailability,
    newMeetingData.duration,
    showAllTimesMode,
  ])

  const handleNextButtonClick = () => {
    if (!startTime) {
      setPopupNotification({
        show: true,
        title: "Select Time",
        message: "Please select a time to continue.",
        callback: null,
      })
      return
    }
    setStep((previousStep: number) => previousStep + 1)
  }

  useEffect(() => {
    if (
      user.activeProfile === "coach" &&
      (window.location.pathname === "/coach/scheduling" ||
        window.location.pathname === "/member/scheduling")
    ) {
      if (startTime) {
        localStorage.setItem("selectedStartTime", JSON.stringify(startTime))
      }
    } else {
      localStorage.removeItem("selectedStartTime")
    }
  }, [startTime, window.location.pathname, user.activeProfile])

  useEffect(() => {
    if (user.activeProfile === "coach") {
      const currentPath = window.location.pathname

      if (
        currentPath === "/coach/scheduling" ||
        currentPath === "/member/scheduling"
      ) {
        const storedTime = localStorage.getItem("selectedStartTime")
        if (storedTime) {
          setStartTime(new Date(JSON.parse(storedTime)))
        }
      }
    }
  }, [user.activeProfile])

  return (
    <div className="w-full min-w-[330px] max-w-[75vw] pb-[180px] md:pb-0">
      <div className="mb-[24px] flex w-full items-center justify-between md:mb-[32px]">
        <h3 className="text-[18px] font-bold md:text-[28px] md:font-semibold">
          Check Availability
        </h3>
        <Button
          onClick={() => setStep((previousStep: number) => previousStep - 1)}
          className="text-md"
          size={"lg"}
        >
          Back
        </Button>
        <Button onClick={handleNextButtonClick} className="text-md" size={"lg"}>
          Next
        </Button>
      </div>
      <p></p>
      <div className="grid gap-[12px] md:grid-cols-2 md:gap-[48px]">
        <div className="w-full">
          {coachIsScheduling && availabilities?.length > 0 && (
            <div className="mb-[16px] md:mb-[24px]">
              <h4 className="text-graySlate mb-[8px] text-base">
                Availability
              </h4>
              <Select
                className="basic-single w-full"
                classNamePrefix="select"
                value={availabilityOptions.find((a) => {
                  return a.value === activeAvailability?.name
                })}
                options={availabilityOptions}
                name="availability"
                onChange={handleAvailabilityOptionSelect}
                isDisabled={showAllTimesMode}
              />
            </div>
          )}

          {/* {user.activeProfile === "coach" && (
            <div className="text-grayCharcoal mb-[16px] flex items-center gap-[8px] text-base font-semibold md:mb-[24px]">
              <Label htmlFor="joint-availability">Joint Availability</Label>
              <Switch
                id="joint-availability"
                onCheckedChange={() => setShowAllTimesMode((prev) => !prev)}
                checked={showAllTimesMode}
                disabled={!clientHasCalendar}
              />
              <SvgInformationCircle
                onClick={showShowAllTimesModeDescription}
                className="hover:bg-grayFlash text-graySlate h-[20px] w-[20px] cursor-pointer rounded-[4px] duration-150"
              />
            </div>
          )} */}

          <div className="flex justify-center px-[20px]">
            <CustomDayPicker
              activeDate={activeDate}
              setActiveDate={setActiveDate}
              disablePastDates={true}
              dayColor={defaultColor}
            />
          </div>
        </div>
        <div className="promotion-shadow flex w-full flex-col rounded-[16px] bg-white px-[24px]">
          <h4 className="mx-auto mb-[16px] mt-[24px] text-[16px] font-bold">
            {format(activeDate, "iiii, MMMM do")}
          </h4>
          <div className="mb-[24px]">
            <h4 className="text-graySlate mb-[8px] text-base">Duration</h4>
            <div className="">
              {newMeetingData.duration
                ? durations.find(
                    (option) => option.value === newMeetingData.duration
                  )?.label
                : durations.find((d) => d.value === durationDefault)?.label}
            </div>
          </div>
          <div className="mb-[24px]">
            <h4 className="text-graySlate mb-[8px] text-base">Time</h4>
            <div className="flex max-h-[240px] w-full flex-col gap-[8px] overflow-y-auto">
              {loadingAvailablePeriods ? (
                <div className="px-[8px] pt-[24px] text-center font-bold">
                  <Loader />
                  <h3 className="text-center text-[16px] font-bold">
                    Loading available meeting times.
                  </h3>
                </div>
              ) : (
                <>
                  {availablePeriods.length > 0 ? (
                    <>
                      {availablePeriods.map((availablePeriod, idx) => {
                        return (
                          <Fragment key={idx}>
                            <TimeOptions
                              availabilityStart={moment(
                                availablePeriod.start
                              ).toDate()}
                              availabilityEnd={moment(
                                availablePeriod.end
                              ).toDate()}
                              duration={
                                newMeetingData.duration || durationDefault
                              }
                              setStartTime={setStartTime}
                              startTime={newMeetingData.startTime}
                              dayColor={defaultColor}
                            />
                            <div className="bg-hover text-graySlate flex items-center justify-center rounded-[10px] p-[12px] text-base font-bold last:hidden">
                              Unavailable
                            </div>
                          </Fragment>
                        )
                      })}
                    </>
                  ) : (
                    <div className="px-[8px] pt-[40px] text-center font-bold">
                      No available meeting times for you and your meeting
                      participant(s) on this day. Please select another day.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckAvailability
