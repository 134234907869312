import React from "react";
import moment from "moment";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { getUserColor } from "../../utils/getUserColor";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import useName from "../../hooks/useName";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";

type Props = {
  notification: any;
  markRead: (notification_id: number) => void;
  markUnread: (notification_id: number) => void;
};

const NotificationCenterItem: React.FC<Props> = ({
  notification,
  markRead,
  markUnread,
}) => {
  const { user } = React.useContext(UserCtx);
  const { initial } = useName({ firstName: notification.creator_first_name });
  const navigate = useNavigate();

  const timezone = user?.timezone?.value;
  const notificationTime = moment
    ?.utc(notification.created_at)
    ?.tz(timezone)
    ?.format("lll");

  const setLink = () => {
    let activeProfile = user.activeProfile;
    let link = "/";
    if (notification.link === "/contacts") {
      link = `/${activeProfile}/contacts`;
    } else if (notification.link === "/scheduling") {
      link = `/${activeProfile}/scheduling`;
    } else if (notification.link === "/sessions") {
      link = `/${activeProfile}/sessions`;
    } else if (notification.link === "/inbox") {
      link = `/${activeProfile}/inbox`;
    } else {
      link = notification.link;
    }
    return link;
  };

  const handleNotificationClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    navigate(setLink());
  };

  return (
    <div className="border-grayMist relative w-full border-b px-1 py-1 last:border-0">
      <button
        className="ring-ring flex w-full items-center gap-4 rounded-md px-1 py-4 text-left focus-visible:ring-2"
        onClick={handleNotificationClick}
      >
        <Avatar size="xs">
          {Boolean(notification.creator_has_avatar) && (
            <AvatarImage
              alt="avatar"
              src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${notification.creator_user_id}`}
            />
          )}
          <AvatarFallback
            className={getUserColor(notification.creator_user_color)}
          >
            {initial}
          </AvatarFallback>
        </Avatar>

        <div className="flex items-center gap-[20px]">
          <div className="flex flex-col gap-[2px]">
            <h3 className="text-base">{notification.notification_name}</h3>
            <p className="text-graySlate text-xs">{notificationTime}</p>
          </div>
        </div>
      </button>

      {notification.viewed === 0 && (
        <Button
          onClick={() => markRead(notification.id)}
          className="absolute bottom-[9px] right-[8px]"
          variant="ghost"
          size="sm"
        >
          <Badge dot variant="alert" />
          Mark Read
        </Button>
      )}
    </div>
  );
};

export default NotificationCenterItem;
