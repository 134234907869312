import type { SVGProps } from "react";
export const SvgSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.25"
      clipPath="url(#clip0_6082_9793)"
    >
      <path d="M15.5 12l2.5 3.5m0 0L15.5 19m2.5-3.5H6M8.5 5L6 8.5m0 0L8.5 12M6 8.5h12">
      </path>
    </g>
    <defs>
      <clipPath id="clip0_6082_9793">
        <path fill="currentColor" d="M0 0H24V24H0z"></path>
      </clipPath>
    </defs>
  </svg>
);
