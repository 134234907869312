import type { SVGProps } from "react";
export const SvgMic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.182 15.364a4.89 4.89 0 0 0 4.909-4.91V6.91A4.89 4.89 0 0 0 12.18 2a4.89 4.89 0 0 0-4.909 4.91v3.544a4.89 4.89 0 0 0 4.91 4.91"
    />
    <path
      fill="currentColor"
      d="M20.364 10.455a.91.91 0 0 0-.91-.91.91.91 0 0 0-.909.91c0 3.454-2.818 6.272-6.363 6.272s-6.364-2.818-6.364-6.272a.91.91 0 0 0-.909-.91.91.91 0 0 0-.909.91c0 4.181 3.182 7.636 7.273 8.09v2.546c0 .545.454.91.909.91.545 0 .909-.365.909-.91v-2.546c4.09-.454 7.273-3.909 7.273-8.09"
    />
  </svg>
);
