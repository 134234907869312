import type { SVGProps } from "react";
export const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="10"
    viewBox="0 0 20 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.219999 5.53005L4.16 9.53005C4.31 9.68005 4.5 9.75005 4.69 9.75005C4.88 9.75005 5.07 9.68005 5.22 9.53005C5.52 9.24005 5.52 8.77005 5.23 8.47005L2.54 5.75005H19.25C19.66 5.75005 20 5.41005 20 5.00005C20 4.59005 19.66 4.25005 19.25 4.25005H2.54L5.23 1.53005C5.52 1.23005 5.52 0.760045 5.22 0.470045C4.93 0.170045 4.45 0.180045 4.16 0.470045L0.219999 4.47005C-0.0700016 4.77005 -0.0700016 5.23004 0.219999 5.53005Z"
      fill="#231F20"
    />
  </svg>
);
