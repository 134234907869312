import type { SVGProps } from "react";
export const SvgStarSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.918 14.32a1.1 1.1 0 0 0-.319.97l.89 4.92a1.08 1.08 0 0 1-.45 1.08 1.1 1.1 0 0 1-1.17.08l-4.43-2.31a1.1 1.1 0 0 0-.5-.13h-.27a.8.8 0 0 0-.27.09l-4.43 2.32c-.22.11-.468.15-.71.11a1.11 1.11 0 0 1-.89-1.27l.89-4.92a1.12 1.12 0 0 0-.32-.98l-3.61-3.5a1.08 1.08 0 0 1-.27-1.13c.134-.396.476-.685.89-.75l4.97-.72c.377-.04.71-.27.88-.61l2.19-4.49q.077-.152.2-.27l.09-.07a.7.7 0 0 1 .16-.13l.11-.04.17-.07h.42c.376.04.707.264.88.6l2.22 4.47c.16.327.47.554.83.61l4.97.72c.42.06.77.35.91.75.13.401.017.841-.29 1.13z"
    />
  </svg>
);
