import { SvgFacebook, SvgInstagram, SvgLinkedin, SvgWebsite } from "../components/icons/"

export function getSocialNameAndIcon(link: string) {
  const lowerCaseLink = link.toLowerCase()

  const socialMappings = [
    {
      regex: /facebook\.com/,
      Icon: SvgFacebook,
      name: "Facebook",
    },
    {
      regex: /instagram\.com/,
      Icon: SvgInstagram,
      name: "Instagram",
    },
    {
      regex: /linkedin\.com/,
      Icon: SvgLinkedin,
      name: "LinkedIn",
    },
  ]

  const matchingPlatform = socialMappings.find((platform) =>
    platform.regex.test(lowerCaseLink)
  )

  return matchingPlatform || { Icon: SvgWebsite, name: "External link" }
}
