import React, { ChangeEvent, FC, useState, useContext } from "react";
import * as z from "zod";
import { CreateFormLinkType } from "../../../models/app.interface";
import { FormRequests } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import FormDialog from "./FormDialog";
import useLoading from "../../../hooks/useLoading";

interface Props {
  setCreateFormModal: (statement: boolean) => void;
  coachProfileId: number;
  getFormList: () => void;
}

const LinkToFormModalForm: FC<Props> = ({
  coachProfileId,
  setCreateFormModal,
  getFormList,
}) => {
  const [formLinkData, setFormLinkData] = useState<CreateFormLinkType>({
    coach_profile_id: coachProfileId,
    title: "",
    description: "",
    url: "",
    base_64_string: "",
    file_extension: "",
  });

  const { startLoading, stopLoading } = useLoading();
  const { renderError, renderSuccess } =
    useContext(CommonFunctionCtx);

  const urlSchema = z.string().url().safeParse(formLinkData.url);

  const changeHandler = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    statePreperty: string
  ) => {
    const uploadedFile = (e.target as HTMLInputElement).files?.[0];

    if (uploadedFile) {
      const fileType = uploadedFile.type.split("/")[1];
      let base64String = "";
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        base64String = (reader.result as string)
          .split("\n")
          .map((data) => data.split(","))[0][1];
        setFormLinkData((prev) => {
          return {
            ...prev,
            base_64_string: base64String,
            file_extension: fileType,
          };
        });
      };
    }

    if (!uploadedFile) {
      setFormLinkData((prev) => {
        return {
          ...prev,
          [statePreperty]: e.target.value,
        };
      });
    }
  };

  const createFormRequest = () => {
    startLoading();
    FormRequests.createFormLink(formLinkData)
      .then((data) => {
        setCreateFormModal(false);
        renderSuccess(data.message);
        getFormList();
      })
      .catch((ex) => {
        setCreateFormModal(false);
        renderError(ex.response.data.message);
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleCreateForm = () => {
    if (formLinkData.file_extension) {
      createFormRequest();
    } else if (!formLinkData.file_extension) {
      if (urlSchema.success) {
        createFormRequest();
      } else {
        renderError(
          `${urlSchema.error.issues[0].message}. Please check the form url and try again`
        );
      }
    }
  };

  return (
    <FormDialog
      dialogTitle={"Add A Form"}
      modalCloseFn={setCreateFormModal}
      changeHandler={changeHandler}
      formHandler={handleCreateForm}
      formLinkData={formLinkData}
    />
  );
};

export default LinkToFormModalForm;
