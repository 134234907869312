import { Button } from "../../ui/button";

const JoinTeamZoeeButton = () => {
  const handleJoinTeamZoeeClick = () => {
    window.open("mailto:hello@zoee.com");
  };

  return (
    <Button
      onClick={handleJoinTeamZoeeClick}
      size="lg"
    >
      Join Team Zoee
    </Button>
  );
};

export default JoinTeamZoeeButton;
