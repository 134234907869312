import type { SVGProps } from "react";
export const SvgIdea = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.177 6.356c-1.038-2.095-3.04-3.65-5.356-4.16-2.435-.536-4.924.039-6.827 1.577A8.03 8.03 0 0 0 4 10.047c0 2.59 1.544 5.303 3.876 6.87l-.002.83c-.008.284-.02.713.315 1.058.347.358.862.404 1.273.404h5.11c.535 0 .948-.146 1.227-.434.379-.39.366-.892.359-1.157v-.701c3.094-2.09 5.032-6.495 3.019-10.56m-4.073 9.58a.7.7 0 0 0-.336.598l.001 1.172.002.091a1 1 0 0 1-.199.016h-5.11q-.126 0-.2-.008l.001-.02.003-1.251a.7.7 0 0 0-.336-.597c-1.762-1.067-3.54-3.302-3.54-5.89 0-2.025.902-3.916 2.476-5.187C9.438 3.59 11.5 3.115 13.523 3.56c1.91.42 3.557 1.698 4.41 3.419 1.727 3.49-.082 7.295-2.83 8.959m.811 5.558a.695.695 0 0 1-.86.48 11.05 11.05 0 0 0-6.103 0 .697.697 0 0 1-.382-1.343 12.45 12.45 0 0 1 6.868 0c.369.106.583.492.477.863"
    />
  </svg>
);
