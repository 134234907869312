import React from "react"
import ContactAvatar from "../contacts/ContactAvatar"
import { SvgCheckbox } from "../icons/"

interface Props {
  contact: any
  selectedContacts: any
  user: any
}

const Person: React.FC<Props> = ({ contact, selectedContacts, user }) => {
  return (
    <div
      className={`h-[68px] w-full p-[14px] flex items-center justify-between cursor-pointer
            ${
              selectedContacts.includes(contact.profile_id) &&
              "bg-primaryBlue/[0.04] rounded-[14px]"
            }`}
    >
      <div className="flex items-center">
        <div className="mr-[16px]">
          <ContactAvatar
            contact={contact}
            width={40}
            height={40}
            border="border-none"
          />
        </div>
        <div className="flex flex-col">
          <h6 className="font-bold text-[16px]">
            {contact.contact_name.length > 20
              ? `${contact.contact_name.slice(0, 20)}…`
              : contact.contact_name}
            {user.activeProfile === "coach" && (
              <span
                style={{
                  backgroundColor: "#F3F4F6",
                  color: "#5865F2",
                  padding: "4px 8px",
                  borderRadius: "12px",
                }}
                className="inline-block ml-2"
              >
                {contact.profile_type === "coach" ? "Coach" : "Member"}
              </span>
            )}
          </h6>
        </div>
      </div>
      <div
        className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                        flex items-center justify-center
                        ${
                          selectedContacts.includes(contact.profile_id) &&
                          "bg-cyanBlue"
                        }`}
      >
        {selectedContacts.includes(contact.profile_id) && <SvgCheckbox />}
      </div>
    </div>
  )
}

export default Person
