import React from "react"
import { SvgEdit, SvgUser } from "../icons/"

type Props = {
  setNewMeetingType: any
  setShowNewMeeting: any
  setKeepBackToButton: any
  setClickReschedule: any
}

const NewMeetingDropdown: React.FC<Props> = ({
  setNewMeetingType,
  setShowNewMeeting,
  setKeepBackToButton,
  setClickReschedule, 
}) => {

  return (
    <>
      <div className="w-[250px] px-[16px] py-[12px] rounded-[12px] bg-white box-shadow-2">
        <button
          onClick={() => {
            setNewMeetingType("individual")
            setShowNewMeeting(true)
            setKeepBackToButton(true)
            setClickReschedule(false)
          }}
          className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
        >
          <SvgUser />
          <p>Individual (Billable)</p>
        </button>
        {/* HIDE GROUP FOR 2.0 */}
        {/* <button
                    onClick={() => {
                        setNewMeetingType("group");
                        setShowNewMeeting(true);
                    }}
                    className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
                >
                    <img
                        src={users}
                        alt="two people icon"
                        className="w-[24px] h-[24px]"
                    />
                    <p>Group</p>
                </button> */}
        <button
          onClick={() => {
            setNewMeetingType("custom")
            setShowNewMeeting(true)
            setKeepBackToButton(true)
            setClickReschedule(false)
          }}
          className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
        >
          <SvgEdit />
          <p>Non-billable</p>
        </button>
      </div>

      {/* HIDE GROUP FOR 2.0 */}
      {/* <button
                    onClick={() => {
                        setNewMeetingType("group");
                        setShowNewMeeting(true);
                    }}
                    className="w-full flex items-center gap-[12px] px-[16px] py-[12px] hover:bg-hover rounded-[12px]"
                >
                    <img
                        src={users}
                        alt="two people icon"
                        className="w-[24px] h-[24px]"
                    />
                    <p>Group</p>
                </button> */}
    </>
  )
}

export default NewMeetingDropdown
