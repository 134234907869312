export function generatePassword(): string {
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()_-+=[]{}|;:,.<>?";

  const getRandomChar = (chars: string) => {
    const randomIndex = Math.floor(Math.random() * chars.length);
    return chars[randomIndex];
  };

  let password = "";

  // Generate one of each required character type
  password += getRandomChar(lowercaseChars);
  password += getRandomChar(uppercaseChars);
  password += getRandomChar(numbers);
  password += getRandomChar(specialChars);

  // Generate the remaining characters
  const remainingLength = 4; // 8 characters total - 4 already generated
  for (let i = 0; i < remainingLength; i++) {
    const charSet = lowercaseChars + uppercaseChars + numbers + specialChars;
    password += getRandomChar(charSet);
  }

  // Shuffle the password characters to randomize the order
  password = password.split("").sort(() => Math.random() - 0.5).join("");

  return password;
}
