import type { SVGProps } from "react";
export const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.885 2.06A9.98 9.98 0 0 0 5.15 4.705a10 10 0 0 0-3.005 5.562 10.2 10.2 0 0 0 .113 4 .667.667 0 0 0 .852.507.667.667 0 0 0 .44-.814 8.9 8.9 0 0 1-.107-3.333 8.67 8.67 0 0 1 2.455-4.782A8.65 8.65 0 0 1 15.993 4.32a8.66 8.66 0 0 1 3.753 3.846 8.68 8.68 0 0 1-1.75 10.073 8.65 8.65 0 0 1-10.113 1.394.664.664 0 0 0-.858.233.668.668 0 0 0 .299.96 9.97 9.97 0 0 0 11.554-1.564 10.01 10.01 0 0 0 2.197-11.468 10 10 0 0 0-4.156-4.506 9.97 9.97 0 0 0-6-1.228z"
    />
    <path
      fill="currentColor"
      d="M10.366 16.314 17.488 9.2a.667.667 0 0 0 0-.94.666.666 0 0 0-.938 0l-6.183 6.194a.18.18 0 0 1-.247 0l-2.702-2.727a.665.665 0 0 0-.939 0 .67.67 0 0 0 0 .94l3.641 3.647a.18.18 0 0 0 .247 0"
    />
  </svg>
);
