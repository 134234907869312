import React, { createRef, FC } from "react"
import ReCAPTCHA from "react-google-recaptcha"

import { VerifyRecaptcha } from "../../../api/auth.service"

interface RecaptchaProps {
  setIsNotRobot: (statement: boolean) => void
}

const Recaptcha: FC<RecaptchaProps> = ({ setIsNotRobot }) => {
  // Logic for ReCAPTCHA - https://www.npmjs.com/package/react-google-recaptcha

  const recaptchaRef = createRef<ReCAPTCHA>()
  const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === "true"

  const recaptchaSubmit = (tokenValue: string | null) => {
    if (tokenValue) {
      VerifyRecaptcha.recaptchaVerification({
        access_token: tokenValue,
      })
        .then((response) => {

          setIsNotRobot(true)
        })
        .catch((error) => {
          setIsNotRobot(false)
        })
    }
  }

  if (recaptchaEnabled) {
    return (
      <ReCAPTCHA
        size="normal"
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
        onChange={recaptchaSubmit}
        onExpired={() => setIsNotRobot(false)}
      />
    )
  } else {
    setIsNotRobot(true)
    return null
  }
}

export default Recaptcha
