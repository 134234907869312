import React from "react";
import NotificationItem from "./NotificationSettingsItem";
import { Notification } from "../../types/notificationTypes";
import { UserCtx } from "../../context/userContext";

type Props = {
    categoryName: string;
    categoryDescription: string;
    handleSelect: (e: React.ChangeEvent<HTMLInputElement>, profileNotificationId: number, type: string) => void;
    notifications: Notification[];
};

const NotificationCategory: React.FC<Props> = ({
    categoryName,
    categoryDescription,
    handleSelect,
    notifications,
}) => {
    const { user } = React.useContext(UserCtx);
    
    const profileTypeNotifications = notifications.filter((notification:any) => {
        return ["general", user.activeProfile].includes(notification.user_type)
    }) 
    

    const generateNotificationPairs = () => {
        // Loop through notifications, sort notifications that share the same notification_id into an array; use this array to generate NotificationItems
        let notificationGroups: any = {};
        notifications.forEach((n) => {
            if (!notificationGroups[n.notification_id.toString()]) {
                notificationGroups[n.notification_id.toString()] = [n];
            } else {
                notificationGroups[n.notification_id.toString()].push(n);
            }
        });
        return Object.keys(notificationGroups).map((key) => notificationGroups[key]);
    };

    return (
        <>
            {profileTypeNotifications.length > 0 && (
                <>
                    <div className="w-full flex flex-col md:flex-row justify-between md:gap-[8px] gap-[40px]">
                        <div className="flex flex-col gap-[8px] lg:w-[60%] lg:max-w-[60%] md:w-[40%] md:max-w-[25%]">
                            <h3 className="font-bold">{categoryName}</h3>
                            <p className="text-graySlate text-base font-semibold">
                                {categoryDescription}
                            </p>
                        </div>

                        <div className="flex flex-col gap-[32px] w-full">
                            {generateNotificationPairs().map((notificationGroup, idx) => {
                                if (
                                    notificationGroup[0].user_type === "general" ||
                                    notificationGroup[0].user_type === user.activeProfile
                                ) {
                                    return (
                                        <NotificationItem
                                            key={idx}
                                            notifications={notificationGroup}
                                            handleSelect={handleSelect}
                                        />
                                    );
                                } else {
                                    return <></>;
                                }
                            })}
                        </div>
                    </div>
                    <div className="last:hidden w-full h-[1px] bg-grayCloud"></div>
                </>
            )}
        </>
    );
};

export default NotificationCategory;
