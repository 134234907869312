import type { SVGProps } from "react"
export const SvgShieldFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 13.5022V7.15454C4.5 6.75724 4.73519 6.39934 5.09918 6.24009L12.0992 3.17759C12.3547 3.0658 12.6453 3.0658 12.9008 3.17759L19.9008 6.24009C20.2648 6.39934 20.5 6.75724 20.5 7.15454V13.5022C20.5 18.0022 12.5 23.0022 12.5 23.0022C12.5 23.0022 4.5 18.0022 4.5 13.5022ZM12.3234 15.9372L16.5094 11.7544C16.9719 11.2921 16.9719 10.5295 16.5094 10.0673C16.0469 9.60521 15.2836 9.60521 14.8211 10.0673L11.4445 13.3951L10.1957 12.1473C9.7332 11.6851 8.96996 11.6851 8.50746 12.1473C8.04496 12.6094 8.04496 13.3721 8.50746 13.8342L10.6121 15.9372C10.8433 16.1682 11.144 16.2838 11.4677 16.2838C11.7915 16.2838 12.0921 16.1683 12.3234 15.9372Z"
      fill="#4750F5"
    />
  </svg>
)
