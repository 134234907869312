import { SvgCode } from "../../../components/icons";
import { Button } from "../../../components/ui/button";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";

interface ButtonVariant {
  [key: string]: Array<string | boolean>;
}

interface Permutation {
  props: { [key: string]: string | boolean };
  label: string;
}

export const ButtonsPage: React.FC = () => {
  const buttonVariants: ButtonVariant[] = [
    { disabled: [false, true] },
    {
      variant: [
        "default",
        "secondary",
        "destructive",
        "ghost",
        "outline",
        "link",
        "utility",
        "context",
      ],
    },
    { size: ["sm", "default", "lg", "icon"] },
  ];

  const generatePermutations = (
    variants: ButtonVariant[],
    index = 0,
    currentPermutation: { [key: string]: string | boolean } = {},
  ): Permutation[] => {
    if (index === variants.length) {
      return [
        {
          props: currentPermutation,
          label: Object.entries(currentPermutation)
            .map(([key, value]) => `${key}: ${value}`)
            .join(", "),
        },
      ];
    }

    const variant = variants[index];
    const variantName = Object.keys(variant)[0];
    const variantValues = variant[variantName];
    const permutations: Permutation[] = [];

    for (const value of variantValues) {
      const updatedPermutation = {
        ...currentPermutation,
        [variantName]: value,
      };
      const subPermutations = generatePermutations(
        variants,
        index + 1,
        updatedPermutation,
      );
      permutations.push(...subPermutations);
    }

    return permutations;
  };

  const permutations: Permutation[] = generatePermutations(buttonVariants);
  const [, copy] = useCopyToClipboard();

  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-3xl font-bold">Buttons</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {permutations.map((permutation, index) => (
          <button
            key={index}
            className="hover:border-blurple flex flex-col items-center gap-6 border p-6 transition-colors"
            onClick={() =>
              copy(
                `<Button${
                  permutation.props.variant !== "default"
                    ? ` variant="${permutation.props.variant}"`
                    : ""
                }${
                  permutation.props.size !== "default"
                    ? ` size="${permutation.props.size}"`
                    : ""
                }${
                  permutation.props.disabled
                    ? ` disabled={${permutation.props.disabled}}`
                    : ""
                }>${permutation.props.size !== "icon" ? "Copy Me" : "<SvgCode />"}</Button>`,
              )
            }
          >
            <Button {...permutation.props}>
              <SvgCode />
              {permutation.props.size !== "icon" && "Copy Me"}
            </Button>

            <ul className="text-muted-foreground flex w-full flex-col items-start text-xs">
              <li>
                <strong>Variant:</strong> {permutation.props.variant}
              </li>
              <li>
                <strong>Size:</strong> {permutation.props.size}
              </li>
              <li>
                <strong>Disabled:</strong>{" "}
                {permutation.props.disabled ? "true" : "false"}
              </li>
            </ul>
          </button>
        ))}
      </div>
    </div>
  );
};
