// This is a JavaScript function to check if the 'localStorage' feature is available.

export const isLocalStorageAvailable = () => {
  try {
    // Attempt to access the 'localStorage' object from the browser's window.
    const storage = window["localStorage"];
    const x = "__storage_test__";

    storage.setItem(x, x);
    storage.removeItem(x);

    // If the code above runs without errors, return 'true', indicating that 'localStorage' is available.
    return true;
  } catch (e) {
    // If any errors occur during the above operations (e.g., if 'localStorage' is disabled due to browser settings), catch the error and return 'false'.
    return false;
  }
};
