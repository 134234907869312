import type { SVGProps } from "react";
export const SvgGallery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.3 2h1.4v5.7H2V6.3C2 3.9 3.9 2 6.3 2M7.7 9.7H2v4.7h5.7zM2 16.3h5.7V22H6.3C3.9 22 2 20.1 2 17.7zM14.4 2H9.7v5.7h4.7zM14.4 9.7H9.7v4.7h4.7zM14.4 16.3H9.7V22h4.7zM22 6.3v1.4h-5.7V2h1.4C20.1 2 22 3.9 22 6.3M22 9.7h-5.7v4.7H22zM16.3 16.3H22v1.4c0 2.3-1.9 4.3-4.3 4.3h-1.4z"
    />
  </svg>
);
