import React from 'react';
import moment from 'moment';
import useDate from '../../hooks/useDate';

interface Props {}

const TimeTicker: React.FC<Props> = () => {
    const [time, setTime] = React.useState<string>(moment().format('h:mm:ss a'));

    const { mdDate } = useDate()

    React.useEffect(() => {
        const intervalT = setInterval(() => setTime(moment().format('h:mm:ss a')), 1000);
        return () => {
            clearInterval(intervalT);
        };
    }, []);

    return (
        <>
            <div 
                className="flex items-center justify-center flex-col 
                gap-[8px] bg-lightPurple/[0.32] w-full h-[122px] rounded-t-[16px]"
            >
                <h1 className='text-purple font-bold text-[32px]'>{time}</h1>
                <h6 className='text-purple font-bold text-base'>{mdDate(new Date())}</h6>
            </div>
        </>
    );
}

export default TimeTicker;
