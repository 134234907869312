import previewImage from "../../../assets/blog/fourCoachingTipsForTheHolidaySeason.webp";
import { Post } from "../../../types/postTypes";
 
export const fourTipsForHolidays: Post = {
   metadata: {
       id: 1,
       title: "4 Tips for Keeping Your Coaching Business Alive During the Holiday Season",
       author: "Zoee",
       date: "November 28, 2022",
       urlSlug: "4-coaching-tips-for-the-holiday-season",
       description:
           "The holidays definitely feel like a time to spend with family and friends, but that doesn't mean you can let your coaching business suffer! Here are our tips for keeping business alive.",
       previewImg: previewImage,
       tags: ["Coach", "Entrepreneur"],
   },
   content: [
       {
           type: "paragraph",
           text: "Well, we’re in the midst of it again: The holiday season. Now that Thanksgiving has passed, many of us feel the pull to gently push “marketing for my coaching business” to the bottom of the to-do list and embrace watching Hallmark movies while sipping hot cocoa.",
       },
       {
           type: "paragraph",
           text: "Being your own boss means you can give yourself a “holiday-themed day off” at any time, right? Not quite.",
       },
       {
           type: "paragraph",
           text: "Our advice is to save the Hallmark movies for the weekend and set your January up for success. We all know that no one wants to be looking at an empty calendar after a super fun New Year’s Eve. Instead, setting yourself up for a full January means bringing critical momentum into the rest of the year. What better way to ring in the new year than with an incredibly impactful (and profitable) quarter 1?",
       },
       {
           type: "paragraph",
           text: "If you need a little guidance, here are 4 tips from Zoee to keep your coaching business alive during the holiday season:",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "1.",
               },
               {
                   type: "bold",
                   text: "Network.",
               },
               {
                   type: "normal",
                   text: "We know, we know. This is something you can do year-round. But hear us out! There’s something extra magical about networking during the holiday season, with businesses throwing holiday parties, friends having gatherings, and community markets being plentiful. It seems that everywhere you turn, there’s constant momentum in December. So, to keep your business alive, it’s all about focusing that momentum where you want it.",
               },
           ],
       },
       {
           type: "paragraph",
           text: "We’re not saying you have to wear a sash that says, “Hire me as your coach!” and hand out candy canes with your business card attached. Still, the holiday season is an important time to build relationships with people in your area. You never know who might connect you with your next client.",
       },
       {
           type: "paragraph",
           text: "The relationships you build at a holiday get-together could be your next discovery call, or they could become a friend that provides referrals for years to come. You’ve got to put yourself out there, let people know what you do—confidently—and come prepared with business cards in case someone asks.",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "2.",
               },
               {
                   type: "bold",
                   text: "Discount Codes.",
               },
               {
                   type: "normal",
                   text: "We know this may sound cheesy, but one way to fill up your January calendar is to mimic every other retail business on the planet by offering a sale in December.",
               },
           ],
       },
       {
           type: "paragraph",
           text: "Offer a $100 discount, 20% off, or bonus coaching session. Just choose a deal that makes sense for you and your business. The offer should incentivize people to either book now if they’re ready or pre-book for January.",
       },
       {
           type: "paragraph",
           text: "Why not capitalize on the fact that people want to make positive changes after January 1st? It allows you and your clients to plan ahead.",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "3.",
               },
               {
                   type: "bold",
                   text: "Tell People What They Can Expect.",
               },
               {
                   type: "normal",
                   text: "When selling a service like coaching, you may need to explain a little bit more about what you do. If someone has never hired a coach before, they may not know what to expect from the relationship.",
               },
           ],
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "How do you solve this? One way is to record a video of yourself (or go live on your favorite social media platform) and tell people what they can expect from working with you as a coach! Will you be available by phone 24/7? Do you schedule weekly calls? Can you meet your clients at the gym for a workout? Will you come to their house and file taxes with them? Whatever your vibe is, tell people about it. You never know who has had a bad experience with coaching in the past and needs to hear what it’s",
               },
               {
                   type: "italic",
                   text: "actually",
               },
               {
                   type: "normal",
                   text: "like working with you.",
               },
           ],
       },
       {
           type: "paragraph",
           text: "You—the awesome coach that you are—could be who they’re looking for!",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "4.",
               },
               {
                   type: "bold",
                   text: "Ask for the Sale.",
               },
               {
                   type: "normal",
                   text: "This seems simple, but it’s one of the things coaches most often miss. As a giving and caring person, it can feel like second nature to want to give your services away for free. But you’re a business owner! You have a business to run and money to make. You deserve to be paid for your expertise.",
               },
           ],
       },
       {
           type: "paragraph",
           text: "Giving away advice and coaching for free is like opening a handbag store and letting people shoplift. Charge for your services and ask people to pay that price. If a fair-weather friend reaches out for some advice, let them know you’d be happy to work with them as their coach and set up a discovery call. Then, at the end of the call, explain your program and fees. You may be surprised at how accessible it is!",
       },
       {
           type: "paragraph",
           text: "We know that December is often the busiest time of year, and for most of us, it is! But strategically adding a few tactics to help market your business is critical to avoiding a holiday slump and setting yourself up for success in the new year.",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "Looking to join the most powerful coaching platform on the market? Start your Zoee trial today!",
               },
           ],
       },
       {
 
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "Written by Zoee."
               },
           ]
       },
   ],
};
 

