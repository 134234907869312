import { FC } from "react";

type Props = {
    personalizeYourPageValues: any;
    hasPypImage: boolean;
    imageId: string | null;
};

const PersonalizeYourPageSection: FC<Props> = ({
    personalizeYourPageValues,
    hasPypImage,
    imageId,
}) => {
    return (
        <div className="max-w-[638px]">
            <div className="flex items-center gap-[24px]">
                {hasPypImage && imageId && (
                    <div className="hidden md:block min-w-[140px] w-[140px] h-[140px] relative rounded-full">
                        <img
                            src={`https://pyp-avatar-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${imageId}`}
                            className="absolute top-0 left-0 w-full h-full object-cover rounded-full flex items-center justify-center"
                            alt="avatar"
                        />
                    </div>
                )}
                <div>
                    <div className="flex items-center gap-[16px] mb-[9px] md:mb-[20px]">
                        {hasPypImage && imageId && (
                            <div className="md:hidden min-w-[73px] w-[73px] h-[73px] relative rounded-full">
                                <img
                                    src={`https://pyp-avatar-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${imageId}`}
                                    className="absolute top-0 left-0 w-full h-full object-cover rounded-full flex items-center justify-center"
                                    alt="avatar"
                                />
                            </div>
                        )}
                        <div className="flex flex-col gap-[4px]">
                            <h4 className="text-[23px] font-bold">
                                {personalizeYourPageValues.name || ""}
                            </h4>
                            <h5>{personalizeYourPageValues.title || ""}</h5>
                        </div>
                    </div>
                    <p className="italic text-grayCharcoal">
                        {personalizeYourPageValues.description || ""}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PersonalizeYourPageSection;
