import React from "react";
import servicePreviewImage from "../../../assets/images/service-preview-image.webp";
import { Label } from "../../ui/label";
import { Switch } from "../../ui/switch";

interface Props {
    setServiceValues: any;
    serviceValues: any;
    connectAccountData: any;
    canEnable: boolean;
    setSyncPrompt: any;
    enrolledCount?: any;
}

const ManageServiceOptions: React.FC<Props> = ({
    setServiceValues,
    serviceValues,
    connectAccountData,
    canEnable,
    setSyncPrompt,
    enrolledCount
}) => {
    const canEnableFree = serviceValues?.isFree && connectAccountData?.identity_status === 'verified'

    return (
        <>
            <div className="flex flex-col w-full gap-[24px] mb-[32px]">
                <div className="flex flex-col w-full gap-[15px]">
                    <span className="text-base text-graySlate">General</span>
                    <div className="flex items-center justify-between w-full">
                        <Label htmlFor="enable-service">Enable Service</Label> 
            
                        <Switch id="enable-service" disabled={enrolledCount && enrolledCount > 0} checked={serviceValues.enabled} onCheckedChange={() => {
                          if (canEnable || canEnableFree) {
                            setServiceValues({
                                ...serviceValues,
                                enabled: !serviceValues.enabled,
                            });
                          } else {
                            setSyncPrompt({show: true, service: serviceValues})
                          }
                        }} />
                    </div>
                </div>

                {/* MY PROFILE TOGGLES - hidden for 2.0 launch */}
                {/* <div className="flex flex-col w-full gap-[15px]">
                    <span className="text-base text-graySlate">
                        My Profile
                    </span>
                    <div className="flex items-center justify-between w-full">
                        <span className="font-bold text-base">
                            Service Visible
                        </span>
                        <Toggle
                            icons={false}
                            checked={serviceValues.profileServiceVisible}
                            onChange={() =>
                                setServiceValues({
                                    ...serviceValues,
                                    profileServiceVisible:
                                        !serviceValues.profileServiceVisible,
                                })
                            }
                        />
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <span className="font-bold text-base">
                            Cost Visible
                        </span>
                        <Toggle
                            icons={false}
                            checked={serviceValues.profileCostVisible}
                            onChange={() =>
                                setServiceValues({
                                    ...serviceValues,
                                    profileCostVisible:
                                        !serviceValues.profileCostVisible,
                                })
                            }
                        />
                    </div>
                </div> */}

                {/* MARKETPLACE TOGGLES - hidden for 2.0 launch */}
                {/* <div className="flex flex-col w-full gap-[15px]">
                    <span className="text-base text-graySlate">
                        Marketplace
                    </span>
                    <div className="flex items-center justify-between w-full">
                        <span className="font-bold text-base">
                            Service Visible
                        </span>
                        <Toggle
                            icons={false}
                            checked={serviceValues.marketplaceServiceVisible}
                            onChange={() =>
                                setServiceValues({
                                    ...serviceValues,
                                    marketplaceServiceVisible:
                                        !serviceValues.marketplaceServiceVisible,
                                })
                            }
                        />
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <span className="font-bold text-base">
                            Cost Visible
                        </span>
                        <Toggle
                            icons={false}
                            checked={serviceValues.marketplaceCostVisible}
                            onChange={() =>
                                setServiceValues({
                                    ...serviceValues,
                                    marketplaceCostVisible:
                                        !serviceValues.marketplaceCostVisible,
                                })
                            }
                        />
                    </div>
                </div> */}
            
            </div>
            <div className="w-full h-[1px] bg-grayMist mb-[40px]"></div>
            <img
                src={servicePreviewImage}
                alt="preview"
                className="mb-[24px] mx-auto "
            />
        </>
    );
};

export default ManageServiceOptions;
