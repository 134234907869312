import React from "react";
import CharLimit from "../../ui/charLimit";
import { SvgWarning } from "../../icons";

interface Props {
    formErrors: any;
    setServiceValues: any;
    serviceValues: any;
    isGroupService: boolean;
}

const ServiceDescriptionForm: React.FC<Props> = ({
    formErrors,
    setServiceValues,
    serviceValues,
    isGroupService
}) => {

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setServiceValues({ ...serviceValues, [e.target.name]: e.target.value });
    };

    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.type === "number" ? parseInt(e.target.value) : e.target.value;
        setServiceValues({ ...serviceValues, [e.target.name]: value });
    };

    return (
        <>
            <div className="md:w-[620px] main-shadow flex flex-col rounded-[16px] bg-white p-[32px] md:p-[40px] mx-auto">
                <h2 className="font-bold text-[22px] mb-[12px]">{isGroupService ? "Group Service " : "1:1 Service "}Info</h2>
                <p className="text-graySlate text-base mb-[32px]">
                    Describe your service. Sell your services by giving your clients
                    a description of what you are offering.
                </p>
                <div className="flex flex-col gap-[8px] w-full mb-[24px]">
                    <div className="ml-auto text-gray text-base">
                        <CharLimit charNumber={120} inputText={serviceValues.title} />
                    </div>
                    <div
                        className={`default-input w-full ${
                            formErrors?.title && "input-error"
                        }`}
                    >
                        <input
                            value={serviceValues?.title}
                            type="text"
                            maxLength={120}
                            placeholder={`Title of ${isGroupService ? "group " : "1:1 "}service`}
                            name="title"
                            onChange={handleChange}
                        />
                        <div className="overlay">Title of Program</div>
                    </div>
                    {formErrors.title && (
                        <div className="field-error w-full">
                            <SvgWarning fill="#CF2E2E" />
                            {formErrors.title}
                        </div>
                    )}
                </div>
                <div
                    className={`flex flex-col gap-[8px] w-full mb-[32px] ${
                        formErrors?.description && "input-error"
                    }`}
                >
                    <div className="ml-auto text-gray text-base">
                        <CharLimit charNumber={500} inputText={serviceValues.description} />
                    </div>
                    <textarea
                        value={serviceValues?.description}
                        onChange={handleTextAreaChange}
                        name="description"
                        cols={30}
                        maxLength={500}
                        rows={8}
                        className={`resize-none border-[1px] border-solid 
                        text-graySlate text-[16px] 
                        py-[16px] px-[20px] rounded-[10px] focus:outline-0
                        focus:border focus:border-solid focus:border-royalBlue
                        focus:text-black border-grayCloud`}
                        placeholder="Brief description for this service"
                    ></textarea>
                    {formErrors.description && (
                        <div className="field-error w-full">
                            <SvgWarning fill="#CF2E2E" />
                            {formErrors.description}
                        </div>
                    )}
                </div>
                <div className="flex flex-col md:flex-row md:items-center gap-[4px] md:gap-0 w-full justify-between mb-[12px]">
                    <h4 className="font-bold text-base">
                        {`What are the main features of this ${isGroupService ? "group" : "1:1"} service?`}
                    </h4>
                    <div className="text-gray text-base w-full md:w-auto text-right md:text-left">
                        50 Characters
                    </div>
                </div>
                <div className="flex flex-col gap-[12px] w-full">
                    <div>
                        <div
                            className={`
                                default-input w-full`}
                        >
                            <input
                                value={serviceValues?.feature1}
                                type="text"
                                placeholder="Main Feature 1"
                                onChange={handleChange}
                                name="feature1"
                                maxLength={50}
                            />
                            <div className="overlay">Main Feature 1</div>
                        </div>
                    </div>
                    <div className="default-input w-full">
                        <input
                            value={serviceValues?.feature2}
                            type="text"
                            placeholder="Main Feature 2"
                            onChange={handleChange}
                            name="feature2"
                            maxLength={50}
                        />
                        <div className="overlay">Main Feature 2</div>
                    </div>
                    <div className="default-input w-full">
                        <input
                            value={serviceValues?.feature3}
                            type="text"
                            placeholder="Main Feature 3"
                            onChange={handleChange}
                            name="feature3"
                            maxLength={50}
                        />
                        <div className="overlay">Main Feature 3</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceDescriptionForm;
