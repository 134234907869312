import React, { useEffect } from "react";
import { format, parseISO } from "date-fns";
import NoteTag from "./NoteTag";
import { Note } from "../../models/app.interface";

type Props = {
    note: Note;
    setActiveNoteId: any;
    activeNoteId: number | null;
    isShowUpdatedDate: boolean;
    setShowNoteModal: any;
};

const NotePreviewCard: React.FC<Props> = ({
    note,
    setActiveNoteId,
    activeNoteId,
    setShowNoteModal,
    isShowUpdatedDate
}) => {
    const [isActive, setIsActive] = React.useState<boolean>(false);
    const [borderStyle, setBorderStyle] = React.useState<string>(
        "border border-grayMist"
    );

    const handleClick = () => {
        window.scrollTo(0, 0);
        setIsActive(() => !isActive);
        if (note.id === activeNoteId) {
            setActiveNoteId(null);
            setShowNoteModal(false);
        } else {
            setActiveNoteId(note.id);
            setShowNoteModal(false)
            setTimeout(() => {
                setShowNoteModal(true);
            }, 1);
        }
        isActive
            ? setBorderStyle("border border-grayMist")
            : setBorderStyle("border-primaryBlue border-2");
    };

    useEffect(() => {
        if (note.id === activeNoteId) {
            setIsActive(true);
            setBorderStyle("border-primaryBlue border-2");
        } else {
            setIsActive(false);
            setBorderStyle("border border-grayMist");
        }
    }, [activeNoteId, note.id]);

    return (
        <div
            className={`cursor-pointer md:min-w-[380px] w-full p-[20px] mb-[8px] last:mb-0 rounded-[14px] ${borderStyle}`}
            onClick={handleClick}
        >
            <div className="flex items-center justify-between mb-[2px]">
                <h4 className="text-base font-bold w-[231px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {note.title}
                </h4>
                {isShowUpdatedDate ? (
                    <p className="text-base text-gray min-w-fit">
                        Updated: {format(parseISO(note.updated_at), "M/d/yy")}
                    </p>
                ) : (
                    <p className="text-base text-gray min-w-fit">
                        Created: {format(parseISO(note.created_at), "M/d/yy")}
                    </p>
                )}
            </div>
            <p className="text-base md:w-[332px] w-[306px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {/* Currently just rendering first block in note. May want to render more or cover edge cases (do other block types also work?) better in future */}
                {/* e.g. Doesn't render a list item if it is the first block in the note */}
                {note.content[0].children[0].text}
            </p>
            {note.session_id || note.program_id ? (
                <div className="mt-[12px] flex items-center gap-[4px]">
                    {note.session_id ? (
                        <NoteTag isSession={true} isProgram={false} />
                    ) : (
                        <></>
                    )}
                    {note.program_id ? (
                        <NoteTag isSession={false} isProgram={true} />
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default NotePreviewCard;
