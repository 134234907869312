import { motion } from "framer-motion";
import { cn } from "../../../../utils/cn";
import type { Feature } from "./feature-data";
import { SvgChevronRight } from "../../../../components/icons";

export function FeatureItem(
  props: {
    feature: Feature;
    activeFeatureIndex: number;
    setActiveFeatureIndex: (id: number) => void;
    index: number;
  },
) {
  return (
    <motion.button
      key={props.feature.id}
      onClick={() => props.setActiveFeatureIndex(props.index)}
      className={cn(
        "relative w-fit p-6 text-xl font-bold flex items-center gap-4 rounded-lg transition-colors duration-500 text-left",
        {
          "text-blurple bg-white": props.activeFeatureIndex === props.index,
        },
      )}
    >
      {props.feature.icon}
      {props.feature.text}
      {props.activeFeatureIndex === props.index && (
        <motion.div
          initial={{ x: 30, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            duration: 0.5,
            bounce: 0.45,
          }}
        >
          <SvgChevronRight />
        </motion.div>
      )}
    </motion.button>
  );
}
