import type { SVGProps } from "react";
export const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.243.81c-.5-.533-1.09-.81-1.792-.81H7.549c-.702 0-1.293.277-1.792.81A2.7 2.7 0 0 0 5 2.729v18.544c0 .751.259 1.384.757 1.917.5.534 1.09.811 1.792.811h9.902c.702 0 1.293-.257 1.792-.81A2.7 2.7 0 0 0 20 21.271V2.728c0-.751-.259-1.384-.757-1.917M13.22 22.913a.95.95 0 0 1-.72.317.95.95 0 0 1-.721-.317 1.08 1.08 0 0 1-.296-.77c0-.297.093-.554.296-.772a.95.95 0 0 1 .72-.316c.277 0 .518.099.72.316.204.218.296.475.296.771s-.092.554-.295.771m5.246-3.815c0 .296-.11.553-.296.75a.94.94 0 0 1-.72.317H7.55c-.278 0-.518-.118-.72-.316a1.08 1.08 0 0 1-.296-.771V2.728c0-.296.11-.553.295-.77a.94.94 0 0 1 .72-.317h9.902c.277 0 .517.118.72.316.204.218.296.475.296.771z"
    />
  </svg>
);
