import React, { SetStateAction } from "react"
import CloseModal from "../CloseModal"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { PublicProfileRequests } from "../../api/public.service"

interface Props {
  setOpen: React.Dispatch<SetStateAction<boolean>>
  open: boolean
}

const ResponseTimeModal: React.FC<Props> = ({ setOpen, open }) => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const { renderSuccess, renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const [responseTime, setResponseTime] = React.useState<string>("")
  const responseOptions = [
    "1 day",
    "3 days",
    "7 days",
    "Out of Office",
    "Don't show response time",
  ]
  const handleSelectOption = (response: string) => {
    setResponseTime((prev: string) => (prev = response))
  }

  const getResponseTime = () => {
    setLoading(true)
    PublicProfileRequests.getCoachPublicProfile({
      endpoint_slug: coachPublicProfileData?.endpoint_slug,
    })
      .then((data) => {
        if (data.coach_public_profile) {
          setResponseTime(data.coach_public_profile?.response_time)
        }
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateResponseTime = () => {
    ProfileRequests.editResponseTime({
      coach_public_profile_id: parseInt(
        coachPublicProfileData?.coach_public_profile_id
      ),
      response_time: responseTime,
    })
      .then(() => {
        renderSuccess("Response time updated successfully!")
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  const handleApply = () => {
    updateResponseTime()
    setOpen(false)
  }

  React.useEffect(() => {
    getResponseTime()
  }, [])

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-full flex items-center
                        justify-center zoee-backdrop-filter z-[1009]"
      >
        <div
          className="w-[800px] max-w-[95%] main-shadow rounded-[16px] pt-[80px] pb-[40px]
                            max-h-[85vh] bg-white fixed left-[50%] -translate-x-[50%]  
                            flex flex-col items-start px-6 md:px-16"
        >
          <CloseModal
            callback={() => setOpen(false)}
            styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h1 className="font-bold text-[20px] lg:text-[25px]">
            Response Time
          </h1>

          <p className="mb-6 text-graySlate text-[14px] lg:text-[16px]">
            Let potential clients know how long it typically takes you to
            respond to an inquiry.
          </p>

          {/* CHECKBOXES FOR INPUT */}
          {loading ? (
            <div className="h-44 w-full flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div className="flex flex-col gap-4 mb-8 text-[14px] lg:text-[16px]">
              {responseOptions.map((option) => (
                <button
                  key={option}
                  className="flex gap-2 w-fit font-bold"
                  onClick={() => handleSelectOption(option)}
                >
                  {responseTime === option ? (
                    <div className="w-6 h-6 rounded-full bg-blurple"></div>
                  ) : (
                    <div className="w-6 h-6 border border-graySlate rounded-full"></div>
                  )}
                  {option}
                </button>
              ))}
            </div>
          )}
          <span className="flex justify-center w-full mt-3 sm:mt-7 gap-2">
            <Button onClick={() => setOpen(false)} variant={"ghost"} size="lg">
              Cancel
            </Button>
            <Button size="lg" onClick={() => handleApply()}>
              Save
            </Button>
          </span>
        </div>
      </div>
    </>
  )
}

export default ResponseTimeModal
