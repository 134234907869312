import React, { PropsWithChildren } from "react"
import ReactDOM from "react-dom"

interface BaseProps {
  className?: string
  [key: string]: unknown
}
// type OrNull<T> = T | null;

export const Button = ({
  className,
  active,
  reversed,
  ...props
}: PropsWithChildren<
  {
    active: boolean
    reversed?: boolean
  } & BaseProps
>) => {
  const ref = React.useRef<HTMLSpanElement>(null)

  const activeStyles = "bg-grayMist hover:opacity-80"

  return (
    <span
      {...props}
      ref={ref}
      className={`${className} ${
        active ? activeStyles : "hover:bg-hoverDark"
      }  rounded-[8px] p-[1px]`}
    />
  )
}

export const EditorValue = ({
  className,
  value,
  ...props
}: PropsWithChildren<
  {
    value: any
  } & BaseProps
>) => {
  const textLines = value.document.nodes
    .map((node: any) => node.text)
    .toArray()
    .join("\n")

  const ref = React.useRef<HTMLDivElement>(null)
  return (
    <div ref={ref} {...props} className="">
      <div className="">Slate's value as text</div>
      <div className="">{textLines}</div>
    </div>
  )
}

export const Icon = ({ className, ...props }: PropsWithChildren<BaseProps>) => {
  const ref = React.useRef<HTMLSpanElement>(null)

  return <span {...props} ref={ref} />
}

export const Instruction = ({
  className,
  ...props
}: PropsWithChildren<BaseProps>) => {
  const ref = React.useRef<HTMLDivElement>(null)
  return <div {...props} ref={ref} className="" />
}

export const Menu = ({ className, ...props }: PropsWithChildren<BaseProps>) => {
  const ref = React.useRef<HTMLDivElement>(null)
  return <div {...props} ref={ref} className="flex w-full" />
}

export const Portal = ({ children }: any) => {
  return typeof document === "object"
    ? ReactDOM.createPortal(children, document.body)
    : null
}

export const Toolbar = ({
  className,
  ...props
}: PropsWithChildren<BaseProps>) => {
  // const ref = React.useRef<HTMLDivElement>(null);
  return (
    <div
      className="sticky top-0 pt-[12px] bg-white flex z-[10] grow w-full max-w-full
            gap-[8px] pb-[6px] mb-[18px] border-b border-grayMist"
    >
      <Menu
        {...props}
        // ref={ref}
        className=""
      />
    </div>
  )
}
