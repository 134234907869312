import React from "react";
import ContactAvatar from "../../../cards/ContactAvatar";
import { SvgCheckbox, SvgCheckboxOff } from "../../../icons/";

type Props = {
    parentHandleSelect: any;
    contact: any;
    isSelected: boolean;
};

const MobileInviteContactsListItem: React.FC<Props> = ({
    parentHandleSelect,
    contact,
    isSelected,
}) => {
    const [selected, setSelected] = React.useState<boolean>(isSelected || false);

    const handleSelectContactClick = () => {
        setSelected(() => !selected);
        parentHandleSelect(contact.profile_id);
    };

    return (
        <div className="flex items-center justify-between py-[8px] min-w-[320px]">
            <div className="flex items-center w-[80%]">
                <ContactAvatar
                    contact={contact}
                    width={48}
                    height={48}
                    bold={false}
                    fontSize={"text-[18px]"}
                />
                <h3 className="font-bold ml-[8px] truncate text-base w-[80%]">{contact.contact_name}</h3>
            </div>
            {/* Hidden to avoid clogging mobile view, all contacts in this list are active; can reinstate later if needed */}
            {/* <div className="flex items-center">
                <ContactStatusTagMobile
                    contactStatus={getStatus(contact.contact_type)}
                    origin=""
                    contactDate={contact.contact_date}
                />
            </div> */}
            <div onClick={handleSelectContactClick} className="ml-[18px] cursor-pointer">
                {selected ? (
                     <SvgCheckbox />
                ) : (
                    <SvgCheckboxOff />
                )}
            </div>
        </div>
    );
};

export default MobileInviteContactsListItem;
