import landingDesktop from "../../../assets/images/why-zoee-landing-desktop-comp.webp";
import landingMobile from "../../../assets/images/why-zoee-landing-mobile-comp.webp";
import zoeeLogoOutline from "../../../assets/images/zoee-logo-outline-square.webp";
import WhoWeAre from "../../../components/marketing/whyZoee/WhoWeAre";
import LetterFromFounder from "../../../components/marketing/whyZoee/LetterFromFounder";
import JoinTeamZoeeButton from "../../../components/marketing/uiux/JoinTeamZoeeButton";
import useScrollToTop from "../../../hooks/useScrollToTop";

export default function WhyZoeePage() {
  useScrollToTop();

  return (
    <div className="relative mx-auto mt-[88px] max-w-screen-2xl overflow-hidden">
      <div>
        <img
          className="hidden max-h-[990px] min-h-[698px] object-cover sm:block"
          src={landingDesktop}
          alt="a group of excited coaches and Zoee team members"
        />
        <img
          className="min-h-[698px w-full object-cover sm:hidden"
          src={landingMobile}
          alt="a group of excited coaches and Zoee team members"
        />
      </div>

      <div className="mx-[24px] -translate-y-[320px] rounded-t-xl bg-white px-[12px] pb-[28px] pt-[42px] sm:mx-[80px] sm:px-[40px] md:mx-[60px] md:-translate-y-[140px] md:px-[40px] lg:mx-[120px] lg:px-[100px] lg:pt-[80px] xl:-translate-y-[174px]">
        <h2 className="mb-[24px] text-[58px] font-bold leading-[103%] md:text-[64px] xl:text-[88px]">
          ZOEE IS <br className="md:hidden" /> HERE TO
          <br />
          <div className="block ">
            <span className="text-blurple">UPLIFT</span> HUMANITY
          </div>
        </h2>

        <p className="mb-[24px] text-[18px]">
          Through tech that offers back-office solutions, Zoee is an all in one
          platform built by coaches, for coaches. In sum, we are in the business
          of helping you run wildly successful businesses by offering guidance,
          support, technology, and community. Zoee is a place for coaches to
          call home, but we aren&apos;t stopping there.
        </p>
        <p className="text-[18px]">
          In fact, we&apos;re just getting started. Zoee is excited to welcome
          members (those looking for coaches) to the platform later this year.
          At that time, we&apos;ll also be launching our Marketplace, where
          coaches and members can get matched with one another. This means we
          can all spend less time scrolling through coach profiles, and more
          time making meaningful connections in coaching calls.
        </p>
      </div>

      <WhoWeAre />
      <LetterFromFounder />
      <div className="gray-background-gradient text-grayCharcoal relative flex w-full flex-col items-center justify-center px-[24px] py-[180px] font-bold">
        <img
          className="absolute top-[-72px] z-0 hidden md:left-[140px] md:block xl:left-[242px]"
          src={zoeeLogoOutline}
          alt="zoee logo outline"
        />
        <div>
          <h4 className="mb-[16px] text-[40px] sm:text-[48px]">
            Want to join team Zoee?
          </h4>
          <p className="mb-[32px] max-w-[388px] text-[22px]">
            If you&apos;re interested in a career at Zoee, we&apos;d love to
            hear from you.
          </p>
          <JoinTeamZoeeButton />
        </div>
      </div>
    </div>
  );
}
