import type { SVGProps } from "react";
export const SvgTimeEvening = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#time-evening_svg__a)">
      <rect width={24} height={24} fill="#29284A" rx={12} />
      <path
        fill="#506B94"
        d="m10 13.5-10.5 5c-1.989 3.48-.046 7.893 3.864 8.776l5.74 1.296c1.253.283 2.548.33 3.818.137l11.385-1.725a4.266 4.266 0 0 0 3.594-3.689l.35-2.81A1.766 1.766 0 0 0 26.5 18.5l-.968-1.52C22.198 11.739 15.252 10.183 10 13.5"
      />
      <path
        fill="#6F8BB6"
        d="M3.406 19.255-7.5 21.5a20.3 20.3 0 0 0 12.212 8.241l4.544 1.026a17.7 17.7 0 0 0 6.532.235l10.84-1.643a3.17 3.17 0 0 0 2.67-2.739c.307-2.458-2.2-4.301-4.454-3.276l-.344.156.082-.99c.42-5.044-4.628-8.757-9.318-6.852a68 68 0 0 1-11.858 3.597"
      />
      <path
        fill="#2E2C58"
        d="M-3.567 22.618-8.5 25a14.52 14.52 0 0 0 9.983 8.077l4.232.956c2.832.639 5.758.744 8.628.309l10.684-1.619a11.64 11.64 0 0 0 9.674-9.23L35 22a10.49 10.49 0 0 1-10.223 2.131l-8.679-2.834a26.4 26.4 0 0 0-19.665 1.321"
      />
      <circle cx={14.5} cy={6.5} r={3.5} fill="#91B0DF" />
    </g>
    <defs>
      <clipPath id="time-evening_svg__a">
        <rect width={24} height={24} fill="#fff" rx={12} />
      </clipPath>
    </defs>
  </svg>
);
