import * as React from "react";
import landingDesktop from "../../../assets/images/contact-landing-desktop.webp";
import { useLocation } from "react-router-dom";
import { MarketingRequests } from "../../../api/public.service";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import { z } from "zod";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";

interface FormData {
  name: string;
  email: string;
  message: string;
}

export default function ContactPage() {
  const { pathname } = useLocation();
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx);

  useScrollToTop(pathname);

  const [contactFormData, setContactFormData] = React.useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = React.useState<FormData>({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmitClick = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setErrors({
      name: "",
      email: "",
      message: "",
    });

    const schema = z.object({
      name: z.string().min(1, "Required"),
      email: z.string().email(),
      message: z.string().min(1, "Required"),
    });

    const result = schema.safeParse(contactFormData);

    if (!result.success) {
      setErrors({
        name: result.error.format().name?._errors[0] ?? "",
        email: result.error.format().email?._errors[0] ?? "",
        message: result.error.format().message?._errors[0] ?? "",
      });
      return;
    }

    MarketingRequests.submitContactForm({
      name: result.data.name,
      email: result.data.email,
      message: result.data.message,
    })
      .then(() => {
        renderSuccess("Your message has been sent!");
        setContactFormData({
          name: "",
          email: "",
          message: "",
        });
      })
      .catch((err) => {
        console.error(err);
        renderError("Something went wrong.");
      });
  };

  return (
    <div
      className="relative mx-auto mt-[88px] max-w-screen-2xl overflow-hidden bg-cover"
      style={{
        backgroundImage: `url(${landingDesktop})`,
      }}
    >
      <div className="relative z-10 mb-[96px] flex min-w-full max-w-[600px] flex-col px-[24px] pt-[56px] md:px-[40px] lg:flex-row lg:gap-[48px] xl:px-[80px] ">
        <div className="md:pb-[32px] lg:pt-[64px]">
          <h2 className="mb-[32px] text-[54px] font-bold leading-[110%] md:mb-[16px] xl:text-[88px]">
            CONTACT US
          </h2>
          <div className="hidden md:block">
            <h5 className="text-[24px] font-bold">hello@zoee.com</h5>
            <h5 className="text-[24px] font-bold">877-FOR-ZOEE</h5>
            <h5 className="text-[18px] font-bold">(877-367-9633)</h5>
          </div>
        </div>
        <div className="relative mx-auto md:min-w-[622px]">
          <form className="flex flex-col gap-6 rounded-xl bg-white px-[24px] pb-[60px] pt-[56px] shadow-md md:px-[64px] ">
            <Input
              label="Name"
              placeholder="Your name"
              onChange={(e) =>
                setContactFormData({
                  ...contactFormData,
                  name: e.target.value,
                })}
              value={contactFormData.name}
              id="name"
              type="text"
              error={errors.name}
              required
            />
            <Input
              label="Email"
              placeholder="Your email"
              onChange={(e) =>
                setContactFormData({
                  ...contactFormData,
                  email: e.target.value,
                })}
              value={contactFormData.email}
              id="email"
              type="email"
              error={errors.email}
              required
            />
            <Textarea
              onChange={(e) =>
                setContactFormData({
                  ...contactFormData,
                  message: e.target.value,
                })}
              value={contactFormData.message}
              id="message"
              placeholder="Your message"
              required
              error={errors.message}
              rows={10}
            />
            <Button
              onClick={handleSubmitClick}
              className="w-full"
              size="lg"
              type="button"
            >
              Send
            </Button>
            <p className="text-graySlate text-base">
              Zoee does not share any personal information. By submitting your
              email address, you may also receive email offers and updates on
              Zoee products and services. You may unsubscribe at any time.
            </p>
          </form>
        </div>
        <div className="mt-[64px] md:hidden">
          <h5 className="text-[24px] font-semibold">hello@zoee.com</h5>
          <h5 className="text-[24px] font-bold">877-FOR-ZOEE</h5>
          <h5 className="text-[18px] font-bold">(877-367-9633)</h5>
        </div>
      </div>
    </div>
  );
}
