import type { SVGProps } from "react";
export const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.75 12.776c0-6.957 2.319-9.275 9.274-9.275 6.956 0 9.275 2.318 9.275 9.275 0 6.956-2.32 9.273-9.275 9.273S2.75 19.732 2.75 12.776"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.025 9.324h18.008M16.033 2.05v3.262M8.025 2.05v3.262"
    />
  </svg>
);
