import type { SVGProps } from "react";
export const SvgArticle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
    >
      <path d="M15.595 15.696h-7.22M15.595 11.937h-7.22M11.13 8.177H8.375" />
      <path
        d="M3.61 12c0 6.937 2.097 9.25 8.39 9.25s8.391-2.313 8.391-9.25-2.097-9.25-8.39-9.25S3.608 5.063 3.608 12"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
