import type { SVGProps } from "react";
export const SvgComingSoon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 54 46"
    {...props}
  >
    <g clipPath="url(#soon_svg__a)">
      <path
        fill="currentColor"
        d="M40.148 15.324V2.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 37.223 0H2.925A2.97 2.97 0 0 0 .842.894 2.96 2.96 0 0 0 0 2.995v40.01c-.009.784.294 1.54.842 2.1A2.97 2.97 0 0 0 2.925 46h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L53.879 18.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
      />
      <g fill="#fff" filter="url(#soon_svg__b)">
        <path d="M17.832 21.134a1.25 1.25 0 1 1-2.499 0 1.25 1.25 0 0 1 2.499 0M24.702 21.134a1.25 1.25 0 1 1-2.498 0 1.25 1.25 0 0 1 2.498 0M19.865 27.38a2.5 2.5 0 0 0 2.65-2.498v-.313a.936.936 0 0 0-.936-.937h-3.123a.936.936 0 0 0-.936.937v.216a2.564 2.564 0 0 0 2.345 2.594M26.288 31.48a.55.55 0 0 1 .247.246l.484.962a.55.55 0 0 0 .987 0l.483-.971a.55.55 0 0 1 .247-.247l.962-.475a.55.55 0 0 0 0-.986l-.97-.484h-.001a.55.55 0 0 1-.247-.247l-.474-.962a.55.55 0 0 0-.987 0l-.484.968a.55.55 0 0 1-.247.247l-.962.478a.55.55 0 0 0 0 .986z" />
        <path d="M29.651 18.667a6.42 6.42 0 0 0-5.308-5.308 26.4 26.4 0 0 0-8.675 0 6.42 6.42 0 0 0-5.309 5.308 26.4 26.4 0 0 0 0 8.678 6.42 6.42 0 0 0 5.309 5.308 26.5 26.5 0 0 0 5.998.29 1.253 1.253 0 0 0-.18-2.497q-.735.056-1.469.056c-1.315.004-2.63-.1-3.928-.312a3.935 3.935 0 0 1-3.254-3.254 23.9 23.9 0 0 1 0-7.857 3.93 3.93 0 0 1 3.254-3.254c2.6-.433 5.254-.433 7.854 0A3.93 3.93 0 0 1 27.2 19.08a24 24 0 0 1 .312 3.928q0 .735-.047 1.471a1.25 1.25 0 0 0 1.25 1.34 1.25 1.25 0 0 0 1.248-1.162q.047-.825.047-1.649c0-1.454-.12-2.905-.36-4.34z" />
      </g>
    </g>
    <defs>
      <clipPath id="soon_svg__a">
        <path fill="#fff" d="M0 0h54v46H0z" />
      </clipPath>
      <filter
        id="soon_svg__b"
        width={100.01}
        height={100}
        x={-30}
        y={-23}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={20} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2197_7663"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2197_7663"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
