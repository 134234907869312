import { useContext } from "react";
import { LoadingContext } from "../context/loadingContext";

function useLoading() {
  const { startLoading, stopLoading } = useContext(LoadingContext);

  return { startLoading, stopLoading };
}

export default useLoading;
