import type { SVGProps } from "react";
export const SvgGoogle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.88 9c0-.585.096-1.145.27-1.67L1.117 5.012A9 9 0 0 0 .192 9c0 1.433.333 2.785.923 3.984l3.032-2.32A5.3 5.3 0 0 1 3.879 9"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.205 3.682c1.27 0 2.417.45 3.318 1.186l2.622-2.618C13.547.86 11.499 0 9.205 0a8.99 8.99 0 0 0-8.089 5.013L4.15 7.329a5.31 5.31 0 0 1 5.056-3.647M9.205 14.318a5.31 5.31 0 0 1-5.056-3.647l-3.033 2.316A8.99 8.99 0 0 0 9.205 18c2.198 0 4.297-.78 5.872-2.243l-2.88-2.226c-.811.512-1.835.787-2.992.787M17.808 9c0-.532-.082-1.105-.205-1.636H9.205v3.477h4.834c-.242 1.186-.9 2.097-1.84 2.69l2.878 2.226c1.655-1.536 2.731-3.823 2.731-6.757"
      clipRule="evenodd"
    />
  </svg>
);
