import previewImage from "../../../assets/blog/coachesCornerJessieCruickshank.webp";
import { Post } from "../../../types/postTypes";
 
export const coachesCornerJessieCruickshank: Post = {
   metadata: {
       id: 13,
       title: "Life Has Many Seasons, Here’s How a Coach Can Help",
       subtitle: "Coaches Corner: An Interview with Jessie Cruickshank",
       imgTag: "Coach's Corner",
       author: "Zoee",
       date: "December 15, 2022",
       urlSlug: "coaches-corner-jessie-cruickshank",
       description:
           "In three acts, Jessie shares her story of becoming a coach, her philosophy to helping executives grow, and how the seasons of life shape who we are truly meant to become.",
       previewImg: previewImage,
       tags: ["Coach’s Corner", "Executive Coaching", "Life Coaching"]
   },
   content: [
       {
           type: "paragraph",
           text: "Somewhere along the path of adulthood, we realize life has seasons. This is more than the traditional winter, spring, summer, and autumn temperature changes—life seasons are sometimes years or even a decade long. They can be defined by a place where we lived, a job we had, a partner we were connected to, or a mindset we were in. If we’re lucky enough to live a long life, we can look back and identify the many seasons that define us.",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "Seasons aren’t good or bad, they just “"
               },
               {
                   type: "italic",
                   text: "are"
               },
               {
                   type: "normal",
                   text: ".” And for people like Jessie Cruickshank, our life seasons can sometimes build on one another. Looking behind us, we can see how all the dots connect and all the experiences stack up like rings of a tree trunk. Nothing is ever erased, but we grow from what we learned in the previous, or current, season."
               }
           ]
       },
       {
           type: "paragraph",
           text: "Serving as an outdoor guide in the untamed lands of Wyoming is Jessie’s Act I. This is where she got her footing as a coach, helping other people learn tactile skills for rock climbing and whitewater rafting. “As a guide, you help people because their life depends on it. And in the process, you begin to understand who they are and why they are doing these arduous outdoor obstacles,” she says.",
       },
       {
           type: "paragraph",
           text: "Back then, Jessie coached people in a very skill-oriented fashion. It’s arguable that she’s still helping people learn skills today, though it’s a lot less about where you put your feet in a raging river, and more about where you put your feet in the boardroom.",
       },
       {
           type: "paragraph",
           text: "Her Act II was grounded in helping others develop character, mostly through faith-based organizations where people were looking to become better listeners and better leaders. As you can imagine, listening is a critical skill when you’re hanging off the side of a mountain. Translating that to a professional setting was a change in season for Jessie.",
       },
       {
           type: "paragraph",
           text: "Now, in Act III, she’s an executive coach, a team development coach, and an organizational psychologist. In fact, her favorite coaching method is working with an individual one on one, then observing them in a team setting. Whether her client is running a meeting and Jessie is observing, or Jessie is guiding the organization through a development exercise and viewing interactions, valuable information is gained when she sees a client outside of a coaching session and in their day-to-day environment.",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "normal",
                   text: "Since the COVID-19 pandemic, Jessie’s coaching practice has turned toward helping people become more holistic—reconciling past experiences with themselves and healing in healthy ways to help them progress in the present. Teaching competencies, skills, and character building are still there, but now she’s encouraging people to hold space for themselves, creating acceptance that’s critical to interpersonal work. It’s this self-acceptance and"
               },
               {
                   type: "italic",
                   text: "emotional"
               },
               {
                   type: "normal",
                   text: "“climbing” that guides people to unlock new seasons of their own, and though she may not be wearing outdoor gear, she’s there to coach them every step of the way."
               }
           ]
       },
       {
           type: "paragraph",
           text: "Here’s a window into our conversation with Jessie:",
       },
       {
           type: "header2",
           text: "What’s the impact of good coaching?",
       },
       {
           type: "paragraph",
           text: "“The impact of good coaching amplifies far beyond the person you’re working with. I’m always focused on helping the person sitting across from me in the moment, but the ripple effect is always much louder than I anticipate. Little changes can be so profound, they end up changing a person’s whole life. That never fails to surprise me, when they actually live out what we discuss in a session.”",
       },
       {
           type: "header2",
           text: "When working with clients, what is the number one piece of advice you find yourself giving over and over?",
       },
       {
           type: "paragraph",
           text: "“People usually come to me when they are burnt out and feeling desperate for change, or they want to understand themselves a little better (they are often suspicious there’s something about themselves they don’t see but want to). In both instances, I often find myself asking, ‘How can you parent yourself more kindly?’ Re-parenting work is common with clients these days. It seems easier to blame other people (e.g., our parents) for things, but if we really want to find change, we have to think about how we’re talking to ourselves in the present moment. Are we speaking unkindly? If so, it’s all about making little choices along the way.”",
       },
       {
           type: "header2",
           text: "What have you learned about people from being a coach?",
       },
       {
           type: "paragraph",
           text: "“People are beautiful and sincere—rarely do I see evil or true darkness in the people that I work with. They truly want to be good and gracious human beings, but just don’t always behave in a way that aligns with that desire. In short, I love humanity! And everyone is trying, they just need some patience, tools, or someone to listen so they can work through whatever they are going through. Kindness goes a long way.”",
       },
       {
           type: "header2",
           text: "How do you think the last two years have affected our health?",
       },
       {
           type: "paragraph",
           text: "“All the cracks that existed before, and all the noise we fed on in our life, became gaping holes. The last two years have fractured us, our social fabric, our economy, psyches, and souls. Where there were cracks, there are now canyons. Coaching is so critical because it has the power to repair the canyons. Buying things will not fix this, consumerism will not fix this. Only another human being can help you know what you almost know. Humans are a herd species; we need each other to live. And coaching can provide that from a person (a coach) with experience and a particular expertise to help you move in a strategic direction.”",
       },
       {
           type: "header2",
           text: "How do you see coaching fitting into the mental health crisis?",
       },
       {
           type: "paragraph",
           text: "“Coaching is one of the most accessible and pragmatic solutions for what we are facing. This is a holistic field, and a holistic space. Most coaching takes into account the mind, body, soul, and spirit, instead of seeing them as compartmentalized. Coaching is also a growing industry because there’s a great need—it’s our future.”",
       },
       {
           type: "paragraph",
           text: "We also asked Jessie a series of “fun questions” to help humanize the coaches you’re reading about in this series. Here are her responses:",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "bold",
                   text: "Favorite part about being a coach:",
               },
               {
                   type: "normal",
                   text: "Meeting cool people and learning their stories.",
               },
           ],
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "bold",
                   text: "Favorite product that you feel ‘saves your life’ day to day:",
               },
               {
                   type: "normal",
                   text: "Coffee in various forms and marking the end of a week with a cigar on the sabbath. It enhances my life!",
               },
           ],
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "bold",
                   text: "If you could eat one food for the rest of your life, what would it be?",
               },
               {
                   type: "normal",
                   text: "Cheese.",
               },
           ],
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "bold",
                   text: "Favorite day of the week:",
               },
               {
                   type: "normal",
                   text: "Thursday. It’s a day I can either get stuff done or whiff it. It’s a freebie day, an “anything I need it to be” day.",
               },
           ],
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "bold",
                   text: "Last song you listened to:",
               },
               {
                   type: "normal",
                   text: "“Dog Days” by Florence and the Machine.",
               },
           ],
       },
       { 
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "Jessie Cruickshank is an executive coach, a team development coach, and an organizational psychologist. She holds a master’s degree in neuroscience and psychology from Harvard University.",
               },
           ],
       },
       {     
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "Ready to simplify your back-office? Join Zoee today. Interested in finding a coach? Sign up for the member waitlist.",
               },
           ],
       },
       {        
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "This interview was conducted and written by Valerie Lane."
               },
           ]
       },
   ],
};
