import type { SVGProps } from "react";
export const SvgCredentials = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 26 27"
    {...props}
  >
    <path
      fill="currentColor" d="M.293 1.334C.293.758.759.292 1.335.292h23.333c.575 0 1.042.466 1.042 1.042v13.333c0 .575-.467 1.042-1.042 1.042H1.335a1.04 1.04 0 0 1-1.042-1.042zm2.083 1.041v11.25h21.25V2.375zM9.876 20.917c0-.576.467-1.042 1.042-1.042h12.5a1.042 1.042 0 0 1 0 2.083h-12.5a1.04 1.04 0 0 1-1.042-1.041m-8.333 0c0-.576.466-1.042 1.042-1.042H6.75a1.042 1.042 0 0 1 0 2.083H2.585a1.04 1.04 0 0 1-1.042-1.041M9.876 25.917c0-.576.467-1.042 1.042-1.042h12.5a1.042 1.042 0 0 1 0 2.083h-12.5a1.04 1.04 0 0 1-1.042-1.041m-8.333 0c0-.576.466-1.042 1.042-1.042H6.75a1.042 1.042 0 0 1 0 2.083H2.585a1.04 1.04 0 0 1-1.042-1.041"
      clipRule="evenodd"
    />
  </svg>
);
