import React from "react";
import { getUserColor } from "../../utils/getUserColor";
import { getTextColor } from "../../utils/getTextColor";

interface Props {
  contact: any;
  width: number;
  height: number;
  border: string;
  isNotBold?: boolean;
  fontSize?: string | null;
}

const ContactAvatar: React.FC<Props> = ({
  contact,
  width,
  height,
  border,
  isNotBold,
  fontSize,
}) => {
  const { contact_name, has_avatar, user_color, email } = contact || {};

  // TODO: Noticed we have 2 ContactAvatars. Post-launch let's go back and consolidate into one
  let bold = isNotBold ? "" : "font-bold";
  const dynamicStyle = {
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: "10%",
    border: border,
  };

  return (
    <div style={dynamicStyle}>
      {has_avatar
        ? (
          <img
            src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${contact?.user_id}?t=${new Date().getTime()}`}
            alt="avatar"
            className="h-full w-full rounded-full object-cover"
          />
        )
        : (
          <div
            className={`flex h-full w-full items-center justify-center rounded-full ${
              getUserColor(user_color)
            }`}
          >
            <h1
              className={`${bold} ${fontSize ? fontSize : "text-[22px]"}`}
              style={{ color: getTextColor(user_color) }}
            >
              {contact_name?.charAt(0).toUpperCase() ||
                email?.charAt(0)?.toUpperCase()}
            </h1>
          </div>
        )}
    </div>
  );
};

export default ContactAvatar;
