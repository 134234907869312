import type { SVGProps } from "react";
export const SvgBank2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M2 45.047h43.615v-6.833c0-.776-.34-1.454-.969-2.084-.581-.581-1.212-.824-2.084-.824h-2.374V19.363h5.427v-6.64L23.808 4 2 12.723v6.64h5.428v16.04H5.053c-.872 0-1.551.242-2.084.824-.63.63-.969 1.357-.969 2.083zm40.417-3.102H5.102v-3.489h37.315zm-5.282-6.64h-6.882V19.363h6.882zm-9.935 0h-6.736V19.363H27.2zm-9.838 0h-6.881V19.363h6.881zM42.417 16.31H5.102v-1.502l18.706-7.415 18.609 7.366z"
    />
  </svg>
);
