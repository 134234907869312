import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { SessionContext } from "./context/sessionContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSNotifications,
  useHMSStore,
} from "@100mslive/react-sdk";
import Conference from "../../components/sessions/Conference";
import useLoading from "../../hooks/useLoading";

export function QuickSession() {
  const { room_code } = useParams();
  const navigate = useNavigate();

  const hmsActions = useHMSActions();

  const config = {
    userName: "Guest",
    authToken: "",
    settings: {
      isAudioMuted: true,
      isVideoMuted: false,
    },
    // metaData: JSON.stringify({ city: "Winterfell", knowledge: "nothing" }),
    rememberDeviceSelection: true, // remember manual device change
  };

  async function joinRoom(roomCode: string) {
    try {
      const authToken = await hmsActions.getAuthTokenByRoomCode({
        roomCode,
      });
      await hmsActions.join({ ...config, authToken });
    } catch (e) {
      console.error(e);
      navigate("/");
    }
  }

  const { setPopupNotification, renderSuccess, renderError } = React
    .useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const [viewType, setViewType] = React.useState("speaker");
  const [mobileView, setMobileView] = React.useState(false);
  const [fullScreen, setFullScreen] = React.useState(false);
  const [currentSpeaker, setCurrentSpeaker] = React.useState<any>(null);
  const [inWaitingRoom, setInWaitingRoom] = React.useState<boolean>(false);
  const [roomId, setRoomId] = React.useState<string>("");
  const [sessionListLoading, setSessionListLoading] = React.useState<boolean>(
    false,
  );
  const [sendReview, setSendReview] = React.useState<boolean>(
    false,
  );
  const [roomCode, setRoomCode] = React.useState<string>("");
  
  const [msUserToken, setMsUserToken] = React.useState<string>("");
  const notification = useHMSNotifications();

  const closePopup = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    });
  };

  const acceptAutoplay = async () => {
    startLoading();
    await hmsActions
      .unblockAudio()
      .then(() => {
        stopLoading();
        setPopupNotification({
          show: false,
          title: "",
          message: "",
          callback: null,
        });
        renderSuccess("Unblocked audio");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showMessage = (type: string, message: string, code: any) => {
    if (type === "error") {
      console.log(`100ms error code ${code}`);
      if (code === 3001) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        });
      } else if (code === 3002) {
        setPopupNotification({
          show: true,
          title: `Capture Device Not Available`,
          message:
            "Please verify that your audio/video capture device is connected.",
        });
      } else if (code === 3003) {
        setPopupNotification({
          show: true,
          title: `Capture Device Is In Use`,
          message:
            "Capture device is in use by another application. Please fix and try again.",
        });
      } else if (code === 3008) {
        setPopupNotification({
          show: true,
          title: "Autoplay blocked",
          message:
            "The browser is requiring this specific page interaction to enable audio. Click 'Ok' to proceed",
          callback: acceptAutoplay,
        });
      } else if (code === 3009) {
      } else if (code === 3010) {
        setPopupNotification({
          show: true,
          title: `Problem with audio output device`,
          message: "Try using an external device to output audio.",
        });
      } else if (code === 3011) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        });
      } else {
        renderError(message);
      }
    } else if (type === "notify") {
      renderSuccess(message);
    }
  };

  const sessionContextValues = {
    viewType,
    setViewType,
    mobileView,
    setMobileView,
    currentSpeaker,
    setCurrentSpeaker,
    fullScreen,
    setFullScreen,
    inWaitingRoom,
    setInWaitingRoom,
    roomId,
    setRoomId,
    msUserToken,
    setMsUserToken,
    sessionListLoading,
    setSessionListLoading,
    roomCode,
    setRoomCode,
    sendReview,
    setSendReview
  };

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setMobileView(true);
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    window.addEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  React.useEffect(() => {
    if (!notification) {
      return;
    }
    switch (notification.type) {
      case "ROOM_ENDED":
        setPopupNotification({
          show: true,
          callback: closePopup,
          title: notification.data.reason,
          message:
            "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
        });
        break;
      case "PEER_JOINED":
        showMessage("notify", `${notification.data.name} joined`, null);
        break;
      case "PEER_LEFT":
        showMessage("notify", `${notification.data.name} left`, null);
        break;
      case "ERROR":
        showMessage("error", notification.data.message, notification.data.code);
        break;
    }
  }, [notification]);

  React.useEffect(() => {
    if (!room_code) {
      return;
    } else {
      joinRoom(room_code);
    }
  }, []);

  return (
    <SessionContext.Provider value={sessionContextValues}>
      <div className="w-full h-screen flex items-center justify-center">
        {isConnected && <Conference />}
      </div>
    </SessionContext.Provider>
  );
}
