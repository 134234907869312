import React from "react";
import { cn } from "../../utils/cn";
import { SvgShield } from "../icons/";

interface Props {
  password: string;
}

export default function SecurePassword(props: Props) {
  const [securityBars, setSecurityBars] = React.useState<number>(1);
  const [securityText, setSecurityText] = React.useState<string>("Weak");

  const checkPwdStrength = (password: string) => {
    var strongPattern =
      /^(?=.*[0-9].*[0-9])(?=.*[A-Z].*[A-Z])(?=.*[a-z].*[a-z])(?=.*[!@#$%^&*].*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/;
    var goodPattern =
      /^(?=.*[0-9].*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/;
    var okayPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/;

    if (password.match(strongPattern)) {
      setSecurityBars(4);
      setSecurityText("Strong");
    } else if (password.match(goodPattern)) {
      setSecurityBars(3);
      setSecurityText("Good");
    } else if (password.match(okayPattern)) {
      setSecurityBars(2);
      setSecurityText("Okay");
    } else if (password.length >= 1) {
      setSecurityBars(1);
      setSecurityText("Weak");
    } else if (password.length === 0) {
      setSecurityBars(1);
      setSecurityText("Weak");
    }
  };

  React.useEffect(() => {
    checkPwdStrength(props.password);
  }, [props.password]);

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="grid grid-cols-4 gap-2">
        {[...Array(4)].map((_, i) => (
          <div
            key={i}
            className={cn("bg-grayCloud h-1 rounded-full transition-colors", {
              "bg-destructive": securityBars >= 1,
              "bg-orange": securityBars >= 2,
              "bg-green": securityBars >= 3,
              "bg-black": props.password.length === 0,
              "bg-grayCloud": securityBars < i + 1,
            })}
          />
        ))}
      </div>

      <div className="flex items-center gap-2">
        <SvgShield />
        <p className="text-graySlate text-base">
          Security level: <span className="text-black">{securityText}</span>
        </p>
      </div>
    </div>
  );
};
