import previewImage from "../../../assets/blog/questionAndAnswer.webp";
import { Post } from "../../../types/postTypes";
 
export const QAWithNicholeLowe: Post = {
   metadata: {
       id: 5,
       title: "Q&A With Nichole Lowe, Founder & CEO of Zoee",
       author: "Zoee",
       date: "November 10, 2020",
       urlSlug: "q-and-a-with-founder-and-ceo",
       description:
           "When Zoee was just getting accepted into Techstars, Founder & CEO Nichole Lowe sat down for an interview to discuss what Zoee means to her, and where she sees the company's future.",
       previewImg: previewImage,
       tags: ["Coach", "Business"]
   },
   content: [
       {
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "October 2022 update: This interview was conducted in 2020 as Zoee was being accepted into Techstars Iowa Accelerator Program. Since the writing of this article, Zoee has launched their most recent technology—an all-inclusive virtual office—for coaches."
               }
           ]
       },
       {
           type: "header2",
           text: "Tell us about your big news with Techstars.",
       },
       {
           type: "paragraph",
           text: "Techstars is an amazing opportunity. I am both humbled and excited that Zoee was accepted into the Techstars Iowa Accelerator program. This program will help us reach our goals more quickly to better serve the coaching industry. Did you know that it’s harder to get accepted into Techstars than MIT?!",
       },
       {
 
           type: "paragraph",
           text: "The program that Kerty Levy and her team put together is top-notch. We’ve been through intensive workshops, two weeks of Mentor Madness, and now we’ve been paired with incredible mentors. The experience has been phenomenal for our team, and we've put things we’ve learned into action.",
       },
       {
           type: "header2",
           text: "You're passionate about coaching. How did you make your way to the coaching field?",
       },
       {
           type: "paragraph",
           text: "I began my career in the entertainment space, and founded a production company in my early 20’s while living in Minneapolis, Minnesota. It was an exciting time! I had the pleasure of working with a popular, home and gardening TV network, as well as Prince (The Artist).",
       },
       {
           type: "paragraph",
           text: "I left the film industry after a near death experience on 9/11. I was working for the BBC on a show called Banzai, and my crew and I were scheduled to fly to New York City to shoot a scene on top of the North Tower of the World Trade Center. Our call time was 6 a.m., but the executive producer canceled the shoot just two days prior. Like so many of us, this tragic and horrific event had an impact on my life—there was life before the terrorist attacks and there was life after.",
       },
       {
           type: "paragraph",
           text: "I closed my production company and took a sabbatical. I traveled, fell in love, and found my faith. In 2003, I pursued a corporate career which turned into a 10 year sales career. I learned about the sales process, leading others, dealing with rejection, and listening to people. I loved working with my customers and developing relationships. This period of time laid the groundwork for my coaching career.",
       },
       {
           type: "paragraph",
           text: "In 2013, I left my corporate job and started a coaching business. It was liberating and exciting. I was back doing what I love—being an entrepreneur, but with a stronger mission and purpose. It was a great blessing and honor to spend time with clients, listening to their stories, encouraging them, fighting with them and for them, and finding solutions to ignite their transformation.",
       },
       {
           type: "paragraph",
           text: "Then I had the opportunity to be a mentor coach for over 500 coaches with the Institute for Integrative Nutrition. I found that my background in business, sales, and marketing was an asset when helping newly certified coaches set up and start their business.",
       },
       {
           type: "header2",
           text: "What did you learn from mentoring 500+ coaches?",
       },
       {
           type: "paragraph",
           text: "Wow, I learned a lot! I learned that coaches have a heart for serving, and that they want to make a big difference in the world. Coaches love spending time with their clients, seeing their clients transform, and they enjoy honing their craft. I also learned that building a coaching business is hard and has many challenges.",
       },
       {
           type: "header2",
           text: "Tell us more about those challenges coaches face.",
       },
       {
           type: "paragraph",
           text: "There are a lot of roadblocks and challenges that get in the way of growing your coaching practice. You end up stretching yourself thin by being the jack of all trades.",
       },
       {
           type: "paragraph",
           text: "When I first started out, I had to learn how to build my website, find payment/invoice systems, figure out my pricing model, create contracts, set up marketing tools, figure out an accounting system, organize workflows, and then take the remaining time to network and find new clients. If a coach doesn't know how to do these things, it can take a lot of time and money to get there.",
       },
       {
           type: "paragraph",
           text: "Other industries, like real estate, are set up to be much more supportive of entrepreneurs. Most of their back-end systems and platforms are ready and available so you can focus on your true business—serving clients.",
       },
       {
           type: "paragraph",
           text: "That’s why I wasn’t too surprised when the International Coaching Federation released research noting that nearly 82% of coaches go out of business within their first two years. This should NOT be happening. And I wanted to change that.",
       },
       {
           type: "header2",
           text: "Is that why you decided to create Zoee?",
       },
       {
           type: "paragraph",
           text: "I created Zoee, because I believe in the power of coaching. I’ve experienced it firsthand as a coach and as someone who received coaching. It’s a powerful catalyst for human transformation.",
       },
       {
           type: "paragraph",
           text: "And consumers are seeking coaching now more than ever! Research shows that one out of two U.S. consumers are thinking about hiring a coach, but there are not enough coaches to meet this demand. Globally, there are just under seven coaches per 1 million population.",
       },
       {
           type: "paragraph",
           text: "Wouldn’t it be wonderful if everyone had access to quality coaching, and wouldn’t it be wonderful if coaches were fully supported?",
       },
       {
           type: "paragraph",
           text: "My dream, Zoee’s dream, is to raise the economic and social standards of our society through coaching. We can do this by leveraging the best of humanity and technology.",
       },
       {
           type: "header2",
           text: "What makes the Zoee platform unique?",
       },
       {
           type: "paragraph",
           text: "Zoee is democratizing the coaching industry through technology. We give our coaches a superpower by giving them their time back and helping them increase their revenue. Additionally, we help people who are looking for coaches get matched with the right coach!",
       },
       {
           type: "header2",
           text: "What kind of tools does Zoee provide?",
       },
       {
           type: "paragraph",
           text: "With Zoee, coaches have access to tele-conferencing, scheduling, billing, custom workflow tools and evidence-based assessments all in one platform.",
       },
       {
           type: "header2",
           text: "Is Zoee right for only a subset of coaches?",
       },
       {
           type: "paragraph",
           text: "Zoee is ideal for independent, certified coaches and their clients. This includes coaches representing most coaching segments, including life, career, relationships, financial, leadership, executive, health, and so on.",
       },
       {
           type: "header2",
           text: "How is Zoee supporting coaches and clients in the era of social distancing?",
       },
       {
           type: "paragraph",
           text: "Zoee is based on the tele-coaching model which is ideal for social distancing or for coaches who live across the country and world from their clients. We’ve taken all precautions to create a secure, stable and private platform that is well suited for this new era we find ourselves in.",
       },
       {
           type: "header2",
           text: "Do coaching clients have to pay to use Zoee?",
       },
       {
           type: "paragraph",
           text: "No, Zoee is free for coaching clients. Clients engage directly with their coach, and the coach determines his or her own fees. Coaches pay a subscription fee to use Zoee, and Zoee takes a small percentage of the client billing for its invoicing services.",
       },
       {
           type: "header2",
           text: "Why should Coaches get excited about Zoee?",
       },
       {
           type: "paragraph",
           text: "I’m super excited for the future of Zoee! Our roadmap includes consumer-to coach matching and on-demand coaching. Prospective clients will be able to enter their ideal coach criteria, and our AI-assisted technology will carefully serve up a handful of coaches that align with the client’s unique wants and needs. Coaching clients will also be able to get on the spot coaching when needed.",
       },
       {
type: "paragraph",
           text: "Our product team’s entire focus is to listen to the needs of coaches and their clients so we are always enhancing the coaching experience. All we want is for coaches and their clients to succeed.",
       },
       {
type: "paragraph",
           text: "And more importantly, we are building a community of coaches where we can all learn from each other and help each other become better at what we are called to do.",
       },
       {
type: "paragraph",
           text: "If you want to be the first to know what we have in store, I invite coaches to join our waitlist to get access to our platform. It’s a great time to be a coach!",
       },
       {
           type: "header2",
           text: "As an entrepreneur and coach, who are some of your greatest teachers or influencers?",
       },
       {
           type: "paragraph",
           text: "My clients. The greatest lessons I've learned have come from everyday people facing everyday challenges. I am in awe of their resilience, courage, and spirit.",
       },
       {
           type: "header2",
           text: "You chose the Raleigh, North Carolina area for Zoee. Why Raleigh?",
       },
       {
           type: "paragraph",
           text: "Not only is Raleigh a beautiful place to live—lots of trees, the water and mountains are nearby—but Raleigh is also a robust tech hub full of highly creative and educated people. I love its vibe, community, and energy. It’s a perfect place to live and work!",
       },
       {
           type: "header2",
           text: "We noticed you do a lot of volunteer work at UNC. Share a little about that.",
       },
       {
           type: "paragraph",
           text: "One of my passions is giving back to my community. I volunteer as a mentor and coach for the Econ-125 entrepreneurial undergraduate course and the Kenan Flagler business course at the University of North Carolina. It’s wonderful to serve amazing, young people who are just starting out in life, and play a small part in the development of a young mind.",
       },
       {
           type: "header2",
           text: "When you do find free time, where and how do you spend it?",
       },
       {
           type: "paragraph",
           text: "I enjoy playing music really loud and singing when no one is around! I also love spending time with my husband exploring new places and restaurants, traveling, and spending time with good friends during our fireside nights.",
       },
       {
           type: "header2",
           text: "What advice would you give to coaches now?",
       },
       {
           type: "paragraph",
           text: "Keep doing what you’re doing. We need you now more than ever—helping us through our journeys, to be an advocate, a cheerleader, someone to walk beside us as we are reborn over and over to new levels of ourselves.",
       },
       {
           type: "paragraph",
           text: "I saw a cute Peanuts quote the other day. Charlie Brown is sitting next to Snoopy looking up at the stars. Charlie Brown says, “We only live once, Snoopy,” and Snoopy replies, “Wrong, we only die once, we live every day!”",
       },
       {
           type: "paragraph",
           text: "The world is a better place, because you’re in it. Onward!",
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office."
               },
           ]
       },
       {
           type: "rich-text",
           items: [
               {
                   type: "italic",
                   text: "This interview was conducted and written by the Zoee team."
               },
           ]
       },
   ],
};
