import React from "react";
import { SvgPolygon } from "./icons";

interface Props {
  message: string;
}

// To use:
// There must be a 'relative' parent element.
// The element that will produce the Tooltip when hovered must have the 'peer' className.

const Tooltip: React.FC<Props> = (props: any) => {
  return (
    <div className="absolute justify-center items-center py-[8px] px-[4px] bg-cyanBlue rounded-[8px] hidden peer-hover:flex z-[100] -top-[48px] -right-[10px] md:-right-[90px]">
      <SvgPolygon
        className="absolute -bottom-[3px] md:left-[50%] right-[25.5px] md:translate-x-[-50%]"
      />
      <p className="w-[195px] text-center text-base font-normal text-white">
        {props.message}
      </p>
    </div>
  );
};

export default Tooltip;
