import type { SVGProps } from "react";
export const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.14 5.857c-.256.132-.482.431-.482.896v1.52c0 .49-.373.889-.833.889h-2.567c-3.427 0-6.439 3.22-6.586 7.4C5.321 14.85 7.47 13.867 9.8 13.867h3.025c.46 0 .833.397.833.888v2.33c0 .465.226.765.482.897a.63.63 0 0 0 .772-.126l5.117-5.149c.431-.49.37-1.216.028-1.547l-.012-.012-5.133-5.166a.63.63 0 0 0-.772-.125m-.728-1.6c.822-.425 1.865-.35 2.643.431l5.128 5.161c1.114 1.085 1.054 2.978.033 4.106l-.027.029-5.134 5.166c-.777.781-1.82.857-2.643.432-.821-.425-1.42-1.341-1.42-2.497v-1.44H9.8c-2.527 0-4.835 1.436-6.255 3.928a.81.81 0 0 1-.936.394C2.249 19.86 2 19.51 2 19.111v-2.228c0-5.186 3.706-9.5 8.258-9.5h1.734v-.63c0-1.155.599-2.071 1.42-2.496"
      clipRule="evenodd"
    />
  </svg>
);
