import type { SVGProps } from "react";
export const SvgGoogleCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path fill="#fff" d="M46.666 17.333H17.333v29.334h29.333z" />
    <path
      fill="#1E88E5"
      d="m34.24 27.893 1.344 1.92 2.112-1.536v11.136H40V24.821h-1.92zM30.591 31.66c.833-.765 1.35-1.827 1.35-2.999 0-2.329-2.043-4.224-4.555-4.224-2.136 0-3.963 1.346-4.44 3.271l2.209.561c.22-.885 1.157-1.528 2.23-1.528 1.257 0 2.28.862 2.28 1.92 0 1.059-1.023 1.92-2.28 1.92h-1.329v2.304h1.33c1.441 0 2.657 1.002 2.657 2.187 0 1.205-1.155 2.187-2.575 2.187-1.282 0-2.378-.814-2.552-1.891l-2.249.368c.35 2.181 2.413 3.827 4.8 3.827 2.676 0 4.853-2.015 4.853-4.491 0-1.364-.672-2.588-1.729-3.412"
    />
    <path
      fill="#FBC02D"
      d="M45.333 56H18.666l-1.333-5.333 1.333-5.334h26.667l1.333 5.334z"
    />
    <path
      fill="#4CAF50"
      d="M50.666 46.667 56 45.333V18.667l-5.334-1.334-5.333 1.334v26.666z"
    />
    <path
      fill="#1E88E5"
      d="m45.333 18.667 1.334-5.334L45.333 8H12a4 4 0 0 0-4 4v33.333l5.333 1.334 5.334-1.334V18.667z"
    />
    <path fill="#E53935" d="M45.333 45.333V56L56 45.333z" />
    <path
      fill="#1565C0"
      d="M52 8h-6.667v10.667H56V12a4 4 0 0 0-4-4M12 56h6.667V45.333H8V52a4 4 0 0 0 4 4"
    />
  </svg>
);
