import * as React from "react"
import Element from "./Element"
import isHotkey from "is-hotkey"
import Leaf from "./Leaf"
import { Button, Toolbar } from "../coach"
import {
  createEditor,
  BaseEditor,
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
} from "slate"
import { Elements, Text } from "../coach"
import { Slate, Editable, withReact, ReactEditor, useSlate } from "slate-react"
import {
  SvgEditorBold,
  SvgEditorHeader1,
  SvgEditorHeader2,
  SvgEditorItalic,
  SvgEditorListBulleted,
  SvgEditorListNumbered,
  SvgEditorUnderline,
} from "../icons"
import { withHistory } from "slate-history"

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: Elements
    Text: Text
  }
}

const HOTKEYS: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
}

const LIST_TYPES = ["numbered-list", "bulleted-list"]

type Props = {
  blogContent: Descendant[]
  setBlogContent: any
  customStyles: string
}

export default function BlogEditor({
  blogContent,
  setBlogContent,
  customStyles,
}: Props) {
  const renderElement = React.useCallback(
    (props: any) => <Element {...props} />,
    []
  )
  const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, [])
  const editor = React.useMemo(() => withHistory(withReact(createEditor())), [])

  const handleBlogContentChange = (blogContent: Descendant[]) => {
    setBlogContent(blogContent)
  }

  return (
    <div
      className={`${
        customStyles
          ? customStyles
          : "focus-within:border-royalBlue border-grayMist border"
      } relative h-full w-full overflow-y-auto overflow-x-hidden rounded-[10px] px-[20px] pb-[20px]`}
    >
      <Slate
        onChange={handleBlogContentChange}
        editor={editor}
        value={blogContent}
      >
        <Toolbar className="w-full">
          <MarkButton format="bold" Icon={SvgEditorBold} />
          <MarkButton format="italic" Icon={SvgEditorItalic} />
          <MarkButton format="underline" Icon={SvgEditorUnderline} />
          <BlockButton format="heading-one" Icon={SvgEditorHeader1} />
          <BlockButton format="heading-two" Icon={SvgEditorHeader2} />
          <BlockButton format="numbered-list" Icon={SvgEditorListNumbered} />
          <BlockButton format="bulleted-list" Icon={SvgEditorListBulleted} />
        </Toolbar>
        <Editable
          className="h-[85%] w-full"
          placeholder={"Write your blog post here..."}
          spellCheck
          autoFocus
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
        />
      </Slate>
    </div>
  )
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  })

  let newProperties: Partial<SlateElement>

  newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  }

  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const isBlockActive = (editor: any, format: any, blockType = "type"): any => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType as keyof Elements] === format,
    })
  )

  return !!match
}

const BlockButton = ({ format, Icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      className="cursor-pointer"
      active={isBlockActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}

const MarkButton = ({ format, Icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      className="cursor-pointer"
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon />
    </Button>
  )
}
