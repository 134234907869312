import React from "react";
import CloseModal from "../../CloseModal";
import Select from "react-select";
import { Button } from "../../ui/button";

interface Props {
  setCreatePromoCode: (statement: boolean) => void;
  handleCreatePromoCode: (promoCode: any) => void;
}

const PromoCodeModalForm: React.FC<Props> = ({
  setCreatePromoCode,
  handleCreatePromoCode,
}) => {
  const [promoCode, setPromoCode] = React.useState<any>({
    promoCodeName: "",
    promoCodeDescription: "",
    promoCodeType: "",
    promoCodeAmount: "",
  });
  const typeSelectOptions = [
    { value: "amount", label: "Amount" },
    { value: "percent", label: "Percent" },
  ];
  const submitDisabled =
    promoCode.promoCodeName.trim() === "" ||
    promoCode.promoCodeType.trim() === "" ||
    promoCode?.promoCodeAmount === "" ||
    promoCode?.promoCodeAmount === 0;

  const handleNameChange = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement;
    const nameValue = target.value.replace(/\s/g, "");
    setPromoCode({ ...promoCode, [target.name]: nameValue });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let amount = parseInt(event.target.value) || 0;

    if (promoCode.promoCodeType === "percent") {
      setPromoCode({ ...promoCode, [event.target.name]: Math.min(amount, 100) });
    } else {
      setPromoCode({ ...promoCode, [event.target.name]: amount });
    }
  };

  const handleChange = (event: React.FormEvent<EventTarget>) => {
    let target = event.target as HTMLInputElement;
    setPromoCode({ ...promoCode, [target.name]: target.value });
  };

  const handleChangeSelect = (selectedOption: any) => {
    setPromoCode({ ...promoCode, promoCodeType: selectedOption.value });
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-full flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
        <div className="w-[500px] max-w-[90%] main-shadow rounded-[16px] p-[44px] flex flex-col items-center gap-[30px] max-h-[95vh] bg-white fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] overflow-y-auto">
          <CloseModal
            callback={() => setCreatePromoCode(false)}
            styling="absolute top-[16px] right-[16px] sm:top-[24px] sm:right-[24px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h3 className=" md:text-[22px] text-[18px] font-bold sm:text-[28px] sm:text-center md:text-left mb-[24px]">
            Create a Promo Code
          </h3>
          <div className="w-full flex-inline items-center justify-between">
            <div className="w-full min-h-[80px] mb-[20px]">
              <div className="flex justify-between">
                <div className="flex">
                  <p className="text-[16px] font-bold mb-[5px]">
                    Promotion Name
                  </p>
                </div>
                <span className="font-normal text-sm text-graySlate">
                  20 characters
                </span>
              </div>
              <div className="default-input w-full">
                <input
                  className="text-mainBlack w-full"
                  type="text"
                  maxLength={20}
                  placeholder="Promotion Name"
                  onChange={handleNameChange}
                  value={promoCode.promoCodeName}
                  name="promoCodeName"
                />
                <div className="overlay">Promo Name</div>
              </div>
            </div>

            <div className="w-full min-h-[80px] mb-[20px]">
              <div className="flex justify-between">
                <div className="flex">
                  <p className="text-[16px] font-bold mb-[5px]">Description</p>
                </div>
                <span className="font-normal text-sm text-graySlate">
                  30 characters
                </span>
              </div>
              <div className="default-input w-full">
                <input
                  className="text-mainBlack w-full"
                  type="text"
                  maxLength={30}
                  placeholder="Enter a description of promo"
                  onChange={handleChange}
                  name="promoCodeDescription"
                />
                <div className="overlay">Description</div>
              </div>
            </div>

            <div className="flex justify-between w-full min-h-[80px] mb-[20px]">
              <div className="w-1/2">
                <p className="text-[16px] font-bold mb-[5px]">Type</p>
                <Select
                  className="w-[95%] basic-single border-grayCloud text-graySlate rounded-[10px]"
                  classNamePrefix="select"
                  options={typeSelectOptions}
                  name="promoCodeType"
                  placeholder="Type"
                  isClearable={false}
                  onChange={handleChangeSelect}
                />
              </div>

              <div className="default-input w-1/2">
                <p className="text-[16px] font-bold mb-[5px]">Amount</p>
                <div className="default-input w-full">
                  <input
                    className="text-mainBlack w-full"
                    placeholder="Amount off"
                    onChange={handleAmountChange}
                    value={promoCode.promoCodeAmount}
                    name="promoCodeAmount"
                  />
                  <div className="overlay">Amount</div>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-between">
              <Button
                onClick={() => setCreatePromoCode(false)}
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleCreatePromoCode(promoCode)}
                disabled={submitDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCodeModalForm;
