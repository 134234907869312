import type { SVGProps } from "react"
export const SvgIINLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Frame" clip-path="url(#clip0_3010_11144)">
      <path
        id="Vector"
        d="M19.679 7.63678C19.2136 6.39369 18.6745 5.39921 17.2537 3.98208C15.3673 2.04286 12.2559 0.774902 10.884 0.774902H10.8595C10.9085 0.799764 10.933 0.799767 11.0065 0.824629C11.1045 0.849491 11.178 0.874347 11.4475 0.973794C13.3094 1.64506 15.0977 3.13678 16.2982 4.87711C17.7926 7.06495 18.0376 8.87985 18.0621 10.9683C18.0866 13.1312 17.4741 15.4186 16.3962 17.0594C14.7548 19.5705 12.0599 20.9876 9.04658 20.9379C7.87065 20.913 6.15573 20.6893 4.8328 19.6699C3.41188 18.6009 2.50543 17.3329 2.16245 15.9157C1.84396 14.6478 1.84396 13.3053 2.16245 12.3357C2.52992 11.2417 3.24038 10.4213 4.22033 9.9489C5.24928 9.47655 6.67021 9.45173 7.69913 9.89925C9.75705 10.7942 9.56108 12.6838 9.31605 13.2556C9.09555 13.8274 8.53207 14.3992 7.77262 14.3992C7.30717 14.3992 6.8662 14.2252 6.59671 13.9268C6.25373 13.5539 6.10673 13.1312 6.05773 12.8827C6.03323 12.8329 6.03324 12.7583 6.00874 12.7335L5.98424 12.6589L5.91074 12.7583C5.91074 12.7832 5.69026 13.1064 5.61676 13.3798C5.51877 13.7528 5.51876 14.1257 5.59226 14.4738C5.76375 15.1202 6.22923 15.6175 6.96419 15.9406C8.01765 16.3882 9.26707 16.1146 10.0511 15.6672C10.7614 15.2445 11.3249 14.623 11.6434 13.902C12.0354 13.0069 12.1334 11.8882 11.9375 10.7694C11.717 9.576 10.5655 8.35777 9.63457 7.81081C8.16465 6.9655 7.11118 6.86606 5.56776 6.99037C4.26933 7.08982 3.14239 7.61192 2.01545 8.6064C1.109 9.42682 0.349539 10.7942 0.153549 12.0373C-0.140435 13.8523 -0.0179423 15.1202 0.570026 16.7362C1.79496 20.0428 4.34283 21.6091 5.10229 22.0069C6.30272 22.6285 7.9686 23.2252 9.9285 23.2252C11.0065 23.2252 12.1579 23.0512 13.4074 22.6036C15.8327 21.7335 17.4986 20.3412 18.8951 18.1036C19.63 16.9352 20.1445 15.2942 20.316 13.0815C20.5365 11.0677 20.1935 9.02902 19.679 7.63678Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M24.7266 0.75H24.457V1.02348V23.0014V23.25H24.7266H28.2789H28.5238V23.0014V1.02348V0.75H28.2789H24.7266Z"
        fill="black"
      />
      <path
        id="Vector_3"
        d="M32.8359 0.75H32.5664V1.02348V23.0014V23.25H32.8359H36.3882H36.6332V23.0014V1.02348V0.75H36.3882H32.8359Z"
        fill="black"
      />
      <path
        id="Vector_4"
        d="M57.5053 0.75H53.9285H53.6835V1.02348V15.1201L43.933 0.87431L43.8595 0.75H43.737H40.9442H40.6992V1.02348V23.0014V23.25H40.9442H44.4965H44.7659V23.0014V8.90467L54.4919 23.1257L54.5654 23.25H54.7124H57.5053H57.7503V23.0014V1.02348V0.75H57.5053Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_3010_11144">
        <rect
          width="57.75"
          height="22.5"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
)
