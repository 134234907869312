import { NavLink, Link, Route, Routes } from "react-router-dom";
import { SvgZoeeLogo } from "../../components/icons";
import { Badge } from "../../components/ui/badge";
import { EscapeJsonPage } from "./escape-json/page";
import { IconsPage } from "./icons/page";
import { ButtonsPage } from "./buttons/page";
import { CommissionTrackingPage } from "./commission-tracking/page";
import { BadgesPage } from "./badges/page";
import { PlaygroundPage } from "./playground/page";
import { cn } from "../../utils/cn";
import { SvgCode } from "../../components/icons";

const HomePage: React.FC = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-3">
      <SvgCode className="text-muted h-36 w-36" />
      <h1 className="text-muted-foreground text-4xl font-semibold">Testing</h1>
      <p className="text-muted-foreground">
        Hello, welcome to the testing page.
      </p>
    </div>
  );
};

const links: { label: string; href: string; element: React.ReactNode }[] = [
  { label: "Home", href: "", element: <HomePage /> },
  { label: "Escape JSON", href: "escape-json", element: <EscapeJsonPage /> },
  { label: "Icons", href: "icons", element: <IconsPage /> },
  { label: "Buttons", href: "buttons", element: <ButtonsPage /> },
  {
    label: "Commission Tracking",
    href: "commission-tracking",
    element: <CommissionTrackingPage />,
  },
  { label: "Badges", href: "badges", element: <BadgesPage /> },
  { label: "Playground", href: "playground", element: <PlaygroundPage /> },
];

export const TestingPage: React.FC = () => {
  return (
    <div className="grid h-full max-h-screen min-h-screen grid-cols-[250px_auto]">
      <nav className="bg-muted">
        <div className="my-6 ml-4 flex items-center justify-start gap-1 p-3">
          <Link to="/">
            <SvgZoeeLogo />
          </Link>
          <Badge>Dev</Badge>
        </div>
        <div className="flex flex-1 flex-col items-stretch gap-1 px-3">
          {links.map(({ label, href }) => (
            <>
              <NavLink
                key={href}
                to={`/testing/${href}`}
                end
                className={({ isActive }) =>
                  cn(
                    "rounded-xl border border-transparent px-4 py-2 font-semibold transition-colors",
                    {
                      "border-blurple text-blurple": isActive,
                      "hover:text-blurple hover:border-border hover:bg-white":
                        !isActive,
                    },
                  )
                }
              >
                {label}
              </NavLink>
            </>
          ))}
        </div>
      </nav>
      <div className="max-h-screen overflow-y-auto p-6">
        <Routes>
          {links.map(({ href, element }) => (
            <Route key={href} path={href} element={element} />
          ))}
        </Routes>
      </div>
    </div>
  );
};
