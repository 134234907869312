import type { SVGProps } from "react";
export const SvgEditorListBulleted = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.837 5.607h14v2h-14zm0 8v-2h14v2zm-3-8.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3m3 8.5v-2h14v2zm-3-2.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3"
    />
  </svg>
);
