import React, { useContext, useState, useEffect } from "react"
import RegistrationPage from "./page"
import { RegisterFormProvider } from "../../components/auth/register-form/context"
import {
  CommonFunctionCtx,
  GetUserReturnType,
} from "../../context/commonFunctionContext"
import { OnSuccessfulAuthCallback } from "../../components/auth/context"
import { VerifyPhone } from "../../api/app.service"
import { useLocation } from "react-router-dom"
import { SubscriptionContext } from "../../components/subscription"

export enum StepType {
  Email = "email",
  VerifyEmail = "verify-email",
  VerifyPhone = "verify-phone",
  CreateAccount = "create-account",
  CreditCard = "credit-card",
  IINCheckout = "iin-checkout",
  EnrollmentFinal = "enrollment-final",
}

interface LocationState {
  from?: string
}

const WrappedRegistrationPage = () => {
  const [step, setStep] = useState<StepType>(StepType.Email)
  const [loading, setLoading] = useState(false)
  const { renderError } = useContext(CommonFunctionCtx)

  const location = useLocation()

  const locationState = location.state as LocationState

  const [isIIN, setIsIIN] = useState(false)

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const flow = queryParams.get("flow")
    const from = locationState?.from || ""

    if (
      flow === "IIN" ||
      from.toLowerCase() === "IINBusinessBlueprint".toLowerCase()
    ) {
      setIsIIN(true)
    } else {
      setIsIIN(false)
    }
  }, [location, locationState])

  const [appliedDiscount, setAppliedDiscount] = useState<any>({
    amount: 0,
    promoCode: "",
  })
  const { createSetupIntent } = useContext(SubscriptionContext)
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false)

  const onSuccess: OnSuccessfulAuthCallback = (user: GetUserReturnType) => {
    if (user) {
      VerifyPhone.sendPhoneCode({ access_token: user.access_token })
        .then((data) => {
          console.log(data.message)
          setLoading(false)
          data.message === "complete" && setStep(StepType.VerifyPhone)
        })
        .catch((err) => {
          console.error(err)
          renderError(err.response.data.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <RegisterFormProvider
      defaultEmail=""
      defaultAccountType={isIIN ? "coach" : "coach"} // TODO How to distiguish? isIIN = coach
      defaultFirstName=""
      defaultLastName=""
      defaultPassword=""
      defaultPhone=""
      onSuccess={onSuccess}
    >
      <RegistrationPage
        isIIN={isIIN}
        step={step}
        setStep={setStep}
        loading={loading}
        setLoading={setLoading}
        appliedDiscount={appliedDiscount}
        createSetupIntent={createSetupIntent}
        isTermsAgreed={isTermsAgreed}
        setIsTermsAgreed={setIsTermsAgreed}
        selectedPlanFromChild=""
      />
    </RegisterFormProvider>
  )
}

export default WrappedRegistrationPage
export * from "./page"
