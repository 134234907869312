import type { SVGProps } from "react";
export const SvgPaintBrush = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8756 19.4983H19.7475C19.8812 19.4983 20 19.6016 20 19.7491C20 19.8819 19.8812 20 19.7475 20H14.7113C14.7113 19.9704 14.6964 19.941 14.6815 19.9262C14.4884 19.6015 14.325 19.2621 14.1913 18.8783C14.0576 18.4945 13.9387 18.0962 13.8495 17.6533C13.8347 17.5205 13.7009 17.4319 13.5524 17.4615C13.4187 17.4911 13.3296 17.6239 13.3593 17.7567C13.4485 18.1995 13.5674 18.6422 13.7159 19.0407C13.8347 19.3802 13.9833 19.7048 14.1466 20H11.2051C11.1159 19.6458 11.0714 19.3359 11.0416 19.0258C11.0119 18.7307 11.0119 18.465 11.0416 18.1994C11.0565 18.0517 10.9524 17.9336 10.8039 17.9189C10.6703 17.9042 10.5514 18.0075 10.5365 18.1551C10.5067 18.4502 10.5067 18.7454 10.5365 19.0701C10.5662 19.3653 10.6107 19.6605 10.6851 20H7.77343C7.83286 19.4981 7.89228 19.0407 7.95171 18.6126C8.02596 18.1256 8.08538 17.6533 8.15975 17.1958C8.18951 17.063 8.10032 16.9301 7.95171 16.9153C7.81803 16.8858 7.69918 16.9891 7.66941 17.122C7.59516 17.5796 7.5208 18.0665 7.46137 18.5388C7.38712 19.0407 7.3277 19.513 7.26828 20H4.25252C4.11885 20 4 19.8819 4 19.7491V19.7048C4.14862 18.4504 4.32688 17.2548 4.51999 16.1183C4.6686 15.2475 4.83194 14.421 5.03997 13.6389C5.12916 13.6832 5.21824 13.7275 5.33709 13.7569L16.479 15.7051C16.6276 15.7346 16.7613 15.7051 16.895 15.6608C16.9544 16.3249 17.0287 16.9595 17.1625 17.5499C17.3259 18.273 17.5636 18.9372 17.8755 19.498L17.8756 19.4983ZM16.494 13.4177C16.8059 13.8753 16.9546 14.4361 16.8505 15.0117C16.8357 15.1445 16.7019 15.2331 16.5682 15.2035L5.41135 13.2554C5.27767 13.2406 5.18848 13.1077 5.21825 12.9749C5.32227 12.3993 5.64903 11.9123 6.09476 11.6024C6.5405 11.2925 7.10497 11.1448 7.68437 11.2482L9.91281 11.632L11.0567 5.24148C11.1309 4.82824 11.3538 4.48876 11.6807 4.26735C11.9926 4.06068 12.3938 3.95744 12.7949 4.01649V4.03122C13.2109 4.09025 13.5526 4.32639 13.7755 4.6363C13.9983 4.96104 14.1023 5.35943 14.028 5.75796L12.8841 12.1633L15.1126 12.5471C15.692 12.6505 16.1822 12.9751 16.4941 13.4179L16.494 13.4177Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8756 19.4983H19.7475C19.8812 19.4983 20 19.6016 20 19.7491C20 19.8819 19.8812 20 19.7475 20H14.7113C14.7113 19.9704 14.6964 19.941 14.6815 19.9262C14.4884 19.6015 14.325 19.2621 14.1913 18.8783C14.0576 18.4945 13.9387 18.0962 13.8495 17.6533C13.8347 17.5205 13.7009 17.4319 13.5524 17.4615C13.4187 17.4911 13.3296 17.6239 13.3593 17.7567C13.4485 18.1995 13.5674 18.6422 13.7159 19.0407C13.8347 19.3802 13.9833 19.7048 14.1466 20H11.2051C11.1159 19.6458 11.0714 19.3359 11.0416 19.0258C11.0119 18.7307 11.0119 18.465 11.0416 18.1994C11.0565 18.0517 10.9524 17.9336 10.8039 17.9189C10.6703 17.9042 10.5514 18.0075 10.5365 18.1551C10.5067 18.4502 10.5067 18.7454 10.5365 19.0701C10.5662 19.3653 10.6107 19.6605 10.6851 20H7.77343C7.83286 19.4981 7.89228 19.0407 7.95171 18.6126C8.02596 18.1256 8.08538 17.6533 8.15975 17.1958C8.18951 17.063 8.10032 16.9301 7.95171 16.9153C7.81803 16.8858 7.69918 16.9891 7.66941 17.122C7.59516 17.5796 7.5208 18.0665 7.46137 18.5388C7.38712 19.0407 7.3277 19.513 7.26828 20H4.25252C4.11885 20 4 19.8819 4 19.7491V19.7048C4.14862 18.4504 4.32688 17.2548 4.51999 16.1183C4.6686 15.2475 4.83194 14.421 5.03997 13.6389C5.12916 13.6832 5.21824 13.7275 5.33709 13.7569L16.479 15.7051C16.6276 15.7346 16.7613 15.7051 16.895 15.6608C16.9544 16.3249 17.0287 16.9595 17.1625 17.5499C17.3259 18.273 17.5636 18.9372 17.8755 19.498L17.8756 19.4983ZM16.494 13.4177C16.8059 13.8753 16.9546 14.4361 16.8505 15.0117C16.8357 15.1445 16.7019 15.2331 16.5682 15.2035L5.41135 13.2554C5.27767 13.2406 5.18848 13.1077 5.21825 12.9749C5.32227 12.3993 5.64903 11.9123 6.09476 11.6024C6.5405 11.2925 7.10497 11.1448 7.68437 11.2482L9.91281 11.632L11.0567 5.24148C11.1309 4.82824 11.3538 4.48876 11.6807 4.26735C11.9926 4.06068 12.3938 3.95744 12.7949 4.01649V4.03122C13.2109 4.09025 13.5526 4.32639 13.7755 4.6363C13.9983 4.96104 14.1023 5.35943 14.028 5.75796L12.8841 12.1633L15.1126 12.5471C15.692 12.6505 16.1822 12.9751 16.4941 13.4179L16.494 13.4177Z"
      fill="url(#paint0_linear_5955_102585)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5955_102585"
        x1="3.13978"
        y1="7.7143"
        x2="22.4021"
        y2="10.3334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F3A941" />
        <stop
          offset="1"
          stop-color="#EA41E0"
        />
      </linearGradient>
    </defs>
  </svg>
);
