import { Outlet } from "react-router-dom"
import Footer from "../../components/marketing/footer/Footer"
// import MarketingNav from "../../components/navigation/MarketingNav";

export default function RadiantPageLayout() {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  )
}
