import React from "react";
import { Button } from "../../ui/button";
import { SvgPlus } from "../../icons";
import calendarsImage from "../../../assets/images/calendars-image.webp";
import {
  SvgApple,
  SvgGoogleCalendar,
  SvgOffice365Calendar,
  SvgOutlookCalendar,
} from "../../icons";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

interface Provider {
  Image: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  name: string;
  label: string;
  button?: string;
}

const providers: Provider[] = [
  {
    Image: SvgGoogleCalendar,
    name: "google",
    label: "Google Calendar",
  },
  {
    Image: SvgOffice365Calendar,
    name: "office365",
    label: "Office365",
  },
  {
    Image: SvgOutlookCalendar,
    name: "live_connect",
    label: "Outlook",
    button: "Outlook",
  },
  {
    Image: SvgApple,
    name: "apple",
    label: "Apple iCloud",
  },
];

interface Props {
  isCalendarConnected: boolean;
}

export const ConnectCalendarCTA: React.FC<Props> = (
  { isCalendarConnected },
) => {
  const startConnectCalendar = async (provider_name: string) => {
    const redirectUri =
      `${process.env.REACT_APP_BASE_URL}/account-settings/calendars`;

    const url =
      `https://app.cronofy.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_CRONOFY_CLIENT_ID}&redirect_uri=${redirectUri}&scope=read_write&provider_name=${provider_name}&avoid_linking=true`;
    window.location.href = url;
  };

  return (
    <section className="bg-white rounded-xl main-shadow p-6 flex flex-col md:flex-row md:items-center gap-6 xl:gap-12">
      <div className="md:basis-1/2 lg:basis-2/5 rounded-lg overflow-hidden">
        <img
          src={calendarsImage}
          alt="calendars"
          className="h-auto w-full"
        />
      </div>
      <div className="flex flex-col items-stretch md:items-start md:basis-1/2 lg:basis-3/5">
        <h3 className="font-bold text-2xl mb-4">
          {isCalendarConnected
            ? "Sync Another Calendar"
            : "Connect Your Calendar"}
        </h3>
        <p className="text-graySlate font-base mb-6">
          {isCalendarConnected
            ? "Connect your calendar to manage your availability, notifications, and upcoming sessions."
            : "Connect your calendar to manage availability, notifications, and upcoming sessions. Did we mention you can connect more than one?"}
        </p>

        <Dialog>
          <DialogTrigger asChild>
            <Button>
              {isCalendarConnected ? "New Calendar" : "Connect Calendar"}
              <SvgPlus />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-center">
                Select Your Calendar
              </DialogTitle>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="flex flex-col gap-2 w-full">
                {providers.map(({ Image, name, label, button }) => (
                  <div key={label} className="flex flex-row p-4 md:p-6 gap-6 gap-2 border border-1 border-border rounded-lg justify-between sm:items-center">
                    <div className="flex gap-4 items-center font-bold text-md justify-center">
                      <Image className="w-8 h-8 sm:w-12 sm:h-12" />
                      {label}
                    </div>
                    <Button
                      onClick={() => startConnectCalendar(name)}
                      variant="secondary"
                    >
                      <span>
                        Sign in{" "}
                        <span className="hidden sm:inline">
                          with{" "}
                          <span className="capitalize">
                            {button ? button : name}
                          </span>
                        </span>
                      </span>
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </section>
  );
};
