import React from "react";
import { Helmet } from "react-helmet";

type Props = {};

const LandingScheduler = (props: Props) => {
    return (
        <>
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://assets.calendly.com/assets/external/widget.js"
                    async
                ></script>
            </Helmet>
            <div className="md:w-[438px] bg-white rounded-[16px] main-shadow ">
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/nichole-lowe/30min?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=007aff"
                    style={{ minWidth: "335px", height: "530px" }}
                ></div>
            </div>
        </>
    );
};

export default LandingScheduler;
