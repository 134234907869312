import React from "react"
import { Link } from "react-router-dom"
import { SvgCheckCircle } from "../../components/icons/"
import { cn } from "../../utils/cn"

interface Props {
  title: string
  text: string
  link: string
  complete: boolean
  step?: number
  icon: any
  handleLinkClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
  profileAccessStatus: string
  connectAccountData: any
  linkId: string
  index: number
}

const BusinessLink: React.FC<Props> = ({
  title,
  text,
  link,
  complete,
  step,
  icon,
  handleLinkClick,
  profileAccessStatus,
  connectAccountData,
  linkId,
  index,
}) => {
  const stepIsCompleted =
    !!complete && linkId !== "verification" && step && linkId !== "profile"
  const stepIsIncompleted =
    !complete && linkId !== "verification" && step && linkId !== "profile"
  // const verificationStepIsCompleted =
  //   connectAccountData &&
  //   connectAccountData?.identity_status === "verified" &&
  //   linkId === "verification" &&
  //   step
  // const verificationStepIsInProcess =
  //   connectAccountData &&
  //   connectAccountData?.identity_status === "processing" &&
  //   linkId === "verification" &&
  //   step
  // const verificationStepIsIncompleted =
  //   connectAccountData &&
  //   connectAccountData?.identity_status !== "verified" &&
  //   linkId === "verification" &&
  //   step
  const profileAccessApproved =
    profileAccessStatus &&
    profileAccessStatus === "approved" &&
    linkId === "profile" &&
    step
  const profileAccessNotApproved =
    profileAccessStatus &&
    profileAccessStatus !== "approved" &&
    linkId === "profile" &&
    step

  return (
    <Link
      to={link}
      onClick={handleLinkClick}
      id={linkId}
      // Probably a much better way to do this, but oh well
      className={cn(
        "settings-card-shadow hover:card-hover-shadow group relative col-span-12 block rounded-[16px] bg-white p-6 md:hover:scale-105",
        {
          "a md:col-span-6 lg:col-span-6 xl:col-span-4": index === 0,
          "b md:col-span-6 lg:col-span-6 xl:col-span-4": index === 1,
          "c md:col-span-6 lg:col-span-6 xl:col-span-4": index === 2,
          "d md:col-span-6 lg:col-span-6 xl:col-span-4": index === 3,
          "e md:col-span-6 lg:col-span-6 xl:col-span-4": index === 4,
          "f md:col-span-6 lg:col-span-6 xl:col-span-4": index === 5,
          "g md:col-span-12 lg:col-span-6 xl:col-span-4": index === 6,
        }
      )}
    >
      {Boolean(stepIsIncompleted || profileAccessNotApproved) && (
        <div className="bg-vividOrange absolute -right-2 -top-3 flex h-8 w-8 items-center justify-center rounded-full text-center text-sm font-bold text-white">
          {step}
        </div>
      )}

      {Boolean(profileAccessApproved || stepIsCompleted) && (
        <SvgCheckCircle className="text-green absolute right-2 top-2" />
      )}

      <div className="mb-2 flex items-center gap-2">
        {icon}
        <h3 className="text-xl font-bold text-black">{title}</h3>
      </div>

      <p className="text-graySlate text-base">{text}</p>

      {/* Removed verification for now  */}
      {/* {verificationStepIsCompleted ? (
        <p className="bg-grassGreen main-shadow absolute -top-4 left-4 hidden rounded-[100px] px-[12px] py-[2px] font-bold text-white group-hover:block">
          Verified
        </p>
      ) : verificationStepIsInProcess ? (
        <p className="bg-vividOrange main-shadow absolute -top-4 left-4 hidden rounded-[100px] px-[12px] py-[2px] font-bold text-white group-hover:block">
          In Progress
        </p>
      ) : verificationStepIsIncompleted ? (
        <p className="bg-vividOrange main-shadow absolute -top-4 left-4 hidden rounded-[100px] px-[12px] py-[2px] font-bold text-white group-hover:block">
          Incompleted
        </p>
      ) : (
        ``
      )} */}
    </Link>
  )
}

export default BusinessLink
