import React, { FC } from "react"
import {
  MessageList,
  MessageInput,
  Window,
  MessageToSend,
  useChannelActionContext,
  Thread,
} from "stream-chat-react"

interface Props {
  setMessageCount: (callback: (count: number) => number) => void
  messageCount: number
}

const ChannelInner: FC<Props> = ({ setMessageCount, messageCount }) => {
  const { sendMessage } = useChannelActionContext()
  const overrideSubmitHandler = (message: MessageToSend, cid: string) => {
    setMessageCount((prevMessageCount: number) => prevMessageCount + 1)
    sendMessage(message)
  }

  return (
    <>
      <Window>
        <MessageList
          messageActions={[
            "flag",
            "mute",
            "quote",
            "react",
            "reply",
            "edit",
            "delete",
          ]}
        />
        <MessageInput
          overrideSubmitHandler={overrideSubmitHandler}
          grow
          additionalTextareaProps={{
            placeholder: "Type your message",
          }}
        />
      </Window>
      <Thread />
    </>
  )
}

export default ChannelInner
