import * as React from "react";
import { useState, useEffect } from "react";
import zoeeLogoOutline from "../../../assets/images/zoee-logo-outline-square.webp";
import PricingToggleSection from "../../../components/marketing/features/PricingToggleSection";
import { Button } from "../../../components/ui/button";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import ZoeeProfilePreview from "../../../assets/images/Profile.png";
import featureInboxOne from "../../../assets/images/feature-inbox 1.png";
import featurePayment from "../../../assets/images/feature-payments.png";
import featureCommunication from "../../../assets/images/featureCommunication.png";
import clientPortals from "../../../assets/images/Client-Portal.png";
import featureScheduling from "../../../assets/images/feature-scheduling.webp";
import CloseModal from "../../../components/CloseModal";
import CalendlyScheduler from "../../../pages/manage-business/CalendlyScheduler";
import useOutsideClick from "../../../hooks/useOutsideClick";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useAuthModal } from "../../../components/auth/use-auth-modal";

export default function FeaturesPage() {
  useScrollToTop();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  // variable for video player controls
  const controls = useAnimation();
  // State for updating video play boolean value
  const [shouldPlay, setShouldPlay] = React.useState(false);

  // OusideClick ref
  const ref = React.useRef(null);
  const [outsideClickCounter, setOutsideClickCounter] = React.useState<number>(
    0,
  );

  // Function for playing the video when it is in view
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  React.useEffect(() => {
    if (inView) {
      setShouldPlay(true);
    }
  }, [inView]);

  const { showRegister } = useAuthModal();

  React.useEffect(() => {
    if (shouldPlay) {
      controls.start({ opacity: 1, scale: 1 });
    } else {
      controls.start({ opacity: 0, scale: 0.8 });
    }
  }, [shouldPlay, controls]);

  React.useEffect(() => {
    const path = window.location.href;
    const pathName = path.split("#").pop();
    let scroll = document.getElementById(`${pathName}`);
    scroll?.scrollIntoView();
  }, []);

  // Close modal with outside click function
  useOutsideClick(ref, () => {
    setOutsideClickCounter((prevCounter) => prevCounter + 1);
    if (outsideClickCounter > 0) {
      setIsModalOpen(false);
    }
  });
  
  return (
    <div className="overflow-hidden">
      {isModalOpen && (
        <div className="zoee-backdrop-filter fixed left-0 top-0 z-[1001] flex
        h-screen w-screen items-center justify-center pb-[88px] md:pb-[40px]">
          <div className="relative mt-[200px] flex h-auto min-w-[360px] shrink flex-col items-center gap-2 rounded-[16px] bg-white px-[25px] pt-[35px] text-center md:mt-[100px] md:w-[600px] md:px-[120px]">
            <CloseModal
              callback={() => setIsModalOpen(false)}
              styling="absolute top-[24px] right-[24px]"
            />
            <div>
              <h2 className="mt-[10px] font-bold">
                Schedule Your 30 Minute Demo
              </h2>
            </div>
            <CalendlyScheduler name="group-demo" />
          </div>
        </div>
      )}

      <section className="flex min-h-[calc(100vh-100px)] items-center bg-gradient-to-t from-[#BACDFF] to-white pb-12 pt-48">
        <div className="container grid items-center gap-12 md:grid-cols-2">
          <div className="flex flex-col items-center gap-4 md:items-start lg:gap-8">
            <h1 className="max-w-screen-sm text-balance text-center text-2xl font-bold text-black md:text-left md:text-4xl lg:text-5xl">
              Built for coaches, by coaches.
            </h1>

            <p className="text-balance text-center text-base font-semibold md:text-left md:text-xl lg:text-3xl">
              We designed the Zoee platform with you, the coach, in mind
              providing features to make running your business easier.
            </p>

            <div className="flex flex-wrap justify-center gap-2 md:justify-start">
              <Button
                onClick={() => showRegister({ accountType: "coach" })}
                variant="default"
                size="lg"
              >
                Start Your Free Trial
              </Button>
            </div>
          </div>

          <motion.div
            ref={inViewRef}
            className="w-full rounded-[20px] bg-black p-4"
            animate={controls}
          >
            <iframe
              src="https://fast.wistia.com/embed/medias/da7jerndvs"
              title="Zoee Video"
              allow="autoplay; fullscreen"
              allowFullScreen
              className="aspect-video h-full w-full rounded-md border-none bg-white"
            >
            </iframe>
          </motion.div>
        </div>
      </section>

      {/* Public Profile Page */}
      <section
        className="container my-24 grid items-center gap-12 md:my-12 md:grid-cols-2"
        id="public-profile"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Public Profile Page
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Promote your brand on Zoee's customizable landing page where you can
            share your expertise, tailored client offerings (including
            individual and group programs), generate new bookings, add video and
            blog content, and seamlessly integrate all of your back-end business
            admin.
          </p>
          <a
            href="https://zoee.com/find-a-coach/lisa-morris-demo"
            className="text-blurple text-center font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Demo Profile Page
          </a>
        </div>

        <img
          className="mx-auto md:-order-1 md:ml-0 md:mr-auto"
          src={ZoeeProfilePreview}
          alt="coach profile page"
        />
      </section>

      {/* Automatic Billing + Payments */}
      <section
        className="container my-36 grid items-center gap-12 md:my-24 md:grid-cols-2"
        id="automatic-billing-and-payments"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Automatic Billing + Payments
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Once your clients select a program and book their first session with
            you, we'll take the reins. Zoee eliminates the need for you to
            manually handle client invoices, payments, cancellations, and
            tracking all of your hours and financial transactions.
          </p>
        </div>

        <img
          className="mx-auto md:ml-auto md:mr-0"
          src={featureInboxOne}
          alt="billing and payments"
        />
      </section>

      {/* Simplified Scheduling + Booking */}
      <section
        className="container my-36 grid items-center gap-12 md:my-24 md:grid-cols-2"
        id="simplified-scheduling-and-booking"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Simplified Scheduling + Booking
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Zoee simplifies your booking and scheduling process through joint
            calendar availability for easy booking and rescheduling, session
            reminders, with option to sync all of your calendars to increase
            bookings, prevent missed appointments and eliminate calendar chaos.
          </p>
        </div>

        <img
          className="mx-auto md:-order-1 md:ml-0 md:mr-auto"
          src={featureScheduling}
          alt="scheduling"
        />
      </section>

      {/* Effortless Client Management */}
      <section
        className="container my-36 grid items-center gap-12 md:my-24 md:grid-cols-2"
        id="efforless-client-management"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Effortless Client Management
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Easily store and manage all of your contacts in one place. Zoee
            organizes your prospects, client and group info, tracks client
            hours, invoice histories, program subscriptions and view session
            notes— all synced automatically within Zoee.
          </p>
        </div>

        <img
          className="mx-auto md:ml-auto md:mr-0"
          src={featurePayment}
          alt="client management"
        />
      </section>

      {/* Client Communication Hub */}
      <section
        className="container my-36 grid items-center gap-12 md:my-24 md:grid-cols-2"
        id="client-communication-hub"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Client Communication Hub
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Streamline all your communications in one place so you can focus on
            connecting with your clients. Zoee enables seamless video sessions,
            chat messaging with notifications, calendar integrations, and
            digital note-taking–automatically saved for future reference.
          </p>
        </div>

        <img
          className="mx-auto md:-order-1 md:ml-0 md:mr-auto"
          src={featureCommunication}
          alt="communications"
        />
      </section>

      {/* Client Portals */}
      <section
        className="container my-36 grid items-center gap-12 md:my-24 md:grid-cols-2"
        id="client-portals"
      >
        <div className="flex flex-col items-center gap-4 md:items-start">
          <h2 className="text-balance text-center text-[24px] font-bold text-black md:text-left md:text-[36px] lg:text-[42px]">
            Client Portals
          </h2>
          <p className="text-center text-sm md:text-left md:text-base lg:text-lg">
            Everything your client needs in one secure, centralized place. The
            portal allows your clients to communicate with you via chat and
            video sessions, share files, access their personal development
            journal and calendar, book sessions, view their invoices, and more.
          </p>
        </div>

        <img
          className="mx-auto md:ml-auto md:mr-0"
          src={clientPortals}
          alt="custom programs features"
        />
      </section>

      <div className="relative z-20 m-auto">
        <PricingToggleSection />
      </div>

      <div className="gray-background-gradient text-grayCharcoal relative flex w-full flex-col items-center justify-center px-[24px] py-[180px] font-bold">
        <h6
          className="text-grayFlash absolute right-[-220px] top-[-680px] z-0 -rotate-90 text-center text-[111px] font-bold leading-[94px] md:right-[-314px] md:top-[-600px] md:text-[160px] md:leading-[150px]"
          aria-hidden="true"
        >
          DO LIFE <br />
          TOGETHER
        </h6>
        <img
          className="absolute top-[-72px] z-0 hidden md:left-[140px] md:block xl:left-[242px]"
          src={zoeeLogoOutline}
          alt="zoee logo outline"
        />
        <div>
          <h4 className="mb-[16px] text-[40px] sm:text-[48px]">
            Just picture it:
          </h4>
          <p className="mb-[16px] max-w-[608px] text-[18px]">
            You&apos;re sipping your morning coffee and looking at a full day of
            coaching sessions on the calendar. Clients are messaging you with
            updates on their goals, you&apos;ve just gotten paid, and your dog
            is sleeping patiently at your feet.
          </p>
          <h5 className="mb-[32px] text-[36px] font-bold">
            What
            <span className="text-blurple">&nbsp;better feeling&nbsp;</span>
            is there?
          </h5>
          <Button
            onClick={() => showRegister({ accountType: "coach" })}
            size="lg"
          >
            Start Now
          </Button>
        </div>
      </div>
    </div>
  );
}
