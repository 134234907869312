import React, { ChangeEvent, FC, useContext, useState } from "react"
import * as z from "zod"
import { Button } from "../ui/button"
import { SvgEdit, SvgTrash } from "../icons"
import FormDilog from "./manage/FormDialog"
import { FormLink, UpdateFormLinkType } from "../../models/app.interface"
import { FormRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgFile } from "../icons/file"
import { SvgFormUrl } from "../icons/formUrl"
import useLoading from "../../hooks/useLoading"

interface Props {
  formLink: FormLink
  deleteForm: (formId: number) => void
  getFormList: () => void
}

const LinkedFormsItem: FC<Props> = ({ formLink, deleteForm, getFormList }) => {
  const [isDialogOpen, setIsdialogOpen] = useState(false)
  const [formLinkData, setFormLinkData] = useState<UpdateFormLinkType>({
    title: formLink.title,
    description: formLink.description,
    url: formLink.url,
    form_link_id: formLink.form_link_id,
  })

  const { startLoading, stopLoading } = useLoading()
  const { renderError, renderSuccess, hideAlert } =
    useContext(CommonFunctionCtx)
  const urlSchema = z.string().url().safeParse(formLinkData.url)

  const changeHandler = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    statePreperty: string
  ) => {
    const uploadedFile = (e.target as HTMLInputElement).files?.[0]

    if (uploadedFile) {
      const fileType = uploadedFile.type.split("/")[1]
      let base64String = ""
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFile)
      reader.onload = () => {
        base64String = (reader.result as string)
          .split("\n")
          .map((data) => data.split(","))[0][1]
        setFormLinkData({
          base_64_string: base64String,
          file_extension: fileType,
          title: formLinkData.title,
          description: formLinkData.description,
          form_link_id: formLink.form_link_id,
        })
      }
    } else {
      setFormLinkData((prev) => {
        const { base_64_string, file_extension, ...rest } = prev // Exclude base_64_string if no file is uploaded
        return {
          ...rest,
          [statePreperty]: e.target.value,
        }
      })
    }
  }

  const updateFormRequest = () => {
    startLoading()
    FormRequests.updateFormLink(formLinkData)
      .then((data) => {
        setIsdialogOpen(false)
        renderSuccess(data.message)
        getFormList()
      })
      .catch((ex) => {
        setIsdialogOpen(false)
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const handleUpdateForm = () => {
    if (formLinkData.file_extension) {
      updateFormRequest()
    } else if (!formLinkData.file_extension) {
      if (urlSchema.success) {
        updateFormRequest()
      } else {
        renderError(
          `${urlSchema.error.issues[0].message}. Please check the form url and try again`
        )
        setTimeout(() => hideAlert(), 5000)
      }
    }
  }

  const formLinkIcon = (
    <Button size="icon" variant="ghost">
      {formLink.url?.includes("form-uploads") ? (
        <SvgFile width="25px" height="25px" />
      ) : (
        <SvgFormUrl width="20px" height="20px" />
      )}
    </Button>
  )

  return (
    <div className="w-full flex gap-y-3 justify-center bg-white rounded-lg main-shadow p-6 md:justify-between md:items-center flex-wrap">
      <h4 className="font-bold w-[100%] md:w-fit">{formLink.title}</h4>
      <p className="text-graySlate w-[100%] md:w-fit">{formLink.description}</p>
      <div className="flex items-center gap-2 justify-end w-[100%] md:w-fit">
        <a href={formLink.url} rel="noreferrer" target="_blank">
          {formLinkIcon}
        </a>
        <Button
          size="icon"
          variant="ghost"
          onClick={() => setIsdialogOpen(true)}
        >
          <SvgEdit />
        </Button>
        <Button
          size="icon"
          variant="ghost"
          onClick={() => deleteForm(formLink.form_link_id)}
        >
          <SvgTrash />
        </Button>
      </div>
      {isDialogOpen && (
        <FormDilog
          dialogTitle={"Update A Form"}
          modalCloseFn={setIsdialogOpen}
          changeHandler={changeHandler}
          formHandler={handleUpdateForm}
          formLinkData={formLinkData}
        />
      )}
    </div>
  )
}

export default LinkedFormsItem
