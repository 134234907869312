import type { SVGProps } from "react";
export const SvgQuestionCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12m10-4.2c-1.148 0-2 .852-2 2a1 1 0 1 1-2 0c0-2.252 1.748-4 4-4s4 1.748 4 4c0 1.906-1.25 3.45-3 3.88v.02a1 1 0 1 1-2 0v-.9a1 1 0 0 1 1-1c1.148 0 2-.852 2-2s-.852-2-2-2m0 7.5a1 1 0 0 1 1 1v.9a1 1 0 1 1-2 0v-.9a1 1 0 0 1 1-1"
      clipRule="evenodd"
    />
  </svg>
);
