import type { SVGProps } from "react";
export const SvgBundle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m19.11 5.623-5.4-3.15a3.5 3.5 0 0 0-3.51 0l-5.31 3.15C3.72 6.252 3 7.602 3 8.953v5.94c0 1.35.72 2.7 1.89 3.33l5.4 3.15c.54.27 1.08.45 1.71.45s1.17-.18 1.71-.45l5.4-3.15c1.17-.63 1.89-1.98 1.89-3.33v-5.94c0-1.35-.72-2.7-1.89-3.33"
    />
    <path
      fill="#fff"
      d="M19.11 7.962c-.27-.36-.81-.54-1.26-.36L12 10.842l-5.85-3.24c-.45-.18-.99 0-1.26.36s-.09.99.36 1.26l5.85 3.15v6.57c0 .54.36.9.9.9s.9-.36.9-.9v-6.57l5.85-3.15c.45-.27.54-.81.36-1.26"
    />
  </svg>
);
