import type { SVGProps } from "react";
export const SvgProblem = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 90 80"
    {...props}
  >
    <path
      fill="currentColor"
      d="M60.148 32.324V19.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 57.223 17H22.925a2.97 2.97 0 0 0-2.083.894A2.96 2.96 0 0 0 20 19.995v40.01c-.009.784.294 1.54.842 2.1a2.97 2.97 0 0 0 2.083.895h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L73.879 35.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
    />
    <g fill="#fff" filter="url(#error-img_svg__a)">
      <path d="M37.828 38.132a1.249 1.249 0 1 1-2.498.001 1.249 1.249 0 0 1 2.498 0M44.694 38.132a1.249 1.249 0 1 1-2.497.001 1.249 1.249 0 0 1 2.497 0M37.448 43.857a1.25 1.25 0 0 0 1.732-.347.85.85 0 0 1 .712-.38h.246c.286-.001.553.142.712.38a1.249 1.249 0 1 0 2.078-1.386 3.35 3.35 0 0 0-2.793-1.493h-.247c-1.12 0-2.166.56-2.786 1.493a1.25 1.25 0 0 0 .346 1.733M48.752 48.126c0-.712-.883-1.917-1.436-2.601a.564.564 0 0 0-.877 0c-.55.684-1.432 1.89-1.432 2.601a1.874 1.874 0 0 0 2.809 1.623c.579-.335.936-.953.936-1.623" />
      <path d="M49.641 35.668a6.42 6.42 0 0 0-5.306-5.309c-2.87-.479-5.8-.479-8.67 0a6.41 6.41 0 0 0-5.306 5.31 26.4 26.4 0 0 0 0 8.678 6.42 6.42 0 0 0 5.306 5.309c1.437.234 2.891.349 4.348.343q.492 0 .99-.018a1.25 1.25 0 0 0 .808-2.154 1.25 1.25 0 0 0-.922-.345q-.436.02-.876.019c-1.316.004-2.629-.1-3.927-.312a3.93 3.93 0 0 1-3.252-3.254 23.9 23.9 0 0 1 0-7.858 3.94 3.94 0 0 1 3.252-3.254 23.9 23.9 0 0 1 7.85 0 3.93 3.93 0 0 1 3.255 3.254c.212 1.299.316 2.613.312 3.929q0 .734-.047 1.47a1.253 1.253 0 0 0 2.497.182Q50 40.83 50 40.006c0-1.454-.12-2.905-.359-4.338" />
    </g>
    <defs>
      <filter
        id="error-img_svg__a"
        width={100}
        height={100}
        x={-10}
        y={-6}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={20} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2086_6342"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2086_6342"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
