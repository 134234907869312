import React from "react";
import ServiceUsageSummary from "./ServiceUsageSummary";
import ServiceUsageHistory from "./ServiceUsageHistory";
import moment from "moment";
import { ServiceRequests } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { UserCtx } from "../../../context/userContext";
import useLoading from "../../../hooks/useLoading";
import { SvgClose } from "../../icons";
import { getSessionType } from "../../../utils/services/get-session-type/get-session-type";
import { getSessionTypeImage } from "../../../utils/services/get-session-type-image";

interface Props {
  setServiceUsage: any;
  serviceUsage: any;
}

const ServiceUsagePopup: React.FC<Props> = ({
  setServiceUsage,
  serviceUsage,
}) => {
  const [selected, setSelected] = React.useState<string>("summary");
  const { user } = React.useContext(UserCtx);
  const timezone = user.timezone.value;
  const { renderError } = React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [serviceUsagePeriods, setServiceUsagePeriods] = React.useState<any[]>(
    []
  );
  const [serviceUsageHistory, setServiceUsageHistory] = React.useState<any[]>(
    []
  );
  const { service } = serviceUsage;

  const getServiceUsageDetails = () => {
    startLoading();
    ServiceRequests.getServiceUsageDetails({
      service_usage_id: service?.service_usage_id,
      service_type: service?.service_details?.type,
    })
      .then((data) => {
        setServiceUsagePeriods(data?.period_array);
        setServiceUsageHistory(data?.session_history);
        stopLoading();
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
      });
  };

  React.useEffect(() => {
    getServiceUsageDetails();
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen z-[801] 
            flex items-center justify-center pb-[160px] md:pb-[120px] zoee-backdrop-filter"
    >
      <div
        className="py-[16px] md:py-[24px] px-[16px] md:px-[36px] w-[760px] max-w-[90%] bg-white 
                main-shadow rounded-[16px] flex flex-col relative max-h-[95%] overflow-y-auto"
      >
        <button
          onClick={() => setServiceUsage({ show: false, service: {} })}
          className={`absolute top-[24px] right-[24px]`}
        >
          <SvgClose />
        </button>
        <h2 className="font-bold text-[22px] mb-[12px] mr-atuo">
          {service?.service_details?.title}
        </h2>
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-[6px] font-bold text-[16px]">
            {getSessionTypeImage(service?.service_details?.type)}
            {getSessionType(service?.service_details?.type)}
          </div>
          <p className="text-graySlate text-base">
            {service?.service_details?.expiration?.expires ? (
              <>
                {service?.service_details?.type === "recurring" ? (
                  <>
                    Program runs&nbsp;
                    {moment(service?.service_expires_at)
                      .subtract(
                        service?.service_details?.expiration?.time,
                        service?.service_details?.expiration?.type
                      )
                      .format("MMMM D, YYYY")}
                    &nbsp;to&nbsp;
                    {moment(service?.service_expires_at)
                      .tz(timezone)
                      .format("MMMM D, YYYY")}
                  </>
                ) : (
                  <>
                    Program expires{" "}
                    {moment(service?.service_expires_at)
                      .tz(timezone)
                      .format("MMMM D, YYYY")}
                  </>
                )}
              </>
            ) : (
              <>Program has no expiration.</>
            )}
          </p>
        </div>
        <div className="w-full h-[1px] bg-grayCloud my-[16px] md:my-[24px]"></div>

        {/* TAB NAVIGATION */}
        <div className="flex items-center justify-start gap-[12px] mb-[16px] md:mb-[32px]">
          <button
            onClick={() => setSelected("summary")}
            className={`${
              selected === "summary"
                ? "btn-primary btn-secondary-nav font-bold"
                : "px-[16px] text-graySlate font-bold text-base"
            }`}
          >
            Summary
          </button>
          <button
            onClick={() => setSelected("history")}
            className={`${
              selected === "history"
                ? "btn-primary btn-secondary-nav font-bold"
                : "px-[16px] text-graySlate font-bold text-base"
            }`}
          >
            History
          </button>
        </div>

        {/* SUMMARY VIEW */}
        {selected === "summary" && (
          <ServiceUsageSummary
            service={service}
            serviceUsagePeriods={serviceUsagePeriods}
          />
        )}
        {selected === "history" && (
          <ServiceUsageHistory
            service={service}
            serviceUsageHistory={serviceUsageHistory}
          />
        )}
      </div>
    </div>
  );
};

export default ServiceUsagePopup;
