import * as React from "react"
import { Link, useNavigate } from "react-router-dom"
import { ContactRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { UserCtx } from "../../context/userContext"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import useLoading from "../../hooks/useLoading"
import { CoachCard } from "../../models/public.interface"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { addCoachToCommissionTracking } from "../../utils/commissionTracking"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { useAuthModal } from "../auth/use-auth-modal"
import ProfileMessageForm from "../auth/register-form/profile-message-form"
import { RegisterFormProvider } from "../auth/register-form/context"
import { AuthContext } from "../auth/context"

export default function MarketplaceProfileCard({
  coach_public_profile_id,
  coach_profile_id,
  user_id,
  endpoint_slug,
  last_name,
  first_name,
  description,
  tags,
  avatar_url,
}: CoachCard) {
  const { email, firstName, lastName, accountType, password, phone } =
    React.useContext(AuthContext)

  const [profileMessageFormModal, setprofileMessageFormModal] =
    React.useState<boolean>(false)

  const { renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { showRegister } = useAuthModal()
  const { user, setUser } = React.useContext(UserCtx)
  const isAuthed = useAuthenticatedUser(user)
  const navigate = useNavigate()

  const handleClientSwitch = async (user: any) => {
    const activeProfile: "member" | "coach" | null = "member"
    const activeProfileId = user.memberProfile.profile_id
    const userObject = {
      ...user,
      activeProfile: activeProfile,
      activeProfileId: activeProfileId,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = (user: any) => {
    ContactRequests.createContactConnection({
      sender_profile_id: coach_profile_id,
      receiver_profile_id: user.activeProfileId,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: user?.isCoach ? "c2c" : "zoee lead", // if coach is connecting to a coach then change the status to c2c. idk if user.isCoach is a correct condition to work on. Recheck later.
    })
      .then(() => {
        user?.isCoach
          ? navigate(`/coach/inbox?chat=${coach_profile_id}`)
          : navigate(`/member/inbox?chat=${coach_profile_id}`)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
      .finally(() => {
        user?.isCoach
          ? navigate(`/coach/inbox?chat=${coach_profile_id}`)
          : navigate(`/member/inbox?chat=${coach_profile_id}`)
      })
  }

  const authenticateAndMessage = (coach_profile_id: number) => {
    const isOwner = coach_profile_id === user?.coachProfile?.profile_id
    console.log(isOwner)
    if (isOwner) {
      renderError(
        "Can't take action on profile page when logged into this account"
      )
      return
    }

    if (isAuthed) {
      startLoading()
      switchToMemberAndConnectAndRedirect(user, coach_profile_id)
    } else {
      showRegister({ accountType: "member" })
    }
  }

  const switchToMemberAndConnectAndRedirect = async (
    user: any,
    coach_profile_id: number
  ) => {
    // await handleClientSwitch(user).then(() => {
    connectAndRedirect(user)
    // });
  }

  return (
    <RegisterFormProvider
      defaultEmail={email}
      defaultAccountType={accountType}
      onSuccess={() => true}
      defaultPhone={phone}
      defaultFirstName={firstName}
      defaultLastName={lastName}
      defaultPassword={password}
    >
      <div className="shadow-custom flex flex-col overflow-hidden rounded-xl transition-all hover:scale-105">
        <div className="aspect-[8/7]">
          <Avatar className="h-full w-full rounded-none">
            <AvatarImage
              alt={`${first_name} ${last_name} profile picture`}
              src={`${avatar_url}?${new Date().getTime()}`}
              className="aspect-[8/7]"
            />
            <AvatarFallback className="text-4xl font-bold">
              {first_name.charAt(0).toUpperCase()}
              {last_name.charAt(0).toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </div>
        <div className="flex flex-1 flex-col justify-between gap-3 p-4">
          <span className="flex items-center justify-between">
            <h2 className="line-clamp-1 text-xl font-bold">
              {`${first_name} ${last_name}`}
            </h2>
          </span>
          <div className="flex h-6 gap-1 overflow-hidden">
            {tags.length > 0 ? (
              <>
                {tags.map((tag) => (
                  <Badge variant="outline" key={tag}>
                    {tag}
                  </Badge>
                ))}
              </>
            ) : (
              <>
                {[1, 2, 3].map((tag) => (
                  <Badge variant="outline" key={tag} className="w-20" />
                ))}
              </>
            )}
          </div>

          <p className="relative mb-2 line-clamp-3 flex-1 leading-relaxed">
            {description}
          </p>

          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <Button
              variant="secondary"
              onClick={() => {
                if (isAuthed) {
                  addCoachToCommissionTracking(endpoint_slug)
                  authenticateAndMessage(coach_profile_id)
                } else {
                  setprofileMessageFormModal(true)
                }
              }}
            >
              Message
            </Button>
            {profileMessageFormModal && (
              <ProfileMessageForm
                showConfirm={profileMessageFormModal}
                setShowConfirm={setprofileMessageFormModal}
                coachName={`${first_name} ${last_name}`}
                connectAndRedirect={connectAndRedirect}
              />
            )}
            <Button asChild>
              <Link
                to={`/find-a-coach/${endpoint_slug}`}
                onClick={() => {
                  addCoachToCommissionTracking(endpoint_slug)
                }}
              >
                View Profile
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </RegisterFormProvider>
  )
}
