import type { SVGProps } from "react";
export const SvgApple = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M47.161 34.45c-.07-7.035 5.737-10.41 5.997-10.58C49.894 19.1 44.81 18.444 43 18.37c-4.324-.44-8.44 2.546-10.634 2.546-2.189 0-5.578-2.482-9.163-2.42-4.717.07-9.064 2.744-11.491 6.966-4.898 8.497-1.253 21.09 3.52 27.986 2.333 3.371 5.116 7.164 8.767 7.025 3.518-.139 4.846-2.275 9.1-2.275s5.448 2.275 9.17 2.204c3.784-.069 6.183-3.435 8.497-6.82 2.679-3.915 3.782-7.707 3.847-7.9-.082-.038-7.376-2.832-7.452-11.232M40.168 13.805c1.936-2.35 3.248-5.616 2.891-8.867-2.794.113-6.176 1.857-8.182 4.205-1.797 2.084-3.369 5.402-2.948 8.594 3.117.242 6.296-1.586 8.239-3.932"
    />
  </svg>
);
