import React, { useRef, useEffect } from "react";
import ContactAvatar from "../ContactAvatar";
import ContactDropdown from "./ContactDropdown";
import ContactStatusTag from "../ContactStatusTag";
import useDate from "../../../hooks/useDate";
import useLoading from "../../../hooks/useLoading";
import { Button } from "../../ui/button";
import { getUserColor } from "../../../utils/getUserColor";
import { SvgChat, SvgChevronDown } from "../../icons";
import { useNavigate, Link } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";

interface Props {
  contact: any;
  setShowBilling: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactPrimaryDetails: React.FC<Props> = ({
  contact,
  setShowBilling,
}) => {
  const { user } = React.useContext(UserCtx);
  const { startLoading, stopLoading } = useLoading();
  const {
    contact_date,
    contact_name,
    contact_type,
    about,
    user_color,
    public_profile_published,
    endpoint_slug,
    status,
    profile_id,
  } = contact;
  const navigate = useNavigate();
  const { smDate, mdDate } = useDate();
  const [openChatModal, setOpenChatModal] = React.useState<boolean>(false);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const firstName = contact_name.split(" ")[0];

  const getStatus = () => {
    let profileStatus = "Unknown";
    if (status === "connected") {
      profileStatus = "active";
    } else if (status === "lead") {
      profileStatus = "lead";
    } else if (status === "zoee lead") {
      profileStatus = "zoee lead";
    } else if (status === "prospect") {
      profileStatus = "prospect";
    } else if (contact_type === "invited") {
      profileStatus = "invited";
    } else if (contact_type === "requested") {
      profileStatus = "requested";
    }
    return profileStatus;
  };

  const getTagline = () => {
    let value = "";
    if (about?.title) {
      value = about.title;
    } else if (contact_type === "invited") {
      value = `Invited ${smDate(contact_date)}`;
    } else {
      if (contact.profile_type === "member") {
        value = "Zoee Member";
      } else {
        value = "Professional Coach";
      }
    }
    return value;
  };

  const startMeetingChat = (contactId: string) => {
    const participantIds = [
      contactId.toString(),
      user.activeProfileId.toString(),
    ];
    setOpenChatModal(true);
    user.isCoach ?
    navigate(`/coach/inbox`, {
      state: {
        participantIds: participantIds,
      },
    }) : navigate(`/member/inbox`, {
      state: {
        participantIds: participantIds,
      },
    })
  };

  const handleStartCreateMeeting = (contact: any) => {
    setShowDropdown((prev) => !prev);
  };

  React.useEffect(() => {
    //Disables background scroll when chat modal is open
    if (openChatModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [openChatModal]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div
      className={`main-shadow flex flex-col rounded-[16px] bg-white
            p-[16px] md:p-[24px] md:pb-[40px]`}
    >
      <div
        className={`h-[114px] w-full rounded-[12px] md:h-[140px] ${getUserColor(
          user_color
        )} relative mb-[44px] md:mb-[32px]`}
      >
        <ContactStatusTag
          contactStatus={getStatus()}
          origin=""
          contactDate={contact_date}
        />
        <div
          className="absolute right-[8px] top-[8px] items-center gap-[6px] rounded-[8px]
                    bg-white px-[8px] py-[6px] text-base font-bold md:flex"
        >
          {`Connected since ${mdDate(contact_date)}`}
        </div>
        <div className="absolute left-[24px] top-[61px]">
          <div className="md:hidden">
            <ContactAvatar
              contact={contact}
              width={80}
              height={80}
              border="border border-[4px] border-white"
            />
          </div>
          <div className="hidden md:block">
            <ContactAvatar
              contact={contact}
              width={140}
              height={140}
              border="border border-[4px] border-white"
            />
          </div>
        </div>
      </div>
      <div className="mb-[73px] hidden w-full items-center justify-between md:flex">
        <div className="ml-[196px] flex flex-col gap-[8px]">
          <h4 className="text-[22px] font-bold">{contact_name}</h4>
          <p className="text-graySlate text-base">{getTagline()}</p>
          {Boolean(public_profile_published) && (
            <Link
              to={`/find-a-coach/${endpoint_slug}`}
              className="text-blurple mr-auto text-base font-bold"
            >
              View Public Profile
            </Link>
          )}
        </div>
        <div className="relative flex items-center gap-[12px]">
          <Button
            variant="default"
            size="lg"
            className="flex items-center justify-between w-[240px]"
            onClick={() => handleStartCreateMeeting(contact)}
          >
            <span className="truncate">{`Schedule with ${firstName}`}</span>
            <SvgChevronDown />
          </Button>
          <Button
            variant="secondary"
            onClick={() => startMeetingChat(contact.profile_id)}
            size="lg"
            className="flex items-center justify-between max-w-[200px]"
          >
            <span className="truncate">Message</span>
            <SvgChat fill="white" />
          </Button>

          {showDropdown && (
            <div ref={dropdownRef} className="absolute top-full left-0 mt-2">
              <ContactDropdown contact={contact} />
            </div>
          )}
        </div>
      </div>

      {/* {openChatModal && (
        <ContactProfileChatWindow setOpenChatModal={setOpenChatModal} />
      )} */}

      {about && Object.keys(about).length > 0 && (
        <div className="mx-[24px] hidden flex-col md:flex">
          <p className="text-graySlate mb-[16px] text-base font-bold">About</p>
          <h4 className="mb-[16px] text-[18px] font-bold">{about?.quote}</h4>
          <p className="text-graySlate text-base">{about?.description}</p>
        </div>
      )}
      {public_profile_published ? (
        <div className="mx-auto mb-[20px] flex md:hidden">
          <Link
            to={`/find-a-coach/${endpoint_slug}`}
            className="text-blurple mr-auto font-bold"
          >
            View Public Profile
          </Link>
        </div>
      ) : null}
      <div className="flex w-full items-center justify-center gap-[12px] md:hidden">
        <button
          onClick={() => setShowBilling(true)}
          className="btn-primary btn-outline-primary border-grayMist h-[40px] min-w-[150px]"
        >
          Billing
        </button>
        <button
          onClick={() => handleStartCreateMeeting(contact)}
          className="btn-primary btn-outline-primary border-grayMist h-[40px] min-w-[170px]"
        >
          Schedule Session
        </button>
      </div>
    </div>
  );
};

export default ContactPrimaryDetails;
