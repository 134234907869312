import type { SVGProps } from "react";
export const SvgVideoPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="currentColor"
      d="M32.63 8.381c-8.422-2.286-16.724-2.286-25.145 0-2.49.635-4.151 3.175-4.151 5.841v11.556c0 2.667 1.66 5.206 4.151 5.841 8.302 2.286 16.723 2.286 25.026 0 2.49-.635 4.15-3.174 4.15-5.841V14.222c.12-2.666-1.66-5.08-4.032-5.841m-8.54 13.08-4.982 3.555c-.237.127-.593.254-.83.254s-.474-.127-.711-.254c-.475-.254-.83-.889-.83-1.524v-6.984c0-.635.355-1.27.83-1.524.474-.254 1.186-.254 1.66.127l4.981 3.556c.475.38.712.889.712 1.396-.119.635-.356 1.143-.83 1.397"
    />
  </svg>
);
