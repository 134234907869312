import React, {type Dispatch, type SetStateAction } from "react";
import { SvgPlus } from "../icons/";

interface Props {
  setCreatePromoCode: Dispatch<SetStateAction<boolean>>
}

const EmptyPromoCodeList: React.FC<Props> = ({setCreatePromoCode}) => {
  return (
    <div className="flex px-[8%] items-center justify-center h-[399px] border border-1 border-grayMist rounded-[16px] bg-[#f8f8f8]">
      <div className="flex-col items-center justify-center gap-[10px]">
        <h2 className="font-bold text-center text-[21px]">
          Create promo codes to share with your clients.
        </h2>
        <button
          className="btn-primary btn-blue bg-blurple mx-auto mt-[20px] md:mt-[40px]"
          onClick={() => setCreatePromoCode(true)}
        >
          Create a Promo Code
          <SvgPlus />
        </button>
      </div>
    </div>
  );
};

export default EmptyPromoCodeList;
