export const getCommissionTracking = (): string[] => {
  const data = localStorage.getItem("commissionTracking");
  return data ? JSON.parse(data) : [];
};

export function isCoachInCommissionTracking(coachSlug: string) {
  const commissionTracking = getCommissionTracking();
  return commissionTracking.includes(coachSlug);
}

export function addCoachToCommissionTracking(coachSlug: string) {
  const commissionTracking = getCommissionTracking();
  if (!commissionTracking.includes(coachSlug)) {
    commissionTracking.push(coachSlug);
    localStorage.setItem(
      "commissionTracking",
      JSON.stringify(commissionTracking),
    );
  }
}

export function removeCoachFromCommissionTracking(coachSlug: string) {
  const commissionTracking = getCommissionTracking();
  const index = commissionTracking.indexOf(coachSlug);
  if (index !== -1) {
    commissionTracking.splice(index, 1);
    localStorage.setItem(
      "commissionTracking",
      JSON.stringify(commissionTracking),
    );
  }
}
