import type { SVGProps } from "react";
export const SvgMetrics = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.316 19.65a1.189 1.189 0 1 1-2.378 0 1.189 1.189 0 0 1 2.378 0M16.073 19.65a1.19 1.19 0 1 1-2.379 0 1.19 1.19 0 0 1 2.379 0"
    />
    <path
      fill="currentColor"
      d="M19.23 5.788a9.48 9.48 0 0 0-7.917-2.712 9.35 9.35 0 0 0-4.616 1.912 9.513 9.513 0 0 0-1.802 13.225 1.19 1.19 0 0 0 1.784.134 1.19 1.19 0 0 0 .095-1.564 7.13 7.13 0 0 1-1.347-5.217 7 7 0 0 1 1.38-3.344 7.13 7.13 0 0 1 7.185-2.675 6.7 6.7 0 0 1 2.244.891 7.153 7.153 0 0 1 1.977 10.36 1.19 1.19 0 0 0 .104 1.543 1.19 1.19 0 0 0 1.784-.14 9.51 9.51 0 0 0-.871-12.413"
    />
    <path
      fill="currentColor"
      d="M16.019 8.998a1.19 1.19 0 0 0-1.683 0l-2.675 2.675a1.19 1.19 0 1 0 1.683 1.683l2.675-2.675a1.19 1.19 0 0 0 0-1.683"
    />
  </svg>
);
