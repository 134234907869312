import * as React from "react";
import { SvgWarning } from "../icons";

interface Props {
  error: string | undefined;
}

const FieldError: React.FC<Props> = ({ error }) => {
  if (error) {
    return (
      <div className="flex gap-1 text-sm items-center text-vividRed mt-1">
        <SvgWarning />
        {error}
      </div>
    );
  }

  return null;
};

export { FieldError };
