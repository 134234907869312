import type { SVGProps } from "react";
export const SvgPromoCode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.223 14.115a1.108 1.108 0 1 1-2.217 0 1.108 1.108 0 0 1 2.217 0M10.992 9.884a1.108 1.108 0 1 1-2.217 0 1.108 1.108 0 0 1 2.217 0M9.545 15.223a.77.77 0 0 1-.544-1.314L13.91 9a.77.77 0 1 1 1.088 1.088l-4.91 4.91a.77.77 0 0 1-.543.225"
    />
    <path
      fill="currentColor"
      d="M12 22a2.76 2.76 0 0 1-1.956-.808l-.95-.95c-.23-.23-.542-.36-.867-.36H6.882a2.77 2.77 0 0 1-2.765-2.764v-1.346c0-.325-.129-.637-.36-.867l-.95-.95a2.77 2.77 0 0 1 0-3.91l.95-.952c.23-.229.36-.54.36-.866V6.882A2.77 2.77 0 0 1 6.88 4.117h1.346c.325 0 .637-.129.866-.36l.951-.95a2.77 2.77 0 0 1 3.91 0l.952.95c.229.23.54.36.866.36h1.345a2.77 2.77 0 0 1 2.765 2.764v1.346c0 .325.129.637.36.866l.95.951a2.77 2.77 0 0 1 0 3.91l-.95.951c-.231.23-.36.541-.36.866v1.346a2.77 2.77 0 0 1-2.765 2.765h-1.345c-.325-.001-.637.128-.867.359l-.95.95a2.76 2.76 0 0 1-1.956.809m0-18.462c-.326 0-.638.129-.868.359l-.95.949c-.517.52-1.221.811-1.954.81H6.882A1.23 1.23 0 0 0 5.656 6.88v1.347a2.75 2.75 0 0 1-.81 1.954l-.95.95a1.23 1.23 0 0 0 0 1.735l.95.95c.52.517.811 1.22.81 1.954v1.345a1.23 1.23 0 0 0 1.226 1.226h1.346a2.75 2.75 0 0 1 1.954.81l.95.95a1.23 1.23 0 0 0 1.735 0l.95-.95a2.75 2.75 0 0 1 1.954-.81h1.346a1.23 1.23 0 0 0 1.226-1.226v-1.345a2.75 2.75 0 0 1 .81-1.954l.95-.95a1.23 1.23 0 0 0 0-1.735l-.95-.95a2.75 2.75 0 0 1-.81-1.954V6.882a1.23 1.23 0 0 0-1.226-1.226h-1.346a2.75 2.75 0 0 1-1.954-.81l-.95-.95a1.22 1.22 0 0 0-.868-.358"
    />
  </svg>
);
