import previewImage from "../../../assets/blog/ventureAtlanta.webp";
import { Post } from "../../../types/postTypes";

export const ventureAtlanta: Post = {
    metadata: {
        id: 6,
        title: "Zoee Selected as a Venture Atlanta 2021 Showcase Company",
        author: "written by Zoee",
        date: "September 22, 2021",
        urlSlug: "zoee-selected-for-venture-atlanta-21",
        description:
            "Zoee announced today that it has been chosen out of a record-breaking 400-plus applicants as one of the top technology companies in the Southeast to be showcased at Venture Atlanta 2021.",
        previewImg: previewImage,
        tags: ["Company News"],
    },
    content: [
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Updated: October 2022",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Over $6.5 billion in funding awarded to date; the leading tech investment conference continues to select a large and diverse lineup of innovators from the Southeast’s hottest tech markets.",
        },
        {
            type: "paragraph",
            text: "ATLANTA – September 22, 2021",
        },
        {
            type: "paragraph",
            text: "Zoee announced today that it has been chosen out of a record-breaking 400-plus applicants as one of the top technology companies in the Southeast to be showcased at Venture Atlanta 2021, being held on October 20-21. For 14 years, the annual conference has been selecting the most promising tech companies and bringing in top investment firms from across the nation to hear them pitch. The annual conference has helped launch more than 500 companies and raise $6.5 billion in funding to date, serving the needs of the region’s vibrant tech community.",
        },
        {
            type: "paragraph",
            text: "“To be selected among such a highly competitive field of startups is an absolute honor. This conference is an incredible opportunity for us to share our vision with thought-leaders in the investor community and build meaningful relationships throughout the Southeast.” said Nichole Lowe, founder and CEO of Zoee.",
        },
        {
            type: "paragraph",
            text: "Zoee is a two-sided network for coaches and clients to find each other on a trustworthy platform using a proprietary matching algorithm. Bringing together essential product features and services, Zoee offers a distinctive all-in-one solution that meets a wide range of needs for coaches and their clients.",
        },
        {
            type: "paragraph",
            text: "Venture Atlanta has become the authority for recognizing technology innovation across the Southeast and beyond, connecting the best and brightest innovators with top-tier, national investors and other leaders in our tech ecosystem,” said Venture Atlanta CEO Allyson Eman. “We again set a new record with over 400 applicants for Venture Atlanta Momentum 2021—it’s our most competitive year ever and reflects yet another raising of the bar in terms of presenter quality.",
        },
        {
            type: "paragraph",
            text: "This year’s Venture Atlanta will be held in person with a limited number of tickets available and will also be offered online for those who wish to tune in virtually. For those attending in person, Venture Atlanta is back with its exceptional sessions, company presentations, networking opportunities, and outdoor dinner events.",
        },
        {
            type: "paragraph",
            text: "Building off last year’s successful virtual model, Venture Atlanta’s online experience offers a high-production-quality option that mirrors the benefits of the live conference. Virtual attendees can connect with others at the conference, set up one-on-one meetings, stroll the virtual show floor, watch pitches in real time, and interact through features like live chat and audience polling. Each ticket purchased provides attendees with on-demand access to all Venture Atlanta pitches and content for a full year.",
        },
        {
            type: "paragraph",
            text: "Invesco is this year’s premier sponsor, with Mailchimp and Stax as presenting sponsors. Venture Atlanta will conclude with the Atlanta Startup Battle, in which the top five ASB companies will pitch on stage against one another to win a $100,000 investment.",
        },
        {
            type: "paragraph",
            text: "To learn more about Zoee, visit www.zoee.com. For additional information about Venture Atlanta, to register for the event or to view the conference schedule, please visit www.ventureatlanta.org.",
        },
        {
            type: "header2",
            text: "About Venture Atlanta",
        },
        {
            type: "paragraph",
            text: "Venture Atlanta, the Southeast’s technology innovation event, is where the region’s most promising tech companies meet the country's top-tier investors. As the Southeast's largest investor showcase helping launch more than 500 companies and raise $6.5 billion in funding to date, the event connects the region’s top entrepreneurs with local and national investors and others in the technology ecosystem who can help them raise the capital they need to grow their businesses. The annual nonprofit event is a collaboration of the Atlanta CEO Council, Metro Atlanta Chamber, and the Technology Association of Georgia (TAG).",
        },
        {
            type: "paragraph",
            text: "For more information, visit www.ventureatlanta.org. For updates, follow us on Twitter and LinkedIn, and visit our blog.",
        },
        {
            type: "header2",
            text: "About Zoee",
        },
        {
            type: "paragraph",
            text: "Zoee is an all-inclusive virtual office and marketplace for coaches and their clients. We’re simplifying the way people connect, streamlining online business, and cultivating a community of members so no one has to do life alone.",
        },
        {
            type: "paragraph",
            text: "For questions, reach out to hello@zoee.com.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This article was written by Team Zoee.",
                },
            ],
        },
    ],
};
