import useScrollToTop from "../../hooks/useScrollToTop"

export default function AffiliateProgramTermsPage() {
  useScrollToTop()

  return (
    <div className="mx-auto mt-20 max-w-screen-2xl overflow-hidden">
      <div className="bg-grayFlash flex items-center justify-center py-16">
        <h1 className="px-6 text-center text-4xl font-bold md:max-w-prose">
          Zoee Affiliate Program Terms & Conditions
        </h1>
      </div>
      <div className="mx-auto my-16 px-6 text-lg md:max-w-prose">
        <h4 className="font-bold">Last Modified November 11, 2024</h4>
        <div className="h-8" />
        <p>
          THESE TERMS OF USE, TOGETHER WITH YOUR APPLICATION TO THE AFFILIATE
          PROGRAM (THE “AGREEMENT”) CONSTITUTES A BINDING CONTRACT AND GOVERNS
          YOUR USE OF AND ACCESS TO THE AFFILIATE PROGRAM.
        </p>
        <div className="h-4" />
        <p>
          PLEASE READ THE ENTIRE AGREEMENT. THIS IS A LEGAL AGREEMENT BETWEEN
          YOU AND (IF APPLICABLE) THE COMPANY YOU REPRESENT (collectively,
          “AFFILIATE” OR “YOU”, “YOUR”) AND SOLELIFE, INC, D/B/A ZOEE (“ZOEE” OR
          “WE”, “US”, “OUR”). BY SUBMITTING OUR ONLINE AFFILIATE APPLICATION
          FORM OR BY COPYING, DISPLAYING OR OTHERWISE USING OUR AFFILIATE LINK,
          YOU ARE AGREEING THAT YOU HAVE READ AND UNDERSTAND THESE TERMS AND
          CONDITIONS AND THAT YOU UNCONDITIONALLY AGREE TO BE LEGALLY BOUND BY
          ALL OF THE TERMS AND CONDITIONS CONTAINED HEREIN. IF YOU DO NOT
          UNCONDITIONALLY AGREE TO ALL OF THESE TERMS, YOU HAVE NO RIGHT TO
          PARTICIPATE IN OUR AFFILIATE PROGRAM.
        </p>
        <div className="h-4" />
        <p>
          We may update these terms at any time at our sole discretion. We might
          also choose to replace these terms in their entirety if, for example,
          Zoee’s affiliate program (the “Affiliate Program”) changes, ends, or
          becomes part of an existing program. If we update or replace these
          terms, we will let you know via electronic means, which may include an
          in-app notification or by email. If any modification is unacceptable
          to you, your only option is to terminate this Agreement. Your
          continued participation in the Affiliate Program following the posting
          of the change notice or new Agreement on our website will indicate
          your agreement to the changes.
        </p>
        <div className="h-4" />
        <p>
          Either you or we may terminate this Agreement at any time, with or
          without cause, by giving the other party written notice. Written
          notice can be in the form of mail, email or fax. In addition, this
          Agreement will terminate immediately upon any breach of this Agreement
          by you.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Eligibility</h2>
        <div className="h-4" />
        <p>
          The Affiliate Program is void where such affiliate programs are
          prohibited. Users who refer others to the program are "Affiliates";
          those who are referred are "Referred Customers." Affiliates may be
          eligible to receive Monetary Rewards (as defined below) for every
          qualified referral. Affiliates must speak and read English, be legally
          able to participate in the Affiliate Program, and must 1) be at least
          the age of majority where they reside, 2) have an existing, valid Zoee
          Coach account, 3) have a connected bank account to their Zoee account,
          and 4) be otherwise in good standing with Zoee. Zoee reserves the
          right to determine if an Affiliate’s Zoee user account is valid.
        </p>

        <div className="h-8" />
        <h2 className="font-bold">Affiliate Obligations</h2>
        <div className="h-4" />
        <p>
          The maintenance and the updating of your site will be your
          responsibility. It is entirely your responsibility to follow all
          applicable intellectual property and other laws that pertain to your
          site. You must have express permission to use any person's copyrighted
          material, whether it be a writing, an image, or any other
          copyrightable work. We will not be responsible (and you will be solely
          responsible and will fully indemnify us) if you use another person's
          copyrighted material or other intellectual property in violation of
          the law or any third party rights.
        </p>
        <div className="h-4" />
        <p>
          You acknowledge and agree that Zoee may collect, use, and disclose
          your personal information, in accordance with Zoee’s Privacy Policy as
          set forth at{" "}
          <a
            className="text-blueVibrant underline"
            href="https://zoee.com/privacy-policy"
            target={`_blank`}
          >
            https://zoee.com/privacy-policy
          </a>
          .
        </p>
        <div className="h-4" />
        <p>
          We have the right to monitor your site at any time to determine if you
          are following the terms and conditions of this Agreement. We may
          notify you of any changes to your site that we feel should be made in
          order to comply with our Affiliate Program and this Agreement. If you
          do not make the changes to your site that we feel are necessary, we
          reserve the right to terminate your participation in the Affiliate
          Program.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Conditions for Receiving Referral Rewards</h2>
        <div className="h-4" />
        <p>
          Monetary Rewards (as defined below) may be awarded for referrals that
          meet the following conditions (a “Qualified Referral”):
        </p>
        <div className="h-4" />
        <ol className="ml-8 list-decimal">
          <li>
            The Referred Customer must use the Personal Link or Code from an
            Affiliate in good standing with Zoee.
          </li>
          <li>
            If a Referred Customer receives more than one Personal Link or Code,
            Zoee will provide the Monetary Reward to the person whose Personal
            Link or Code is used to complete the Zoee subscription transaction
            regardless of when the Personal Links were sent. If a Referred
            Customer registers for a subscription using any other method, the
            membership will not count as a Qualified Referral and the Affiliate
            will not earn Monetary Rewards.
          </li>
          <li>
            The Referred Customer may not combine the link with any other
            monetary offer.
          </li>
          <li>
            The Referred Customer must not, at any time within the prior 12
            months, have been registered with a Zoee coach account under any
            email address or alias.
          </li>
          <li>
            The Referred Customer must be eligible to create a Zoee account and
            otherwise be qualified.
          </li>
          <li>
            The Referred Customer must purchase a membership under an account
            using a valid and current email address that has not been used to
            register for Zoee services within the last 12 months. If the
            Referred Customer is on a free trial, such customer will not qualify
            as a Qualified Referral.
          </li>
        </ol>
        <div className="h-4" />
        <p>When a Qualified Referral is successfully made:</p>
        <ol className="ml-8 list-decimal">
          <li>
            The Affiliate will receive a $20 reward, which will be deposited
            directly into the bank account linked to their Coach account with
            Zoee.
          </li>
          <li>
            The Referred Customer will receive a 20% discount on their Zoee
            Subscription:
            <ul className="ml-8 list-disc">
              <li>For a one-month subscription: 20% off the first month</li>
              <li>
                For a one-year subscription: 20% off the entire first year
              </li>
            </ul>
          </li>
        </ol>
        <div className="h-4" />
        <p>Please note:</p>
        <ol className="ml-8 list-disc">
          <li>
            The Referred Customer will pay the full, regular price upon renewal.
          </li>
          <li>All Monetary Rewards are subject to verification.</li>
          <li>
            Rewards will typically be awarded within 60 days of successful
            verification.
          </li>
        </ol>
        <div className="h-4" />
        <p>
          You must sync your bank account to your Zoee account in order to be
          eligible to receive any Monetary Rewards. Failure to sync your bank
          account may result in you not receiving your Monetary Rewards.
        </p>
        <div className="h-4" />
        <p>
          Payments to you may be withheld to reflect or adjusted to exclude any
          amounts arising from breach of this Agreement or other invalid,
          fraudulent or abusive activity, as determined by us in our sole
          discretion. If you dispute any payment made or withheld relating to
          the Affiliate Program, you must notify us in writing within 30 days of
          any such payment. If you do not notify us in writing, any claim
          relating to the disputed payment is waived. To ensure proper payment,
          you are responsible for providing and maintaining accurate contact and
          payment information in your Zoee account and providing us all
          information we may reasonably request to comply with tax or other laws
          or regulations. You are responsible for any charges assessed by your
          bank or payment provider. You are responsible for all taxes relating
          to this Agreement, except taxes on our net income.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Intellectual Property</h2>
        <div className="h-4" />
        <p>
          Zoee retains sole and exclusive ownership of all right, title and
          interest in and to the Affiliate Program and all associated
          intellectual property rights. No rights or licenses are granted to you
          with respect to the Affiliate Program.
        </p>
        <div className="h-4" />
        <p>
          You grant Zoee a non-exclusive right to use your name and trademarks
          to identify you as a participant in our Affiliate Program and for
          other marketing purposes.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Disclaimers</h2>
        <div className="h-4" />
        <p>
          Under no circumstances should an Affiliate run ads promoting their
          affiliate link directly. An Affiliate may choose to run ads if the ad:
        </p>
        <div className="h-4" />
        <ul className="ml-8 list-disc">
          <li>Points to your own domain—Not the Zoee domain</li>
          <li>
            Points to content that includes your affiliate link—Not linked
            directly to your referral link
          </li>
          <li>
            Includes your branding—Do not run ads posing as the Zoee brand
          </li>
        </ul>
        <div className="h-4" />
        <p>
          The Affiliate must disclose when they may be compensated for making a
          recommendation for Zoee. This disclosure applies to all promotional
          materials such as email, social posts, and other content.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Liability Release</h2>
        <div className="h-4" />
        <p>
          ZOEE MAKES NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES
          REGARDING ZOEE’S WEBSITE OR APPLICATIONS OR THE PRODUCTS OR SERVICES
          PROVIDED THEREIN OR ANY OTHER SUBJECT MATTER OF THIS AGREEMENT, AND
          ZOEE HEREBY FULLY DISCLAIMS AND ANY AND ALL WARRANTIES, INCLUDING THE
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. IN ADDITION, WE MAKE NO REPRESENTATION REGARDING THE
          RESULTS OF YOUR PARTICIPATION IN OUR AFFILIATE PROGRAM OR THAT THE
          OPERATION OF OUR SITE WILL BE UNINTERRUPTED OR ERROR FREE, AND WE WILL
          NOT BE LIABLE FOR THE CONSEQUENCES OF ANY INTERRUPTIONS OR ERRORS.
        </p>
        <div className="h-4" />
        <p>
          WE WILL NOT BE LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS
          AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY OR
          OTHER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES (INCLUDING, WITHOUT
          LIMITATION, LOSS OF REVENUE OR GOODWILL OR ANTICIPATED PROFITS OR LOST
          BUSINESS), EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Indemnification</h2>
        <div className="h-4" />
        <p>
          You hereby agree to indemnify and hold harmless Zoee, and its
          subsidiaries and affiliates, and their directors, officers, employees,
          agents, shareholders, partners, members, and other owners, against any
          and all claims, actions, demands, liabilities, losses, damages,
          judgments, settlements, costs, and expenses (including reasonable
          attorneys' fees) (any or all of the foregoing hereinafter referred to
          as "Losses") arising out of or relating to your activities in
          connection with this Agreement or your participation in the Affiliate
          Program, including, without limitation, any Losses relating to (i) any
          claim that our use of your trademarks or other provided materials
          infringes on any intellectual property or proprietary right of any
          third party, (ii) any misrepresentation, fraud, abuse, negligence or
          breach of this Agreement by you, or (iii) any claim relating to your
          site, products or services.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Miscellaneous</h2>
        <div className="h-4" />
        <p>
          You agree that nothing in this Agreement will create any partnership,
          joint venture, agency, franchise, sales representative, or employment
          relationship between You and Zoee. You will have no authority to make
          or accept any offers or representations on our behalf and you will not
          make any statement that implies anything to the contrary. Neither
          party may assign its rights or obligations under this Agreement to any
          party, except that we may assign this Agreement to a successor to all
          or substantially all of our assets or business.
        </p>
        <div className="h-4" />
        <p>
          This Agreement shall be governed by and construed under the internal
          laws of the State of North Carolina without reference to conflict of
          law principles. Any disputes under this Agreement shall be resolved in
          a court of general jurisdiction in Wake County, North Carolina. You
          expressly agree to submit to the exclusive personal jurisdiction of
          this jurisdiction for the purposes of resolving any dispute relating
          to this Agreement or access to or use of the Affiliate Program.
        </p>
        <div className="h-8" />
        <h2 className="font-bold">Reservations of Rights</h2>
        <div className="h-4" />
        <p>
          We reserve the right to modify or amend at any time these terms and
          conditions and the methods through which Monetary Rewards are earned.
          We reserve the right to disqualify any Affiliate at any time from
          participation in the Affiliate Program if they do not comply with any
          of these terms. Zoee’s failure to enforce any term of these terms
          shall not constitute a waiver of that provision.
        </p>
        <div className="h-4" />
        <p>
          CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE OR UNDERMINE THE
          LEGITIMATE OPERATION OF THE AFFILIATE PROGRAM MAY BE IN VIOLATION OF
          CRIMINAL AND CIVIL LAWS AND WILL RESULT IN DISQUALIFICATION FROM
          PARTICIPATION IN THE AFFILIATE PROGRAM. SHOULD SUCH AN ATTEMPT BE
          MADE, ZOEE RESERVES THE RIGHT TO SEEK REMEDIES AND DAMAGES (INCLUDING
          ATTORNEY FEES) TO THE FULLEST EXTENT OF THE LAW, INCLUDING CRIMINAL
          PROSECUTION.
        </p>
      </div>
    </div>
  )
}
