import React from 'react';
import {
    useHMSStore,
    selectScreenShareByPeerID,
    useVideo,
    selectPeers
  } from "@100mslive/react-sdk";
import ScreensharePeer from "./ScreensharePeer";
import { SessionContext } from "../../pages/sessions/context/sessionContext";

interface Props {
    presenter: any;
}

const Screenshare:React.FC<Props> = ({presenter}) => {
    const peers = useHMSStore(selectPeers);
    const screenshareVideoTrack = useHMSStore(selectScreenShareByPeerID(presenter.id));
    const { videoRef } = useVideo({
        trackId: screenshareVideoTrack.id
    });
    const { mobileView } = React.useContext(SessionContext);
    
    return (
        <div
            className='absolute top-0 left-0 w-full h-full z-[10] bg-black'
        >
            <div
                className="w-full md:w-auto text-center absolute top-0 left-[50%] -translate-x-[50%] z-[100]
                bg-primaryBlue text-white py-[4px] px-[8px] md:rounded-b-[4px] font-bold"
            >
                {presenter.name + " is sharing their screen"}
            </div>
            <video className='w-full h-full' ref={videoRef} autoPlay muted playsInline></video>
            {!mobileView && (
                <>
                    <ScreensharePeer peer={presenter} peerType="presenter" />
                    <ScreensharePeer peer={peers.filter(peer => peer.isLocal)[0]} peerType="self" />
                </>
            )}
        </div>
    );
}

export default Screenshare;
