import React from "react";
import NotePreview from "./NotePreview";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { NoteRequests } from "../../../api/app.service";
import NotesInfo from "./NotesInfo";
import NoteModal from "../../notes/NoteModal";
import useLoading from "../../../hooks/useLoading";
import { Button } from "../../ui/button";
import { SvgInformationCircle } from "../../icons";

interface Props {
  isNewNote: boolean;
  setIsNewNote: any;
  contact: any;
  setShowNotesPopover: any;
  callGetMemberNotes: boolean;
  setCallGetMemberNotes: React.Dispatch<React.SetStateAction<boolean>>;
  showNoteModal: boolean;
  setShowNoteModal: any;
}

const NotesPreviewElement: React.FC<Props> = ({
  contact,
  setIsNewNote,
  setShowNotesPopover,
  callGetMemberNotes,
  setCallGetMemberNotes,
  showNoteModal,
  setShowNoteModal,
}) => {
  const { user } = React.useContext(UserCtx);
  const { renderError } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [notes, setNotes] = React.useState<any>(null);
  const [notesInfo, setNotesInfo] = React.useState<boolean>(false);
  const [isCreatingNewNote, setIsCreatingNewNote] =
    React.useState<boolean>(false);
  const [activeNoteId, setActiveNoteId] = React.useState<number | null>(null);
  const [triggerPopoverUpdate, setTriggerPopoverUpdate] =
    React.useState<number>(0);

  const getMemberNotes = async () => {
    if (user) {
      await NoteRequests.getMemberNotes({
        writer_profile_id: user.activeProfileId,
        relates_to_profile_id: contact.profile_id,
      })
        .then((data) => {
          setNotes(data.notes);
          stopLoading();
        })
        .catch((ex) => {
          console.log(ex);
          stopLoading();
          renderError(ex.response.data.message);
        });
    }
  };

  const sortedNotes = notes?.sort((a: any, b: any) => {
    const bDate: any = new Date(b.updated_at);
    const aDate: any = new Date(a.updated_at);
    return bDate - aDate;
  });

  React.useEffect(() => {
    if (!notes) {
      getMemberNotes();
    }
  }, []);

  React.useEffect(() => {
    if (callGetMemberNotes) {
      getMemberNotes();
      setCallGetMemberNotes(false);
    }
  }, [callGetMemberNotes]);

  return (
    <>
      {notesInfo && <NotesInfo setNotesInfo={setNotesInfo} isCoach />}
      <div className="w-full main-shadow rounded-[16px] p-[16px] md:p-[40px] sm:p-[20px] md:h-[500px]">
        <div className="flex items-center justify-between mb-[24px]">
          <div>
            <div className="flex items-center gap-[8px]">
              <h2 className="font-semibold text-[18px] md:text-[28px]">
                My Notes
                <button className="p-2 text-graySlate hover:bg-grayFlash duration-150 rounded-[10px]" onClick={() => setNotesInfo(true)}>
                  <SvgInformationCircle fill="black" />
                </button>
              </h2>
            </div>
            <p className="text-base text-graySlate font-bold">
              Private and Confidential
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-end sm:items-center gap-[8px]">
            <div>
              <Button
                onClick={() => {
                  setIsCreatingNewNote(true);
                  setShowNoteModal(true);
                  // setShowNotesPopover(true);
                }}
                disabled={showNoteModal}
              >
                New Note
              </Button>
            </div>
            <Button
              onClick={() => setShowNotesPopover(true)}
              variant="secondary"
            >
              See All
            </Button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-[16px] md:gap-[4px]">
          {sortedNotes?.length > 0 ? (
            <>
              {sortedNotes?.slice(0, 3).map((note: any) => (
                <div key={note.id}>
                  <NotePreview
                    note={note}
                    setActiveNoteId={setActiveNoteId}
                    setIsNewNote={setIsNewNote}
                    setShowNoteModal={setShowNoteModal}
                    setTriggerPopoverUpdate={setTriggerPopoverUpdate}
					          getMemberNotes={getMemberNotes}
                    activeNoteId={activeNoteId}
                  />
                </div>
              ))}
            </>
          ) : (
            <div>Let's get started!</div>
          )}
        </div>
        {/* NOTES EDITOR MODAL */}
        {(activeNoteId || isCreatingNewNote) && showNoteModal && (
          <NoteModal
            note={notes?.find((n: any) => n.id === activeNoteId)}
            setActiveNoteId={setActiveNoteId}
            setShowNoteModal={setShowNoteModal}
            isNewNote={isCreatingNewNote}
            setIsNewNote={setIsCreatingNewNote}
            user={user}
            contact={contact}
			      getMemberNotes={getMemberNotes}
            getNotes
          />
        )}
        {/* EDITOR MODAL NOTES END */}
      </div>
    </>
  );
};

export default NotesPreviewElement;
