import { FC, useContext, useRef, useState } from "react"
import Maximize from "../ui/Maximize"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { SvgAudio, SvgAudioOff, SvgMaximize, SvgPause, SvgPlay } from "../icons"

interface Props {}

const PublicAvatarVideo: FC<Props> = (props: any) => {
  const { coachPublicProfileData, setLargeVideo, largeVideo } = useContext(
    CoachPublicProfileContext
  )
  const videoRef = useRef<HTMLVideoElement>(null)
  const [profileVideoPlaying, setProfileVideoPlaying] = useState<boolean>(false)
  const [profileAudioMuted, setProfileAudioMuted] = useState<boolean>(false)
  const [maximize, setMaximize] = useState<boolean>(false)

  const videoHandler = (control: any) => {
    if (control === "play") {
      videoRef?.current?.play()
      setProfileVideoPlaying(true)
    } else if (control === "pause") {
      videoRef?.current?.pause()
      setProfileVideoPlaying(false)
    }
  }

  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4"
    } else {
      return extension
    }
  }

  return (
    <div className="w-full h-full rounded-[16px] flex items-center relative group bg-gray">
      <div
        className="absolute bottom-[8px] left-[50%] -translate-x-[50%] flex sm:hidden sm:group-hover:flex z-[20]
                items-center w-full sm:justify-evenly gap-[8px] justify-center sm:gap-0"
      >
        <button
          className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-gray"
          onClick={(e: any) => {
            if (profileVideoPlaying) {
              videoHandler("pause")
            } else {
              videoHandler("play")
            }
          }}
        >
          {profileVideoPlaying ? (
            <SvgPause className="w-[32px]" />
          ) : (
            <SvgPlay />
          )}
        </button>
        <button
          className="w-[32px] h-[32px] rounded-[8px] hidden sm:flex items-center justify-center bg-gray"
          onClick={(e: any) => {
            videoHandler("pause")
            setLargeVideo({
              show: true,
              videos: [
                {
                  id: coachPublicProfileData?.main_video_id,
                  isMain: true,
                  video_extension: coachPublicProfileData?.main_video_extension,
                },
              ],
              selectedIndex: 0,
            })
            setMaximize(true)
          }}
        >
          <SvgMaximize fill="white" />
        </button>
        <button
          className="w-[32px] h-[32px] rounded-[8px] flex items-center justify-center bg-gray"
          onClick={() => setProfileAudioMuted(!profileAudioMuted)}
        >
          {profileAudioMuted ? <SvgAudioOff /> : <SvgAudio />}
        </button>
      </div>
      <video
        ref={videoRef}
        className="w-full h-full object-cover rounded-[16px]"
        muted={profileAudioMuted}
        onEnded={() => videoHandler("pause")}
      >
        <source
          src={`https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.coach_public_profile_id}_main_${coachPublicProfileData?.main_video_id}.${coachPublicProfileData?.main_video_extension}`}
          type={`video/${getVideoType(
            coachPublicProfileData?.main_video_extension
          )}`}
        />
        Sorry, your browser doesn't support embedded videos.
      </video>
      {maximize && (
        <>
          <Maximize
            videoSrc={largeVideo}
            onClose={setMaximize}
            coachPublicProfileId={
              coachPublicProfileData?.coach_public_profile_id
            }
            coachPublicProfileData={coachPublicProfileData}
            isMainVideo={true}
          />
        </>
      )}
    </div>
  )
}

export default PublicAvatarVideo
