import React, { useEffect, useState } from "react";
import { SvgCheckbox, SvgCheckboxOff } from "../icons";

interface ChatCheckboxProps {
  newChatMembers: string[];
  setNewChatMembers: (newChatMembers: string[]) => void;
  contact: any;
}

const ChatCheckbox: React.FC<ChatCheckboxProps> = ({
  setNewChatMembers,
  newChatMembers,
  contact,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagedId = e.target.id.toString();
    const strId = contact.profile_id.toString();

    setIsChecked(tagedId === strId ? !isChecked : isChecked);

    if (e.target.checked) {
      setNewChatMembers([...newChatMembers, strId]);
    } else {
      setNewChatMembers(newChatMembers.filter((id) => id !== tagedId));
    }
  };

  useEffect(() => {
    setNewChatMembers([])
  }, [])

  return (
    <label className="flex items-center font-bold text-lg max-w-full w-[calc(100%-64px)] justify-between">
      <p className="max-w-[75%] w-[175px] truncate">{contact.contact_name}</p>
      {isChecked ? <SvgCheckbox /> : <SvgCheckboxOff />}
      <input
        id={contact.profile_id}
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={(e) => changeHandler(e)}
      />
    </label>
  );
};

export default ChatCheckbox;
