import React from "react";
import { cn } from "../utils/cn";
import { SvgClose } from "./icons/";
import { Button } from "./ui/button";

interface Props {
  styling?: string;
  callback: any;
}

const CloseModal: React.FC<Props> = ({ styling, callback }) => {
  return (
    <Button
      onClick={() => callback(false)}
      size="icon"
      variant="ghost"
      className={cn("rounded-full", styling)}
    >
      <SvgClose />
    </Button>
  );
};

export default CloseModal;
