import React, { FC } from "react"
import AttendanceModal from "./AttendanceModal"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { SessionsRequests } from "../../../api/app.service"
import { SessionContext } from "../../../pages/sessions/context/sessionContext"
import moment from "moment"
import { parseISO } from "date-fns"
import useScrollToTop from "../../../hooks/useScrollToTop"
import BackToButton from "../../BackToButton"
import { getUserColor } from "../../../utils/getUserColor"
import useLoading from "../../../hooks/useLoading"
import useName from "../../../hooks/useName"
import { Button } from "../../ui/button"
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard"
import {
  SvgBundle,
  SvgConsultation,
  SvgCopy,
  SvgDownload,
  SvgRecord,
  SvgRecurring,
  SvgUserCheck,
} from "../../icons"
import { Badge } from "../../ui/badge"
import { SvgVideoSolid } from "../../icons"
import { getUnits } from "../../../utils/services/get-units/get-units"
import { getPrice } from "../../../utils/services/get-price"

interface Props {
  detailedPastSession: any
  setDetailedPastSession: any
  setShowAttachService: any
  setServiceChangeType: any
}

const DetailedPastSession: FC<Props> = ({
  detailedPastSession,
  setDetailedPastSession,
  setShowAttachService,
  setServiceChangeType,
}) => {
  const [viewAttendance, setViewAttendance] = React.useState<boolean>(false)
  const [sessionData, setSessionData] = React.useState<any>(null)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { mobileView } = React.useContext(SessionContext)
  const { billing, session, type, image } =
    sessionData?.session_data?.service_details || {}

  const {
    room_id,
    meeting_name,
    participant_list,
    session_end_time,
    session_start_time,
    service_details,
  } = detailedPastSession.session
  const { user } = React.useContext(UserCtx)
  const { initial } = useName()
  const [, copy] = useCopyToClipboard()

  const backToPastSessions = () => {
    setDetailedPastSession({ show: false, session: {} })
  }

  const getParticipantCount = () => {
    return `${participant_list.length - 4}`
  }

  const getSessionMinutes = () => {
    return Math.floor(sessionData?.session_data?.duration / 60)
  }

  const getServiceType = () => {
    if (type === "consultation") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgConsultation />
          Consultation Session
        </div>
      )
    } else if (type === "individual") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgUserCheck />
          Individual Session
        </div>
      )
    } else if (type === "bundle") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgBundle />
          Bundle Package
        </div>
      )
    } else if (type === "recurring") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgRecurring />
          Recurring Program
        </div>
      )
    }
  }

  const getTimezoneAdjustedTime = (time: any) => {
    return moment.utc(time).tz(user.timezone.value).format("h:mm a")
  }

  const getParticipantColor = (participant: any) => {
    return `bg-${participant.user_color}`
  }

  const getServiceColor = () => {
    return `${image?.color || "bg-lightPurple"}`
  }

  const getSessionDetails = () => {
    if (user) {
      startLoading()
      SessionsRequests.getPastSessionDetail({
        room_id: room_id,
      })
        .then((data: any) => {
          setSessionData(data)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  const handleEditServiceClick = () => {
    setDetailedPastSession({ ...detailedPastSession, show: false })
    setServiceChangeType("update")
    setShowAttachService(true)
  }

  const handleAttachServiceClick = () => {
    setDetailedPastSession({ ...detailedPastSession, show: false })
    setServiceChangeType("attach")
    setShowAttachService(true)
  }

  const handleRemoveServiceClick = () => {
    if (user) {
      startLoading()
      SessionsRequests.sessionDeleteService({
        room_id: room_id,
        service_usage_id: parseInt(sessionData?.session_data?.service_usage_id),
      })
        .then((data: any) => {
          renderSuccess("Removed service from the session")
          getSessionDetails()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const parseLogItems = () => {
    const userLogs = sessionData?.details_list
    const logItems: any[] = []
    userLogs.forEach((log: any) => {
      logItems.push({
        firstName: log.first_name,
        lastName: log.last_name,
        hasAvatar: log.has_avatar,
        logType: "joined",
        userColor: log.user_color,
        logTime: log.zoom_join_time,
        userId: log.user_id,
      })
      logItems.push({
        firstName: log.first_name,
        lastName: log.last_name,
        hasAvatar: log.has_avatar,
        logType: "left",
        userColor: log.user_color,
        logTime: log.zoom_leave_time,
        userId: log.user_id,
      })
    })
    const sortedLogs = logItems.sort((a, b) => {
      return parseISO(a.logTime).getTime() - parseISO(b.logTime).getTime()
    })
    return sortedLogs
  }

  React.useEffect(() => {
    getSessionDetails()
  }, [])

  useScrollToTop()

  function formatSize(sizeInBytes: number) {
    const kiloBytes = sizeInBytes / 1024
    const megaBytes = kiloBytes / 1024
    const gigaBytes = megaBytes / 1024

    if (gigaBytes >= 1) {
      return gigaBytes.toFixed(2) + " GB"
    } else if (megaBytes >= 1) {
      return megaBytes.toFixed(2) + " MB"
    } else if (kiloBytes >= 1) {
      return kiloBytes.toFixed(2) + " KB"
    } else {
      return sizeInBytes + " Bytes"
    }
  }

  function formatDuration(seconds: number) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    const formattedDuration = [
      hours > 0 ? hours + "h" : "",
      minutes > 0 ? minutes + "m" : "",
      remainingSeconds > 0 ? remainingSeconds + "s" : "",
    ].join(" ")

    return formattedDuration.trim()
  }

  return (
    <>
      {viewAttendance && (
        <AttendanceModal
          participants={participant_list}
          setViewAttendance={setViewAttendance}
          logItems={parseLogItems()}
          detailedPastSession={detailedPastSession}
        />
      )}
      <div className="w-[335px] md:w-[744px] mx-auto md:pb-[100px] -mt-0 md:-mt-[43px]">
        <BackToButton
          current="Session Details"
          prev="Past Sessions"
          onClick={backToPastSessions}
        />
        <div className="flex flex-col">
          <span className="text-graySlate font-bold mb-[24px]">
            SESSION DETAILS
          </span>
          <h2 className="font-semibold text-[28px] mb-[16px]">
            {meeting_name}
          </h2>
          {service_details === null && (
            <span className="text-graySlate font-bold mb-[24px]">
              No Program
            </span>
          )}
          <div className="flex items-center gap-[24px] mb-[32px]">
            <Badge>
              <SvgVideoSolid className="mr-2" />
              {`${!mobileView ? "Lasted" : ""} ${getSessionMinutes()} min`}
            </Badge>

            <div className="flex items-center gap-[12px] text-graySlate text-base">
              <div>
                {!mobileView ? "Started at " : ""}
                <span className="font-bold text-black">
                  {getTimezoneAdjustedTime(session_start_time)}
                </span>
              </div>
              <span>{mobileView ? "to" : "-"}</span>
              <div>
                {!mobileView ? "Ended at " : ""}
                <span className="font-bold text-black">
                  {getTimezoneAdjustedTime(session_end_time)}
                </span>
              </div>

              {Boolean(sessionData?.recording_assets?.length) && (
                <SvgRecord className="text-vividRed" />
              )}
            </div>
          </div>
          <div
            className="w-full flex items-center justify-between py-[24px]
                        border-y border-grayMist mb-[32px]"
          >
            <div className="flex items-center gap-[12px]">
              {sessionData?.session_data?.total_participant_count > 1 ? (
                <>
                  {participant_list
                    .slice(0, 4)
                    .map((participant: any, idx: number) => (
                      <div className="w-[40px] h-[40px]" key={idx}>
                        {participant.has_avatar ? (
                          <img
                            src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${participant?.user_id}`}
                            alt="avatar"
                            className="w-full h-full rounded-full object-cover"
                          />
                        ) : (
                          <div
                            className={`flex items-center justify-center rounded-full 
                                                    w-full h-full text-[18px] ${getParticipantColor(
                                                      participant
                                                    )}`}
                          >
                            {initial}
                          </div>
                        )}
                      </div>
                    ))}
                  {participant_list.length > 4 && (
                    <div
                      className="flex items-center justify-center bg-grayFlash 
                                            font-bold text-base w-[40px] h-[40px] rounded-full"
                    >
                      + {getParticipantCount()}
                    </div>
                  )}
                </>
              ) : (
                <div className="w-[40px] h-[40px]">
                  {user.hasAvatar ? (
                    <img
                      src={user.avatar_url}
                      alt="avatar"
                      className="w-full h-full rounded-full object-cover"
                    />
                  ) : (
                    <div
                      className={`flex items-center justify-center rounded-full 
                                            w-full h-full text-[18px] ${getUserColor(
                                              user.userColor
                                            )}`}
                    >
                      {initial}
                    </div>
                  )}
                </div>
              )}
            </div>
            {sessionData?.session_data?.total_participant_count > 1 ? (
              <Button variant="link" onClick={() => setViewAttendance(true)}>
                View Attendance
              </Button>
            ) : (
              <span className="font-bold">Coach was the only participant</span>
            )}
          </div>
          {sessionData?.session_data?.service_details ? (
            <div className="w-full flex flex-col main-shadow rounded-[16px] px-[40px] py-[32px] bg-white mb-[80px] sm:mb-0">
              <div className="flex flex-col md:flex-row w-full items-center justify-between mb-[24px]">
                <h5 className="text-graySlate text-base font-bold mb-[12px] md:m-0">
                  SERVICES
                </h5>
                {user.activeProfile === "coach" && (
                  <div className="flex items-center gap-[10px]">
                    <button
                      className="btn-primary btn-blue py-[4px] px-[20px] h-auto"
                      onClick={handleEditServiceClick}
                    >
                      {mobileView ? "Edit" : "Edit Service"}
                    </button>
                    <button
                      className="btn-primary btn-outline-primary py-[4px] px-[20px] h-auto"
                      onClick={handleRemoveServiceClick}
                    >
                      {mobileView ? "Remove" : "Remove Service"}
                    </button>
                  </div>
                )}
              </div>
              <div className="w-full rounded-[16px] bg-white main-shadow relative p-[24px] pt-[104px]">
                <div
                  className={`absolute top-0 left-0 w-full h-[80px] rounded-t-[16px] ${getServiceColor()}`}
                >
                  {sessionData?.session_data?.service_details?.image
                    ?.exists && (
                    <img
                      src={`https://clientservice-images-${
                        process.env.REACT_APP_USER_ENVIRONMENT
                      }.s3.amazonaws.com/${
                        sessionData.session_data?.stripe_product_id
                      }?${new Date().getTime()}`}
                      alt="service"
                      className="w-full h-full object-cover rounded-t-[16px]"
                    />
                  )}
                </div>
                <h5 className="font-bold text-[16px] mb-[2px]">
                  {sessionData?.session_data?.service_details?.title}
                </h5>
                <div className="text-blurple font-bold text-[18px] mb-[16px]">
                  {getPrice(billing?.amount, service_details?.symbol)}{" "}
                  <span className="text-base text-graySlate font-normal">
                    {getUnits(billing?.unit, parseInt(billing?.amount), type)}
                  </span>
                </div>
                <div className="w-full h-[1px] bg-grayMist mb-[16px]"></div>
                <div className="flex w-full justify-between items-center">
                  {getServiceType()}
                  <div className="flex items-center justify-center text-base text-graySlate font-bold bg-grayFlash py-[4px] px-[8px] rounded-[8px]">
                    <div>
                      {parseInt(session?.duration) / 60} minutes
                      <span className="text-darkGray">/ session</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full flex flex-col main-shadow rounded-[16px] px-[40px] py-[32px] bg-white">
              <div className="flex w-full items-center justify-between mb-[4px]">
                <h5 className="text-graySlate text-base font-bold">SERVICES</h5>
                {user.activeProfile === "coach" && (
                  <Button onClick={handleAttachServiceClick}>
                    Attach Service
                  </Button>
                )}
              </div>
              {!mobileView && (
                <span className="text-base">No services attached.</span>
              )}
            </div>
          )}
        </div>

        {Boolean(
          sessionData?.recording_assets?.length &&
            user.activeProfile === "coach"
        ) && (
          <div className="w-full flex flex-col main-shadow rounded-[16px] px-[40px] py-[32px] bg-white mt-12">
            <h5 className="text-graySlate text-base font-bold mb-3">
              SESSION RECORDINGS
            </h5>
            <p className="text-base mb-1">
              This session was recorded. To share this video with your client,
              copy the link below and share it with them via inbox.
            </p>
            <p className="text-base text-vividRed mb-3">
              Warning: This recording will be deleted in 24-hours from the end
              of your session dated:{" "}
              {moment
                .utc(session_end_time)
                .tz(user.timezone.value)
                .format("MMM DD, yyyy h:mm a")}
            </p>

            {sessionData?.recording_assets
              ?.filter((asset: any) => asset.type === "room-composite")
              .map((recording: any) => (
                <div
                  key={recording.id}
                  className="grid grid-cols-2 md:grid-cols-3 border-b last:border-none mb-2 pb-1.5 last:mb-0 last:pb-0 box-content"
                >
                  <div className="flex flex-col">
                    <h6 className="font-bold text-xs text-graySlate">
                      DURATION
                    </h6>
                    <p className="font-mono font-sm">
                      {formatDuration(recording.duration)}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <h6 className="font-bold text-xs text-graySlate">
                      FILE SIZE
                    </h6>
                    <p className="font-mono text-sm">
                      {formatSize(recording.size)}
                    </p>
                  </div>
                  <div className="flex gap-2 items-center justify-end col-span-full md:col-span-1">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => copy(recording.presigned_url.url)}
                    >
                      <SvgCopy />
                    </Button>

                    <Button variant="ghost" size="icon" asChild>
                      <a
                        href={recording.presigned_url.url}
                        download
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SvgDownload />
                      </a>
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  )
}

export default DetailedPastSession
