import type { SVGProps } from "react";
export const SvgVerifyEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.448 16.544a.645.645 0 0 1-.644-.652v-8.05l5.782 5.182c.456.414 1.039.625 1.63.625.583 0 1.166-.211 1.614-.625l5.787-5.182v2.621a5.3 5.3 0 0 1 1.805.373V7.448c0-.66-.271-1.288-.763-1.77A2.45 2.45 0 0 0 17.982 5H4.448c-.627 0-1.228.246-1.694.686A2.46 2.46 0 0 0 2 7.448v8.444a2.45 2.45 0 0 0 2.448 2.448h8.825a5.6 5.6 0 0 1-.466-1.796z"
    />
    <path
      fill="currentColor"
      d="M18.406 12.149a3.93 3.93 0 0 0-3.922 3.921 3.92 3.92 0 1 0 7.843 0 3.93 3.93 0 0 0-3.921-3.921m1.745 3.777-1.974 1.321a.67.67 0 0 1-.355.102.64.64 0 0 1-.517-.27l-.678-.966a.637.637 0 0 1 .161-.881.627.627 0 0 1 .881.152l.322.457 1.457-.974a.64.64 0 0 1 .88.178.63.63 0 0 1-.177.881"
    />
  </svg>
);
