import React, { FC, FormEvent, useContext, useState } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import PasswordRequirements from "../../account-settings/PasswordRequirements";
import { RegisterFormContext } from "./context";
import Loader from "../../ui/loader";

export const PasswordForm: FC = () => {
  const { password, setPassword, setView, accountType } =
    useContext(RegisterFormContext);
  const [loading, setLoading] = useState(false);
  const [confUserPassword, setConfUserPassword] = useState<string>("");
  const [showPaswReqs, setShowPassReq] = useState<boolean>(false);
  const [isEntreeValid, setIsEntryValid] = useState<boolean>(false);

  const isDisabled =
    !isEntreeValid || password.length === 0 || !(password === confUserPassword);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (isDisabled) {
      setLoading(false);
      return;
    }

    if (accountType === "coach") {
      setView("phone");
    }
  };

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={handleSubmit}
    >
      {showPaswReqs && (
        <PasswordRequirements
          validEntree={password}
          setIsEntryValid={setIsEntryValid}
        />
      )}
      <Input
        label="Password"
        placeholder="Enter your password"
        value={password}
        onFocus={() => setShowPassReq(true)}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        autoComplete="off"
        disabled={loading}
      />
      <Input
        label="Confirm your password"
        placeholder="Confirm your password"
        value={confUserPassword}
        onChange={(e) => setConfUserPassword(e.target.value)}
        type="password"
        onFocus={() => setShowPassReq(false)}
        autoComplete="off"
        disabled={loading}
      />
      <Button
        disabled={isDisabled}
        className="w-full"
      >
        {loading ? <Loader /> : "Continue"}
      </Button>
    </form>
  );
};
