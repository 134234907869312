import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  name: string;
}

const CalendlyScheduler: React.FC<Props> = ({ name }) => {
  return (
    <div className="w-[375px] md:w-[475] bg-white rounded-[16px] main-shadow overflow-hidden">
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        >
        </script>
      </Helmet>
      <div
        className="calendly-inline-widget"
        data-url={`https://calendly.com/hello-zoee/${name}?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=4750F5`}
        style={{ minWidth: "335px", height: "530px" }}
      >
      </div>
    </div>
  );
};

export default CalendlyScheduler;
