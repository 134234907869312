import React, { FC, useEffect } from "react"
import { InputErrors } from "../../../page"

interface RegisterPassReqsProps {
  password: string
  setValidPassword: (callback: (prev: InputErrors) => InputErrors) => void
}

const passwordReqs = [
  "8 characters",
  "1 special characters (ex: #, $, !)",
  "1 number",
  "1 uppercase and 1 lowercase character",
]
const RegisterPassReqs: FC<RegisterPassReqsProps> = ({
  password,
  setValidPassword,
}) => {
  const validateEntree = (entree: string) => {
    const entreeLength = entree.length >= 8
    const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(entree)
    const containsNumber = /\d/.test(entree)
    const mixOfRegister = /[A-Z]/.test(entree) && /[a-z]/.test(entree)
    return [entreeLength, containsSpecialChar, containsNumber, mixOfRegister]
  }

  useEffect(() => {
    if (validateEntree(password).every((el) => el === true)) {
      setValidPassword((prev) => ({ ...prev, validPassword: true }))
    } else {
      setValidPassword((prev) => ({ ...prev, validPassword: false }))
    }
  }, [password])

  return (
    <div className="text-xs text-[#808f7d]">
      <p>Passwords must have at least</p>
      <ul className="grid grid-cols-1 md:grid-cols-[1fr,2fr] gap-1">
        {passwordReqs.map((req, idx) => (
          <li
            key={`req${idx}`}
            className={`w-full before:content-['✓'] before:mr-2 ${
              validateEntree(password)[idx] && "text-[#39b221] font-bold"
            }`}
          >
            {req}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default RegisterPassReqs
