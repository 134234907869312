import type { SVGProps } from "react"
export const SvgCoachIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Vector"
      d="M20.018 16.6236L15.8875 9.31037C15.512 8.66745 14.8361 8.26562 14.0851 8.26562C13.3341 8.26562 12.6582 8.66745 12.2827 9.31037L9.20352 14.6948C8.97822 15.0967 9.12842 15.5789 9.42883 15.82C9.80433 16.0611 10.2549 15.9003 10.4802 15.5789L13.5594 10.1944C13.7096 9.9533 13.9349 9.87293 14.0851 9.87293C14.2353 9.87293 14.4606 9.87293 14.6108 10.1944L18.7413 17.5076C18.8915 17.8291 18.8164 18.0702 18.7413 18.1506C18.6662 18.2309 18.516 18.472 18.2156 18.472H6.12439C5.82399 18.472 5.67379 18.2309 5.59869 18.1506C5.52359 18.0702 5.44849 17.8291 5.59869 17.5076L7.47621 14.293C7.62641 14.0519 7.85171 13.9716 8.07701 13.9716C8.45252 14.0519 8.90312 13.7305 8.90312 13.2483C8.97822 12.8464 8.67782 12.3643 8.22721 12.3643C7.40111 12.2839 6.575 12.6857 6.19949 13.409L4.32198 16.6236C3.94647 17.3469 3.87137 18.1506 4.24688 18.8738C4.62238 19.5971 5.29829 19.999 6.04929 19.999H18.1405C18.8915 19.999 19.5674 19.5971 19.9429 18.8738C20.3935 18.1506 20.3935 17.3469 20.018 16.6236Z"
      fill="white"
    />
    <line
      id="Line 5"
      x1="14.25"
      y1="8.25"
      x2="14.25"
      y2="4.75"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      id="Vector 12"
      d="M9.19922 4L14.1992 2V6L9.19922 4Z"
      stroke="white"
      stroke-width="1.5"
      stroke-miterlimit="3.99933"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
