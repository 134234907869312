import type { SVGProps } from "react";
export const SvgPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.279 10.217c.65 0 1.178.209 1.572.62.541.564.788 1.495.735 2.767a27 27 0 0 1-.311 3.016h1.345c1.01 0 1.832-.822 1.832-1.832V1.832C15.452.822 14.63 0 13.62 0H5.832C4.822 0 4 .822 4 1.832v12.956c0 1.01.822 1.832 1.832 1.832h.901c.192-.73.38-1.495.487-2.413.275-2.35 1.533-3.99 3.059-3.99M7.207 6.543a.458.458 0 1 1-.917 0V2.878a.458.458 0 1 1 .917 0zM20.5 17.868v.001c.005 1.104-.578 2.519-1.253 4.157l-.296.722a.46.46 0 0 1-.425.286H7.687a.91.91 0 0 1-.916-.93c.043-2.203.375-3.448.727-4.766l.1-.376.087-.341H7.68c.178-.693.346-1.427.45-2.307.212-1.813 1.135-3.18 2.148-3.18.396 0 .694.11.912.337.356.372.522 1.096.48 2.094a28 28 0 0 1-.532 4.189.458.458 0 0 0 .895.196c.004-.022.037-.17.083-.413h1.503a2.75 2.75 0 0 0 2.749-2.749V9.856c.823.664 4.106 3.586 4.132 8.012"
    />
  </svg>
);
