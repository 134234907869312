import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { CoachCard } from "../../models/public.interface"
import ContactsList from "../../components/contacts/ContactsList"
import ContactFilters from "../../components/contacts/ContactFilters"
import ContactInvite from "../../components/contacts/ContactInvite"
import ConfirmContactInvite from "../../components/contacts/ConfirmContactInvite"
import ContactRequestFull from "../../components/contacts/ContactRequestFull"
import MobileContactsFilters from "../../components/contacts/MobileContactsFilters"
import useScrollToTop from "../../hooks/useScrollToTop"
import Loader from "../../components/ui/loader"
import useLoading from "../../hooks/useLoading"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { NotificationCtx } from "../../context/notificationContext"
import { NotificationRequests } from "../../api/app.service"
import { ContactRequests } from "../../api/app.service"
import { filterContacts } from "./CoachContacts"
import { SvgFilter, SvgSearch, SvgUserPlus } from "../../components/icons/"
import { Badge } from "../../components/ui/badge"
import { Button } from "../../components/ui/button"
import { UserCtx } from "../../context/userContext"

const MemberContacts: React.FC<{
  setShowContactProfile: (show: boolean) => void
  coach: CoachCard
}> = ({ setShowContactProfile, coach }) => {
  const {
    coach_public_profile_id,
    coach_profile_id,
    user_id,
    endpoint_slug,
    last_name,
    first_name,
    description,
    tags,
    avatar_url,
    max_end_date,
    updated_at,
    subscription_promo_codes,
    email,
  } = coach
  const [contacts, setContacts] = useState<any>([])
  const [, setSelectedContact] = useState<any>(null)
  const [showAllContacts, setShowAllContacts] = useState<boolean>(true)
  const [showZoeeLeads, setShowZoeeLeads] = useState<boolean>(false)
  const [showProspective, setShowProspective] = useState<boolean>(false)
  const [showReceivedInvites, setShowReceivedInvites] = useState<boolean>(false)
  const [showInvited, setShowInvited] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false)
  const [showConfirmInviteModal, setShowConfirmInviteModal] =
    useState<boolean>(false)
  const [contactRequestFull, setContactRequestFull] = useState<any>({
    show: false,
    contact: null,
  })
  const { user } = useContext(UserCtx)
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [invites, setInvites] = useState<any>([{ email: "" }])
  const [showCoachDirectory, setShowCoachDirectory] =
    React.useState<boolean>(false)
  const [selectedSort, setSelectedSort] = useState<string>("none")
  const [selectedFilter, setSelectedFilter] = useState<string>("active")
  const [showContactsMobileListView, setShowContactsMobileListView] =
    useState<boolean>(true)
  const [showMobileFilters, setShowMobileFilters] = useState<boolean>(false)
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false)
  const { contactsNotifications, setContactsNotifications } =
    React.useContext(NotificationCtx)
  const [, setSortedContacts] = React.useState([])

  const showComponent = (callback: any, sortFn: any = null) => {
    if (sortFn) {
      const sorted = sortFn(contacts)
      setSortedContacts(sorted)
    } else {
      setSortedContacts([])
    }
    setShowAllContacts(false)

    setShowReceivedInvites(false)
    setShowInvited(false)

    callback(true)
  }
  const filterConnected = contacts.filter((contact: any) => {
    return contact.contact_type === "connected"
  })

  const filterReceivedInvites = contacts.filter((contact: any) => {
    return contact.contact_type === "requested"
  })

  const filterInvited = contacts.filter((contact: any) => {
    return contact.contact_type === "invited"
  })

  const updateSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }
  const filterContacts = (
    contacts: any[],
    type: "connected" | "requested" | "invited" | "all" | "coach" | "member"
  ) => {
    return contacts.filter((contact) => {
      switch (type) {
        case "all":
          return true
        case "connected":
          return contact.contact_type === "connected"
        case "coach":
          return (
            contact.contact_type === "connected" &&
            contact.profile_type === "coach"
          )
        case "requested":
          return contact.contact_type === "requested"
        case "invited":
          return contact.contact_type === "invited"
        default:
          return true
      }
    })
  }

  const getContacts = () => {
    if (user?.coachProfile || user?.memberProfile) {
      setLoadingContacts(true)
      ContactRequests.getContactList({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setContacts(data.contact_list)
          setLoadingContacts(false)
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingContacts(false)
        })
    }
  }

  const acceptContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.acceptContactRequest({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
        request_id: parseInt(contact.request_id),
        request_by_profile_id: parseInt(contact.request_by_profile_id),
        user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      })
        .then(() => {
          getContacts()
          renderSuccess("Request accepted!")
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const denyContactRequest = (contact: any) => {
    if (user?.coachProfile) {
      startLoading()
      ContactRequests.denyContactRequest({
        request_id: parseInt(contact.request_id),
      })
        .then(() => {
          getContacts()
          renderSuccess("Request denied!")
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const revokeContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.revokeContactRequest({
        request_id: parseInt(contact.request_id),
        request_email: contact.contact_name,
        request_by_profile_id: user.activeProfileId,
      })
        .then(() => {
          getContacts()
          renderSuccess("Invite revoked!")
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const resendContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.resendContactRequest({
        request_id: parseInt(contact.request_id),
        request_email: contact.contact_name,
        request_by_profile_id: user.activeProfileId,
      })
        .then(() => {
          getContacts()
          renderSuccess("Invite resent!")
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const markContactNotificationsAsRead = () => {
    if (contactsNotifications.length === 0) return

    const unreadNotificationIds = contactsNotifications
      .filter((n: any) => n.viewed === 0)
      .map((n: any) => n.id)
    NotificationRequests.markRead({
      profile_id: user.activeProfileId,
      notification_ids: unreadNotificationIds,
    })
      .then(() => {
        const allReadContactsNotifications = contactsNotifications.map(
          (n: any) => {
            n.viewed = true
            return n
          }
        )
        setContactsNotifications(allReadContactsNotifications)
      })
      .catch((ex) => {
        console.error(ex)
        renderError(ex.response.data.message)
      })
  }
  React.useEffect(() => {
    getContacts()
  }, [])

  return (
    <>
      {showInviteModal && (
        <ContactInvite
          setShowInviteModal={setShowInviteModal}
          invites={invites}
          setInvites={setInvites}
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          invitedContacts={filterContacts(contacts, "invited")}
        />
      )}

      {showConfirmInviteModal && (
        <ConfirmContactInvite
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          invites={invites}
          setInvites={setInvites}
          contacts={contacts}
        />
      )}

      {contactRequestFull.show ? (
        <ContactRequestFull
          setContactRequestFull={setContactRequestFull}
          contactRequestFull={contactRequestFull}
          acceptContactRequest={acceptContactRequest}
          denyContactRequest={denyContactRequest}
          resendContactRequest={resendContactRequest}
          revokeContactRequest={revokeContactRequest}
        />
      ) : (
        <>
          {/* MOBILE */}
          <div className="md:hidden bg-[#fcfcfc]">
            <div className="max-w-[600px] mx-auto px-[20px] flex items-center gap-[16px] w-full justify-center mt-[8px] mb-[12px]">
              <div className="search-input h-[44px]">
                <input type="text" onChange={updateSearchValue} />
                <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
              </div>
              <SvgFilter
                onClick={() => setShowMobileFilters(() => !showMobileFilters)}
              />
            </div>
          </div>

          {showMobileFilters && (
            <MobileContactsFilters
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              setShowMobileFilters={setShowMobileFilters}
              showComponent={showComponent}
              setShowReceivedInvites={setShowReceivedInvites}
              setShowInvited={setShowInvited}
              setShowZoeeLeads={setShowZoeeLeads}
              setShowProspective={setShowProspective}
              setShowAllContacts={setShowAllContacts}
              showReceivedInvites={showReceivedInvites}
              showInvited={showInvited}
              showZoeeLeads={showZoeeLeads}
              showProspective={showProspective}
              showAllContacts={showAllContacts}
              contacts={contacts}
              setContacts={setContacts}
              filterReceivedInvites={filterReceivedInvites}
              filterInvited={filterInvited}
              showCoachDirectory={showCoachDirectory}
              setShowCoachDirectory={setShowCoachDirectory}
            />
          )}

          {/* DESKTOP */}
          <div
            className={`hidden mt-0 w-full sm:w-[1080px] md:flex flex-col bg-[#fcfcfc] mx-auto z-[200]`}
          >
            <div className="w-full flex flex-col md:flex-row justify-between items-center border-b-[1px] border-[#EBEBEB] pb-[24px] mb-[24px]">
              <h1 className="font-bold text-[36px]">My Coaches</h1>
              <div className="flex items-center justify-between w-[335px] sm:w-auto sm:gap-[12px]">
                <div className="search-input w-[189px] sm:w-[280px]">
                  <input
                    type="text"
                    placeholder="Search Coaches"
                    onChange={updateSearchValue}
                  />
                  <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
                </div>
                <div className="ml-auto mr-2">
                  <Button onClick={() => setShowInviteModal(!showInviteModal)}>
                    <SvgUserPlus />
                    Add Contact
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-between md:flex-row">
              <div className="mb-[20px] flex w-[351px] items-center gap-2 overflow-x-auto pb-2 sm:w-auto md:mb-0">
                <Button
                  variant={showAllContacts ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowAllContacts)}
                >
                  My Coaches
                </Button>

                <Button
                  variant={showReceivedInvites ? "utility" : "ghost"}
                  onClick={() => {
                    showComponent(setShowReceivedInvites)
                    markContactNotificationsAsRead()
                  }}
                >
                  Received Invites
                  {filterContacts(contacts, "requested").length > 0 && (
                    <Badge variant="alert">
                      {filterContacts(contacts, "requested").length}
                    </Badge>
                  )}
                </Button>

                <Button
                  variant={showInvited ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowInvited)}
                >
                  Sent Invites
                  {filterContacts(contacts, "invited").length > 0 && (
                    <Badge variant="alert">
                      {filterContacts(contacts, "invited").length}
                    </Badge>
                  )}
                </Button>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-end items-center">
              <ContactFilters
                contacts={contacts}
                setContacts={setContacts}
                selectedSort={selectedSort}
                selectedFilter={selectedFilter}
                setSelectedSort={setSelectedSort}
                setSelectedFilter={setSelectedFilter}
              />
            </div>
          </div>

          {/* SHARED ELEMENTS FOR DESKTOP AND MOBILE */}
          {loadingContacts ? (
            <div className="mt-[24px] flex flex-col items-center gap-[12px]">
              <Loader />
              <h3 className="mb-[4px] text-center text-[16px] font-bold">
                Looking for your contacts...
              </h3>
            </div>
          ) : (
            <div className="mx-auto pt-[12px] md:w-[1080px] md:pt-[32px]">
              {showAllContacts && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "member")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="all"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}

              {showReceivedInvites && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "requested")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="requested"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
              {showInvited && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "invited")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="invited"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
            </div>
          )}
          <div className="md:h-[300px] md:w-full"></div>
        </>
      )}
    </>
  )
}

export default MemberContacts
