import type { SVGProps } from "react";
export const SvgMicOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.334 12.137a.83.83 0 0 0 .639.274h.274c.274-.091.548-.365.639-.64.091-.365.183-.821.183-1.187V6.933c0-1.279-.548-2.558-1.462-3.47C14.694 2.547 13.416 2 12.137 2c-1.644 0-3.014.73-3.927 1.918-.365.365-.274.822 0 1.187zM17.982 14.785c.182.092.274.092.456.092.365 0 .64-.183.822-.457a8.37 8.37 0 0 0 1.005-3.927c0-.548-.366-.913-.914-.913s-.913.365-.913.913c0 1.096-.274 2.1-.73 3.014-.366.457-.183 1.005.274 1.278M21.726 20.539l-7.123-7.123L8.667 7.48 3.553 2.365a.883.883 0 0 0-1.279 0 .883.883 0 0 0 0 1.279l4.84 4.84v2.01a4.91 4.91 0 0 0 4.932 4.93c.548 0 1.187-.09 1.735-.273l1.096 1.096c-.822.456-1.827.639-2.74.639-1.735 0-3.288-.64-4.475-1.826-1.187-1.188-1.826-2.832-1.826-4.475 0-.548-.366-.914-.914-.914s-.913.366-.913.914c0 2.191.822 4.2 2.375 5.753 1.187 1.278 2.922 2.1 4.748 2.283v2.557c0 .548.366.913.914.913s.913-.365.913-.913v-2.557a7.05 7.05 0 0 0 3.196-1.096l4.293 4.292a1 1 0 0 0 .639.274 1 1 0 0 0 .639-.274.883.883 0 0 0 0-1.278"
    />
  </svg>
);
