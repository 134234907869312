import React from 'react'
import CloseModal from '../../CloseModal';

interface Props {
    setShowBufferTime: any;
}

const BufferTimeDetails: React.FC<Props> = ({ setShowBufferTime }) => {
  return (
    <div
    className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
        z-[9001] flex items-center justify-center"
    >
        <div
            className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                    main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
            >
            <CloseModal
                callback={setShowBufferTime}
                styling={"absolute top-[24px] right-[24px]"}
            />
            <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
            Buffer Windows
            </h1>
            <div className="w-full flex flex-col gap-[40px] text-center text-[18px]">
            <p className="text-black">
            Add dedicated buffer times, both before and after appointments, to allow for preparation, transition, and follow-up between sessions.
            </p>
         </div>
      </div>
    </div>
  )
}

export default BufferTimeDetails