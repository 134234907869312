import React from "react"
import moment from "moment"
import type { ChartData } from "chart.js"
import { ClientPaymentsItem } from "../../types/clientPaymentTypes"
import PaymentsSetupInstructions from "./PaymentsSetupInstructions"

type Props = {
  connectAccountData: any
  clientPaymentsData: Array<ClientPaymentsItem> | null
  setActiveDates: React.Dispatch<React.SetStateAction<Date[] | null>>
}
export default function PaymentsDashboard({
  clientPaymentsData,
  connectAccountData,
  setActiveDates,
}: Props) {
  const [chartData, setChartData] = React.useState<ChartData<"line"> | null>(
    null
  )

  // Generate chart labels and net payments data when clientPaymentsData is available
  React.useEffect(() => {
    const monthYearAndStripeNetArrOfObj = clientPaymentsData?.map((e) => {
      return {
        date: e.month_year,
        stripeNet: e.stripe_amount_net,
      }
    })
    const reducer = (map: any, obj: any) => {
      if (map[obj.date] == null) {
        map[obj.date] = obj.stripeNet
      } else {
        map[obj.date] += obj.stripeNet
      }
      return map
    }
    const reducedMonthYearAndStripeNetArrOfObj: {
      date: string
      stripeNet: number
    }[] = monthYearAndStripeNetArrOfObj?.map((obj) => obj).reduce(reducer, {})
    if (reducedMonthYearAndStripeNetArrOfObj) {
      const labels: Array<string> = Object.keys(
        reducedMonthYearAndStripeNetArrOfObj
      ).map((e) => moment(e).format("MMM 'YY"))
      const netData: Array<number> = Object.values(
        reducedMonthYearAndStripeNetArrOfObj
      ).map((e: any) => e / 100)
      // console.log(labels, netData);
      setChartData({
        labels,
        datasets: [
          {
            label: "Total Net Paid",
            data: netData,
          },
        ],
      })
    }

  }, [clientPaymentsData])
  const currency = clientPaymentsData?.[0]?.currency
  const example = [
    { code: "USD", symbol: "\\u0024" },
    { code: "EUR", symbol: "\\u20AC" },
    { code: "GBP", symbol: "\\u00A3" },
    { code: "AUD", symbol: "\\u0024" },
    { code: "CAD", symbol: "\\u0024" },
  ]
  // Check if currency is defined before filtering
  const symbol_data = currency
    ? example.filter((codes) => {
        return codes.code.toUpperCase() === currency.toUpperCase()
      })
    : []
  const symbol = symbol_data.length > 0 ? symbol_data[0].symbol : "\\u0024" // Default to '$'
  const currencySymbol = String.fromCharCode(parseInt(symbol.slice(2), 16))
  const netTotals = clientPaymentsData?.map((e) => e.stripe_amount_net)
  const netTotalsSum = netTotals
    ? netTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0
  const formattedPaidTotal = netTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  })


  const grossTotals = clientPaymentsData?.map((e) => e.stripe_amount_gross)
  const grossTotalsSum = grossTotals
    ? grossTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0
  const formattedGrossTotal = grossTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  })
  const feeTotals = clientPaymentsData?.map((e) => e.total_fee_amount)
  const feeTotalsSum = feeTotals
    ? feeTotals.reduce((previous, current) => previous + current, 0) / 100
    : 0
  const formattedFeeTotal = feeTotalsSum.toLocaleString("en-US", {
    minimumFractionDigits: 2,
  })
  return (

    //
    <div>
      <PaymentsSetupInstructions connectAccountData={connectAccountData} />
    </div>
  )
}
