// @ts-nocheck
import * as React from "react";
import { Badge, BadgeProps } from "../../../components/ui/badge";

export const BadgesPage: React.FC = () => {
  // Generate all permutations of variant and dot prop combinations
  const permutations: BadgeProps[] = [];
  const variants = [
    "default",
    "alert",
    "secondary",
    "success",
    "destructive",
    "outline",
    "notDefault",
  ];
  const dots = [true, false];

  variants.forEach((variant) => {
    dots.forEach((dot) => {
      permutations.push({ variant, dot });
    });
  });

  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-3xl font-bold">Badges</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {permutations.map((props, index) => (
          <div
            key={index}
            className="flex flex-col items-center gap-6 border p-6"
          >
            {/* Render Badge component with each permutation */}
            <Badge {...props}>{props.dot ? "" : "Badge"}</Badge>
            <ul className="text-muted-foreground text-xs">
              <li>
                <strong>Variant</strong>: {props.variant}
              </li>
              <li>
                <strong>Dot</strong>: {props.dot.toString()}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
