import React from 'react';
import { SvgCheckbox } from '../icons/';


interface Props {
    program: any;
    selectedProgram: any;
}

const Program: React.FC<Props> = (props: any) => {
    return(
       <div 
            className={`h-[68px] w-full p-[14px] flex items-center justify-between cursor-pointer
            ${props.selectedProgram?.id === props.program?.id && "bg-primaryBlue/[0.04] rounded-[14px]"}`}
        >
            <div className='flex items-center'>
                <div className='w-[40px] h-[40px] flex items-center justify-center rounded-full bg-paleCyanBlue mr-[16px]'>
                    {props.program?.name?.charAt(0)}
                </div>
                <div className="flex flex-col">
                    <h6 className="font-bold text-[16px]">{props.program.name}</h6>
                    <p className="text-graySlate text-base">
                        <span className='text-blurple'>10/32&nbsp;</span>
                        people enrolled
                    </p>
                </div>
            </div>
            <div 
                className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                flex items-center justify-center
                ${props.selectedProgram?.id === props.program?.id && "bg-cyanBlue"}`}    
            >
                {props.selectedProgram?.id === props.program?.id && (
                    <SvgCheckbox />
                )}
            </div>
       </div>
    )
}

export default Program;
