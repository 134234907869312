import type { SVGProps } from "react";
export const SvgUser3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.559 21.456c-3.672 0-6.81-.555-6.81-2.779 0-2.224 3.118-4.228 6.81-4.228 3.671 0 6.807 1.985 6.807 4.208s-3.115 2.8-6.807 2.8zM9.558 11.278a4.364 4.364 0 10-4.364-4.364 4.348 4.348 0 004.333 4.364h.031z"
      clipRule="evenodd"
    >
    </path>
  </svg>
);
