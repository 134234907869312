import previewImage from "../../../assets/blog/techstars.webp";
import { Post } from "../../../types/postTypes";

export const techstars: Post = {
    metadata: {
        id: 7,
        title: "Zoee Selected for Techstars Iowa Accelerator Class of 2020",
        author: "written by Zoee",
        date: "September 9, 2020",
        urlSlug: "zoee-selected-for-techstars-iowa-20",
        description:
            "Zoee, an all-inclusive virtual office and marketplace for coaches and their clients, is honored to announce it will join the Techstars Iowa Accelerator class of 2020.",
        previewImg: previewImage,
        tags: ["Company News"],
    },
    content: [
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Updated: October 2022",
                },
            ],
        },
        {
            type: "paragraph",
            text: "(Raleigh, NC) - Zoee, an all-inclusive virtual office and marketplace for coaches and their clients, is honored to announce it will join the Techstars Iowa Accelerator class of 2020.",
        },
        {
            type: "paragraph",
            text: "Techstars Iowa is a 12-week mentorship-driven accelerator program based in Des Moines, Iowa dedicated to helping startups reach greater success. Acceptance into the program is an achievement in itself, with hundreds applying and only 1% accepted into the accelerator program.",
        },
        {
            type: "paragraph",
            text: "In addition to $120k investment and more than $500k in perks, Zoee will have access to mentors, investors, corporate leaders, and experts from Iowa and across the globe. Researchers and professors from Grinnell College, University of Iowa, and Iowa State University will also provide mentorship.",
        },
        {
            type: "paragraph",
            text: "“We are really excited to be backed by Techstars and its global platform for investment and innovation. With their network and support, Zoee is looking to disrupt the coaching market,” said Nichole Lowe, Founder and CEO of Zoee. “The multi-billion-dollar coaching industry is in desperate need of innovation with nearly 82% of independent coaches going out of business within the first two years.”",
        },
        {
            type: "paragraph",
            text: "“The biggest constraint for coaches is the cost structure and disparate technologies. To eliminate these constraints, Zoee is on a mission to shift this paradigm by producing the most powerful centralized AI-driven workflow automation ecosystem on the market. Our system will significantly save time and money, and increase revenue for our coaches,” said Lowe.",
        },
        {
            type: "paragraph",
            text: "“One of the biggest advantages of Techstars is access to a vast network of support and subject-matter expertise in the areas of digitization, innovation, and strategy,” said Craig Martin, Zoee CTO.",
        },
        {
            type: "paragraph",
            text: "“The support we have already received from the Techstars Managing Director, Kerty Levy and the team, has been exceptional,” explained Jason Faber, Zoee CMO, “and I am looking forward to seeing how this program will help propel our business forward. This couldn’t have come at a more perfect time.”",
        },
        {
            type: "header2",
            text: "About Techstars",
        },
        {
            type: "paragraph",
            text: "Techstars is the global platform for investment and innovation. Techstars founders connect with other entrepreneurs, experts, mentors, alumni, investors, community leaders, and corporations to grow their companies. Techstars accelerator portfolio includes more than 2,200 companies with a market cap of more than $27 billion. www.techstars.com",
        },
        {
            type: "header2",
            text: "About Zoee",
        },
        {
            type: "paragraph",
            text: "Zoee, a Techstars portfolio company, is an all-inclusive virtual office and marketplace for coaches and their clients. We’re simplifying the way people connect, streamlining online business, and cultivating a community of members so no one has to do life alone. Visit www.zoee.com",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This article was written by Team Zoee.",
                },
            ],
        },
    ],
};
