import type { SVGProps } from "react";
export const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.5 11.533a6.533 6.533 0 1 1 13.066 0 6.533 6.533 0 0 1-13.066 0M12.033 3.5a8.033 8.033 0 1 0 4.916 14.387l2.476 2.47a.75.75 0 0 0 1.059-1.063l-2.439-2.432A8.033 8.033 0 0 0 12.033 3.5"
      clipRule="evenodd"
    />
  </svg>
);
