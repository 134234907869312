import { FC } from "react";
import zoeeLogoOutline from "../../../assets/images/zoee-logo-outline-full-text.webp";
import signature from "../../../assets/images/nichole-lowe-signature.webp";
import nichole from "../../../assets/images/nichole-lowe.webp";

type Props = {};

const LetterFromFounder: FC<Props> = () => {
    return (
        <section className="relative md:px-auto sm:px-[48px] px-[24px] md:pb-[240px] pb-[180px] mt-[120px] text-[18px] overflow-hidden">
            <img
                className="absolute md:top-[48px] top-[-20px] md:right-[-93px] right-[-200px] md:h-auto h-[110px]"
                src={zoeeLogoOutline}
                alt="outline of zoee logo"
            />
            <h2 className="xl:ml-[204px] lg:ml-[84px] md:ml-[0px] mb-[76px] max-w-[676px] font-bold text-[54px] leading-[63px] lg:leading-[103px] lg:text-[80px]">
                A Letter from the Founder &amp; CEO
            </h2>

            <div>
                <div className="md:mx-auto md:max-w-[816px]">
                    <p className="mb-[24px]">
                        Success does not happen alone. No journey, in business or life, was meant to
                        be a solo one.
                    </p>
                    <p className="mb-[24px]">I founded Zoee to embody those ideals.</p>
                    <p className="mb-[48px]">
                        As an entrepreneur and a certified coach myself, I recognized how difficult
                        it was to build and run a thriving coaching business without the proper
                        tools. Despite the demand and astounding growth in the industry, the
                        coaching community has been woefully underserved, overlooked, and
                        underestimated.
                    </p>
                </div>

                <div className="lg:flex lg:max-w-[816px] lg:gap-[42px] lg:mx-auto lg:w-full lg:justify-center">
                    <div className="lg:hidden mb-[48px] font-semibold text-[30px] italic leading-[36px] flex justify-center gap-[4px]">
                        <div>“</div>
                        <div className="max-w-[324px]">
                            I made it my mission to liberate each entrepreneur from daily drudgery
                            to focus on their clients and their own success.
                        </div>
                    </div>

                    <div className="lg:max-w-[432px]">
                        <p className="mb-[24px]">
                            I set out to provide what was missing from this industry. How could I
                            make the path forward easier for others? What tools would have helped me
                            the most? Could I make those available to other coaches to help them
                            win, too?
                        </p>
                        <p className="mb-[24px]">
                            I made it my mission to liberate each entrepreneur from daily drudgery
                            to focus on their clients and their own success.
                        </p>
                    </div>

                    <div className="hidden translate-x-[60px] lg:flex mb-[48px] font-semibold text-[30px] italic leading-[36px] justify-center gap-[4px]">
                        <div>“</div>
                        <div className="max-w-[324px]">
                            I made it my mission to liberate each entrepreneur from daily drudgery
                            to focus on their clients and their own success.
                        </div>
                    </div>
                </div>

                <div className="lg:mx-auto lg:max-w-[816px]">
                    <p className="mb-[24px]">
                        Time is the most valuable asset to any entrepreneur and if I could give that
                        back, I could transform lives—and the industry.
                    </p>
                    <p className="mb-[48px]">
                        What coaches needed was a single solution to match with potential clients
                        and to manage their business.
                    </p>
                </div>

                <div className="lg:flex lg:gap-[32px] lg:mx-auto lg:w-full lg:justify-center">
                    <div className="lg:w-[408px] lg:h-[176px] lg:translate-x-[-60px] mb-[48px] font-semibold text-[30px] italic leading-[36px] flex justify-center gap-[4px]">
                        <div>“</div>
                        <div className="lg:max-w-[408px] max-w-[324px]">
                            Time is the most valuable asset to any entrepreneur and if I could give
                            that back, I could transform lives—and the industry.
                        </div>
                    </div>

                    <div className="lg:max-w-[440px]">
                        <p className="mb-[24px]">
                            I decided to solve this problem and share my knowledge to empower other
                            coaches to have access to what they need so they too could make their
                            business wildly successful.
                        </p>
                        <p className="mb-[24px]">
                            My focus was not only to improve the individual experience of
                            establishing and running a coaching business, but to provide a greater
                            sense of community for coaches and clients alike.
                        </p>
                    </div>
                </div>

                <div className="lg:mx-auto lg:max-w-[816px]">
                    <h4 className="mb-[24px] text-[30px] font-semibold italic">Zoee was born.</h4>

                    <p className="mb-[24px]">
                        The company would be more than just my knowledge and experience, it would be
                        the sum total of all of us supporting each other on this amazing adventure.
                    </p>

                    <p className="mb-[24px]">
                        It&apos;s been an incredible journey, and we're just getting started!
                    </p>
                </div>

                <div className="md:mt-[200px] mt-[100px] flex flex-col justify-center md:items-center md:flex-row md:gap-[64px]">
                    <div>
                        <h5 className="font-bold text-[24px]">Nichole Lowe</h5>
                        <img className="mb-[24px]" src={signature} alt="Nichole Lowe signature" />
                    </div>
                    <img
                        className="w-[280px] h-[280px]"
                        src={nichole}
                        alt="Zoee Founder and CEO Nichole Lowe"
                    />
                </div>
            </div>
        </section>
    );
};

export default LetterFromFounder;
