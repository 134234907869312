import type { SVGProps } from "react";
export const SvgUser2Solid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.5 3.333c-9.166 0-16.666 7.5-16.666 16.667 0 4.333 1.667 8.5 4.833 11.667l.167.166c2 1.834 4.333 3.334 6.833 4.167 1.667.5 3.334.667 5 .667 1.667 0 3.334-.334 5-.667a18.9 18.9 0 0 0 6.834-4l.166-.167c3-3.166 4.834-7.333 4.834-11.666-.334-9.334-7.834-16.834-17-16.834m0 3.334c7.334 0 13.334 6 13.334 13.333 0 2.833-.833 5.5-2.5 7.667C30.001 25.5 28.001 24 25.667 23c1.167-1.333 2-3 2-5 0-4-3.166-7.167-7.166-7.167S13.334 14 13.334 18c0 2 .833 3.667 2 5-2.333 1-4.167 2.667-5.667 4.667-1.666-2.167-2.5-5-2.5-7.667 0-7.333 6-13.333 13.334-13.333"
    />
  </svg>
);
