import type { SVGProps } from "react";
export const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m4.68 14.143.682-.16zm0-5.64L4 8.343zm15.994 0-.682.16zm0 5.64.681.16zm-5.177 5.177-.16-.682zm-5.64 0 .16-.682zm0-15.994.16.682zm5.64 0-.16.682zM5.361 15.914l.613-.339-.216-.392-.447.032zm2.725 2.725.699.05.032-.447-.392-.216zm1.026-9.544a.7.7 0 1 0 0 1.4zm3.056 1.4a.7.7 0 0 0 0-1.4zm-2.038 1.656a.7.7 0 1 0 0 1.4zm5.093 1.4a.7.7 0 1 0 0-1.4zm-9.861.432c-.41-1.75-.41-3.57 0-5.32l-1.363-.32c-.46 1.96-.46 4 0 5.96zm14.63-5.32c.41 1.75.41 3.57 0 5.32l1.363.32c.46-1.96.46-4 0-5.96zm-4.655 9.975c-1.75.41-3.57.41-5.32 0l-.32 1.363c1.96.46 4 .46 5.96 0zm-5.32-14.63c1.75-.41 3.57-.41 5.32 0l.32-1.363c-1.96-.46-4-.46-5.96 0zm0 14.63a6.25 6.25 0 0 1-4.655-4.655l-1.363.32a7.65 7.65 0 0 0 5.698 5.698zm5.64 1.363a7.65 7.65 0 0 0 5.698-5.698l-1.363-.32a6.25 6.25 0 0 1-4.655 4.655zm-.32-15.993a6.25 6.25 0 0 1 4.655 4.655l1.363-.32a7.65 7.65 0 0 0-5.698-5.698zm-5.64-1.363a7.65 7.65 0 0 0-5.698 5.698l1.363.32a6.25 6.25 0 0 1 4.655-4.655zM5.31 15.215A3.247 3.247 0 0 0 2.3 18.453h1.4c0-.974.755-1.773 1.711-1.841zM2.3 18.453A3.247 3.247 0 0 0 5.547 21.7v-1.4A1.847 1.847 0 0 1 3.7 18.453zM5.547 21.7a3.247 3.247 0 0 0 3.238-3.01l-1.397-.101A1.847 1.847 0 0 1 5.547 20.3zm2.878-3.674a6.25 6.25 0 0 1-2.451-2.45l-1.226.676a7.65 7.65 0 0 0 3 3zm.687-7.53h3.056v-1.4H9.112zm1.018 3.055h5.093v-1.4H10.13z"
    />
  </svg>
);
