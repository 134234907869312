"use client"

import * as React from "react"
import { useAuthModal } from "../auth/use-auth-modal"
import { useScroll, useTransform } from "framer-motion"
import productImage from "./product-image.png"
import productimage2 from "./product-image2.png"
import productimage3 from "./product-image3.png"
import productimage4 from "./product-image4.png"
import productimage5 from "./product-image5.png"
import productimage6 from "./product-image6.png"
import MostRecommendedBadge from "../../assets/images/capterra-badges/sa-most_recommended-2023.png"
import BestValueBadge from "../../assets/images/capterra-badges/ca-value-2023.png"
import CustomerSupportBadge from "../../assets/images/capterra-badges/sa-customer_support-2023.png"
import CEO from "./CEO.jpg"
import CEOSignature from "../../assets/images/CEO-signature.png"
import VShroffHeadshot from "./VShroff-Headshot.jpeg"
import IAPRCLogo from "./IAPRC-500.png"

function IaprcDiscount() {

  const { scrollYProgress } = useScroll()
  const { showRegister } = useAuthModal()
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "-300%"])

  const handleCreateAccount = () => {
    localStorage.setItem("referrerPromoCode", "IAPRC2024")
    showRegister({ accountType: "coach" })
  }

  return (
    <>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="mr-auto mt-4 flex items-center h-24 md:h-32">
                <img className="h-full" src={IAPRCLogo} alt="icflogo" />
              </div>

              <div className="text-[#333333] text-[22px] md:text-[42px] font-bold font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Exclusive 50% Discount plus a free listing in the marketplace
                for IAPRC Members. Offer ends December 31st, 2024
              </div>
              <div className=" mr-auto text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Code:{" "}
                <span className="text-[#333333] text-[22px] md:text-[42px] font-bold font-['Beausite Classic'] leading-tight md:leading-[48px]">
                  {" "}
                  IAPRC2024{" "}
                </span>
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                As a member of the International Association of Professional
                Recovery Coaches, you have the benefit to unlock the full
                potential of your certification with Zoee.
              </div>
            </div>

            <button
              className="flex justify-center items-center text-white bg-[#333333] rounded-xl px-6 py-3 mr-auto"
              onClick={handleCreateAccount}
            >
              Try for Free
            </button>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 "
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                ClientCare: Your Practice's Pulse
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Elevate your coaching practice with Zoee's ClientCare. This
                isn't just a management system; it's your command center,
                orchestrating every aspect of your client ecosystem.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                From curious prospects to loyal clients, Zoee organizes it all.
                Track client hours effortlessly, manage billing with ease, and
                oversee program subscriptions seamlessly. Your session notes?
                Always at your fingertips, synced automatically.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                With Zoee, you're not just managing – you're mastering the art
                of client relationships. Let Zoee handle the details while you
                focus on what you do best: transforming lives.
              </div>
              <div className="text-[#333333] mr-auto text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Organize. Streamline. Thrive.
              </div>
            </div>
          </div>

          {/* image */}
          <div className="md:w-1/2 flex justify-center md:justify-end">
            <img
              className="w-full h-auto md:w-[584px] md:h-[475px]"
              src={productImage}
              alt="product contact cards and woman on laptop"
            />
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          {/* image */}
          {/* <div className="md:w-1/2 flex justify-center md:justify-end"> */}
          <img
            className="w-full md:w-[584px] h-auto"
            src={productimage2}
            alt="product contact cards and woman on laptop"
          />
          {/* </div> */}
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Time Tamer: Your scheduling genius
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Unleash effortless scheduling with Zoee's Time Tamer. Wave
                goodbye to email chaos, timezone tangles, and double bookings.
                Our smart detection and two-way calendar sync work behind the
                scenes, keeping your schedule pristine.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                But there's more! Clients complete intake forms before booking,
                preparing you with crucial information upfront. With Zoee as
                your sidekick, focus on what matters - transforming lives
                through your coaching.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Let us handle the scheduling details while you work your magic.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Brand Beacon: Your Digital Storefront
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Illuminate your coaching brilliance with Zoee. This isn't just a
                landing page; it's your digital stage to dazzle potential
                clients. Showcase your expertise, flaunt your offerings (from
                one-on-one sessions to group extravaganzas), and let your unique
                coaching style shine.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                With Zoee, you're not just visible - you're irresistible. Spark
                new bookings, captivate with video content, drop knowledge bombs
                with your blog, all while Zoee quietly manages your back-end
                business wizardry.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Your brand, amplified. Your admin, simplified. That's the power
                of Zoee.
              </div>
            </div>
          </div>

          {/* image */}
          <div className="md:w-1/2 flex justify-center md:justify-end">
            <img
              className="w-full h-auto md:w-[584px] md:h-[475px]"
              src={productimage3}
              alt="product contact cards and woman on laptop"
            />
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          {/* image */}
          <div className="md:w-1/2 flex justify-center md:justify-end">
            <img
              className="w-full md:w-[584px] h-auto"
              src={productimage4}
              alt="product contact cards and woman on laptop"
            />
          </div>
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                SpotlightPro: Marketplace for Coaching Stars
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Step into the limelight with Zoee's SpotlightPro. This isn't
                just a marketplace; it's your VIP pass to a curated arena where
                eager clients seek coaching excellence.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Showcase your unique talents, methodologies, and success stories
                to a targeted audience hungry for transformation. With
                SpotlightPro, you're not just listed - you're featured. Stand
                out from the crowd and attract the perfect clients who resonate
                with your coaching style.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Shine. Connect. Thrive. Let SpotlightPro turn your expertise
                into opportunity.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                CoachCompass: Your Professional Growth Network
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Expand your horizons with Zoee's CoachConnect. This isn't just
                another social network; it's your gateway to a vibrant community
                where Zoee fosters education and meaningful connections among
                passionate, like-minded coaches.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Share insights, learn from peers, and uncover new opportunities.
                Whether you're seeking mentorship, collaboration, or simply
                fresh perspectives, CoachConnect puts a world of coaching
                expertise at your fingertips.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Connect. Learn. Grow. Your next big opportunity is just a
                conversation away.
              </div>
            </div>
          </div>

          {/* image */}
          <div className="md:w-1/2 flex justify-center md:justify-end">
            <img
              className="w-full md:w-[584px] h-auto"
              src={productimage5}
              alt="product contact cards and woman on laptop"
            />
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 "
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        {" "}
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
          <div className="md:w-1/2 flex justify-center md:justify-end">
            <img
              className="w-full md:w-[584px] h-auto"
              src={productimage6}
              alt="product contact cards and woman on laptop"
            />
          </div>
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                CoachConnect: Your Client Conversation Hub
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Transform your practice into a communication powerhouse with
                Zoee's CoachConnect. This isn't just a toolbox; it's your
                personal client engagement center. Host crystal-clear video
                sessions, exchange instant messages with smart alerts, and
                synchronize your schedule effortlessly.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                But there's more to your hub! Capture lightning-bolt insights
                with digital notes that auto-save for future inspiration. With
                CoachConnect, you're not just chatting; you're crafting client
                success stories from a single, intuitive dashboard.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Engage. Inspire. Thrive. Elevate client interactions with
                seamless video and messaging from one intuitive hub.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      <div
        className=" md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 bg-gradient-to-b from-[#BACDFF] to-white pb-12 pt-48"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col items-center gap-8 md:gap-16">
          <div className="w-full flex flex-col justify-start items-center gap-8">
            <div className="text-center self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Elevate Your Coaching Career with Zoee
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-center">
                From novice to veteran, Zoee empowers every coach to thrive. Our
                all-in-one platform combines cutting-edge business tools, a
                supportive community, and powerful connections to propel your
                coaching practice to new heights. Don't just coach – excel.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-center leading-none">
                Join Zoee today and transform your passion into a thriving
                business.
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-center leading-none">
                Sign up now and experience the Zoee difference!
              </div>

              <button
                className="flex justify-center items-center text-white bg-[#333333] rounded-xl px-6 py-3"
                onClick={handleCreateAccount}
              >
                Try for Free
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className=" md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 pt-48"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col items-center gap-8 md:gap-16">
          <div className="w-full flex flex-col justify-start items-center gap-8">
            <div className="text-center self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Why coaches love us
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-center">
                See what coaches like you have to say about Zoee
              </div>
              <div className="flex justify-center items-center flex-wrap gap-4 mt-22 gap-8">
                <img
                  src={CustomerSupportBadge}
                  alt={CustomerSupportBadge}
                  className="h-20 sm:h-24 md:h-28 lg:h-32 xl:h-40"
                />
                <img
                  src={BestValueBadge}
                  alt={BestValueBadge}
                  className="h-20 sm:h-24 md:h-28 lg:h-32 xl:h-40"
                />
                <img
                  src={MostRecommendedBadge}
                  alt={MostRecommendedBadge}
                  className="h-20 sm:h-24 md:h-28 lg:h-32 xl:h-40"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
          style={{
            fontFamily: "BeausiteClassic",
          }}
        >
          {" "}
          <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16">
            <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
              <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
                <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                  "I have been so fortunate to experience Zoee" -
                  <span className="text-[22px] font-bold italic">
                    Vahbiz Shroff, PhD, PCC
                  </span>
                </div>
                <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                  As the Owner / Founder of Living Your Best Coaching, LLC,
                  Vahbiz has served as an executive coach for businesses,
                  healthcare and pharmaceutical organizations as well as
                  prestigious academic institutions. Vahbiz holds an
                  International Coaching Federation PCC credential, specializing
                  in Authentic Leadership & Executive Coaching. She also has
                  expertise in Career Transition, Team Coaching, and behavioral
                  assessments (DISC, EQi, Strengths). She now dedicates herself
                  to coaching professionals and mentoring students to achieve
                  fulfilling careers.
                </div>
              </div>
              <button
                className="flex justify-center items-center text-white bg-[#333333] rounded-xl px-6 py-3"
                onClick={() =>
                  window.open(
                    "https://zoee.wistia.com/medias/xpoox3deym",
                    "_blank"
                  )
                }
              >
                Play Video
              </button>
            </div>

            {/* image */}
            <div className="md:w-1/2 flex justify-center md:justify-end">
              <img
                className="w-full md:w-[584px] h-auto"
                src={VShroffHeadshot}
                alt="product contact cards and woman on laptop"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
      {/* <div
      {/* <div
        className=" md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 pt-48 bg-gradient-to-b from-white to-blurple to-white "
        style={{
          fontFamily: "BeausiteClassic",
        }}
      > */}
      {/* <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col items-center gap-8 md:gap-16 "> */}
      {/* <div className="w-full flex flex-col justify-start items-center gap-8">
            <div className="text-center self-stretch flex-col justify-center items-center gap-6 flex"> */}
      {/* <div className="text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
                Wait! Don't leave without your free demo with the founder and
                CEO of Zoee.
              </div> */}
      {/* <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-center">
                See the future of coaching in action! Exclusively for{" "}
                <span className="font-bold">IAPRC Members.</span>
              </div> */}
      {/* <button
                className="flex justify-center items-center text-white bg-[#333333] rounded-xl px-6 py-3 mb-5 mt-5"
                onClick={() =>
                  window.open(
                    "https://calendly.com/hello-zoee/group-orientation?",
                    "_blank"
                  )
                }
              >
                Schedule Now
              </button> */}
      {/* 
              <div className="flex justify-center items-center flex-wrap mt-26 gap-8">
                <img
                  src={Group6}
                  alt="Group6"
                  className="h-28 sm:h-32 md:h-36 lg:h-40 xl:h-48"
                />
                <img
                  src={Group8}
                  alt="Group8"
                  className="h-28 sm:h-32 md:h-36 lg:h-40 xl:h-48"
                />
                <img
                  src={Group9}
                  alt="Group9"
                  className="h-28 sm:h-32 md:h-36 lg:h-40 xl:h-48"
                />
              </div> */}
      {/* </div>
          </div> */}
      {/* </div> */}
      {/* </div> */}
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col gap-8">
          <div className="w-full flex flex-col justify-start gap-8">
            <div className="text-left text-[#333333] text-[22px] md:text-[42px] font-['Beausite Classic'] leading-tight md:leading-[48px]">
              Meet our Founder
            </div>
            <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-left w-full">
              As a certified coach with 15 years of experience, I've walked the
              path you're on. I've felt the thrill of transforming lives and the
              frustration of managing the business side of coaching. When I
              started my journey, I realized the tool I desperately needed
              didn't exist. So, I did what coaches do best – I solved the
              problem.
            </div>
            <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-left w-full">
              Zoee isn't just a platform; it's the culmination of my passion for
              coaching and my drive to empower fellow coaches. It's the tool I
              wish I had when I began, refined by years of real-world coaching
              experience and entrepreneurial grit.
            </div>
            <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-left w-full">
              Our small but mighty team shares my obsession with creating an
              exceptional experience for coaches like you. We understand your
              challenges because we've lived them. Zoee is more than a business
              solution; it's a mission to help coaches thrive, doing what they
              love.
            </div>
            <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic'] text-left w-full">
              I believe in the power of connection and community. That's why I'm
              always eager to hear from you. Whether you have questions, ideas,
              or just want to chat about the wonderful world of coaching, reach
              out to me at <span className="font-bold">hello@zoee.com</span>.
              Let's connect, collaborate, and elevate the coaching profession
              together.
            </div>
          </div>
        </div>

        <div className="md:w-1/2 flex flex-col items-center justify-center md:items-start mx-auto  mt-10">
          <div className="flex items-center gap-4 rounded-full">
            <img
              className="w-[180px] h-[180px] rounded-full"
              src={CEO}
              alt="Zoee Founder and CEO Nichole Lowe"
            />
            <div className="flex flex-col items-center">
              <img
                className="w-auto h-auto md:w-[200px] md:h-[160px]"
                src={CEOSignature}
                alt="CEO Signature"
              />
              <p className="text-center mt-2 font-bold">
                Founder and CEO of Zoee
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
      <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
      <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
    </>
  )
}

export default IaprcDiscount
