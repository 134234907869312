import React from "react"
import CloseModal from "../CloseModal"
import DragDropArea from "../DragDropArea"
import Loader from "../ui/loader"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { SvgTrash, SvgUploadImage } from "../icons"

export default function EditBannerImage() {
  const {
    setEditBannerImage,
    previewImgUrl,
    setPreviewImgUrl,
    coachPublicProfileData,
    getCoachPrivateProfile,
  } = React.useContext(CoachPrivateProfileContext)
  const { setPopupNotification, renderSuccess, renderError } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [loadingSave, setLoadingSave] = React.useState<boolean>(false)
  const [loadingDelete, setLoadingDelete] = React.useState<boolean>(false)
  const [bannerImgFile, setBannerImgFile] = React.useState<File>()
  const [awaitNewImage, setAwaitNewImage] = React.useState<boolean>(false)
  const [imageProperties, setImageProperties] = React.useState<any>(null)

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true
    } else if (
      imageFile.type === "video/mp4" &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true
    } else {
      return false
    }
  }

  const saveBannerImage = () => {
    startLoading()
    setLoadingSave(true)
    ProfileRequests.editBannerImage({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
      base_64_string: imageProperties.base64,
      file_extension: imageProperties.base64extension,
    })
      .then(() => {
        getCoachPrivateProfile().then(() => {
          setLoadingSave(false)
          renderSuccess("Uploaded banner image")
          setBannerImgFile(undefined)
          setAwaitNewImage(false)
          setEditBannerImage(false)
          stopLoading()
          getCoachPrivateProfile()
        })
      })
      .catch((ex: any) => {
        console.error(ex)
        setBannerImgFile(undefined)
        setPreviewImgUrl("")
        setImageProperties(null)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const deleteBannerImage = () => {
    startLoading()
    setLoadingDelete(true)
    ProfileRequests.deleteBannerImage({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then(() => {
        setLoadingDelete(false)
        renderSuccess("Deleted banner image")
        setBannerImgFile(undefined)
        setAwaitNewImage(false)
        setEditBannerImage(false)
        stopLoading()
        getCoachPrivateProfile()
      })
      .catch((ex: any) => {
        console.error(ex)
        setBannerImgFile(undefined)
        setPreviewImgUrl("")
        setImageProperties(null)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const setBannerImgDetails = () => {
    if (bannerImgFile) {
      if (validateFile(bannerImgFile)) {
        setPreviewImgUrl(URL.createObjectURL(bannerImgFile))
        let base64 = ""
        const type = bannerImgFile.type.split("/")[1]
        const reader = new FileReader()
        reader.readAsDataURL(bannerImgFile)
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",")
          })
          base64 = string[0][1]
          setImageProperties({
            base64: base64,
            base64extension: type,
          })
        }
        reader.onerror = function (error) {
          console.log("Error: ", error)
        }
        setAwaitNewImage(false)
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        })
        setBannerImgFile(undefined)
        setAwaitNewImage(true)
        setImageProperties(null)
        setPreviewImgUrl("")
      }
    }
  }

  React.useEffect(() => {
    setBannerImgDetails()
  }, [bannerImgFile])

  return (
    <div className="zoee-backdrop-filter fixed left-0 top-0 z-[1009] flex h-screen w-screen items-center justify-center md:z-[801]">
      <div className="main-shadow fixed left-[50%] top-[60px] flex w-[800px] max-w-[90%] -translate-x-[50%] flex-col items-start rounded-[16px] bg-white px-[40px] pb-[40px] pt-[123px]">
        <CloseModal
          callback={() => {
            setEditBannerImage(false)
            setPreviewImgUrl("")
            setBannerImgFile(undefined)
            setImageProperties(null)
          }}
          styling="absolute top-6 right-8"
        />
        <h2 className="absolute left-[32px] top-[26px] text-[18px] font-bold">
          Edit Header Image
        </h2>
        <div className="mx-auto flex flex-col items-center">
          <p className="text-graySlate mb-[8px] text-balance text-center text-sm font-bold">
            Maximum file size: 5 megabytes. For best results, maintain a 3.5:1
            aspect ratio. Accepted formats: PNG, JPG, or JPEG.
          </p>
          <p className="text-graySlate mb-[24px] text-center">
            Looking for a header image? Get a free stock photo here{" "}
            <a
              href="https://pixabay.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blurple"
            >
              https://pixabay.com
            </a>
          </p>
        </div>
        <div className="mb-[48px] h-[217px] w-full">
          {coachPublicProfileData?.has_banner_image ? (
            <>
              {awaitNewImage ? (
                <DragDropArea
                  styling={` w-full h-full rounded-lg`}
                  mediaFile={bannerImgFile}
                  setMediaFile={setBannerImgFile}
                  mediaType="image"
                />
              ) : (
                <>
                  {previewImgUrl ? (
                    <img
                      src={previewImgUrl}
                      alt="preview"
                      className="h-full w-full rounded-lg object-cover"
                    />
                  ) : (
                    <img
                      src={`${
                        coachPublicProfileData?.banner_image
                      }?timestamp=${new Date().getTime()}`}
                      alt="banner"
                      className="h-full w-full rounded-lg object-cover"
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {previewImgUrl ? (
                <img
                  src={previewImgUrl}
                  alt="preview"
                  className="h-full w-full rounded-lg object-cover"
                />
              ) : (
                <DragDropArea
                  styling={` w-full h-full rounded-lg`}
                  mediaFile={bannerImgFile}
                  setMediaFile={setBannerImgFile}
                  mediaType="image"
                />
              )}
            </>
          )}
        </div>
        <div className="mx-auto flex flex-wrap items-center justify-center gap-[12px]">
          {loadingDelete ? (
            <Loader />
          ) : coachPublicProfileData?.has_banner_image ? (
            <Button variant={"utility"} onClick={deleteBannerImage}>
              <SvgTrash />
              Delete
            </Button>
          ) : (
            <></>
          )}
          {!awaitNewImage &&
          (coachPublicProfileData?.has_banner_image || previewImgUrl) ? (
            <Button
              variant="utility"
              onClick={() => {
                setBannerImgFile(undefined)
                setAwaitNewImage(true)
                setImageProperties(null)
                setPreviewImgUrl("")
              }}
            >
              <SvgUploadImage />
              Upload
            </Button>
          ) : (
            <></>
          )}
          {loadingSave ? (
            <Loader />
          ) : (
            <Button onClick={saveBannerImage} disabled={!imageProperties}>
              Save Image
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
