import * as React from "react";

export const PlaygroundPage: React.FC = () => {
  return (
    <>
      <div className="flex h-full flex-col items-center justify-center gap-3">
        <h1 className="text-muted-foreground text-4xl font-semibold">
          Playground
        </h1>
        <p className="text-muted-foreground">
          Do whatever you want in this file. Just please don't commit it! :)
        </p>
      </div>
    </>
  );
};
