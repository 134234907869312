import React from "react"
import { IINZoeeLogo } from "../../../../../components/icons/logos/zoee-iin-logo"

const bulletPoints = [
  "Reduce your time spent on admin, billing, scheduling, and client management by 60%",
  "Build a virtual coaching practice to meet potential client's where they're at - 74% of coaching clients transitioned to audio-visual coaching sessions since 2020",
  "Tap into the 72% of people who desire health coaching by learning how to create an irresistible signature program for your ideal client and sell it with confidence.",
]

const IINFlow = () => {
  return (
    <div className="md:w-[500px] h-fit md:p-10 px-4 py-10 flex flex-col gap-6">
      <IINZoeeLogo />
      <h1 className="font-bold text-[32px]">
        Get the Business Blueprint{" "}
        <span className="italic font-medium">for</span> Health Coaches for $695
      </h1>
      <ul className="text-[18px] list-disc flex flex-col gap-2">
        {bulletPoints.map((el: string) => (
          <li
            className="ml-4"
            key={el}
          >
            {el}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default IINFlow
