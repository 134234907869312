import type { SVGProps } from "react";
export const SvgDisconnect = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 8"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.832 8c-.198 0-.396 0-.594-.106l-2.476-1.377c-.396-.212-.693-.742-.693-1.166v-.742c0-.53-.297-.953-.792-1.06-1.584-.317-2.673-.317-4.356 0-.495.107-.792.53-.792 1.06v.636c0 .53-.297.954-.693 1.165L3.96 7.789C3.465 8 2.871 8 2.475 7.576L.495 5.457C.099 5.245 0 4.927 0 4.503c0-.318.198-.635.396-.847 5.545-4.875 13.663-4.875 19.208 0 .198.212.396.53.396.847 0 .318-.099.742-.396.954l-1.98 2.119c-.198.318-.495.424-.792.424"
    />
  </svg>
);
