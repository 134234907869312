import React from 'react';
import {
    useVideo,
    selectIsPeerVideoEnabled,
    useHMSStore,
    selectIsPeerAudioEnabled
  } from "@100mslive/react-sdk";
import { SvgMicOff } from '../icons';

interface Props {
    peer: any;
    peerType: string;
}

const ScreensharePeer:React.FC<Props> = ({peer, peerType }) => {
    const { videoRef } = useVideo({
        trackId: peer.videoTrack
    });
    const videoOn = useHMSStore(selectIsPeerVideoEnabled(peer.id));
    const audioOn = useHMSStore(selectIsPeerAudioEnabled(peer.id));

    return (
        <>
            {peerType === "self" && (
                <div className={`absolute top-[24px] right-[40px] w-[152px] h-[85.5px] bg-black rounded-[10px]`}>
                    {videoOn ? (
                        <>
                            <video
                                className='w-full h-full rounded-[10px]'
                                ref={videoRef}
                                autoPlay
                                muted
                                playsInline
                            />
                            <div
                                className={`absolute font-bold bg-blur-md left-[50%] -translate-x-[50%] flex gap-[10px]
                                text-white text-base px-[4px] py-[2px] rounded-[10px] bg-black/[0.64] z-[5] items-center
                                justify-center bottom-[2px] w-[90%]`}
                            >
                                {peer.name + " (Me)"}
                                {!audioOn && (
                                    <div className="bg-white w-[16px] h-[16px] flex items-center justify-center rounded-full z-[6]">
                                        <SvgMicOff
                                            className='w-[8px] h-[8px]'
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white w-full text-center"
                        >
                            {peer.name + " (Me)"}
                        </div>
                    )}
                </div>
            )}
            {peerType === "presenter" && (
                <div className={`absolute top-[122px] right-[40px] w-[152px] h-[85.5px] bg-black rounded-[10px]`}>
                    {videoOn ? (
                        <>
                            <video
                                className='w-full h-full rounded-[10px]'
                                ref={videoRef}
                                autoPlay
                                muted
                                playsInline
                            />
                            <div
                                className={`absolute font-bold bg-blur-md left-[50%] -translate-x-[50%] flex gap-[10px]
                                text-white text-base px-[4px] py-[2px] rounded-[10px] bg-black/[0.64] z-[5] items-center
                                justify-center bottom-[2px] w-[90%]`}
                            >
                                {peer.name}
                                {!audioOn && (
                                    <div className="bg-white w-[16px] h-[16px] flex items-center justify-center rounded-full z-[6]">
                                        <SvgMicOff
                                            className='w-[8px] h-[8px]'
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div
                            className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white w-full text-center"
                        >
                            {peer.name}
                        </div>
                    )}
                </div>
            )}
        </>
  );
}

export default ScreensharePeer;
