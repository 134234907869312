import React from "react";

type Props = {
    isSession: boolean;
    isProgram: boolean;
};

const NoteTag: React.FC<Props> = ({ isSession, isProgram }) => {
    let tagColor = "";

    if (isProgram) {
        tagColor = "bg-primaryPurple";
    } else {
        tagColor = "bg-primaryBlue";
    }

    let tagName = "";

    if (isProgram) {
        tagName = "Program";
    } else {
        tagName = "Session";
    }

    return (
        <div
            className={`${tagColor} px-[8px] py-[4px] rounded-[8px] text-base text-white font-bold`}
        >
            {tagName}
        </div>
    );
};

export default NoteTag;
