import type { SVGProps } from "react";
export const SvgBilling = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M1.428 8.499A8.17 8.17 0 0 1 7.59 2.49l.504-.112a16 16 0 0 1 6.923 0l.505.112a8.17 8.17 0 0 1 6.16 6.008c.572 2.299.572 4.703 0 7.002a8.17 8.17 0 0 1-6.16 6.008l-.505.112c-2.28.505-4.643.505-6.923 0l-.504-.112a8.17 8.17 0 0 1-6.162-6.008 14.5 14.5 0 0 1 0-7.002Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.556 6.444c.425 0 .769.325.769.725v.593h.085c1.43 0 2.479 1.171 2.479 2.481 0 .4-.345.725-.77.725-.424 0-.769-.325-.769-.725 0-.63-.482-1.032-.94-1.032h-.085v2.281l1.039.356a2.256 2.256 0 0 1-.734 4.39h-.305v.593c0 .4-.344.724-.77.724-.424 0-.768-.324-.768-.724v-.593H10.7c-1.43 0-2.479-1.172-2.479-2.481 0-.4.345-.725.77-.725.424 0 .769.324.769.725 0 .63.482 1.032.94 1.032h.085v-2.281l-1.039-.356a2.256 2.256 0 0 1 .734-4.39h.306v-.593c0-.4.344-.725.769-.725m-.77 2.767h-.304c-.337 0-.721.3-.721.807 0 .387.239.678.512.771l.514.176zm1.539 3.823v1.755h.305c.336 0 .72-.3.72-.807 0-.387-.239-.678-.512-.772z"
      clipRule="evenodd"
    />
  </svg>
);
