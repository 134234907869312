import type { SVGProps } from "react";
export const SvgAudio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M3 15.483V8.517a.25.25 0 0 1 .25-.25h4.158a.25.25 0 0 0 .162-.06l4.518-3.855a.25.25 0 0 1 .412.19v14.916a.25.25 0 0 1-.412.19L7.57 15.793a.25.25 0 0 0-.162-.06H3.25a.25.25 0 0 1-.25-.25ZM15 15a3 3 0 1 0 0-6"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M16 18c2.761 0 5-2.686 5-6s-2.239-6-5-6"
    />
  </svg>
);
