import React, { FC, PropsWithChildren, useEffect } from "react"
import HappyCoach from "../../../assets/images/HappyCoach.png"
import { IINZoeeLogo } from "../../../components/icons/logos/zoee-iin-logo"
import { StepType } from ".."
import { SvgCheckCircle } from "../../../components/icons"
import { SvgZoeeLogoTM } from "../../../components/icons/logos"
import { useSearchParams, Link } from "react-router-dom"
import { useIntercom } from "react-use-intercom"

interface StepsLayoutProps extends PropsWithChildren {
  isIIN?: boolean
  step?: StepType
}

const StepsLayout: FC<StepsLayoutProps> = ({ children, isIIN, step }) => {
  const { shutdown } = useIntercom()
  useEffect(() => {
    shutdown()
  }, [shutdown])

  const [searchParams] = useSearchParams()
  const isIINFromParams = searchParams.get("flow") || ""

  const conditionalRendering =
    step !== StepType.Email && step !== StepType.VerifyEmail

  return (
    <div className="h-[100vh] px-5 md:px-0 bg-[white] flex flex-col">
      <div className="flex flex-col justify-between items-center p-4 md:py-4 md:px-[72px] md:flex-row border-b border-[#D1D9E0]">
        {isIIN || isIINFromParams ? (
          <Link to="/IINBusinessBlueprint">
            <IINZoeeLogo />
          </Link>
        ) : (
          <SvgZoeeLogoTM className="h-6 w-[125px]" />
        )}
        {isIIN || isIINFromParams ? (
          <p className="text-[24px] font-bold text-center">
            Business Blueprint <span className="italic font-medium">for</span>{" "}
            Health Coaches
          </p>
        ) : null}
      </div>
      <div
        className={`flex-grow flex-col md:flex-row ${
          !conditionalRendering && "md:px-[72px] py-10"
        } flex items-center`}
      >
        <div className="md:w-[500px] p-10 flex flex-col justify-between gap-6 mx-auto rounded-[12px] bg-[#FFF]">
          {children}
        </div>
        {conditionalRendering &&
          (step !== StepType.IINCheckout ? (
            <div
              className="hidden w-[550px] sm:hidden md:block bg-no-repeat bg-cover bg-center h-full ml-[100px]"
              style={{ backgroundImage: `url(${HappyCoach})` }}
            ></div>
          ) : step === undefined ? (
            <div className="hidden w-[550px] sm:hidden md:block bg-no-repeat bg-cover bg-center h-full ml-[100px]">
              <p>Loading...</p>
            </div>
          ) : (
            <div className="w-full md:w-[600px] bg-no-repeat bg-cover bg-center md:ml-[100px] h-full flex flex-col justify-center bg-[#F2F5F7] ">
              <div className="flex flex-col justify-center sm:mt-32 p-8 bg-[#F2F5F7]">
                <p
                  className="text-[22px] mb-2 font-bold "
                  style={{
                    fontFamily: "BeausiteClassic",
                  }}
                >
                  Business Blueprint for Health Coaches
                </p>
                <p>Includes:</p>
                <div className="space-y-2">
                  <div className="flex items-center">
                    <SvgCheckCircle stroke="#4750f5" />
                    <span className="ml-2">Business Blueprint Workbooks</span>
                  </div>
                  <div className="flex items-center">
                    <SvgCheckCircle stroke="#4750f5" />
                    <span className="ml-2">3-month subscription to Zoee</span>
                  </div>
                  <div className="flex items-center">
                    <SvgCheckCircle stroke="#4750f5" />
                    <span className="ml-2">
                      Live business support and accountability
                    </span>
                  </div>
                  <div className="flex items-center">
                    <SvgCheckCircle stroke="#4750f5" />
                    <span className="ml-2">And more!</span>
                  </div>
                </div>
                <hr className="mt-12 border-t border-gray-700 mb-12" />
                <p className="text-darkGray mb-4 font-bold">SUMMARY</p>

                <div className="flex items-center w-full mb-4">
                  <p className="flex-shrink-0">BBHC</p>
                  <div className="flex-grow border-t border-gray-700 mx-2"></div>
                  <p className="text-right flex-shrink-0">$695.00</p>
                </div>
                <div className="flex items-center w-full mb-4">
                  <p className="flex-shrink-0">Course Tax (7%)</p>
                  <div className="flex-grow border-t border-gray-700 mx-2"></div>
                  <p className="text-right flex-shrink-0">$48.65</p>
                </div>
                <div className="flex items-center w-full mb-4">
                  <p className="flex-shrink-0">Zoee Subscription</p>
                  <div className="flex-grow border-t border-gray-700 mx-2"></div>
                  <p className="text-right flex-shrink-0">$0</p>
                </div>

                <div className="flex items-center justify-between w-full mb-4">
                  <p className="flex-shrink-0 text-lg font-bold">
                    Total due Today
                  </p>
                  <div className="flex-grow border-t border-gray-700 mx-2"></div>
                  <p className="text-right flex-shrink-0">$743.65</p>
                </div>
              </div>{" "}
            </div>
          ))}
      </div>
    </div>
  )
}

export default StepsLayout
