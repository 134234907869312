import React, { useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { SvgCheckboxOff, SvgCheckbox, SvgChevronDown } from "../icons/";

interface Props {
  contacts: any;
  setContacts: any;
  selectedSort: string;
  selectedFilter: string;
  setSelectedSort: any;
  setSelectedFilter: any;
}

const ContactFilters: React.FC<Props> = ({
  contacts,
  setContacts,
  selectedSort,
  selectedFilter,
  setSelectedSort,
  setSelectedFilter,
}) => {
  const [filterExpanded, setFilterExpanded] = React.useState<boolean>(false);
  const [sortByExpanded, setSortByExpanded] = React.useState<boolean>(false);
  const ref = useRef(null);

  useOutsideClick(ref, () => {
    if (sortByExpanded) {
      setSortByExpanded(false);
    }
  });

  const toggleSortBy = () => {
    setSortByExpanded(!sortByExpanded);
  };

  const sortContactsBy = (sortValue: string) => {
    const sorted = [...contacts];

    if (sortValue === "first") {
      sorted?.sort((a: any, b: any) =>
        a.contact_name
          ?.split(" ")[0]
          .localeCompare(b.contact_name?.split(" ")[0], undefined, {
            sensitivity: "base",
          })
      );
    } else if (sortValue === "last") {
      sorted?.sort((a: any, b: any) =>
        a.contact_name
          ?.split(" ")[1]
          .localeCompare(b.contact_name?.split(" ")[1], undefined, {
            sensitivity: "base",
          })
      );
    }

    setContacts(sorted);
    setSelectedSort(sortValue);
  };

  return (
    <div className="flex items-center gap-[32px]">
      <div
        className="relative"
        ref={ref}
      >
        <button
          className="flex items-center gap-[8px] font-bold text-base"
          onClick={toggleSortBy}
        >
          Sort by
          <SvgChevronDown
            className={`${sortByExpanded ? "rotate-[180deg]" : ""}`}
          />
        </button>
        {sortByExpanded && (
          <div
            className="absolute right-0 top-[40px] py-[24px] px-[32px] bg-white
                        rounded-[12px] main-shadow flex flex-col z-[300]"
          >
            <span className="mb-[16px] text-graySlate text-base mr-auto">
              Sort by
            </span>
            <div className="w-[184px] flex flex-col gap-[10px] text-base">
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("none")}
              >
                None
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${selectedSort === "none" && "bg-blurple"}`}
                >
                  {selectedSort === "none" && <SvgCheckbox />}
                </div>
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("first")}
              >
                First Name
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${
                                      selectedSort === "first" && "bg-blurple"
                                    }`}
                >
                  {selectedSort === "first" && <SvgCheckbox />}
                </div>
              </button>
              <button
                className="flex items-center justify-between w-full"
                onClick={() => sortContactsBy("last")}
              >
                Last Name
                <div
                  className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${selectedSort === "last" && "bg-blurple"}`}
                >
                  {selectedSort === "last" && <SvgCheckbox />}
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactFilters;
