import type { SVGProps } from "react";
export const SvgSyncNeeded = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 49 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.848 17.863a1.006 1.006 0 0 0 1.231.705c.536-.146.851-.697.706-1.232l-.976-3.593a1 1 0 0 0-1.232-.706c-.535.146-.85.697-.705 1.232zM19.388 20.311a1 1 0 0 0 .71.295 1.002 1.002 0 0 0 .71-1.713l-2.917-2.916a1 1 0 0 0-1.419 0 1 1 0 0 0 0 1.419zM14.242 22.313l3.816 1.026q.134.036.262.036a1.006 1.006 0 0 0 .262-1.976l-3.817-1.026a1.004 1.004 0 0 0-.523 1.94M26.152 21.258l3.067-3.067a2.5 2.5 0 0 1 1.773-.728h.006c.67 0 1.291.252 1.755.716.979.98.979 2.57 0 3.55L28.95 25.53a2.34 2.34 0 0 1-1.384.678 1 1 0 0 0-.145.033 2.49 2.49 0 0 1-1.976-.67 1.006 1.006 0 0 0-1.148-.4 1.005 1.005 0 0 0-.642 1.264c.047.151.217.401.33.514.848.848 2.02 1.318 3.129 1.318q.29 0 .577-.044.162-.001.307-.048a4.37 4.37 0 0 0 2.373-1.226l3.805-3.804a4.52 4.52 0 0 0 0-6.387 4.45 4.45 0 0 0-3.174-1.303h-.009a4.48 4.48 0 0 0-3.189 1.318l-3.07 3.064a1.004 1.004 0 0 0 1.419 1.422"
    />
    <path
      fill="currentColor"
      d="M17.252 27.315A4.505 4.505 0 0 0 20.435 35h.02a4.45 4.45 0 0 0 3.172-1.309l3.097-3.093a1 1 0 0 0 0-1.42 1 1 0 0 0-1.42 0l-3.099 3.091a2.45 2.45 0 0 1-1.752.723h-.01a2.496 2.496 0 0 1-1.773-4.257l3.82-3.816c.94-.937 2.508-.952 3.466-.05a1.005 1.005 0 0 0 1.791-.869 1.6 1.6 0 0 0-.315-.497 4.5 4.5 0 0 0-6.36 0z"
    />
  </svg>
);
