import React, { useState, useContext } from "react"
import Loader from "../../ui/loader"
import { Button } from "../../ui/button"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { ProfileClientPaymentsItem } from "../../../types/clientPaymentTypes"
import { ServiceRequests } from "../../../api/app.service"
import { SvgDownload, SvgEye } from "../../icons"
import { UserCtx } from "../../../context/userContext"

interface Props {
  invoice: ProfileClientPaymentsItem
  serviceState: any
  onRefundSuccess: () => void
}

const BillingPreviewItem: React.FC<Props> = ({
  invoice,
  serviceState,
  onRefundSuccess,
}) => {
  const { user } = useContext(UserCtx)
  const [loading, setLoading] = useState(false)
  const { renderSuccess } = useContext(CommonFunctionCtx)

  const formattedAmount = parseInt(invoice.amount).toLocaleString()

  const formatStripeStatus = (stripeStatus: string) => {
    if (stripeStatus === "succeeded") {
      return "Paid"
    } else {
      return stripeStatus
    }
  }

  const refundService = async () => {
    if (user) {
      setLoading(true)
      try {
        const data = await ServiceRequests.refundService({
          capture_id: invoice.paypal_capture_id,
          amount: invoice.amount,
        })
        renderSuccess("Refund Successful!")
        setLoading(false)
        onRefundSuccess()
      } catch (error: any) {
        console.error("Refund service error:", error.message)
        alert(`Refund failed: ${error.message}`)
        setLoading(false)
      }
    } else {
      console.warn("User is not logged in.")
      alert("You must be logged in to process a refund.")
      setLoading(false)
    }
  }

  return (
    <div className="w-full h-[76px] border border-grayMist rounded-[10px] px-[24px] flex items-center justify-between">
      <div className="flex items-center gap-[24px]">
        <div className="w-[1px] h-[40px] bg-grayMist"></div>
        <div className="flex flex-col">
          <h6 className="font-bold text-[16px]">{invoice?.service_name}</h6>
          <span className="text-gray text-base">
            {formatStripeStatus(invoice.stripe_status)}
          </span>
        </div>
      </div>
      <div className="relative flex items-center gap-[24px]">
        <h5 className="font-bold text-[16px]">
          {invoice &&
            String.fromCharCode(parseInt(invoice?.symbol?.slice(2), 16))}
          {formattedAmount}
        </h5>
        {serviceState === "client" && (
          <Button
            onClick={refundService}
            className="ml-auto"
            disabled={invoice.payment_status === "REFUNDED"}
          >
            {loading ? <Loader /> : "Refund"}
          </Button>
        )}
      </div>
    </div>
  )
}

export default BillingPreviewItem

interface DropdownProps {
  viewInvoiceUrl: string
  downloadInvoiceUrl: string
}

const BillingPreviewItemDropdown: React.FC<DropdownProps> = ({
  viewInvoiceUrl,
  downloadInvoiceUrl,
}) => {
  return (
    <div className="flex flex-col bg-white px-[16px] py-[12px] rounded-[12px] card-hover-shadow">
      <a
        href={viewInvoiceUrl}
        target="_blank"
        className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
        rel="noreferrer"
      >
        <SvgEye />
        View Receipt
      </a>
      <a
        href={downloadInvoiceUrl}
        target="_blank"
        className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
        rel="noreferrer"
      >
        <SvgDownload />
        Download Invoice PDF
      </a>
    </div>
  )
}
