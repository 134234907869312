import React, { useContext } from "react";
import { Button } from "../ui/button";
import { AuthContext } from "./context";
import { RegisterFormContext } from "./register-form/context";

export const SwapAuthAction: React.FC = () => {
  const { authAction, setAuthAction } = React.useContext(AuthContext);
  const { accountType } = useContext(RegisterFormContext);

  if (authAction === "register" && accountType === "coach") {
    return (
      <p className="text-muted-foreground text-sm text-center">
        Already have an account?{" "}
        <Button
          variant="link"
          size="sm"
          type="button"
          onClick={() => setAuthAction("login")}
        >
          Login
        </Button>
      </p>
    );
  } else if (authAction !== "register") {
    return (
      <p className="text-muted-foreground text-sm text-center">
        Don't have an account?{" "}
        <Button
          variant="link"
          size="sm"
          type="button"
          onClick={() => setAuthAction("register")}
        >
          Register
        </Button>
      </p>
    );
  }
  return null;
};
