import { FC } from "react";
import moneyflowLogo from "../../../assets/images/moneyflow-logo.png"

type Props = {
    pypIsEnabled: boolean;
    variation: string;
};

const LandingSplashText: FC<Props> = ({ pypIsEnabled, variation }) => {
    return (
        <>
            {!pypIsEnabled ? (
                <>
                    {/* LARGER SCREENS pyp disabled */}
                    <div className="hidden md:block">
                        {variation === "normal" && (
                            <>
                                <h1 className="md:text-[60px] lg:text-[70px] xl:text-[80px] font-bold leading-[100%] mb-[18px]">
                                    Run the coaching
                                    <br />
                                    business of
                                    <br />
                                    your dreams
                                </h1>
                                <div 
                                    className="flex flex-wrap sm:text-[18px] md:text-[20px] lg:text-[24px] items-center font-bold gap-[8px]
                                    max-w-[635px] mb-[40px]"
                                >
                                    <span>AUTOPAY</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>CLIENT MANAGEMENT</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>MARKETING</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>SCHEDULING</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>CHAT & VIDEO</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>COMMUNITY</span>
                                </div>
                            </>
                        )}
                        {variation === "moneyflow" ? (
                            <>
                                <img src={moneyflowLogo} alt="moneyflow logo" className="max-w-[272px] md:mb-[70px] lg:mb-[100px]" />
                                <h1 className="text-white max-w-[640px] text-[54px] xl:text-[72px] leading-none">
                                    When there are many right solutions, <strong>choose the simplest path</strong>
                                </h1>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* MOBILE SCREENS pyp disabled */}
                    <div className="md:hidden">
                        {variation === "normal" && (
                            <>
                                <h1 className="text-[50px] font-bold leading-[100%] mb-[18px]">
                                    Run the coaching
                                    <br />
                                    business of
                                    <br />
                                    your dreams
                                </h1>
                                <div 
                                    className="flex flex-wrap text-[18px] items-center font-bold gap-[8px]
                                    max-w-[635px] mb-[40px]"
                                >
                                    <span>AUTOPAY</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>CLIENT MANAGEMENT</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>MARKETING</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>SCHEDULING</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>CHAT & VIDEO</span>
                                    <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                                    <span>COMMUNITY</span>
                                </div>
                            </>
                        )}
                        {variation === "moneyflow" && (
                            <>
                                <img src={moneyflowLogo} alt="moneyflow logo" className="max-w-[272px] mb-[40px] lg:mb-[100px]" />
                                <h1 className="text-white font-bold text-[42px] leading-none mb-[40px]">
                                    Zoee is so excited to be part of this awesome event!
                                </h1>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {/* LARGER SCREENS pyp enabled */}
                    <div className="hidden md:block">
                        <h1 className="md:text-[60px] lg:text-[70px] xl:text-[80px] font-bold leading-[100%] mb-[18px]">
                            Run the coaching
                            <br />
                            business of
                            <br />
                            your dreams
                        </h1>
                        <div 
                            className="flex flex-wrap sm:text-[18px] md:text-[20px] lg:text-[24px] items-center font-bold gap-[8px]
                            max-w-[635px] mb-[40px]"
                        >
                            <span>AUTOPAY</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>CLIENT MANAGEMENT</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>MARKETING</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>SCHEDULING</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>CHAT & VIDEO</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>COMMUNITY</span>
                        </div>
                    </div>

                    {/* MOBILE SCREENS pyp enabled */}
                    <div className="md:hidden max-w-[420px]">
                        <h1 className="text-[50px] font-bold leading-[100%] mb-[18px]">
                            Run the coaching
                            <br />
                            business of
                            <br />
                            your dreams
                        </h1>
                        <div 
                            className="flex flex-wrap text-[18px] items-center font-bold gap-[8px]
                            max-w-[635px] mb-[40px]"
                        >
                            <span>AUTOPAY</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>CLIENT MANAGEMENT</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>MARKETING</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>SCHEDULING</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>CHAT & VIDEO</span>
                            <div className="w-[10px] h-[10px] rounded-full bg-black"></div>
                            <span>COMMUNITY</span>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default LandingSplashText;
