import type { SVGProps } from "react";
export const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M26.3638 7.22696C25.2146 5.91097 23.306 5.27142 20.53 5.27142H20.2098L20.2096 5.20567C20.2096 2.88658 20.2096 0 15.1488 0H13.0994C8.03862 0 8.03862 2.88652 8.03862 5.20567L8.03884 5.27142H7.71863C4.94245 5.27142 3.0338 5.91097 1.88483 7.22696C0.550549 8.75452 0.590466 10.7957 0.735639 12.1849L1.69776 22.7411C1.98538 25.5061 3.07838 28.3333 8.99975 28.3333H19.2491C25.1708 28.3333 26.2634 25.5059 26.5528 22.7275L27.5124 12.1983C27.6586 10.7956 27.6987 8.75446 26.3645 7.22696H26.3638ZM9.96056 5.20568C9.96056 2.85569 9.96056 1.97673 13.0997 1.97673H15.149C18.2881 1.97673 18.2881 2.85564 18.2881 5.20568L18.2879 5.27142H9.96024L9.96056 5.20568ZM24.6403 22.5297C24.422 24.6235 23.8659 26.3563 19.2486 26.3563H8.9992C4.38191 26.3563 3.82577 24.6233 3.60872 22.5431L2.91081 14.8842C5.29643 16.3136 7.92425 17.2713 10.6273 17.6997C10.7339 18.9678 11.2834 20.4263 14.1242 20.4263C16.9644 20.4263 17.5142 18.9629 17.6209 17.6767C20.3546 17.2158 22.9798 16.2326 25.3462 14.7891L24.6403 22.5297ZM12.5225 16.8418V15.4846C12.5225 15.3758 12.5225 15.2613 12.5238 15.1565C12.6259 15.1552 12.7372 15.1552 12.8429 15.1552H15.4053C15.511 15.1552 15.6223 15.1552 15.7242 15.1565C15.7255 15.2613 15.7255 15.3758 15.7255 15.4846V16.8288C15.7255 18.2335 15.7255 18.4497 14.124 18.4497C12.5225 18.4497 12.5225 18.2039 12.5225 16.8418H12.5225ZM25.6001 12.0008L25.5735 12.2936C23.2095 13.9839 20.4983 15.1411 17.6472 15.6723V15.4845C17.6472 13.6307 17.2075 13.1784 15.4053 13.1784H12.8429C11.0407 13.1784 10.601 13.6307 10.601 15.4845V15.699C7.78982 15.2044 5.07355 14.0794 2.68567 12.4087L2.64728 11.9873C2.47698 10.3568 2.68889 9.26357 3.3139 8.54811C4.06716 7.6857 5.54897 7.24822 7.71843 7.24822H20.5297C22.6992 7.24822 24.1812 7.6857 24.9342 8.54811C25.5595 9.26357 25.7716 10.3567 25.6 12.0008L25.6001 12.0008Z" />
  </svg>
);
