import { SvgCapterra, SvgStarRating } from "../../../../components/icons";
import MostRecommendedBadge from "../../../../assets/images/capterra-badges/sa-most_recommended-2023.png";
import BestValueBadge from "../../../../assets/images/capterra-badges/ca-value-2023.png";
import CustomerSupportBadge from "../../../../assets/images/capterra-badges/sa-customer_support-2023.png";
import { Link } from "react-router-dom";

const badges: { src: string; alt: string }[] = [
  { src: MostRecommendedBadge, alt: "Badge: Most Recommended 2023" },
  { src: BestValueBadge, alt: "Badge: Best Value 2023" },
  { src: CustomerSupportBadge, alt: "Badge: Best Customer Support 2023" },
];

export function Badges() {
  return (
    <>
      <Link
        to="https://www.capterra.com/p/10002877/Zoee/reviews/"
        target="_blank"
        rel="noopener noreferrer"
        className="mb-8 flex flex-col items-center justify-center gap-4"
      >
        <SvgCapterra className="h-8" />
        <div className="flex items-center justify-center gap-2">
          <SvgStarRating className="h-8" />
          <span className="text-[#044D80] text-lg font-normal">4.8</span>
        </div>
      </Link>
      <div className="flex justify-center items-center flex-wrap gap-4 mt-22 gap-8">
        {badges.map(({ src, alt }) => (
          <img
            key={src}
            src={src}
            alt={alt}
            className="h-20 sm:h-24 md:h-28 lg:h-32 xl:h-40"
          />
        ))}
      </div>
    </>
  );
}
