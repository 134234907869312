import React from "react";
import CloseModal from "./CloseModal";
import { SvgCheckCircle } from "./icons";

interface Props {
  setInviteSent: any;
}

const InviteSent: React.FC<Props> = (props: any) => {
  const closeAndReset = () => {
    props.setInviteSent(false);
  };

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter
            z-[801] flex items-center justify-center"
    >
      <div
        className="w-[700px] h-[346px] bg-white main-shadow rounded-[16px]
                flex items-center justify-center relative"
      >
        <CloseModal
          callback={closeAndReset}
          styling="absolute top-[24px] right-[24px] cursor-pointer"
        />
        <div className="flex flex-col items-center gap-[16px]">
          <SvgCheckCircle />
          <span className="text-[22px] font-bold">Invite sent</span>
        </div>
      </div>
    </div>
  );
};

export default InviteSent;
