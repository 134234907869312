import React from "react"
import { SvgCheckCircle } from "../../components/icons"
import { Button } from "../../components/ui/button"

type Props = {
  plan: any
  title: string
  handleChoosePlanClick: (plan: any) => void
}

const ICFSubscriptionPlanCard: React.FC<Props> = ({
  plan,
  title,
  handleChoosePlanClick,
}) => {
  const [cardDetailsColor, setCardDetailsColor] = React.useState<string>("")
  const [cardColor, setCardColor] = React.useState<string>("")
  const [price, setPrice] = React.useState<string>("")
  const [billingPeriod, setBillingPeriod] = React.useState<string>("")
  const [annualPeriod, setAnnualPeriod] = React.useState<string>("")
  const [marketPrice, setMarketPrice] = React.useState<string>("")

  // const assignCardColor = () => {
  //   if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
  //     setCardDetailsColor("blurple")
  //   }
  //   if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
  //     setCardDetailsColor("blurple")
  //   }
  //   if (plan?.amount === 500 && plan?.is_promotional) {
  //     setCardDetailsColor("mainBlack")
  //     setCardColor("subscription-cta-gradient")
  //   }
  // }

  const setPeriod = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
      setPrice("$41.00")
      setMarketPrice("$55/mo")
      setBillingPeriod("/month")
      setAnnualPeriod("Billed annually at $490")
    } else if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
      setPrice("$44.10")
      setMarketPrice("$69/mo")
      setBillingPeriod("/month")
    } else if (plan?.amount === 500 && plan?.recurring_interval_count === 2) {
      setPrice(`$${plan.amount}`)
      setBillingPeriod("one time cost")
    }
  }

  React.useEffect(() => {
    // assignCardColor()
    setPeriod()
  }, [plan])

  return (
    <div
      className={`${cardColor} w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white`}
    >
      {annualPeriod ? (
        <div className="w-full h-[35px] bg-blurple rounded-t-[32px] flex items-center justify-center">
          <p className="text-white text-center">10% off for ICF members</p>
        </div>
      ) : (
        <div className="w-full h-[35px] bg-black rounded-t-[32px] flex items-center justify-center">
          <p className="text-white text-center">10% off for ICF members</p>
        </div>
      )}
      <div className="px-[32px] pb-[32px] pt-[24px]">
        <div>
          <h2 className="mb-[8px] font-bold md:font-semibold text-[22px] md:text-[28px]">
            {title}
          </h2>
          <p className="mb-[24px] md:mb-[40px] text-graySlate">
            {plan?.stripe_product_description}
          </p>
          <div className="flex font-bold items-start gap-[4px] leading-none">
            <h4 className="text-[80px] -translate-y-[10px]">{price}</h4>
            {billingPeriod && (
              <div className="flex flex-col">
                <h4 className="text-[18px] text-graySlate">{billingPeriod}</h4>
              </div>
            )}
          </div>
          <div className="mb-0">
            <p className="text-[18px] text-graySlate line-through">
              {marketPrice}
            </p>

            {annualPeriod ? (
              <p className="text-[18px] text-graySlate">{annualPeriod}</p>
            ) : (
              <p className="text-[18px] text-graySlate">&nbsp;</p>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-[12px]">
          <Button
            onClick={() => handleChoosePlanClick(plan)}
            variant={
              plan?.recurring_interval === "annual" ? "default" : "outline"
            }
            size={"lg"}
            className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
          >
            Select Plan
          </Button>
          {Array.from({ length: 10 }, (_, index) => (
            <div className="flex items-center gap-[16px]" key={index}>
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">
                {plan?.zoee_product_metadata[`feature${index + 1}`]}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ICFSubscriptionPlanCard
