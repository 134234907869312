import React from "react"
import { SvgArrowRight } from "../icons"
import { useNavigate } from "react-router-dom"

const IINQuestions: React.FC<{ pathname: string }> = ({ pathname }) => {
  const navigate = useNavigate()

  const handleCreateAccount = () => {
    navigate("/registration", { state: { from: pathname } })
  }

  return (
    <div
      className="py-[56px] md:py-[96px] flex flex-col items-center justify-center overflow-hidden md:px-[144px] px-4 sm:px-8"
    >
      <h2
        className="text-black text-[48px] text-center font-bold leading-[48px] mb-12 mx-auto"
        style={{
          fontFamily: "BeausiteClassic",
          fontSize: "40px",
          textAlign: "center",
        }}
      >
        Are you a coach who is
      </h2>

      <div className="w-full max-w-4xl flex flex-col gap-6">
        {[
          "Unclear where to start or what to do to run a business?",
          "Held back by feelings of overwhelm and/or imposter syndrome?",
          "Seeking accountability and support to achieve your career goals?",
          "Struggling to find clients and grow your network?",
          "Looking for a simple, streamlined, cost-effective solution to start or manage your practice?",
        ].map((text, index) => (
          <div key={index} className="flex flex-col gap-4">
            <div className="flex items-start gap-6">
              <div
                className="text-black"
                style={{
                  fontFamily: "BeausiteClassic",
                  fontSize: "24px",
                  fontWeight: 600,
                  lineHeight: "28.8px",
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                •
              </div>
              <div
                className="text-black"
                style={{
                  fontFamily: "BeausiteClassic",
                  fontSize: "24px",
                  fontWeight: 500,
                  lineHeight: "28.8px",
                  textAlign: "left",
                  textUnderlinePosition: "from-font",
                  textDecorationSkipInk: "none",
                }}
              >
                {text}
              </div>
            </div>
            {/* Divider */}
            <div className="w-full h-px bg-[#abb8c3]" />
          </div>
        ))}
      </div>
      <div className="mt-12 text-center w-full max-w-4xl">
        <div
          className="text-black font-bold mb-6"
          style={{
            fontFamily: "BeausiteClassic",
            fontSize: "22px",
            fontWeight: 500,
            lineHeight: "26.4px",
            textAlign: "center",
          }}
        >
          The Business Blueprint for Health Coaches will help you break through
          these blocks and more so you can build your dream business!
        </div>
        <div className="flex justify-center">
          <button
            className="px-6 py-3 bg-[#4750f5] text-white text-base font-bold rounded-lg flex items-center gap-2"
            onClick={handleCreateAccount}
          >
            REGISTER NOW
            <SvgArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default IINQuestions
