import React from "react";
import { SvgCalendar, SvgSettings } from "../../icons";
import { Badge } from "../../ui/badge";
import { Button } from "../../ui/button";

interface Props {
  calendar: any;
  setEditCalendarSettings: any;
  fetchUserCalendars?: any;
}

const ConnectedCalendar: React.FC<Props> = ({
  calendar,
  setEditCalendarSettings,
}) => {
  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-[10px] font-bold text-base">
          <SvgCalendar />
          <span className="whitespace-nowrap">{calendar.calendar_name}</span>
          <div className="flex gap-[12px] items-center md:ml-[24px]">
            {calendar?.is_primary && (
              <Badge>Primary</Badge>
            )}
          </div>
        </div>
      </div>
      
      <Button
        variant="outline"
        onClick={() =>
          setEditCalendarSettings({ show: true, calendar: calendar })
        }
      >
        <SvgSettings />
        <span className="hidden md:inline">Settings</span>
      </Button>
    </div>
  );
};

export default ConnectedCalendar;
