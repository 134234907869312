import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserCtx } from '../../context/userContext';

const PrivateRouteSubscription = () => {
    const { user } = React.useContext(UserCtx);
    
    return user?.isCoach === true ? <Outlet /> : <Navigate to={"/member"} />
}

export default PrivateRouteSubscription;
