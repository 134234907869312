import type { SVGProps } from "react";
export const SvgNotebook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.5 2H8.318c-1.454 0-2.909.826-3.636 2.11-.273.459-.091 1.01.363 1.284.455.276 1 .092 1.273-.366.364-.734 1.182-1.193 2-1.193H16.5c1.273 0 2.364 1.1 2.364 2.385v11.56c0 1.284-1.091 2.385-2.364 2.385H8.318c-1.273 0-2.363-1.1-2.363-2.385v-.275h.727c.545 0 .909-.367.909-.918 0-.55-.364-.917-.91-.917H3.41c-.545 0-.909.367-.909.917s.364.918.91.918h.726v.275c0 2.293 1.91 4.22 4.182 4.22H16.5c2.273 0 4.182-1.927 4.182-4.22V6.22c0-2.293-1.91-4.22-4.182-4.22"
    />
    <path
      fill="currentColor"
      d="M3.474 8.364h.78v5.454c0 .546.389.91.973.91.585 0 .974-.364.974-.91V8.364h.78c.584 0 .974-.364.974-.91 0-.545-.39-.909-.974-.909H3.474c-.584 0-.974.364-.974.91 0 .545.39.909.974.909"
    />
  </svg>
);
