import * as React from "react";
import { OnSuccessfulAuthCallback } from "../context";
import { LoginFormProvider } from "./context";
import { Form } from "./form";

interface Props {
  defaultEmail: string;
  onSuccess: OnSuccessfulAuthCallback;
}

export const LoginForm: React.FC<Props> = ({ defaultEmail, onSuccess }) => {
  return (
    <LoginFormProvider defaultEmail={defaultEmail} onSuccess={onSuccess}>
      <Form />
    </LoginFormProvider>
  );
};
