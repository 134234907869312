import React, { useEffect, useState } from "react";

interface Props {
  charNumber: number;
  inputText: any;
}

const CharLimit: React.FC<Props> = ({ charNumber, inputText }) => {
    
  const [characterCount, setCharacterCount] = useState(charNumber);

  useEffect(() => {
    const remainingCharacters = inputText ? charNumber - inputText.length : charNumber;
    setCharacterCount(remainingCharacters);
  }, [charNumber, inputText]);

  return (
    <span
      className={`font-normal text-sm ${
        characterCount < 0 ? "" : "text-graySlate"
      }`}
    >
      {characterCount} Characters Remaining
    </span>
  );
};

export default CharLimit;
