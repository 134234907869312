"use client"

import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

export function IaprcVideo() {
  const [shouldPlay, setShouldPlay] = React.useState(false)

  const controls = useAnimation()
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  React.useEffect(() => {
    if (inView) {
      setShouldPlay(true)
    }
  }, [inView])

  React.useEffect(() => {
    if (shouldPlay) {
      controls.start({ opacity: 1, scale: 1 })
    } else {
      controls.start({ opacity: 0, scale: 0.8 })
    }
  }, [shouldPlay, controls])

  return (
    <div className="w-full h-auto md:w-[584px] md:h-[475px]">
      <motion.div
        ref={inViewRef}
        className="w-full border-8 lg:border-12 border-black rounded-xl overflow-hidden relative"
        animate={controls}
      >
        <div
          style={{
            position: "relative",
            paddingTop: "65%",
          }}
        >
          <iframe
            src="https://fast.wistia.com/embed/medias/da7jerndvs"
            title="Zoee Video"
            allow="autoplay; fullscreen"
            allowFullScreen
            className="absolute inset-0 w-full h-full"
          ></iframe>
        </div>
      </motion.div>
    </div>
  )
}
