import * as React from "react";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { Button } from "../../../components/ui/button";
import { SvgCopy } from "../../../components/icons";

export const EscapeJsonPage: React.FC = () => {
  const [inputJson, setInputJson] = React.useState("");
  const [outputJson, setOutputJson] = React.useState("");
  const [, copy] = useCopyToClipboard();

  const handleInputChange = (event: any) => {
    setInputJson(event.target.value);
  };

  const formatJson = () => {
    try {
      const parsedJson = JSON.parse(inputJson);
      const formattedJson = JSON.stringify(parsedJson)
        .replace(/"/g, '\\"')
        .replace(/\s+/g, "");
      setOutputJson(`{ "body": "${formattedJson}" }`);
    } catch (error) {
      setOutputJson("Invalid JSON");
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-3xl font-bold">Escape JSON</h1>
      <textarea
        placeholder="Paste JSON data here"
        value={inputJson}
        onChange={handleInputChange}
        rows={6}
        className="w-full resize-y rounded-lg border bg-white p-3"
      />
      <Button onClick={formatJson}>Format JSON</Button>
      {outputJson && (
        <div className="relative min-h-[100px] rounded-lg border border-dashed p-3">
          <Button
            variant="secondary"
            size="icon"
            className="absolute right-3 top-3"
            onClick={() => copy(outputJson)}
          >
            <SvgCopy />
          </Button>
          <pre className="whitespace-pre-wrap break-all">{outputJson}</pre>
        </div>
      )}
    </div>
  );
};
