import type { SVGProps } from "react";
export const SvgQuoteRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.824 7.824h2.496c.528 0 .768.192.768.576 0 1.248-1.44 2.64-4.224 3.168l-.912 4.272c5.664-.912 8.736-2.784 9.84-8.112L23.472 0h-8.016zm-11.952 0h2.496c.528 0 .768.192.768.576 0 1.248-1.44 2.64-4.224 3.168L0 15.84c5.664-.912 8.736-2.784 9.84-8.112L11.52 0H3.504z"
    />
  </svg>
);
