import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserCtx } from '../../context/userContext';
import { ContactRequests } from '../../api/app.service';
import { useLocation } from 'react-router-dom';
import LoadingPage from '../LoadingPage';

const PrivateRouteContact = ({basePath = "/"}) => {
    const [isContact, setIsContact] = React.useState<any>();
    const { user } = React.useContext(UserCtx);
    const location = useLocation();
    const profileIdToMatch = parseInt(location.pathname.split("/")[3]);

    React.useEffect(() => {
        ContactRequests.getContactList({
            profile_id: user.activeProfileId,
            profile_type: user.activeProfile
        })
            .then((data) => {
                const contacts = data.contact_list;
                const matches = contacts.filter((contact:any) => contact.profile_id === profileIdToMatch);
                if(matches.length > 0){
                    setIsContact(true);
                } else {
                    setIsContact(false);
                }
            })
            .catch((ex) => {
                console.log(ex);
                setIsContact(false);
            });
    }, [])

    if (isContact === undefined) {
        return <LoadingPage />
    }

    return isContact ? <Outlet /> : <Navigate to={basePath} />;
}

export default PrivateRouteContact;
