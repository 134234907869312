import type { SVGProps } from "react";
export const SvgDinersClub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#0068A9"
      d="M21.167 3H2.877A1.88 1.88 0 0 0 1 4.878v14.207c0 1.037.841 1.878 1.878 1.878h18.29a1.88 1.88 0 0 0 1.877-1.878V4.878A1.88 1.88 0 0 0 21.167 3"
    />
    <path
      fill="#fff"
      d="M13.703 18.595H10.17c-4.155 0-7.21-2.67-7.21-6.499 0-4.19 3.055-6.728 7.21-6.728h3.533c4.108 0 7.383 2.537 7.383 6.728 0 3.83-3.275 6.5-7.383 6.5"
    />
    <path
      fill="#0068A9"
      d="M10.182 7.1a4.92 4.92 0 0 0-4.918 4.919 4.925 4.925 0 0 0 4.918 4.918 4.92 4.92 0 0 0 4.918-4.918A4.914 4.914 0 0 0 10.182 7.1"
    />
    <path
      fill="#fff"
      d="M9.066 14.923a3.11 3.11 0 0 1-1.998-2.904c0-1.326.831-2.46 1.998-2.913zM11.298 14.931V9.106a3.13 3.13 0 0 1 1.998 2.913c0 1.326-.831 2.459-1.998 2.912"
    />
  </svg>
);
