import type { SVGProps } from "react";
export const SvgChatBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M16.364 16.363c-2.75 2.75-6.823 3.345-10.156 1.804-.492-.199-3.677.683-4.368-.007s.192-3.876-.006-4.368C.292 10.46.887 6.384 3.637 3.634c3.511-3.512 9.216-3.512 12.727 0 3.518 3.518 3.511 9.217 0 12.729"
      clipRule="evenodd"
    />
  </svg>
);
