import previewImage from "../../../assets/blog/coachesCornerJacquelineWhitmore.webp";
import { Post } from "../../../types/postTypes";

export const coachesCornerJacquelineWhitmore: Post = {
    metadata: {
        id: 16,
        title: "What We Learned From an Etiquette Coach",
        subtitle: "Coach’s Corner: An Interview with Jacqueline Whitmore",
        imgTag: "Coach's Corner",
        author: "Zoee",
        date: "January 19, 2022",
        urlSlug: "coaches-corner-jacqueline-whitmore",
        description:
            "When it comes to etiquette coaching, there’s a lot more than meets the eye. Zoee sat down with Jacqueline Whitmore to learn more about what etiquette has to do with mindfulness, and what we can do to care for ourselves in the process.",
        previewImg: previewImage,
        tags: ["Coach’s Corner", "Etiquette Coaching", "Executive Coaching"],
    },
    content: [
        {
            type: "paragraph",
            text: "Picture this: You’re a 55-year-old woman whose children just moved out to attend college. You’ve got the “proud parent” bumper sticker, you’ve cleaned out their rooms after watching both seasons of The Home Edit, and now you’re sitting on your back patio nibbling on breakfast and wondering, “Well, what’s next?”",
        },
        {
            type: "paragraph",
            text: "Hiring an etiquette coach may not be the first thing you think to turn to. In fact, hiring an etiquette coach may be one of the last things you think about. But in fact, etiquette coaching isn’t all about what fork to use for the salad course or whether to clink glasses during a toast. In reality, etiquette coaching can help you navigate life changes—just like when you become an empty nester.",
        },
        {
            type: "paragraph",
            text: "Jaqueline Whitmore built her career around helping people “become mindful about how their behavior affects other people.” But how your behavior affects other people probably isn’t top of mind when you’re busy raising a family, tending to aging parents, and pretty much ensuring everyone else’s needs are met. Scrambling to answer text messages from a stressed-out child while you’re having dinner with your father who is experiencing Alzheimer’s may feel like the norm. Actually, no one’s getting your full attention, including you.",
        },
        {
            type: "paragraph",
            text: "Through a holistic approach that examines a person’s family of origin, daily habits, communication style, and future goals, Jacqueline helps her clients feel empowered, confident, and professional as they navigate change.",
        },
        {
            type: "paragraph",
            text: "While Jacqueline has many years of experience as an etiquette consultant, she never set out to make coaching a priority in her business. It was only five years ago that her phone began to ring with women looking for etiquette advice in the form of a coach. Being a savvy businesswoman, Jacqueline not only took the calls but has built a successful coaching business around her original mission: helping people become mindful about how their behavior affects other people.",
        },
        {
            type: "paragraph",
            text: "It may surprise you to learn that the women Jacqueline began coaching didn’t really need etiquette coaching; they needed life coaching. They needed someone to be a good listener, to help them figure out where they lost their confidence, and what they needed to do to get back on track. Today, Jacqueline works with people in group and individual settings to nurture their goals into reality. And if you have questions about dining etiquette, she’ll be able to help you with that too!",
        },
        {
            type: "paragraph",
            text: "Zoee had the opportunity to sit down with Jacqueline and hear more about what it’s like to be a coach, what she’s learned about people, and what we can do to care for ourselves now.",
        },
        {
            type: "header2",
            text: "What’s an impact you’ve had as a coach that you weren’t expecting?",
        },
        {
            type: "paragraph",
            text: "“I wasn’t expecting to become a girlfriend. Over the course of my time working with clients, (which is about eight weeks), the first half is really spent building trust and getting to know one another. Similar to when you’re dating someone new, you want to get to know their family of origin, what motivates them, hobbies they enjoy, and pretty much what makes them tick. During the second half of our time together, momentum builds—we know each other better and a friendship is created. As a result, I’ve become friends with many clients and I find myself meeting them for lunch or coffee to simply catch up on how life is going, even long after our client/coach relationship has ended.”",
        },
        {
            type: "header2",
            text: "When working with clients, what is the number one piece of advice you find yourself giving over and over?",
        },
        {
            type: "paragraph",
            text: "“I always stress the importance of taking care of themselves and putting themselves first. Especially with women, clients often put themselves last for so long. They’ve sacrificed their careers, they are taking care of aging parents, supporting their families, and holding it all together",
        },
        {
            type: "paragraph",
            text: "Etiquette isn’t just about whether or not to shake hands at a networking event; it’s also being kind to yourself and taking good care of your health. If you don’t practice self-care, if you aren’t healthy, then it affects every other area of your life. Imagine having to care for an aging parent while never paying attention to your own health. That doesn’t serve either party.",
        },
        {
            type: "paragraph",
            text: "When you put yourself first, you’ll prosper in other areas of your life.”",
        },
        {
            type: "header2",
            text: "What have you learned about people from being a coach?",
        },
        {
            type: "paragraph",
            text: "“As a person who wants to help people, I’ve learned not everyone is going to take your advice and not everyone is willing to do the work. As much as you want change for your clients, they have to want it for themselves too. People think they are going to do the work, and then they don’t. That being said, if a coach says they are successful 100% of the time, they aren’t telling the truth.”",
        },
        {
            type: "header2",
            text: "In your experience, how do you think the last two years(+) have affected our health?",
        },
        {
            type: "paragraph",
            text: "“Without a doubt, it’s taken a toll on all of our health. I feel that children and the elderly have suffered the most. College students have faced anxiety, depression, and isolation during a period of time that’s supposed to be about connection, learning, and growth. It has affected all of us in some way, some more than others.",
        },
        {
            type: "paragraph",
            text: "That’s one of the reasons why I think coaching is so important, because, for a professional person, it gives them some guidance. You, as a coach, become their north star. They don’t have to stress and worry about things they would ordinarily worry about because you’re there doing life with them.",
        },
        {
            type: "paragraph",
            text: "And because of that, I believe coaches should have coaches too. I recently hired a coach to help me with business development and she helped me greatly. You may not always need life coaching or health coaching; there are coaches for all sorts of life transitions. The average person might change coaches over the course of their life—don’t be afraid to try out different people.”",
        },
        {
            type: "header2",
            text: "What does it take to be a good coach?",
        },
        {
            type: "paragraph",
            text: "“In order to be a good coach, you have to be a good listener and you have to be empathetic. Tough love is a style, but there are some people who don’t respond well to tough love. I’ve found that really listening between the lines is critical to getting to know your clients, because a challenge they’re facing may be different than it appears.",
        },
        {
            type: "paragraph",
            text: "To be honest, I tend to dig in pretty deep. I ask about everything: family dynamics, birth order, where they grew up, their challenges in life, who inspired them to be who they are, etc. This helps me really get to know my clients so I can guide them in a way that works best for them.”",
        },
        {
            type: "header2",
            text: "What can we do to move forward in a way that supports ourselves as well as each other?",
        },
        {
            type: "paragraph",
            text: "“The more you give, the more you get in return. Supporting fellow coaches, business owners, community members, or family members as much as you can will breed abundance. There is always enough to go around.”",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Jacqueline Whitmore is an etiquette coach, consultant, and trainer of other etiquette coaches. Her business is centered around helping people become more mindful about how their behavior affects other people, building confidence, poise, and professionalism along the way.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "The most successful coaches are the ones who find ways to spend more time ON their business and less time IN their business. Zoee is all about simplifying their processes so they can focus on their clients and growing their business. Get started for free today!",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This interview was conducted and written by Valerie Lane.",
                },
            ],
        },
    ],
};
