import { FC } from "react";
import { motion } from "framer-motion";
import { SvgClose } from "../icons";

type Props = {
    personalizeYourPageValues: any;
    hasPypImage: boolean;
    imageId: string | null;
    setShowPypPreview: React.Dispatch<React.SetStateAction<boolean>>;
};

const PersonalizeYourPagePreview: FC<Props> = ({
    personalizeYourPageValues,
    hasPypImage,
    imageId,
    setShowPypPreview,
}) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter z-[801] flex items-center justify-center"
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="relative flex flex-col items-center bg-white min-w-[330px] md:w-[660px] h-auto rounded-[16px] py-[40px] md:px-[32px] px-[16px]"
            >
                <button>
                    <SvgClose
                        onClick={() => setShowPypPreview(false)}
                        className="absolute top-[24px] right-[24px]"
                    />
                </button>
                <div className="max-w-[638px] w-full">
                    <div className="flex items-center gap-[24px]">
                        {hasPypImage && imageId && (
                            <div className="hidden md:block min-w-[140px] w-[140px] h-[140px] relative rounded-full">
                                <img
                                    src={`https://pyp-avatar-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${imageId}`}
                                    className="absolute top-0 left-0 w-full h-full object-cover rounded-full flex items-center justify-center"
                                    alt="avatar"
                                />
                            </div>
                        )}
                        <div>
                            <div className="flex items-center gap-[16px] mb-[9px] md:mb-[20px]">
                                {hasPypImage && imageId && (
                                    <div className="md:hidden min-w-[73px] w-[73px] h-[73px] relative rounded-full">
                                        <img
                                            src={`https://pyp-avatar-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${imageId}`}
                                            className="absolute top-0 left-0 w-full h-full object-cover rounded-full flex items-center justify-center"
                                            alt="avatar"
                                        />
                                    </div>
                                )}
                                <div className="flex flex-col gap-[4px]">
                                    <h4 className="text-[23px] font-bold">
                                        {personalizeYourPageValues.name || ""}
                                    </h4>
                                    <h5>{personalizeYourPageValues.title || ""}</h5>
                                </div>
                            </div>
                            <p className="italic text-grayCharcoal">
                                {personalizeYourPageValues.description || ""}
                            </p>
                        </div>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    );
};

export default PersonalizeYourPagePreview;
