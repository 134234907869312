import React from "react";
import { CalendarRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import ConnectedCalendar from "./ConnectedCalendar";
import EditCalendarSettings from "./EditCalendarSettings";
import useLoading from "../../../hooks/useLoading";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import {
  SvgApple,
  SvgChevronDown,
  SvgGoogleCalendar,
  SvgOffice365Calendar,
  SvgOutlookCalendar,
} from "../../icons";
import { cn } from "../../../utils/cn";

interface Props {
  profile: any;
  profileCount: any;
  fetchUserCalendars: any;
}

const ConnectedCalendarProfile: React.FC<Props> = ({
  profile,
  profileCount,
  fetchUserCalendars,
}) => {
  const [profileExpanded, setProfileExpanded] = React.useState<boolean>(false);
  const [editCalendarSettings, setEditCalendarSettings] = React.useState<any>({
    show: false,
    calendar: {},
  });
  const { provider_service, calendar_name, cronofy_profile_id } = profile;
  const { user } = React.useContext(UserCtx);
  const { renderSuccess, renderError, updateNextStepsData } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();

  const isPrimary = profile?.calendars?.find(
    (calendar: any) => calendar.is_primary,
  );

  const typeCapitalized = () => {
    let service = provider_service;
    if (service === "outlook_com") {
      service = "outlook";
    }
    if (service === "gsuite") {
      service = "google";
    }
    const first = service.slice(0, 1).toUpperCase();
    const rest = service.slice(1);
    return `${first}${rest}`;
  };

  const getCalendarLogo = () => {
    if (provider_service === "gsuite") {
      return <SvgGoogleCalendar />;
    } else if (provider_service === "office365") {
      return <SvgOffice365Calendar />;
    } else if (provider_service === "outlook_com") {
      return <SvgOutlookCalendar />;
    } else if (provider_service === "apple") {
      return <SvgApple />;
    }
  };

  const disconnectCalendar = () => {
    if (user) {
      startLoading();
      CalendarRequests.deleteCalendar({
        user_id: parseInt(user.user_id),
        profile_count: profileCount,
        cronofy_profile_id: cronofy_profile_id,
      })
        .then(() => {
          renderSuccess("Deleted calendar successfully");
          fetchUserCalendars();
          stopLoading();
          updateNextStepsData(false);
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex?.response?.data?.message);
          stopLoading();
        });
    }
  };

  return (
    <>
      {editCalendarSettings?.show && (
        <EditCalendarSettings
          calendar={editCalendarSettings.calendar}
          setEditCalendarSettings={setEditCalendarSettings}
          profile={profile}
          fetchUserCalendars={fetchUserCalendars}
        />
      )}
      <div className="main-shadow flex flex-col rounded-[16px] px-[16px] md:px-[24px]">
        <div className="flex w-full flex-col items-center gap-[24px] py-[24px] md:flex-row md:gap-0">
          <div className="flex w-full flex-row items-center justify-between">
            <div className="flex w-full flex-col items-center gap-[12px] md:flex-row md:gap-0">
              <div className="flex flex-row items-center gap-[16px] md:gap-[24px]">
                {getCalendarLogo()}
                <div className="flex flex-col items-center gap-[2px] md:items-start md:gap-[4px]">
                  <h5 className="whitespace-nowrap text-[16px] font-bold">
                    {`${typeCapitalized()} Calendar`}
                  </h5>
                  <p className="text-graySlate whitespace-nowrap text-base md:text-[16px]">
                    {calendar_name}
                  </p>
                </div>
                {isPrimary && <Badge>Primary</Badge>}
              </div>
            </div>
          </div>

          <div className="flex w-full flex-col gap-5 md:flex-row-reverse">
            <Button
              onClick={disconnectCalendar}
              variant="secondary"
              disabled={profileCount <= 1}
            >
              Disconnect
            </Button>
            <Button
              variant="ghost"
              onClick={() => setProfileExpanded(!profileExpanded)}
            >
              <span className="whitespace-nowrap">View Calendars</span>
              <SvgChevronDown
                className={cn("transition-transform", {
                  "-scale-y-100": profileExpanded,
                })}
              />
            </Button>
          </div>
        </div>
        {profileExpanded && (
          <>
            <div className="bg-grayCloud h-[1px] w-full"></div>
            <div className="flex flex-col py-[24px]">
              <h3 className="mb-[24px] text-[18px] font-bold sm:text-[28px] md:text-[22px]">
                Calendars
              </h3>
              <div className="flex flex-col gap-[16px]">
                {profile?.calendars?.map((calendar: any, index: number) => (
                  <ConnectedCalendar
                    setEditCalendarSettings={setEditCalendarSettings}
                    calendar={calendar}
                    key={index}
                    fetchUserCalendars={fetchUserCalendars}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConnectedCalendarProfile;
