import * as React from "react";
import { useParams } from "react-router-dom";
import LandingCallToAction from "../../../components/marketing/landing/LandingCallToAction";
import LandingComparison from "../../../components/marketing/landing/LandingComparison";
import LandingScheduler from "../../../components/marketing/landing/LandingScheduler";
import LandingSignupForm from "../../../components/marketing/landing/LandingSignupForm";
import LandingSplashText from "../../../components/marketing/landing/LandingSplashText";
import LandingTalkToAHuman from "../../../components/marketing/landing/LandingTalkToAHuman";
import LandingWhyZoee from "../../../components/marketing/landing/LandingWhyZoee";
import LandingMainVideo from "../../../components/marketing/landing/LandingMainVideo";
import PersonalizeYourPageSection from "../../../components/marketing/landing/PersonalizeYourPageSection";
import { ReferralRequests } from "../../../api/app.service";
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable";

export default function MoneyflowPage() {
  const { promocode } = useParams();
  const [referrerPromoCode, setReferrerPromoCode] = React.useState<
    string | null
  >(null);
  const [personalizeYourPageValues, setPersonalizeYourPageValues] = React
    .useState<{
      is_published: boolean;
      name: string;
      title: string;
      description: string;
      first_name: string;
      last_name: string;
      promo_code: string;
    }>({
      is_published: false,
      name: "",
      title: "",
      description: "",
      first_name: "",
      last_name: "",
      promo_code: "",
    });
  const [hasPypImage, setHasPypImage] = React.useState(false);
  const [imageId, setImageId] = React.useState<string | null>(null);

  const getPersonalizeYourPageDetails = (promoCode: string) => {
    ReferralRequests.getPersonalizeYourPageDetails({
      promo_code: promoCode,
    })
      .then((data) => {
        setPersonalizeYourPageValues({
          is_published: data.pyp_data.is_published || false,
          name: data.pyp_data.pyp_details.name || "",
          title: data.pyp_data.pyp_details.title || "",
          description: data.pyp_data.pyp_details.description || "",
          first_name: data.pyp_data.first_name,
          last_name: data.pyp_data.last_name,
          promo_code: data.pyp_data.promo_code,
        });
        setHasPypImage(data.pyp_data.has_pyp_image);
        setImageId(data.pyp_data.img_id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setLocalStorageReferrerPromoCode = () => {
    if (promocode) {
      setReferrerPromoCode(promocode);
      if (isLocalStorageAvailable()) {
        localStorage.setItem("referrerPromoCode", promocode);
      }

      getPersonalizeYourPageDetails(promocode);
    }
  };

  React.useEffect(() => {
    setLocalStorageReferrerPromoCode();
  }, [promocode]);

  return (
    <div>
      <section
        className={`relative md:min-h-[840px] min-h-[1026px] flex flex-col md:gap-[40px] gap-[30px] md:flex-row justify-between pt-[134px] md:pt-[197px] pb-[131px] md:pb-[220px] px-[10%]`}
        style={{
          background: "linear-gradient(180deg, #DEC8FB 0%, #FCB900 100%)",
        }}
      >
        <div className="flex flex-col gap-[42px] md:gap-[73px]">
          <LandingSplashText
            pypIsEnabled={personalizeYourPageValues.is_published}
            variation="moneyflow"
          />
          {personalizeYourPageValues.is_published &&
            (
              <div className="mb-[51px]">
                <PersonalizeYourPageSection
                  personalizeYourPageValues={personalizeYourPageValues}
                  hasPypImage={hasPypImage}
                  imageId={imageId}
                />
              </div>
            )}
        </div>
        <LandingSignupForm
          referrerPromoCodeQueryParam={referrerPromoCode}
          personalizeYourPageValues={personalizeYourPageValues}
          variation="moneyflow"
        />
        <div className="absolute w-full bottom-[-141px] md:bottom-[-328px] left-0 right-0 mx-auto">
          <LandingMainVideo />
        </div>
      </section>
      <div className="bg-white flex flex-col items-center">
        <section className="flex flex-col md:flex-row justify-between md:gap-[64px] gap-[46px] px-[10%] pt-[226px] md:pt-[432px] pb-[85px] md:pb-[140px]">
          <LandingTalkToAHuman />
          <LandingScheduler />
        </section>
        <section className="px-[10%] pb-[71px] md:pb-[209px]">
          <LandingComparison variation="moneyflow" />
        </section>
        <section className="mx-auto px-[10%] pb-[128px] md:pb-[162px]">
          <LandingWhyZoee variation="moneyflow" />
        </section>
        <section className="pb-[108px] md:pb-[118px]">
          <LandingCallToAction variation="moneyflow" />
        </section>
      </div>
    </div>
  );
}
