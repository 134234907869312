import * as React from "react";
import { cn } from "../../../utils/cn";

interface Props {
  type: string;
  icon: React.ReactNode;
  title: string;
  description: string;
  setServiceType: (servieType: string) => void;
  serviceType: string;
}

const ServiceTypeButton: React.FC<Props> = ({
  type,
  icon,
  title,
  description,
  setServiceType,
  serviceType,
}) => {
  return (
    <>
      <button
        className={cn(
          "rounded-lg border flex flex-col gap-2 focus-visible:ring p-4 transition-colors",
          {
            "border-blurple bg-white": serviceType === type,
            "bg-white hover:bg-muted": serviceType !== type,
          },
        )}
        onClick={() => setServiceType(type)}
      >
        <div className="flex items-center gap-4">
          <div
            className={cn(
              "flex items-center justify-center p-2 rounded-lg",
              {
                "bg-white": serviceType !== type,
                "bg-muted": serviceType === type,
              },
            )}
          >
            {icon}
          </div>
          <h5 className="font-bold">{title}</h5>
        </div>
        <p className="text-left text-muted-foreground text-sm">{description}</p>
      </button>
    </>
  );
};

export default ServiceTypeButton;
