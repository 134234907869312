import type { SVGProps } from "react";
export const SvgNoContacts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 54 46"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.148 15.324V2.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 37.223 0H2.925A2.97 2.97 0 0 0 .842.894 2.96 2.96 0 0 0 0 2.995v40.01c-.009.784.294 1.54.842 2.1A2.97 2.97 0 0 0 2.925 46h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L53.879 18.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.559 32.456c-3.673 0-6.81-.555-6.81-2.779s3.117-4.228 6.81-4.228c3.671 0 6.807 1.985 6.807 4.208s-3.116 2.8-6.808 2.8M17.558 22.277a4.364 4.364 0 1 0-4.364-4.364 4.35 4.35 0 0 0 4.333 4.364z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M24.801 21.079a3.289 3.289 0 0 0-.187-6.397M25.46 24.653c1.989 0 3.687 1.348 3.687 2.551 0 .71-.585 1.437-1.475 1.646"
    />
  </svg>
);
