import type { SVGProps } from "react";
export const SvgEditorHeader2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3 4h2v6h4V4h2v14H9v-6H5v6H3zm18 14h-6a2 2 0 0 1-2-2c0-.53.2-1 .54-1.36l4.87-5.23c.37-.36.59-.86.59-1.41a2 2 0 1 0-4 0h-2a4 4 0 1 1 8 0c0 1.1-.45 2.1-1.17 2.83L15 16h6z"
    />
  </svg>
);
