import React from "react"
import icfLogo from "../../../assets/images/landing/icf-logo.png"

const IcfLogo = () => (
  <img
    src={icfLogo}
    alt="ICF Business Solutions Provider Logo"
    width="135"
    height="125"
    style={{ objectFit: "contain" }}
  />
)

export default IcfLogo
