import React, { FC } from "react"
import ContactsList from "../../components/contacts/ContactsList"
import ContactFilters from "../../components/contacts/ContactFilters"
import ContactInvite from "../../components/contacts/ContactInvite"
import ContactAdd from "../../components/contacts/ContactAdd"
import ConfirmContactInvite from "../../components/contacts/ConfirmContactInvite"
import ContactRequestFull from "../../components/contacts/ContactRequestFull"
import Loader from "../../components/ui/loader"
import MobileContactsFilters from "../../components/contacts/MobileContactsFilters"
import useLoading from "../../hooks/useLoading"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Badge } from "../../components/ui/badge"
import { Button } from "../../components/ui/button"
import { CoachCard } from "../../models/public.interface"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests } from "../../api/app.service"
import { NotificationRequests } from "../../api/app.service"
import { NotificationCtx } from "../../context/notificationContext"
import { SvgFilter, SvgSearch, SvgUserPlus } from "../../components/icons/"
import { UserCtx } from "../../context/userContext"

export const filterContacts = (
  contacts: any[],
  type:
    | "connected"
    | "leads"
    | "prospect"
    | "requested"
    | "invited"
    | "coachdirectory"
    | "all"
) => {
  return contacts.filter((contact) => {
    switch (type) {
      case "all":
        return true
      case "connected":
        return (
          contact.contact_type === "connected" ||
          contact.contact_type === "prospect"
        )
      case "leads":
        return contact.status === "zoee lead"
      case "prospect":
        return contact.contact_type === "prospect"
      case "requested":
        return contact.contact_type === "requested"
      case "invited":
        return contact.contact_type === "invited"
      case "coachdirectory":
        return contact.contact_type === "coachdirectory"
      default:
        return true
    }
  })
}

const CoachContacts: React.FC<{
  setShowContactProfile: (show: boolean) => void
  coach: CoachCard
}> = ({ setShowContactProfile, coach }) => {
  const {
    coach_public_profile_id,
    coach_profile_id,
    user_id,
    endpoint_slug,
    last_name,
    first_name,
    description,
    tags,
    avatar_url,
    max_end_date,
    updated_at,
    subscription_promo_codes,
    email,
  } = coach
  const [contacts, setContacts] = React.useState<any>([])
  const [, setSelectedContact] = React.useState<any>(null)
  const [showAllContacts, setShowAllContacts] = React.useState<boolean>(true)
  const [showZoeeLeads, setShowZoeeLeads] = React.useState<boolean>(false)
  const [showProspective, setShowProspective] = React.useState<boolean>(false)
  const [showReceivedInvites, setShowReceivedInvites] =
    React.useState<boolean>(false)
  const [showInvited, setShowInvited] = React.useState<boolean>(false)
  const [showCoachDirectory, setShowCoachDirectory] =
    React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string>("")
  const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false)
  const [showProspectModal, setShowProspectModal] =
    React.useState<boolean>(false)
  const [showConfirmInviteModal, setShowConfirmInviteModal] =
    React.useState<boolean>(false)
  const [contactRequestFull, setContactRequestFull] = React.useState<any>({
    show: false,
    contact: null,
    variant: null,
  })
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [invites, setInvites] = React.useState<any>([{ email: "" }])
  const [prospect, setProspect] = React.useState<any>({
    firstName: "",
    lastName: "",
    email: "",
  })
  const [selectedSort, setSelectedSort] = React.useState<string>("none")
  const [selectedFilter, setSelectedFilter] = React.useState<string>("active")
  const [showContactsMobileListView, setShowContactsMobileListView] =
    React.useState<boolean>(true)
  const [showMobileFilters, setShowMobileFilters] =
    React.useState<boolean>(false)
  const { contactsNotifications, setContactsNotifications } =
    React.useContext(NotificationCtx)
  const [loadingContacts, setLoadingContacts] = React.useState<boolean>(false)
  const [, setSortedContacts] = React.useState([])

  const showComponent = (callback: any, sortFn: any = null) => {
    if (sortFn) {
      const sorted = sortFn(contacts)
      setSortedContacts(sorted)
    } else {
      setSortedContacts([])
    }
    setShowAllContacts(false)
    setShowZoeeLeads(false)
    setShowProspective(false)
    setShowReceivedInvites(false)
    setShowInvited(false)
    setShowCoachDirectory(false)
    callback(true)
  }

  const filterContacts = (
    contacts: any[],
    type:
      | "connected"
      | "leads"
      | "prospect"
      | "requested"
      | "invited"
      | "coachdirectory"
      | "all"
      | "coach"
      | "member"
  ) => {
    return contacts.filter((contact) => {
      switch (type) {
        case "all":
          return true
        case "connected":
          return (
            contact.contact_type === "connected" ||
            contact.contact_type === "prospect"
          )
        case "leads":
          return contact.status === "zoee lead"
        case "coach":
          return (
            contact.contact_type === "connected" &&
            contact.profile_type === "coach"
          )
        case "member":
          return (
            contact.contact_type === "connected" &&
            contact.profile_type === "member"
          )
        case "requested":
          return contact.contact_type === "requested"
        case "invited":
          return contact.contact_type === "invited"
        case "coachdirectory":
          return (
            contact.contact_type === "connected" &&
            contact.profile_type === "coach"
          )
        default:
          return true
      }
    })
  }

  const updateSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const getContacts = () => {
    // mode is coach -> activeProfileId will be of a coach,
    //
    if (user?.coachProfile || user?.memberProfile) {
      setLoadingContacts(true)
      ContactRequests.getContactList({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
      })
        .then((data) => {
          setContacts(data.contact_list)
          setLoadingContacts(false)
          stopLoading()
        })
        .catch((ex) => {
          console.error(ex)
          renderError(ex.response.data.message)
          setLoadingContacts(false)
        })
    }
  }

  const acceptContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.acceptContactRequest({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
        request_id: parseInt(contact.request_id),
        request_by_profile_id: parseInt(contact.request_by_profile_id),
        user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      })
        .then(() => {
          getContacts()
          renderSuccess("Request accepted!")
        })
        .catch((ex) => {
          console.error(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const denyContactRequest = (contact: any) => {
    if (user?.coachProfile) {
      startLoading()
      ContactRequests.denyContactRequest({
        request_id: parseInt(contact.request_id),
      })
        .then(() => {
          getContacts()
          renderSuccess("Request denied!")
        })
        .catch((ex) => {
          console.error(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const revokeContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.revokeContactRequest({
        request_id: parseInt(contact.request_id),
        request_email: contact.contact_name,
        request_by_profile_id: user.activeProfileId,
      })
        .then(() => {
          getContacts()
          renderSuccess("Invite revoked!")
        })
        .catch((ex) => {
          console.error(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const resendContactRequest = (contact: any) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.resendContactRequest({
        request_id: parseInt(contact.request_id),
        request_email: contact.contact_name,
        request_by_profile_id: user.activeProfileId,
      })
        .then(() => {
          getContacts()
          renderSuccess("Invite resent!")
        })
        .catch((ex) => {
          console.error(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const markContactNotificationsAsRead = () => {
    if (contactsNotifications.length === 0) return

    const unreadNotificationIds = contactsNotifications
      .filter((n: any) => n.viewed === 0)
      .map((n: any) => n.id)
    NotificationRequests.markRead({
      profile_id: user.activeProfileId,
      notification_ids: unreadNotificationIds,
    })
      .then(() => {
        const allReadContactsNotifications = contactsNotifications.map(
          (n: any) => {
            n.viewed = true
            return n
          }
        )
        setContactsNotifications(allReadContactsNotifications)
      })
      .catch((ex) => {
        console.error(ex)
        renderError(ex.response.data.message)
      })
  }

  React.useEffect(() => {
    getContacts()
  }, [])

  useScrollToTop()

  return (
    <>
      {showInviteModal && (
        <ContactInvite
          setShowInviteModal={setShowInviteModal}
          invites={invites}
          setInvites={setInvites}
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          invitedContacts={filterContacts(contacts, "invited")}
        />
      )}

      {showConfirmInviteModal && (
        <ConfirmContactInvite
          setShowConfirmInviteModal={setShowConfirmInviteModal}
          invites={invites}
          contacts={contacts}
          setInvites={setInvites}
          // getContacts={getContacts}
        />
      )}

      {showProspectModal && (
        <ContactAdd
          prospect={prospect}
          setProspect={setProspect}
          contacts={contacts}
          setShowProspectModal={setShowProspectModal}
          getContacts={getContacts}
        />
      )}

      {/* {showInviteForm && <ContactForm setShowInviteForm={setShowInviteForm} />} */}

      {contactRequestFull.show ? (
        <ContactRequestFull
          setContactRequestFull={setContactRequestFull}
          contactRequestFull={contactRequestFull}
          acceptContactRequest={acceptContactRequest}
          denyContactRequest={denyContactRequest}
          resendContactRequest={resendContactRequest}
          revokeContactRequest={revokeContactRequest}
        />
      ) : (
        <>
          {/* MOBILE */}
          <div className="bg-[#fcfcfc] md:hidden text-center">
            <div className="mx-auto mb-[12px] mt-[8px] flex w-full max-w-[600px] items-center justify-center gap-[16px] px-[20px]">
              <div className="search-input h-[44px]">
                <input type="text" onChange={updateSearchValue} />
                <SvgSearch className="absolute left-3 top-1/2 -translate-y-1/2" />
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setShowMobileFilters(() => !showMobileFilters)}
              >
                <SvgFilter />
              </Button>
            </div>
            <Button
              className="md:hidden"
              onClick={() => setShowInviteModal(!showInviteModal)}
            >
              <SvgUserPlus />
              Add Contact
            </Button>
          </div>

          {showMobileFilters && (
            <MobileContactsFilters
              selectedSort={selectedSort}
              setSelectedSort={setSelectedSort}
              setShowMobileFilters={setShowMobileFilters}
              showComponent={showComponent}
              setShowReceivedInvites={setShowReceivedInvites}
              setShowInvited={setShowInvited}
              setShowZoeeLeads={setShowZoeeLeads}
              setShowProspective={setShowProspective}
              setShowAllContacts={setShowAllContacts}
              showReceivedInvites={showReceivedInvites}
              showCoachDirectory={showCoachDirectory}
              setShowCoachDirectory={setShowCoachDirectory}
              showInvited={showInvited}
              showZoeeLeads={showZoeeLeads}
              showProspective={showProspective}
              showAllContacts={showAllContacts}
              contacts={contacts}
              setContacts={setContacts}
              filterReceivedInvites={filterContacts(contacts, "requested")}
              filterInvited={filterContacts(contacts, "invited")}
            />
          )}

          {/* DESKTOP */}
          <div
            className={`z-[200] mx-auto hidden w-full flex-col bg-[#fcfcfc] sm:w-[1080px] md:flex`}
          >
            <div className="mb-[24px] flex w-full flex-col items-center justify-between border-b-[1px] border-[#EBEBEB] pb-[24px] md:flex-row">
              <h1 className="text-[36px] font-bold">Contacts</h1>
              <div className="flex w-[335px] items-center justify-between sm:w-auto sm:gap-[12px]">
                <div className="search-input w-[189px] sm:w-[260px]">
                  <input
                    type="text"
                    placeholder="Search Contacts"
                    onChange={updateSearchValue}
                  />
                  <SvgSearch className="absolute left-3 top-1/2 -translate-y-1/2" />
                </div>
                <div className="ml-auto mr-2">
                  <Button onClick={() => setShowInviteModal(!showInviteModal)}>
                    <SvgUserPlus />
                    Add Contact
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col items-center justify-between md:flex-row">
              <div className="mb-[20px] flex w-[351px] items-center gap-2 overflow-x-auto pb-2 sm:w-auto md:mb-0">
                <Button
                  variant={showAllContacts ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowAllContacts)}
                >
                  My Clients
                </Button>
                <Button
                  variant={showProspective ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowProspective)}
                >
                  My Coaches
                </Button>

                <Button
                  variant={showZoeeLeads ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowZoeeLeads)}
                >
                  Leads
                </Button>

                <Button
                  variant={showReceivedInvites ? "utility" : "ghost"}
                  onClick={() => {
                    showComponent(setShowReceivedInvites)
                    markContactNotificationsAsRead()
                  }}
                >
                  Received Invites
                  {filterContacts(contacts, "requested").length > 0 && (
                    <Badge variant="alert">
                      {filterContacts(contacts, "requested").length}
                    </Badge>
                  )}
                </Button>

                <Button
                  variant={showInvited ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowInvited)}
                >
                  Sent Invites
                  {filterContacts(contacts, "invited").length > 0 && (
                    <Badge variant="alert">
                      {filterContacts(contacts, "invited").length}
                    </Badge>
                  )}
                </Button>
                <Button
                  variant={showCoachDirectory ? "utility" : "ghost"}
                  onClick={() => showComponent(setShowCoachDirectory)}
                >
                  Coach Directory
                </Button>
              </div>
              {!showInvited && (
                <ContactFilters
                  contacts={contacts}
                  setContacts={setContacts}
                  selectedSort={selectedSort}
                  selectedFilter={selectedFilter}
                  setSelectedSort={setSelectedSort}
                  setSelectedFilter={setSelectedFilter}
                />
              )}
            </div>
          </div>

          {/* SHARED ELEMENTS FOR DESKTOP AND MOBILE */}
          {loadingContacts ? (
            <div className="mt-[24px] flex flex-col items-center gap-[12px]">
              <Loader />
              <h3 className="mb-[4px] text-center text-[16px] font-bold">
                Looking for your contacts...
              </h3>
            </div>
          ) : (
            <div className="mx-auto pt-[12px] md:w-[1080px] md:pt-[32px]">
              {/* shows member contacts, showAllContacts is misleading yet to rename the flag to showClients */}
              {showAllContacts && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "member")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="all"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}

              {showZoeeLeads && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "leads")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="leads"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
               {/* shows coach contacts, showProspective is misleading yet to rename the flag to showCoaches*/}
              {showProspective && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "coach")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="prospect"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
              {showReceivedInvites && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "requested")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="requested"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
              {showInvited && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={filterContacts(contacts, "invited")}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="invited"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
              {showCoachDirectory && (
                <ContactsList
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  contacts={contacts}
                  searchValue={searchValue}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setShowInviteModal={setShowInviteModal}
                  type="coachdirectory"
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  showContactsMobileListView={showContactsMobileListView}
                  first_name={first_name}
                  last_name={last_name}
                  coach_public_profile_id={coach_public_profile_id}
                  coach_profile_id={coach_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  avatar_url={avatar_url}
                  max_end_date={max_end_date}
                  subscription_promo_codes={subscription_promo_codes}
                  updated_at={updated_at}
                  email={email}
                />
              )}
            </div>
          )}
          <div className="md:h-[300px] md:w-full"></div>
        </>
      )}
    </>
  )
}

export default CoachContacts
