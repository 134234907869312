import type { SVGProps } from "react";
export const SvgLocation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.717 6.828a3.6 3.6 0 0 0-3.595 3.594 3.6 3.6 0 0 0 3.595 3.595 3.6 3.6 0 0 0 3.594-3.595 3.6 3.6 0 0 0-3.594-3.594m0 5.794c-1.213 0-2.2-.987-2.2-2.2 0-1.212.987-2.199 2.2-2.199 1.212 0 2.2.987 2.2 2.2 0 1.212-.988 2.199-2.2 2.199m8.472-4.031C19.159 4.04 15.194 2 11.72 2h-.005C8.245 2 4.28 4.034 3.247 8.58c-1.141 5.013 1.89 9.219 4.633 11.864a5.513 5.513 0 0 0 7.67.003c2.741-2.642 5.773-6.845 4.639-11.856m-5.607 10.852a4.117 4.117 0 0 1-5.735-.004c-2.484-2.396-5.237-6.171-4.24-10.55.923-4.056 4.405-5.495 7.108-5.495h.005c2.705.002 6.19 1.444 7.108 5.504.99 4.378-1.763 8.15-4.246 10.545"
    />
  </svg>
);
