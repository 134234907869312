import type { SVGProps } from "react";
export const SvgMarketplaceSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="currentColor"
      d="m14.95 13.476.823-8.383h4.494l.863 8.793c-.013.776-.265 1.496-.76 2.053-1.163 1.308-3.497 1.308-4.66 0-.582-.655-.852-1.53-.76-2.463m-9.127-.46.372-3.8c.436-3.198 1.43-4.123 4.435-4.123h3.039l-.848 8.635c-.172 1.749-1.774 3.225-3.499 3.225-1.042 0-1.978-.408-2.637-1.15s-.965-1.731-.862-2.788m14.61 17.89h-4.795v-2.441c0-1.758.672-2.442 2.397-2.442 1.726 0 2.398.684 2.398 2.442zm8.886-7.731c0 5.44-1.812 7.528-6.831 7.713v-2.423c0-2.925-1.582-4.535-4.452-4.535s-4.452 1.61-4.452 4.535v2.423c-5.02-.185-6.831-2.274-6.831-7.713v-4.761c.777.4 1.643.632 2.57.632 1.757 0 3.394-.92 4.432-2.306.136.206.265.416.431.603.976 1.098 2.345 1.703 3.854 1.703s2.877-.605 3.853-1.703c.15-.17.265-.36.39-.545 1.04 1.355 2.66 2.248 4.395 2.248.955 0 1.845-.248 2.641-.673zm-.004-7.372c-.659.742-1.596 1.15-2.637 1.15-1.725 0-3.327-1.477-3.499-3.224l-.005-.059c-.004-.134.013-.265 0-.401l-.803-8.176h2.999c3.005 0 4 .925 4.43 4.082l.377 3.84c.104 1.056-.203 2.046-.862 2.788"
    />
  </svg>
);
