import React from "react";
import { Link, useLocation } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import { NotificationCtx } from "../../context/notificationContext";
import NotificationCenter from "../notifications/NotificationCenter";
import { NotificationRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import NavShell from "./NavShell";
import UserButton from "./UserButton";
import { NavLinkItem } from "./NavLinkItem";
import { Button } from "../ui/button";
import UserButtonMobile from "./UserButtonMobile";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { getUserColor } from "../../utils/getUserColor";
import { getTextColor } from "../../utils/getTextColor";
import useName from "../../hooks/useName";
import {
  SvgBilling,
  SvgCalendar,
  SvgChat,
  SvgClose,
  SvgHamburger,
  SvgHome,
  SvgListCheck,
  SvgNotifications,
  SvgSettingsSlider,
  SvgUser3,
  SvgVideoOutline,
  SvgZoeeIcon,
  SvgZoeeLogo,
} from "../icons/";
import { Badge } from "../ui/badge";

const CoachAuthedNav: React.FC = () => {
  const { user } = React.useContext(UserCtx);
  const { initial } = useName();
  const authenticatedUser = useAuthenticatedUser(user);
  const location = useLocation();
  const { renderError, previewImgUrl } =
    React.useContext(CommonFunctionCtx);
  const { notificationAlert } = React.useContext(NotificationCtx);
  const [isNewNotificationAlert] = React.useState<boolean>(
    notificationAlert.message !== "",
  );
  const {
    inboxNotifications,
    setInboxNotifications,
    contactsNotifications,
    setContactsNotifications,
  } = React.useContext(NotificationCtx);
  const [showNotificationCenter, setShowNotificationCenter] =
    React.useState<boolean>(false);
  const [notifications, setNotifications] = React.useState<any[]>([]);
  const [showMobileNav, setShowMobileNav] = React.useState<boolean>(false);
  const [showUserButton, setShowUserButton] = React.useState<boolean>(false);

  const unreadInboxNotificationNumber = React.useMemo(() => {
    return inboxNotifications.filter((n: any) => !n.viewed).length;
  }, [inboxNotifications]);

  const unreadContactsNotificationNumber = React.useMemo(() => {
    return contactsNotifications.filter((n: any) => !n.viewed).length;
  }, [contactsNotifications]);

  const getNotifications = () => {
    if (authenticatedUser) {
      NotificationRequests.getNotifications({
        profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          const filteredNotifications = data.notifications.filter((n) => {
            // filter out message notifications
            return n.notification_category !== "messages";
          });
          setNotifications(filteredNotifications);
          const unreadContactNotifications = data.notifications.filter((n) => {
            return n.slug === "received_connection_request" && n.viewed === 0;
          });
          setContactsNotifications(unreadContactNotifications);
          const unreadInboxNotifications = data.notifications.filter((n) => {
            return n.notification_category === "messages" && n.viewed === 0;
          });
          setInboxNotifications(unreadInboxNotifications);
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        });
    }
  };

  React.useEffect(() => {
    if (showNotificationCenter) {
      setShowNotificationCenter(false);
    }
  }, [location]);

  React.useEffect(() => {
    getNotifications();
  }, []);

  React.useEffect(() => {
    getNotifications();
  }, [notificationAlert.show]);

  React.useEffect(() => {
    if (isNewNotificationAlert) {
      getNotifications();
    }
  }, [isNewNotificationAlert]);

  return (
    <>
      <NavShell
        className="flex items-center justify-between"
        outerClassName="hidden lg:flex"
      >
        <Link to="/">
          <SvgZoeeIcon />
        </Link>

        <div className="hidden items-center lg:flex lg:gap-2">
          <NavLinkItem to="/coach/dashboard">
            <SvgHome />
            Home
          </NavLinkItem>
          <NavLinkItem
            to="/coach/inbox"
            notifications={unreadInboxNotificationNumber}
          >
            <SvgChat />
            Inbox
          </NavLinkItem>
          <NavLinkItem
            to="/coach/contacts"
            notifications={unreadContactsNotificationNumber}
          >
            <SvgUser3 />
            Contacts
          </NavLinkItem>
          <NavLinkItem to="/coach/scheduling">
            <SvgCalendar />
            Calendar
          </NavLinkItem>
          <NavLinkItem to="/coach/sessions">
            <SvgVideoOutline />
            Sessions
          </NavLinkItem>
          <NavLinkItem to="/coach" end>
            <SvgListCheck />
            Manage
          </NavLinkItem>
        </div>

        <div className="relative flex items-center justify-end lg:gap-[24px]">
          <div className="relative">
            <SvgNotifications
              className="cursor-pointer"
              onClick={() => {
                setShowNotificationCenter((prev) => !prev);
              }}
            />
            {notifications.filter((n) => !n.viewed).length > 0 && (
              <Badge dot variant="alert" className="absolute -right-1 -top-1" />
            )}
            <style>
              {`
                  @media (min-width: 1024px) and (max-width: 1043px) {
                    .gap-20 {
                      gap: 5px;
                    }
                  }
                `}
            </style>
          </div>

          {showNotificationCenter && (
            <NotificationCenter
              notifications={notifications}
              setNotifications={setNotifications}
              showNotificationCenter={showNotificationCenter}
              setShowNotificationCenter={setShowNotificationCenter}
            />
          )}

          <UserButton />
        </div>
      </NavShell>

      {/* Mobile */}
      <nav className="relative sticky top-2.5 z-30 mx-2.5 mb-10 flex lg:hidden">
        <div
          className="relative mx-auto flex h-20 w-full max-w-screen-xl items-center justify-between rounded-lg bg-white px-2.5 py-5 sm:px-4"
          style={{
            boxShadow:
              "0 10px 20px -5px rgba(111, 89, 246, 0.15), 0 -5px 10px -5px rgba(111, 89, 246, 0.15), 0 6px 10px -5px rgba(111, 89, 246, 0.10)",
          }}
        >
          <Button
            variant="ghost"
            className="ml-4 lg:hidden"
            size="icon"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            {showMobileNav ? <SvgClose /> : <SvgHamburger />}
          </Button>

          {showMobileNav && (
            <div className="zoee-backdrop-filter fixed left-0 top-0 z-[601] h-screen w-screen"></div>
          )}

          <div
            className={`fixed left-0 top-0 flex h-screen flex-col overflow-y-auto rounded-lg bg-white bg-white p-8 transition-all duration-500 ease-in-out ${
              showMobileNav ? "left-0 w-[279px]" : "left-[-279px] w-0"
            } z-[1000]`}
            style={{
              boxShadow:
                "5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.15), 5px 0 15px -2px rgba(111, 89, 246, 0.1)",
            }}
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <Button
              className="bg-grayFlash absolute right-[20px] top-[25px] rounded-full"
              size="icon"
              variant="ghost"
              onClick={() => setShowMobileNav(!showMobileNav)}
            >
              <SvgClose />
            </Button>
            <Link
              to="/"
              onClick={() => setShowMobileNav(false)}
              className="mb-[48px] mt-[10px]"
            >
              <SvgZoeeLogo className="h-auto w-[155px]" />
            </Link>
            <div className="mb-[56px] flex flex-col gap-[20px] text-center text-[18px]">
              <NavLinkItem to="/coach/dashboard">
                <SvgHome />
                Home
              </NavLinkItem>
              <NavLinkItem
                to="/coach/inbox"
                notifications={unreadInboxNotificationNumber}
              >
                <SvgChat />
                Inbox
              </NavLinkItem>
              <NavLinkItem
                to="/coach/contacts"
                notifications={unreadContactsNotificationNumber}
              >
                <SvgUser3 />
                Contacts
              </NavLinkItem>
              <NavLinkItem to="/coach/services">
                <SvgBilling />
                Services
              </NavLinkItem>
              <NavLinkItem to="/coach/scheduling">
                <SvgCalendar />
                Calendar
              </NavLinkItem>
              <NavLinkItem to="/coach/sessions">
                <SvgVideoOutline />
                Sessions
              </NavLinkItem>
              <NavLinkItem to="/coach" end>
                <SvgSettingsSlider />
                Manage
              </NavLinkItem>
            </div>
          </div>

          <div className="relative mr-2 flex items-center justify-end gap-[20px] lg:gap-[24px]">
            <div className="relative">
              <SvgNotifications
                className="cursor-pointer"
                onClick={() => {
                  setShowNotificationCenter((prev) => !prev);
                }}
              />
              {notifications.filter((n) => !n.viewed).length > 0 && (
                <Badge
                  dot
                  variant="alert"
                  className="absolute -right-1 -top-1"
                />
              )}
            </div>

            {showNotificationCenter && (
              <NotificationCenter
                notifications={notifications}
                setNotifications={setNotifications}
                showNotificationCenter={showNotificationCenter}
                setShowNotificationCenter={setShowNotificationCenter}
              />
            )}
            {showUserButton ? (
              <SvgClose />
            ) : (
              <Button
                variant="ghost"
                className="h-10 w-10 rounded-full"
                onClick={() => setShowUserButton(() => !showUserButton)}
              >
                <Avatar size="xs">
                  {Boolean(user.hasAvatar) && (
                    <AvatarImage
                      alt="avatar"
                      src={previewImgUrl || user.avatar_url}
                    />
                  )}
                  <AvatarFallback className={getUserColor(user.userColor)}>
                    <div
                      style={{
                        color: getTextColor(user.userColor),
                      }}
                    >
                      {initial}
                    </div>
                  </AvatarFallback>
                </Avatar>
              </Button>
            )}
            <UserButtonMobile
              setShowUserButton={setShowUserButton}
              showUserButton={showUserButton}
            />
          </div>
        </div>
      </nav>
      <div className="hidden h-24 md:block"></div>
    </>
  );
};

export default CoachAuthedNav;
