"use client";

import * as React from "react";
import { features } from "./feature-data";
import { FeatureItem } from "./feature-item";
import { CarouselControls } from "../carousel-controls";

export function Features() {
  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <section className="py-24 bg-gradient-to-t from-[#BACDFF] to-white">
      <div className="container">
        <h3 className="sm:mb-[52px] mb-[8px] px-[32px] text-[25px] lg:text-[42px] text-black font-bold text-center">
          The Tools You Need Most <br />
          <span className="text-blurple">All in One Place</span>
        </h3>

        <div className="grid lg:grid-cols-2">
          <div className="hidden lg:flex flex-col mb-[50px]">
            {features.map((feature, index) => (
              <FeatureItem
                key={feature.id}
                feature={feature}
                index={index}
                activeFeatureIndex={activeIndex}
                setActiveFeatureIndex={setActiveIndex}
              />
            ))}
          </div>

          <div>
            {features[activeIndex]?.content}
          </div>

          <div className="lg:hidden">
            <CarouselControls
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              dataLength={features.length}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
