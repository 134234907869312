import React from "react"

type Props = {
  subscriptionName: string
}

const SubscriptionTag: React.FC<Props> = ({ subscriptionName }) => {
  return (
    <div className="w-fit h-fit rounded-[100px] px-[20px] py-[10px] growth-subscription-gradient">
      <h3 className="text-[18px] md:text-[22px] font-bold text-white">
        {subscriptionName}
      </h3>
    </div>
  )
}

export default SubscriptionTag
