import React from "react";
import User from "./User";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ContactRequests } from "../../api/app.service";
import CloseModal from "../CloseModal";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgSearch } from "../icons";

interface Props {
    setShowModal: any;
    callback: any;
    modalTitle: string;
    buttonText: string;
}

const UserSelect: React.FC<Props> = ({ setShowModal, callback, modalTitle, buttonText }) => {
    const [selectedContacts, setSelectedContacts] = React.useState<any>([]);
    const [contacts, setContacts] = React.useState<any>([]);
    const [foundContacts, setFoundContacts] = React.useState<any>(contacts);
    const { user } = React.useContext(UserCtx);
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();

    const completeSelection = () => {
        setShowModal(false);
        callback(selectedContacts);
    };

    const updateSelectedContacts = (contact: any) => {
        const profileIds = selectedContacts.map((c: any) => c.profile_id)
        if (profileIds.includes(contact.profile_id)) {
            const filtered = selectedContacts.filter((c: any) => {
                return c.profile_id !== contact.profile_id
            })
            setSelectedContacts(filtered)
        } else {
            setSelectedContacts([...selectedContacts, contact]);
        }
    };

    const filterList = (e: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = e.target.value;
        if (keyword !== "") {
            const results = contacts?.filter((contact:any) => {
                return contact?.contact_name?.toLowerCase()?.includes(keyword?.toLowerCase());
            });
            setFoundContacts(results);
        } else {
            setFoundContacts(contacts);
        }
    };

    const filterContacts = (contactList: any) => {
        const filtered = contactList?.filter((contact: any) => contact?.contact_type === "connected");
        setContacts(filtered);
        setFoundContacts(filtered);
    };

    const getContactsList = () => {
        if (user?.coachProfile || user?.memberProfile) {
            startLoading();
            const profileId =
                user.activeProfile === "coach"
                    ? user.coachProfile.profile_id
                    : user.memberProfile.profile_id;
            ContactRequests.getContactList({
                profile_id: parseInt(profileId),
                profile_type: user.activeProfile === "coach" ? "coach" : "member",
            })
                .then((data) => {
                    filterContacts(data.contact_list);
                    setContacts(data.contact_list);
                })
                .catch((ex) => {
                    console.log(ex);
                    renderError(ex.response.data.message);
                })
                .finally(() => {
                    stopLoading();
                });
        }
    };

    React.useEffect(() => {
        getContactsList();
    }, [user]);

    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[801]">
            <div
                className="absolute w-[351px] sm:w-[520px] main-shadow rounded-[16px] py-[24px] px-[8px] 
                bg-white left-[50%] -translate-x-[50%] top-[64px]
                flex flex-col items-center max-h-[50vh] md:max-h-[80vh]"
            >
                <CloseModal callback={() => setShowModal(false)} styling="absolute top-[24px] right-[24px]" />
                <h4 className="font-bold text-[22px] mb-[20px]">{modalTitle}</h4>
                <div className="search-input mb-[8px] w-[319px] sm:w-[392px]">
                    <input type="text" placeholder={`Search for contacts`} onChange={filterList} />
                    <SvgSearch
                        className="left-[12px] absolute top-[50%] -translate-y-[50%]"
                    />
                </div>
                <div className="w-full sm:w-[392px] sm:mx-auto min-h-[100px] max-h-[308px] overflow-y-auto mb-[16px] cursor-pointer">
                    {foundContacts.map((contact: any) => (
                        <div
                            key={contact.profile_id}
                            onClick={() => updateSelectedContacts(contact)}
                        >
                            <User contact={contact} selectedContacts={selectedContacts} />
                        </div>
                    ))}
                </div>
                <Button
                    className="min-h-[40px] text-md"
                    disabled={selectedContacts.length === 0}
                    onClick={() => {
                        completeSelection();
                    }}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default UserSelect;
