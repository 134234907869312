import type { SVGProps } from "react";
export const SvgQuickBooks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 39 39"
    {...props}
  >
    <path
      fill="#2CA01C"
      d="M19.5 38.584c10.577 0 19.15-8.608 19.15-19.226S30.078.133 19.5.133.35 8.741.35 19.36 8.925 38.584 19.5 38.584"
    />
    <path
      fill="#fff"
      d="M13.114 11.882c-4.115 0-7.447 3.35-7.447 7.476 0 4.131 3.332 7.477 7.447 7.477h1.064v-2.777h-1.064c-2.583 0-4.68-2.106-4.68-4.7 0-2.593 2.097-4.7 4.68-4.7h2.558v14.526a2.77 2.77 0 0 0 2.766 2.778v-20.08zm12.77 14.953c4.115 0 7.447-3.35 7.447-7.476 0-4.132-3.332-7.477-7.447-7.477H24.82v2.777h1.064c2.583 0 4.681 2.106 4.681 4.7 0 2.593-2.098 4.7-4.68 4.7h-2.558V9.532a2.77 2.77 0 0 0-2.767-2.777v20.079z"
    />
  </svg>
);
