import React from "react"
import BentoBlogFlow from "./BentoBlogFlow"
import BentoImageFlow from "./BentoImageFlow"
import BentoTestimonialFlow from "./BentoTestimonialFlow"
import BentoQuoteFlow from "./BentoQuoteFlow"
import BentoVideoFlow from "./BentoVideoFlow"
import CloseModal from "../CloseModal"
import { BlogPost } from "../../models/blog.interface"
import { Button } from "../ui/button"
import {
  SvgArticle,
  SvgCheckCircle,
  SvgHelp,
  SvgQuoteRight2,
  SvgVideoIcon,
} from "../icons"

const buttonsInfo = [
  {
    btnFlow: "blog",
    IconComp: SvgArticle,
  },
  {
    btnFlow: "quote",
    IconComp: SvgQuoteRight2,
  },
  {
    btnFlow: "testimonials",
    IconComp: SvgHelp,
  },
  {
    btnFlow: "video",
    IconComp: SvgVideoIcon,
  },
]

interface Props {
  setEditBento: any
  bentoBoxSelection: number
  editBento: boolean
  updateBento: any
  post: BlogPost | null
  allPosts: BlogPost[]
  coachData: any
}

export default function BentoSelectModal({
  setEditBento,
  bentoBoxSelection,
  updateBento,
  post,
  allPosts,
  coachData,
}: Props) {
  const [flow, setFlow] = React.useState<any | null>(() => {
    if (bentoBoxSelection === 3) {
      return coachData.bento_box_three?.type
    }
    if (bentoBoxSelection === 4) {
      return coachData.bento_box_four?.type
    }
    if (bentoBoxSelection === 5) {
      return coachData.bento_box_five?.type
    }
  })

  console.log(flow, bentoBoxSelection)

  const [step, setStep] = React.useState<number>(1)

  const handleClick = (option: string | null) => {
    switch (option) {
      case "video":
      case "blog":
      case "quote":
      case "testimonials":
      case "image":
        setFlow(option)
        setStep(2)
        break
      case "switch content":
        setFlow(null)
        setStep(1)
        break
      case "cancel":
        setFlow(null)
        setEditBento(false)
        break
      default:
        break
    }
  }

  const handleOptionClick = (option: string) => {
    setFlow(option)
  }

  return (
    <>
      <div
        className={` ${
          step === 1 ? "" : "hidden"
        } fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]`}
      >
        <div className="w-[600px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto ">
          <CloseModal
            callback={() => setEditBento(false)}
            styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          />
          <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
            Featured Content
          </h2>

          <p className="font-bold text-[20px] sm:mt-4">
            Select Content Type For Block
          </p>
          <div className="flex w-[62%] md:w-full flex-col md:flex-row flex-wrap gap-4 justify-center items-center mt-8 md:mt-12">
            {buttonsInfo.map(({ IconComp, btnFlow }) => (
              <Button
                key={btnFlow}
                className={`md:w-[150px] md:h-[150px] w-full flex flex-col text-md hover:bg-grayFlash relative ${
                  flow === btnFlow ? "bg-grayFlash" : "bg-white"
                }`}
                variant={"utility"}
                onClick={() => {
                  handleOptionClick(btnFlow)
                }}
              >
                {flow === btnFlow && (
                  <SvgCheckCircle
                    className="absolute top-2 right-2"
                    stroke="green"
                  />
                )}
                <IconComp height="40" width="40" />
                {`${btnFlow.charAt(0).toUpperCase()}${btnFlow.slice(1)}`}
              </Button>
            ))}
          </div>

          <div className="flex">
            <Button
              className="mt-8"
              size="lg"
              variant={"ghost"}
              onClick={() => {
                handleClick("cancel")
              }}
            >
              Cancel
            </Button>

            <Button
              className="mt-8 ml-2"
              size="lg"
              onClick={() => {
                handleClick(flow)
              }}
              disabled={!flow}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {/* BLOG OPTION UX */}
      {Boolean(flow === "blog" && step === 2) && (
        <BentoBlogFlow
          handleClick={handleClick}
          post={post}
          allPosts={allPosts}
          updateBento={updateBento}
          bentoSelection={bentoBoxSelection}
        />
      )}
      {/* IMAGE QUOTE UX */}
      {Boolean(flow === "quote" && step === 2) && (
        <BentoQuoteFlow
          handleClick={handleClick}
          updateBento={updateBento}
          bentoSelection={bentoBoxSelection}
        />
      )}
      {/* TESTIMONIALS OPTION UX */}
      {Boolean(flow === "testimonials" && step === 2) && (
        <BentoTestimonialFlow
          handleClick={handleClick}
          updateBento={updateBento}
          bentoSelection={bentoBoxSelection}
        />
      )}
      {/* VIDEO OPTION UX */}
      {Boolean(flow === "video" && step === 2) && (
        <BentoVideoFlow
          handleClick={handleClick}
          updateBento={updateBento}
          bentoSelection={bentoBoxSelection}
        />
      )}
      {/* IMAGE OPTION UX */}
      {Boolean(flow === "image" && step === 2) && (
        <BentoImageFlow
          handleClick={handleClick}
          updateBento={updateBento}
          bentoSelection={bentoBoxSelection}
        />
      )}
    </>
  )
}
