import * as React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import CoachDirectoryInvite from "./CoachDirectoryInvite"
import { AuthContext } from "../auth/context"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Button } from "../ui/button"
import { CoachCard } from "../../models/public.interface"
import { getUserColor } from "../../utils/getUserColor"
import { RegisterFormProvider } from "../auth/register-form/context"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface CoachDirectoryMobileListItemProps extends CoachCard {
  setInvites: (invites: any) => void
  setShowConfirmInviteModal: (show: boolean) => void
  setSelectedContact: (contact: CoachCard) => void
  setShowContactProfile: (show: boolean) => void
  setContactRequestFull: (full: boolean) => void
  acceptContactRequest: (request: any) => void
  shouldApplyMargin: any
  contacts: any
  coaches: CoachCard[]
  email: string
  contact: any
}

const CoachDirectoryMobileListItem = ({
  coach_profile_id,
  last_name,
  first_name,
  description,
  avatar_url,
  shouldApplyMargin,
  email,
  contacts,
  contact,
}: CoachDirectoryMobileListItemProps) => {
  const [coach, setCoach] = React.useState<any>(null)
  const [showConfirmInviteModal, setShowConfirmInviteModal] =
    React.useState(false)
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const [invites, setInvites] = React.useState([{ name: "", email: "" }])
  const [isHovered, setIsHovered] = React.useState(false)

  const { firstName, lastName, accountType, password, phone } =
    React.useContext(AuthContext)

  const handleInvite = (contact: any) => {
    setInvites([{ name: "", email: email }])
    setShowConfirmInviteModal(true)
  }

  const handleCoachInvite = (contact: any) => {
    handleInvite(email)
  }

  const isCoachDisabledByEmail = (coachEmail: string): boolean => {
    return contacts.some((contact: any) => contact.contact_name === coachEmail)
  }

  const isCoachDisabledByContactID = (coachId: number): boolean => {
    return contacts.some((contact: any) => contact.profile_id === coachId)
  }

  const isCoachDisabledByName = (coachId: number): boolean => {
    return contacts.some(
      (contact: any) => contact.request_by_profile_id === coachId
    )
  }

  const shouldHideCoachCard = (): boolean => {
    return user && user.activeProfileId
      ? coach_profile_id === user.activeProfileId
      : false
  }

  const cardClassName = shouldHideCoachCard() ? "hidden" : ""

  return (
    <div
      className={`coach-card ${cardClassName} flex items-center justify-between mb-4`}
    >
      <RegisterFormProvider
        defaultEmail={email}
        defaultAccountType={accountType}
        onSuccess={() => true}
        defaultPhone={phone}
        defaultFirstName={firstName}
        defaultLastName={lastName}
        defaultPassword={password}
      >
        <Dialog.Root
          open={showConfirmInviteModal}
          onOpenChange={setShowConfirmInviteModal}
        >
          {/* Avatar and Name Container */}
          <div className="flex items-center flex-grow">
            <Avatar className="h-12 w-12 rounded-full mr-2">
              <AvatarImage
                alt={`${first_name} ${last_name} profile picture`}
                src={`${avatar_url}?${new Date().getTime()}`}
              />
              <AvatarFallback
                className={`${getUserColor(
                  user.userColor
                )} text-[22px] font-bold`}
              >
                {" "}
                {first_name.charAt(0).toUpperCase()}
                {last_name.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <h3 className="font-bold">{`${first_name} ${last_name}`}</h3>
          </div>
          {/* Button Section */}
          <Button
            variant={
              isCoachDisabledByEmail(email) ||
              isCoachDisabledByContactID(coach_profile_id) ||
              isCoachDisabledByName(coach_profile_id)
                ? "outline"
                : isHovered
                ? "utility"
                : "default"
            }
            className="w-[120px] h-[40px] flex items-center justify-center"
            onClick={() => handleCoachInvite(coach)}
            disabled={
              isCoachDisabledByEmail(email) ||
              isCoachDisabledByContactID(coach_profile_id) ||
              isCoachDisabledByName(coach_profile_id)
            }
          >
            {isCoachDisabledByEmail(email) ||
            isCoachDisabledByName(coach_profile_id)
              ? "Pending"
              : isCoachDisabledByContactID(coach_profile_id)
              ? "Connected"
              : "Connect"}
          </Button>
          <Dialog.Portal>
            <Dialog.Overlay onClick={() => setShowConfirmInviteModal(false)} />
            <Dialog.Content className="fixed inset-0 flex items-center justify-center">
              <div className="bg-white rounded-lg p-6 shadow-lg z-50">
                <CoachDirectoryInvite
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  invites={invites}
                  setInvites={setInvites}
                  // getContacts={{}}
                  contacts={contacts}
                />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </RegisterFormProvider>
    </div>
  )
}

export default CoachDirectoryMobileListItem
