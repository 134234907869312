import type { SVGProps } from "react";
export const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.164 12.052a3.162 3.162 0 1 1-6.325 0 3.162 3.162 0 0 1 6.325 0"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.75 12.052c0 3.28 4.142 7.302 9.252 7.302s9.252-4.019 9.252-7.302-4.143-7.302-9.252-7.302S2.75 8.772 2.75 12.052"
      clipRule="evenodd"
    />
    <line
      x1="22.3355"
      y1="2.37077"
      x2="1.33546"
      y2="21.3708"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
