import type { SVGProps } from "react";
export const SvgCheckCircleSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.667 2.184c-.355-.27-.888-.27-1.243.179L16.85 5.59c-1.42-.986-3.108-1.524-4.972-1.524C6.995 4.067 3 8.102 3 13.033 3 17.965 6.995 22 11.878 22s8.878-4.035 8.878-8.967c0-1.793-.533-3.407-1.42-4.842l-.622.718-.621.717-5.15 6.546c-.177.179-.444.358-.71.358a.8.8 0 0 1-.621-.269l-3.818-4.124c-.266-.27-.266-.718-.177-.987 0-.09.088-.179.177-.269a.85.85 0 0 1 1.243 0l3.107 3.408 4.883-6.187.533-.718.533-.717 2.574-3.228c.444-.359.444-.897 0-1.255"
    />
  </svg>
);
