import { FC } from "react";
import { Post } from "../../../types/postTypes";
import { Link } from "react-router-dom";
import { Button } from "../../ui/button";

type Props = {
  post: Post;
};

const FeaturePost: FC<Props> = ({ post }) => {
  return (
    <section className="flex flex-col lg:flex-row md:gap-[62px] gap-[42px]">
      <img
        src={post.metadata.previewImg}
        alt={post.metadata.title}
        className="object-cover md:max-w-[668px] md:w-[668px] md:h-[406px] h-[248px] w-auto bg-gray rounded-[24px]"
      >
      </img>
      <div className="flex flex-col lg:max-w-[450px] max-w-[600px] items-start">
        <h4 className="text-[18px] text-grayCharcoal font-semibold mb-[24px]">
          FEATURED POST
        </h4>
        <h5 className="text-grayCharcoal font-semibold mb-[4px]">
          {post.metadata.date}
        </h5>
        <h2 className="text-[48px] text-grayCharcoal font-bold leading-[58px] mb-[12px] text-balance">
          {post.metadata.title}
        </h2>
        <p className="mb-[18px]">{post.metadata.description}</p>

        <Button variant="link" asChild>
          <Link
            to={`/blog/${post.metadata.urlSlug}`}
          >
            Read more
          </Link>
        </Button>
      </div>
    </section>
  );
};

export default FeaturePost;
