import type { SVGProps } from "react";
export const SvgPiechartThreeQuarters = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="currentColor" opacity={0.15} />
    <path fill="currentColor" d="M24 12A12 12 0 1 1 12 0v12z" />
  </svg>
);
