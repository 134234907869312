import React, { FC, useContext, useEffect, useState } from "react"
import { RegisterFormContext } from "../../components/auth/register-form/context"
import EmailsStep from "./components/EmailStep/EmailStep"
import VerifyEmailStep from "./components/VerifyEmailStep"
import VerifyPhoneStep from "./components/VerifyPhoneStep"
import CreateAccountStep from "./components/CreateAccuntStep/CreateAccountStep"
import IINCheckout from "../../components/IIN/IINCheckout"
import { useSearchParams } from "react-router-dom"
import { StepType } from "."
import { VerifyEmail } from "../../api/auth.service"

export interface InputErrors {
  emailError: string
  validPassword: boolean
  firstNameError: string
  lastNameError: string
  phoneError: boolean
  phoneVerifyCodeError: string
}

interface RegistrationPageProps {
  step: StepType
  setStep: (step: StepType) => void
  setLoading: (loading: boolean) => void
  loading: boolean
  isIIN: boolean
  appliedDiscount: {
    amount: number
    promo_code: string
  }
  createSetupIntent: any
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  selectedPlanFromChild: any
}

const RegistrationPage: FC<RegistrationPageProps> = ({
  step,
  setStep,
  loading,
  setLoading,
  isIIN,
  appliedDiscount,
  createSetupIntent,
  isTermsAgreed,
  setIsTermsAgreed,
  selectedPlanFromChild,
}) => {
  const [searchParams] = useSearchParams()
  const [inputErrors, setInputErrors] = useState<InputErrors>({
    emailError: "",
    validPassword: false,
    firstNameError: "",
    lastNameError: "",
    phoneError: false,
    phoneVerifyCodeError: "",
  })

  const {
    email,
    setEmail,
    setFirstName,
    firstName,
    setLastName,
    lastName,
    password,
    setPassword,
    phone,
    setPhone,
    createAccount,
    accountType,
  } = useContext(RegisterFormContext)

  // const responseVerifyToken = localStorage.getItem("email_verify_token") || ""
  const emailVerifyToken = searchParams.get("t") || ""
  const email_query = searchParams.get("verification") || ""

  const verifyEmailToken = async () => {
    if (!email_query || !emailVerifyToken) return

    setLoading(true)
    try {
      const response = await VerifyEmail.verifyEmailToken({
        email: email_query,
      })
      if (response.token === emailVerifyToken) {
        setStep(StepType.CreateAccount)
      } else {
        setInputErrors((prev) => ({
          ...prev,
          emailError:
            response.message || "Invalid or expired email verification link.",
        }))
      }
    } catch (error) {
      console.error("Error verifying email token:", error)
      setInputErrors((prev) => ({
        ...prev,
        emailError:
          "An error occurred while verifying the email. Please try again.",
      }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    verifyEmailToken()
  }, [email_query, emailVerifyToken])

  return (
    <>
      {step === StepType.Email && (
        <EmailsStep
          setStep={setStep}
          email={email}
          setEmail={setEmail}
          emailError={inputErrors.emailError}
          setInputErrors={setInputErrors}
          setLoading={setLoading}
          loading={loading}
          accountType={accountType}
          isIIN={isIIN}
        />
      )}
      {step === StepType.VerifyEmail && (
        <VerifyEmailStep
          isIIN={isIIN}
          email={email}
          accountType={accountType}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {step === StepType.CreateAccount && (
        <CreateAccountStep
          phone={phone}
          setPhone={setPhone}
          password={password}
          setPassword={setPassword}
          setFirstName={setFirstName}
          firstName={firstName}
          setLastName={setLastName}
          lastName={lastName}
          setEmail={setEmail}
          accountType={accountType}
          setInputErrors={setInputErrors}
          validPassword={inputErrors.validPassword}
          firstNameError={inputErrors.firstNameError}
          lastNameError={inputErrors.lastNameError}
          phoneError={inputErrors.phoneError}
          setLoading={setLoading}
          loading={loading}
          setStep={setStep}
          step={step}
          createAccount={createAccount}
        />
      )}
      {step === StepType.VerifyPhone && (
        <VerifyPhoneStep
          isIIN={isIIN}
          phoneVerifyCodeError={inputErrors.phoneVerifyCodeError}
          setInputErrors={setInputErrors}
          setStep={setStep}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {step === StepType.IINCheckout && (
        <IINCheckout
          appliedDiscount={appliedDiscount}
          createSetupIntent={createSetupIntent}
          isTermsAgreed={isTermsAgreed}
          setIsTermsAgreed={setIsTermsAgreed}
          selectedPlanFromChild={selectedPlanFromChild}
          isIIN={isIIN}
        />
      )}
    </>
  )
}

export default RegistrationPage
