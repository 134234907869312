import React, { FC, useEffect, useState } from "react";
import { UserCtx } from "../../context/userContext";
import { Link, useSearchParams } from "react-router-dom";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { Button } from "../ui/button";
import NavShell from "./NavShell";
import UserButton from "./UserButton";
import { SearchMarketplace } from "./SearchMarketplace";
import { SvgZoeeIcon, SvgZoeeLogo } from "../icons/";
import { useAuthModal } from "../auth/use-auth-modal";

const MarketplaceNav: FC = () => {
  const { user } = React.useContext(UserCtx);

  const [searchParams, setSearchParams] = useSearchParams();
  const register = searchParams.get("register");
  const coachregister = searchParams.get("coachregister");

  const authenticatedUser = useAuthenticatedUser(user);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const { showRegister, showLogin } = useAuthModal();

  useEffect(() => {
    if (register === "true") {
      showRegister();
    } else if (register === "coach") {
      setShowMobileNav(false);
      showRegister({ accountType: "coach" });
    } else if (register === "member") {
      setShowMobileNav(false);
      showRegister({ accountType: "member" });
    }
  }, [register, coachregister]);

  useEffect(() => {
    if (showMobileNav) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden";
      }
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileNav]);

  return (
    <NavShell className="flex items-center justify-between text-base font-semibold">
      <div onClick={() => setShowMobileNav(false)}>
        <Link to="/">
          <SvgZoeeLogo className="hidden md:block" />
          <SvgZoeeIcon className="md:hidden" />
        </Link>
      </div>
      {window.location.pathname === "/find-a-coach" && (
        <div className="hidden lg:block max-w-lg w-full">
          <SearchMarketplace />
        </div>
      )}
      <div className="flex">
        {authenticatedUser ? (
          <div className="flex items-center gap-4">
            <Button
              variant="link"
              asChild
            >
              <Link
                to={ user.isCoach ? `/coach` : `/member`}
                className="font-semibold text-base text-grayCharcoal"
              >
                Back to Account
              </Link>
            </Button>

            <UserButton />
          </div>
        ) : (
          <div className="flex items-center gap-4">
            <Button
              onClick={() => showLogin()}
              variant="outline"
            >
              Sign In
            </Button>
            <Button
              onClick={() => showRegister()}
              variant="default"
            >
              <span className="sm:hidden">Get Started</span>
              <span className="hidden sm:inline">Create Free Account</span>
            </Button>
          </div>
        )}
      </div>
    </NavShell>
  );
};

export default MarketplaceNav;
