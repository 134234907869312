import type { SVGProps } from "react";
export const SvgOutlookCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="#1B80E4"
      d="M59.96 15.179H33.865A1.87 1.87 0 0 0 32 17.049c0 1.031.835 1.869 1.864 1.869h20.665l-11.453 8.93-8.213-5.237-1.998 3.155 9.32 5.944a1.863 1.863 0 0 0 2.144-.101l13.768-10.74v24.216H33.864A1.867 1.867 0 0 0 32 46.954c0 1.032.835 1.87 1.864 1.87H59.96c1.03 0 1.864-.838 1.864-1.87V17.049c0-1.032-.835-1.87-1.864-1.87"
    />
    <path
      fill="#2299F8"
      d="M35.054 4.394a1.83 1.83 0 0 0-1.533-.396L3.696 9.605a1.864 1.864 0 0 0-1.521 1.836v41.12c0 .898.637 1.671 1.52 1.837l29.826 5.607q.168.033.343.034a1.868 1.868 0 0 0 1.864-1.87V5.834c0-.557-.245-1.085-.674-1.44"
    />
    <path
      fill="#FCFCFC"
      d="M18.951 45.085c-5.137 0-9.32-5.031-9.32-11.215 0-6.182 4.183-11.214 9.32-11.214s9.32 5.032 9.32 11.214c0 6.184-4.182 11.215-9.32 11.215m0-18.69c-3.083 0-5.592 3.352-5.592 7.475 0 4.124 2.51 7.477 5.592 7.477 3.084 0 5.593-3.353 5.593-7.477 0-4.123-2.51-7.476-5.593-7.476"
    />
  </svg>
);
