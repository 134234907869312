import React from "react";
import CloseModal from "../CloseModal";

interface Props {
  setShowDetails: any;
}

const QuickSubscriptionDetails: React.FC<Props> = ({ setShowDetails }) => {
  return (
   <div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setShowDetails}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Join Zoee as a Founding Coach - Limited Offer
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
            Are you a rockstar coach looking for a dynamic and innovative
            platform to impress clients and streamline your business? 
            Consider joining Zoee as a Founding Coach to get in on the ground
            level and receive these benefits:
          </p>
          <ul className="list-disc font-bold gap-[8px] flex flex-col text-grayCharcoal">
            <li>Unlimited access to Zoee for life</li>
            <li>Free upgrade to Your Business Page to enhance your professional brand and convert leads.</li>
            <li>Zoee marketing support - Instagram highlights, blog features, interviews, webinars, and special promotions.</li>
            <li>Zoee Community - Private Facebook Group of Founding Coaches</li>
            <li>Risk-Free (30-day money back guarantee)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default QuickSubscriptionDetails;
