import type { SVGProps } from "react";
export const SvgZoeeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    fill="none"
    viewBox="0 0 44 38"
    {...props}
  >
    <g clipPath="url(#zoee-icon_svg__a)">
      <path
        fill="#000"
        d="M32.718 12.66V2.473A2.46 2.46 0 0 0 32.032.74 2.4 2.4 0 0 0 30.335 0H2.384C1.744.01 1.133.275.686.739A2.46 2.46 0 0 0 0 2.474v33.052a2.46 2.46 0 0 0 .686 1.735c.447.464 1.057.73 1.698.739h27.979c.64-.01 1.25-.275 1.698-.739a2.46 2.46 0 0 0 .686-1.735v-4.79a.2.2 0 0 1 .031-.127l11.131-15.542c.188-.254.1-1.526-.213-1.526l-10.893-.019c-.132.025-.085-2.855-.085-.863"
      />
      <path
        fill="#fff"
        d="M24.718 26.035v3.347a.75.75 0 0 1-.19.537.74.74 0 0 1-.51.246H7.841a.74.74 0 0 1-.51-.246.75.75 0 0 1-.19-.537V26.6c0-.182.058-.36.167-.504l8.9-11.765a.785.785 0 0 0-.146-1.123.77.77 0 0 0-.387-.146h-7.58a.73.73 0 0 1-.648-.507.8.8 0 0 1-.036-.29V8.91a.75.75 0 0 1 .185-.531.73.73 0 0 1 .499-.25h15.594a.73.73 0 0 1 .667.481q.052.14.048.287v2.798a.86.86 0 0 1-.166.504l-8.904 11.768c-.383.508-.06 1.288.533 1.288h8.154c.195.01.377.099.508.245a.75.75 0 0 1 .189.535"
      />
    </g>
    <defs>
      <clipPath id="zoee-icon_svg__a">
        <path fill="#fff" d="M0 0h44v38H0z" />
      </clipPath>
    </defs>
  </svg>
);
