import React from "react"
import CloseModal from "../CloseModal"
import { Button } from "../ui/button"
import { BlogPost } from "../../models/blog.interface"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { SvgArticle, SvgCheckbox, SvgCheckboxOff, SvgPlus } from "../icons"
import { SvgArrowLeftRight } from "../icons/arrow-left-right"

interface Props {
  handleClick: any
  post: BlogPost | null
  allPosts: BlogPost[]
  updateBento: (
    bentoSpot: number,
    selection: string,
    contentId?: number,
    content?: string,
    url?: string
  ) => void
  bentoSelection: number
}

export default function BentoBlogFlow({
  handleClick,
  post,
  allPosts,
  updateBento,
  bentoSelection,
}: Props) {
  const [selectedBlog, setSelectedBlog] = React.useState<BlogPost | null>()
  const { setCreateBlogPost } = React.useContext(CoachPrivateProfileContext)

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009]">
      <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] md:min-h-[70vh] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto">
        <CloseModal
          callback={() => {
            handleClick("cancel")
          }}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
          Featured Content
        </h2>
        <div className="flex gap-x-2 justify-center items-center">
          <SvgArticle height="34" width="34" />
          <p className="font-bold text-[20px] sm:mt-4 md:-translate-y-2">
            Blog
          </p>
        </div>
        <Button
          variant={"link"}
          onClick={() => {
            handleClick("switch content")
          }}
        >
          <SvgArrowLeftRight />
          Switch content type
        </Button>
        <div className="flex flex-col gap-2 justify-center items-center mt-4 w-full">
          {post ? (
            <>
              {allPosts.map((blog: BlogPost, index: number) => {
                return (
                  <div
                    className="self-start hover:cursor-pointer"
                    key={index}
                    onClick={() => {
                      setSelectedBlog(blog)
                    }}
                  >
                    <div className="flex gap-x-8">
                      {selectedBlog === blog ? (
                        <>
                          <SvgCheckbox />
                        </>
                      ) : (
                        <>
                          <SvgCheckboxOff />
                        </>
                      )}
                      <p className="font-bold text-md">{blog.title}</p>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <div
                className={`h-full flex flex-col items-center justify-center gap-y-8`}
              >
                <p className="text-graySlate text-md text-center">
                  No blog posts available.
                  <br />
                  Add your first blog post to feature in this section
                </p>
              </div>
            </>
          )}
        </div>
        <Button
          variant={"utility"}
          className={`bg-white hover:bg-grayFlash drop-shadow-lg mt-auto ${
            post ? "" : ""
          }`}
          onClick={() => setCreateBlogPost(true)}
        >
          <SvgPlus />
          Add a Blog Post
        </Button>
        <div className="flex gap-x-2 mt-auto">
          <Button
            className="mt-8"
            size="lg"
            variant={"ghost"}
            onClick={() => {
              handleClick("cancel")
            }}
          >
            Cancel
          </Button>

          <Button
            className="mt-8"
            size="lg"
            variant={`${selectedBlog ? "default" : "secondary"}`}
            onClick={() => {
              updateBento(bentoSelection, "blog", selectedBlog?.id)
              handleClick("cancel")
            }}
            disabled={!selectedBlog}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
