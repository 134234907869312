import React from 'react';

interface ProfileInterface {
    coachPublicProfileData: any;
    setCoachPublicProfileData: any;
    initialState: boolean;
    setInitialState: any;
    normalState: boolean;
    setNormalState: any;
    editBannerImage: boolean;
    setEditBannerImage: any;
    editProfileInformation: boolean;
    setEditProfileInformation: any;
    editprimaryProfileVideo: boolean;
    setEditPrimaryProfileVideo: any;
    editBlogPost: any;
    setEditBlogPost: any;
    createBlogPost: boolean;
    setCreateBlogPost: any;
    editPortraitVideo: any;
    setEditPortraitVideo: any;
    editCredentials: boolean;
    setEditCredentials: any;
    editSocialMedia: boolean,
    setEditSocialMedia: any,
    profileColor: string;
    setProfileColor: any;
    previewImgUrl: string;
    setPreviewImgUrl: any;
    selectedTile: string;
    setSelectedTile: any;
    selectedTags: number[]
    setSelectedTags: any;
    hasProfileVideo: boolean;
    setHasProfileVideo: any;
    getCoachPrivateProfile: any;
    services: any;
    getServices: any;
    setServices: any;
    loadingServices: boolean;
    setLoadingServices: any;
    nextStepsData: any;
    setNextStepsData: any;
    connectAccountData: any;
    syncPrompt: boolean;
    setSyncPrompt: any;
    loadingBento: boolean;
    setLoadingBento: any;
}

export const CoachPrivateProfileContext = React.createContext<ProfileInterface>({
    coachPublicProfileData: {},
    setCoachPublicProfileData: {},
    initialState: false,
    setInitialState: {},
    normalState: true,
    setNormalState: {},
    editBannerImage: false,
    setEditBannerImage: {},
    editProfileInformation: false,
    setEditProfileInformation: {},
    editprimaryProfileVideo: false,
    setEditPrimaryProfileVideo: {},
    editBlogPost: {},
    setEditBlogPost: {},
    createBlogPost: false,
    setCreateBlogPost: {},
    editPortraitVideo: {},
    setEditPortraitVideo: {},
    editCredentials: false,
    setEditCredentials: {},
    editSocialMedia: false,
    setEditSocialMedia: {},
    profileColor: "#C6AB5B",
    setProfileColor: {},
    previewImgUrl: "",
    setPreviewImgUrl: {},
    selectedTile: "about",
    setSelectedTile: {},
    selectedTags: [],
    setSelectedTags: {},
    hasProfileVideo: false,
    setHasProfileVideo: {},
    getCoachPrivateProfile: {},
    services: {},
    getServices: {},
    setServices: {},
    loadingServices: false,
    setLoadingServices: {},
    nextStepsData: {},
    setNextStepsData: {},
    connectAccountData: {},
    syncPrompt: false,
    setSyncPrompt: {},
    loadingBento: false,
    setLoadingBento: {},
});
