import type { SVGProps } from "react";
export const SvgVerifyPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M21.793 16.883a2.1 2.1 0 0 0-.748-.884l-3.094-2.193a4.2 4.2 0 0 0-.782-.433 1.98 1.98 0 0 0-1.818.102c-.31.171-.593.388-.838.642l-.671.625a6.3 6.3 0 0 1-1.078-.773c-.425-.36-.883-.782-1.434-1.323-.43-.438-.862-.908-1.316-1.433a7.3 7.3 0 0 1-.763-1.029q-.01-.029-.023-.064l.635-.66q.364-.353.624-.79c.193-.306.295-.661.297-1.023a2.1 2.1 0 0 0-.182-.832 3.8 3.8 0 0 0-.45-.79L7.995 2.986a2.2 2.2 0 0 0-.897-.752A2.46 2.46 0 0 0 6.043 2a3.4 3.4 0 0 0-2.417 1.065 5 5 0 0 0-1.24 1.801A5.5 5.5 0 0 0 2 6.921a8.6 8.6 0 0 0 .726 3.316 17.5 17.5 0 0 0 1.904 3.308 27 27 0 0 0 2.673 3.128c.977.967 2.025 1.86 3.135 2.673a17 17 0 0 0 3.343 1.918 8.5 8.5 0 0 0 3.31.736 5.4 5.4 0 0 0 2.082-.405 4.86 4.86 0 0 0 1.78-1.276c.304-.333.556-.71.747-1.119.197-.41.3-.858.3-1.312a2.6 2.6 0 0 0-.207-1.005m-1.357 1.721a3.1 3.1 0 0 1-.518.778c-.35.4-.788.716-1.278.923a5.57 5.57 0 0 1-4.312-.324 15.7 15.7 0 0 1-3.066-1.763 26 26 0 0 1-2.971-2.531 26 26 0 0 1-2.528-2.96 16 16 0 0 1-1.75-3.034 7.2 7.2 0 0 1-.616-2.772 4.1 4.1 0 0 1 .288-1.54c.202-.5.513-.948.91-1.312a2.04 2.04 0 0 1 1.448-.674c.16 0 .317.035.462.103.14.064.26.165.347.292l2.165 3.046q.179.241.296.517a.8.8 0 0 1 .074.294.6.6 0 0 1-.105.317c-.116.19-.256.366-.417.52l-.696.723a1.2 1.2 0 0 0-.356.867q.003.236.073.462L8 10.81q.414.7.95 1.31c.48.552.93 1.043 1.39 1.511.585.575 1.068 1.02 1.52 1.402q.614.54 1.326.941l.247.11a1.31 1.31 0 0 0 1.354-.276l.71-.701q.239-.25.539-.418a.496.496 0 0 1 .603-.026 2.8 2.8 0 0 1 .512.287l3.09 2.19a.7.7 0 0 1 .262.282 1.2 1.2 0 0 1 .099.466 1.65 1.65 0 0 1-.167.715"
    />
    <path
      fill="currentColor"
      d="M17.922 3A3.93 3.93 0 0 0 14 6.921a3.92 3.92 0 0 0 3.922 3.93 3.92 3.92 0 0 0 3.921-3.93A3.93 3.93 0 0 0 17.922 3m1.744 3.778-1.973 1.32a.67.67 0 0 1-.356.102.64.64 0 0 1-.517-.27l-.677-.966a.637.637 0 0 1 .16-.881.627.627 0 0 1 .882.152l.322.458 1.456-.974a.64.64 0 0 1 .881.177.63.63 0 0 1-.178.882"
    />
  </svg>
);
