import type { SVGProps } from "react";
export const SvgVerifyIdentity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 66 49"
    {...props}
  >
    <g clipPath="url(#verify-identity_svg__a)">
      <path stroke="currentColor" strokeWidth={3} d="M2 5h62v38H2z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.912 19.754c0 2.545-1.963 4.586-4.412 4.586s-4.411-2.041-4.411-4.587 1.963-4.585 4.411-4.585c2.45 0 4.412 2.04 4.412 4.586M20.5 32.5c-3.614 0-6.666-.61-6.666-2.968s3.07-2.948 6.666-2.948c3.616 0 6.667.611 6.667 2.969S24.096 32.5 20.5 32.5"
        clipRule="evenodd"
      />
      <path
        stroke="currentColor"
        strokeWidth={3}
        d="M32.5 16h15M32.5 23h24M32.5 30h15"
      />
    </g>
    <defs>
      <clipPath id="verify-identity_svg__a">
        <path fill="currentColor" d="M.5.5h65v48H.5z" />
      </clipPath>
    </defs>
  </svg>
);
