import React from "react"
import DeleteDialogueModal from "../DialogueModal"
import moment from "moment"
import SubscriptionTag from "../../components/subscription/SubscriptionTag"
import useDate from "../../hooks/useDate"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SubscriptionContext } from "./SubscriptionContext"
import { SubscriptionRequests, ReferralRequests } from "../../api/app.service"
import { SvgChatEllipses, SvgLightning } from "../icons/"
import { useIntercom } from "react-use-intercom"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

const CurrentSubscription: React.FC = () => {
  const { user } = React.useContext(UserCtx)
  const {
    subscription,
    setSelectedSubscription,
    showSubscriptionComponents,
    setIsSubscriptionUpdate,
    setShowSubscriptionCheckout,
    setShowSubscriptionPlans,
    plans,
    selectedPaymentMethod,
    getSubscription,
  } = React.useContext(SubscriptionContext)
  const { renderError, renderSuccess, hideAlert } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [addPromocode, setAddPromocode] = React.useState<boolean>(false)
  const [promoCode, setPromoCode] = React.useState<string>("")
  const { feature1, feature2, feature3, feature4 } =
    subscription?.zoee_product_metadata || {}
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    React.useState<boolean>(false)
  const { mdDate } = useDate()
  const { show } = useIntercom()
  const navigate = useNavigate()

  const handleCancelSubscriptionClick = () => {
    if (showCancelSubscriptionModal) {
      console.log("Cancel Subscription")
    } else {
      setShowCancelSubscriptionModal(true)
    }
  }

  const updateSubscription = (promoData: any) => {
    startLoading()
    SubscriptionRequests.updateSubscription({
      user_id: parseInt(user.user_id),
      stripe_payment_method_id: selectedPaymentMethod.stripe_payment_method_id,
      current_stripe_price_id: subscription?.stripe_price_id,
      new_stripe_price_id: subscription?.stripe_price_id,
      promotion_code: promoCode,
    })
      .then((data) => {
        if (data.subscribed_with_referral) {
          ReferralRequests.updateReferralOnSubscribe({
            user_id: parseInt(user.user_id),
            promo_code: promoCode,
            email: user.email,
          })
            .then((data) => {})
            .catch((ex) => {
              console.error("updateReferralOnSubscribe err", ex)
            })
        }
      })
      .then(() => {
        const details = promoData.promo_code
        const discountType = details.promo_value_type
        const discountUnits = details.promo_value
        if (discountType === "percent") {
          const discountDollars = (discountUnits / 100) * subscription?.amount
          renderSuccess(
            `Congrats! Your promotion code is saving you $${discountDollars.toFixed(
              2
            )} on future payments`
          )
          setTimeout(() => hideAlert(), 5000)
        } else if (discountType === "amount") {
          renderSuccess(
            `Congrats! Your promotion code is saving you $${discountUnits.toFixed(
              2
            )} on future payments`
          )
          setTimeout(() => hideAlert(), 5000)
        }
        setAddPromocode(false)
        setShowSubscriptionCheckout(false)
        setTimeout(() => {
          getSubscription()
        }, 2500)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setTimeout(() => hideAlert(), 5000)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const cancelSubscription = () => {
    startLoading()
    SubscriptionRequests.cancelCoachSubscription({
      subscription_id: subscription?.subscription_id,
    })
      .then((data) => {
        console.log(data)
        getSubscription()
        renderSuccess(data.message)
        setShowCancelSubscriptionModal(false)
        // setUserActiveSubscription()
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setTimeout(() => hideAlert(), 5000)
        stopLoading()
      })
  }

  const validatePromoCode = () => {
    if (promoCode !== "") {
      startLoading()
      SubscriptionRequests.validatePromoCode({
        promo_code: promoCode,
        promo_duration_interval: subscription?.recurring_interval,
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          if (!data.promo_valid) {
            renderError(data.promo_invalid_reason)
            setTimeout(() => {
              hideAlert()
            }, 5000)
            setAddPromocode(false)
            stopLoading()
          } else {
            updateSubscription(data)
          }
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setTimeout(() => hideAlert(), 5000)
          stopLoading()
        })
    }
  }

  const getTrialDaysRemaining = () => {
    const expiration = moment(user?.trialExpiration)
    const now = moment()

    return Math.max(0, expiration.diff(now, "days"))
  }

  const getCurrentPlanSection = () => {
    if (subscription) {
      if (
        subscription?.subscription_end_date === "None" &&
        subscription.subscription_promo_code !== "500FOREVER"
      ) {
        return (
          <>
            {/* <button
              className="btn-primary bg-vividRed mx-auto mt-[24px] h-[42px]"
              onClick={handleCancelSubscriptionClick}
            >
              Cancel Subscription
            </button> */}
            <div className="flex justify-start">
              <Button
                onClick={handleCancelSubscriptionClick}
                variant="ghost"
                className="h-12 px-6 py-3 rounded-[10px] border border-[#d1d9e0] flex items-center gap-2.5"
              >
                <span className="text-black text-sm font-bold font-['Beausite Classic'] leading-normal">
                  Cancel Subscription
                </span>
              </Button>
            </div>

            {showCancelSubscriptionModal && (
              <DeleteDialogueModal
                isWarningVariant
                setShowDialogueModal={setShowCancelSubscriptionModal}
                helpText={`Are you sure you want to cancel your subscription? If you cancel, your subscription will be active until ${mdDate(
                  subscription.subscription_next_payment_date
                )}`}
                header="Cancel Subscription?"
                confirmButtonText="Cancel Subscription"
                confirmCallbackFn={cancelSubscription}
              />
            )}
          </>
        )
      } else if (
        subscription?.subscription_end_date !== "None" &&
        subscription.subscription_promo_code !== "500FOREVER"
      ) {
        return (
          <h2 className="font-bold">
            {/* Subscription end date: {mdDate(subscription.subscription_end_date)} */}
          </h2>
        )
      } else {
        return <></>
      }
    }
  }

  const navigateToSomewhere = () => navigate("/coach/subscription/plans")

  return (
    <div className="main-shadow grow rounded-[16px] p-[24px] sm:min-w-[490px] md:h-fit md:p-[32px]">
      <div className="mb-[24px] flex w-full items-center justify-between">
        <h2 className="text-[22px] font-bold">Current Plan</h2>
      </div>

      <div className="border-grayMist flex w-full flex-col rounded-[24px] border p-[24px] md:p-[32px]">
        {!subscription ? (
          <>
            <div className="mb-[96px] flex w-full justify-between font-bold">
              <SubscriptionTag subscriptionName={"Free Trial"} />
              {(getTrialDaysRemaining() || 0) <= 0 ? (
                <h4 className="text-vividRed text-[32px]">Expired</h4>
              ) : (
                <h4 className="text-green text-[32px]">Free</h4>
              )}
            </div>
            <div className="flex items-center justify-between">
              <div className="mb-[18px] flex flex-col gap-[8px] text-base md:mb-0">
                <div className="flex items-center gap-[8px]">
                  <SvgChatEllipses />
                  <p>Need help deciding?</p>
                </div>
                <button
                  onClick={() => {
                    show()
                  }}
                  className="text-blurple font-bold"
                >
                  Chat with our support
                </button>
              </div>
              <Button
                onClick={navigateToSomewhere}
                className="flex w-[158px] min-w-fit items-center gap-[10px]"
              >
                Upgrade Plan
                <SvgLightning />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="mb-[24px] flex items-center justify-between">
              {/* PLAN DETAILS */}
              <div className="flex flex-col gap-[12px]">
                <h2 className="text-[22px] font-bold">
                  {subscription?.promotion_details?.lifetime ? (
                    <>Zoee Lifetime</>
                  ) : (
                    <>{`${
                      subscription?.recurring_interval === "annual"
                        ? "Annual"
                        : "Monthly"
                    } Pro`}</>
                  )}
                </h2>
                {/* PRICE */}
                <div>
                  <span className="text-black text-[42px] font-semibold font-['Beausite Classic']">
                    $ {subscription?.amount}
                  </span>
                  <span className="text-black text-[28px] font-normal font-['Beausite Classic']">
                    {subscription?.promotion_details?.lifetime && (
                      <>/one-time</>
                    )}
                    {subscription?.recurring_interval === "annual" &&
                      subscription?.promo_duration === 2 && <>/two years</>}
                    {subscription?.recurring_interval === "annual" &&
                      subscription?.promo_duration !== 2 &&
                      !subscription?.promotion_details?.lifetime && <>/year</>}
                    {subscription?.recurring_interval !== "annual" && (
                      <>/month</>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {getCurrentPlanSection()}
      </div>
    </div>
  )
}

export default CurrentSubscription
