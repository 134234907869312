export function getSessionType(type: string) {
  if (type === "individual") {
    return "Single Session";
  } else if (type === "consultation") {
    return "Consultation Session";
  } else if (type === "recurring") {
    return "Recurring Sessions";
  } else if (type === "bundle") {
    return "Multiple Sessions";
  }
};
