import React from "react"
import { SvgCheckCircle } from "../icons"
import { useLocation } from "react-router-dom"

type Props = {
  isNewPlan: boolean
  subscription: any
  onSelectedPlanChange: (selectedPlan: any) => void
}

const SubscriptionCheckoutCard: React.FC<Props> = ({
  isNewPlan,
  subscription,
  onSelectedPlanChange,
}) => {
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("")

  const [cardColor, setCardColor] = React.useState<string>("")

  const getStrikethroughPrice = () => {
    if (
      selectedPlan?.recurring_interval === "annual" &&
      !selectedPlan?.is_promotional
    )
      return "$55.00/mo"
    if (
      selectedPlan?.recurring_interval === "month" &&
      !selectedPlan?.is_promotional
    )
      return "$69.00/mo"
  }

  const getBillingCycleText = () => {
    if (
      selectedPlan?.recurring_interval === "annual" &&
      !selectedPlan?.is_promotional
    )
      return "Billed annually"
    if (
      selectedPlan?.recurring_interval === "month" &&
      !selectedPlan?.is_promotional
    )
      return <span style={{ visibility: "hidden" }}>Monthly</span>
  }

  const location = useLocation()
  const { selectedPlan, selectedTitle, selectedAmount } = location.state || {}

  React.useEffect(() => {
    if (selectedPlan) {
      onSelectedPlanChange(selectedPlan)
    }
  }, [selectedPlan, onSelectedPlanChange])

  return (
    <div
      className={`${cardColor} md:w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px] ${
          subscription?.recurring_interval === "annual"
            ? "bg-blurple"
            : subscription?.recurring_interval === "month"
            ? "bg-black"
            : "bg-gray-400"
        } flex items-center justify-center`}
        style={{ borderTopLeftRadius: "32px", borderTopRightRadius: "32px" }}
      >
        {subscription?.recurring_interval !== "freeTrial" && (
          <p className="text-white text-center">Limited Time Offer</p>
        )}
      </div>

      <div className="h-[736px] px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex mb-4">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            {selectedTitle}
          </div>
          <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic'] -mt-2">
            ${selectedAmount}
            {paymentPeriod}
          </div>
          <div>
            <p className="text-[18px] text-graySlate line-through -mt-2">
              {getStrikethroughPrice()}
            </p>
            <p className="text-[18px] text-graySlate">
              {getBillingCycleText()}
            </p>
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] ">
            <div className="flex flex-col gap-[16px]">
              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col gap-[16px]">
                  {Object.keys(selectedPlan?.zoee_product_metadata || {})
                    .filter((key) => key.startsWith("feature"))
                    .map((key, idx) => (
                      <div
                        className="flex items-center gap-4"
                        key={`${idx}-${key}`}
                      >
                        <div className="flex-shrink-0">
                          <SvgCheckCircle stroke="#4750f5" />
                        </div>
                        <p>{selectedPlan.zoee_product_metadata[key]}</p>
                      </div>
                    ))}
                </div>
              </div>
              <p className="italic">
                * Limited Time Discount! Sign up now to lock in our special
                pricing forever.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCheckoutCard
