"use client"

import * as React from "react"
import { Button } from "../ui/button"
import { useAuthModal } from "../auth/use-auth-modal"
import { useScroll, useTransform } from "framer-motion"
import { IaprcVideo } from "./iaprc-video"
import { SvgZoeeLogo } from "../icons"

function IaprcWelcome() {
  const { scrollYProgress } = useScroll()
  const { showRegister } = useAuthModal()

  const handleCreateAccount = () => {
    localStorage.setItem("referrerPromoCode", "IAPRC2024")
    showRegister({ accountType: "coach" })
  }

  return (
    <>
      <div
        className="pt-[100px] md:pt-[150px] flex flex-col items-center justify-center overflow-hidden -mt-16 bg-gradient-to-t from-blurple/30 to-white"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        <div className="flex flex-col items-center sm:items-start sm:flex-row justify-between w-full px-4 md:px-10">
          <div className="mt-4">
            <SvgZoeeLogo className="h-6 md:h-8 lg:h-10 w-auto" />
          </div>

          <div className="mt-4">
            <button
              className="text-white bg-[#333333] rounded-xl px-6 py-3"
              onClick={handleCreateAccount}
            >
              Try for Free
            </button>
          </div>
        </div>

        <div className="h-auto w-full max-w-[1200px] px-4 md:px-[50px] lg:px-[100px] flex flex-col md:flex-row justify-start items-center md:items-start gap-8 md:gap-16 mt-8">
          <div className="md:w-1/2 flex flex-col justify-start items-center md:items-start gap-8">
            <div className="text-center md:text-left self-stretch flex-col justify-center items-center gap-6 flex">
              <div className="text-[#333333] text-[32px] md:text-[56px] font-bold font-['Beausite Classic'] leading-tight md:leading-[64px]">
                Transform Your Coaching Business:
                <span className="text-[#333333] font-thin">
                  {" "}
                  Less Hassle, More Impact
                </span>
              </div>
              <div className="text-[#333333] text-[14px] md:text-[18px] font-thin font-['Beausite Classic']">
                Streamline your business, reach new clients, all-in-one place.
                Created by coaches for coaches. IAPRC Approved Partner.
              </div>
            </div>

            <button
              className="flex justify-center items-center text-white bg-[#333333] rounded-xl px-6 py-3"
              onClick={handleCreateAccount}
            >
              Try for Free
            </button>
          </div>

          {/* video */}
          <div className="md:w-1/2 flex justify-center md:justify-end hidden md:flex">
            <IaprcVideo />
          </div>
        </div>

        <div className="flex flex-row items-center flex-wrap gap-8 justify-center mt-8 md:mt-16"></div>
        <p className="mt-5 mb-10 md:mb-20 text-base text-muted-foreground"></p>
        <div className="bg-gradient-to-t from-white to-blurple w-full flex justify-center"></div>
      </div>
    </>
  )
}

export default IaprcWelcome
