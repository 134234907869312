import type { SVGProps } from "react";
export const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 34 34"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17 1.417C8.381 1.417 1.417 8.38 1.417 17S8.38 32.583 17 32.583 32.583 25.62 32.583 17 25.62 1.417 17 1.417m8.332 16.06L12.898 25.11c-.382.222-.891-.032-.891-.477V9.367c0-.445.509-.731.89-.476l12.435 7.632c.35.223.35.732 0 .954"
    />
  </svg>
);
