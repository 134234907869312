import type { SVGProps } from "react";
export const SvgLightning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.7 14.138c-.061 0-6.7 7.54-6.769 7.54-.486.753-1.822.065-1.527-.82l2.673-8.379-2.898-1.055c-.452-.217-1.085-.32-1.163-.942a.99.99 0 0 1 .277-.886l6.942-7.228a.84.84 0 0 1 .807-.36.89.89 0 0 1 .69.58c.118.313.073.671-.117.939l-2.343 7.822 3.123 1.178c.198.068.366.21.475.4.109.192.151.418.12.64a.97.97 0 0 1-.29.571"
    />
  </svg>
);
