import type { SVGProps } from "react";
export const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.024 5.176a.6.6 0 0 0-.848.848l6.01 6.011-6.001 6.001a.6.6 0 1 0 .848.849l6.002-6.001 6.01 6.01a.6.6 0 0 0 .85-.848l-6.011-6.01 6.008-6.008a.6.6 0 1 0-.848-.848l-6.009 6.007z"
      clipRule="evenodd"
    />
  </svg>
);
