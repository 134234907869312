/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import avatar from "../../assets/images/avatars.png"
import Person from "./Person"
import Program from "./Program"
import { Link } from "react-router-dom"
import { useChatContext } from "stream-chat-react"
import { ShowChatCtx } from "../../context/showChatContext"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests, UserInfo } from "../../api/app.service"
import CloseModal from "../CloseModal"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { SvgSearch } from "../icons"

interface Props {
  setSelectNewChatParams: any
  selectNewChatParams: any
  user: any
}

const programs = [
  {
    id: "1000000",
    name: "Eat Right Feel Right",
    img: avatar,
    users: ["Rory_McIlroy_1000281", "Roger_Federer_1000282"],
  },
  {
    id: "1000001",
    name: "Be the G.O.A.T",
    img: avatar,
    users: [
      "Rory_McIlroy_1000281",
      "Roger_Federer_1000282",
      "LeBron_James_1000283",
      "Lionel_Messi_1000284",
      "Mikaela_Shiffrin_1000285",
    ],
  },
  {
    id: "1000002",
    name: "Team Sports Only",
    img: avatar,
    users: ["LeBron_James_1000283", "Lionel_Messi_1000284"],
  },
]

const NewChatSelector: React.FC<Props> = (props: any) => {
  const { renderError, updateNextStepsData } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { user } = React.useContext(UserCtx)
  const [selectedContacts, setSelectedContacts] = React.useState<any>([])
  const [selectedProgram, setSelectedProgram] = React.useState<any>(null)
  const [search, setSearch] = React.useState<string>("")
  const [contacts, setContacts] = React.useState<any>([])
  const [foundContacts, setFoundContacts] = React.useState<any>([])
  const [foundPrograms, setFoundPrograms] = React.useState<any>(programs)
  const { client, setActiveChannel } = useChatContext()
  const showChatContext = React.useContext(ShowChatCtx)
  const { setSelectNewChatParams, selectNewChatParams } = props
  const [nextStepsData, setNextStepsData] = React.useState<any>(null)

  const isMember = props.user.activeProfile === "member"

  const updateSelectedContacts = (e: React.MouseEvent, profileId: any) => {
    const filterredContacts = foundContacts.filter(
      (contact: any) => profileId === contact.profile_id
    )

    if (isMember) {
      setFoundContacts(filterredContacts)
    }

    if (!selectedContacts.includes(profileId)) {
      setSelectedContacts([...selectedContacts, profileId])
    } else {
      const filtered = selectedContacts.filter((contact: any) => {
        return contact !== profileId
      })
      setSelectedContacts(filtered)
    }
  }

  const updateSelectedProgram = (program: any) => {
    if (program === selectedProgram) {
      setSelectedProgram(null)
    } else {
      setSelectedProgram(program)
    }
  }

  const getMessageType = () => {
    const type = selectNewChatParams.messageType
    if (type === "direct") {
      return "Direct Message"
    } else if (type === "group") {
      return "Group Message"
    } else if (type === "program") {
      return "Program Message"
    } else {
      return "New Message"
    }
  }

  const createDirectOrGroupChannel = async () => {
    const members = [...selectedContacts, client?.user?.id]
    const stringifiedMembers = members.map((member) => member.toString())
    if (client) {
      const channel = client.channel("messaging", stringifiedMembers.join(""), {
        members: stringifiedMembers,
      })
      await channel.create()
      setActiveChannel(channel)
      showChatContext?.setShowChat(true)
    }
    if (!nextStepsData.has_sent_chats) {
      const created_chat = true
      updateNextStepsData(created_chat)
    }
    setSelectNewChatParams(false)
  }

  const createProgramChannel = async () => {
    if (client) {
      const channel = client.channel("messaging", {
        members: [...selectedProgram.users, client?.user?.id],
        name: selectedProgram.name,
        programId: selectedProgram.id,
      })
      await channel.create()
      setActiveChannel(channel)
      showChatContext?.setShowChat(true)
    }
    if (!nextStepsData.has_sent_chats) {
      const created_chat = true
      updateNextStepsData(created_chat)
    }
    setSelectNewChatParams(false)
  }

  const filterList = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value
    const filterable =
      selectNewChatParams.messageType === "group" ||
      selectNewChatParams?.messageType === "direct"
        ? contacts
        : programs
    if (keyword !== "") {
      const results = filterable.filter((item: any) => {
        return item?.contact_name
          ?.toLowerCase()
          ?.includes(keyword.toLowerCase())
      })
      selectNewChatParams.messageType === "group" ||
      selectNewChatParams.messageType === "direct"
        ? setFoundContacts(results)
        : setFoundPrograms(results)
    } else {
      selectNewChatParams.messageType === "group" ||
      selectNewChatParams.messageType === "direct"
        ? setFoundContacts(contacts)
        : setFoundPrograms(programs)
    }
    setSearch(keyword)
  }

  const populateContacts = async () => {
    if (user?.coachProfile) {
      startLoading()
      await ContactRequests.getContactList({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
      })
        .then((data) => {
          const connectedContacts = data.contact_list.filter(
            (contact: any) => contact.contact_type === "connected"
          )
          setContacts(connectedContacts)
          setFoundContacts(connectedContacts)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const focusSearch = () => {
    const search = document.getElementById("newChatSelectorSearch")
    search?.focus()
  }

  const getNextStepsValues = () => {
    if (user) {
      UserInfo.getNextStepsValues({
        user_id: parseInt(user.user_id),
      })
        .then((data) => {
          setNextStepsData(data.next_steps_data)
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  React.useEffect(() => {
    if (user) {
      populateContacts().then(() => {
        getNextStepsValues()
      })
    }
    focusSearch()
  }, [])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div
        className="w-[351px] sm:w-[520px] main-shadow rounded-[16px] py-[32px] px-[8px] 
                bg-white fixed left-[50%] -translate-x-[50%] top-[150px]
                flex flex-col items-center"
      >
        <CloseModal
          callback={() => setSelectNewChatParams(false)}
          styling="absolute top-[24px] right-[24px]"
        />
        <h4 className="font-bold text-[22px] mb-[10px]">{getMessageType()}</h4>
        {isMember && (
          <p className="mb-[10px] font-bold text-[#727679]">
            You can only chat with one coach at a time
          </p>
        )}
        <div className="search-input mb-[8px] w-[319px] sm:w-[392px]">
          <input
            type="text"
            placeholder={`Search for ${
              selectNewChatParams.messageType === "direct" ||
              selectNewChatParams.messageType === "group"
                ? "people"
                : "programs"
            }`}
            onChange={filterList}
            id="newChatSelectorSearch"
          />
          <SvgSearch className="left-[12px] absolute top-[50%] -translate-y-[50%]" />
        </div>
        {selectNewChatParams.messageType === "program" && (
          <div
            className="w-full sm:w-[392px] bg-primaryBlue/[0.04] rounded-[14px] 
                        flex items-center justify-between px-[24px] py-[16px] mb-[8px]"
          >
            <h5 className="font-bold text-[16px]">Create a new program</h5>
            <Link to="#" className="btn-primary btn-blue">
              Create
            </Link>
          </div>
        )}
        <div className="w-full sm:w-[392px] sm:mx-auto max-h-[308px] overflow-y-auto mb-[16px]">
          {(selectNewChatParams?.messageType === "direct" ||
            selectNewChatParams?.messageType === "group") &&
            foundContacts?.map((contact: any, idx: number) => (
              <div
                key={idx}
                onClick={(e) => {
                  updateSelectedContacts(e, contact.profile_id)
                }}
              >
                <Person
                  user={user}
                  contact={contact}
                  selectedContacts={selectedContacts}
                />
              </div>
            ))}
          {selectNewChatParams?.messageType === "program" &&
            foundPrograms?.map((program: any, idx: number) => (
              <div key={idx} onClick={() => updateSelectedProgram(program)}>
                <Program program={program} selectedProgram={selectedProgram} />
              </div>
            ))}
        </div>
        <Button
          id="start_chat"
          className="text-md"
          disabled={
            ((selectNewChatParams?.messageType === "direct" ||
              selectNewChatParams?.messageType === "group") &&
              selectedContacts?.length === 0) ||
            (selectNewChatParams?.messageType === "program" &&
              selectedProgram === null)
          }
          onClick={() => {
            if (
              selectNewChatParams?.messageType === "direct" ||
              selectNewChatParams?.messageType === "group"
            ) {
              createDirectOrGroupChannel()
            } else if (selectNewChatParams?.messageType === "program") {
              createProgramChannel()
            }
          }}
        >
          Start Message
        </Button>
      </div>
    </div>
  )
}

export default NewChatSelector
