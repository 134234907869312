import React from "react";
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard";
import { SvgCopy, SvgTrash } from "../icons";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";

interface Props {
  promoCode: any;
  setCurrentPromoCode: any;
  setDeletePromoCode: any;
}

const PromoCodeItem: React.FC<Props> = ({
  promoCode,
  setCurrentPromoCode,
  setDeletePromoCode,
}) => {
  const [, copy] = useCopyToClipboard();

  const handlePromoCodeDelete = () => {
    setCurrentPromoCode(promoCode);
    setDeletePromoCode(true);
  };

  return (
    <div className="w-full bg-white rounded-lg main-shadow p-6 grid md:grid-cols-3 items-center gap-3">
      <div className="flex flex-col">
        <h4 className="font-bold">{promoCode.promo_code}</h4>
        <p className="text-base text-graySlate">
          {promoCode.promo_description}
        </p>
      </div>
      <Badge variant="success" className="justify-self-start text-base">
        {promoCode?.promo_type === "percent"
          ? `${promoCode?.promo_value}%`
          : `${promoCode?.promo_value}`} OFF
      </Badge>
      <div className="flex items-center gap-2 justify-end">
        <Button
          size="icon"
          variant="ghost"
          onClick={() => copy(promoCode.promo_code)}
        >
          <SvgCopy />
        </Button>
        <Button
          size="icon"
          variant="ghost"
          onClick={() => handlePromoCodeDelete()}
        >
          <SvgTrash />
        </Button>
      </div>
    </div>
  );
};

export default PromoCodeItem;
