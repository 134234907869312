import * as React from "react";
import { accountSettingsLinks } from "../../../objects/settingsObjects";
import { UserCtx } from "../../../context/userContext";
import { useLocation } from "react-router-dom";
import useScrollToTop from "../../../hooks/useScrollToTop";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../components/ui/avatar";
import { getUserColor } from "../../../utils/getUserColor";
import useName from "../../../hooks/useName";
import { getTextColor } from "../../../utils/getTextColor";
import { Link } from "react-router-dom";
import { SvgChevronRight, SvgComingSoon } from "../../../components/icons/";

interface Props {
  setShowSubscriptionCheckout: any;
}

const AccountSettingsPage: React.FC<Props> = ({
  setShowSubscriptionCheckout,
}) => {
  const location = useLocation();
  const { user } = React.useContext(UserCtx);
  const { initial, username } = useName();

  React.useEffect(() => {
    if (location?.state?.showQuickCheckout) {
      setShowSubscriptionCheckout(true);
    }
  }, []);

  useScrollToTop();

  return (
    <div className="mx-auto flex flex-col items-center px-[24px] pt-[8px] md:w-[1080px] md:pt-0">
      <div className="hidden flex-col justify-start md:flex md:w-[1080px]">
        <h2 className="mb-[24px] mr-auto w-[262px] text-[36px] font-bold md:w-auto md:text-[48px] md:font-semibold">
          {`Manage your ${user.activeProfile} account`}
        </h2>
        <div className="mb-[97px] mr-auto flex items-center md:mb-[70px]">
          <Avatar size="sm">
            {Boolean(user.hasAvatar) && (
              <AvatarImage alt="avatar" src={user.avatar_url} />
            )}
            <AvatarFallback className={getUserColor(user.userColor)}>
              <div
                style={{
                  color: getTextColor(user.userColor),
                }}
              >
                {initial}
              </div>
            </AvatarFallback>
          </Avatar>

          <div className="ml-4 flex flex-col gap-[4px]">
            <div className="text-[18px] font-semibold text-black">
              {username}
            </div>
            <div className="text-blurple text-base">{user?.email}</div>
          </div>
        </div>
      </div>
      <div className="mb-[64px] flex flex-col md:mb-[80px] md:w-[1080px]">
        <h2 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[36px] md:font-semibold">
          Account Settings
        </h2>
        {/* <p className="text-graySlate text-base mb-[24px] md:mb-[32px] md:text-[16px]">
                    This is where you set yourself up for success.
                </p> */}
        <div className="flex flex-wrap md:gap-[16px]">
          {accountSettingsLinks?.map((link, idx) => (
            <div
              key={idx}
              className="border-grayMist w-full border-b last:border-0 md:w-auto md:border-0"
            >
              <SettingsLink
                title={link.title}
                text={link.text}
                link={link.link}
                soon={link.soon}
                key={link.id}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="mb-[64px] hidden flex-col md:mb-[80px] md:flex md:w-[1080px]">
        <h2 className="mb-[12px] text-[32px] font-semibold text-black md:text-[36px]">
          Resources
        </h2>
        <p className="text-graySlate mb-[24px] text-base md:mb-[32px] md:text-[16px]">
          We're here to help you run a successful coaching practice.
        </p>
        <div className="flex flex-wrap gap-[16px]">
          {/* Commented our Request a Feature and Report a Bug until there is a way to link them to Linear instead of Canny */}
          {/* {resourceLinks?.map((link) => (
                        <SettingsLink
                            title={link.title}
                            text={link.text}
                            link={link.link}
                            soon={link.soon}
                            key={link.id}
                        />
                    ))} */}
          <a
            href={"https://intercom.help/zoee/en"}
            target="_blank"
            className={`settings-card-shadow hover:card-hover-shadow group relative flex h-[146px] w-[335px] rounded-[16px] bg-white md:w-[349px] md:hover:scale-105`}
            rel="noreferrer"
          >
            <div>
              <div className="group">
                <h3 className="absolute left-[32px] top-[20px] text-[22px] font-bold text-black">
                  Knowledgebase
                </h3>
                <p className="text-graySlate absolute left-[32px] top-[62px] w-[285px] text-base">
                  "Help Me!” If this is you, click here. You’ll find FAQs, help
                  articles, and videos to help you navigate your Zoee
                  experience.
                </p>
                {/* <div className="hidden group-hover:block absolute left-[50%] top-[50%] -translate-y-[50%] -translate-x-[50%] flex flex-col items-center">
                                    <img src={soon} alt="soon" className='m-auto mb-[16px]'/>
                                    <h4 className="text-black font-bold">Coming Soon!</h4>
                                </div> */}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AccountSettingsPage;

const SettingsLink: React.FC<{
  title: string;
  text: string;
  link: string;
  soon: boolean;
}> = (props) => {
  return (
    <>
      {/* MOBILE */}
      <Link
        to={props.link}
        className={`group flex w-full justify-between py-[20px] md:hidden`}
      >
        <h3 className=" text-black">{props.title}</h3>
        <SvgChevronRight />
      </Link>

      {/* DESKTOP */}
      <Link
        to={props.link}
        className={`settings-card-shadow hover:card-hover-shadow group relative hidden h-[146px] w-[335px] rounded-[16px] bg-white md:flex md:w-[349px] md:hover:scale-105`}
      >
        {props.soon ? (
          <div className="group">
            <h3 className="absolute left-[32px] top-[20px] text-[22px] font-bold text-black group-hover:hidden">
              {props.title}
            </h3>
            <p className="text-graySlate absolute left-[32px] top-[62px] w-[285px] text-base group-hover:hidden">
              {props.text}
            </p>
            <div className="absolute left-[50%] top-[50%] flex hidden -translate-x-[50%] -translate-y-[50%] flex-col items-center group-hover:block">
              <SvgComingSoon className="m-auto mb-[16px]" />
              <h4 className="font-bold text-black">Coming Soon!</h4>
            </div>
          </div>
        ) : (
          <div>
            <h3 className="absolute left-[32px] top-[20px] text-[22px] font-bold text-black">
              {props.title}
            </h3>
            <p className="text-graySlate absolute left-[32px] top-[62px] w-[285px] text-base">
              {props.text}
            </p>
          </div>
        )}
      </Link>
    </>
  );
};
