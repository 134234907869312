import * as React from "react"
import { SvgZoeeLogo } from "../../icons"
import { AccountTypeForm } from "./account-type-form"
import { RegisterFormContext } from "./context"
import { RegistrationForm } from "./registration-form"
import { PasswordForm } from "./password-form"
import { NameForm } from "./name-form"
import { PhoneForm } from "./phone-form"

export const Form: React.FC = () => {
  const { view, accountType } = React.useContext(RegisterFormContext)

  const renderForm = () => {
    if (view === "account-type") {
      return <AccountTypeForm />
    } else if (view === "email") {
      return <RegistrationForm />
    } else if (view === "password") {
      return <PasswordForm />
    } else if (view === "name") {
      return <NameForm />
    } else if (view === "phone") {
      return <PhoneForm />
    } else {
      throw new Error("Invalid login view")
    }
  }

  return (
    <>
      {accountType === "coach" && (
        <>
          <h2 className="-mt-4 mb-4 font-bold text-lg text-center capitalize">
            Create {accountType} Account
          </h2>
          <SvgZoeeLogo className="mx-auto -mt-2 mb-4" />
        </>
      )}
      {renderForm()}
    </>
  )
}
