import React from "react"
import IaprcWelcome from "./iaprc-welcome"
import IaprcDiscount from "./iaprc-discount"
import Footer from "../marketing/footer/Footer"

const IAPRC: React.FC = () => {
  return (
    <>
      <IaprcWelcome />
      <IaprcDiscount />
      <Footer />
    </>
  )
}

export default IAPRC
