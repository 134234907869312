import React from 'react';
import CloseModal from '../CloseModal';
import { SvgExpired } from '../icons';

interface Props {
    setShowExpiredTrialPopup: any;
    showSubscriptionComponents: any;
    setShowSubscriptionPlans: any;
}

const ExpiredTrialPopup: React.FC<Props> = ({
    setShowExpiredTrialPopup,
    showSubscriptionComponents,
    setShowSubscriptionPlans
}) => {

    const goToSubscriptionPlans = () => {
        setShowExpiredTrialPopup(false);
        showSubscriptionComponents(setShowSubscriptionPlans);
    }

    return(
        <div className='fixed top-0 left-0 w-screen h-screen pb-[100px] md:pb-0 zoee-backdrop-filter z-[801] flex items-center justify-center'>
            <div 
                className="py-[32px] md:py-[64px] md:w-[600px] mx-[12px] px-[20px] bg-white main-shadow
                rounded-[16px] flex flex-col items-center relative"
            >
				<CloseModal callback={() => setShowExpiredTrialPopup(false)} styling="absolute top-[24px] right-[24px]" />
                <SvgExpired className='mb-[24px] md:mb-[32px]' />
                <h2 className="mb-[8px] font-semibold text-[22px] md:text-[28px]">Your free trial has expired</h2>
                <p className="text-center text-graySlate text-base md:text-[16px] md:w-[470px] mb-[32px]">
                    In order to continue using Zoee as a Coach please upgrade to a paid subscription
                </p>
                <button
                    className='btn-primary btn-blue mx-auto'
                    onClick={goToSubscriptionPlans}
                >Upgrade Subscription</button>
                {/* <button className="btn-primary btn-secondary">Convert to Member</button> */}
            </div>
        </div>
    )
}

export default ExpiredTrialPopup;
