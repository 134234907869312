import type { SVGProps } from "react";
export const SvgLinkedin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.557 2A2.445 2.445 0 0 1 22 4.444v15.113A2.445 2.445 0 0 1 19.557 22H4.444A2.445 2.445 0 0 1 2 19.557V4.444A2.445 2.445 0 0 1 4.444 2zM8.268 18.534V9.717H5.337v8.817zm10.568 0v-5.056c0-2.708-1.446-3.968-3.374-3.968-1.555 0-2.252.855-2.642 1.456V9.717H9.89c.039.828 0 8.817 0 8.817h2.93V13.61c0-.263.02-.527.097-.715.212-.527.694-1.072 1.504-1.072 1.06 0 1.484.809 1.484 1.994v4.717zM6.822 5.466c-1.003 0-1.658.659-1.658 1.523 0 .846.635 1.524 1.62 1.524h.018c1.022 0 1.659-.678 1.659-1.524-.02-.863-.635-1.521-1.639-1.523"
      clipRule="evenodd"
    />
  </svg>
);
