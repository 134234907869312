import React, {
  FC,
  useEffect,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import { Button } from "../../ui/button";
import { SvgCheckbox, SvgCheckboxOff } from "../../icons";
import { FormRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { FormLink } from "../../../models/app.interface";
import useLoading from "../../../hooks/useLoading";
interface ChooseFormsToAttachModalProps {
  cancelAttach: (statement: boolean) => void;
  setLinkedForms: (formList: FormLink[]) => void;
  setServiceValues: (object: any) => void;
  serviceValues: any;
  setCreateFormModal: (statement: boolean) => void;
  createFormModal: boolean
}

interface CustomCheckboxProps {
  onClick?: () => void;
  form: FormLink;
  setSelectedForms: Dispatch<SetStateAction<FormLink[]>>;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({
  form,
  setSelectedForms,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagedId = e.target.id;
    setIsChecked(
      tagedId === form.form_link_id.toString() ? !isChecked : isChecked
    );
  };

  const clickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setSelectedForms((prevForms) => {
      const target = e.target as HTMLInputElement;
      const targetId = target.id;
      if (isChecked) {
        return prevForms.filter((form) => form.form_link_id !== +targetId);
      } else {
        return [...prevForms, form];
      }
    });
  };

  return (
    <label className="inline-flex items-center gap-6 py-3 font-bold text-lg w-full">
      <input
        id={form.form_link_id.toString()}
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={(e) => changeHandler(e)}
        onClick={(e) => {
          clickHandler(e);
        }}
      />
      {isChecked ? <SvgCheckbox /> : <SvgCheckboxOff />}
      {form.title}
    </label>
  );
};

const ChooseFormsToAttachModal: FC<ChooseFormsToAttachModalProps> = ({
  cancelAttach,
  setLinkedForms,
  setServiceValues,
  serviceValues,
  setCreateFormModal,
  createFormModal,
}) => {
  const [formList, setFormList] = useState<FormLink[]>([]);
  const [selectedForms, setSelectedForms] = useState<FormLink[]>([]);
  const { user } = useContext(UserCtx);
  const { startLoading, stopLoading } = useLoading();
  const getForms = () => {
    startLoading();
    FormRequests.getFormLinks({
      coach_profile_id: user.coachProfile.profile_id,
    })
      .then((data: FormLink[]) => {
        setFormList(data);
      })
      .catch((ex) => console.log(ex))
      .finally(() => {
        stopLoading();
      });
  }

  useEffect(() => {
    getForms()
  }, [createFormModal]);

  return (
    <div className="fixed top-0 left-0 w-screen h-full flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div className="w-[620px] max-w-[90%] main-shadow rounded-[16px] p-[44px] flex flex-col items-center gap-[30px] max-h-[95vh] bg-white fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] overflow-y-auto">
        <div>
          <h3 className=" md:text-[22px] text-[18px] font-bold sm:text-[28px] sm:text-center md:text-left">
            Choose Forms To Attach
          </h3>
          <p className="text-center">Select up to 5 forms to attach</p>
        </div>
        <Button
                variant="utility"
                size="default"
                onClick={() => setCreateFormModal(true)}
              >
                + Create form link
              </Button>
        <div
          className={`w-[100%] h-[208px] overflow-scroll ${
            formList.length === 0 && "flex justify-center items-center"
          }`}
        >
          {formList.length !== 0 ? (
            formList.map((form) => (
              <CustomCheckbox
                key={form.form_link_id}
                form={form}
                setSelectedForms={setSelectedForms}
              />
            ))
          ) : (
            <p className="font-bold text-center">
              You dont have forms to attach. Please{" "}
              <Button
                variant="link"
                size="sm"
                className="text-[#038cfc] px-0 underline"
                onClick={() => setCreateFormModal(true)}
              >
                add
              </Button>{" "}
              desired forms first.
            </p>
          )}
        </div>

        <div className="flex w-[255px] justify-between">
          <Button
            variant="secondary"
            className="w-[100px]"
            onClick={() => cancelAttach(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              cancelAttach(false);
              setLinkedForms(selectedForms);
              setServiceValues({
                ...serviceValues,
                serviceLinkedForms: selectedForms,
              });
            }}
            className="w-[100px]"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseFormsToAttachModal;
