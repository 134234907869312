import type { SVGProps } from "react";
export const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={1.25}
      d="m13.5 6 4.5 5.5m0 0L13.5 17m4.5-5.5H6"
    />
  </svg>
);
