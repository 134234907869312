import type { SVGProps } from "react";
export const SvgShareScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.389 9.644c-.988 0-1.789.801-1.789 1.79v8.177c0 .988.801 1.789 1.789 1.789h12.267c.988 0 1.789-.801 1.789-1.789v-8.178c0-.988-.801-1.789-1.79-1.789z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 4.789C2 3.801 2.8 3 3.789 3h12.266c.989 0 1.79.8 1.79 1.789v5.622a.767.767 0 0 1-1.534 0V4.789a.256.256 0 0 0-.256-.256H3.79a.256.256 0 0 0-.256.256v8.177c0 .142.115.256.256.256h3.578a.767.767 0 0 1 0 1.533H3.789c-.988 0-1.789-.8-1.789-1.789z"
      clipRule="evenodd"
    />
  </svg>
);
