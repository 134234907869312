import React from "react";
type Props = {};

const LandingTalkToAHuman = (props: Props) => {
    return (
        <div className="max-w-[497px]">
            <h3 className="text-grayCharcoal text-[32px] md:text-[48px] font-bold mb-[6px]">
                Let Us Help Your Business Grow
            </h3>
            <p className="text-grayCharcoal font-bold text-[24px] mb-[24px]">
                Schedule a call with a Zoee Onboarding Specialist
            </p>
            <p className="mb-[24px]">
                Book a free 30 minute call with one of our onboarding specialists and get the support and guidance you need to get set up with ease and succeed with Zoee. Our team of experts will work with you to ensure a smooth and seamless onboarding experience, answer any questions you may have, and provide you with the resources you need to get the most out of Zoee.
            </p>
            <p className="mb-[24px]">Don't wait - schedule your onboarding call today and take the first step towards growing your business with Zoee!</p>
        </div>
    );
};

export default LandingTalkToAHuman;
