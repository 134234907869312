import type { SVGProps } from "react";
export const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.559 21.456c-3.672 0-6.81-.555-6.81-2.779s3.118-4.228 6.81-4.228c3.672 0 6.807 1.985 6.807 4.208s-3.115 2.8-6.807 2.8M9.559 11.278a4.364 4.364 0 1 0-4.364-4.365 4.35 4.35 0 0 0 4.333 4.365z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.801 10.079a3.289 3.289 0 0 0-.187-6.397M17.46 13.654c1.989 0 3.687 1.348 3.687 2.55 0 .71-.585 1.438-1.475 1.646"
    />
  </svg>
);
