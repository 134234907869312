import React, { type Dispatch, type SetStateAction } from "react"
import EmptyPromoCodeList from "./EmptyPromoCodeList"
import PromoCodeItem from "./PromoCodeItem"

interface Props {
  promoCodes: any[]
  setCurrentPromoCode: any
  setCreatePromoCode: Dispatch<SetStateAction<boolean>>
  setDeletePromoCode: Dispatch<SetStateAction<boolean>>
}

const PromoCodes: React.FC<Props> = (
  {
    promoCodes,
    setCurrentPromoCode,
    setCreatePromoCode,
    setDeletePromoCode
  },
) => {
  return (
    <>
      {promoCodes.length > 0
        ? (
          <div className="w-full mb-[50px] flex flex-col items-center rounded-[16px] gap-[8px]">
            {promoCodes?.map((promoCode:any) => (
              <div className="w-full" key={promoCode?.promo_id}>
                 <PromoCodeItem 
                   promoCode={promoCode} 
                   setDeletePromoCode={setDeletePromoCode}
                   setCurrentPromoCode={setCurrentPromoCode}
                 />
              </div>
            ))}
          </div>
        )
        : <EmptyPromoCodeList setCreatePromoCode={setCreatePromoCode} />
      }
    </>
  );
};

export default PromoCodes;
