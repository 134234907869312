import React from "react";
import ServiceCard from "./ServiceCard";

interface Props {
    services: any;
    setUpdateService: any;
    getServices: any;
    showComponent: any;
    setShowArchivedServices: any;
    setIsGroupService: any;
}

const ArchivedServices: React.FC<Props> = ({ services, setUpdateService, getServices, showComponent, setShowArchivedServices, setIsGroupService }) => {
    const archivedServices = services.filter(
        (service: any) => service.service_details.status === "archived"
    );

    return (
        <>
            {archivedServices.length > 0 ? (
                <div className="flex flex-wrap justify-center md:justify-start w-full gap-[24px] last:mb-[80px]">
                    {archivedServices.map((service: any, idx: number) => (
                        <div key={idx}>
                            <ServiceCard
                                service={service}
                                setUpdateService={setUpdateService}
                                getServices={getServices}
                                showComponent={showComponent}
                                setShowArchivedServices={setShowArchivedServices}
                                setIsGroupService={setIsGroupService}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div
                    className="w-full flex items-center justify-center h-[399px] border border-1
                    border-grayMist rounded-[16px] bg-[#f8f8f8]"
                >
                    <div className="flex flex-col items-center gap-[12px]">
                        <h2 className="font-bold text-[22px]">No Archived Services</h2>
                    </div>
                </div>
            )}
        </>
    );
};

export default ArchivedServices;
