import type { SVGProps } from "react";
export const SvgCheckboxOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.25"
      y="0.25"
      width="23.5"
      height="23.5"
      rx="7.75"
      stroke="#d1D9E0"
      strokeWidth={0.5}
    />
  </svg>
);
