import React from "react"
import AttachService from "../../components/sessions/historical/AttachService"
import PastSessions from "../../components/sessions/historical/PastSessions"
import DetailedPastSession from "../../components/sessions/historical/DetailedPastSession"
import { SessionContext } from "./context/sessionContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  selectIsConnectedToRoom,
  useHMSActions,
  useHMSStore,
  useHMSNotifications,
} from "@100mslive/react-sdk"
import ActiveSessionLanding from "../../components/sessions/ActiveSessionLanding"
import Conference from "../../components/sessions/Conference"
import SessionTips from "../../components/sessions/historical/SessionTips"
import useLoading from "../../hooks/useLoading"
import { SvgInformationCircle } from "../../components/icons/"
import { Button } from "../../components/ui/button"

interface Props {}

const NewCoachSessions: React.FC<Props> = () => {
  const { setPopupNotification, renderSuccess, renderError } =
    React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const hmsActions = useHMSActions()
  const [viewType, setViewType] = React.useState("speaker")
  const [mobileView, setMobileView] = React.useState(false)
  const [fullScreen, setFullScreen] = React.useState(false)
  const [showActiveSessionTab, setShowActiveSessionTab] =
    React.useState<boolean>(true)
  const [showPastSessionsTab, setShowPastSessionsTab] =
    React.useState<boolean>(false)
  const [detailedPastSession, setDetailedPastSession] = React.useState<any>({
    show: false,
    session: {},
  })
  const [showAttachService, setShowAttachService] =
    React.useState<boolean>(false)
  const [serviceChangeType, setServiceChangeType] = React.useState<string>("")
  const [currentSpeaker, setCurrentSpeaker] = React.useState<any>(null)
  const [inWaitingRoom, setInWaitingRoom] = React.useState<boolean>(false)
  const [roomId, setRoomId] = React.useState<string>("")
  const [sessionListLoading, setSessionListLoading] =
    React.useState<boolean>(false)
    const [sendReview, setSendReview] =
    React.useState<boolean>(false)
  const [roomCode, setRoomCode] = React.useState<string>("");
  const [msUserToken, setMsUserToken] = React.useState<string>("")
  const notification = useHMSNotifications()

  // useState for video call instructions for user
  const [instructionModal, setInstructionModal] = React.useState(false)

  // Modal with video best practice instructions
  const handleInstructionModal = () => {
    setInstructionModal(!instructionModal)
  }

  const handleShowActiveSession = () => {
    setShowActiveSessionTab(true)
    setShowPastSessionsTab(false)
    setInstructionModal(false)
  }

  const handleShowPastSessions = () => {
    setShowActiveSessionTab(false)
    setShowPastSessionsTab(true)
    setInstructionModal(false)
  }

  const closePopup = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    })
  }

  const acceptAutoplay = async () => {
    startLoading()
    await hmsActions
      .unblockAudio()
      .then(() => {
        stopLoading()
        setPopupNotification({
          show: false,
          title: "",
          message: "",
          callback: null,
        })
        renderSuccess("Unblocked audio")
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const showMessage = (type: string, message: string, code: any) => {
    if (type === "error") {
      console.log(`100ms error code ${code}`)
      if (code === 3001) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        })
      } else if (code === 3002) {
        setPopupNotification({
          show: true,
          title: `Capture Device Not Available`,
          message:
            "Please verify that your audio/video capture device is connected.",
        })
      } else if (code === 3003) {
        setPopupNotification({
          show: true,
          title: `Capture Device Is In Use`,
          message:
            "Capture device is in use by another application. Please fix and try again.",
        })
      } else if (code === 3008) {
        setPopupNotification({
          show: true,
          title: "Autoplay blocked",
          message:
            "The browser is requiring this specific page interaction to enable audio. Click 'Ok' to proceed",
          callback: acceptAutoplay,
        })
      } else if (code === 3009) {
      } else if (code === 3010) {
        setPopupNotification({
          show: true,
          title: `Problem with audio output device`,
          message: "Try using an external device to output audio.",
        })
      } else if (code === 3011) {
        setPopupNotification({
          show: true,
          title: `Browser Permissions Error`,
          message:
            "Your web browser does not have permission to share your full screen. Click the links for instructions on enabling permissions.",
        })
      } else {
        renderError(message)
      }
    } else if (type === "notify") {
      renderSuccess(message)
    }
  }

  const sessionContextValues = {
    viewType,
    setViewType,
    mobileView,
    setMobileView,
    currentSpeaker,
    setCurrentSpeaker,
    fullScreen,
    setFullScreen,
    inWaitingRoom,
    setInWaitingRoom,
    roomId,
    setRoomId,
    msUserToken,
    setMsUserToken,
    sessionListLoading,
    setSessionListLoading,
    roomCode,
    setRoomCode,
    sendReview,
    setSendReview,
  }

  React.useEffect(() => {
    if (window.innerWidth < 768) {
      setMobileView(true)
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileView(true)
      } else {
        setMobileView(false)
      }
    }

    window.addEventListener("resize", handleResize)
  }, [])

  React.useEffect(() => {
    window.onunload = () => {
      if (isConnected) {
        hmsActions.leave()
      }
    }
  }, [hmsActions, isConnected])

  React.useEffect(() => {
    if (!notification) {
      return
    }
    switch (notification.type) {
      case "ROOM_ENDED":
        setPopupNotification({
          show: true,
          callback: closePopup,
          title: notification.data.reason,
          message:
            "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
        })
        break
      case "PEER_JOINED":
        showMessage("notify", `${notification.data.name} joined`, null)
        break
      case "PEER_LEFT":
        showMessage("notify", `${notification.data.name} left`, null)
        break
      case "ERROR":
        showMessage("error", notification.data.message, notification.data.code)
        break
    }
  }, [notification])

  // uncomment below to enable popup notifications for "YOU'RE EARLY etc..."

  // const getLocalDate = (datetime:any) => {
  //     return moment.utc(datetime).tz(user?.timezone?.value).format("MMMM DD, YYYY")
  // }

  // const getLocalTime = (datetime:any) => {
  //     return moment.utc(datetime).tz(user?.timezone?.value).format("hh:MM A")
  // }

  // React.useEffect(() => {
  //     if(location?.state){
  //         const nowUtc = moment.utc().format();
  //         const start = moment(location.state.meetingStartTime).format();
  //         const end = moment(location.state.meetingEndTime).format();
  //         console.log(nowUtc);

  //         console.log(start);

  //         console.log(nowUtc < start);

  //         if(nowUtc < start){
  //             setPopupNotification({
  //                 show: true,
  //                 title: "You're early!",
  //                 message: `${location.state.meetingName} starts on ${getLocalDate(location.state.meetingStartTime)}
  //                 at ${getLocalTime(location.state.meetingStartTime)}.`,
  //                 callback: null
  //             })
  //         } else if(nowUtc > start && nowUtc < end){
  //             setPopupNotification({
  //                 show: true,
  //                 title: "Your meeting is in progress!",
  //                 message: `${location.state.meetingName} started on ${getLocalDate(location.state.meetingStartTime)}
  //                 at ${getLocalTime(location.state.meetingStartTime)}.`,
  //                 callback: null
  //             })
  //         } else if(nowUtc > end){
  //             setPopupNotification({
  //                 show: true,
  //                 title: "This meeting has ended",
  //                 message: `${location.state.meetingName} ended on ${getLocalDate(location.state.meetingEndTime)}
  //                 at ${getLocalTime(location.state.meetingEndTime)}.`,
  //                 callback: null
  //             })
  //         }
  //     }
  // }, [])

  return (
    <SessionContext.Provider value={sessionContextValues}>
      {detailedPastSession.show && (
        <DetailedPastSession
          detailedPastSession={detailedPastSession}
          setDetailedPastSession={setDetailedPastSession}
          setShowAttachService={setShowAttachService}
          setServiceChangeType={setServiceChangeType}
        />
      )}
      {showAttachService && (
        <AttachService
          setShowAttachService={setShowAttachService}
          setDetailedPastSession={setDetailedPastSession}
          detailedPastSession={detailedPastSession}
          setServiceChangeType={setServiceChangeType}
          serviceChangeType={serviceChangeType}
        />
      )}
      {!detailedPastSession.show && !showAttachService && (
        <div
          className={`mx-auto ${
            !isConnected ? "pt-[28px]" : ""
          } md:pt-0 w-full md:w-[1080px]`}
        >
          {/* Replace two lines below with line below to enable waiting room again */}
          {/* {(!isConnected && !inWaitingRoom) && ( */}
          {!isConnected && !inWaitingRoom && (
            <div className="flex-wrap justify-center md:justify-start flex w-full gap-[12px] mb-[32px] font-bold text-base">
              <Button
                variant={showActiveSessionTab ? "utility" : "ghost"}
                onClick={handleShowActiveSession}
              >
                Active Sessions
              </Button>
              <Button
                variant={showPastSessionsTab ? "utility" : "ghost"}
                onClick={handleShowPastSessions}
              >
                Past Sessions
              </Button>
              <Button
                variant="ghost"
                onClick={handleInstructionModal}
                className="md:ml-auto"
              >
                <SvgInformationCircle />
                Video Session Tips
              </Button>
            </div>
          )}

          <div className="w-full">
            {showActiveSessionTab && (
              <>
                {isConnected ? (
                  <Conference />
                ) : (
                  <>
                    {/* uncomment below to enable waiting room */}
                    {/* {inWaitingRoom ? (
                                                <SessionWaitingRoom />
                                            ) : (
                                                <ActiveSessionLanding />
                                            )} */}
                    <ActiveSessionLanding />
                  </>
                )}
              </>
            )}
            {showPastSessionsTab && (
              <PastSessions
                setDetailedPastSession={setDetailedPastSession}
              />
            )}
            {instructionModal && (
              <SessionTips setInstructionModal={setInstructionModal} />
            )}
          </div>
        </div>
      )}
    </SessionContext.Provider>
  )
}

export default NewCoachSessions
