import type { SVGProps } from "react";
export const SvgVideoOffSolid = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.727 11.545a.83.83 0 0 0 .636.273c.091 0 .273 0 .364-.09q.546-.274.546-.819V9.364A3.26 3.26 0 0 0 12 6.09h-1.546c-.363 0-.727.182-.818.545-.182.364-.09.728.182 1zM15 14.273l-5.09-5-5.91-6a.88.88 0 0 0-1.273 0 .88.88 0 0 0 0 1.272l1.637 1.637C3 6.636 2 7.818 2 9.364v6.545a3.26 3.26 0 0 0 3.273 3.273H12c1.454 0 2.636-.91 3.09-2.182l4 4a.98.98 0 0 0 .637.273c.182 0 .455-.091.637-.273a.88.88 0 0 0 0-1.273zM21.09 7.182c-.636-.364-1.454-.455-2.09-.091 0 0-.091 0-.091.09l-2.364 1.637c-.273.182-.363.455-.363.727v3.091c0 .546.181 1.091.636 1.455l2.727 2.727c.273.273.818.455 1.182.273.273-.091.546-.182.727-.364q.546-.545.546-1.363V8.727c0-.636-.364-1.182-.91-1.545"
    />
  </svg>
);
