import React, { FC } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog"
import { Button } from "../../components/ui/button"
import { SvgClose } from "../icons"
import { useNavigate } from "react-router-dom"

interface ConnectPayPalProps {
  connectPayPalRequest: boolean
  setConnectPayPalRequest: (show: boolean) => void
}

const ConnectPayPal: FC<ConnectPayPalProps> = ({
  connectPayPalRequest,
  setConnectPayPalRequest,
}) => {
  const navigate = useNavigate()
  const navigateOnClose = "/coach/client-payments"

  const onModalClose = () => {
    setConnectPayPalRequest(false)
    navigate(navigateOnClose)
  }

  return (
    <Dialog
      open={connectPayPalRequest}
      onOpenChange={onModalClose}
    >
      <DialogContent className="text-[16px]">
        <button
          onClick={onModalClose}
          className="absolute top-6 right-6 text-gray-500 hover:text-gray-800"
          aria-label="Close"
        >
          <SvgClose />
        </button>

        {/* Modal Content */}
        <div className="text-center w-16 mx-auto rounded-xl bg-gradient-to-br from-[#3886F7] to-[#892FF4] text-white font-bold">
          NEW
        </div>
        <DialogHeader className="flex gap-6">
          <DialogTitle className="text-center font-bold leading-6">
            Zoee has switched from Stripe to PayPal for better payment
            processing!
          </DialogTitle>
          <DialogDescription className="text-[16px]">
            <span className="font-bold text-blurple">Action Required:</span>{" "}
            This update requires you to link your bank account through PayPal.
            Please connect your PayPal account to continue accepting payments.
          </DialogDescription>
        </DialogHeader>
        <Button
          size="lg"
          onClick={onModalClose}
        >
          Connect Paypal
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default ConnectPayPal
