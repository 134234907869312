import { NavLink, NavLinkProps } from "react-router-dom"
import { cn } from "../../utils/cn"
import { Badge } from "../ui/badge"

interface Props extends NavLinkProps {
  children: React.ReactNode
  notifications?: number
}

export function NavLinkItem({
  children,
  to,
  notifications = 0,
  ...rest
}: Props) {
  return (
    <NavLink
      {...rest}
      to={to}
      className={({ isActive }) =>
        cn("flex items-center gap-2 rounded-md px-3.5 py-2", {
          "bg-hoverDark font-bold text-black": isActive,
          "text-grayCharcoal hover:bg-hover duration-200": !isActive,
        })
      }
    >
      {children}

      {notifications > 0 && <Badge variant="alert">{notifications}</Badge>}
    </NavLink>
  )
}
