import type { SVGProps } from "react";
export const SvgCalendarCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#DEC8FB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2054 11.3843L18.2059 11.7591C19.5833 11.8671 20.4931 12.8056 20.4946 14.2449L20.5 18.4578C20.502 20.027 19.5161 20.9925 17.9359 20.995L14.0759 21C12.5056 21.002 11.5074 20.0135 11.5054 18.4398L11.5 14.2764C11.498 12.8276 12.3758 11.8916 13.7531 11.7651L13.7526 11.3903C13.7521 11.1704 13.915 11.005 14.1322 11.005C14.3494 11.0045 14.5123 11.1694 14.5128 11.3893L14.5133 11.7391L17.4457 11.7351L17.4452 11.3853C17.4447 11.1654 17.6076 11.0005 17.8248 11C18.0371 10.9995 18.205 11.1644 18.2054 11.3843ZM12.2608 14.4308L19.7348 14.4208V14.2459C19.7136 13.1715 19.1745 12.6077 18.207 12.5238L18.2074 12.9086C18.2074 13.1235 18.0401 13.2939 17.8278 13.2939C17.6106 13.2944 17.4472 13.1245 17.4472 12.9096L17.4467 12.5048L14.5143 12.5088L14.5148 12.9131C14.5148 13.1285 14.3524 13.2984 14.1352 13.2984C13.918 13.2989 13.7546 13.1295 13.7546 12.9141L13.7541 12.5293C12.7915 12.6257 12.2588 13.1914 12.2603 14.2754L12.2608 14.4308Z"
      fill="black"
    />
  </svg>
);
