import type { SVGProps } from "react";
export const SvgInformationCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="12"
      cy="12"
      r="8"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
    />
    <path
      d="M13.7079 10.075L10.6839 10.454L10.5759 10.956L11.1709 11.064C11.5579 11.157 11.6349 11.296 11.5509 11.683L10.5759 16.26C10.3209 17.443 10.7159 18 11.6429 18C12.3629 18 13.1969 17.668 13.5759 17.211L13.6919 16.662C13.4289 16.894 13.0419 16.987 12.7869 16.987C12.4239 16.987 12.2929 16.732 12.3849 16.283L13.7079 10.075ZM13.7989 7.32C13.7989 7.67009 13.6599 8.00583 13.4123 8.25338C13.1648 8.50093 12.829 8.64 12.4789 8.64C12.1289 8.64 11.7931 8.50093 11.5456 8.25338C11.298 8.00583 11.1589 7.67009 11.1589 7.32C11.1589 6.96991 11.298 6.63417 11.5456 6.38662C11.7931 6.13907 12.1289 6 12.4789 6C12.829 6 13.1648 6.13907 13.4123 6.38662C13.6599 6.63417 13.7989 6.96991 13.7989 7.32Z"
      fill="currentColor"
    />
  </svg>
);
