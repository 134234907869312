import React, { useEffect, useState } from "react"
import BentoBoxFive from "./BentoBoxFive"
import BentoBoxFour from "./BentoBoxFour"
import BentoBoxThree from "./BentoBoxThree"
import BentoSelectModal from "./BentoSelectModal"
import classes from "./PublicOverviewBento.module.css"
import ListSocialMedia from "./ListSocialMedia"
import OverviewServices from "./OverviewServices"
import PrivateProfileScheduler from "./PrivateProfileScheduler"
import useLoading from "../../hooks/useLoading"
import { BlogPost } from "../../models/blog.interface"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"

interface Props {
  renderServices: () => void
}

export default function OverviewBento({ renderServices }: Props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { coachPublicProfileData, services, setLoadingBento } =
    React.useContext(CoachPrivateProfileContext)
  const backgroundColor =
    coachPublicProfileData?.profile_information?.profileColor
  const [, setLoadingPosts] = React.useState<boolean>(false)
  const [post, setPost] = React.useState<BlogPost | null>(null)
  const [allPosts, setAllPosts] = React.useState<BlogPost[]>([])
  const [editBento, setEditBento] = useState<boolean | undefined>(undefined)
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const [bentoBoxSelection, setBentoBoxSelection] = React.useState<number>(0)
  const { startLoading, stopLoading } = useLoading()

  const itemStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 0 20px 5px rgba(150, 150, 150, 0.2)",
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const getCoachBlogPosts = async () => {
    if (user) {
      setLoadingPosts(true)
      await ProfileRequests.getCoachBlogPosts({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          const firstPost = data.blog_posts[0]
          setPost(firstPost)
          setAllPosts(data.blog_posts)
          setLoadingPosts(false)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          setLoadingPosts(false)
        })
    }
  }

  type selectionType = "quote" | "blog" | "video" | "testimonials" | "image"
  const updateBento = (
    bentoSpot: number | null,
    selection: selectionType,
    contentId?: number,
    content?: string,
    url?: string
  ) => {
    startLoading()
    setLoadingBento(true)
    const bentoBoxUpdateData = {
      id: parseInt(coachPublicProfileData?.coach_public_profile_id),
      bento_box_three: JSON.stringify(coachPublicProfileData.bento_box_three),
      bento_box_four: JSON.stringify(coachPublicProfileData.bento_box_four),
      bento_box_five: JSON.stringify(coachPublicProfileData.bento_box_five),
    }

    if (bentoSpot === 3) {
      bentoBoxUpdateData.bento_box_three = JSON.stringify({
        type: selection,
        data: { type_id: contentId, img_url: url, quote: content },
      })
      // setCoachPublicProfileData({
      //   ...coachPublicProfileData,
      //   bento_box_three: { type: selection, data: { type_id: contentId, img_url: url, quote: content } },
      // })
    } else if (bentoSpot === 4) {
      bentoBoxUpdateData.bento_box_four = JSON.stringify({
        type: selection,
        data: { type_id: contentId, img_url: url, quote: content },
      })
      // setCoachPublicProfileData({
      //   ...coachPublicProfileData,
      //   bento_box_four: { type: selection, data: { type_id: contentId, img_url: url, quote: content } },
      // })
    } else if (bentoSpot === 5) {
      bentoBoxUpdateData.bento_box_five = JSON.stringify({
        type: selection,
        data: { type_id: contentId, img_url: url, quote: content },
      })
      // setCoachPublicProfileData({
      //   ...coachPublicProfileData,
      //   bento_box_five: { type: selection, data: { type_id: contentId, img_url: url, quote: content } },
      // })
    }

    ProfileRequests.editBentoBox(bentoBoxUpdateData)
      .then(() => {
        renderSuccess("Box updated successfully!")
      })
      .catch((ex: any) => {
        stopLoading()
        console.log(ex)
        renderError(ex.data?.message || "An error occurred")
      })
      .finally(() => {
        stopLoading()
        setLoadingBento(false)
      })
  }

  React.useEffect(() => {
    getCoachBlogPosts()
  }, [coachPublicProfileData])

  return (
    <>
      <div className={classes.container}>
        {windowWidth >= 1280 && (
          <div
            style={{ ...itemStyle, gridArea: "cons" }}
            className="w-[296px] h-[601px]"
          >
            <PrivateProfileScheduler
              consultation={services.find(
                (service: any) =>
                  service.service_details.type === "consultation"
              )}
            />
          </div>
        )}
        <div
          style={{ ...itemStyle, gridArea: "serv" }}
          className="w-[300px] md:w-[624px] h-[280px]"
        >
          <OverviewServices
            renderServices={renderServices}
            services={services}
            coachColor={backgroundColor}
          />
        </div>
        {/* BentoBoxThree Render options:  Video | image/quotes |  color block*/}
        <div
          style={{ ...itemStyle, gridArea: "vide" }}
          className="w-[300px] h-[460px]"
        >
          <BentoBoxThree
            endpointSlug={coachPublicProfileData.endpoint_slug}
            setEditBento={setEditBento}
            editBento={editBento}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
            setBentoBoxSelection={setBentoBoxSelection}
          />
        </div>
        {/* BentoBoxFour Render options:  Blog | image/quotes |  color block*/}
        <div
          style={{ ...itemStyle, gridArea: "blog" }}
          className="w-[300px] h-[300px]"
        >
          <BentoBoxFour
            post={post as BlogPost | null}
            allPosts={allPosts}
            blogTag
            isEditable
            endpointSlug={coachPublicProfileData.endpoint_slug}
            setEditBento={setEditBento}
            editBento={editBento}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
            setBentoBoxSelection={setBentoBoxSelection}
          />
        </div>
        {/* BentoBoxFive Render options:  Testimonials | image/quotes |  color block*/}
        <div
          style={{ ...itemStyle, gridArea: "test" }}
          className="w-[300px] h-[300px]"
        >
          <BentoBoxFive
            endpointSlug={coachPublicProfileData.endpoint_slug}
            setEditBento={setEditBento}
            editBento={editBento}
            coachData={coachPublicProfileData}
            coachColor={backgroundColor}
            setBentoBoxSelection={setBentoBoxSelection}
          />
        </div>

        <div
          style={{ ...itemStyle, gridArea: "soci" }}
          className="w-[300px] h-[120px]"
        >
          <ListSocialMedia
            isEditable
            bgColor={backgroundColor}
            coachPublicProfileId={
              coachPublicProfileData?.coach_public_profile_id
            }
          />
        </div>
      </div>

      {editBento && (
        <>
          <BentoSelectModal
            setEditBento={setEditBento}
            bentoBoxSelection={bentoBoxSelection}
            editBento={editBento}
            updateBento={updateBento}
            post={post as BlogPost | null}
            allPosts={allPosts}
            coachData={coachPublicProfileData}
          />
        </>
      )}
    </>
  )
}
