import React from "react"
import { SvgArrowRight } from "../icons"
import { Button } from "../ui/button"
import PlaceholderImage from "./image 53.png"
import { useNavigate } from "react-router-dom"

const IINLanding: React.FC<{ pathname: string }> = ({ pathname }) => {
  const navigate = useNavigate()

  const handleCreateAccount = () => {
    navigate("/registration", { state: { from: pathname } })
  }
  return (
    <>
      <div
        className="md:pt-[96px] pt-[48px] md:pb-[96px] pb-[56px] px-6 md:px-[120px] flex flex-col items-center justify-center overflow-hidden"
        style={{
          fontFamily: "BeausiteClassic",
        }}
      >
        <div className="w-full flex flex-col md:flex-row items-center gap-20 justify-between">
          {/* Left Content */}
          <div className="md:w-[720px] flex flex-col gap-4 md:gap-6">
            <div
              className="flex flex-col items-start"
            >
              <h1
                className="text-[#0D0D0D] mb-1 font-bold text-[64px] leading-tight tracking-[-0.06em]"
              >
                Business Blueprint <br />
                <span className="font-normal italic">for</span> Health Coaches
              </h1>
              <p
                className="text-[#4750F5] font-medium text-[20px] leading-[24px]"
                style={{
                  maxWidth: "550px",
                  whiteSpace: "normal",
                  fontFamily: "BeausiteClassic",
                  letterSpacing: "-0.07em",
                  textAlign: "left",
                }}
              >
                Your Complete Business Solution Exclusively for IIN Alumni
              </p>
            </div>

            <div className="flex flex-col gap-4 mt-4">
              <p
                style={{ fontSize: "16px", color: "#0D0D0D" }}
                className="font-light leading-relaxed text-left"
              >
                Looking for support in launching, relaunching, or growing your
                coaching business? IIN and Zoee are proudly partnering to help
                IIN Health Coaches kickstart and accelerate their coaching
                careers and generate income with less effort.
              </p>
              <p
                style={{ fontSize: "16px", color: "#0D0D0D" }}
                className="font-light leading-relaxed text-left"
              >
                Expertly crafted, this transformational program combines IIN's
                tried-and-true business methodology with Zoee’s cutting-edge
                all-in-one coaching platform. Build or scale your business in
                just 3 months using the Health Coach Business Blueprint — a
                step-by-step customizable strategy for achieving your career
                goals with clarity, ease, and authenticity.
              </p>
              <p
                style={{ fontSize: "16px", color: "#0D0D0D" }}
                className="font-light leading-relaxed text-left"
              >
                Limited spots available.{" "}
                <span className="text-[#4750F5] font-bold">
                  Register by JANUARY 10, 2025
                </span>{" "}
                to secure your seat in this exclusive program designed by IIN
                coaches, for IIN coaches.
              </p>
              <p
                style={{ fontSize: "16px", color: "#0D0D0D" }}
                className="font-light leading-relaxed text-left"
              >
                Program dates:{" "}
                <span className="font-bold">
                  JANUARY 13, 2025 – APRIL 13, 2025
                </span>
              </p>
              <div className="flex justify-center md:justify-start">
                <Button
                  variant="default"
                  onClick={handleCreateAccount}
                  className="px-6 py-3 bg-[#4750F5] text-white font-bold rounded-lg flex items-center gap-2"
                >
                  REGISTER NOW <SvgArrowRight />
                </Button>
              </div>
            </div>
          </div>

          {/* Image Section */}
          <div className="w-full md:w-fit flex justify-center md:justify-end">
            <img
              className="max-w-full rounded-md shadow-lg"
              src={PlaceholderImage}
              alt="Program Visual"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default IINLanding
