import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react"
import CheckoutSuccessPopup from "./CheckoutSuccessPopup"
import PayPalCardFields from "../subscription/PayPalCardFields"
import useManageServices, { ServiceType } from "./hooks/useManageServices"
import ServiceCardSelector from "./ServiceCardSelector"
import { CardSelector } from "../subscription"
import { UserCtx } from "../../context/userContext"
import { PayPalButton } from "../subscription/PayPalButton"
import { ServiceRequests } from "../../api/app.service"
import { ClientPaymentRequests } from "../../api/app.service"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { PaymentRequests } from "../../api/app.service"
import { Button } from "../ui/button"
import Loader from "../ui/loader"
import { getPrice } from "../../utils/services/get-price"
import FreeCheckoutSuccessPopup from "./FreeCheckoutSuccessPopup"
interface Window {
  paypal: {
    Buttons: (options: any) => {
      render: (container: HTMLElement) => void
    }
  }
}

interface Props {
  meetingDetails: {
    startTime: Date
    endTime: Date
  }
  appliedDiscount: {
    amount: number
    promo_code: string
  }
  createSetupIntent: any
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  service: any
  isGroupService: boolean
  availabilities: any
  activeContact: any
  activeService: any
  paymentMethods: any
  selectedPaymentMethod: any
  setSelectedPaymentMethod: any
  merchantId: any
  setMerchantId: any
  checkoutSuccessPopup: any
  setCheckoutSuccessPopup: any
  contact: any
  activeContactRef: any
  selectedPlanFromChild: any
  validatePromoCode: any
}

export const PayPalCheckoutServiceComponent: React.FC<Props> = ({
  meetingDetails,
  appliedDiscount,
  createSetupIntent,
  isTermsAgreed,
  setIsTermsAgreed,
  service,
  isGroupService,
  availabilities,
  activeContact,
  activeService,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  merchantId,
  setMerchantId,
  contact,
  selectedPlanFromChild,
  validatePromoCode,
}) => {
  const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID

  const { user } = useContext(UserCtx)
  const { getServiceObject } = useManageServices()
  const { renderError } = useContext(CommonFunctionCtx)
  const [loading, setLoading] = useState(false)
  const [isSdkReady, setIsSdkReady] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [hasMounted, setHasMounted] = useState(false)
  const [sdkLoaded, setSdkLoaded] = useState(false)
  const [paymentMethods, setPaymentMethods] = React.useState<any>(null)
  const [useSavedCard, setUseSavedCard] = useState(false)
  const [checkoutSuccessPopup, setCheckoutSuccessPopup] = useState(false)
  const [serviceType, setServiceType] = React.useState<ServiceType>(
    isGroupService ? "individual" : "consultation"
  )
  const [promoDiscount, setPromoDiscount] = useState<number | null>(null)
  const [serviceValues, setServiceValues] = useState<any>({
    availability_rule_id: availabilities
      ? availabilities?.find((rule: any) => rule?.is_default).id
      : "",
    isGroupService: isGroupService,
    color: "bg-blurple",
    title: "",
    description: "",
    feature1: "",
    feature2: "",
    feature3: "",
    sessionLength: { minutes: 30 },
    sessionCost: 50,
    currency: "USD",
    symbol: "\\u0024",
    isFree: false,
    allowPromo: true,
    package: {
      packageSessions: 2,
      packageBillingType: "bulk",
      packageCost: 100,
      installmentIncrements: [{ time: 1, type: "months" }],
      upFrontPercentage: 50,
    },
    recurring: {
      periodCost: 100,
      recurringFrequency: "monthly",
      sessionsPerPeriod: 1,
      unlimited: false,
    },
    expiration: {
      expireTime: 1,
      expireType: "months",
      expires: serviceType === "recurring" ? true : false,
    },
    enabled: true,
    profileServiceVisible: true,
    profileCostVisible: false,
    marketplaceServiceVisible: false,
    marketplaceCostVisible: false,
    image: { exists: false, base64: "", base64extension: "" },
    group: {
      isFree: false,
      // meetingDates: [moment().hour(16).minute(0).add(14, "days").toDate()],
      weeklyDayTimes: [
        {
          day: { value: "monday", label: "Monday" },
          time: { value: "07:00", label: "7:00 AM" },
        },
      ],
      // enrollByDate: moment().hour(16).minute(0).add(7, "days").toDate(),
      maximumClients: 2,
      groupChat: true,
    },
    serviceLinkedForms: [],
  })

  const user_id = parseInt(user.user_id)
  const isCheckedRef = useRef(false)
  const activeServiceRef = useRef(activeService)
  const useSavedCardRef = useRef(useSavedCard)
  const activeMerchantIdRef = useRef(merchantId)
  const appliedDiscountRef = useRef(appliedDiscount)

  const activeContactRef = useRef<any>(null)

  useEffect(() => {
    activeServiceRef.current = activeService
  }, [activeService])

  useEffect(() => {
    activeContactRef.current = activeContact
  }, [activeContact])

  useEffect(() => {
    appliedDiscountRef.current = appliedDiscount
  }, [appliedDiscount])

  useEffect(() => {
    if (window.paypal) {
      setSdkLoaded(true)
    } else {
      console.log("sdk not loading")
    }
  }, [])

  const serviceValuesRef = useRef(serviceValues)

  useEffect(() => {
    serviceValuesRef.current = serviceValues
  }, [serviceValues])

  useEffect(() => {
    useSavedCardRef.current = useSavedCard
  }, [useSavedCard])

  useEffect(() => {
    if (activeService?.service_details?.billing?.amount) {
      const rawAmount = activeService.service_details.billing.amount
      const sanitizedAmount = rawAmount.replace(/[^0-9.]/g, "")
      const parsedAmount = parseFloat(sanitizedAmount)

      if (!isNaN(parsedAmount)) {
        let calculatedPrice = parsedAmount

        if (appliedDiscountRef?.current?.amount) {
          const discount = appliedDiscountRef.current.amount 

          if (!isNaN(discount)) {
            calculatedPrice = parsedAmount - discount
          }
        }

        setServiceValues((prevValues: any) => ({
          ...prevValues,
          sessionCost: calculatedPrice, 
        }))

        const finalPrice = getPrice(
          calculatedPrice,
          activeService.service_details.symbol
        )
      } else {
        console.error("Invalid amount:", rawAmount)
      }
    }
  }, [activeService, appliedDiscountRef, validatePromoCode])

  const createOrder = useCallback(
    (data: any, source: "paypal" | "card") => {
      const serviceObject = getServiceObject(
        serviceType,
        serviceValuesRef.current
      )
      const service = activeServiceRef.current
      const contactsss = activeContactRef.current
      const saveCard = isCheckedRef.current
      const useSavedCard = useSavedCardRef.current
      const promo = appliedDiscountRef.current.amount
      return ServiceRequests.createPurchaseService({
        user_id,
        coach_user_id: contactsss?.user_id,
        client_profile_id: user.activeProfileId,
        coach_profile_id: contactsss?.profile_id,
        payment_method_id: useSavedCard ? selectedPaymentMethod?.id : "",
        service: { ...serviceObject },
        stripe_product_id: service.stripe_product_id,
        source: source,
        save_for_future: saveCard,
        user_environment: "",
        entered_promo_code: promo,
      })
        .then((response: any) => {
          console.log(response)
          return response.data
        })
        .then((order: any) => {
          if (order.id) {
            return order.id
          } else if (order.data.status === "COMPLETED") {
            return order
          }
        })
    },
    [useSavedCard, selectedPaymentMethod]
  )

  // Finalize the transaction after payer approval
  const onApprove = useCallback(
    async (data: any, source: "paypal" | "card") => {
      const service = activeServiceRef.current
      const getContact = activeContactRef.current
      const promo = appliedDiscountRef.current.amount
      const serviceObject = getServiceObject(
        serviceType,
        serviceValuesRef.current
      )
      try {
        const response = await ServiceRequests.completePurchaseService({
          user_id,
          coach_user_id: getContact?.user_id,
          client_profile_id: user.activeProfileId,
          coach_profile_id: getContact?.profile_id,
          payment_method_id: useSavedCard ? selectedPaymentMethod?.id : "",
          service: serviceObject,
          stripe_product_id: service.stripe_product_id,
          source: source,
          order: data.orderID || data,
          user_environment: "",
          entered_promo_code: promo,
        })
        const orderData = response
        setCheckoutSuccessPopup(true)
      } catch (error) {
        return console.log("error in approve:", error)
      }
    },
    [useSavedCard, selectedPaymentMethod]
  )

  useEffect(() => {
    isCheckedRef.current = isChecked
  }, [isChecked])

  useEffect(() => {
    if (clientID && merchantId) {
      const loadPayPalSDK = () => {
        if (document.getElementById("paypal-sdk")) {
          console.log("PayPal SDK already loaded.")
          setIsSdkReady(true)
          return
        }

        const script = document.createElement("script")
        script.id = "paypal-sdk"
        script.src = `https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=${clientID}&merchant-id=${merchantId}&currency=USD&intent=capture&disable-funding=paylater`
        script.async = true

        script.onload = () => {
          console.log("PayPal SDK loaded successfully.")
          setIsSdkReady(true)
        }

        script.onerror = (err) => {
          console.error("Failed to load PayPal SDK:", err)
        }

        document.body.appendChild(script)
      }

      loadPayPalSDK()
    }
  }, [merchantId])

  useEffect(() => {
    getPaymentMethods()
  }, [])

  const getPaymentMethods = async () => {
    if (user) {
      try {
        const data = await PaymentRequests.getSavedPaymentMethods({
          user_id: parseInt(user.user_id),
        })
        setPaymentMethods(data?.data?.payment_tokens || [])
        if (data?.payment_methods?.length > 0) {
          setSelectedPaymentMethod(data?.data?.payment_tokens[0].id)
        } else {
          setSelectedPaymentMethod({})
        }
      } catch (ex: any) {
        console.error("Error fetching or updating payment methods:", ex)
        renderError(
          ex.response?.data?.message || "Failed to fetch payment methods"
        )
      } finally {
      }
    }
  }

  useEffect(() => {
    if (isSdkReady) {
      setHasMounted(true)
    }
  }, [isSdkReady])

  if (!isSdkReady || !hasMounted) {
    return <div>Loading PayPal SDK...</div>
  }

  return (
    <>
      {checkoutSuccessPopup && (
        <FreeCheckoutSuccessPopup
          checkoutSuccessPopup={checkoutSuccessPopup}
          setCheckoutSuccessPopup={setCheckoutSuccessPopup}
          contact={activeContact}
          meetingDetails={meetingDetails}
          activeContact={activeContact}
        />
      )}
      <div className="justify-flex flex-col items-center justify-center">
        <PayPalButton createOrder={createOrder} onApprove={onApprove} />
        <div className="flex items-center w-full mb-2 mt-2">
          <div className="flex-grow border-t border-gray-300"></div>
          <span className="mx-2 text-gray-500 text-graySlate">OR</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>
        {paymentMethods && paymentMethods.length > 0 ? (
          <>
            <ServiceCardSelector
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={(paymentMethod: any) => {
                setSelectedPaymentMethod(paymentMethod)
                setUseSavedCard(true)
              }}
              callback={() => {
                createSetupIntent()
                  .then(() => {
                    console.log("Payment method setup intent created")
                  })
                  .catch((error: any) => {
                    console.error("Error creating setup intent", error)
                  })
              }}
            />
            <div className="mb-4"></div>
            {!useSavedCard ? (
              <PayPalCardFields
                isCheckedRef={isCheckedRef}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                onApprove={onApprove}
                createOrder={createOrder}
                isTermsAgreed={isTermsAgreed}
                setIsTermsAgreed={setIsTermsAgreed}
                redirectUrl=""
                selectedPlanFromChild={selectedPlanFromChild}
              />
            ) : (
              <div className="mt-4 text-gray-500">
                {/* {!isTermsAgreed && (
                  <p className="text-[red] w-[100%] text-center">
                    Please agree to Agreement & disclosure.
                  </p>
                )} */}
                <Button
                  type="button"
                  className="w-[95%] text-md ml-2"
                  onClick={() => {
                    setLoading(true)
                    createOrder({}, "card")
                      .then((orderData) => {
                        return onApprove(orderData, "card")
                      })
                      .catch((error) => {
                        console.error(
                          "Error processing saved card payment",
                          error
                        )
                      })
                      .finally(() => {
                        setLoading(false)
                      })
                  }}
                  // disabled={!isTermsAgreed}
                >
                  {loading ? <Loader /> : "Pay Now"}
                </Button>
              </div>
            )}
          </>
        ) : (
          <PayPalCardFields
            isCheckedRef={isCheckedRef}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onApprove={onApprove}
            createOrder={createOrder}
            isTermsAgreed={isTermsAgreed}
            setIsTermsAgreed={setIsTermsAgreed}
            redirectUrl=""
            selectedPlanFromChild={selectedPlanFromChild}
          />
        )}{" "}
      </div>
    </>
  )
}

export default PayPalCheckoutServiceComponent
