import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionsRequests } from '../../api/app.service';
import LoadingPage from '../../components/LoadingPage';
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { UserCtx } from "../../context/userContext";
import useLoading from '../../hooks/useLoading';
import { isLocalStorageAvailable } from '../../utils/isLocalStorageAvailable';

interface Props {}

const SessionsRouter: React.FC<Props> = () => {
    const {
        renderError,
    } = React.useContext(CommonFunctionCtx);
    const { stopLoading } = useLoading();
    const { user, setUser } = React.useContext(UserCtx);
    const navigate = useNavigate();

    const setActiveProfile = async (participantRole:string) => {
        let activeProfile: "member" | "coach" | null = null;
        let activeProfileId;
        if(participantRole === "member"){
            activeProfile = "member";
            activeProfileId = user.memberProfile.profile_id;
        } else {
            activeProfile = "coach";
            activeProfileId = user.coachProfile.profile_id;
        }
        const userObject = {
            ...user,
            // activeProfile: activeProfile,
            // activeProfileId: activeProfileId
        }
        if(isLocalStorageAvailable()){
            localStorage.setItem("user", JSON.stringify(userObject));
        }
        setUser(userObject);
    }

    const getUserSessionRole = async (meetingId: string) => {
        await SessionsRequests.getParticipantRole({
            meeting_id: parseInt(meetingId),
            user_id: parseInt(user?.user_id)
        })
        .then((data) => {
            if(data.meeting){
                // switch profiles, then navigate to session route
                setActiveProfile(data.meeting.profile_type).then(() => {
                    navigate(
                        `/${data?.meeting?.profile_type}/sessions`,
                        {
                            state: {
                                meetingId: meetingId,
                                meetingStartTime: data?.meeting?.meeting_start_time,
                                meetingEndTime: data?.meeting?.meeting_end_time,
                                meetingName: data?.meeting?.meeting_name || "Your meeting"
                            }
                        }
                    )
                })
            }
            
        })
        .catch((ex: any) => {
            console.log(ex);
            renderError(ex.response.data.message);
            stopLoading();
        });
    }

    const checkUrlParams = () => {
        const params = new URLSearchParams(window.location.search)
        const meetingId = params.get("meetingId");
        if(meetingId){
            getUserSessionRole(meetingId);
        }
    }

    React.useEffect(() => {
        checkUrlParams();
    }, []);

    return (
        <LoadingPage />
    );
}

export default SessionsRouter;
