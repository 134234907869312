import type { SVGProps } from "react";
export const SvgInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 6 20"
    {...props}
  >
    <path fill="currentColor" d="m5.847 6.792-5.04.631-.18.837.991.18c.645.155.774.387.634 1.032L.627 17.1C.202 19.072.86 20 2.405 20c1.2 0 2.59-.553 3.222-1.315l.193-.915c-.438.387-1.083.542-1.508.542-.605 0-.824-.425-.67-1.174zM5.998 2.2a2.2 2.2 0 1 1-4.4 0 2.2 2.2 0 0 1 4.4 0" />
  </svg>
);
