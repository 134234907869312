import { type FC } from "react";
import Loader from "./ui/loader";

interface LoadingPageProps {
  message?: string | null;
}

const LoadingPage: FC<LoadingPageProps> = ({ message }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center flex-col gap-6 bg-white z-[10000000]">
      <Loader />
      {message && (
        <div className="max-w-sm md:max-w-md px-5 text-center">
          <div className="font-bold mb-2">{message}</div>
          <div className="font-semibold text-base text-graySlate">
            Please do not leave this page or refresh your browser.
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingPage;
