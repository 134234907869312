import React from 'react';
import Peer from './Peer';
import {
    useHMSStore,
    selectPeers,
    selectDominantSpeaker
  } from "@100mslive/react-sdk";
import { SessionContext } from '../../pages/sessions/context/sessionContext';
import { SvgChevronRight } from '../icons';

interface Props {}

const SpeakerView:React.FC<Props> = () => {
    const { currentSpeaker, setCurrentSpeaker, mobileView, fullScreen } = React.useContext(SessionContext);
    const peers = useHMSStore(selectPeers);
    const dominantSpeaker = useHMSStore(selectDominantSpeaker);
    const [nonSpeakerRange, setNonSpeakerRange] = React.useState<any>({start: 0, end: 5});

    const handlePageLeft = () => {
        const newStart = nonSpeakerRange.start - 5;
        const newEnd = nonSpeakerRange.end - 5;
        setNonSpeakerRange({start: newStart, end: newEnd})
    }
    
    const handlePageRight = () => {
        const newStart = nonSpeakerRange.start + 5;
        const newEnd = nonSpeakerRange.end + 5;
        setNonSpeakerRange({start: newStart, end: newEnd})
    }

    React.useEffect(() => {
        if(dominantSpeaker){
            if(mobileView){
                if(!dominantSpeaker.isLocal){
                    setCurrentSpeaker(dominantSpeaker);
                }
            } else {
                setCurrentSpeaker(dominantSpeaker);
            }
        } else {
            const peerIds = peers.map(peer => peer.id);
            if(currentSpeaker && !peerIds.includes(currentSpeaker.id)){
                setCurrentSpeaker(peers.filter(peer => !peer.isLocal)[0]);
            }
        }
    }, [dominantSpeaker])

    React.useEffect(() => {
        const speaker = peers.length < 2 ? peers.filter(peer => peer.isLocal)[0] : peers.filter(peer => !peer.isLocal)[0]
        setCurrentSpeaker(speaker);
    }, [peers])
    
    return (
        <>
            {currentSpeaker ? (
                <>
                    {peers.length === 1 && (
                        <Peer
                            peer={peers[0]}
                            peerCount={peers.length}
                            peerType="full-screen"
                            currentPage={null}
                        />
                    )}
                    {peers.length === 2 && peers.map((peer) => (
                        <>
                            {peer.isLocal ? (
                                <Peer
                                    peer={peer}
                                    peerCount={peers.length}
                                    peerType="double-self"
                                    currentPage={null}
                                />
                            ) : (
                                <Peer
                                    peer={peer}
                                    peerCount={peers.length}
                                    peerType="full-screen"
                                    currentPage={null}
                                />
                            )}
                        </>
                    ))}
                    {peers.length > 2 && (
                        <>
                            <Peer
                                peer={currentSpeaker}
                                peerCount={peers.length}
                                peerType="speaker"
                                currentPage={null}
                            />
                            {mobileView ? (
                                <>
                                    <Peer
                                        peer={peers.filter(peer => peer.isLocal)[0]}
                                        peerCount={peers.length}
                                        peerType="double-self"
                                        currentPage={null}
                                    />
                                </>
                            ) : (
                                <div
                                    className={`absolute top-[18px] left-[50%] -translate-x-[50%] 
                                    ${fullScreen ? "h-[80px] gap-[6px]" : "h-[60px] gap-[4px]"} flex items-center`}
                                >
                                    {peers.length > 6 && (
                                        <button
                                            className='mr-[16px] w-[40px] h-[40px] rounded-full
                                            bg-white flex items-center justify-center disabled:bg-gray'
                                            disabled={nonSpeakerRange.start === 0}
                                            onClick={handlePageLeft}
                                        >
                                            <SvgChevronRight stroke="#3395FF" className='rotate-[180deg]' />
                                        </button>
                                    )}
                                    {peers.filter((peer) => peer.id !== currentSpeaker.id).slice(nonSpeakerRange.start, nonSpeakerRange.end).map((peer) => (
                                        <Peer
                                            peer={peer}
                                            peerCount={peers.length}
                                            peerType="non-speaker"
                                            currentPage={null}
                                        />
                                    ))}
                                    {peers.length > 6 && (
                                        <button
                                            className='ml-[16px] w-[40px] h-[40px] rounded-full
                                            bg-white flex items-center justify-center disabled:bg-gray'
                                            disabled={nonSpeakerRange.end >= peers.length - 1}
                                            onClick={handlePageRight}
                                        >
                                            <SvgChevronRight stroke="#3395FF" className='' />
                                        </button>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </>
            ) : (
                <div>Loading...</div>
            )}
        </>
  );
}

export default SpeakerView;
