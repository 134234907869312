"use client";

import * as React from "react";
import { cn } from "../../../../utils/cn";
import { CarouselControls } from "../carousel-controls";
import Group6 from "../../../../assets/images/coach-testimonials/Group 6.png";
import Group7 from "../../../../assets/images/coach-testimonials/Group 7.png";
import Group8 from "../../../../assets/images/coach-testimonials/Group 8.png";
import Group9 from "../../../../assets/images/coach-testimonials/Group-9.png";
import Group10 from "../../../../assets/images/coach-testimonials/Group-10.png";

export const TestimonialSlider = () => {
  const slides = [
    { image: Group6 },
    { image: Group7 },
    { image: Group8 },
    { image: Group9 },
    { image: Group10 },
  ];

  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((index) => (index + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="lg:hidden flex justify-center items-center flex-col pt-8 mb-6">
      <div className="h-[400px] w-[400px] relative flex justify-center">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            className={cn("absolute transition-opacity duration-500", {
              "opacity-100": slideIndex === currentIndex,
              "opacity-0": slideIndex !== currentIndex,
            })}
          >
            <img
              src={slide.image}
              alt={`Slide ${slideIndex + 1}`}
              className="w-full h-full object-cover ml-4"
            />
          </div>
        ))}
      </div>
      <CarouselControls
        activeIndex={currentIndex}
        setActiveIndex={setCurrentIndex}
        dataLength={slides.length}
      />
    </div>
  );
};
