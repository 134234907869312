import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter } from "react-router-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { HelmetProvider } from "react-helmet-async";
import { UserContextProvider } from "../context/userContext";
import { CommonFunctionProvider } from "../context/commonFunctionContext";
import { NotificationCtxProvider } from "../context/notificationContext";
import { SubscriptionProvider } from "../context/subscriptionContext";
import { LoadingProvider } from "../context/loadingContext";
import { AuthProvider } from "./auth/context";
import GoogleAuthProviderWrapper from "../context/GoogleAuthProvider";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <BrowserRouter>
      <HMSRoomProvider>
        <HelmetProvider>
          <LoadingProvider>
            <IntercomProvider
              appId={process.env.REACT_APP_INTERCOM || ""}
              autoBoot
            >
              <UserContextProvider>
                <CommonFunctionProvider>
                  <GoogleAuthProviderWrapper>
                    <AuthProvider>
                      <SubscriptionProvider>
                        <NotificationCtxProvider>
                          {children}
                        </NotificationCtxProvider>
                      </SubscriptionProvider>
                    </AuthProvider>
                  </GoogleAuthProviderWrapper>
                </CommonFunctionProvider>
              </UserContextProvider>
            </IntercomProvider>
          </LoadingProvider>
        </HelmetProvider>
      </HMSRoomProvider>
    </BrowserRouter>
  );
}
