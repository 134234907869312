import type { SVGProps } from "react";
export const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 25"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 14.056c-.41 0-.75-.34-.75-.75v-5c0-.41.34-.75.75-.75s.75.34.75.75v5c0 .41-.34.75-.75.75M12 17.556a1 1 0 0 1-.71-.29q-.135-.15-.21-.33a1 1 0 0 1-.08-.38c0-.13.03-.26.08-.38.05-.13.12-.23.21-.33.37-.37 1.05-.37 1.42 0 .09.1.16.2.21.33.05.12.08.25.08.38s-.03.26-.08.38q-.075.18-.21.33a1 1 0 0 1-.71.29"
    />
    <path
      fill="currentColor"
      d="M11.99 22.556c-2.58 0-5.02-.97-6.89-2.76a9.97 9.97 0 0 1-3.1-7c-.07-2.67.92-5.2 2.76-7.14a9.97 9.97 0 0 1 7-3.1c2.66-.06 5.21.91 7.14 2.76a9.97 9.97 0 0 1 3.1 7c.06 2.67-.91 5.21-2.76 7.14a9.97 9.97 0 0 1-7 3.1zm.02-18.5h-.21c-2.28.06-4.39.99-5.95 2.64a8.4 8.4 0 0 0-2.34 6.07c.05 2.27.99 4.38 2.63 5.95a8.38 8.38 0 0 0 6.07 2.34c2.27-.05 4.38-.99 5.95-2.63a8.4 8.4 0 0 0 2.34-6.07 8.45 8.45 0 0 0-2.63-5.95 8.46 8.46 0 0 0-5.86-2.35"
    />
  </svg>
);
