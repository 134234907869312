import React from 'react';
import { ConnectCalendarCTA } from './account-settings/calendars/ConnectCalendarCTA';
import CloseModal from "./CloseModal";

interface Props {
  setShowCalendarNotice: any;
}

const ConnectCalendarWarningCardModal: React.FC<Props> = ({
    setShowCalendarNotice,
}) => {
  return(
    <div className="fixed top-0 left-0 w-screen h-full flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div className="w-[900px] max-w-[90%] main-shadow rounded-[16px] flex flex-col items-center gap-[30px] max-h-[95vh] bg-white fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] overflow-y-auto">
      <CloseModal callback={() => setShowCalendarNotice(false)} styling="absolute top-[16px] right-[16px] sm:top-[24px] sm:right-[24px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash" />
        <ConnectCalendarCTA isCalendarConnected={false} />
      </div>
    </div>
  )
}

export default ConnectCalendarWarningCardModal;
