import type { SVGProps } from "react";
export const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.5 6.5C3.5 3.875 3.528 3 7 3s3.5.875 3.5 3.5S10.511 10 7 10s-3.5-.875-3.5-3.5M15.525 8.975c-1.856-1.856-2.455-2.495 0-4.95s3.094-1.856 4.95 0 2.483 2.467 0 4.95-3.094 1.856-4.95 0M3.5 17.5c0-2.625.028-3.5 3.5-3.5s3.5.875 3.5 3.5S10.511 21 7 21s-3.5-.875-3.5-3.5M14.5 17.5c0-2.625.028-3.5 3.5-3.5s3.5.875 3.5 3.5.011 3.5-3.5 3.5-3.5-.875-3.5-3.5"
      clipRule="evenodd"
    />
  </svg>
);
