import React from "react";
import moment from "moment";
import { UserCtx } from "../../context/userContext";
import { getUserColor } from "../../utils/getUserColor";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";

type Props = {
  notification: any;
  markRead: (notification_id: number) => void;
  markUnread: (notification_id: number) => void;
};

const NotificationsPageItem: React.FC<Props> = ({
  notification,
  markRead,
  markUnread,
}) => {
  const { user } = React.useContext(UserCtx);
  const timezone = user.timezone.value;
  // const notificationTimeFromNow = moment.utc(notification.created_at).tz(timezone).fromNow();
  const notificationTime = moment
    .utc(notification.created_at)
    .tz(timezone)
    .format("lll");

  return (
    <div className="border-grayCloud relative flex w-full items-center justify-between gap-[8px] border-b px-[12px] py-[20px] last:border-0">
      {notification.viewed === 0 && (
        <Badge dot variant="alert" className="absolute left-0" />
      )}

      <div className="flex items-center gap-[20px]">
        <div
          className={`${getUserColor(notification.creator_user_color)} flex max-h-[40px] min-h-[40px] min-w-[40px] max-w-[40px] items-center justify-center rounded-full text-[18px]`}
        >
          {notification.creator_has_avatar ? (
            <img
              src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${notification.creator_user_id}`}
              className="h-[40px] w-[40px] rounded-full object-cover"
              alt="avatar"
            />
          ) : (
            <>{notification?.creator_first_name?.charAt(0)}</>
          )}
        </div>

        <div className="flex flex-col gap-[2px]">
          <h3 className="text-[16px] font-semibold">
            {notification.notification_name}
          </h3>
          <p className="text-graySlate text-base">{notificationTime}</p>
        </div>
      </div>

      <div>
        <Button
          onClick={
            notification.viewed === 0
              ? () => markRead(notification.id)
              : () => markUnread(notification.id)
          }
          variant="secondary"
          size="sm"
        >
          {notification.viewed === 0 ? "Mark as read" : "Mark as unread"}
        </Button>

        {/* TODO ADd go to notification button? */}
      </div>
    </div>
  );
};

export default NotificationsPageItem;
