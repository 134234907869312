import React from "react";
import CloseModal from "../CloseModal";
import csvInstructions from "../../assets/images/csv-instructions.webp";

interface Props {
  setShowInstructions: any;
}

const CsvInstructions: React.FC<Props> = ({ setShowInstructions }) => {
  return (
    <div className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter z-[9001] flex items-center justify-center">
      <div className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto">
        <CloseModal
          callback={setShowInstructions}
          styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Uploading a CSV
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <ul className="list-disc font-bold gap-[8px] flex flex-col text-grayCharcoal">
            <li>Create an Excel file</li>
            <li>
              In one column, add the email addresses of those you would like to
              invite
            </li>
            <li>Export as a CSV file</li>
            <li>Drag the file into the dashed blue box</li>
          </ul>
          <img src={csvInstructions} alt="" className="w-1/2 mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default CsvInstructions;
