import type { SVGProps } from "react";
export const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 55 46"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.648 15.324V2.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 37.723 0H3.425a2.97 2.97 0 0 0-2.083.894A2.96 2.96 0 0 0 .5 2.995v40.01c-.009.784.294 1.54.842 2.1A2.97 2.97 0 0 0 3.425 46h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L54.379 18.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M18.02 21.132a1.25 1.25 0 1 1-2.498 0 1.25 1.25 0 0 1 2.498 0M25.516 21.132a1.25 1.25 0 1 1-2.499 0 1.25 1.25 0 0 1 2.499 0M19.27 26.442H20a3 3 0 0 1 1.39.337 1.27 1.27 0 0 0 1.493-.206 1.25 1.25 0 0 0-.312-1.996 5.5 5.5 0 0 0-2.577-.624h-.724a1.25 1.25 0 0 0 0 2.498z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.18 32.641a26.5 26.5 0 0 0 8.677 0 6.44 6.44 0 0 0 5.309-5.31c.234-1.43.35-2.876.347-4.325 0-1.453-.12-2.904-.36-4.338a6.44 6.44 0 0 0-5.309-5.309 26.4 26.4 0 0 0-8.676 0 6.44 6.44 0 0 0-5.309 5.31 26.4 26.4 0 0 0 0 8.675 6.44 6.44 0 0 0 5.322 5.297m-2.844-13.564a3.94 3.94 0 0 1 3.254-3.254c2.6-.433 5.254-.433 7.854 0a3.94 3.94 0 0 1 3.258 3.254c.212 1.3.316 2.613.312 3.93.004 1.315-.1 2.63-.312 3.928a3.945 3.945 0 0 1-3.254 3.254 24 24 0 0 1-3.93.313c-1.315.004-2.63-.1-3.928-.313a3.94 3.94 0 0 1-3.254-3.254 23.9 23.9 0 0 1 0-7.854z"
    />
  </svg>
);
