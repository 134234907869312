import React from "react"
import moment from "moment"
import { UserCtx } from "../../../context/userContext"
import { getUserColor } from "../../../utils/getUserColor"
import useName from "../../../hooks/useName"

interface Props {
  log: any
  detailedPastSession: any
}

const AttendanceLogItem: React.FC<Props> = ({ log, detailedPastSession }) => {
  const { user } = React.useContext(UserCtx)
  const { initial } = useName()
  const { firstName, lastName, hasAvatar, logType, userColor, userId } = log

  const { session_end_time, session_start_time } = detailedPastSession.session

  const getTimezoneAdjustedTime = (time: any) => {
    return moment.utc(time).tz(user.timezone.value).format("h:mm a")
  }

  const adjustedSessionStart = getTimezoneAdjustedTime(session_start_time)
  const adjustedSessionEnd = getTimezoneAdjustedTime(session_end_time)

  return (
    <div className="w-full p-[14px] flex items-center justify-between">
      <div className="flex items-center gap-[16px]">
        <div className="w-[40px] h-[40px]">
          {hasAvatar ? (
            <img
              src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${userId}`}
              alt="avatar"
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <div
              className={`w-full h-full rounded-full justify-center items-center flex ${getUserColor(
                userColor
              )}`}
            >
              {initial}
            </div>
          )}
        </div>
        <div className="flex flex-col">
          <span className="font-bold text-[16px]">
            {firstName + " " + lastName}
          </span>
          <div className="text-grayCharcoal text-base">
            <span
              className={logType === "joined" ? "text-green" : "text-vividRed"}
            >
              {logType === "joined" ? "Joined" : "Left"}
            </span>
            &nbsp;
            {logType === "joined"
              ? `at session start time: ${adjustedSessionStart}`
              : `at session end time: ${adjustedSessionEnd}`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AttendanceLogItem
