import type { SVGProps } from "react";
export const SvgTimeAfternoon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#time-afternoon_svg__a)">
      <rect width={24} height={24} fill="#ACEAFF" rx={12} />
      <path
        fill="#7DD2F7"
        d="M4.925 16.45-4.5 15c0 6.14 4.253 11.46 10.242 12.813l2.302.52c1.953.44 3.972.513 5.953.213l7.183-1.088a8.443 8.443 0 0 0 6.88-10.57L25.5 7.5l-2.51 2.69a20.46 20.46 0 0 1-18.065 6.26"
      />
      <path
        fill="#F7DD00"
        d="M2.828 17.887-.5 16.5c-1.384 6.366 2.625 12.657 8.98 14.092l1.546.35c1.635.369 3.325.43 4.982.178l8.298-1.257A7.607 7.607 0 0 0 28.97 18.94L26.5 14 20 17.5a21.19 21.19 0 0 1-17.172.387"
      />
      <path
        fill="#E3B603"
        d="M.328 21.387-3 20c-1.384 6.366 2.625 12.657 8.98 14.092l1.546.35c1.635.368 3.325.43 4.982.178l8.298-1.257A7.607 7.607 0 0 0 26.47 22.44L24 17.5 17.5 21a21.19 21.19 0 0 1-17.172.387"
      />
      <circle cx={14.5} cy={6.5} r={3.5} fill="#FBE961" />
    </g>
    <defs>
      <clipPath id="time-afternoon_svg__a">
        <rect width={24} height={24} fill="#fff" rx={12} />
      </clipPath>
    </defs>
  </svg>
);
