import type { SVGProps } from "react";
export const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 90 80"
    {...props}
  >
    <path
      fill="currentColor"
      d="M60.148 32.324V19.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 57.223 17H22.925a2.97 2.97 0 0 0-2.083.894A2.96 2.96 0 0 0 20 19.995v40.01c-.009.784.294 1.54.842 2.1a2.97 2.97 0 0 0 2.083.895h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L73.879 35.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
    />
    <g fill="#fff" filter="url(#success-img_svg__a)">
      <path d="M37.82 38.125a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M44.69 38.125a1.25 1.25 0 1 1-2.498.001 1.25 1.25 0 0 1 2.499 0M39.885 44.375h.247c1.12 0 2.167-.56 2.788-1.494a1.25 1.25 0 0 0-.96-1.941 1.25 1.25 0 0 0-1.12.554.85.85 0 0 1-.712.381h-.246a.85.85 0 0 1-.712-.381 1.25 1.25 0 1 0-2.08 1.387 3.35 3.35 0 0 0 2.795 1.494M46.277 33.478c.107.054.194.14.247.247l.484.963a.55.55 0 0 0 .987 0l.484-.969a.55.55 0 0 1 .247-.247l.962-.478a.55.55 0 0 0 0-.987l-.971-.485a.55.55 0 0 1-.247-.247l-.475-.962a.55.55 0 0 0-.987 0l-.484.971a.55.55 0 0 1-.247.247l-.962.476a.55.55 0 0 0 0 .987z" />
      <path d="M49.944 38.35a1.25 1.25 0 0 0-1.25-1.163 1.25 1.25 0 0 0-1.249 1.341q.056.737.056 1.472c.005 1.317-.1 2.632-.312 3.931a3.94 3.94 0 0 1-3.254 3.256 24 24 0 0 1-3.929.313c-1.316.004-2.63-.1-3.929-.313a3.93 3.93 0 0 1-3.254-3.256 24 24 0 0 1 0-7.862 3.94 3.94 0 0 1 3.255-3.257 24 24 0 0 1 3.928-.312q.735 0 1.471.047a1.252 1.252 0 0 0 .181-2.5A29 29 0 0 0 40.006 30c-1.453 0-2.904.12-4.338.36a6.42 6.42 0 0 0-5.309 5.312c-.479 2.875-.479 5.809 0 8.684a6.43 6.43 0 0 0 5.31 5.312c1.434.23 2.885.34 4.337.331 1.454 0 2.905-.12 4.338-.359a6.42 6.42 0 0 0 5.31-5.312 26.5 26.5 0 0 0 .29-5.978" />
    </g>
    <defs>
      <filter
        id="success-img_svg__a"
        width={100}
        height={100}
        x={-10}
        y={-6}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={20} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2086_2133"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2086_2133"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
