import * as React from "react";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import * as Icons from "../../../components/icons/index";
import { Input } from "../../../components/ui/input";
import {
  addCoachToCommissionTracking,
  isCoachInCommissionTracking,
  getCommissionTracking,
  removeCoachFromCommissionTracking,
} from "../../../utils/commissionTracking";

export const CommissionTrackingPage: React.FC = () => {
  const [slug, setSlug] = React.useState("");
  const [slugs, setSlugs] = React.useState<string[]>(getCommissionTracking());

  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-3xl font-bold">Commission Tracking</h1>

      <div className="border-border flex flex-col rounded-lg border">
        <div className="flex items-center justify-between border-b p-3">
          <h3 className="text-lg font-bold">Slugs</h3>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setSlugs(getCommissionTracking())}
            className="group"
          >
            <Icons.SvgRedo className="transform transition-transform group-active:rotate-90" />
          </Button>
        </div>

        <div className="flex flex-col gap-2 p-6">
          {slugs.length === 0 && (
            <p className="text-muted-foreground pb-3 text-center">
              No slugs added
            </p>
          )}

          {slugs.map((slug, index) => (
            <div key={index} className="flex items-center justify-between">
              <p>
                {slug}
                <Badge
                  className="ml-2"
                  variant={
                    isCoachInCommissionTracking(slug) ? "success" : "alert"
                  }
                >
                  {isCoachInCommissionTracking(slug)
                    ? "Tracking"
                    : "Not Tracking"}
                </Badge>
              </p>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  removeCoachFromCommissionTracking(slug);
                  setSlugs(getCommissionTracking());
                }}
              >
                <Icons.SvgClose />
              </Button>
            </div>
          ))}

          <form className="grid grid-cols-[auto_150px] gap-2">
            <Input
              label="Slug"
              onChange={(e) => setSlug(e.target.value)}
              value={slug}
              placeholder="Enter slug"
            />
            <Button
              className="h-full"
              onClick={(e) => {
                e.preventDefault();
                addCoachToCommissionTracking(slug);
                setSlugs(getCommissionTracking());
                setSlug("");
              }}
            >
              <Icons.SvgPlus />
              Add
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
