import React from "react";
import InputMask from "react-input-mask";
import { VerifyPhone } from "../../api/app.service";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import CloseModal from "../CloseModal";
import useLoading from "../../hooks/useLoading";
import { Button } from "../ui/button";
import { SvgWarning } from "../icons";

interface Props {
  phone: string;
  setVerifyPhoneForm: any;
  setPhoneVerified: any;
}

export default function ConfirmMobile({ phone, setVerifyPhoneForm, setPhoneVerified }: Props) {
  const codeMask = "999999";
  const [values, setValues] = React.useState<any>({
    confirmation_code: "",
  });
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { user } = React.useContext(UserCtx);
  const { renderSuccess, getUser, renderError } =
    React.useContext(CommonFunctionCtx);

  const { startLoading, stopLoading } = useLoading();

  const resendCode = () => {
    if (user) {
      startLoading();
      VerifyPhone.sendPhoneCode({ access_token: user.access_token })
        .then(() => {
          renderSuccess("A new code was sent to your mobile number.");
          stopLoading();
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement;
    setValues({ ...values, [target.name]: target.value });
  };

  const validateForm = (values: any) => {
    const errors: any = {};
    (!values.confirmation_code || values.confirmation_code === "") &&
      (errors.phone = "Enter your 6-digit code");
    return errors;
  };

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      startLoading();
      VerifyPhone.verifyPhoneCode({
        verification_code: values.confirmation_code,
        access_token: user.access_token,
      })
        .then(() => {
          renderSuccess("Your phone is verified!");
          setPhoneVerified(true);
          setVerifyPhoneForm(false);
          getUser();
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const handlePhoneContinue = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setFormErrors(validateForm(values));
    setIsSubmit(true);
  };

  const handleVerifyLater = () => {
    setVerifyPhoneForm(false);
  };

  return (
    <div
      className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-[10px]
    flex items-center mx-auto flex-col w-[335px] md:w-[600px] py-[64px] px-[24px] md:pb-[100px 
    phone-confirm-popup-shadow z-[10000] gap-[24px]"
    >
      <CloseModal
        styling="absolute top-[29px] right-[29px]"
        callback={() => setVerifyPhoneForm(false)}
      />
      <div className="text-center text-[28px] text-black font-semibold mb-[8px] leading-[33.6px]">
        Confirm your number
      </div>
      <div className="base2 text-center mb-[24px]">
        Enter the code we sent over SMS to{" "}
        <span className="text-mainBlack">{`+${phone}`}</span>
      </div>
      <form action="#" onSubmit={handlePhoneContinue}>
        <div className="mb-[24px]">
          <div className="default-input w-[287px] md:w-[360px]">
            <InputMask
              mask={codeMask}
              type="text"
              placeholder="Enter code"
              name="confirmation_code"
              autoComplete="off"
              onChange={handleChange}
            ></InputMask>
            <div className="overlay">Confirmation Code</div>
          </div>
          {formErrors.phone && (
            <div className="field-error">
              <SvgWarning />
              {formErrors.phone}
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <Button type="submit">Continue</Button>
        </div>
      </form>
      <div className="text-blurple items-center ">
        <button className="font-bold" onClick={handleVerifyLater}>
          Verify Later
        </button>
      </div>
      <div className="flex items-center">
        <div className="base1">
          Having trouble?&nbsp;
          <button
            className="text-black underline cursor-pointer"
            onClick={resendCode}
          >
            Resend Code
          </button>
        </div>
      </div>
    </div>
  );
};
