import React from "react"
import ReceiptItem from "./ReceiptItem"
import { SvgChatEllipses, SvgQuestionCircle } from "../icons/"
import { Button } from "../ui/button"
import { useIntercom } from "react-use-intercom"

interface Props {
  receipts: any[]
}

const SubscriptionReceipts: React.FC<Props> = ({ receipts }) => {
  const { show } = useIntercom()

  return (
    <div className="w-full flex flex-col lg:flex-row gap-16 mt-6 items-start mb-16">
      <div className="px-6 md:px-0 w-full">
        <div className="flex justify-between items-center">
          <h3 className="font-bold text-[16px] md:mb-[16px]">
            {receipts.length} Receipt{receipts.length !== 1 ? "s" : ""}
          </h3>
        </div>
        <div className="w-full flex flex-col items-center gap-3">
          {receipts?.map((receipt) => (
            <ReceiptItem
              receipt={receipt}
              key={receipt.subscription_payment_id}
            />
          ))}
          {receipts.length === 0 && (
            <h3 className="mr-auto mt-6 font-bold text-graySlate">
              You haven't made any subscription payments.
            </h3>
          )}
        </div>
      </div>

      <div className="mx-auto w-96 bg-white main-shadow p-6 rounded-xl flex flex-col items-center">
        <SvgQuestionCircle className="text-grayMist h-12 w-12 mb-2" />
        <h4 className="font-bold mb-2">Have a question?</h4>
        <p className="text-graySlate text-base mb-6 text-center">
          Connect with Zoee's amazing support and billing team!
        </p>
        <Button
          variant="link"
          onClick={() => {
            show()
          }}
        >
          <SvgChatEllipses />
          Chat with us
        </Button>
        <p className="text-base text-graySlate text-center mt-6">
          Zoee protects your payment information with PayPal.{" "}
          <a
            href="https://intercom.help/zoee/en/articles/8623117-how-does-zoee-protect-your-information-on-paypal"
            target="_blank"
            rel="noreferrer"
            className="text-blurple cursor-pointer font-bold"
          >
            Learn more.
          </a>
        </p>
      </div>
    </div>
  )
}

export default SubscriptionReceipts
