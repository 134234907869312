import type { SVGProps } from "react";
export const SvgMastercard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="#F93232" d="M7.5 19a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15" />
    <path
      fill="#FED049"
      d="M16.5 4a7.45 7.45 0 0 0-4.485 1.5H12v.01a7.6 7.6 0 0 0-.83.74h1.66q.346.353.642.75h-2.95q-.272.36-.5.75h3.948c.141.243.274.49.389.75H9.64a7 7 0 0 0-.282.75h5.283q.115.368.193.75H9.162q-.077.368-.118.75h5.911q.045.369.045.75H9c0 .254.02.502.045.75h5.912a8 8 0 0 1-.122.75H9.163q.078.383.195.75h5.284a7 7 0 0 1-.283.75H9.64q.17.387.382.75h3.948q-.228.39-.502.75h-2.945q.298.397.646.75h1.661a7.5 7.5 0 0 1-.83.74A7.45 7.45 0 0 0 16.5 19a7.5 7.5 0 0 0 0-15"
    />
  </svg>
);
