import { Post } from "../../types/postTypes";
import { whatIsZoee } from "./posts/whatIsZoee";
import { howBoundariesAndBreaksCanHelpFightCoachBurnout } from "./posts/howBoundariesAndBreaksCanHelpFightCoachBurnout";
import { coachesAreEntrepreurs } from "./posts/coachesAreEntrepreneurs";
import { readySetGoal } from "./posts/readySetGoal";
import { QAWithNicholeLowe } from "./posts/QAWithNichole";
import { techstars } from "./posts/techstars";
import { ventureAtlanta } from "./posts/ventureAtlanta";
import { coachesCornerJoanneWhiteside } from "./posts/coachesCornerJoanneWhiteside";
import { fourTipsForHolidays } from "./posts/fourTipsForHolidays";
import { coachesCornerChristyStuber } from "./posts/coachesCornerChristyStuber";
import { runASuccessfulOnlineBusiness } from "./posts/runASuccessfulOnlineBusiness";
import { coachesCornerRachelGreb } from "./posts/coachesCornerRachelGreb";
import { coachesCornerJessieCruickshank } from "./posts/coachesCornerJessieCruickshank";
import { TwelveCoachingTipsForTheHolidaySeason } from "./posts/12CoachingTipsForTheHolidaySeason";
import { howToMarketMyOnlineCoachingBusiness } from "./posts/howToMarketMyOnlineCoachingBusiness";
import { coachesCornerJacquelineWhitmore } from "./posts/coachesCornerJacquelineWhitmore";
import { StewardYourInfluenceInBusinessAndLife  } from "./posts/stewardYourInfluenceInBusinessAndLife";
import { TheTopMostProfitableCoachingNiches  } from "./posts/topMostProfitableCoachingNiches";
import { WhyEveryEntrepreneurNeedsACoach  } from "./posts/whyEveryEntrepreneurNeedsACoach";
import { TheGreatestNeedInTheCoachingIndustry  } from "./posts/theGreatestNeedInTheCoachingIndustry";
import { DoYouNeedInsuranceToBeACoach  } from "./posts/doYouNeedInsuranceToBeACoach";
import { WhenYouShouldHireALifeCoach  } from "./posts/whenYouShouldHireALifeCoach";

export const posts: Array<Post> = [
    WhenYouShouldHireALifeCoach,
    DoYouNeedInsuranceToBeACoach,
    TheGreatestNeedInTheCoachingIndustry,
    WhyEveryEntrepreneurNeedsACoach,
    TheTopMostProfitableCoachingNiches,
    whatIsZoee,
    StewardYourInfluenceInBusinessAndLife,
    coachesCornerJacquelineWhitmore,
    howToMarketMyOnlineCoachingBusiness,
    TwelveCoachingTipsForTheHolidaySeason,
    coachesCornerJessieCruickshank,
    coachesCornerRachelGreb,
    runASuccessfulOnlineBusiness,
    coachesCornerChristyStuber,
    fourTipsForHolidays,
    coachesCornerJoanneWhiteside,
    QAWithNicholeLowe,
    howBoundariesAndBreaksCanHelpFightCoachBurnout,
    readySetGoal,
    coachesAreEntrepreurs,
    ventureAtlanta,
    techstars
];
