import type { SVGProps } from "react";
export const SvgQuoteLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M9.648 8.016H7.152c-.528 0-.768-.192-.768-.576 0-1.248 1.44-2.64 4.224-3.168L11.52 0C5.856.912 2.784 2.784 1.68 8.112L0 15.84h8.016zm11.952 0h-2.496c-.528 0-.768-.192-.768-.576 0-1.248 1.44-2.64 4.224-3.168L23.472 0c-5.664.912-8.736 2.784-9.84 8.112l-1.68 7.728h8.016z"
    />
  </svg>
);
