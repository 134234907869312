import * as React from "react"
import CloseModal from "../CloseModal"
import Loader from "../ui/loader"
import Select from "react-select"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { months } from "../../objects/timeObjects"
import { ProfileRequests } from "../../api/app.service"
import { SvgCredentials, SvgEducation, SvgPlus, SvgTrash } from "../icons"

export default function EditCredentials() {
  const { renderSuccess, renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [credentialsAndEducation, setCredentialsAndEducation] =
    React.useState<any>({
      credentials: [],
      education: [],
    })
  const generatePriorYears = () => {
    const today = new Date().getFullYear()
    const max = today
    const min = today - 50
    let years = []
    for (var i = max; i >= min; i--) {
      years.push({ value: i.toString(), label: i.toString() })
    }
    return years
  }

  const generateYears = () => {
    const today = new Date().getFullYear()
    const max = today + 50
    const min = today - 50
    let years = []
    for (var i = max; i >= min; i--) {
      years.push({ value: i.toString(), label: i.toString() })
    }
    return years
  }
  const {
    setEditCredentials,
    coachPublicProfileData,
    getCoachPrivateProfile,
    setEditProfileInformation,
  } = React.useContext(CoachPrivateProfileContext)

  const updateCredentialsArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const newCredentials = credentialsAndEducation?.credentials
    newCredentials[index][e.target.name] = e.target.value
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      credentials: newCredentials,
    })
  }

  const updateCredentialsArrayDate = (e: any, index: any, name: string) => {
    const newCredentials = credentialsAndEducation?.credentials
    newCredentials[index][name] = e.value
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      credentials: newCredentials,
    })
  }

  const addCredential = (e: any) => {
    e.preventDefault()
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      credentials: [
        ...credentialsAndEducation.credentials,
        {
          name: "",
          issuingOrganization: "",
          issueMonth: null,
          issueYear: null,
          expireMonth: null,
          expireYear: null,
        },
      ],
    })
  }

  const removeCredential = (e: any, index: number) => {
    e.preventDefault()
    if (credentialsAndEducation.credentials.length > 1) {
      let newCredentials = credentialsAndEducation.credentials
      newCredentials.splice(index, 1)
      setCredentialsAndEducation({
        ...credentialsAndEducation,
        credentials: newCredentials,
      })
    } else {
      setCredentialsAndEducation({
        ...credentialsAndEducation,
        credentials: [],
      })
    }
  }

  const updateEducationArray = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    const newEducation = credentialsAndEducation?.education
    newEducation[index][e.target.name] = e.target.value
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      education: newEducation,
    })
  }

  const updateEducationArrayDate = (e: any, index: any, name: string) => {
    const newEducation = credentialsAndEducation?.education
    newEducation[index][name] = e.value
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      education: newEducation,
    })
  }

  const addEducation = (e: any) => {
    e.preventDefault()
    setCredentialsAndEducation({
      ...credentialsAndEducation,
      education: [
        ...credentialsAndEducation.education,
        {
          school: "",
          degree: "",
          fieldOfStudy: "",
          startMonth: null,
          startYear: null,
          endMonth: null,
          endYear: null,
          activitiesAndSocieties: "",
        },
      ],
    })
  }

  const removeEducation = (e: any, index: number) => {
    e.preventDefault()
    if (credentialsAndEducation.education.length > 1) {
      let newEducation = credentialsAndEducation.education
      newEducation.splice(index, 1)
      setCredentialsAndEducation({
        ...credentialsAndEducation,
        education: newEducation,
      })
    } else {
      setCredentialsAndEducation({
        ...credentialsAndEducation,
        education: [],
      })
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (credentialsAndEducation !== coachPublicProfileData?.credentials) {
      startLoading()
      setLoading(true)
      ProfileRequests.editCredentials({
        coach_public_profile_id:
          coachPublicProfileData?.coach_public_profile_id,
        credentials: credentialsAndEducation,
      })
        .then(() => {
          setLoading(false)
          renderSuccess("Updated Credentials and Education")
          setEditCredentials(false)
          getCoachPrivateProfile()
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    } else {
      renderError("No changes made!")
    }
  }

  React.useEffect(() => {
    if (coachPublicProfileData?.credentials) {
      setCredentialsAndEducation(coachPublicProfileData?.credentials)
    }
  }, [])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009] credentials">
      <div className="w-[800px] max-w-[90%] main-shadow rounded-[16px] pt-[40px] pb-[40px] max-h-[85vh] bg-white fixed left-[50%] -translate-x-[50%] flex flex-col items-start">
        <CloseModal
          callback={() => setEditCredentials(false)}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />

        {/* FORM */}
        <form
          action="#"
          onSubmit={handleSubmit}
          className="overflow-y-auto w-full px-[16px] md:px-[100px]"
        >
          <div className="flex flex-col gap-[40px]">
            {/* CREDENTIALS */}
            <div className="flex flex-col">
              <h2 className="font-bold text-[32px] mb-[16px]">Credentials</h2>
              <p className="text-[16px] md:max-w-[70%] mb-[32px]">
                Let your potential clients know about all your coaching
                certifications.
              </p>
              <div className="flex flex-col gap-[12px]">
                {credentialsAndEducation?.credentials?.map(
                  (credential: any, index: number) => (
                    <div key={index}>
                      <div className="flex items-center mb-[24px] w-full justify-between">
                        <h5 className="font-bold flex items-center gap-[8px]">
                          <SvgCredentials fill="black" className="w-[20px]" />
                          Credentials ({index + 1})
                        </h5>
                        <button
                          onClick={(e: any) => removeCredential(e, index)}
                        >
                          <SvgTrash />
                        </button>
                      </div>
                      {/* NAME */}
                      <div className={`mb-[16px] default-input w-full`}>
                        <input
                          required
                          type="text"
                          placeholder="Name"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateCredentialsArray(e, index)}
                          name="name"
                          value={credential?.name}
                        />
                        <div className="overlay">Name</div>
                      </div>
                      {/* ISSUING ORGANIZATION */}
                      <div className={`default-input w-full group mb-[16px]`}>
                        <input
                          required
                          type="text"
                          placeholder="Issuing Organization"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateCredentialsArray(e, index)}
                          name="issuingOrganization"
                          value={credential?.issuingOrganization}
                        />
                        <div className="overlay">Issuing Organization</div>
                      </div>
                      {/* ISSUE DATE */}
                      <span className="text-graySlate text-[16px] mb-[16px]">
                        Issue Date
                      </span>
                      <div className="flex items-center w-full justify-between px-[4px] mb-[16px]">
                        <Select
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={months.filter(
                            (month) => month.value === credential?.issueMonth
                          )}
                          options={months}
                          menuPlacement="bottom"
                          name="issueMonth"
                          onChange={(e) =>
                            updateCredentialsArrayDate(e, index, "issueMonth")
                          }
                        />
                        <Select
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={generatePriorYears().filter(
                            (year) => year.value === credential?.issueYear
                          )}
                          options={generatePriorYears()}
                          menuPlacement="bottom"
                          name="issueYear"
                          onChange={(e) =>
                            updateCredentialsArrayDate(e, index, "issueYear")
                          }
                        />
                      </div>
                      {/* EXPIRE DATE */}
                      <span className="text-graySlate text-[16px] mb-[16px]">
                        Expire Date
                      </span>
                      <div className="flex items-center w-full justify-between px-[4px] mb-[16px]">
                        <Select
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={months.filter(
                            (month) => month.value === credential?.expireMonth
                          )}
                          options={months}
                          menuPlacement="bottom"
                          name="expireMonth"
                          onChange={(e) =>
                            updateCredentialsArrayDate(e, index, "expireMonth")
                          }
                        />
                        <Select
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={generateYears().filter(
                            (year) => year.value === credential?.expireYear
                          )}
                          options={generateYears()}
                          menuPlacement="bottom"
                          name="expireYear"
                          onChange={(e) =>
                            updateCredentialsArrayDate(e, index, "expireYear")
                          }
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
              <button
                onClick={addCredential}
                className="btn-primary btn-secondary gap-[8px] mb-[20px]"
              >
                <SvgPlus />
                Add New Credential
              </button>
            </div>

            {/* EDUCATION */}
            <div className="flex flex-col">
              <h2 className="font-bold text-[32px] mb-[16px]">Education</h2>
              <p className="text-[16px] md:max-w-[70%] mb-[32px]">
                Tell your clients about your educational background.
              </p>
              <div className="flex flex-col gap-[12px]">
                {credentialsAndEducation?.education?.map(
                  (item: any, index: number) => (
                    <div key={index}>
                      <div className="flex items-center mb-[24px] w-full justify-between">
                        <h5 className="font-bold flex items-center gap-[8px]">
                          <SvgEducation fill="black" className="w-[20px]" />
                          Education ({index + 1})
                        </h5>
                        <button onClick={(e: any) => removeEducation(e, index)}>
                          <SvgTrash />
                        </button>
                      </div>
                      {/* SCHOOL */}
                      <div className={`mb-[16px] default-input w-full`}>
                        <input
                          required
                          type="text"
                          placeholder="School"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateEducationArray(e, index)}
                          value={item?.school}
                          name="school"
                        />
                        <div className="overlay">School</div>
                      </div>
                      {/* DEGREE */}
                      <div className={`default-input w-full group mb-[16px]`}>
                        <input
                          required
                          type="text"
                          placeholder="Degree"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateEducationArray(e, index)}
                          value={item.degree}
                          name="degree"
                        />
                        <div className="overlay">Degree</div>
                      </div>
                      {/* FIELD OF STUDY */}
                      <div className={`default-input w-full group mb-[16px]`}>
                        <input
                          required
                          type="text"
                          placeholder="Field of Study"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateEducationArray(e, index)}
                          value={item.fieldOfStudy}
                          name="fieldOfStudy"
                        />
                        <div className="overlay">Field of Study</div>
                      </div>
                      {/* START DATE */}
                      <span className="text-graySlate text-[16px] mb-[16px]">
                        Start Date
                      </span>
                      <div className="flex items-center w-full justify-between px-[4px] mb-[16px]">
                        <Select
                          required
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={months.filter(
                            (month) => month.value === item?.startMonth
                          )}
                          options={months}
                          menuPlacement="bottom"
                          name="startMonth"
                          onChange={(e) =>
                            updateEducationArrayDate(e, index, "startMonth")
                          }
                        />
                        <Select
                          required
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={generatePriorYears().filter(
                            (year) => year.value === item?.startYear
                          )}
                          options={generatePriorYears()}
                          menuPlacement="bottom"
                          name="startYear"
                          onChange={(e) =>
                            updateEducationArrayDate(e, index, "startYear")
                          }
                        />
                      </div>
                      {/* EXPIRE DATE */}
                      <span className="text-graySlate text-[16px] mb-[16px]">
                        End Date
                      </span>
                      <div className="flex items-center w-full justify-between px-[4px] mb-[16px]">
                        <Select
                          required
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={months.filter(
                            (month) => month.value === item?.endMonth
                          )}
                          options={months}
                          menuPlacement="bottom"
                          name="endMonth"
                          onChange={(e) =>
                            updateEducationArrayDate(e, index, "endMonth")
                          }
                        />
                        <Select
                          required
                          className={`w-[49%] basic-single`}
                          classNamePrefix="select"
                          value={generateYears().filter(
                            (year) => year.value === item?.endYear
                          )}
                          options={generateYears()}
                          menuPlacement="bottom"
                          name="endYear"
                          onChange={(e) =>
                            updateEducationArrayDate(e, index, "endYear")
                          }
                        />
                      </div>
                      {/* ACTIVITIES AND SOCIETIES */}
                      <div className={`default-input w-full group mb-[16px]`}>
                        <input
                          placeholder="Activities and Societies"
                          className="w-full text-indent-[20px] peer"
                          onChange={(e) => updateEducationArray(e, index)}
                          name="activitiesAndSocieties"
                          value={item.activitiesAndSocieties}
                        />
                        <div className="overlay">Activities and Societies</div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <button
                onClick={(e: any) => addEducation(e)}
                className="btn-primary btn-secondary gap-[8px] mb-[20px]"
              >
                <SvgPlus />
                Add New Education
              </button>
            </div>
          </div>
          <div className="flex w-full justify-center items-center mt-8 mb-4 gap-4">
            <Button
              variant={"link"}
              onClick={() => {
                setEditCredentials(false)
                setEditProfileInformation(true)
              }}
            >
              Back
            </Button>

            {loading ? (
              <Loader />
            ) : (
              <Button size={"lg"} type="submit" value="Save Changes">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
