import React, { useEffect, useState } from "react"
import CoachDirectory from "./CoachDirectory"
import CoachDirectoryMobileListItem from "./CoachDirectoryMobileListItem"
import ContactCard from "./ContactCard"
import ContactMobileListItem from "./ContactMobileListItem"
import marketplaceSoon from "../../assets/images/marketplace-coming-soon.webp"
import { Button } from "../ui/button"
import { CoachCard } from "../../models/public.interface"
import { PublicProfileRequests } from "../../api/public.service"
import { SvgComingSoon, SvgUserPlus, SvgUsers } from "../icons/"
import { UserCtx } from "../../context/userContext"
import { useSearchParams } from "react-router-dom"

interface Props {
  setInvites?: any
  setShowConfirmInviteModal?: any
  getContacts?: any
  contacts: any
  searchValue: string
  setSelectedContact: any
  setShowContactProfile: any
  type: string
  setShowInviteModal: any
  setContactRequestFull: any
  acceptContactRequest: any
  denyContactRequest: any
  revokeContactRequest: any
  resendContactRequest: any
  showContactsMobileListView: boolean
  first_name: string
  last_name: string
  coach_public_profile_id: number
  coach_profile_id: number
  user_id: number
  endpoint_slug: string
  description: string
  tags: string[]
  subscription_promo_codes: string
  max_end_date: string
  avatar_url: string
  updated_at: string
  email: string
}

const ContactsList: React.FC<Props> = ({
  setInvites,
  setShowConfirmInviteModal,
  getContacts,
  contacts,
  searchValue,
  setSelectedContact,
  denyContactRequest,
  resendContactRequest,
  setShowContactProfile,
  type,
  setShowInviteModal,
  setContactRequestFull,
  acceptContactRequest,
  revokeContactRequest,
  showContactsMobileListView,
  first_name,
  last_name,
  coach_public_profile_id,
  coach_profile_id,
  user_id,
  endpoint_slug,
  description,
  tags,
  avatar_url,
  subscription_promo_codes,
  updated_at,
  max_end_date,
  email,
}) => {
  const [foundContacts, setFoundContacts] = React.useState<any>(null)
  const [showInviteButton, setShowInviteButton] = React.useState(false)
  const [coaches, setCoaches] = useState<CoachCard[]>([])
  const [hasNextPage, setHasNextPage] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const { user } = React.useContext(UserCtx)

  const updateFoundContacts = () => {
    const keyword = searchValue
    if (keyword !== "") {
      const results = contacts.filter((contact: any) => {
        const name = contact.contact_name
        return name.toLowerCase().includes(keyword.toLowerCase())
      })
      setFoundContacts(results)
    } else {
      setFoundContacts(contacts)
    }
  }

  const getNoContactString = () => {
    if (type === "all") {
      return user.activeProfile === "coach"
        ? "Looks like you don't have any contacts yet! Let's get started by inviting your clients"
        : "You don't have any coaches yet!"
    } else if (type === "invited") {
      return "Looks like you don't have any sent invitations at the moment."
    } else if (type === "leads") {
      return "Looks like you don't have any Zoee leads yet."
    } else if (type === "prospect") {
      return "Looks like you don't have any prospective clients yet"
    } else if (type === "connected") {
      return "Looks like you don't have any connected contacts yet."
    } else if (type === "requested") {
      return "Looks like you don't have any pending contact requests at the moment."
    } else if (type === "coachdirectory") {
      return "Looks like there are no coaches in the coach directory."
    }
  }

  React.useEffect(() => {
    if (!foundContacts) {
      setFoundContacts(contacts)
    }
    updateFoundContacts()
  }, [searchValue, contacts])

  const shouldApplyMargin = (index: number, totalCards: number) => {
    const screenWidth = window.innerWidth
    let columns

    if (screenWidth < 640) {
      columns = 2
    } else if (screenWidth < 768) {
      columns = 3
    } else {
      columns = 4
    }
    const lastRowStartIndex = Math.floor((totalCards - 1) / columns) * columns
    return screenWidth < 768 && index >= lastRowStartIndex
  }

  async function getCoaches() {
    try {
      const coaches = await PublicProfileRequests.getCoaches(
        searchParams.get("q") || "",
        searchParams.getAll("tags").map((tag) => parseInt(tag)),
        parseInt(searchParams.get("page") || "1")
      )

      setCoaches(coaches)

      const nextPageCoaches = await PublicProfileRequests.getCoaches(
        searchParams.get("q") || "",
        searchParams.getAll("tags").map((tag) => parseInt(tag)),
        parseInt(searchParams.get("page") || "1") + 1
      )

      if (nextPageCoaches.length) {
        setHasNextPage(true)
      } else {
        setHasNextPage(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (type === "coachdirectory") {
      getCoaches()
    } else {
      setFoundContacts(contacts)
    }
  }, [contacts, type])

  React.useEffect(() => {
    setShowInviteButton(window.innerWidth <= 768)
  }, [])

  return (
    <>
      {/* MOBILE */}
      <div className="md:hidden">
        {showContactsMobileListView ? (
          // Mobile list view
          <div className="flex flex-col w-full px-[20px]">
            {type === "coachdirectory" ? (
              coaches.length > 0 ? (
                coaches.map((coach: CoachCard, index: number, contact: any) => (
                  <CoachDirectoryMobileListItem
                    setInvites={setInvites}
                    setShowConfirmInviteModal={setShowConfirmInviteModal}
                    coaches={coaches}
                    setSelectedContact={setSelectedContact}
                    setShowContactProfile={setShowContactProfile}
                    setContactRequestFull={setContactRequestFull}
                    acceptContactRequest={acceptContactRequest}
                    first_name={coach.first_name}
                    last_name={coach.last_name}
                    avatar_url={coach.avatar_url}
                    updated_at={coach.updated_at}
                    subscription_promo_codes={coach.subscription_promo_codes}
                    max_end_date={coach.max_end_date}
                    coach_profile_id={coach.coach_profile_id}
                    coach_public_profile_id={coach.coach_public_profile_id}
                    user_id={user_id}
                    endpoint_slug={endpoint_slug}
                    description={description}
                    tags={tags}
                    shouldApplyMargin={shouldApplyMargin}
                    contacts={contacts}
                    contact={contact}
                    email={coach.email}
                  />
                ))
              ) : (
                <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
                  <SvgUsers />
                  <h5 className="font-bold text-[16px] text-center max-w-[300px]">
                    {getNoContactString()}
                  </h5>
                </div>
              )
            ) : foundContacts?.length > 0 ? (
              foundContacts.map((contact: any, index: number) => (
                <ContactMobileListItem
                  key={contact.profile_id || `cont${index}`}
                  contact={contact}
                  setContactRequestFull={setContactRequestFull}
                  type={type}
                  setShowInviteModal={setShowInviteModal}
                  showInviteButton={showInviteButton && index === 0}
                />
              ))
            ) : (
              <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
                <SvgUsers />
                <h5 className="font-bold text-[16px] text-center max-w-[300px]">
                  {getNoContactString()}
                </h5>
              </div>
            )}
          </div>
        ) : null}
      </div>

      {/* DESKTOP */}
      <div className="hidden w-full md:flex mx-auto items-center md:flex-row flex-wrap gap-[8px] justify-center md:justify-start">
        {type === "coachdirectory" ? (
          coaches.length > 0 ? (
            coaches.map((coach: CoachCard, index: number, contact: any) => (
              <div className="gap-[8px]" key={coach.coach_public_profile_id}>
                <CoachDirectory
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  coaches={coaches}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  first_name={coach.first_name}
                  last_name={coach.last_name}
                  avatar_url={coach.avatar_url}
                  updated_at={coach.updated_at}
                  subscription_promo_codes={coach.subscription_promo_codes}
                  max_end_date={coach.max_end_date}
                  coach_profile_id={coach.coach_profile_id}
                  coach_public_profile_id={coach.coach_public_profile_id}
                  user_id={user_id}
                  endpoint_slug={endpoint_slug}
                  description={description}
                  tags={tags}
                  shouldApplyMargin={shouldApplyMargin}
                  contacts={contacts}
                  contact={contact}
                  email={coach.email}
                />
              </div>
            ))
          ) : (
            <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
              <SvgUsers />
              <h5 className="font-bold text-[18px] w-[355px] text-center">
                {getNoContactString()}
              </h5>
            </div>
          )
        ) : foundContacts?.length > 0 ? (
          foundContacts.map((contact: any, index: number) => {
            // console.log("CONTACT'S PROFILE IDS: ", contact.profile_id)
            return (
              <div className="gap-[8px]" key={contact.profile_id}>
                <ContactCard
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  contact={contact}
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  shouldApplyMargin={shouldApplyMargin(
                    index,
                    foundContacts.length
                  )}
                />
              </div>
            )
          })
        ) : (
          <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
            <SvgUsers />
            <h5 className="font-bold text-[18px] w-[355px] text-center">
              {getNoContactString()}
            </h5>
          </div>
        )}
      </div>
    </>
  )
}

export default ContactsList
