import * as React from "react";

import { cn } from "../../utils/cn";
import { FieldError } from "./field-error";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <div>
        <textarea
          className={cn(
            "border-input bg-background placeholder:text-muted-foreground flex min-h-[80px] w-full cursor-text rounded-md border px-3 py-2 text-sm transition-colors focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            "hover:bg-grayFlash hover:border-grayFlash border-grayCloud focus-within:border-blurple focus-within:hover:border-blurple",
            className,
            { "border-vividRed": props.error },
          )}
          ref={ref}
          {...props}
        />

        <FieldError error={props.error} />
      </div>
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
