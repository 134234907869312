import type { SVGProps } from "react";
export const SvgArrowLeftRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 8L13.5 11.5M13.5 11.5L11 15M13.5 11.5H1.5"
      stroke="#4750F5"
      strokeWidth="1.25"
      strokeLinecap="round" />
    <path
      d="M4 1L1.5 4.5M1.5 4.5L4 8M1.5 4.5H13.5"
      stroke="#4750F5"
      strokeWidth="1.25"
      strokeLinecap="round" />
  </svg>

);
