import type { SVGProps } from "react";
export const SvgConfirmEmailAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 54 46"
    {...props}
  >
    <path
      fill="currentColor"
      d="M40.148 15.324V2.995a2.96 2.96 0 0 0-.842-2.1A2.97 2.97 0 0 0 37.223 0H2.925A2.97 2.97 0 0 0 .842.894 2.96 2.96 0 0 0 0 2.995v40.01c-.009.784.294 1.54.842 2.1A2.97 2.97 0 0 0 2.925 46h34.333a2.97 2.97 0 0 0 2.083-.894 2.96 2.96 0 0 0 .841-2.101v-5.798a.24.24 0 0 1 .039-.154L53.879 18.24c.231-.307.123-1.847-.261-1.847l-13.366-.023c-.162.03-.104-3.456-.104-1.045"
    />
    <path
      fill="#fff"
      d="M12.448 27.544a.645.645 0 0 1-.644-.652v-8.05l5.781 5.182c.457.414 1.04.625 1.631.625.583 0 1.166-.211 1.614-.625l5.787-5.182v2.621a5.3 5.3 0 0 1 1.805.373v-3.388c0-.66-.271-1.288-.763-1.77A2.45 2.45 0 0 0 25.982 16H12.448c-.627 0-1.228.246-1.694.686A2.46 2.46 0 0 0 10 18.448v8.444a2.45 2.45 0 0 0 2.448 2.448h8.825a5.6 5.6 0 0 1-.465-1.796z"
    />
    <path
      fill="#fff"
      d="M26.406 23.149a3.93 3.93 0 0 0-3.922 3.921A3.92 3.92 0 0 0 26.406 31a3.92 3.92 0 0 0 3.921-3.93 3.93 3.93 0 0 0-3.921-3.921m1.744 3.777-1.973 1.322a.67.67 0 0 1-.356.101.64.64 0 0 1-.516-.271l-.678-.966a.637.637 0 0 1 .16-.88.627.627 0 0 1 .882.152l.322.457 1.456-.974a.64.64 0 0 1 .881.178.63.63 0 0 1-.178.881"
    />
  </svg>
);
