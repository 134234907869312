import React, { useMemo } from "react";
import { format, add, differenceInMinutes } from "date-fns";
import moment from "moment";

type TimeOptionsProps = {
    availabilityStart: Date;
    availabilityEnd: Date;
    duration: number;
    startTime: Date | null;
    setStartTime: any;
    dayColor: string;
};

const TimeOptions: React.FC<TimeOptionsProps> = ({
    availabilityStart,
    availabilityEnd,
    duration,
    setStartTime,
    startTime,
    dayColor,
}) => {
    const MEETING_START_TIME_INTERVAL = 15;

    const generateStartTimes = (
        availabilityStart: Date,
        availabilityEnd: Date,
        duration: number
    ): Array<Date> => {
        // clamp start time to a quarter hour if it's not already on the quarter hour
        let adjustedAvailabilityStartTime = availabilityStart;
        const quarterHours = [0, 15, 30, 45];
        if (!quarterHours.includes(moment(availabilityStart).minutes())) {
            const minutesToAdd = 15 - (moment(availabilityStart).minutes() % 15);
            adjustedAvailabilityStartTime = moment(availabilityStart)
                .set("minute", moment(availabilityStart).minutes() + minutesToAdd)
                .toDate();
        }
        // loop through available times in multiples of the duration; add options to array until the end time is exceeded
        const numLoops =
            differenceInMinutes(availabilityEnd, adjustedAvailabilityStartTime) /
            MEETING_START_TIME_INTERVAL;
        const options = [];
        for (let i = 0; i < numLoops; i++) {
            const currentTime = new Date();
            const t = add(adjustedAvailabilityStartTime, {
                minutes: MEETING_START_TIME_INTERVAL * i,
            });
            if (add(t, { minutes: duration }) > availabilityEnd) {
                break;
            } else {
                if (t > currentTime) {
                    options.push(t);
                }
            }
        }
        return options;
    };

    // const dayColor = coachPublicProfileData?.profile_information?.profileColor; 

    const startTimes = useMemo(() => {
        return generateStartTimes(availabilityStart, availabilityEnd, duration);
    }, [availabilityStart, availabilityEnd, duration]);

    return (
        <>
            {startTimes.map((t, idx) => {
                const startTimeLabel = format(t, "h:mm aa");
                const endTimeLabel = format(add(t, { minutes: duration }), "h:mm aa");
                const isSelected = moment(startTime).isSame(moment(t));

                return (
                    <button
                        key={idx}
                        onClick={() => {
                            setStartTime(t);
                        }}
                        className={`${
                            isSelected ? "text-white" : "bg-hoverDark"
                        } flex items-center justify-center p-[12px] rounded-[10px] text-base font-bold cursor-pointer`}
                        style={{
                            backgroundColor: isSelected ? dayColor : "",
                        }}
                        aria-label={`${startTimeLabel} to ${endTimeLabel}`}
                    >
                        {startTimeLabel}&nbsp;-&nbsp;{endTimeLabel}
                    </button>
                );
            })}
        </>
    );
};

export default TimeOptions;
