import type { SVGProps } from "react";
export const SvgMonday = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 48 29"
    {...props}
  >
    <path
      fill="#FF3D57"
      d="M5.723 28.134a5.732 5.732 0 0 1-4.85-8.772L11.184 2.893a5.73 5.73 0 1 1 9.714 6.083l-10.315 16.47a5.73 5.73 0 0 1-4.86 2.688"
    />
    <path
      fill="#FFCB00"
      d="M23.495 28.134a5.732 5.732 0 0 1-4.86-8.772L28.953 2.893a5.73 5.73 0 1 1 9.7 6.083l-10.3 16.47a5.73 5.73 0 0 1-4.861 2.688z"
    />
    <path
      fill="#00D647"
      d="M41.262 28.131c3.17 0 5.74-2.505 5.74-5.596 0-3.09-2.57-5.595-5.74-5.595s-5.74 2.505-5.74 5.595 2.57 5.596 5.74 5.596"
    />
  </svg>
);
