import * as React from "react";
import { AuthModal } from "./auth-modal";
import { AccountType } from "./register-form/context";
import { GetUserReturnType } from "../../context/commonFunctionContext"

export type AuthAction = "login" | "register";

export type OnSuccessfulAuthCallback = (user: GetUserReturnType) => void;

export interface AuthContext {
  authAction: AuthAction;
  setAuthAction: (authAction: AuthAction) => void;

  open: boolean;
  setOpen: (open: boolean) => void;

  email: string;
  setEmail: (email: string) => void;

  password: string;
  setPassword: (password: string) => void;

  phone: string;
  setPhone: (phone: string) => void;
  
  firstName: string;
  setFirstName: (name: string) => void;

  lastName: string;
  setLastName: (name: string) => void;

  accountType: AccountType;
  setAccountType: (accountType: AccountType) => void;

  onSuccessfulAuth: OnSuccessfulAuthCallback | undefined;
  setOnSuccessfulAuth: (callback: OnSuccessfulAuthCallback | undefined) => void;
}

export const AuthContext = React.createContext<AuthContext>(
  {} as AuthContext,
);

export const AuthProvider: React.FC<
  React.PropsWithChildren
> = (
  { children },
) => {
  const [authAction, setAuthAction] = React.useState<AuthAction>("register");
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [accountType, setAccountType] = React.useState<AccountType>(null);
  const [onSuccessfulAuth, setOnSuccessfulAuth] = React.useState<OnSuccessfulAuthCallback | undefined>(undefined);

  return (
    <AuthContext.Provider
      value={{
        authAction,
        setAuthAction,
        open,
        setOpen,
        email,
        setEmail,
        password,
        setPassword,
        phone,
        setPhone,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        accountType,
        setAccountType,
        onSuccessfulAuth,
        setOnSuccessfulAuth,
      }}
    >
      <AuthModal />
      {children}
    </AuthContext.Provider>
  );
};
