import { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import LoginEmail from "./LoginEmail";
import LoginPassword from "./LoginPassword";
import ForgotPassword from "./ForgotPassword";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import CloseModal from "../CloseModal";
import OneTimePasscode from "./OneTimePasscode";
import Loader from "../ui/loader";

export type View = "email" | "password" | "forgot-pass" | "otp";

type Props = {
  successRedirectPathname?: string;
  successRedirectSearchParams?: any;
  preRedirectActions?: any;
};

export default function AuthModal({ successRedirectPathname, successRedirectSearchParams, preRedirectActions }: Props) {
  const { setShowAuthModal, setInitializeCreateAccountModal } = useContext(
    CommonFunctionCtx,
  );
  const [showGetStarted, setShowGetStarted] = useState<boolean>(false);
  const [emailLoginObject, setEmailLoginObject] = useState<any>(null);

  const [view, setView] = useState<View>("email");
  const [modalLoading, setModalLoading] = useState<boolean | string>(false);

  const renderView = () => {
    switch (view) {
      case "email":
        return (
          <LoginEmail
            setView={setView}
            setEmailLoginObject={setEmailLoginObject}
            setShowGetStarted={setShowGetStarted}
            isModalVariant={true}
            setModalLoading={setModalLoading}
          />
        );
      case "password":
        return (
          <LoginPassword
            setView={setView}
            emailLoginObject={emailLoginObject}
            isModalVariant={true}
            setModalLoading={setModalLoading}
            modalLoading={modalLoading}
            successRedirectPathname={successRedirectPathname}
            successRedirectSearchParams={successRedirectSearchParams}
            preRedirectActions={preRedirectActions}
          />
        );
      case "forgot-pass":
        return (
          <ForgotPassword
            setView={setView}
            isModalVariant={true}
          />
        );
      case "otp":
        return (
          <OneTimePasscode
            setView={setView}
            emailLoginObject={emailLoginObject}
            isModalVariant={true}
            setModalLoading={setModalLoading}
            modalLoading={modalLoading}
            successRedirectPathname={successRedirectPathname}
            successRedirectSearchParams={successRedirectSearchParams}
            preRedirectActions={preRedirectActions}
          />
        );
      default:
        console.error("Invalid view");
        return <p className="font-bold text-vividRed">Invalid view</p>;
    }
  };

  useEffect(() => {
    if (showGetStarted) {
      setShowAuthModal(false);
      setInitializeCreateAccountModal({
        userType: "get-started",
        options: {
          successRedirectPathname: successRedirectPathname,
          successRedirectSearchParams: successRedirectSearchParams,
          preRedirectActions: preRedirectActions,
          redirectToDashboard: true,
        },
      });
    } else if (preRedirectActions?.forceMemberSignup) {
      setShowAuthModal(false);
      setInitializeCreateAccountModal({
        userType: "member",
        options: {
          successRedirectPathname: successRedirectPathname,
          successRedirectSearchParams: successRedirectSearchParams,
          preRedirectActions: preRedirectActions,
          redirectToDashboard: true,
        },
      });
    } else if (preRedirectActions?.forceMemberLogin) {
      setEmailLoginObject(preRedirectActions.emailObject);
      setView("password");
    }
  }, [showGetStarted]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
        z-[801] flex items-center justify-center pb-[88px] md:pb-[40px]"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="relative flex flex-col items-center bg-white min-w-[360px] md:w-[600px] h-auto rounded-[16px] pt-[24px] pb-[72px] md:px-[120px] px-[16px]"
      >
        <CloseModal
          callback={() => setShowAuthModal(false)}
          styling="absolute top-[24px] right-[24px]"
        />

        <h4 className="font-bold mb-[32px]">Sign in</h4>

        {modalLoading
          ? (
            <div className="mt-[60px] mb-[30px] flex flex-col items-center gap-6">
              <Loader />
              {typeof modalLoading === "string" && (
                <p>{modalLoading}</p>
              )}
            </div>
          )
          : renderView()}
      </motion.div>
    </motion.div>
  );
};
