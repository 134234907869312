import * as React from "react";
import { cn } from "../../utils/cn";
import { Badge } from "../ui/badge";

interface Props {
  children: React.ReactNode;
  className?: string;
  outerClassName?: string;
}

const NavShell: React.FC<Props> = ({ children, className, outerClassName }) => {
  return (
    <nav
      className={cn("relative top-2.5 z-30 mx-2.5 sm:sticky", outerClassName)}
    >
      <div
        className={cn(
          "relative mx-auto h-16 w-full max-w-screen-xl rounded-lg bg-white px-2.5 sm:px-4",
          className,
        )}
        style={{
          boxShadow:
            "0 10px 20px -5px rgba(111, 89, 246, 0.15), 0 -5px 10px -5px rgba(111, 89, 246, 0.15), 0 6px 10px -5px rgba(111, 89, 246, 0.10)",
        }}
      >
        <EnvBadge />
        {children}
      </div>
    </nav>
  );
};

export default NavShell;

const EnvBadge: React.FC = () => {
  let env = process.env.REACT_APP_USER_ENVIRONMENT as "development" | "qa";

  return (
    <>
      {["development", "qa"].includes(env) && (
        <Badge
          className="absolute bottom-0 left-0 z-50 -translate-x-1/2 translate-y-1/2"
          variant={
            env === "development" ? "default" : env === "qa" ? "alert" : null
          }
        >
          {env === "development" && "dev"}
          {env === "qa" && "qa"}
        </Badge>
      )}
    </>
  );
};
