import { useEffect } from "react";

/**
 * Scrolls the window to the top when the component mounts.
 *
 * @param deps - Optional dependencies array to trigger the effect.
 * @remarks
 * Pass an optional dependencies array to specify when the effect should be triggered.
 * If no dependencies are provided, the effect will only run once when the component mounts.
 *
 * Usage:
 * ```tsx
 * useScrollToTop(); // Scrolls to top on mount
 *
 * useScrollToTop(dep1, dep2); // Scrolls to top when dep1 or dep2 change
 * ```
 */
function useScrollToTop(...deps: any[]) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant" as ScrollBehavior,
    });
  }, deps);
}

export default useScrollToTop;
