import type { SVGProps } from "react";
export const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.89 9.554c0 8.02 1.154 11.644-6.61 11.644S5.693 17.573 5.693 9.554"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.5 2.25c-1.094 0-1.945.205-2.596.561-.66.36-1.073.855-1.32 1.36A3.4 3.4 0 0 0 8.25 5.73H4.215a.75.75 0 0 0 0 1.5h16.15a.75.75 0 0 0 0-1.5H16.75q0-.105-.003-.232a3.4 3.4 0 0 0-.33-1.328 3.1 3.1 0 0 0-1.321-1.36c-.651-.356-1.502-.56-2.596-.56m2.749 3.48a1.87 1.87 0 0 0-.18-.9 1.6 1.6 0 0 0-.693-.703c-.377-.206-.964-.377-1.876-.377s-1.5.17-1.876.377a1.6 1.6 0 0 0-.693.703 1.9 1.9 0 0 0-.18.9z"
      clipRule="evenodd"
    />
  </svg>
);
