import React from "react";
import Select from "react-select";
import useManageServices, { ServiceType } from "../hooks/useManageServices";
import {
  installmentIncrementOptions,
  packageBillingTypes,
  timeTypes,
  numbers,
} from "../../../objects/createServiceObjects";
import AvailabilityRulesSelect from "../AvailabilityRulesSelect";
import { Switch } from "../../ui/switch";
import { Label } from "../../ui/label";
import { SvgPiechartOneQuarter, SvgTrash, SvgWarning } from "../../icons";
import { Button } from "../../ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "../../ui/dropdown-menu";
import currencyCodesData from './currencyCodesData.json'; 
import { pluralize } from "../../../utils/pluralize";
import { getOrdinal } from "../../../utils/get-ordinal";

interface Props {
  formErrors: any;
  serviceValues: any;
  setServiceValues: any;
  handleExpiresToggle: any;
  serviceType: ServiceType;
  isGroupService: boolean;
  availabilities: Array<any>;
  handleAvailabilityRulesChange: any;
  service: any;
}

const PackageForm: React.FC<Props> = ({
  formErrors,
  serviceValues,
  setServiceValues,
  handleExpiresToggle,
  serviceType,
  isGroupService,
  availabilities,
  handleAvailabilityRulesChange,
  service,
}) => {
  const { getAvailableTimeTypes } = useManageServices();

  const showSessionCost = () => {
    let showCost = false;
    if (isGroupService) {
      if (!serviceValues?.group?.isFree) {
        showCost = true;
      }
    } else {
      if (serviceType === "consultation") {
        if (!serviceValues.isFree) {
          showCost = true;
        }
      } else {
        showCost = true;
      }
    }
    return showCost;
  };

  React.useEffect(() => {
    if (serviceValues.group.isFree) {
      setServiceValues({
        ...serviceValues,
        package: { ...serviceValues.package, packageCost: 0.0 },
      });
    }
  }, [serviceValues.group]);

  const increaseInstallmentCount = (index: any) => {
    let timeToSet = 1;
    let typeToSet = "months";
    if (!index === null) {
      timeToSet = serviceValues.package.installmentIncrements[index].time;
      typeToSet = serviceValues.package.installmentIncrements[index].type;
    }
    let incrementsArray = serviceValues.package.installmentIncrements;
    incrementsArray.push({ time: timeToSet, type: typeToSet });
    setServiceValues({
      ...serviceValues,
      package: {
        ...serviceValues.package,
        installmentIncrements: incrementsArray,
      },
    });
  };

  const removeLastInstallment = () => {
    let incrementsArray = serviceValues.package.installmentIncrements;
    incrementsArray.pop();
    setServiceValues({
      ...serviceValues,
      package: {
        ...serviceValues.package,
        installmentIncrements: incrementsArray,
      },
    });
  };

  const handleChangeInstallmentTime = (e: any, index: any) => {
    let incrementsArray = serviceValues.package.installmentIncrements;
    let incrementsObject = incrementsArray[index];
    incrementsObject["time"] = e.value;
    incrementsArray[index] = incrementsObject;
    setServiceValues({
      ...serviceValues,
      package: {
        ...serviceValues.package,
        installmentIncrements: incrementsArray,
      },
    });
  };

  const handleChangeInstallmentType = (e: any, index: any) => {
    let incrementsArray = serviceValues.package.installmentIncrements;
    let incrementsObject = incrementsArray[index];
    incrementsObject["type"] = e.value;
    incrementsArray[index] = incrementsObject;
    setServiceValues({
      ...serviceValues,
      package: {
        ...serviceValues.package,
        installmentIncrements: incrementsArray,
      },
    });
  };

  const getFirstInstallmentAmount = () => {
    const upFrontPercentage = serviceValues?.package?.upFrontPercentage / 100;
    return (serviceValues?.package?.packageCost * upFrontPercentage).toFixed(2);
  };

  const getSubsequentInstallmentAmount = () => {
    const firstInstallmentAmount = parseFloat(getFirstInstallmentAmount());
    const numberInstallments = serviceValues?.package?.installmentIncrements?.length;
    const remainingAmount = parseFloat(serviceValues?.package?.packageCost) - parseFloat(firstInstallmentAmount.toFixed(2));
    const subsequentInstallmentAmount = (remainingAmount / numberInstallments).toFixed(2)
    return subsequentInstallmentAmount;
  };

  React.useEffect(() => {
    if (serviceValues?.package?.installmentIncrements?.length === 0) {
      setServiceValues({
        ...serviceValues,
        package: {
          ...serviceValues.package,
          upFrontPercentage: 100,
        },
      });
    }
  }, [serviceValues?.package?.installmentIncrements?.length]);

  React.useEffect(() => {
    if (serviceValues?.package?.upFrontPercentage === 100) {
      setServiceValues({
        ...serviceValues,
        package: {
          ...serviceValues.package,
          installmentIncrements: [],
        },
      });
    }
  }, [serviceValues?.package?.upFrontPercentage]);

  React.useEffect(() => {
    const packageCostInput =
      document.getElementById("packageCostInput") || null;
    const percentDueInput = document.getElementById("percentDue") || null;
    if (packageCostInput) {
      packageCostInput.addEventListener("keydown", function (e) {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      });
    }
    if (percentDueInput) {
      percentDueInput.addEventListener("keydown", function (e) {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      });
    }
  }, []);

  interface CurrencyCodes {
    code: string,
    symbol: string
  }

  const [currencyCodes, setCurrencyCodes] = React.useState<CurrencyCodes[]>([]);

  React.useEffect(() => {
    // Simulating fetching data from JSON file
    const data: CurrencyCodes[] = currencyCodesData.codes
    setCurrencyCodes(data);
  }, []);

  const handleCurrencySelect = (code: CurrencyCodes) => {
    setServiceValues({
      ...serviceValues,
      currency: code.code,
      symbol: code.symbol
    })
    // Perform any other actions on currency select
  };

  return (
    <>
      <h4 className="font-bold text-base mb-[16px]">
        How many sessions in this package?
      </h4>
      <div
        className={`default-input mb-[24px] w-full ${
          formErrors?.packageSessions && "input-error"
        }`}
      >
        <input
          type="number"
          min="0"
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          value={serviceValues?.package?.packageSessions}
          disabled={service.enrolled_count >= 1}
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              package: {
                ...serviceValues.package,
                packageSessions: parseInt(e.target.value),
              },
            })
          }
          name="packageSessions"
        />
        <div className="overlay">Sessions</div>
      </div>
      {formErrors.packageSessions && (
        <div className="field-error w-full">
          <SvgWarning />
          {formErrors.packageSessions}
        </div>
      )}

      <h4 className="font-bold text-base mb-[16px]">
        What is the length of each session?
      </h4>
      <div
        className={`${
          formErrors?.sessionLength && "input-error"
        } flex items-center gap-[12px] mb-[24px]`}
      >
        <input
          type="number"
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          min="0"
          max="60"
          className="w-[54px] h-[54px] bg-white focus:outline-0 border
                    border-1 border-grayCloud rounded-[10px] text-graySlate text-center"
          value={serviceValues.sessionLength.minutes}
          name="sessionMinutes"
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              sessionLength: { minutes: e.target.value },
            })
          }
        />
        <span className="font-bold text-base">minutes</span>
      </div>
      {formErrors.sessionLength && (
        <div className="field-error w-[100%]">
          <SvgWarning />
          {formErrors.sessionLength}
        </div>
      )}

      
      {/* SELECT WITH EXISTING AVAILABILITY RULES */}
      {availabilities?.length > 0 && (
          <AvailabilityRulesSelect availabilities={availabilities} serviceType={serviceType} service={serviceValues} handleAvailabilityRulesChange={handleAvailabilityRulesChange} />      
      )}
      
      <div className="w-full h-px bg-grayMist my-[32px]"></div>

      {/* GROUP IS FREE TOGGLE */}
      {isGroupService && (
        <>
          <div className="flex flex-col mb-[20px]">
            <h4 className={`font-bold text-base mb-[8px] ${service.enrolled_count >= 1 ? 'text-graySlate' : ''}`}>
              Is this a free group?
            </h4>
            <div className="flex items-center gap-2">
              <Switch onCheckedChange={() => setServiceValues({ ...serviceValues, group: { ...serviceValues.group, isFree: !serviceValues.group.isFree }})} checked={serviceValues.group.isFree} id="free" disabled={service.enrolled_count >= 1} />
              <Label htmlFor="free">Free</Label>
            </div>
          </div>
        </>
      )}
      {showSessionCost() && (
        <>
          {/* Package price */}
          <h4 className={`font-bold text-base mb-[8px] ${service.enrolled_count >= 1 ? 'text-graySlate' : ''}`}>Package price</h4>
        <div className={`relative ${formErrors?.packageCost && "input-error"} default-input w-full`}>
          <div className="flex">
              <DropdownMenu>
              <DropdownMenuTrigger asChild>
              <Button size="icon" variant="utility">
                {serviceValues.currency}
              </Button>
            </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel className="text-graySlate text-xs p-2">
                Currency
                </DropdownMenuLabel>
                <DropdownMenuGroup>
                  {currencyCodes.map((code) => (
                    <>
                    <DropdownMenuItem onClick={() => handleCurrencySelect(code)}>
                      {code.code}
                    </DropdownMenuItem>
                    </>
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
            <input
              id="packageCostInput"
              type="number"
              onWheel={() => (document.activeElement as HTMLElement)?.blur()}
              step="0.01"
              min="0"
              autoComplete="off"
              name="packageCost"
              placeholder="Cost"
              value={serviceValues.package.packageCost}
              disabled={service.enrolled_count >= 1}
              onChange={(e: any) =>
                setServiceValues({
                  ...serviceValues,
                  package: {
                    ...serviceValues.package,
                    packageCost: e.target.value,
                  },
                })
              }
              className="ml-2 flex-1 peer border border-gray-300 py-2 focus:outline-none focus:border-blue-500"
            />
          </div>
           
          {formErrors.packageCost && (
            <div className="field-error w-full">
              <SvgWarning />
              {formErrors.packageCost}
            </div>
          )}
          </div>

          {showSessionCost() && (
            <div className="flex flex-col">
              <h4 className="font-bold text-base mb-[8px]">
                Enable clients to use your promo codes?
              </h4>
              <div className="flex items-center gap-2">
                <Switch onCheckedChange={() => setServiceValues({ ...serviceValues, allowPromo: !serviceValues.allowPromo })} checked={serviceValues.allowPromo} disabled={serviceValues.package.packageBillingType === 'installment'} id="promo" />
                <Label htmlFor="free">Enabled</Label>
              </div>
            </div>
          )}
          {/* Package billing type */}

          <div className="w-full h-px bg-grayMist my-[32px]"></div>

          <div
            className={`flex flex-col ${
              serviceValues?.package?.packageBillingType === "installment"
                ? "mb-[24px]"
                : ""
            }`}
          >
            <h4 className="font-bold text-base mb-[8px]">
              How do you want to bill the client?
            </h4>
            <Select
              className="basic-single w-full"
              classNamePrefix="select"
              options={packageBillingTypes}
              value={packageBillingTypes.filter(
                (option) =>
                  option.value === serviceValues.package.packageBillingType
              )}
              menuPlacement="bottom"
              name="packageBillingType"
              isDisabled={service.enrolled_count >= 1}
              onChange={(e: any) =>
                setServiceValues({
                  ...serviceValues,
                  allowPromo: e.value === 'bulk',
                  package: {
                    ...serviceValues.package,
                    packageBillingType: e.value,
                  },
                })
              }
            />
          </div>

          {/* Installment Section */}

          {serviceType === "bundle" &&
            serviceValues.package.packageBillingType === "installment" && (
              <>
                <div className="mb-[24px]">
                  <h4 className="font-bold text-base mb-[8px]">
                    Percent due at booking
                  </h4>
                  <div
                    className={`${
                      formErrors?.upFrontPercentage && "input-error"
                    } default-input w-full`}
                  >
                    <input
                      id="percentDue"
                      onWheel={() => (document.activeElement as HTMLElement)?.blur()}
                      disabled={
                        serviceValues?.package?.installmentIncrements
                          ?.length === 0 || service.enrolled_count >= 1
                      }
                      type="number"
                      step="1"
                      min="0"
                      autoComplete="off"
                      name="upFrontPercentage"
                      placeholder="Percentage"
                      value={serviceValues?.package?.upFrontPercentage}
                      onChange={(e: any) =>
                        setServiceValues({
                          ...serviceValues,
                          package: {
                            ...serviceValues.package,
                            upFrontPercentage: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="overlay">Percentage</div>
                    <div
                      className="absolute top-[50%] -translate-y-[50%] right-[20px] text-graySlate peer-focus:text-black peer-hover:text-grayCharcoal"
                    >
                      %
                    </div>
                  </div>
                  {formErrors.upFrontPercentage && (
                    <div className="field-error w-full">
                      <SvgWarning />
                      {formErrors.upFrontPercentage}
                    </div>
                  )}
                </div>

                {/* List of installments after first one */}

                <div className="mb-[24px]">
                  {serviceValues.package.installmentIncrements.length > 0 ? (
                    <h4 className="font-bold text-base mb-[8px]">
                      Payment due
                    </h4>
                  ) : (
                    <>
                      <h4 className="font-bold text-base mb-[8px]">
                        No installments planned
                      </h4>
                      <button
                        className="btn-primary btn-secondary-nav mr-auto"
                        disabled={
                          serviceValues?.package?.installmentIncrements
                            .length >= 12
                        }
                        onClick={() => increaseInstallmentCount(null)}
                      >
                        Add another installment
                      </button>
                    </>
                  )}
                  {serviceValues.package.installmentIncrements.map(
                    (input: number, index: number) => (
                      <div key={index}>
                        <div className="mb-[8px]">
                          <div className="flex items-center justify-between">
                            <div className="grid grid-cols-[1fr_1fr_40px] items-center gap-2 w-full">
                              <Select
                                className="basic-single w-full"
                                classNamePrefix="select"
                                options={installmentIncrementOptions}
                                menuPlacement="bottom"
                                value={installmentIncrementOptions.filter(
                                  (option) =>
                                    option.value ===
                                    serviceValues.package.installmentIncrements[
                                      index
                                    ].time
                                )}
                                onChange={(e: any) =>
                                  handleChangeInstallmentTime(e, index)
                                }
                                isDisabled={service.enrolled_count >= 1}
                              />
                              <Select
                                className="basic-single min-w-[35%]"
                                classNamePrefix="select"
                                options={timeTypes}
                                menuPlacement="bottom"
                                value={timeTypes.filter(
                                  (option) =>
                                    option.value ===
                                    serviceValues.package.installmentIncrements[
                                      index
                                    ].type
                                )}
                                onChange={(e: any) =>
                                  handleChangeInstallmentType(e, index)
                                }
                                isDisabled={service.enrolled_count >= 1}
                              />
                              {index === serviceValues.package.installmentIncrements.length - 1 && (
                                <Button size="icon" variant="ghost" onClick={removeLastInstallment} disabled={service.enrolled_count >= 1}>
                                  <SvgTrash />
                                </Button>
                              )}
                            </div>
                          </div>
                          {formErrors.installmentIncrements && (
                            <div className="field-error w-full">
                              <SvgWarning />
                              {formErrors.installmentIncrements}
                            </div>
                          )}
                        </div>
                        {index ===
                          serviceValues.package.installmentIncrements.length -
                            1 && (
                          <Button
                            variant="secondary"
                            disabled={
                              serviceValues?.package?.installmentIncrements
                                .length >= 12 || service.enrolled_count >= 1
                            }
                            onClick={() => increaseInstallmentCount(index)}
                          >
                            Add another installment
                          </Button>
                        )}
                      </div>
                    )
                  )}
                </div>

                <div className="mb-[60px]">
                  <h4 className="font-bold text-base mb-[8px]">Preview</h4>
                  <div className="w-full border rounded-xl p-[20px] flex flex-col">
                    <h5 className="text-graySlate text-base mb-[32px]">
                      Payment Schedule
                    </h5>
                    <div className="flex w-full items-center justify-between mb-[16px]">
                      <div className="flex items-center gap-[16px] min-w-fit">
                        <SvgPiechartOneQuarter />
                        <p className="text-[16px] whitespace-nowrap">Payment at booking</p>
                      </div>
                      <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
                      <span className="text-base">
                        {serviceValues.symbol && String.fromCharCode(parseInt(serviceValues.symbol.slice(2), 16))}
                        {getFirstInstallmentAmount()}
                      </span>
                    </div>
                    {serviceValues?.package?.installmentIncrements?.length >
                      0 &&
                      serviceValues?.package?.installmentIncrements.map(
                        (installment: any, index: number) => (
                          <div
                            key={index}
                            className="flex w-full items-center justify-between mb-[16px]"
                          >
                            <div className="flex items-center gap-[16px] min-w-fit">
                            <SvgPiechartOneQuarter />
                              <p className="text-[16px] whitespace-nowrap">
                                {getOrdinal(index)} installment {pluralize(installment.time, installment.type)} later
                              </p>
                            </div>
                            <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
                            <span className="text-base">
                            {serviceValues.symbol && String.fromCharCode(parseInt(serviceValues.symbol.slice(2), 16))}
                              {getSubsequentInstallmentAmount()}
                            </span>
                          </div>
                        )
                      )}
                    <div className="text-graySlate w-full bg-grayMist rounded-lg p-[26px] flex items-center justify-center text-base">
                      <p className="text-center">
                        Payments will be charged{" "}
                        <strong className="text-black">
                          {serviceValues?.recurring?.recurringFrequency}
                        </strong>{" "}
                        on the anniversary of the first payment.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

          {serviceType === "bundle" &&
            serviceValues.package.packageBillingType === "bulk" && (
              <>
                <div className="w-full h-px bg-grayMist my-[32px]"></div>
                <h4 className="font-bold text-base mb-[8px]">Preview</h4>
                <div className="w-full border rounded-xl p-[20px] flex flex-col">
                  <h5 className="text-graySlate text-base mb-[32px]">
                    Payment Details
                  </h5>
                  <div className="flex w-full items-center justify-between mb-[16px]">
                    <div className="flex items-center gap-[16px] min-w-fit">
                      <div
                        className="w-[24px] h-[24px] bg-primaryBlue rounded-full text-white font-bold justify-center items-center flex"
                      >
                       { serviceValues.symbol && String.fromCharCode(parseInt(serviceValues.symbol.slice(2), 16))}
                      </div>
                      <p className="text-[16px]">Payment at checkout</p>
                    </div>
                    <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
                    <span className="text-base">
                    {serviceValues.symbol && String.fromCharCode(parseInt(serviceValues.symbol.slice(2), 16))}
                      {`${parseInt(
                        serviceValues?.package?.packageCost
                      ).toFixed(2)}`}
                    </span>
                  </div>
                  <div
                    className="text-graySlate w-full bg-grayMist rounded-lg p-[26px] flex items-center justify-center text-base"
                  >
                    <p className="text-center">
                      Payment will be charged{" "}
                      <strong className="text-black">immediately</strong> at
                      checkout.
                    </p>
                  </div>
                </div>
              </>
            )}
        </>
      )}

      {/* Attach availability */}
      {/* HIDE for now. Need to fetch availability schedules for coach and add to select dropdown, etc... */}

      {/* <div className="flex items-center w-full justify-between mb-[8px]">
                <h4 className="font-bold text-base">
                    Attach Availability
                </h4>
                <img src={faq} alt="faq" />
            </div>
            
            <div
                className={`default-input w-full`}
            >
                <Select
                    className="basic-single w-full"
                    classNamePrefix="select"
                    // value={numbers.filter(
                    //     (option) =>
                    //         option.value ===
                    //         serviceValues.package.packageBillingType
                    // )}
                    options={numbers}
                    menuPlacement="bottom"
                    name="attachedAvailability"
                    onChange={(e: any) => setServiceValues({
                        ...serviceValues,
                        package: {
                            ...serviceValues.package,
                            packageBillingType: e.value,
                        }
                    })}
                />
            </div> */}

      {/* Expiration toggle and selector */}
      {!isGroupService && (
        <>
          <div className="w-full h-px bg-grayMist my-[32px]"></div>
          <h4 className="font-bold text-base mb-[8px]">
            {`When does this 
                        ${serviceType === "consultation" ? "consultation" : ""} 
                        ${serviceType === "individual" ? "service" : ""}
                        ${serviceType === "bundle" ? "package" : ""}
                        expire?`}
          </h4>
          <div className="flex items-center gap-2 mb-[24px]">
            <Switch onCheckedChange={handleExpiresToggle} checked={!serviceValues?.expiration?.expires} id="no-set-date" />
            <Label htmlFor="no-set-date">No set date</Label>
          </div>
          {serviceValues?.expiration?.expires && (
            <>
              <div className="flex items-center justify-between">
                <Select
                  className="basic-single w-[49%]"
                  classNamePrefix="select"
                  options={numbers}
                  menuPlacement="bottom"
                  value={numbers.filter(
                    (number) =>
                      number.value === serviceValues?.expiration?.expireTime
                  )}
                  onChange={(e: any) =>
                    setServiceValues({
                      ...serviceValues,
                      expiration: {
                        ...serviceValues.expiration,
                        expireTime: e.value,
                      },
                    })
                  }
                />
                <Select
                  className="basic-single w-[49%]"
                  classNamePrefix="select"
                  options={getAvailableTimeTypes(
                    serviceType,
                    timeTypes,
                    serviceValues
                  )}
                  menuPlacement="bottom"
                  value={getAvailableTimeTypes(
                    serviceType,
                    timeTypes,
                    serviceValues
                  ).filter(
                    (type: any) =>
                      type.value === serviceValues?.expiration?.expireType
                  )}
                  onChange={(e: any) =>
                    setServiceValues({
                      ...serviceValues,
                      expiration: {
                        ...serviceValues.expiration,
                        expireType: e.value,
                      },
                    })
                  }
                />
              </div>
              {formErrors.expires && (
                <div className="field-error w-full">
                  <SvgWarning />
                  {formErrors.expires}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PackageForm;
