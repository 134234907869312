import { FC } from "react";
import TeamMobile from "../../../assets/images/MVteam.png";
import TeamDT from "../../../assets/images/DVteam.png"
import JoinTeamZoeeButton from "../uiux/JoinTeamZoeeButton";

type Props = {};

const WhoWeAre: FC<Props> = () => {
    return (
        <section className="relative xl:min-h-[1280px] lg:min-h-[1100px] gray-background-gradient translate-y-[-160px] md:translate-y-[-60px] pt-[60px] px-[24px]">
            <h2 className="text-[54px] md:text-[88px] font-bold text-center">Who We Are</h2>
            <p className="content-center max-w-[622px] mx-auto shrink">
                We are a team of dedicated professionals working to make the world a better place.
                Through tech that simplifies not only the back-office of a coaching business, but hosts
                an ecosystem for coaches to connect with one another, Zoee is here to change your
                life—and the world.
            </p>
            <div className="flex lg:hidden w-[100%] mt-[36px] mb-[54px] justify-center mx-auto">
                <img src={TeamMobile} alt="the Zoee team" />
            </div>
            <div className="lg:block hidden  relative top-[60px] grow mx-auto left-0 right-0">
                <img src={TeamDT} alt="the Zoee team" />
            </div>
            <div className="content-center top-[25%]  max-w-[622px] mx-auto">
                <p className="mb-[24px]">
                    We are digital nomads, firmly rooted families, Gen Zs, Baby Boomers, and
                    everything in between. We are a team that celebrates the small wins, the big
                    wins, the solved problems, and the hilarious Slack messages.
                </p>
                <p className="mb-[24px]">
                    We work hard, we don&apos;t give up, and we love what we do. Here at Zoee, there
                    are real humans behind the tech, the marketing materials, and the emails you
                    receive. We are real people making real change in the lives of our members.
                </p>
                <p className="mb-[32px]">
                    Come join us on this journey—we&apos;d love to add another member to the Zoee
                    community.
                </p>
                <div className="justify-items-center">
                <JoinTeamZoeeButton />    
                </div>
                
            </div>
        </section>
    );
};

export default WhoWeAre;
