// This component is not accessible in the current version of the app because the max number of contacts invited to a meeting is set to 6
// It needs updates before going live if it's still in the design

import { FC } from "react";
import useScrollToTop from "../../../hooks/useScrollToTop";
import BackToButton from "../../BackToButton";

type Props = {
    // TODO: update once we know what this data looks like
    sessionName: string;
    contacts: Array<number>;
    setShowAttendeesView: any;
};

// const placeholderContact = {
//     contact_type: "connected",
//     profile_id: 1000557,
//     user_id: 1000355,
//     contact_name: "Roger Federer",
//     has_avatar: 0,
//     user_color: "lightPurple",
//     contact_date: "2022-08-22 12:47:28",
// };

const AttendeesView: FC<Props> = ({
    sessionName,
    setShowAttendeesView,
}) => {
    useScrollToTop();

    return (
        <div>
            {/* TODO make dynamic for member? */}
            <BackToButton onClick={() => setShowAttendeesView(false)} prev={sessionName} current={sessionName} />
            <div className="w-[1080px] mx-auto grid grid-cols-4 gap-[16px]">
                {/* {contacts.map((g, idx) => {
                    return (
                        <ConnectedContactCard
                            key={idx}
                            variant="view"
                            contact={placeholderContact}
                        />
                    );
                })} */}
            </div>
        </div>
    );
};

export default AttendeesView;
