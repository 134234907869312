import React, { FC, useState } from "react"
import BackToButton from "../../components/BackToButton"
import ICFSubscriptionPlanCard from "./ICFSubscriptionPlanCard"
import Loader from "../../components/ui/loader"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { useNavigate } from "react-router-dom"
import { SubscriptionContext } from "../subscription/SubscriptionContext"
import { SvgChatEllipses, SvgZoeeIcon } from "../../components/icons"
import { useIntercom } from "react-use-intercom"
// import { SubscriptionRequests } from "../../api/app.service"
// import { UserCtx } from "../../context/userContext"

type Props = {}

const ICFSubscriptionCheckout: FC<Props> = () => {
  // const { user } = React.useContext(UserCtx)
  // const [plans, setPlans] = React.useState<any>(null)
  const { show } = useIntercom()
  const { setPopupNotification } = React.useContext(CommonFunctionCtx)
  // const [showSubscription, setShowSubscription] = React.useState<boolean>(true)
  // const [selectedSubscription, setSelectedSubscription] =
  //   React.useState<any>(null)
  // const [showSubscriptionCheckout, setShowSubscriptionCheckout] =
  //   React.useState<boolean>(false)
  //   const [showSubscriptionPlans, setShowSubscriptionPlans] =
  //   React.useState<boolean>(false)
  const {
    plans,
    setSelectedSubscription,
    showSubscriptionComponents,
    setShowSubscriptionCheckout,
    setShowSubscription,
  } = React.useContext(SubscriptionContext)

  const [selectedPlan, setSelectedPlan] = useState("annual") // Default to annual for toggle
  const navigate = useNavigate()
  // const { renderError } =
  //   React.useContext(CommonFunctionCtx)

  const handleChoosePlanClick = (chosenSubscription: any): void => {
    console.log(chosenSubscription)
    setSelectedSubscription(chosenSubscription)
    showSubscriptionComponents(setShowSubscriptionCheckout)
    // navigate("/icf/checkout")
  }

  // const showSubscriptionComponents = (callback: any) => {
  //   setShowSubscriptionPlans(false)
  //   setShowSubscriptionCheckout(true)
  //   callback(true)
  // }

  // const getSubscriptionPlans = () => {
  //   SubscriptionRequests.getSubscriptionPlans({
  //     user_id: parseInt(user.user_id),
  //   })
  //     .then((data) => {
  //       const annual = data?.plans?.find((plan: any) => {
  //         return plan.amount === 659
  //       })
  //       const monthly = data?.plans?.find((plan: any) => {
  //         return plan.amount === 69
  //       })
  //       const lifetime = data?.plans?.find((plan: any) => {
  //         return (
  //           plan.amount === 500 &&
  //           plan.is_promotional === true &&
  //           plan.recurring_interval_count !== 2
  //         )
  //       })
  //       const twoYears = data?.plans?.find((plan: any) => {
  //         return (
  //           plan.amount === 500 &&
  //           plan.is_promotional === true &&
  //           plan.recurring_interval_count === 2
  //         )
  //       })
  //       setPlans({
  //         annual: annual,
  //         monthly: monthly,
  //         lifetime: lifetime,
  //         twoYears: twoYears,
  //       })
  //     })
  //     .catch((ex) => {
  //       console.log(ex)
  //       renderError(ex.response.data.message)
  //     })
  // }

  // React.useEffect(() => {
  //   getSubscriptionPlans()
  // }, [])

  // useScrollToTop()
  // console.log(plans)

  const navigateBackToReg = () => {
    navigate("/coach")
  }

  return (
    <div className="mt-4 mb-[32px]">
      <SvgZoeeIcon className="h-14 w-14 md:-mb-6" />
      <BackToButton
        onClick={navigateBackToReg}
        current="Plans & Pricing"
        prev="Dashboard"
      />
      {/* <div className="h-24"></div> */}
      <div className="w-full flex flex-col items-right">
        <h1 className="font-bold flex text-[22px] md:text-[36px] text-center mb-[8px] -mt-4">
          Manage Subscription
        </h1>
        <div className="mb-[24px] flex items-center gap-[8px] max-w-[302px] md:max-w-none">
          <p className="text-graySlate text-center">
            * Payment and processing fees are not included.
            <span
              className="font-bold text-blurple md:hidden cursor-pointer"
              onClick={() =>
                setPopupNotification({
                  show: true,
                  title: "Coming Soon",
                  message:
                    "This feature will be available on a future version of Zoee!",
                })
              }
            >
              {" "}
              Learn More
            </span>
          </p>
          <button
            className="font-bold text-blurple md:block hidden"
            onClick={() =>
              setPopupNotification({
                show: true,
                title: "Coming Soon",
                message:
                  "This feature will be available on a future version of Zoee!",
              })
            }
          >
            Learn more
          </button>
        </div>
      </div>

      {/*   Toggle   */}
      <div className="md:hidden flex justify-center mb-4">
        <div className="relative flex justify-center items-center w-full max-w-xs rounded-[20px] border-2 border-black h-[50px]">
          <div className="p-[4px] h-full w-full justify-center items-center">
            <button
              onClick={() => setSelectedPlan("monthly")}
              className={`w-1/2 h-full rounded-[18px] ${
                selectedPlan === "monthly"
                  ? "bg-black text-white border-white"
                  : "bg-white text-black border-black"
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setSelectedPlan("annual")}
              className={`w-1/2 h-full rounded-[18px] ${
                selectedPlan === "annual"
                  ? "bg-black text-white border-white"
                  : "bg-white text-black border-black"
              }`}
            >
              Annual (Save 16%)
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        {plans ? (
          <>
            <div className="w-full flex flex-col md:flex-row items-center md:justify-center gap-[32px] mx-auto mb-[64px]">
              {/*   Mobile View for toggle   */}
              <div className="block md:hidden">
                {selectedPlan === "annual"
                  ? plans.annual && (
                      <ICFSubscriptionPlanCard
                        plan={plans.annual}
                        handleChoosePlanClick={handleChoosePlanClick}
                        title={`Annual Pro`}
                      />
                    )
                  : plans.monthly && (
                      <ICFSubscriptionPlanCard
                        plan={plans.monthly}
                        handleChoosePlanClick={handleChoosePlanClick}
                        title={`Monthly Pro`}
                      />
                    )}
              </div>
              {/*     Desktop View    */}
              <div className="hidden md:flex gap-[72px]">
                {plans.monthly && (
                  <ICFSubscriptionPlanCard
                    plan={plans.monthly}
                    handleChoosePlanClick={handleChoosePlanClick}
                    title={`Monthly Pro`}
                  />
                )}
                {plans.annual && (
                  <ICFSubscriptionPlanCard
                    plan={plans.annual}
                    handleChoosePlanClick={handleChoosePlanClick}
                    title={`Annual Pro`}
                  />
                )}
              </div>
            </div>
            {/* <button
              onClick={() => navigate("/coach")}
              className="mb-2 text-blurple"
            ></button> */}
            <div className="text-[16px] flex items-center gap-[8px] mb-4">
              <SvgChatEllipses />
              <p>Need help deciding?</p>
              <button
                onClick={() => {
                  show()
                }}
                className="text-blurple font-bold"
              >
                Chat with our support
              </button>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

export default ICFSubscriptionCheckout
