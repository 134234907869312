import type { SVGProps } from "react";
export const SvgUser2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.5 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20M7.079 18.632a6.786 6.786 0 0 1 10.842 0 8.55 8.55 0 0 1-10.842 0m11.875-1a8.215 8.215 0 0 0-12.904 0 8.572 8.572 0 1 1 12.904 0"
    />
    <path
      fill="currentColor"
      d="M12.5 5.214a4.286 4.286 0 1 0 0 8.572 4.286 4.286 0 0 0 0-8.572m0 7.143a2.858 2.858 0 1 1 0-5.715 2.858 2.858 0 0 1 0 5.715"
    />
  </svg>
);
