import type { SVGProps } from "react";
export const SvgCheckbox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="24"
      height="24"
      rx="8"
      fill="#4750F5"
    />
    <path
      d="M7 12L10.3343 15L17 9"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
