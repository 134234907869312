import React from "react";
import avatar from "../../assets/images/avatars.png";
import { useChatContext } from "stream-chat-react";
import CloseModal from "../CloseModal";
import { SvgSearch } from "../icons";

interface Props {
  setEditChatPopover: any;
}

const notEnrolled = [
  {
    id: "eddie-lacy",
    name: "Eddie Lacy",
    img: avatar,
  },
  {
    id: "jayz",
    name: "Jay Z",
    img: avatar,
  },
  {
    id: "tj-yeldon",
    name: "TJ Yeldon",
    img: avatar,
  },
];

const enrolled = [
  {
    id: "beyonce",
    name: "Beyonce Knowles",
    img: avatar,
  },
  {
    id: "kanye",
    name: "Kanye West",
    img: avatar,
  },
];

const EditChatPopover: React.FC<Props> = (props: any) => {
  const { channel: activeChannel } = useChatContext();
  const [memberCount, setMemberCount] = React.useState<number>(0);

  React.useEffect(() => {
    if (activeChannel) {
      setMemberCount(Object.keys(activeChannel?.state?.members).length);
    }
  }, [activeChannel]);

  return (
    <div
      className="fixed top-0 right-0 h-[100vh] w-full sm:w-[456px] 
            bg-white z-[500] sm:card-hover-shadow pt-[40px] px-[20px] sm:px-[32px] pb-[16px]
            overflow-y-auto flex flex-col"
        >
            <div className="flex flex-col mb-[49px] w-full">
                <div className="flex justify-between w-full mb-[12px]">
                    <h3 className="font-bold text-[22px]">{activeChannel?.data?.name}</h3>
					<CloseModal callback={() => props.setEditChatPopover(false)} />
                </div>
                <div className="flex gap-[12px] text-graySlate text-base items-center mb-[16px]">
                    <p>{`${memberCount} members`}</p>
                    <div className="w-[4px] h-[4px] rounded-full bg-grayMist"></div>
                    <p>{`${memberCount} enrolled`}</p>
                </div>
                <button className="btn-primary btn-blue w-[144px] h-[32px] text-base">Go to Program</button>
            </div>
            <div className="default-input w-full rounded-[100px] mb-[24px]">
                <input type="text" className="rounded-[100px] pt-0 indent-[48px]" placeholder='Search for people' />
                <SvgSearch className='absolute top-[50%] -translate-y-[50%] left-[12px]' />
            </div>
            <div className="flex flex-col w-full h-[477px] overflow-y-auto mb-[16px]">
                <div className="flex flex-col w-full">
                    <p className="font-bold text-base mb-[8px]">Not Enrolled</p>
                    {notEnrolled.map((person) => (
                        <div className="w-full flex gap-[16px] p-[14px]" key={person.id}>
                            <img src={avatar} alt="avatar" className='w-[40px] h-[40px]'/>
                            <div className="flex flex-col">
                                <h5 className="font-bold text-[16px]">{person?.name}</h5>
                                <p className='text-graySlate text-base'>Client</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col w-full">
                    <p className="font-bold text-base mb-[8px]">Enrolled</p>
                    {enrolled.map((person) => (
                        <div className="w-full flex gap-[16px] p-[14px]" key={person.id}>
                            <img src={avatar} alt="avatar" className='w-[40px] h-[40px]'/>
                            <div className="flex flex-col">
                                <h5 className="font-bold text-[16px]">{person?.name}</h5>
                                <p className='text-graySlate text-base'>Client</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button className="btn-primary btn-blue mx-auto">Invite New Users</button>
       </div>
    )
}

export default EditChatPopover;
