import React, { useEffect } from "react"
import * as z from "zod"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import { LoginFormContext } from "./context"
import { UserExists } from "../../../api/auth.service"
import Loader from "../../ui/loader"
import { SwapAuthAction } from "../swap-auth-action"
import Cookies from "js-cookie"
import { SvgCheckbox, SvgCheckboxOff } from "../../icons"

const doesEmailExist = async (email: string) => {
  try {
    const data = await UserExists.verifyUserExists({
      attributes_to_verify: {
        email,
      },
    })

    return { found: data.user_found }
  } catch (error) {
    console.error(error)
  }
}

export const EmailForm: React.FC = () => {
  const { email, setEmail, setView } = React.useContext(LoginFormContext)

  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [rememberMe, setRememberMe] = React.useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const emailSchema = z.string().email().safeParse(email)

    if (!emailSchema.success) {
      setError(emailSchema.error.issues[0].message)
      setLoading(false)
      return
    }

    const emailExists = await doesEmailExist(email)

    if (!emailExists?.found) {
      setError("Email not found")
      setLoading(false)
      return
    }

    setError(undefined)

    if (rememberMe) {
      Cookies.set("rememberedEmail", email, { expires: 30 })
    } else {
      Cookies.remove("rememberedEmail")
    }

    setView("password")
  }

  useEffect(() => {
    const rememberedEmail = Cookies.get("rememberedEmail")
    if (rememberedEmail) {
      setEmail(rememberedEmail)
      setRememberMe(true)
    }
  }, [setEmail])

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <Input
        label="Email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={error}
        disabled={loading}
        autoFocus
      />

      <label className="flex gap-3 text-muted-foreground items-center">
        {rememberMe ? <SvgCheckbox /> : <SvgCheckboxOff />}
        <input
          type="checkbox"
          checked={rememberMe}
          className="hidden"
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        Remember me
      </label>

      <Button className="w-full" disabled={loading}>
        {loading ? <Loader /> : "Continue"}
      </Button>

      <SwapAuthAction />
    </form>
  )
}
