import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { PublicProfileRequests } from "../../api/public.service";
import { CoachTagWithImage } from "../../models/public.interface";
import { SvgClose, SvgCheck, SvgFilter, SvgSearch, SvgChevronLeft } from "../icons/";
import { Button } from "../ui/button";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "../ui/popover";

export function SearchMarketplace() {
  const [searchParams, setSearchParams] = useSearchParams();

  const showBackButton = Boolean(
    searchParams.getAll("tags").length || searchParams.get("q"),
  );

  const [query, setQuery] = React.useState(searchParams.get("q") || "");
  const [tags, setTags] = React.useState<CoachTagWithImage[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<Set<number>>(
    new Set(searchParams.getAll("tags").map((tag) => parseInt(tag)) || []),
  );

  async function getCoachTags() {
    try {
      let tags = await PublicProfileRequests.getCoachTags();
      tags = tags.filter(tag => tag.coach_count > 0);

      const tagsWithImagePromises = tags.map(async (tag) => {
        const image = await import(
          `../../assets/images/marketplace/categories/${tag.id}.jpg`
        );
        return {
          ...tag,
          image: image.default,
        };
      });

      const tagsWithImage = await Promise.all(tagsWithImagePromises);

      setTags(tagsWithImage);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSelectTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTags = new Set<number>(selectedTags);

    if (e.target.checked) {
      newTags.add(parseInt(e.target.value));
    } else {
      newTags.delete(parseInt(e.target.value));
    }

    setSelectedTags(newTags);
  };

  const handleUpdateTags = () => {
    if (selectedTags.size) {
      searchParams.delete("tags");
      selectedTags.forEach((tag) =>
        searchParams.append("tags", tag.toString())
      );
    } else {
      searchParams.delete("tags");
    }

    searchParams.set("page", "1");

    setSearchParams(searchParams);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (query.length) {
      searchParams.set("q", query);
    } else {
      searchParams.delete("q");
    }

    if (selectedTags.size) {
      searchParams.delete("tags");
      selectedTags.forEach((tag) =>
        searchParams.append("tags", tag.toString())
      );
    } else {
      searchParams.delete("tags");
    }

    searchParams.set("page", "1");

    setSearchParams(searchParams);
  };

  const clearFilter = () => {
    setSelectedTags(new Set());
    setQuery("");
    searchParams.delete("q");
    searchParams.delete("tags");
    setSearchParams(searchParams);
  };

  React.useEffect(() => {
    getCoachTags();
  }, []);

  React.useEffect(() => {
    setSelectedTags(
      new Set(searchParams.getAll("tags").map((tag) => parseInt(tag)) || []),
    );
  }, [searchParams]);

  return (
    <div className="flex-1 flex items-center gap-2 mx-2">
      {showBackButton && (
        <Button variant="ghost" size="sm" asChild onClick={clearFilter}>
          <Link to="/find-a-coach">
            <SvgChevronLeft className="w-4 h-4" /> Back
          </Link>
        </Button>
      )}
      <Popover>
        <PopoverAnchor asChild>
          <form
            className="flex-1 flex items-center gap-2 justify-center"
            onSubmit={handleSubmit}
          >
            <input
              placeholder="Search by Credentials, Category, or Expertise"
              className="bg-muted h-10 w-full flex-1 rounded-md px-3 border-border border-0 focus-within:bg-background hover:bg-grayFlash focus-within:hover:bg-background transition-colors focus-visible:outline-none ring-offset-background focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />

            <Button variant={query.length ? "default" : "secondary"} type="submit">
              <SvgSearch /> Search
            </Button>
            <PopoverTrigger asChild>
              <Button
                size="icon"
                variant="secondary"
                type="button"
                className="relative"
              >
                <SvgFilter />
                {!!selectedTags.size && (
                  <div className="absolute -top-1 -right-1 bg-blurple text-white rounded-full text-xs w-4 h-4 flex items-center justify-center">
                    {selectedTags.size}
                  </div>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-full relative overflow-y-auto max-h-[70dvh]">
              <div className="grid gap-4">
                <div className="space-y-2">
                  <h4 className="leading-none font-bold">Categories</h4>
                </div>
                <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mb-12">
                  {tags.map((tag) => (
                    <label
                      className="flex items-center rounded-md gap-2 pr-2 [&:has(input:checked)]:bg-muted focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-background focus-within:ring-ring transition-colors"
                      key={tag.id}
                    >
                      <div className="h-12 w-12 rounded-md overflow-hidden">
                        {selectedTags.has(tag.id)
                          ? (
                            <div className="flex items-center justify-center h-full">
                              <SvgCheck className="w-4 h-4 text-blurple" />
                            </div>
                          )
                          : (
                            <img
                              src={tag.image}
                              alt={tag.name}
                              className="object-cover w-full h-full"
                            />
                          )}
                      </div>
                      <input
                        type="checkbox"
                        value={tag.id}
                        onChange={handleSelectTag}
                        checked={selectedTags.has(tag.id)}
                        className="hidden"
                      />
                      <span className="select-none">{tag.name}</span>
                    </label>
                  ))}
                </div>
                <div className="col-span-full flex items-center justify-between fixed bottom-0 inset-x-0 p-2 bg-white border border-border rounded-b-md">
                  <Button
                    variant="ghost"
                    size="sm"
                    disabled={!selectedTags.size}
                    onClick={() => setSelectedTags(new Set())}
                  >
                    <SvgClose /> Clear
                  </Button>
                  <PopoverClose asChild>
                    <Button size="sm" onClick={handleUpdateTags}>
                      Apply
                    </Button>
                  </PopoverClose>
                </div>
              </div>
            </PopoverContent>
          </form>
        </PopoverAnchor>
      </Popover>
    </div>
  );
}
