import React from "react";
import ServiceCardBannerOverlay from "./ServiceCardBannerOverlay";
import { SvgBundle, SvgConsultation, SvgRecurring, SvgUserCheck } from "../icons";

interface Props {
    service: any;
    getSessionTypeImage?:any
    getSessionType?:any
}

const SelectServiceCardPreviewWide: React.FC<Props> = ({ 
    service,
 }) => {
    const { image, session, title, type } = service?.service_details || {};

    const getServiceType = () => {
        if (type === "consultation") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgConsultation />
                    Consultation Session
                </div>
            );
        } else if (type === "individual") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgUserCheck />
                    Individual Session
                </div>
            );
        } else if (type === "bundle") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgBundle />
                    Bundle Package
                </div>
            );
        } else if (type === "recurring") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgRecurring />
                    Recurring Program
                </div>
            );
        }
    };

    return (
        <div className={`flex flex-col rounded-[16px] bg-white main-shadow w-full`}>
            <div
                className={`w-full h-[80px] rounded-t-[16px] ${service?.service_details?.image?.color} relative`}
            >
                <ServiceCardBannerOverlay 
                  service={service}
                />
                {image?.exists && (
                    <img
                        src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service.stripe_product_id}?${new Date().getTime()}`}
                        alt="service"
                        className="w-full h-full object-cover rounded-t-[16px]"
                    />
                )}
            </div>
            <div className="w-full px-[24px] mx-auto py-[24px] flex flex-col justify-between">
                <div>
                    <div className="flex flex-col mb-[20px]">
                        <h3 className="font-bold text-[16px]">{title}</h3>
                    </div>
                    <div className="w-full h-[1px] bg-grayMist mb-[16px]"></div>
                    <div className="flex items-start justify-between w-full mb-[16px]">
                        {getServiceType()}
                        <div className="flex flex-col items-end gap-[8px]">
                            <div
                                className="flex items-center justify-center text-base text-graySlate font-bold bg-grayFlash 
                            py-[4px] px-[8px] rounded-[8px]"
                            >
                                <div>
                                    {parseInt(session?.duration) / 60} minutes
                                    <span className="text-darkGray"> / session</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectServiceCardPreviewWide;
