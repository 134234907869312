import React from "react";
import * as Icons from "../../../components/icons/index";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { Input } from "../../../components/ui/input";

export const IconsPage: React.FC = () => {
  const iconComponentNames = Object.keys(Icons);
  const [, copy] = useCopyToClipboard();
  const [iconQuery, setIconQuery] = React.useState("");

  return (
    <div className="flex flex-col gap-3">
      <h1 className="text-3xl font-bold">Icons: {iconComponentNames.length}</h1>
      <Input
        label="Search"
        placeholder="Search Icons"
        value={iconQuery}
        onChange={(e) => setIconQuery(e.target.value)}
        className="mb-2"
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        {iconComponentNames
          .filter((iconName) =>
            iconName.toLowerCase().includes(iconQuery.toLowerCase()),
          )
          .map((iconName, index) => {
            // @ts-ignore-next-line
            const IconComponent = Icons[iconName];

            return (
              <button
                onClick={() => {
                  copy(`<${iconName} />`);
                }}
                className="hover:border-blurple flex flex-col items-center gap-2 overflow-x-hidden border p-6 transition-colors"
                key={index}
              >
                <IconComponent className="flex-1" />
                <p className="w-full truncate text-center text-sm">
                  {iconName}
                </p>
              </button>
            );
          })}
      </div>
    </div>
  );
};
