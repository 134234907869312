import type { SVGProps } from "react";
export const SvgComment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.625 1.66667C0.625 1.09137 1.09137 0.625 1.66667 0.625H28.3333C28.9086 0.625 29.375 1.09137 29.375 1.66667V20.7143C29.375 21.2896 28.9086 21.756 28.3333 21.756H20.7872L11.1918 29.1581C10.8774 29.4007 10.4524 29.4433 10.096 29.2682C9.73967 29.093 9.51389 28.7304 9.51389 28.3333V20.7143C9.51389 20.139 9.98026 19.6726 10.5556 19.6726C11.1309 19.6726 11.5972 20.139 11.5972 20.7143V26.2142L19.7958 19.8895C19.9781 19.7489 20.2019 19.6726 20.4321 19.6726H27.2917V2.70833H2.70833V19.6726H6.60494C7.18023 19.6726 7.64661 20.139 7.64661 20.7143C7.64661 21.2896 7.18023 21.756 6.60494 21.756H1.66667C1.09137 21.756 0.625 21.2896 0.625 20.7143V1.66667ZM6.23909 6.57897C6.23909 6.00367 6.70546 5.5373 7.28075 5.5373H22.193C22.7683 5.5373 23.2347 6.00367 23.2347 6.57897C23.2347 7.15426 22.7683 7.62063 22.193 7.62063H7.28075C6.70546 7.62063 6.23909 7.15426 6.23909 6.57897ZM6.23909 11.0524C6.23909 10.4771 6.70546 10.0108 7.28075 10.0108H23.6843C24.2596 10.0108 24.7259 10.4771 24.7259 11.0524C24.7259 11.6277 24.2596 12.0941 23.6843 12.0941H7.28075C6.70546 12.0941 6.23909 11.6277 6.23909 11.0524ZM6.23909 15.5263C6.23909 14.951 6.70546 14.4846 7.28075 14.4846H14.7369C15.3122 14.4846 15.7786 14.951 15.7786 15.5263C15.7786 16.1016 15.3122 16.568 14.7369 16.568H7.28075C6.70546 16.568 6.23909 16.1016 6.23909 15.5263Z"
    />
  </svg>
);
