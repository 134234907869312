import React from "react";
import { EmailLogin, VerifyConfirmationCode } from "../../api/auth.service";
import { UserInfo } from "../../api/app.service";
import InputMask from "react-input-mask";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import moment from "moment-timezone";
import { View } from "./AuthModal";
import { Button } from "../ui/button";
import { getUserStats } from "../../utils/getUserStats";
import { SvgWarning, SvgZoeeLogo } from "../icons";
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable";

interface Props {
  emailLoginObject: any;
  isModalVariant?: boolean;
  setModalLoading?: any;
  modalLoading?: boolean | string;
  successRedirectPathname?: string;
  successRedirectSearchParams?: any;
  preRedirectActions?: any;
  setView: React.Dispatch<React.SetStateAction<View>>;
}

export default function OneTimePasscode(props: Props) {
  const {
    renderError,
    getUser,
    renderSuccess,
    hideAlert,
  } = React.useContext(CommonFunctionCtx);
  const codeMask = "999999";
  const [values, setValues] = React.useState<any>({
    confirmation_code: "",
  });
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const [stats, setStats] = React.useState<any>({});
  const [sessionData, setSessionData] = React.useState<any>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, confirmation_code: e.target.value });
  };

  const validateForm = (values: any) => {
    const errors: any = {};
    (!values.confirmation_code || values.confirmation_code === "") &&
      (errors.confirmation_code = "Confirmation code is required");
    return errors;
  };

  const sendOneTimePasscode = () => {
    props.setModalLoading("Sending one-time password");
    EmailLogin.loginOTP({ email: props.emailLoginObject.email })
      .then((data) => {
        renderSuccess("A code was sent to your email.");
        setSessionData(data);
      })
      .catch((ex) => {
        console.log(ex);
        if (ex.response.status === 401) {
          props.setView("password");
          renderError("Your email isn't validated. Login with password.");
        } else {
          renderError(ex.response.data);
        }
      })
      .finally(() => {
        props.setModalLoading(false);
      });
  };

  React.useEffect(() => {
    setStats(getUserStats());
    sendOneTimePasscode();
  }, []);

  const updateTimezone = async (userId: any, access_token: string) => {
    props.setModalLoading("Updating your timezone");
    await UserInfo.editTimezone({
      user_id: parseInt(userId),
      access_token: access_token,
      timezone: { value: moment.tz.guess(true) },
    })
      .then((data) => {
        if (data) {
          getUser({
            successRedirectPathname: props.successRedirectPathname,
            successRedirectSearchParams: props.successRedirectSearchParams,
            preRedirectActions: props.preRedirectActions,
            redirectToDashboard: true,
          });
        }
      })
      .catch((ex: { response: { data: any } }) => {
        console.error(ex);
        renderError(ex.response.data.message);
        props.setModalLoading(false);
      });
  };

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      props.setModalLoading("Verifying code");
      VerifyConfirmationCode.verifyConfirmationCode({
        confirmation_code: values.confirmation_code,
        session: sessionData.session,
        user_id: sessionData.user_id,
        stats: stats,
      })
        .then((data) => {
          if (data) {
            hideAlert();
            if (isLocalStorageAvailable()) {
              localStorage.setItem("user", JSON.stringify(data));
            }
            updateTimezone(data.user_id, data.access_token);
          }
        })
        .catch((ex: { response: { data: any } }) => {
          console.error(ex);
          renderError(ex.response.data.message);
          props.setModalLoading(false);
        });
    } else {
      setIsSubmit(false);
      props.setModalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setFormErrors(validateForm(values));
    setIsSubmit(true);
  };

  if (props.modalLoading) {
    return null;
  }

  return (
    <div
      className={`flex w-[360px] flex-col items-center justify-center gap-6 md:w-auto ${
        props.isModalVariant ? "" : "pb-[180px] md:pb-[100px]"
      } pt-[10px]`}
    >
      <SvgZoeeLogo className="w-[121px]" />
      <hgroup className="text-center font-normal">
        <h2>Welcome back, {props.emailLoginObject.first_name}</h2>
        <p>Check your email for your one-time passcode</p>
      </hgroup>
      <form onSubmit={handleSubmit}>
        <div className="mb-[24px]">
          <div
            className={`default-input ${formErrors?.email && "input-error"}`}
          >
            <InputMask
              mask={codeMask}
              value={values.confirmation_code}
              onChange={handleChange}
              placeholder="Enter code"
            />
            <div className="overlay">Confirmation Code</div>
          </div>
          {formErrors.confirmation_code && (
            <div className="field-error">
              <SvgWarning />
              {formErrors.confirmation_code}
            </div>
          )}
        </div>
        <div className="flex flex-col items-center gap-3">
          <Button type="submit" size="lg">
            Continue
          </Button>
          <Button variant="ghost" onClick={sendOneTimePasscode}>
            Resend Code
          </Button>
        </div>
      </form>
    </div>
  );
};
