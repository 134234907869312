import previewImage from "../../../assets/blog/coachesCornerRachelGreb.webp";
import { Post } from "../../../types/postTypes";

export const coachesCornerRachelGreb: Post = {
    metadata: {
        id: 12,
        title: "Hiring a Sex Coach Will Help You Outside The Bedroom…",
        subtitle: "Coach's Corner: An Interview with Rachel Greb",
        imgTag: "Coach's Corner",
        author: "Valerie Lane",
        date: "December 8, 2022",
        urlSlug: "coaches-corner-rachel-greb",
        description:
            "Zoee sat down with Rachel Greb, a sex coach, about how she helps her clients with dating troubles, a lack of self-assurance, or wanting to become more connected with their own body. It may sound unconventional, but sex coaching can be a way to boost confidence and help you set boundaries in all sorts of settings.",
        previewImg: previewImage,
        tags: ["Coach’s Corner", "Business", "Entrepreneur"],
    },
    content: [
        {
            type: "paragraph",
            text: "When Rachel Greb lost her job at the start of the COVID-19 pandemic, she really had no clue what to do next. Like many of us, her world came to a crashing halt and there didn’t seem to be an obvious path forward.",
        },
        {
            type: "paragraph",
            text: "With a Master’s degree in special education, a nonprofit focusing on girls empowerment, and a yoga teaching certification under her belt, she had skills to bring to a new career. But what could she do in a pandemic with no end in sight?",
        },
        {
            type: "paragraph",
            text: "Rachel started by asking herself, “What do I love doing?”",
        },
        {
            type: "paragraph",
            text: "Her answer: Talking with women about sex.",
        },
        {
            type: "paragraph",
            text: "Though it’s not everyday you wake up and realize you want to be a sex coach, the fact of the matter is that sex is an important part of our health. It’s also a tool for empowerment, especially if you’ve had harmful experiences in the past.",
        },
        {
            type: "paragraph",
            text: "“Health and life coaching, and specifically, sex coaching, is a way for me to help people inside and out.” Rachel says. When we work on our ‘insides’ (emotions, mental health, nutrition), oftentimes our ‘outsides’ benefit (elevated mood, regulated sleep patterns, and better relationships with loved ones). At this realization she enrolled in a coaching program to hone her skills and define what kind of coach she would become, answering questions like: What do I want to help people with? What struggles have I overcome? What helped me become a better version of myself?",
        },
        {
            type: "paragraph",
            text: "When you enroll in a coaching program, there’s room to grow into a specific type of coach. Of course learning nutrition won’t necessarily help you become a leadership coach for people who have trouble public speaking, but in the realm of ‘health’ you can wiggle into a niche, or a specialty that sets you apart. For Rachel, this was dating and sex.",
        },
        {
            type: "paragraph",
            text: "She says, “My coaching practice focuses on self-pleasure, and how that leads to empowerment and finding your voice.” When we know what we like, we can speak up for ourselves. Though those skills may start in the bedroom, they’ll soon translate to the outside world, boosting confidence and the ability to set boundaries in all sorts of settings.",
        },
        {
            type: "paragraph",
            text: "“When you find out what you like and what you don’t like, you can create boundaries about that. Then, you can speak it.” Rachel says. This really isn’t just about sex, but how you show up in the world and speak your truth.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "To provide a popular culture example, let’s side step into the well known HBO series",
                },
                {
                    type: "italic",
                    text: "Sex and the City.",
                },
                {
                    type: "normal",
                    text: "Samantha Jones, played by Kim Catrall, is one of the most sexually empowered fictional female figures on television. She’s not afraid to talk about sex, she’s not afraid to ask for it, and she’s not afraid to provide detailed instructions along the way. In short, she’s a woman that knows what she wants and isn’t afraid to hide it.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Fear not, Rachel isn’t transforming clients into mini-Samantha’s, trotting around New York City looking for their next man. (Though, we’re sure she could help you if that was your goal!) As with most coaches, her approach is much more individualized, focusing on what the client wants to achieve. This could be dating troubles, a lack of confidence, or wanting to become more connected with your own body.",
        },
        {
            type: "header2",
            text: "Self-pleasure Releases Stress",
        },
        {
            type: "paragraph",
            text: "The last few years have been tense, and this tension resides in our body whether we’re actively aware or not. When we’re stressed all the time we can’t relax, which means self-care and self-pleasure are becoming more important. Simply put: It’s a source of relieving stress.",
        },
        {
            type: "paragraph",
            text: `Rachel tells us, “Finding ways to release and relax are really important. There’s a lot of anger coming at us right now and we’ve got to learn to release some of the tension.” If you think she’s referring to sex here—that’s only one piece of the puzzle. A simple release of your tongue from the top of your mouth can do the trick.`,
        },
        {
            type: "paragraph",
            text: "“In order to be there for someone else, we have to be there for ourselves.” she says. In times of high stress, putting yourself first is important. Ask yourself, “What’s the next right thing I can do for myself?” Though it feels like the world is out of control, we can cultivate autonomy in our own lives. That care starts with us, and spills over to our friends and family.",
        },
        {
            type: "header2",
            text: "Embracing Not Following the Norms",
        },
        {
            type: "paragraph",
            text: "One of the ways we can regain control in our personal world is by letting go of the norm, or expectations. Especially when it comes to sexuality and dating, there are gobs of unspoken rules. Though there’s no one formula for happiness, we think there is, and that’s the trap. Rachel says, “I find myself stopping clients mid-sentence and saying, ‘How did you just say that?’” She takes the opportunity to unpack preconceived notions about what should be happening, and level-sets with clients on what is, and what could be.",
        },
        {
            type: "paragraph",
            text: "“Most importantly, people have to let go of the rules and figure out what works for them, and their partner.” If you want to send a thank you text message minutes after ending a really spectacular first date, Rachel encourages that sort of behavior. Feeling empowered to be yourself is energizing, and leads to intentional decisions.",
        },
        {
            type: "paragraph",
            text: "This doesn’t just apply to Rachels clients, either. As a coach, she practices doing things in alignment with her true self. As she says, “It’s hard to get away with feeling stuck as a coach.” She sees herself growing, she sees where she wants to go, and most importantly, she’s practicing what she preaches.",
        },
        {
            type: "paragraph",
            text: "We couldn’t pass up the opportunity to ask a sex coach these questions:",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Favorite part about being a coach? -",
                },
                {
                    type: "normal",
                    text: "Being able to talk about sex all day.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Favorite product that you feel ‘saves your life’ day to day. -",
                },
                {
                    type: "normal",
                    text: "Working out, lifting weights, and my favorite sex toy.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "If you could eat one food for the rest of your life, what would it be? -",
                },
                {
                    type: "normal",
                    text: "Either street tacos or yogurt & cereal.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Favorite day of the week. -",
                },
                {
                    type: "normal",
                    text: "During football season, it’s Saturday for college football.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Last song you listened to. -",
                },
                {
                    type: "normal",
                    text: "Power, by Kevin Gates",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Rachel Greb is a sex coach located in Austin, Texas.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Ready to simplify your back-office? Join Zoee today. Interested in finding a coach? Sign up for the member waitlist.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This interview was conducted and written by Valerie Lane.",
                },
            ],
        },
    ],
};
