import type { SVGProps } from "react";
export const SvgDuplicate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.982 19.06a1.5 1.5 0 0 0 1.06.44h8.316a1.5 1.5 0 0 0 1.06-.44l.043-.042a1.5 1.5 0 0 0 .44-1.06V9.642a1.5 1.5 0 0 0-.44-1.061l-.043-.043a1.5 1.5 0 0 0-1.06-.439H6.043a1.5 1.5 0 0 0-1.06.44l-.043.042a1.5 1.5 0 0 0-.44 1.06v8.315c0 .398.158.78.44 1.061zm11.54-11.538-.043-.043a3 3 0 0 0-2.122-.879H6.043a3 3 0 0 0-2.122.879l-.042.043A3 3 0 0 0 3 9.643v8.315a3 3 0 0 0 .879 2.121l.042.043A3 3 0 0 0 6.043 21h8.314a3 3 0 0 0 2.122-.878l.042-.043a3 3 0 0 0 .879-2.121V9.643a3 3 0 0 0-.879-2.121"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 13.25v-1.5a.75.75 0 0 1 1.5 0v1.5h1.5a.75.75 0 0 1 0 1.5h-1.5v1.5a.75.75 0 0 1-1.5 0v-1.5h-1.5a.75.75 0 0 1 0-1.5zM17.4 15.9h.557a1.5 1.5 0 0 0 1.061-.44l.043-.042a1.5 1.5 0 0 0 .439-1.06V6.042a1.5 1.5 0 0 0-.44-1.061l-.042-.043a1.5 1.5 0 0 0-1.06-.439H9.642a1.5 1.5 0 0 0-1.061.44l-.043.042a1.5 1.5 0 0 0-.439 1.06V6.6H6.6v-.557A3 3 0 0 1 7.48 3.92l.042-.042A3 3 0 0 1 9.643 3h8.314a3 3 0 0 1 2.122.879l.042.042A3 3 0 0 1 21 6.043v8.314a3 3 0 0 1-.879 2.122l-.042.042a3 3 0 0 1-2.122.879H17.4z"
      clipRule="evenodd"
    />
  </svg>
);
