import React, { ChangeEvent, FC, useState } from "react";
import CloseModal from "../../CloseModal";
import { Button } from "../../ui/button";
import {
  CreateFormLinkType,
  UpdateFormLinkType,
} from "../../../models/app.interface";

interface Props {
  modalCloseFn: (statement: boolean) => void;
  formLinkData: CreateFormLinkType | UpdateFormLinkType;
  changeHandler: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    statePreperty: string
  ) => void;
  formHandler: () => void;
  dialogTitle: string;
}

const FormDilog: FC<Props> = ({
  modalCloseFn,
  changeHandler,
  formHandler,
  formLinkData,
  dialogTitle,
}) => {
  const [radioChecked, setRadioChecked] = useState(true);
  const [sizeError, setSizeError] = useState<boolean>(false);

  const radioCheckHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioChecked(e.target.checked ? !radioChecked : radioChecked);
  };

  return (
    <div className="fixed top-0 left-0 w-screen h-full flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div className="w-[705px] max-w-[90%] main-shadow rounded-[16px] p-[44px] flex flex-col items-center gap-[30px] max-h-[95vh] bg-white fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] overflow-y-auto">
        <CloseModal
          callback={() => modalCloseFn(false)}
          styling="absolute top-[16px] right-[16px] sm:top-[24px] sm:right-[24px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <h3 className=" md:text-[22px] text-[18px] font-bold sm:text-[28px] sm:text-center md:text-left mb-[24px]">
          {dialogTitle}
        </h3>
        <div className="w-full flex-inline items-center justify-between">
          <div className="w-full min-h-[80px] mb-[20px]">
            <div className="flex justify-between">
              <div className="flex">
                <p className="text-[16px] font-bold mb-[5px]">
                  Title{" "}
                  <span className="font-normal">
                    (Required) This is the name your client will see.
                  </span>
                </p>
              </div>
              <span className="font-normal text-sm text-graySlate">
                50 characters
              </span>
            </div>
            <div className="default-input w-full">
              <input
                className="text-mainBlack w-full"
                type="text"
                maxLength={50}
                placeholder="Enter form title..."
                required
                onChange={(e) => {
                  changeHandler(e, "title");
                }}
                name="formLinkTitle"
                value={formLinkData.title}
              />
              <div className="overlay">Form Name</div>
            </div>
          </div>

          <div className="w-full min-h-[80px] mb-[20px]">
            <div className="flex justify-between">
              <div className="flex">
                <p className="text-[16px] font-bold mb-[5px]">
                  Description{" "}
                  <span className="font-normal">
                    This is an internal description for your reference.
                  </span>
                </p>
              </div>
              <span className="font-normal text-sm text-graySlate">
                100 characters
              </span>
            </div>
            <div className="default-input w-full h-[118px]">
              <textarea
                className="default-input w-full h-[118px] py-4 focus:py-6"
                maxLength={100}
                placeholder="Enter a description of your form..."
                onChange={(e) => {
                  changeHandler(e, "description");
                }}
                value={formLinkData.description}
                name="formLinkDescription"
              />
              <div className="overlay">Form Description</div>
            </div>
          </div>
          <div className="flex justify-between md:w-[50%] mx-auto mb-[20px]">
            <label
              htmlFor="link_radio"
              className="font-bold"
            >
              Enter form URL
              <input
                className="ml-[5px]"
                id="link_radio"
                type="radio"
                checked={radioChecked}
                onChange={(e) => radioCheckHandler(e)}
              />
            </label>
            <span>or</span>
            <label
              htmlFor="form_radio"
              className="font-bold"
            >
              <input
                className="mr-[5px]"
                id="form_radio"
                type="radio"
                checked={!radioChecked}
                onChange={(e) => radioCheckHandler(e)}
              />
              Upload the form
            </label>
          </div>
          {radioChecked ? (
            <div className="w-full min-h-[80px] mb-[20px]">
              <div className="flex justify-between">
                <div className="flex">
                  <p className="text-[16px] font-bold mb-[5px]">
                    URL <span className="font-normal">(Required)</span>
                  </p>
                </div>
              </div>
              <div className="default-input w-full">
                <input
                  className="text-mainBlack w-full"
                  type="text"
                  maxLength={100}
                  placeholder="Enter URL to your digital form i.e. https://forms.com/form/"
                  required
                  onChange={(e) => {
                    changeHandler(e, "url");
                  }}
                  value={formLinkData.url}
                  name="formLinkUrl"
                />
                <div className="overlay">Form Url</div>
              </div>
            </div>
          ) : (
            <label
              htmlFor="form_upload"
              className="w-full font-bold"
            >
              Upload your form file{" "}
              <span className="font-normal">
                Files accepted: .docx, .doc, .pdf (5MB Max)
              </span>{" "}
              <br />
              {sizeError && (
                <span className="text-[#f71b02]">
                  File exceeds maximum size of 5MB
                </span>
              )}
              <input
                id="form_upload"
                className="text-mainBlack mt-[5px] w-full border border-solid border-gray-300 rounded-[10px] p-[22px] font-normal text-gray-300"
                type="file"
                accept=".docx, .doc, .pdf"
                onChange={(e) => {
                  setSizeError(false);
                  const fileSize = e.target.files?.[0].size;
                  if (fileSize && fileSize > 5242880) {
                    setSizeError(true);
                  }
                  changeHandler(e, "");
                }}
              />
            </label>
          )}
        </div>
        <div className="flex w-[255px] justify-between">
          <Button
            onClick={() => modalCloseFn(false)}
            variant="secondary"
            className="w-[100px]"
          >
            Cancel
          </Button>
          <Button
            className="w-[100px]"
            onClick={formHandler}
            disabled={
              formLinkData.title &&
              !sizeError &&
              (formLinkData?.url || formLinkData?.base_64_string)
                ? false
                : true
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormDilog;
