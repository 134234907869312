import type { SVGProps } from "react";
export const SvgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M18.1138 3.59497C17.6555 3.52943 17.1239 3.5 16.3902 3.5H7.60978C6.87611 3.5 6.34448 3.52943 5.91005 3.59204C2.64026 3.95063 2 5.87838 2 9.08577V14.9142C2 18.1215 2.64022 20.0491 5.88618 20.405C6.34448 20.4706 6.87611 20.5 7.60978 20.5H16.3902C17.1239 20.5 17.6555 20.4706 18.0899 20.408C21.3597 20.0494 22 18.1216 22 14.9142V9.08578C22 5.87851 21.3598 3.95092 18.1138 3.59497ZM20.5366 14.9142C20.5366 18.1301 19.8859 18.7448 17.9061 18.9631C17.5193 19.018 17.0514 19.0428 16.3902 19.0428H7.60978C6.94866 19.0428 6.4807 19.0181 6.07011 18.9603C4.11429 18.7448 3.46341 18.1301 3.46341 14.9142V9.08577C3.46341 5.86988 4.11408 5.25514 6.09394 5.03691C6.4807 4.98194 6.94861 4.95722 7.60978 4.95722H16.3902C17.0513 4.95722 17.5193 4.98194 17.9299 5.03968C19.8857 5.25515 20.5366 5.86985 20.5366 9.08578V14.9142ZM17.6098 10.0571C17.6098 10.4592 17.282 10.7856 16.8781 10.7856H13.4634C13.0595 10.7856 12.7317 10.4592 12.7317 10.0571C12.7317 9.65488 13.0595 9.3285 13.4634 9.3285H16.8781C17.282 9.3285 17.6098 9.65488 17.6098 10.0571ZM9.16927 10.0571C9.16927 9.52008 9.60177 9.0857 10.1411 9.0857H10.1497C10.689 9.0857 11.1253 9.52025 11.1253 10.0571C11.1253 10.594 10.6888 11.0284 10.1497 11.0284C9.61042 11.0286 9.16927 10.594 9.16927 10.0571H9.16927ZM6.24246 10.0571C6.24246 9.52008 6.67496 9.0857 7.21424 9.0857H7.2229C7.76219 9.0857 8.19845 9.52025 8.19845 10.0571C8.19845 10.594 7.76202 11.0284 7.2229 11.0284C6.68361 11.0286 6.24246 10.594 6.24246 10.0571H6.24246ZM17.6098 15.4001C17.6098 15.8022 17.282 16.1286 16.8781 16.1286H7.14099C6.73708 16.1286 6.39979 15.8022 6.39979 15.4001C6.39979 14.9977 6.71796 14.6715 7.12188 14.6715H16.8778C17.2819 14.6715 17.6097 14.9977 17.6097 15.4001H17.6098Z"
      fill="currentColor"
    />
  </svg>
);
