import type { SVGProps } from "react";
export const SvgRedo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path strokeWidth={2} stroke="currentColor" d="M19.614 7.193a9.6 9.6 0 0 0-1.253-1.56c-3.51-3.51-9.22-3.51-12.729 0-3.51 3.511-3.51 9.223 0 12.734s9.22 3.51 12.729 0a9.15 9.15 0 0 0 2.534-4.96c.14-.92.14-1.867 0-2.786" />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.58}
      d="M20.394 3.961v3.873h-3.871"
    />
  </svg>
);
