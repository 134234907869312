import { useContext, useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"
import ForceVerifyEmail from "../../pages/ForceVerifyEmail"

const PrivateRouteEmailVerified = () => {
  const { user } = useContext(UserCtx)
  const location = useLocation()
  const navigate = useNavigate()
  const redirectPath = localStorage.getItem("redirectAfterVerification")

  useEffect(() => {
    if (user.emailVerified) {
      if (redirectPath) {
        navigate(redirectPath)
        localStorage.removeItem("redirectAfterVerification")
      }
    } else {
      localStorage.setItem("redirectAfterVerification", `${location.pathname}${location.search}`)
    }
  }, [user.emailVerified, location, navigate])

  if (!user.emailVerified) {
    return <ForceVerifyEmail />
  }
  
  return <Outlet />
}

export default PrivateRouteEmailVerified
