import type { SVGProps } from "react";
export const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.696 5.304c-1.738-1.739-4.654-1.739-6.392 0L10.707 6.9a1 1 0 0 1-1.414-1.415L10.89 3.89c2.52-2.52 6.7-2.52 9.22 0s2.52 6.7 0 9.22l-1.596 1.597a1 1 0 0 1-1.415-1.414l1.597-1.597c1.739-1.738 1.739-4.654 0-6.392m-2.989 2.989a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0m-8.806 1a1 1 0 0 1 0 1.414l-1.597 1.597c-1.739 1.738-1.739 4.654 0 6.392 1.738 1.739 4.654 1.739 6.392 0l1.597-1.597a1 1 0 0 1 1.414 1.415L13.11 20.11c-2.52 2.52-6.7 2.52-9.22 0s-2.52-6.7 0-9.22l1.596-1.597a1 1 0 0 1 1.415 0"
      clipRule="evenodd"
    />
  </svg>
);
