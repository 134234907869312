import type { SVGProps } from "react";
export const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.298 10.568c0-1.06.793-1.832 1.642-1.832.888 0 1.643.812 1.643 1.625v1.221h-3.286zm-1.5 1.139v-1.14c0-1.779 1.36-3.33 3.142-3.33 1.744 0 3.143 1.51 3.143 3.124v1.346a3.444 3.444 0 0 1 2.518 3.308v2.47a3.44 3.44 0 0 1-3.433 3.432H9.713a3.44 3.44 0 0 1-3.433-3.433v-2.469a3.444 3.444 0 0 1 2.517-3.308M7.78 15.015c0-1.061.872-1.933 1.933-1.933h4.455c1.061 0 1.933.872 1.933 1.933v2.47a1.94 1.94 0 0 1-1.933 1.932H9.713a1.94 1.94 0 0 1-1.933-1.933z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20.808 9.92a9.066 9.066 0 0 0-17.616 0V4.045"
    />
  </svg>
);
