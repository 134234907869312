import * as React from "react";
import { SvgCheckCircle } from "../../../components/icons";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";

interface FormData {
  email: string | null;
}

export const SubscribeToNewsletterForm = () => {
  const [newsletterFormData, setNewsletterFormData] = React.useState<FormData>({
    email: null,
  });
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState<
    boolean
  >(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  const subscribeToNewsletter = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/subscribeToNewsletter`,
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(newsletterFormData),
      },
    );
    return response.json();
  };

  const validateEmail = (email: string) => {
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setError(undefined);
    const isValidEmail = validateEmail(newsletterFormData.email || "");
    if (!isValidEmail) {
      setError("Invalid email");
    } else {
      e.preventDefault();
      subscribeToNewsletter()
        .then(() => {
          setNewsletterFormData({ email: null });
          setIsSuccessfullySubmitted(true);
        })
        .catch((err) => {
          setError("Something went wrong. Please try again");
          console.error(err);
        });
    }
  };

  return (
    <div className="bg-blurple main-shadow py-24 px-[32px] md:px-[64px] text-center relative">
      {isSuccessfullySubmitted &&
        (
          <div className="w-full flex flex-col gap-4 items-center justify-center text-white absolute inset-0 bg-blurple/90 backdrop-blur z-30">
            <SvgCheckCircle />
            <p className="font-semibold">
              Thank you for signing up for our newsletter! Keep an eye on your
              inbox for updates.
            </p>
          </div>
        )}
      <h4 className="text-[24px] font-bold mb-[8px] text-white">
        Stay in the Know!
      </h4>
      <p className="mb-[16px] w-[90%] md:w-[500px] mx-auto text-white">
        We will send you industry news, Zoee updates, and interviews and
        articles on amazing coach-ready leaders.
      </p>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-12 justify-center items-center md:flex-row md:items-start"
      >
        <div className="w-[95%] lg:w-[40%] relative bg-white rounded-md p-px m-[2px]">
          <Input
            placeholder="Email address"
            label="Email"
            onChange={(e) =>
              setNewsletterFormData({
                email: e.target.value,
              })}
            id="email"
            type="email"
            value={newsletterFormData.email || ""}
            error={error}
          />
        </div>
        <div className="md:w-auto w-full">
          <Button
            size="lg"
            variant="outline"
            className="w-[40%] h-[62px] md:w-full text-[17px]"
          >
            Subscribe
          </Button>
          <div className="text-white text-opacity-50 text-base w-full">
            We respect your privacy
          </div>
        </div>
      </form>
    </div>
  );
};
