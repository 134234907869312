import type { SVGProps } from "react";
export const SvgRecord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="currentColor" fillOpacity={0.33} />
    <circle cx={10} cy={10} r={4} fill="currentColor" />
  </svg>
);
