import React, { FC, useRef, useState } from "react";
import {
  SvgAudio,
  SvgAudioOff,
  SvgMinimize,
  SvgPause,
  SvgPlay,
  SvgVideoIcon,
} from "../icons/";

interface Props {
  videoSrc: any;
  onClose: any;
  coachPublicProfileId?: number;
  coachPublicProfileData?: any;
  isMainVideo?: boolean;
}

const Maximize: FC<Props> = ({
  videoSrc,
  onClose,
  coachPublicProfileId,
  coachPublicProfileData,
  isMainVideo,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [profileVideoPlaying, setProfileVideoPlaying] =
    useState<boolean>(false);

  const [profileAudioMuted, setProfileAudioMuted] =
    useState<boolean>(false);

  const videoHandler = (control: any) => {
    if (control === "play") {
      videoRef?.current?.play();
      setProfileVideoPlaying(true);
    } else if (control === "pause") {
      videoRef?.current?.pause();
      setProfileVideoPlaying(false);
    }
  };

  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4";
    } else {
      return extension;
    }
  };

  const baseVideoSrc = `https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileId}`
  const aboutVideoSrc = `${baseVideoSrc}_about_${videoSrc.id}.${videoSrc.video_extension}`;
  const mainVideoSrc = `${baseVideoSrc}_main_${coachPublicProfileData?.main_video_id}.${coachPublicProfileData?.main_video_extension}`;
  const videoType = `video/${getVideoType(
    isMainVideo
      ? coachPublicProfileData?.main_video_extension
      : videoSrc.video_extension
  )}`;

  return (
    <>
      <div className="zoee-backdrop-filter fixed left-0 top-0 z-[1009] flex h-screen w-screen items-center justify-center md:z-[801]">
        <div className="main-shadow fixed mt-[20px] flex h-[800px] max-h-[85vh] w-auto max-w-[90%] flex-col items-start overflow-y-auto rounded-[16px] bg-white">
          <div className="group relative flex h-full w-full items-center rounded-[16px] bg-black">
            <div className="absolute bottom-[8px] left-[50%] z-[20] flex w-full -translate-x-[50%] items-center justify-center gap-[8px] sm:hidden sm:justify-evenly sm:gap-0 sm:group-hover:flex">
              <button
                className="flex h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white"
                onClick={() => {
                  if (profileVideoPlaying) {
                    videoHandler("pause");
                  } else {
                    videoHandler("play");
                  }
                }}
              >
                {profileVideoPlaying ? (
                  <SvgPause className="w-[32px]" />
                ) : (
                  <SvgPlay />
                )}
              </button>
              <button
                className="flex h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white"
                onClick={(e: any) => {
                  videoHandler("pause");
                  onClose(false);
                }}
              >
                <SvgMinimize />
              </button>
              <button
                className="flex h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white"
                onClick={() => setProfileAudioMuted(!profileAudioMuted)}
              >
                {profileAudioMuted ? <SvgAudioOff /> : <SvgAudio />}
              </button>
            </div>
            <SvgVideoIcon className="absolute right-4 top-4 z-[20] w-[32px] text-white" />
            <video
              ref={videoRef}
              className="z-[1] h-full w-full rounded-[16px] object-contain"
              muted={profileAudioMuted}
              onEnded={() => videoHandler("pause")}
            >
              <source
                src={isMainVideo ? mainVideoSrc : aboutVideoSrc}
                type={videoType}
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maximize;
