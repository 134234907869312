import type { SVGProps } from "react";
export const SvgEllipses = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle cx={6.5} cy={11.5} r={1.5} fill="currentColor" />
    <circle cx={12.5} cy={11.5} r={1.5} fill="currentColor" />
    <circle cx={18.5} cy={11.5} r={1.5} fill="currentColor" />
  </svg>
);
