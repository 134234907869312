import previewImage from "../../../assets/blog/12CoachingTipsFor2023.webp";
import { Post } from "../../../types/postTypes";

export const TwelveCoachingTipsForTheHolidaySeason: Post = {
    metadata: {
        id: 14,
        title: "12 Coaching Tips for 2023",
        author: "Zoee",
        date: "January 2, 2023",
        urlSlug: "12-coaching-tips-for-2023",
        description:
            "Zoee coaches share some self development tips to help make 2023 your best year yet.",
        previewImg: previewImage,
        tags: ["Mental Health", "Personal Development", "Life Coaching"],
    },
    content: [
        {
            type: "paragraph",
            text: "We are always looking for ways to improve and grow, but this is heightened during the new year. No wonder this is the best time to start working with a coach and bettering yourself. Zoee is lucky to have incredibly insightful coaches, so we've collected 12 coaching tips to share with you:",
        },
        {
            type: "header2",
            text: "1. Have an attitude of gratitude.",
        },
        {
            type: "paragraph",
            text: "“Be grateful for times of stretching. Although this is difficult to do, it's in these times that we truly grow and learn. We're all dealing with stuff whether it's family, work, financial, self doubt, imposter syndrome... I know I've dealt with all of this and more in my life. But what I've learned is that when I'm in a season of stretching, I try to show up as my authentic self, trust who I am, lean into my faith, and when I do this, I stop worrying about the future. The future will worry about itself. And when I embrace uncertainty with gratitude, I'm able to make better decisions with clarity. You may not be where you want to be, but I bet you're much further than you used to be. Have an attitude of gratitude. You will get through this season and be all the wiser for it.” – Nichole Lowe, CEO & Found of Zoee",
        },
        {
            type: "header2",
            text: "2. Celebrate every step you take.",
        },
        {
            type: "paragraph",
            text: "“Don’t forget that where you are professionally today was something you once aspired to. The next time you find yourself wanting to be or do more, remember to celebrate how far you’ve come already.” – Julia Wuench, Executive & Career Coach",
        },
        {
            type: "header2",
            text: "3. Understand where you come from.",
        },
        {
            type: "paragraph",
            text: "“When I begin to work with a client, I not only consider their family background, but also their birth order. Where you come from is just as important to me as who you are now.” – Jacqueline Whitmore, Etiquette Coach",
        },
        {
            type: "header2",
            text: "4. Remind yourself of your control.",
        },
        {
            type: "paragraph",
            text: "“Remember you own yourself, You get to decide what you‘re going to eat and drink when you socialize. Make the choices that serve you best. You don’t have to gain 5 or 10 pounds during the holidays. You can have a little sip, but treat yourself well. You have that control.” - Joanne Whiteside, RN, Health & Wellness Coach",
        },
        {
            type: "header2",
            text: "5. Try breathwork.",
        },
        {
            type: "paragraph",
            text: "“If you ever find yourself believing a lie, go through a breathwork prayer. Breathe in the lie for 5 seconds and exhale the truth, coming from the Lord. A lie we might believe is that our worth is based on our performance. Breathe that in and say it in your head and then as you exhale say “my worth is found in being a daughter or son of the lord.” If you do that 5 or 10 times each day you will be better at detecting the lies and replacing them with the truth.” - Faith Anne Wright, Christian Life Coach",
        },
        {
            type: "header2",
            text: "6. Practice mindfulness on a daily basis.",
        },
        {
            type: "paragraph",
            text: "Practice gratitude to be more mindful of the present moment. Notice the small things that make your life easier, more enjoyable or simply beautiful. For example, you could write down three things you notice in nature each day, like a bird’s nest or some beautiful flowers. You may notice yourself feeling more content by expressing gratitude for all you have to be grateful for this holiday season.",
        },
        {
            type: "header2",
            text: "7. Get comfortable with silence.",
        },
        {
            type: "paragraph",
            text: "Ask yourself, can you sit in silence with your client and feel comfortable? If not, it's time to start practicing. With time, you’ll find that this creates a more comfortable atmosphere for both of you as it gives your client time to think before responding and allows you to assess their emotions.",
        },
        {
            type: "header2",
            text: "8. Stop saying “I should” and “I can’t”.",
        },
        {
            type: "paragraph",
            text: "Be aware of your inner critic. Negative self talk can wreak havoc on our personal and professional life. Research shows that negative self-talk can contribute to feelings of depression. It's important to stop negative thoughts immediately before they get ahold of you and affect our lives negatively. Here are some ways to strengthen your mental fitness:",
        },
        {
            type: "paragraph",
            text: "Just breathe, you got this! It may sound simple, but calming your mind through deep breathing exercises can combat your inner critic.",
        },
        {
            type: "paragraph",
            text: `Be mindful of the words you use when speaking to yourself -- words like "never" and "can't" have a negative connotation. These words also make us feel smaller instead of competent and capable. In these moments, when you catch yourself using these words, try replacing them with something positive. For example, instead of saying "I can't do this", say "I am learning how to do this." Instead of saying "I never get it right", say "I am learning how to do it better next time."`,
        },
        {
            type: "paragraph",
            text: "You control how much power your inner Judge has. By minimizing your negative self talk, you'll gain greater positive control over your thoughts, actions, and life.",
        },
        {
            type: "header2",
            text: "9. Nurture your healthy relationships.",
        },
        {
            type: "paragraph",
            text: "Nurture your healthy relationships. It can be difficult to identify the people in your life that are toxic, but it's much easier to find the ones that bring you joy. Spend some time thinking about the people in your life and how they make you feel. Do they support you? Do they listen to what you have to say? Are they there for you when times get tough?",
        },
        {
            type: "paragraph",
            text: "Focus on relationships that fill you up, and think of some ways you can support these people in return. Build your healthy social network, get rid of the toxicity, and spend that extra time growing your meaningful relationships. You’ll notice that your relationships will feel less draining, and more inspiring.",
        },
        {
            type: "header2",
            text: "10. Give yourself permission to say no.",
        },
        {
            type: "paragraph",
            text: "At this time of the year, it may feel like you have to say yes to everything and everyone. If you’re feeling overwhelmed or just want to limit your commitments, then set boundaries for yourself and stick to them.",
        },
        {
            type: "paragraph",
            text: "Too many commitments will leave you feeling burnt. It’s important to know how much time you can realistically devote to something, so that you don’t end up feeling stressed out or resentful of your obligations. Take care of yourself– you deserve it!",
        },
        {
            type: "header2",
            text: "11. Practice being alone.",
        },
        {
            type: "paragraph",
            text: "It's important to practice being alone because it gives you the space and time to truly connect with yourself and your surroundings. This is especially important in a culture that places so much emphasis on social media, where we often feel pressure to portray ourselves in a certain way.",
        },
        {
            type: "paragraph",
            text: "Take yourself out on a date, go for a bike ride, volunteer at a local shelter, or go to the movies. Being independent may be uncomfortable at first, but over time you'll learn to appreciate your own company.",
        },
        {
            type: "header2",
            text: "12. Maintain your routine.",
        },
        {
            type: "paragraph",
            text: "Keeping a routine can be difficult during the holiday season. To prepare for the new year, getting back into your daily rhythm can help you feel more accomplished.",
        },
        {
            type: "paragraph",
            text: "If you’ve been working out, keep it up. If you’ve been practicing your instrument, keep playing. If you’re reading a book, keep reading. This will help ensure that when January 1st rolls around, you won’t feel like all the progress you made during the year has disappeared. If you have new goals you're considering, now is also the perfect time to craft a new routine that works best for you.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Looking to join the most powerful coaching platform on the market? Start your Zoee trial today!",
                },
            ],
        },
    ],
};
