import { Helmet } from "react-helmet";
import { FC } from "react";
import { SvgPlay, SvgQuoteLeft, SvgQuoteRight } from "../../icons/";

type Props = {
    variation: string;
};

const LandingWhyZoee: FC<Props> = ({variation}) => {

    const getButtonBackground = () => {
        switch (variation) {
            case "normal":
                return "#4750F5"
            case "moneyflow":
                return "#FCB900"
            default:
                break;
        }
    }

    const handleClickScroll = () => {
        const element = document.getElementById("coachCreateForm");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }

        const emailElement = document.getElementById("createEmailInput");
        if (emailElement) {
            setTimeout(() => {
                emailElement.focus();
            }, 1000);
        }
    };

    return (
        <>
            <Helmet>
                <script src="https://fast.wistia.com/embed/medias/hz301wwpr5.jsonp" async></script>
                <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
            </Helmet>
            <div className="flex md:flex-row flex-col lg:gap-[108px] md:gap-[40px] gap-[70px]">
                <div className="flex flex-col gap-[60px]">
                    <div className="max-w-[565px]">
                        <h3 className="text-[32px] font-semibold mb-[24px]">What is Zoee?</h3>
                        <p className="text-[18px]">
                            {variation === "moneyflow" ? (
                                <>
                                    From chaos to order, the simplest path to a more fulfilling and 
                                    profitable coaching business starts with Zoee, a virtual coaching 
                                    platform that will streamline your online business and simplify your 
                                    life, so you and your clients can do more…. more time…more money…more 
                                    freedom. Zoee values community and that people like you are going to 
                                    change the world for the better. Take the first step to simplifying 
                                    your life so you can do more. It’s simple, quick, and free to start!
                                </>
                            ) : (
                                <>
                                    Zoee is a gateway to connection through a powerful online experience. We
                                    take coaches into the future of coaching with a fully integrated virtual
                                    office including video chat, client management, communication tools
                                    (including live chat), custom programs, and seamless scheduling and
                                    billing. We aren't stopping there—keep an eye out for Zoee's upcoming
                                    features, including a marketplace (where we'll help you grow your
                                    business), group coaching, and more!
                                </>
                            )}
                        </p>
                    </div>

                    <button
                        onClick={handleClickScroll}
                        className="cursor-pointer mx-auto text-white font-semibold text-base px-[49px] py-[13px] flex items-center justify-center rounded-[100px]"
                        style={{backgroundColor: getButtonBackground()}}
                    >
                        Start free trial now
                    </button>

                    <div className="hidden md:block max-w-[565px]">
                        <h3 className="text-[32px] font-semibold mb-[24px]">
                            Why should I choose Zoee?
                        </h3>
                        <p className="text-[18px]">
                            Zoee is on a mission to transform the way we do life. There's a reason
                            why coaching is the second fastest growing profession in the world,
                            according to PwC—people are broken, mental health issues are on the
                            rise, and we are more stressed out, disconnected, and full of dis-ease
                            than ever before. If the pandemic taught us anything, it's that we're
                            all aching for human connection.
                        </p>
                    </div>
                </div>

                <div className="max-w-[468px]">
                    <div className="relative mx-auto w-[298px] max-w-[298px] max-h-[462px] h-[462px] rounded-[16px] md:mb-[16px] mb-[50px]">
                        <div
                            className="wistia_responsive_padding rounded-[16px] overflow-hidden"
                            style={{ padding: "177.78% 0 0 0", position: "relative" }}
                        >
                            <div
                                className="wistia_responsive_wrapper"
                                style={{
                                    height: "100%",
                                    left: 0,
                                    position: "absolute",
                                    top: 0,
                                    width: "100%",
                                }}
                            >
                                <div
                                    className="wistia_embed wistia_async_hz301wwpr5 videoFoam=true"
                                    style={{
                                        height: "100%",
                                        position: "relative",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        className="wistia_swatch"
                                        style={{
                                            height: "100%",
                                            left: 0,
                                            opacity: 0,
                                            overflow: "hidden",
                                            position: "absolute",
                                            top: 0,
                                            transition: "opacity 200ms",
                                            width: "100%",
                                        }}
                                    >
                                        <img
                                            src="https://fast.wistia.com/embed/medias/hz301wwpr5/swatch"
                                            style={{
                                                filter: "blur(5px)",
                                                height: "100%",
                                                objectFit: "contain",
                                                width: "100%",
                                            }}
                                            alt=""
                                            aria-hidden="true"
                                            // onload="this.parentNode.style.opacity=1";
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="absolute w-[246px] md:left-[-80px] left-0 right-0 mx-auto md:right-auto bottom-[-100px] md:bottom-[0px] px-[16px] py-[9px] rounded-[8px] bg-lightPurple flex items-center gap-[11px]">
                            <SvgPlay />
                            <div className="flex flex-col gap-[2px]">
                                <h5 className="text-[20px] font-semibold">Faith Ann Wright</h5>
                                <p className="text-grayCharcoal text-base">
                                    Christian Life Coach
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="md:mt-[88px] mt-[116px] flex gap-[10px] items-start">
                        <SvgQuoteLeft />
                        <p className="italic text-center">
                            I recently subscribed to Zoee and ditched my Zoom and HoneyBook because
                            its an all-in-one inclusive CRM platform so now I can do invoicing and
                            video chat my clients and a ton of other features.
                        </p>
                        <SvgQuoteRight />
                    </div>
                </div>

                <div className="md:hidden max-w-[565px]">
                    <h3 className="text-[32px] font-semibold mb-[24px]">
                        Why should I choose Zoee?
                    </h3>
                    <p className="text-[18px]">
                        Zoee is on a mission to transform the way we do life. There's a reason why
                        coaching is the second fastest growing profession in the world, according to
                        PwC—people are broken, mental health issues are on the rise, and we are more
                        stressed out, disconnected, and full of dis-ease than ever before. If the
                        pandemic taught us anything, it's that we're all aching for human
                        connection.
                    </p>
                </div>
            </div>
        </>
    );
};

export default LandingWhyZoee;
