import type { SVGProps } from "react";
export const SvgExpired = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="currentColor"
      d="M26.992 13c-4.089 0-7.496 3.042-8.056 6.985.171-.025.341-.05.536-.05 1.29 0 2.482.682 3.14 1.777l4.477 7.52c4.43-.073 8.007-3.674 8.007-8.128C35.12 16.65 31.47 13 26.992 13m3.358 9.029h-3.358a.9.9 0 0 1-.9-.9v-4.21c0-.512.413-.901.9-.901.51 0 .9.413.9.9v3.31h2.458c.511 0 .9.413.9.9.025.511-.389.9-.9.9"
    />
    <path
      fill="currentColor"
      d="M20.932 22.759c-.657-1.095-2.263-1.095-2.92 0l-5.768 9.686c-.681 1.12.146 2.555 1.46 2.555h11.56c1.314 0 2.117-1.436 1.46-2.555zm-1.533 2.19a.924.924 0 0 1 1.022.876v.17l-.316 3.627a.647.647 0 0 1-.682.584.63.63 0 0 1-.584-.584l-.316-3.626a.96.96 0 0 1 .876-1.047m.073 8.08c-.56 0-1.022-.463-1.022-1.023s.462-1.022 1.022-1.022 1.022.463 1.022 1.023-.462 1.022-1.022 1.022"
    />
  </svg>
);
