import React from "react";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable";

const PrivateRouteStorageAvailable = ({ homePath = "/home" }) => {
    const { user } = React.useContext(UserCtx);
    const location = useLocation();
    const pathname = location.pathname;
    const [searchParams] = useSearchParams();
    const keys = Array.from(searchParams.keys())
    const params: any = {}
    keys.forEach(key => {
        params[key] = searchParams.get(key)
    })

    let route;
    if (isLocalStorageAvailable()) {
        if (user) {
            route = <Outlet />;
        } else {
            route = (
                <Navigate
                    to="/direct-routes"
                    state={{ storageAvailable: true, pathname: pathname, searchParams: params }}
                />
            );
        }
    } else {
        route = <Navigate to={homePath} state={{ storageAvailable: false }} />;
    }
    return route;
};

export default PrivateRouteStorageAvailable;
