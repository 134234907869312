import type { SVGProps } from "react";
export const SvgAsterisk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 8 8"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.828 8H3.172V5.386L.828 6.694 0 5.308 2.344 4 0 2.694l.828-1.387 2.344 1.307V0h1.656v2.615l2.344-1.308L8 2.694 5.656 4 8 5.307l-.828 1.386-2.344-1.307z"
    />
  </svg>
);
