import * as React from "react";
import moment from "moment";
import { ClientPaymentRequests, UserInfo } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import type {
  GetConnectAccountResponseType,
  MessageResponseType,
  NextStepsValuesType,
} from "../../../models/app.interface";
import { OnSuccessfulAuthCallback } from "../context";

type View = "email" | "password" | "one-time-password";

export interface LoginFormContext {
  email: string;
  setEmail: (email: string) => void;

  password: string;
  setPassword: (password: string) => void;

  oneTimePassword: string;
  setOneTimePassword: (oneTimePassword: string) => void;

  view: View;
  setView: (view: View) => void;

  onSuccess: OnSuccessfulAuthCallback;

  getIdentityStatus: (
    userId: string,
  ) => Promise<GetConnectAccountResponseType | undefined>;
  getSetupInfo: (userId: string) => Promise<NextStepsValuesType | undefined>;
  updateTimezone: (
    userId: string,
    access_token: string,
  ) => Promise<MessageResponseType | undefined>;
}

export const LoginFormContext = React.createContext<LoginFormContext>(
  {} as LoginFormContext,
);

export const LoginFormProvider: React.FC<
  React.PropsWithChildren<
    { defaultEmail: string; onSuccess: OnSuccessfulAuthCallback }
  >
> = (
  { children, defaultEmail, onSuccess },
) => {
  const [email, setEmail] = React.useState<string>(defaultEmail || "");
  const [password, setPassword] = React.useState<string>("");
  const [oneTimePassword, setOneTimePassword] = React.useState<string>("");
  const [view, setView] = React.useState<View>("email");

  const { renderError } = React.useContext(CommonFunctionCtx);

  const getIdentityStatus = async (userId: string) => {
    try {
      const identityStatus = await ClientPaymentRequests.getConnectAccount({
        user_id: parseInt(userId),
      });

      return identityStatus;
    } catch (error: any) {
      console.error(error);
      renderError(error?.response?.data?.message);
    }
  };

  const getSetupInfo = async (userId: string) => {
    try {
      const nextSteps = await UserInfo.getNextStepsValues({
        user_id: parseInt(userId),
      });

      return nextSteps;
    } catch (error: any) {
      console.error(error);
      renderError(error.response.data.message);
    }
  };

  const updateTimezone = async (userId: string, access_token: string) => {
    try {
      const data = await UserInfo.editTimezone({
        user_id: parseInt(userId),
        access_token: access_token,
        timezone: { value: moment.tz.guess(true) },
      });

      return data;
    } catch (error: any) {
      console.error(error);
      renderError(error.response.data.message);
    }
  };

  return (
    <LoginFormContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        oneTimePassword,
        setOneTimePassword,
        view,
        setView,
        getIdentityStatus,
        getSetupInfo,
        updateTimezone,
        onSuccess,
      }}
    >
      {children}
    </LoginFormContext.Provider>
  );
};
