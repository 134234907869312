import { AvailabilityRulesData } from "../types/availabilityTypes";

export const timezones = [
  {
    value: "america/new_york",
    label: "Eastern Standard Time - North America",
  },
  {
    value: "america/chicago",
    label: "Central Standard Time - North America",
  },
  {
    value: "america/denver",
    label: "Mountain Standard Time - North America",
  },
  {
    value: "america/los_angeles",
    label: "Pacific Standard Time - North America",
  },
  {
    value: "europe/london",
    label: "GMT - United Kingdom",
  },
];

export const defaultAvailability: AvailabilityRulesData = {
  id: null,
  name: "New Availability Rule",
  is_default: false,
  timezone: null,
  weekly_periods: [
    {
      day: "monday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      day: "tuesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      day: "wednesday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      day: "thursday",
      start_time: "09:00",
      end_time: "17:00",
    },
    {
      day: "friday",
      start_time: "09:00",
      end_time: "17:00",
    },
  ],
  buffer_before: 0,
  buffer_after: 0,
};

export const defaultAvailabilityOption = [
  {
    value: "none",
    label: "(No availabilities have been set yet)",
  },
];
