import type { SVGProps } from "react";
export const SvgInProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="40"
      height="40"
      rx="20"
      fill="#F2F5F7"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 9.6875C19.2111 9.6875 18.4439 9.77593 17.7075 9.94303L17.2926 8.11451C18.1642 7.91673 19.0705 7.8125 20 7.8125C20.9296 7.8125 21.8359 7.91673 22.7075 8.11451L22.2926 9.94303C21.5562 9.77593 20.789 9.6875 20 9.6875Z"
      fill="#FCB900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.2921 12.708C26.7342 12.1501 26.1292 11.6701 25.4903 11.2676L26.4899 9.68123C27.2461 10.1577 27.9606 10.7249 28.6179 11.3821C29.2752 12.0394 29.8423 12.754 30.3188 13.5102L28.7325 14.5097C28.3299 13.8708 27.85 13.2658 27.2921 12.708ZM11.2676 25.4903C11.6702 26.1292 12.1501 26.7342 12.708 27.292C13.2659 27.8499 13.8709 28.3299 14.5098 28.7324L13.5102 30.3188C12.754 29.8423 12.0394 29.2751 11.3822 28.6179C10.7249 27.9606 10.1578 27.246 9.68128 26.4898L11.2676 25.4903Z"
      fill="#FCB900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.94303 17.7076C9.77593 18.444 9.6875 19.2112 9.6875 20.0001C9.6875 20.7891 9.77593 21.5562 9.94303 22.2927L8.11451 22.7076C7.91673 21.8359 7.8125 20.9296 7.8125 20.0001C7.8125 19.0706 7.91673 18.1643 8.11451 17.2927L9.94303 17.7076Z"
      fill="#FCB900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5098 11.2676C13.8709 11.6701 13.2659 12.1501 12.708 12.708C12.1501 13.2658 11.6702 13.8708 11.2676 14.5097L9.68128 13.5102C10.1578 12.754 10.7249 12.0394 11.3822 11.3821C12.0395 10.7249 12.754 10.1577 13.5102 9.68123L14.5098 11.2676Z"
      fill="#FCB900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 9.6875C19.2111 9.6875 18.4439 9.77593 17.7075 9.94303L17.2926 8.11451C18.1642 7.91673 19.0705 7.8125 20 7.8125C20.9296 7.8125 21.8359 7.91673 22.7075 8.11451L22.2926 9.94303C21.5562 9.77593 20.789 9.6875 20 9.6875Z"
      stroke="#FCB900"
      stroke-width="1.66667"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.2921 12.708C26.7342 12.1501 26.1292 11.6701 25.4903 11.2676L26.4899 9.68123C27.2461 10.1577 27.9606 10.7249 28.6179 11.3821C29.2752 12.0394 29.8423 12.754 30.3188 13.5102L28.7325 14.5097C28.3299 13.8708 27.85 13.2658 27.2921 12.708ZM11.2676 25.4903C11.6702 26.1292 12.1501 26.7342 12.708 27.292C13.2659 27.8499 13.8709 28.3299 14.5098 28.7324L13.5102 30.3188C12.754 29.8423 12.0394 29.2751 11.3822 28.6179C10.7249 27.9606 10.1578 27.246 9.68128 26.4898L11.2676 25.4903Z"
      stroke="#FCB900"
      stroke-width="1.66667"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.94303 17.7076C9.77593 18.444 9.6875 19.2112 9.6875 20.0001C9.6875 20.7891 9.77593 21.5562 9.94303 22.2927L8.11451 22.7076C7.91673 21.8359 7.8125 20.9296 7.8125 20.0001C7.8125 19.0706 7.91673 18.1643 8.11451 17.2927L9.94303 17.7076Z"
      stroke="#FCB900"
      stroke-width="1.66667"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.5098 11.2676C13.8709 11.6701 13.2659 12.1501 12.708 12.708C12.1501 13.2658 11.6702 13.8708 11.2676 14.5097L9.68128 13.5102C10.1578 12.754 10.7249 12.0394 11.3822 11.3821C12.0395 10.7249 12.754 10.1577 13.5102 9.68123L14.5098 11.2676Z"
      stroke="#FCB900"
      stroke-width="1.66667"
    />
    <path
      d="M17.7079 30.0612C18.4443 30.2283 19.2115 30.3167 20.0004 30.3167C20.7894 30.3167 21.5565 30.2283 22.293 30.0612L22.7079 31.8897C21.8362 32.0875 20.9299 32.1917 20.0004 32.1917C19.0709 32.1917 18.1646 32.0875 17.293 31.8897L17.7079 30.0612Z"
      fill="black"
    />
    <path
      d="M30.3129 20.0043C30.3129 19.2154 30.2245 18.4482 30.0573 17.7118L31.8859 17.2969C32.0836 18.1685 32.1879 19.0748 32.1879 20.0043C32.1879 20.9338 32.0836 21.8402 31.8859 22.7118L30.0573 22.2969C30.2244 21.5605 30.3129 20.7933 30.3129 20.0043Z"
      fill="black"
    />
    <path
      d="M27.2925 27.2962C27.8503 26.7384 28.3303 26.1334 28.7329 25.4945L30.3192 26.4941C29.8427 27.2502 29.2756 27.9648 28.6183 28.6221C27.961 29.2793 27.2465 29.8465 26.4903 30.323L25.4907 28.7366C26.1296 28.3341 26.7346 27.8541 27.2925 27.2962Z"
      fill="black"
    />
    <path
      d="M17.7079 30.0612C18.4443 30.2283 19.2115 30.3167 20.0004 30.3167C20.7894 30.3167 21.5565 30.2283 22.293 30.0612L22.7079 31.8897C21.8362 32.0875 20.9299 32.1917 20.0004 32.1917C19.0709 32.1917 18.1646 32.0875 17.293 31.8897L17.7079 30.0612Z"
      stroke="black"
      stroke-width="1.66667"
    />
    <path
      d="M30.3129 20.0043C30.3129 19.2154 30.2245 18.4482 30.0573 17.7118L31.8859 17.2969C32.0836 18.1685 32.1879 19.0748 32.1879 20.0043C32.1879 20.9338 32.0836 21.8402 31.8859 22.7118L30.0573 22.2969C30.2244 21.5605 30.3129 20.7933 30.3129 20.0043Z"
      stroke="black"
      stroke-width="1.66667"
    />
    <path
      d="M27.2925 27.2962C27.8503 26.7384 28.3303 26.1334 28.7329 25.4945L30.3192 26.4941C29.8427 27.2502 29.2756 27.9648 28.6183 28.6221C27.961 29.2793 27.2465 29.8465 26.4903 30.323L25.4907 28.7366C26.1296 28.3341 26.7346 27.8541 27.2925 27.2962Z"
      stroke="black"
      stroke-width="1.66667"
    />
  </svg>
);
