import React from "react";
import CloseModal from "./CloseModal";
import { Button } from "./ui/button";
import { SvgWarning } from "./icons";

interface Props {
    setShowDialogueModal: any;
    confirmCallbackFn: any;
    header: string;
    helpText: string;
    confirmButtonText: string;
    isWarningVariant?: boolean;
}

const DeleteDialogueModal: React.FC<Props> = ({
    setShowDialogueModal,
    confirmCallbackFn,
    header,
    helpText,
    confirmButtonText,
    isWarningVariant,
}) => {
    return (
        <div
            className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[3001] flex items-center justify-center"
        >
            <div
                className="md:p-[48px] p-[24px] h-auto w-[335px] md:w-[600px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative"
            >
                <CloseModal
                    styling="absolute top-[24px] right-[24px]"
                    callback={setShowDialogueModal}
                />
                {isWarningVariant && (
                    <SvgWarning className="mb-[24px] md:mb-[32px]"/>
                )}
                <h3 className="font-bold text-[18px] md:text-[22px] text-center">{header}</h3>
                <p className="mt-[16px] text-graySlate text-base text-center w-[80%]">
                    {helpText}
                </p>
                <div className="flex items-center justify-center gap-[16px] mt-[32px]">
                    <Button
                        onClick={() => setShowDialogueModal((prev: boolean) => !prev)}
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <Button onClick={confirmCallbackFn} variant="destructive">
                        {confirmButtonText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DeleteDialogueModal;
