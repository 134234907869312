import previewImage from "../../../assets/blog/runASuccessfulOnlineBusiness.webp";
import { Post } from "../../../types/postTypes";

export const runASuccessfulOnlineBusiness: Post = {
    metadata: {
        id: 11,
        title: "What does it really take to run a successful online coaching business?",
        subtitle: "written by Nichole Lowe, CEO & Founder of Zoee",
        author: "Nichole Lowe, CEO & Founder of Zoee",
        date: "December 5, 2022",
        urlSlug: "run-a-successful-online-business",
        description:
            "Are you considering becoming an online coach, but are not sure how to get started? There's no one-size-fits-all path to becoming an online coach, but these tips can help you set up your online business for success.",
        previewImg: previewImage,
        tags: ["Coach", "Business", "Entrepreneur"],
    },
    content: [
        {
            type: "paragraph",
            text: "Running an online coaching business can seem like a dream at first. You’ll work from home, have a more flexible schedule, make a difference in the lives of everyday people, and be your own boss. Ideal, right?",
        },
        {
            type: "paragraph",
            text: "But when you actually start the process of opening and running an online business, there could be unexpected hurdles. Things like:",
        },
        {
            type: "ordered-list",
            items: [
                "Getting new clients when the only living creatures you interact with are your dog and house plant.",
                "Staying organized when you’re suddenly your own accountant, marketer, tech support, and administrative assistant, in addition to being a full time coach.",
                "Managing the rising monthly expenses of being a solopreneur.",
            ],
        },
        {
            type: "paragraph",
            text: "These aren’t reasons to close up shop and return to a 9-5 job—not at all. In fact, there are simple solutions you can do today to positively impact your coaching business for years to come. Because the truth is, you were meant to be a coach and you shouldn’t let obstacles hold you back. So why not embrace all the quirks that come with being an online business owner and continue to make an impact in your clients’ lives?",
        },
        {
            type: "paragraph",
            text: "Here’s our advice for how to run a successful online coaching business:",
        },
        {
            type: "header2",
            text: "Get Comfortable with a Routine",
        },
        {
            type: "paragraph",
            text: "One of the most boring activities we do as humans everyday, twice a day (hopefully), is brush our teeth. Unless you’ve got a brand new fancy toothpaste you’re jazzed about, brushing your teeth is mundane—but it’s necessary. We believe your online coaching business deserves the same dedication and attention your teeth do.",
        },
        {
            type: "paragraph",
            text: "By creating a routine in your business, you can ensure it grows. Setting time aside for meeting with clients, discovery calls, marketing your business, and networking to generate new leads, is critical to your business’s success. So how do you find time to get it all done?",
        },
        {
            type: "paragraph",
            text: "With a platform like Zoee, you can set your availability for client and discovery calls so there’s no back and forth communication about “finding a time that works for everyone.” In fact, with Zoee you and your clients can sync multiple calendars, meaning you can quickly view eachothers real-time availability, and clients can self-book and even auto-pay when you connect your Zoee customized programs.",
        },
        {
            type: "paragraph",
            text: "You can also block off time in your calendar for admin tasks, but those tasks diminish quickly with Zoee. “Inbox Zero” becomes “Welcome messages to your clients” and “Send invoices” becomes “Meditate before my next session to get grounded.” See? You’re already saving time.",
        },
        {
            type: "header2",
            text: "Simplify to Amplify",
        },
        {
            type: "paragraph",
            text: "With an overload of clunky, disjointed systems—a CRM to manage clients, email and text for communication, Zoom for video conferencing, QuickBooks for payment processing and invoicing, Google Workspace for note taking and sharing, and a website to showcase programs and services—it can feel like you’re more of an office manager than a coach. The maintenance and cost of hacking together all these platforms combined take valuable time, mindshare and money away from your business.",
        },
        {
            type: "paragraph",
            text: "We recommend selecting a single solution platform like Zoee to consolidate and streamline your online coaching business. With Zoee, it’s simple -- all you need is one subscription to handle it all. What’s more? We free coaches from the headache of invoicing clients. Instead, we’ve created a system like Uber for coaches. On Zoee, autopay means you’ll never have to bill clients again. All you’ll see is the payment records in your account—and get paid, of course.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "This means you’re saving money—nearly $100 per month or $500 annually—by moving forward with the platform of the future. Zoee also offers more than the other platforms combined. See for yourself by checking out",
                },
                {
                    type: "link",
                    href: "https://zoee.com/features",
                    text: " our features.",
                },
            ],
        },
        {
            type: "header2",
            text: "Don’t be Afraid to Put Yourself Out There",
        },
        {
            type: "paragraph",
            text: "Lead generation can be a struggle for coaches, especially when you work from home. The opportunity to meet people in passing is basically gone, unless you travel to a coworking space or different coffee shops to do your work each day. So how can you get your name out there?",
        },
        {
            type: "paragraph",
            text: "There’s social media marketing, local networking events, and all the other traditional means of marketing your business. But what if your online business platform could market your coaching business for you?",
        },
        {
            type: "paragraph",
            text: "Coming in early 2023, Zoee is excited to launch Marketplace, a powerful search engine similar to Airbnb that will showcase coaches instead of beach bungalows. We’ll promote our qualified coaches on the platform and on social media, and offer matching technology so potential clients can get paired with you. And your personalized public profile page is available for anyone to see, meaning when people come looking for a coach on Zoee, you’ll be right there waiting for them.",
        },
        {
            type: "paragraph",
            text: "Lastly, our growing community of coaches offers a supportive network of like-minded thought leaders and business owners for you to connect with. While other coaches may not be immediate leads, coaching businesses thrive on referrals. We’re here to help you meet clients and coaches who will grow your business. And coaches need coaches. On Zoee, coaches also get a free member account, meaning you can search and hire other coaches to advance your personal and professional development goals.",
        },
        {
            type: "paragraph",
            text: "We believe running an online coaching business should be simple. Being a coach is a huge accomplishment, and what you’re offering clients is an incredible contribution to their livelihood, their families, and their communities. So don’t let the obstacles of running your business get in your way. We’re here to partner with you so you can run the coaching business of your dreams!",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "If you’re interested in a free trial, don’t wait! We’ve got special offers for coaches through the end of the year. We can’t wait to have you join Zoee.",
                },
            ],
        },
    ],
};
