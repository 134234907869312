import type { SVGProps } from "react";
export const SvgUploadImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 22 18"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22 10.77a6.62 6.62 0 0 1-2.043 4.757 7.1 7.1 0 0 1-4.922 1.973.74.74 0 0 1-.635-.354.69.69 0 0 1 0-.708.74.74 0 0 1 .635-.355c1.907 0 3.678-.954 4.68-2.523a5.15 5.15 0 0 0 .232-5.173c-.857-1.646-2.536-2.746-4.436-2.905l-.476-.023a5.6 5.6 0 0 0-4.097 1.77.749.749 0 0 1-1.041.062.694.694 0 0 1-.051-1.007 7.05 7.05 0 0 1 4.239-2.171 7.4 7.4 0 0 0-4.096-2.087 7.55 7.55 0 0 0-4.573.703 7.2 7.2 0 0 0-3.222 3.213 6.86 6.86 0 0 0-.584 4.438 7.02 7.02 0 0 0 2.288 3.89 7.45 7.45 0 0 0 4.246 1.785.74.74 0 0 1 .497.252c.125.144.185.33.168.517a.7.7 0 0 1-.258.483.74.74 0 0 1-.537.16 8.94 8.94 0 0 1-5.262-2.291 8.4 8.4 0 0 1-2.66-4.949 8.26 8.26 0 0 1 1.086-5.476 8.7 8.7 0 0 1 4.364-3.648 9.1 9.1 0 0 1 5.765-.25 8.8 8.8 0 0 1 4.686 3.256 7.08 7.08 0 0 1 4.283 2.254A6.64 6.64 0 0 1 22 10.77m-6.787 1.976-2.977-2.833a.74.74 0 0 0-.523-.203l-.006-.002a.73.73 0 0 0-.55.251L8.23 12.746a.696.696 0 0 0-.014 1.008c.137.135.325.211.522.211a.75.75 0 0 0 .522-.21l1.713-1.632v4.669c0 .253.14.487.367.613a.76.76 0 0 0 .733 0 .7.7 0 0 0 .367-.613v-4.697l1.743 1.66c.138.134.326.21.522.21a.75.75 0 0 0 .522-.21.695.695 0 0 0-.015-1.009"
    />
  </svg>
);
