import type { SVGProps } from "react";
export const SvgEditorIndentIncrease = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11 13h10v-2H11zm0-4h10V7H11zM3 3v2h18V3zm8 14h10v-2H11zM3 8v8l4-4zm0 13h18v-2H3z"
    />
  </svg>
);
