import previewImage from "../../../assets/blog/howBoundaries.webp";
import { Post } from "../../../types/postTypes";

export const howBoundariesAndBreaksCanHelpFightCoachBurnout: Post = {
    metadata: {
        id: 2,
        title: "How Boundaries and Breaks Can Help Fight Coach Burnout",
        author: "Zoee",
        date: "September 19, 2022",
        urlSlug: "boundaries-and-breaks-to-fight-burnout",
        description:
            "You're not alone in your fight against burnout. Take action now by utilizing these 5 strategies to alleviate burnout this week.",
        previewImg: previewImage,
        tags: ["Mental Health", "Coach", "Entrepreneur", "Burnout"]
    },
    content: [
        {
            type: "paragraph",
            text: "If you've sensed a certain “flakiness” going around lately, you aren't alone. People are canceling plans at the last minute, neglecting wedding RSVPs, and even ghosting coworkers on work calls. It begs the question, “What's going on here?”",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "It's no secret that burnout has been the topic of conversation for many months now as a result of everyone slogging through work while dealing with a global pandemic, a divisive political climate, and social unrest. Though burnout existed before 2020, the buzzword has leapt into our social media feeds,"
                },
                {
                    type: "italic",
                    text: "Harvard Business Review"
                },
                {
                    type: "normal",
                    text: "and even dinner conversations among friends. It's relatively easy to identify: always tired, not feeling rested after the weekend or a night's sleep, low motivation and sense of accomplishment, cynicism, headaches, difficulty concentrating, and being in a constant state of stress, just to name a few."
                }
            ]
        },
        {
            type: "paragraph",
            text: "People are dropping the ball more frequently. As a result of burnout, they aren't motivated to let you know whether they'll come to your wedding, or they don't care about canceling plans at the last minute because caring about things like that takes effort and is something we don't have energy for. Missing work calls is another thing people are more comfortable doing when they are burned out, which affects their performance reviews, trustworthiness among co-workers, and overall work culture.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "In Greg McKeown's book"
                },
                {
                    type: "italic",
                    text: "Essentialism,"
                },
                {
                    type: "normal",
                    text: "the author describes one man who worked himself nearly to death and was required by doctor's orders to take three months' vacation. The troubling part about this (other than his near-death experience) was that the more time he was away from work on sabbatical, the worse he felt. Symptoms of burnout increased as he neared the end of his three months, eventually prompting him to ask his doctor for more time. We won't spoil the book for you, but the gist is that his body needed a lot of time to recover from the incredible stress that compounded year after year."
                }
            ]
        },
        {
            type: "paragraph",
            text:
                "Of course, a sabbatical is ideal when you're experiencing total burnout, but it's not always an option, especially if you have a home to manage, as well as your job, caretaking, etc. Our lives are busy, made up of many responsibilities. Though the temptation to fly to Tahiti and rest for a year is tempting, it's not in the cards for most of us.",
        },
        {
            type: "paragraph",
            text:
                "So what do we do? Continue on a downward spiral of burnout until a doctor writes a prescription for a vacation? We've identified a few things you can do in the next week to help yourself recover from burnout, and none of them involve an expensive plane ticket or a visit to the doctor's office.",
        },
        {
            type: "header1",
            text: "Boundaries Are Beautiful"
        },
        {
            type: "paragraph",
            text:
                "Setting boundaries can seem like the simplest idea on paper, but in practice it's difficult to execute. This is because we say to ourselves, “Today I'm going to work from 8 a.m. to 4 p.m. so I can exercise at 4:15 p.m. before making dinner for my family at 6 p.m.” Then 3:55 p.m. rolls around and we jump into a new project on our list, letting the stress of work overcome our desire to exercise. Our tasks become the priority because we think it will relieve stress. The mentality here is that, if my to-do list is shorter, I'll feel less stressed. But in reality, we are breaking a promise to ourselves, and that sacrifice is only slightly moving the needle forward in our work.",
        },
        {
            type: "paragraph",
            text:
                "It's not worth it. Shutting down your computer at the time you set for yourself actually builds self-trust and allows you to decompress with a chosen activity, like exercising, spending time with your kids, going for a walk with a neighbor, or reading a book.",
        },
        {
            type: "paragraph",
            text:
                "Remember all the boundaries circulating the internet at the beginning of the COVID lockdown? Everyone was talking about how to maintain work-life balance when we were all working from home. Two and a half years may have passed, but there's still a lot of truth to that advice. Shutting down work at a certain time each day builds a habit to stop thinking about work at that time.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "There's a funny scene in the hit NBC show"
                },
                {
                    type: "italic",
                    text: "The Office,"
                },
                {
                    type: "normal",
                    text: "where Ed Helms' character, Andy Bernard, has recently become regional manager and wants to implement a closing ritual for the team. He plays the song “Closing Time” by Semisonic to encourage everyone to leave work at 5 p.m. Though cheesy (and may trigger memories of bars closing and politely telling you to go home at 2 a.m.), strategies like this work."
                }
            ]
        },
        {
            type: "header1",
            text:
                "Learn to Love Lunch",
        },
        {
            type: "paragraph",
            text:
                "Working through lunch is a common practice we all find ourselves doing. “I just need to get this last email sent,” we say, and then before you know it, it's 1 p.m. and we have another Zoom meeting to hop on. Aside from the fact that our bodies need nutrients, nourishment, and calories to continue to function for the rest of the workday, working through lunch doesn't allow our brain to turn off and think about anything else. We'll power through and work eight hours straight without a single break, other than maybe to use the restroom, and never let our minds think about anything other than the reports we've got to finalize by the end of the week.",
        },
        {
            type: "paragraph",
            text:
                "Microbreaks (frequent 10-minute breaks during the workday) and the Pomodoro Technique (which, sadly, does not mean taking breaks to eat spaghetti), where you work in 25-minute increments separated by 5-minute breaks, are useful tactics. But this still doesn't speak to lunch: Are we working through it to lighten the load, or are we taking a break to eat a sandwich and chat with co-workers?",
        },
        {
            type: "paragraph",
            text:
                "The choice is the right one only if it's intentional. If you're on a roll and feel that working until 2 p.m. and then taking a lunch break will satisfy you, then do it. Disrupting the flow of work can be frustrating. So, as long as there are breaks for a meal and brain rest throughout the day, we think it's okay to work through a normal lunchtime.",
        },
        {
            type: "paragraph",
            text:
                "You can also intentionally put a “lunch meeting” on your calendar to ensure you prioritize the break. This can just be lunch with yourself (and maybe a podcast you love listening to), nothing overly fancy. Food is fuel, so stopping work to nourish your body will actually make you more productive in the long run. We all know what “hangry” feels like, and it often doesn't lead to good performance reviews or satisfying workdays.",
        },
        {
            type: "header1",
            text:
                "Stop! In the Name of Love—er'… of Work",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "For the love of your career, there"
                },
                {
                    type: "boldItalic",
                    text: "is"
                },
                {
                    type: "normal",
                    text: "a time to stop working. Email notifications after 5 p.m. hold you suspended between both sides of the work-life balance threshold, diminishing your rest and recovery time from the office. Checking your phone as soon as you wake up in the morning? Also not great, given that creating space for"
                },
                {
                    type: "italic",
                    text: "ourselves"
                },
                {
                    type: "normal",
                    text: "as soon as we wake up is advised and can lead to a sense of control and peace."
                }
            ]
        },
        {
            type: "paragraph",
            text: "Work is work. We give it the time it needs in exchange for a paycheck, fulfillment (hopefully), and connection to our purpose on the planet. But that doesn't mean it runs our entire lives. Sitting in your car and catching up on emails while your child plays soccer is not being present at the soccer game, nor is it being present with yourself.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "We need to remind ourselves that our jobs are not life and death oriented. Though our work is important and we should take it seriously, sending that email is"
                },
                {
                    type: "italic",
                    text: "not"
                },
                {
                    type: "normal",
                    text: "a life-and-death matter."
                }
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "And if our jobs did mean life or death, we'd be"
                },
                {
                    type: "italic",
                    text: "required"
                },
                {
                    type: "normal",
                    text: "o take breaks. Airline pilots, for instance, have strict rules around when they take off (okay, pun intended). None of us want to fly in an airplane flown by a sleep-deprived and stressed-out person, because we don't want the plane to crash! Surgeons also have gobs of vacation time—and they use it. You want the person operating on your body to be happy, healthy, unstressed, and confident in their decision making. So, no, your job may not be saving lives, but know that you should value your time off as if it did."
                }
            ]
        },
        {
            type: "header1",
            text: "Hire a Coach"
        },
        {
            type: "paragraph",
            text: "No surprise here! Hiring a coach can help you with all of this. Whether you are unsure about what you really want in life, need help prioritizing, are feeling frustrated with your current work environment, or are going through a major life change, coaches are there to do life with you. They are there to help hold you accountable, see your blind spots, encourage you, and celebrate progress."
        },
        {
            type: "paragraph",
            text: "Selecting the right coach for you may seem daunting at first, but taking the time to consider what your pain points are and what you really need help with can be a game changer in the search for a perfect match. Don't hesitate to make a few inquiry calls or read through coaches' websites to find someone who aligns with you. It's worth the upfront work to find someone who will help you create a life you love."
        },
        {
            type: "paragraph",
            text: "Before spending time on the internet, however, tap all your networks for a coach. Friends may have coach recommendations, and those referrals can often be the best matches. Also, you may have resources through your company to hire a coach, which is a relatively recent perk in many work environments."
        },
        {
            type: "header1",
            text: "High-Five a New Hobby"
        },
        {
            type: "paragraph",
            text: "Though learning a new skill may seem like it would add even more stress to your already full plate, it actually helps the brain turn off its work mode and turn on its “fully concentrate on this new thing” mode. We aren't thinking about work when we're learning how to play tennis or speak French or knit."
        },
        {
            type: "paragraph",
            text: "We need to allow our brains to think of something completely different, and getting our bodies involved is even better. Watching Netflix is great for a lazy Saturday, but it doesn't necessarily help us recover from the stress of the workday—especially if we're scrolling on our phones while passively watching television."
        },
        {
            type: "paragraph",
            text: "Finding a new hobby can also build mental endurance and bring about stress relief—two things that would help us become more productive at work. So consider picking up that skill you have been thinking about for the last five years. You may not be the next Serena Williams, but you will help yourself recover from burnout."
        },
        {
            type: "paragraph",
            text: "–"
        },
        {
            type: "paragraph",
            text: "Don't let us stop you: If a sabbatical is available to you and you really feel like you need one, then take it! Maybe even hiring a coach to help you navigate a major change (like taking off work for a few months) would be beneficial and help you recover."
        },
        {
            type: "paragraph",
            text: "But if you can't take time off, consider the strategies we offered here. Signing up for a new hobby, stopping work at a certain time each day, hiring a coach, being intentional with your lunch break, and turning off email notifications can help you create a sense of work-life balance. At first, these things may feel uncomfortable since your body is used to living in a state of stress. But over time, these strategies can help you relax your shoulders, ease your grip, and reduce burnout."
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office."
                },
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Written by Zoee."
                },
            ]
        },
    ],
};
