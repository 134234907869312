import type { SVGProps } from "react";
export const SvgLinkedin2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="M5.047 20V7.152H1.05V20zM3.027 5.434c1.29 0 2.32-1.075 2.32-2.364 0-1.246-1.03-2.277-2.32-2.277A2.29 2.29 0 0 0 .75 3.07c0 1.29 1.031 2.364 2.277 2.364M20 20v-7.047c0-3.437-.773-6.101-4.812-6.101-1.934 0-3.223 1.074-3.782 2.062h-.043V7.152H7.54V20h3.996v-6.36c0-1.675.3-3.265 2.363-3.265s2.106 1.89 2.106 3.395V20z"
    />
  </svg>
);
