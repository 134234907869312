import React from "react";
import { UserCtx } from "../../../context/userContext";
import ServiceCardBannerOverlay from "../../services/ServiceCardBannerOverlay";
import { SvgBundle, SvgConsultation, SvgRecurring, SvgUserCheck } from "../../icons";
import { pluralize } from "../../../utils/pluralize";
import { getUnits } from "../../../utils/services/get-units/get-units";
import { getPrice } from "../../../utils/services/get-price";

interface Props {
    service: any;
    showFeatures: boolean;
    buttonText: string;
    buttonCallback: React.MouseEventHandler<HTMLButtonElement>;
    secondaryButtonCallback: any;
    serviceChangeType?: string;
    setServiceChangeType?: any;
}

const ServiceCardPreview: React.FC<Props> = ({
    service,
    showFeatures,
    buttonText,
    buttonCallback,
    secondaryButtonCallback
}) => {
    const {
        billing,
        expiration,
        image,
        session,
        title,
        type,
        symbol
    } = service.service_details;
    const { user } = React.useContext(UserCtx);

    const getServiceType = () => {
        if (type === "consultation") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgConsultation />
                    Consultation Session
                </div>
            );
        } else if (type === "individual") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgUserCheck />
                    Individual Session
                </div>
            );
        } else if (type === "bundle") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgBundle />
                    Bundle Package
                </div>
            );
        } else if (type === "recurring") {
            return (
                <div className="flex items-center gap-[8px] text-base font-bold">
                    <SvgRecurring />
                    Recurring Program
                </div>
            );
        }
    };

    const getSessionCount = () => {
        if(["consultation", "individual"].includes(type)){
            return `${session.count} session`
        } else if (["bundle"].includes(type)){
            return pluralize(session.count, "session")
        } else if (["recurring"].includes(type)){
            return (<div>
                {pluralize(session?.count, "session")}
                <span className="text-darkGray"> / {`${billing?.unit}`}</span>
            </div>)
        } else {
            return "Not Found"
        }
    }

    return (
        <div
            className={`flex flex-col rounded-[16px] bg-white main-shadow  ${
                showFeatures ? "h-[496px] w-[488px]" : "h-[319px] w-[326px] md:h-[304px] md:w-[528px]"
            }`}
        >
            <div
                className={`w-full ${
                    showFeatures ? "h-[120px]" : "h-[80px]"
                } rounded-t-[16px] ${service?.service_details?.image?.color} relative`}
            >
                <ServiceCardBannerOverlay
                  service={service}
                />
                {image?.exists && (
                    <img 
                        src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service.stripe_product_id}?${new Date().getTime()}`} 
                        alt="service"
                        className='w-full h-full object-cover rounded-t-[16px]'
                    />
                )}
            </div>
            <div className="md:w-[430px] w-full min-h-[224px] mx-auto px-[24px] md:px-0 py-[24px] flex flex-col justify-between">
                <div>
                    <div className="flex flex-col md:flex-row md:items-center w-full justify-between mb-[16px] md:mb-[20px]">
                        <h3 className="font-bold text-[16px]">{title}</h3>
                        <div className="flex items-baseline gap-[2px]">
                            <h3 className="font-bold text-[22px] text-blurple">{getPrice(billing?.amount, symbol)}</h3>
                            <p className="text-base text-graySlate">{getUnits(billing?.unit, parseInt(billing?.amount), type)}</p>
                        </div>
                    </div>
                    <div className="w-full h-[1px] bg-grayMist mb-[16px]"></div>
                    <div className="flex items-center justify-between w-full mb-[16px]">
                        {getServiceType()}
                        <div className="flex items-center gap-[8px]">
                            <div
                                className="hidden md:flex items-center justify-center text-base text-graySlate font-bold bg-grayFlash 
                            py-[4px] px-[8px] rounded-[8px]"
                            >
                                {getSessionCount()}
                            </div>
                        </div>
                    </div>
                    {showFeatures && (
                        <>
                            <div className="w-full h-[1px] bg-grayMist mb-[24px]"></div>
                            <div className="flex items-center justify-between w-full mb-[12px]">
                                <div className="text-base">Sessions Remaining</div>
                                <div
                                    className="flex items-center justify-center text-base text-graySlate font-bold bg-grayFlash 
                                    py-[4px] px-[8px] rounded-[8px]"
                                >
                                    {`${session.count} sessions`}
                                </div>
                            </div>
                            <div className="flex items-center justify-between w-full mb-[52px]">
                                <div className="text-base">Expiration</div>
                                <div
                                    className="flex items-center justify-center text-base text-graySlate font-bold bg-grayFlash 
                                    py-[4px] px-[8px] rounded-[8px]"
                                >
                                    {expiration.expires ? (
                                        `${expiration.time} ${expiration.type}`
                                    ) : (
                                        `None`
                                    )}
                                    
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <button onClick={() => buttonCallback(service)} className="btn-primary btn-blue w-full h-[48px]">
                        {buttonText}
                    </button>
                    {user.activeProfile === "member" && service.service_status === "pending" && (
                        <button 
                            onClick={() => secondaryButtonCallback(service)} 
                            className="btn-primary btn-outline-primary w-full h-[48px] ml-[10px]"
                        >
                            Decline
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServiceCardPreview;
