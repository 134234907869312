import React from "react";
import CloseModal from "../../CloseModal";

interface Props {
  setShowDetails: any;
}

const AttachServiceDetails: React.FC<Props> = ({ setShowDetails }) => {

  return (
   <div
        className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter 
            z-[9001] flex items-center justify-center"
      >
      <div
          className="px-[40px] md:px-[100px] py-[80px] max-h-[90vh] w-[90%] md:w-[800px] max-w-[95%] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative overflow-y-auto"
        >
        <CloseModal
            callback={setShowDetails}
            styling={"absolute top-[24px] right-[24px]"}
        />
        <h1 className="text-black text-[18px] md:text-[28px] font-semibold text-center mb-[36px]">
          Want to attach a client service to this meeting?
        </h1>
        <div className="w-full flex flex-col gap-[40px]">
          <p className="text-black">
            Consider the following regarding attaching a service to a meeting:
          </p>
          <ul className="list-disc font-bold gap-[8px] flex flex-col text-grayCharcoal">
            <li>You'll only see individual services as options here.</li>
            <li>Meetings for group services are scheduled automatically on the date enrollment closes, but can be updated after they're created.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AttachServiceDetails;
