import FeaturePost from "../../../components/marketing/blog/FeaturePost";
import PostsGrid from "../../../components/marketing/blog/PostsGrid";
import { posts } from "../../../data/postsData/postIndex";
import useScrollToTop from "../../../hooks/useScrollToTop";

export default function BlogPage() {
  useScrollToTop();

  return (
    <div className="blog-gray-background-gradient mx-auto mt-20 max-w-screen-2xl overflow-hidden">
      <div className="mx-auto max-w-[1210px] px-[24px] pt-[40px] md:pb-[12px] md:pt-[100px]">
        <FeaturePost post={posts[0]} />
        <div className="h-20" />
        <PostsGrid posts={posts} />
      </div>
    </div>
  );
}
