import { Button } from "../../../components/ui/button";
import { SvgChevronLeft, SvgChevronRight } from "../../../components/icons/";
import { cn } from "../../../utils/cn";

interface Props {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  dataLength: number;
}

function cycleIndex(
  currentIndex: number,
  direction: "next" | "prev",
  length: number,
) {
  const step = direction === "prev" ? -1 : 1;
  return (currentIndex + step + length) % length;
}

export function CarouselControls(props: Props) {
  return (
    <div className="flex items-center justify-between py-[20px] px-[32px] gap-4">
      <Button
        size="icon"
        variant="ghost"
        onClick={() =>
          props.setActiveIndex(
            cycleIndex(props.activeIndex, "prev", props.dataLength),
          )}
      >
        <SvgChevronLeft />
      </Button>

      <div className="flex items-center gap-3">
        {Array(props.dataLength).fill(null).map((_, index) => (
          <button
            key={index}
            className={cn("rounded-full size-4", {
              "bg-grayCharcoal": index === props.activeIndex,
              "bg-grayCharcoal/20": index !== props.activeIndex,
            })}
            onClick={() => props.setActiveIndex(index)}
          >
          </button>
        ))}
      </div>

      <Button
        size="icon"
        variant="ghost"
        onClick={() =>
          props.setActiveIndex(
            cycleIndex(props.activeIndex, "next", props.dataLength),
          )}
      >
        <SvgChevronRight />
      </Button>
    </div>
  );
}
