import React from "react";

type Props = {};

const ChannelLoading = (props: Props) => {
    return (
        <div className="pt-[32px] w-full justify-center items-center text-center text-base font-bold">
            Loading your inbox...
        </div>
    );
};

export default ChannelLoading;
