import type { SVGProps } from "react";
export const SvgProtectedPayment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 189 208"
    {...props}
  >
    <path
      fill="currentColor"
      d="M94 146.668C77.389 156.89 63.333 156.89 63.333 156.89c0 46 30.667 48.556 30.667 48.556s30.667-2.555 30.667-48.556c0 0-14.056 0-30.667-10.222m12.778 43.444a2.554 2.554 0 0 1-2.556 2.556H83.778a2.556 2.556 0 0 1-2.556-2.556v-12.777a2.56 2.56 0 0 1 2.556-2.556v-5.111a10.223 10.223 0 0 1 20.445 0v5.111a2.555 2.555 0 0 1 2.555 2.556zm-7.667-15.333H88.89v-5.111a5.11 5.11 0 0 1 7.666-4.426 5.11 5.11 0 0 1 2.556 4.426z"
    />
    <path
      stroke="currentColor"
      d="M.61 116.836c47.99-59.633 139.027-59.827 188.001-1"
    />
    <path
      stroke="currentColor"
      d="M20.48 126.172c37.771-46.935 109.424-47.088 147.97-.787"
    />
    <path
      stroke="currentColor"
      d="M41.342 135.841c26.862-33.38 77.821-33.489 105.234-.56"
    />
  </svg>
);
