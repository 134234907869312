import type { SVGProps } from "react";
export const SvgFacebook2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 13 23"
    {...props}
  >
    <path
      fill="currentColor"
      d="m11.395 13.125.601-3.953H8.172V6.594c0-1.117.515-2.149 2.234-2.149h1.762V1.051S10.578.75 9.074.75c-3.136 0-5.199 1.934-5.199 5.371v3.05H.352v3.954h3.523v9.625h4.297v-9.625z"
    />
  </svg>
);
