import { FC, useContext, useState } from "react"
import PublicAvatarImage from "./PublicAvatarImage"
import PublicAvatarVideo from "./PublicAvatarVideo"
import ShareModal from "./ShareModal"
import useLoading from "../../hooks/useLoading"
import { AuthContext } from "../auth/context"
import { Button } from "../ui/button"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests } from "../../api/app.service"
import { getTextColor } from "../../utils/getTextColor"
import { isCoachInCommissionTracking } from "../../utils/commissionTracking"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { ProfileMessageForm } from "../auth/register-form/profile-message-form"
import { RegisterFormProvider } from "../auth/register-form/context"
import { SvgChat } from "../icons"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useAuthModal } from "../auth/use-auth-modal"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

const PublicProfileInformation: FC = () => {
  const { renderError } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { showRegister } = useAuthModal()
  const { user, setUser } = useContext(UserCtx)
  const isAuthed = useAuthenticatedUser(user)
  const navigate = useNavigate()
  const { coachPublicProfileData, isOwner } = useContext(
    CoachPublicProfileContext
  )
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
  const coachColor = coachPublicProfileData?.profile_information?.profileColor
  const coachTitle = coachPublicProfileData?.profile_information?.title
  const coachRegion = coachPublicProfileData?.profile_information?.region?.label
  const endPoint = coachPublicProfileData?.endpoint_slug
  const response: string = coachPublicProfileData.response_time
  const [profileMessageFormModal, setprofileMessageFormModal] =
    useState<boolean>(false)

  const { email, firstName, lastName, accountType, password, phone } =
    useContext(AuthContext)

  const handleClientSwitch = async (user: any) => {
    const userObject = {
      ...user,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = async (user: any) => {
    try {
      await ContactRequests.createContactConnection({
        sender_profile_id: coachPublicProfileData?.coach_profile_id,
        receiver_profile_id: user.activeProfileId,
        user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
        status: user?.isCoach
          ? "c2c"
          : isCoachInCommissionTracking(coachPublicProfileData?.endpoint_slug) 
          // if coach is connecting to a coach then change the status to c2c. 
          // idk if user.isCoach is a correct condition to work on. Recheck later.
          ? "zoee lead"
          : "connected",
      })

      user?.isCoach
        ? navigate(
            `/coach/inbox?chat=${coachPublicProfileData?.coach_profile_id}`
          )
        : navigate(
            `/member/inbox?chat=${coachPublicProfileData?.coach_profile_id}`
          )
    } catch (error: any) {
      console.log(error)
      renderError(error.response?.data?.message || "An error occurred")
    } finally {
      stopLoading()
    }
  }

  const responseTime = () => {
    if (response === "Out of Office") {
      return (
        <p className="text-graySlate text-center text-base">Out of Office</p>
      )
    } else if (response !== "Don't show response time" && response !== null) {
      return (
        <p className="text-graySlate text-base">
          Response time: Within {response}
        </p>
      )
    } else if (response === null) {
      return <></>
    } else {
      return <></>
    }
  }

  const authenticateAndMessage = async () => {
    if (isOwner) {
      renderError(
        "Can't take action on profile page when logged into this account"
      )
      return
    }

    if (isAuthed) {
      startLoading()
      await switchToMemberAndConnectAndRedirect(user)
    } else {
      showRegister({
        accountType: "member",
        onSuccess: async (user) => {
          await switchToMemberAndConnectAndRedirect(user)
        },
      })
    }
  }

  const switchToMemberAndConnectAndRedirect = async (user: any) => {
    await handleClientSwitch(user)
    await connectAndRedirect(user)
  }

  return (
    <RegisterFormProvider
      defaultEmail={email}
      defaultAccountType={accountType}
      onSuccess={() => true}
      defaultPhone={phone}
      defaultFirstName={firstName}
      defaultLastName={lastName}
      defaultPassword={password}
    >
      {/* DESKTOP */}
      <div className="relative hidden w-full items-center gap-[20px] px-12 md:flex">
        {/* PROFILE IMAGE OR VIDEO */}
        <div className="min-w-[185px] max-w-[185px]">
          {coachPublicProfileData?.has_profile_video ? (
            <div className="h-[258px] w-full">
              <PublicAvatarVideo />
            </div>
          ) : (
            <div className="h-[185px] w-full md:-mt-44">
              <PublicAvatarImage
                coachPublicProfileData={coachPublicProfileData}
              />
            </div>
          )}
        </div>

        {/* INFORMATION */}

        <div className="hidden w-full flex-col items-start md:flex lg:-mt-4 lg:ml-8 ">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col gap-[1px]">
              <h3 className="text-[28px] font-bold">
                {`${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`}
              </h3>
              <p className="mb-[17px] text-[16px]  md:max-w-[90%]">
                {coachTitle || "Coach"}
              </p>
            </div>
            <div className="-mb-4 flex flex-col gap-2">
              <Button
                variant="ghost"
                style={{
                  backgroundColor: coachColor,
                  color: getTextColor(coachColor),
                }}
                onClick={() => {
                  isAuthed
                    ? authenticateAndMessage()
                    : setprofileMessageFormModal(true)
                }}
              >
                <SvgChat />
                Send Message
              </Button>
              {/* Show response time */}
              <>{responseTime()}</>
            </div>
            {profileMessageFormModal && (
              <ProfileMessageForm
                showConfirm={profileMessageFormModal}
                setShowConfirm={setprofileMessageFormModal}
                coachName={`${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`}
                connectAndRedirect={connectAndRedirect}
              />
            )}
          </div>
          <div className="md:max-w-[80%]">
            <p className="mb-[17px] text-[16px] md:max-w-[80%]">
              {coachPublicProfileData?.profile_information?.description}
            </p>
          </div>
          <div className="flex gap-[48px]">
            {coachPublicProfileData?.profile_information?.yearsExperience >
              0 && (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-graySlate text-base">Experience</p>
                <h5 className="text-[16px] font-bold">
                  {coachPublicProfileData?.profile_information?.yearsExperience}{" "}
                  Years
                </h5>
              </div>
            )}
            {coachRegion && (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-graySlate text-base">Location</p>
                <h5 className="text-[16px] font-bold">{coachRegion}</h5>
              </div>
            )}

            {coachPublicProfileData?.credentials?.credentials.length > 0 ? (
              <div className="flex flex-col items-start gap-[8px]">
                <p className="text-graySlate text-base">Credentials</p>
                <h5 className="text-[16px] font-bold">
                  <h5 className="text-[16px] font-bold">
                    {coachPublicProfileData.credentials.credentials[0].name}
                  </h5>
                </h5>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {shareModalOpen && (
        <ShareModal
          endpoint_slug={endPoint}
          shareModalOpen={shareModalOpen}
          setShareModalOpen={setShareModalOpen}
        />
      )}
      {/* MOBILE */}
      <div className="relative flex w-full flex-col items-start gap-[20px] md:hidden">
        {/* PROFILE IMAGE OR VIDEO */}
        <div className="-mt-8 mb-28 flex w-full items-center justify-start gap-[16px]">
          <div className="h-[130px] w-[130px]">
            {coachPublicProfileData?.has_profile_video ? (
              <div className="h-[258px] min-w-full max-w-full">
                <PublicAvatarVideo />
              </div>
            ) : (
              <div className="h-[130px] w-[130px]">
                <PublicAvatarImage
                  coachPublicProfileData={coachPublicProfileData}
                />
              </div>
            )}
          </div>
          <div className="ml-auto flex flex-col">
            <Button
              variant="ghost"
              style={{
                backgroundColor: coachColor,
                color: getTextColor(coachColor),
              }}
              onClick={authenticateAndMessage}
            >
              <SvgChat />
              Message
            </Button>
            <>{responseTime()}</>
          </div>
        </div>

        <div className="flex flex-col items-start justify-start">
          <h3 className="text-[22px] font-bold">{`${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`}</h3>

          <p className="text-[16px]">
            {coachPublicProfileData?.profile_information?.title || "Coach"}
          </p>
        </div>
        {/* INFORMATION */}
        <div className="flex flex-col items-start">
          <p className="mb-[17px] text-[16px]">
            {coachPublicProfileData?.profile_information?.description}
          </p>
        </div>
      </div>
    </RegisterFormProvider>
  )
}

export default PublicProfileInformation
