import React, { useRef } from "react"
import BlogCard from "./BlogCard"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ProfileRequests } from "../../api/app.service"
import { SvgChevronLeft, SvgChevronRight } from "../icons/"

interface Props {
  coachColor?: any
}

const ListBlogCards: React.FC<Props> = ({ coachColor }) => {
  const { coachPublicProfileData } = React.useContext(CoachPublicProfileContext)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [posts, setPosts] = React.useState<any[]>([])
  const containerRef = useRef<HTMLDivElement | null>(null)

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -320,
        behavior: "smooth",
      })
    }
  }

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 320,
        behavior: "smooth",
      })
    }
  }

  const getCoachBlogPosts = async () => {
    startLoading()
    await ProfileRequests.getCoachBlogPosts({
      coach_profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
    })
      .then((data) => {
        const approvedPosts = data?.blog_posts?.filter(
          (post: any) => post.status === "approved" && post.published
        )
        setPosts(approvedPosts)
      })
      .catch((ex: any) => {
        console.error(ex)
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
      })
  }

  React.useEffect(() => {
    getCoachBlogPosts()
  }, [])

  return (
    <>
      <div className="flex flex-row w-full">
        <div className="hidden sm:flex w-[5%]">
          <div
            className="flex h-full items-center justify-center cursor-pointer"
            onClick={scrollLeft}
          >
            <Button size="sm" variant="ghost">
              <SvgChevronLeft />
            </Button>
          </div>
        </div>
        <div className="w-full sm:w-[90%]">
          <div className="relative w-full overflow-hidden">
            <div
              className="scroll-container w-full flex items-center gap-[21px] py-6 px-6"
              ref={containerRef}
            >
              {posts?.length === 0 ? (
                <div className="col-span-full">No blog posts</div>
              ) : (
                <>
                  {posts?.map((post) => (
                    <div key={post.id} className="h-[300px] w-[300px]">
                      <BlogCard
                        post={post}
                        endpointSlug={coachPublicProfileData.endpoint_slug}
                        coachColor={coachColor}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="hidden sm:flex w-[5%]">
          <div
            className="flex h-full items-center justify-center cursor-pointer"
            onClick={scrollRight}
          >
            <Button size="sm" variant="ghost">
              <SvgChevronRight />
            </Button>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex mb-6">
        <div
          className="flex h-full w-1/2 items-center justify-center cursor-pointer"
          onClick={scrollLeft}
        >
          <Button size="sm" variant="ghost">
            <SvgChevronLeft />
          </Button>
        </div>
        <div
          className="flex h-full w-1/2 items-center justify-center cursor-pointer"
          onClick={scrollRight}
        >
          <Button size="sm" variant="ghost">
            <SvgChevronRight />
          </Button>
        </div>
      </div>
    </>
  )
}

export default ListBlogCards
