const Element = ({ attributes, children, element }: any) => {
    // const style = { textAlign: element.align };
    // add style tags to return html tags if needed later
    switch (element.type) {
        // case "block-quote":
        //     return <blockquote {...attributes}>{children}</blockquote>;
        case "bulleted-list":
            return (
                <ul className="list-inside list-disc" {...attributes}>
                    {children}
                </ul>
            );
        case "heading-one":
            return (
                <h1 className="font-bold text-[24px] my-[4px]" {...attributes}>
                    {children}
                </h1>
            );
        case "heading-two":
            return (
                <h2 className="font-bold text-[18px] my-[4px]" {...attributes}>
                    {children}
                </h2>
            );
        case "list-item":
            return (
                <li className="list-item" {...attributes}>
                    {children}
                </li>
            );
        case "numbered-list":
            return (
                <ol className="list-inside list-decimal" {...attributes}>
                    {children}
                </ol>
            );
        // case "indented":
        //     return (
        //         <div className="first-line:ml-[16px]" {...attributes}>
        //             {children}
        //         </div>
        //     );
        default:
            return <p {...attributes}>{children}</p>;
    }
};

export default Element