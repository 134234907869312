import type { SVGProps } from "react";
export const SvgLightningCircled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      width="32"
      height="32"
      rx="16"
      fill="#E8C7C7"
    />
    <path
      d="M19.7894 17.4965C19.7469 17.4965 15.1006 22.774 15.0519 22.774C14.7118 23.3018 13.7764 22.8202 13.9829 22.2001L15.8536 16.3356L13.825 15.5968C13.5092 15.445 13.0657 15.3725 13.0111 14.9371C12.9701 14.7076 13.0441 14.4717 13.2054 14.317L18.0645 9.25741V9.2573C18.1979 9.07 18.4133 8.97413 18.6295 9.00606C18.8455 9.03786 19.0296 9.19248 19.1123 9.4117C19.1949 9.63093 19.1637 9.88139 19.0302 10.0688L17.3903 15.5443L19.5768 16.3689V16.3688C19.7147 16.4164 19.8325 16.516 19.9087 16.6495C19.985 16.783 20.0149 16.9417 19.993 17.0971C19.9712 17.2524 19.899 17.3942 19.7895 17.4968L19.7894 17.4965Z"
      fill="black"
    />
  </svg>
);
