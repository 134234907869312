import React from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import useLoading from "../../../hooks/useLoading";
import { Button } from "../../ui/button";

interface Props {
  setNewCardField: any;
  callback: any;
  getPaymentMethods?: any;
  stripeRedirectParams?: string | null;
}

export default function NewCardForm({ setNewCardField, callback, getPaymentMethods, stripeRedirectParams }: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const { startLoading, stopLoading } = useLoading();
  const [returnUrl, setReturnUrl] = React.useState<string>("");

  const cancelNewPaymentMethod = () => {
    setNewCardField(false);
    callback(false);
  };

  const handleSubmitNewCard = async (e: React.FormEvent<HTMLFormElement>) => {
    startLoading();
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({ elements, confirmParams: { return_url: returnUrl } });

    stopLoading();
    if (getPaymentMethods) {
      getPaymentMethods();
    }

    if (result.error) {
      console.log(result.error.message);
    }
  };

  React.useEffect(() => {
    setReturnUrl(`${process.env.REACT_APP_BASE_URL}${window.location.pathname}${stripeRedirectParams ? stripeRedirectParams : ""}`);
  }, []);

  return (
    <form className="w-full" onSubmit={handleSubmitNewCard}>
      <div className="w-full py-[20px] px-[24px] border rounded-xl mb-[24px]">
        <PaymentElement />
      </div>
      <div className="flex items-center justify-between w-full">
        <Button variant="secondary" onClick={() => cancelNewPaymentMethod()}>
          Cancel
        </Button>
        <Button type="submit">Save New Card</Button>
      </div>
    </form>
  );
};
