import type { SVGProps } from "react";
export const SvgEditorBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.5 15.5H10v-3h3.5a1.5 1.5 0 1 1 0 3m-3.5-9h3a1.5 1.5 0 0 1 0 3h-3zm5.6 4.29c.97-.68 1.65-1.79 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.1 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42"
    />
  </svg>
);
