import { useEffect, useContext, useState } from "react";
import { CommonFunctionCtx } from "../context/commonFunctionContext";
import { UserCtx } from "../context/userContext";
import AuthModal from "./authentication/AuthModal";
import { useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useAuthenticatedUser } from "../hooks/useAuthenticatedUser";

const DirectLinkRouter = () => {
    const { user } = useContext(UserCtx);
    const authenticatedUser = useAuthenticatedUser(user)
    const { showAuthModal, setShowAuthModal } = useContext(CommonFunctionCtx);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    const [isFirstRenderComplete, setIsFirstRenderComplete] = useState<boolean>(false);

    useEffect(() => {
        if (!authenticatedUser && !showAuthModal && isFirstRenderComplete) {
            navigate(`/home`);
        }
    }, [showAuthModal]);

    useEffect(() => {
        if (!authenticatedUser) {
            setShowAuthModal(true);
        } else {
            user?.isCoach ? 
            navigate('/coach')
            :
            navigate('/member')
        }
        setIsFirstRenderComplete(true);
    }, []);

    return (
        <div className="">
            <AnimatePresence>
                {showAuthModal && (
                    <div className="fixed top-[20%] left-0 right-0 mx-auto">
                        <AuthModal
                            successRedirectPathname={state.pathname || null}
                            successRedirectSearchParams={state.searchParams || null}
                            preRedirectActions={state.actions || null}
                        />
                    </div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default DirectLinkRouter;
