import type { SVGProps } from "react";
export const SvgMultiUserCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4119 15.1726C12.2523 14.9332 12.0927 14.7736 12.0128 14.5341C11.6138 13.8159 11.4542 13.0178 11.4542 12.2197C11.4542 11.0225 11.933 9.90521 12.6513 9.02731C12.8109 8.86769 12.8907 8.70807 13.0504 8.62826C13.0504 8.54845 13.4494 8.30902 13.6888 8.14941C15.1254 7.2715 16.0033 5.67532 15.8437 3.99933C15.7639 3.36085 15.6043 2.80219 15.3648 2.40314C14.7264 1.206 13.609 0.328101 12.2523 0.0886732C9.37914 -0.469991 6.82525 1.68486 6.82525 4.47818C6.82525 5.99456 7.54353 7.2715 8.66086 8.0696C6.66563 8.78788 5.06944 10.3043 4.19154 12.2995C3.87231 13.0976 3.95211 13.9755 4.43097 14.6938C5.06944 15.3322 5.86754 15.8111 6.82525 15.8111H13.0504C12.8109 15.5717 12.5715 15.412 12.4119 15.1726Z"
      fill="#4750F5"
    />
    <path
      d="M19.7544 10.6235C19.5948 10.2244 19.2755 9.82538 18.9563 9.50615C18.3178 8.86767 17.3601 8.46863 16.4024 8.46863C16.0832 8.46863 15.6841 8.54844 15.3649 8.62825C15.1255 8.70806 14.8062 8.78786 14.5668 8.94748C14.3274 9.02729 14.1677 9.18691 13.9283 9.34653C13.1302 9.985 12.6514 11.0225 12.6514 12.1399C12.6514 13.0178 12.9706 13.8158 13.5293 14.4543C13.7687 14.6937 14.0081 14.9332 14.2476 15.0928C14.8062 15.4918 15.4447 15.7313 16.0832 15.7313C16.163 15.7313 16.2428 15.7313 16.3226 15.7313C18.3178 15.7313 19.9938 14.0553 19.9938 12.06C20.0736 11.5812 19.914 11.1023 19.7544 10.6235Z"
      fill="black"
    />
    <path
      d="M8.41189 15.1726C8.25227 14.9332 8.09266 14.7736 8.01285 14.5341C7.6138 13.8159 7.45418 13.0178 7.45418 12.2197C7.45418 11.0225 7.93304 9.90521 8.65132 9.02731C8.81094 8.86769 8.89075 8.70807 9.05037 8.62826C9.05037 8.54845 9.44941 8.30902 9.68884 8.14941C11.1254 7.2715 12.0033 5.67532 11.8437 3.99933C11.7639 3.36085 11.6043 2.80219 11.3648 2.40314C10.7264 1.206 9.60903 0.328101 8.25227 0.0886732C5.37914 -0.469991 2.82525 1.68486 2.82525 4.47818C2.82525 5.99456 3.54353 7.2715 4.66086 8.0696C2.66563 8.78788 1.06944 10.3043 0.191542 12.2995C-0.127695 13.0976 -0.0478855 13.9755 0.43097 14.6938C1.06944 15.3322 1.86754 15.8111 2.82525 15.8111H9.05037C8.81094 15.5717 8.57151 15.412 8.41189 15.1726Z"
      fill="#4750F5"
    />
    <path
      d="M19.7544 10.6235C19.5948 10.2244 19.2755 9.82538 18.9563 9.50615C18.3178 8.86767 17.3601 8.46863 16.4024 8.46863C16.0832 8.46863 15.6841 8.54844 15.3649 8.62825C15.1255 8.70806 14.8062 8.78786 14.5668 8.94748C14.3274 9.02729 14.1677 9.18691 13.9283 9.34653C13.1302 9.985 12.6514 11.0225 12.6514 12.1399C12.6514 13.0178 12.9706 13.8158 13.5293 14.4543C13.7687 14.6937 14.0081 14.9332 14.2476 15.0928C14.8062 15.4918 15.4447 15.7313 16.0832 15.7313C16.163 15.7313 16.2428 15.7313 16.3226 15.7313C18.3178 15.7313 19.9938 14.0553 19.9938 12.06C20.0736 11.5812 19.914 11.1023 19.7544 10.6235Z"
      fill="black"
    />
    <path
      d="M11.8976 15.3111H6.82525C6.06295 15.3111 5.39649 14.94 4.82073 14.376C4.46071 13.8088 4.40236 13.1254 4.65275 12.4928C5.4784 10.622 6.9716 9.20914 8.83022 8.54004L9.72749 8.21702L8.95148 7.66273C7.96072 6.95505 7.32525 5.82724 7.32525 4.47818C7.32525 2.00197 9.58973 0.08032 12.1568 0.579481L12.1568 0.579555L12.1654 0.581065C13.3574 0.791417 14.3508 1.56438 14.9237 2.63844L14.9296 2.64957L14.9361 2.66039C15.125 2.97529 15.271 3.45487 15.3466 4.05395C15.4833 5.52014 14.7144 6.93669 13.4281 7.72276L13.4197 7.72791L13.4115 7.73338C13.3771 7.75632 13.3308 7.78634 13.2796 7.81956C13.1872 7.8795 13.0787 7.94983 12.9952 8.00766C12.9266 8.05518 12.8499 8.11081 12.7864 8.16663C12.7593 8.19043 12.7232 8.22415 12.6885 8.2656C12.592 8.33614 12.5216 8.41362 12.4712 8.47246C12.4394 8.50958 12.416 8.53818 12.3959 8.56262C12.3615 8.60461 12.3372 8.63431 12.2978 8.67375L12.2801 8.69139L12.2643 8.71069C11.486 9.66197 10.9542 10.8881 10.9542 12.2197C10.9542 13.07 11.1217 13.9417 11.5551 14.7394C11.6157 14.9022 11.6975 15.0325 11.7728 15.1401C11.82 15.2075 11.8526 15.251 11.8821 15.2904C11.8874 15.2974 11.8925 15.3042 11.8976 15.3111Z"
      fill="#4750F5"
      stroke="white"
    />
    <path
      d="M19.9246 8.97668C19.7612 8.81818 19.4345 8.73893 19.1894 8.89743C19.1077 8.89743 19.026 8.97668 18.9444 9.05594L18.6993 9.37294L17.474 10.7202L16.9838 11.1957L16.4937 11.6712L16.0853 12.1468L15.3501 11.3542C15.105 11.1165 14.6965 11.0372 14.3698 11.275C14.1247 11.5127 14.043 11.909 14.2881 12.226L15.5134 13.5733C15.6768 13.7318 15.8402 13.811 16.0036 13.811C16.1669 13.811 16.412 13.7318 16.4937 13.5733L16.9021 13.177L17.5557 12.4638L18.0458 11.909L19.4345 10.4032L19.9246 9.9277C20.2514 9.6107 20.1697 9.21444 19.9246 8.97668Z"
      fill="white"
    />
  </svg>
);
