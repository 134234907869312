import previewImage from "../../../assets/blog/readySetGoal.webp";
import { Post } from "../../../types/postTypes";

export const readySetGoal: Post = {
    metadata: {
        id: 4,
        title: "Ready, Set, Goal!",
        author: "written by Zoee",
        date: "September 12, 2022",
        urlSlug: "ready-set-goal",
        description:
            "Setting personal goals can feel like a daunting task, but it doesn't have to be! You also don't have to wait until New Year's Eve to start thinking about goals, either.",
        previewImg: previewImage,
        tags: ["Personal Development"],
    },
    content: [
        {
            type: "paragraph",
            text: "We're already more than halfway through 2022. If your eyebrows just shot off your forehead in an act of surprise, we're sorry. But it's true. July Christmas sales are long gone, and though we'd all like to bask in the glory of summer poolside glow forever, back-to-school chatter is well under way.",
        },
        {
            type: "paragraph",
            text: "Whether or not you participate in back-to-school culture, there is a kind of reset that happens after Labor Day weekend every year. It's something between a January 1 reprise and an excitement to finish the year strong. Regardless, we see it as an opportunity to set new goals or revisit old ones.",
        },
        {
            type: "paragraph",
            text: "Goal setting may have been something you were great at pre-pandemic; however, living in a constant state of “what's going to happen next?” anxiety has severely altered our ability to plan for the future and, more importantly, to set goals for ourselves.",
        },
        {
            type: "paragraph",
            text: "Though COVID still exists, we aren't living in a constant state of fear like we were two years ago. In an effort to move onward and upward from the “unprecedented times,” here's our strategy on personal goal setting to help you finish the second half of 2022 strong.",
        },
        {
            type: "header1",
            text: "Dream Big",
        },
        {
            type: "paragraph",
            text: "Visioning exercises are powerful tools. You can do this by finding a quiet, comfortable space, sitting down, closing your eyes, and envisioning the ideal version of yourself. (You can also find guided meditations that prompt you.) This allows you to see your life playing out like a movie and gives your brain something to refer back to when the struggle is real. When, in your mind, you can see yourself very clearly crossing the finish line at a race or accepting the promotion from your boss, it gets you excited and lets you know that this dream is possible.",
        },
        {
            type: "paragraph",
            text: "Athletes use coaches to help them “get into the zone,” and one way they do this is with visioning exercises—picturing themselves executing the perfect technique or winning. Michael Phelps, the former competitive swimmer who holds the all-time record for Olympic gold medals, famously used visioning exercises every morning to prepare for the Olympics. Rest assured, this method is gold medal-worthy.",
        },
        {
            type: "paragraph",
            text: "The most important thing here is to let yourself dream—and dream big. Maybe you don't need to think about the next 10 years, but at least let yourself dream about the next two to five. In that dream, do you live in a different house? Do you have the same job? A new title? A baby? A daily meditation practice? Have you written a book? Put solar panels on your home? Paid off your student loans? Run a half marathon? Learned to speak Spanish? Entered your pup into a dog show?",
        },
        {
            type: "paragraph",
            text: "By the way, these goals don't have to be what social media suggests. No, you don't have to start a side hustle. No, you don't have to move to a farm and grow all your own food. No, you don't have to start a family. This is the critical part about the dreaming process. You get to decide what your ideal life looks like without any limiting factors or restrictions. Let yourself get wild, and dig deep to find out what you truly want for yourself.",
        },
        {
            type: "paragraph",
            text: "Take a moment to write down this ideal version of yourself. You'll find that goals begin to pop off the page naturally. In your ideal world, do you take your family sailing every weekend in the summer? Great! Your goal then becomes “learn to sail.” Be sure to highlight or rewrite your goals from your ideal visioning so you know what you're working toward.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "bold",
                    text: "Pro tip:",
                },
                {
                    type: "normal",
                    text: "If even two years into the future seems tough, just think about the next six months. Do you want to be able to do 12 bicep curls with 15-pound weights by New Year's Day? Fabulous! That's a specific and achievable goal, even if you haven't picked up a hand weight since 1984. (We aren't judging.)",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Now that you have some goals down on the page, let's discuss how you'll achieve them.",
        },
        {
            type: "header1",
            text: "Work Backward",
        },
        {
            type: "paragraph",
            text: "Start with the end in mind. If your goal is to run a 10-mile race by the end of the year, then work backward to figure out how to get there. This is easy with running goals because we can simply set up checkpoints in training. If we need to run 10 miles by December 31, then we need to be running 8 miles by the end of November, 6 miles by the end of October, 4 miles by the end of September, and a 5K by Labor Day weekend. See what we did there? Worked backward from the end goal to set up checkpoints to hit along the way. This breaks the goal up into bite-sized pieces and reduces overwhelm.",
        },
        {
            type: "paragraph",
            text: "Let's take another example that's less numbers oriented. Say you want to establish a morning routine in the next six weeks. You get to decide what your mornings look like, but let's assume you want to meditate, walk your dog, and write in your journal.",
        },
        {
            type: "paragraph",
            text: "First, you'll need to figure out how much time all of this will take you. One hour? Two hours? How long do you want to spend meditating? How long is your walk? What do you want to journal about? If you need to wake up earlier to achieve this ideal morning routine, then you'll need to set your alarm earlier, which means going to bed earlier.",
        },
        {
            type: "paragraph",
            text: "Start with the ideal morning and work backward: If you want to arrive at your work desk by 8:30 a.m. and you need 90 minutes for your morning routine, then you'll need to start your day at 7 a.m. If you're used to waking up at 8 a.m., then maybe you slowly begin to wake up 15 minutes earlier each day until you reach the ideal wake-up time of 7 a.m.",
        },
        {
            type: "paragraph",
            text: "The point here is to implement change in small steps. It's impossible to reach a goal overnight, so let's create smaller checkpoints to measure our progress along the way.",
        },
        {
            type: "header1",
            text: "Celebrate the Wins",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "What is the fun of creating checkpoints (and goals) if you don't celebrate the crap out of them?! Even if your goal feels small,",
                },
                { type: "italic", text: "meeting" },
                { type: "normal", text: "a goal means you've" },
                { type: "italic", text: "changed." },
                {
                    type: "normal",
                    text: "And changing our behavior is difficult—no one can do it but us. So it's time to really celebrate and internalize the win. Here are some of our favorite celebration strategies when you hit a checkpoint or meet a goal:",
                },
            ],
        },
        {
            type: "ordered-list",
            items: [
                "Crack open a bottle of bubbly. Taking inspiration from Rachel Hollis when she became a New York Times bestselling author—write your goal on a piece of masking tape and tape it to the outside of a nice bottle of champagne or your favorite beverage. Stick it in the fridge so every time you see it, you're reminded of your goal and how amazing it will feel to pop the cork and toast to your achievement.",
                "Plan a meal with the people who supported you. If your running group has helped you achieve your goal, plan a dinner with them! If your husband has supported your dream of getting your MBA, book reservations at a nice restaurant and celebrate the sacrifices you both made to get there.",
                "Sit with your success. This one is a little less glamorous but really important to internalizing your achievement. Especially if you're a person who quickly moves from one goal to the next without pomp and circumstance, spend 20 minutes meditating on how good it feels to have achieved your goal. Journal about it, sit with the emotions, call a friend and let them know how proud of yourself you are. Pause before setting the next goal and just enjoy your success.",
                "Treat yourself. Maybe you've had your eye on a new office chair and once you get that promotion, you're going to buy it. Or maybe you want to be a fancy runner and, once you hit your running milestone, you get yourself a gift card to your favorite athletic store. Just like you purchase birthday gifts for loved ones, purchase yourself a goal gift. Set a date on the calendar and prepare to celebrate!",
            ],
        },
        {
            type: "paragraph",
            text: "Setting goals can feel overwhelming, but remember you can start small. There's no need to feel outside pressure on what your goals should be—the only goals that matter are the goals you truly want for yourself.",
        },
        {
            type: "paragraph",
            text: "Grab a floor pillow, get comfortable, and begin your vision casting. You're capable of many incredible things before 2022 is over, so set some checkpoints and let's see what you can do!",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "To begin your Zoee journey, join the waitlist and be first in line for our all-inclusive virtual office."
                },
            ]
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Written by Zoee."
                },
            ]
        },
    ],
};
