import type { SVGProps } from "react";
export const SvgInvite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M22 8.784v6.432c0 3.358-1.95 5.284-5.349 5.284H7.349a.7.7 0 0 1-.604-.345.68.68 0 0 1 0-.689.7.7 0 0 1 .604-.344h9.302c2.66 0 3.954-1.278 3.954-3.906V8.784c0-2.628-1.293-3.906-3.954-3.906H7.35c-2.66 0-3.954 1.278-3.954 3.906a.69.69 0 0 1-.348.597.7.7 0 0 1-.698 0A.69.69 0 0 1 2 8.784C2 5.426 3.95 3.5 7.349 3.5h9.302C20.051 3.5 22 5.426 22 8.784m-10 4.02a3.52 3.52 0 0 0 2.179-.725l2.908-2.298a.687.687 0 0 0 .11-.969.7.7 0 0 0-.981-.107l-2.909 2.298a2.24 2.24 0 0 1-2.615 0L7.784 8.705a.7.7 0 0 0-.689-.103.685.685 0 0 0-.182 1.179l2.908 2.298a3.52 3.52 0 0 0 2.18.724m-3.023 3.33a.69.69 0 0 0-.205-.486.7.7 0 0 0-.493-.202H2.698a.7.7 0 0 0-.604.344.68.68 0 0 0 0 .69.7.7 0 0 0 .604.344h5.581a.7.7 0 0 0 .493-.202.69.69 0 0 0 .205-.487m-6.28-2.986h2.791c.25 0 .48-.13.605-.344a.68.68 0 0 0 0-.69.7.7 0 0 0-.605-.344h-2.79a.7.7 0 0 0-.604.345.68.68 0 0 0 0 .689.7.7 0 0 0 .604.344"
    />
  </svg>
);
