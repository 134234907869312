import React from 'react';
import { getUserColor } from '../../../utils/getUserColor';

interface Props {
    participant: any;
}

const AttendanceListItem: React.FC<Props> = ({participant}) => {
    return (
        <div className="w-full p-[14px] flex items-center justify-between">
            <div className="flex items-center gap-[16px]">
                <div className="w-[40px] h-[40px]">
                    {participant.has_avatar ? (
                        <img src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${participant?.user_id}`} alt="avatar" className='w-full h-full object-cover rounded-full' />
                    ) : (
                        <div 
                            className={`w-full h-full rounded-full justify-center items-center flex ${getUserColor(participant.user_color)}`}
                        >
                            {participant?.first_name?.charAt(0) || participant?.email?.charAt(0)?.toUpperCase()}
                        </div>
                    )}
                </div>
                <span className='font-bold text-[16px]'>{participant?.first_name}</span>
            </div>
        </div>
    );
}

export default AttendanceListItem;
