import type { SVGProps } from "react";
export const SvgUserCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.412 19.173c-.16-.24-.32-.4-.4-.639-.398-.718-.558-1.516-.558-2.314 0-1.197.479-2.315 1.197-3.193.16-.16.24-.319.4-.399 0-.08.398-.319.638-.479 1.436-.877 2.314-2.474 2.155-4.15-.08-.638-.24-1.197-.48-1.596-.638-1.197-1.755-2.075-3.112-2.314-2.873-.559-5.427 1.596-5.427 4.39 0 1.516.719 2.793 1.836 3.59-1.995.719-3.592 2.235-4.47 4.23-.319.799-.239 1.677.24 2.395.638.638 1.437 1.117 2.394 1.117h6.225c-.24-.24-.479-.399-.638-.638"
    />
    <path
      fill="currentColor"
      d="M19.925 12.977c-.163-.159-.49-.238-.735-.08-.082 0-.164.08-.245.159l-.245.317-1.226 1.347-.49.476-.49.475-.408.476-.735-.793a.76.76 0 0 0-.98-.079c-.246.238-.328.634-.082.951l1.225 1.347q.245.238.49.238c.163 0 .409-.08.49-.238l.409-.396.653-.713.49-.555 1.389-1.506.49-.475c.327-.317.245-.714 0-.951"
    />
  </svg>
);
