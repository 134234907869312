import type { SVGProps } from "react";
export const SvgRecurring = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.942 17.742c-.457 0-.857-.371-.828-.857 0-.229.114-.457.286-.628A5.604 5.604 0 0 0 19 8.37a5.64 5.64 0 0 0-4.257-1.943H9.314a.86.86 0 0 1-.857-.857c0-.486.372-.857.857-.857h5.429A7.316 7.316 0 0 1 22 12.028a7.21 7.21 0 0 1-2.515 5.486.78.78 0 0 1-.543.228M14.714 19.314H9.286A7.28 7.28 0 0 1 2 12.028c0-2.114.914-4.114 2.514-5.485a.88.88 0 0 1 1.2.028.88.88 0 0 1-.028 1.2s-.029.029-.057.029a5.575 5.575 0 0 0-.543 7.885c1.057 1.229 2.6 1.915 4.228 1.915h5.429a.86.86 0 0 1 .857.857c-.057.486-.429.857-.886.857"
    />
    <path
      fill="currentColor"
      d="M12 22.028a.82.82 0 0 1-.6-.257.88.88 0 0 1 0-1.2l2.114-2.114-2.114-2.115a.83.83 0 0 1 0-1.2.83.83 0 0 1 1.2 0l2.714 2.715a.88.88 0 0 1 0 1.2L12.6 21.77a.78.78 0 0 1-.6.257M12 9.143a.82.82 0 0 1-.6-.258L8.684 6.171a.88.88 0 0 1 0-1.2l2.714-2.714a.83.83 0 0 1 1.2 0 .83.83 0 0 1 0 1.2l-2.114 2.114 2.114 2.115a.88.88 0 0 1 0 1.2.82.82 0 0 1-.6.257"
    />
  </svg>
);
