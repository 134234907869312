import type { SVGProps } from "react";
export const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.465 20h10.419a1.116 1.116 0 0 0 0-2.233H8.464a1.116 1.116 0 0 0 0 2.233m-4.466-7.442h14.885a1.116 1.116 0 0 0 0-2.233H3.999a1.116 1.116 0 0 0 0 2.233M8.465 5.233h10.419a1.116 1.116 0 0 0 0-2.233H8.464a1.116 1.116 0 0 0 0 2.233"
    />
  </svg>
);
