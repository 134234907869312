import type { SVGProps } from "react";
export const SvgDraggable = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 20 7"
    {...props}
  >
    <path
      fill="currentColor"
      d="M2 4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 2 4m0-4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 2 0m4 4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 6 4m0-4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 6 0m4 4a1.334 1.334 0 1 1 0 2.667A1.334 1.334 0 0 1 10 4m0-4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 10 0m4 4a1.334 1.334 0 1 1 0 2.667A1.334 1.334 0 0 1 14 4m0-4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 14 0m4 4a1.334 1.334 0 1 1 0 2.667A1.334 1.334 0 0 1 18 4m0-4a1.333 1.333 0 1 1 0 2.667A1.333 1.333 0 0 1 18 0"
    />
  </svg>
);
