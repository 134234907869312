import type { SVGProps } from "react";
export const SvgDiscover = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#F9F9F9"
      d="M21.457 3H2.907A1.91 1.91 0 0 0 1 4.907v14.39c0 1.052.854 1.906 1.907 1.906h18.377c1.052 0 2.08-.854 2.08-1.907V4.907A1.907 1.907 0 0 0 21.457 3"
    />
    <path
      fill="#000"
      d="M21.376 10.664c.48-.1.742-.421.742-.922 0-1.132-1.203-.97-2.101-.97v3.259h.636v-1.304h.087l.882 1.304h.779zm-.537-.381h-.186v-.99h.194c.401 0 .62.167.62.481 0 .334-.219.509-.628.509M17.796 8.777h1.8v.556h-1.164v.723h1.124v.548h-1.124v.882h1.164v.548h-1.8zM15.067 8.777l.87 2.193.882-2.193h.695l-1.41 3.345h-.342l-1.39-3.345z"
    />
    <path
      fill="#FF6F00"
      d="M12.846 8.658a1.771 1.771 0 1 1 0 3.544 1.771 1.771 0 1 1 0-3.544"
    />
    <path
      fill="#000"
      d="M10.887 8.9v.755c-.798-.799-1.86-.187-1.86.755 0 .993 1.093 1.53 1.86.762v.755c-1.18.568-2.515-.226-2.515-1.517 0-1.24 1.315-2.106 2.515-1.51M7.025 11.534c.453 0 .89-.608-.13-.97-.596-.218-.803-.453-.803-.901 0-.922 1.216-1.248 1.974-.568l-.333.429c-.414-.461-.99-.247-.99.099 0 .175.108.274.49.41.722.261.937.496.937 1.016 0 1.172-1.542 1.486-2.249.45l.41-.394a.75.75 0 0 0 .694.429M5.675 8.777h-.636v3.257h.636z"
    />
    <path
      fill="#FF6F00"
      d="M23.364 19.296v-5.271c-1.542.963-10.473 5.53-19.243 7.178h17.187c1.044-.013 2.056-.862 2.056-1.907"
    />
    <path
      fill="#000"
      d="M2.993 8.777h-.93v3.257h.93c1.228 0 1.752-.89 1.752-1.625 0-.957-.715-1.632-1.752-1.632m.743 2.455c-.195.175-.46.254-.87.254h-.167V9.333h.167c.41 0 .663.067.87.262.226.199.354.5.354.814s-.128.616-.354.823"
    />
  </svg>
);
