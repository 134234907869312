import React from "react";
import { FilterType } from "../../../types/schedulingTypes";
import { UserCtx } from "../../../context/userContext";
import { SvgCheckbox, SvgCheckboxOff, SvgZoeeIcon } from "../../icons/";

type Props = {
    activeFilters: Array<FilterType>;
    setActiveFilters: any;
};

const CalendarSelectDropdown: React.FC<Props> = ({ activeFilters, setActiveFilters }) => {
    const { user } = React.useContext(UserCtx);


    return (
        <div className="w-[264px] p-[24px] rounded-[12px] bg-white box-shadow-2">
            <h6 className="text-base text-graySlate mb-[16px]">Show Me</h6>
            {/* TODO: Consider abstracting Dropdown entry in to component */}
            <div className="flex flex-col gap-[24px]">
                <div className="relative pl-[14px]">
                    <div className="absolute top-0 left-0 bg-blurple rounded-[100px] w-[3px] h-[92px]" />
                    <div className="flex items-center gap-[8px] mb-[8px]">
                        <SvgZoeeIcon className="w-[24px] h-[24px]" />
                        <p className="text-base">
                            {user.activeProfile === "coach" ? "Coach Account" : "Member Account"}
                        </p>
                    </div>
                    <div>
                        <p className="text-base text-graySlate">My meetings created from my 
                            {user.activeProfile === "coach" ? " coach account with Zoee clients" : " member account with Zoee coaches"}
                        </p>
                    </div>
                    <div className="absolute top-0 right-0">
                        {activeFilters.includes("zoee-active-profile") ? (
                            <div
                                onClick={() => {
                                    setActiveFilters(
                                        activeFilters.filter((e) => e !== "zoee-active-profile")
                                    );
                                }}
                                className="cursor-pointer"
                            >
                                 <SvgCheckbox />
                            </div>
                        ) : (
                            <div
                                className="cursor-pointer"
                                onClick={() =>
                                    setActiveFilters([...activeFilters, "zoee-active-profile"])
                                }
                            >
                                 <SvgCheckboxOff />
                            </div>
                        )}
                    </div>
                </div>

                <div className="relative pl-[14px]">
                    <div className="absolute top-0 left-0 bg-mainBlack rounded-[100px] w-[3px] h-[92px]" />
                    <div className="flex items-center gap-[8px] mb-[8px]">
                        <SvgZoeeIcon className="w-[24px] h-[24px]" />
                        <p className="text-base">
                            {user.activeProfile === "coach" ? "Member Account" : "Coach Account"}
                        </p>
                    </div>
                    <div>
                        <p className="text-base text-graySlate">Meetings created from my
                            {user.activeProfile === "coach" ? " member account with Zoee coaches" : " coach account with Zoee clients"}
                        </p>
                    </div>
                    <div className="absolute top-0 right-0">
                        {activeFilters.includes("zoee-inactive-profile") ? (
                            <div
                                onClick={() => {
                                    setActiveFilters(
                                        activeFilters.filter((e) => e !== "zoee-inactive-profile")
                                    );
                                }}
                                className="cursor-pointer"
                            >
                                 <SvgCheckbox />
                            </div>
                        ) : (
                            <div
                                className="cursor-pointer"
                                onClick={() =>
                                    setActiveFilters([...activeFilters, "zoee-inactive-profile"])
                                }
                            >
                                 <SvgCheckboxOff />
                            </div>
                        )}
                    </div>
                </div>

                <div className="relative pl-[14px]">
                    <div className="absolute top-0 left-0 bg-grayMist rounded-[100px] w-[3px] h-[72px]" />
                    <div className="flex items-center gap-[8px] mb-[8px]">
                        <p className="text-base">Other</p>
                    </div>
                    <div>
                        <p className="text-base text-graySlate">Non-Zoee managed Calendars that are synced to Zoee</p>
                    </div>
                    <div className="absolute top-0 right-0">
                        {activeFilters.includes("other") ? (
                            <div
                                onClick={() => {
                                    setActiveFilters(activeFilters.filter((e) => e !== "other"));
                                }}
                                className="cursor-pointer"
                            >
                                 <SvgCheckbox />
                            </div>
                        ) : (
                            <div
                                className="cursor-pointer"
                                onClick={() => setActiveFilters([...activeFilters, "other"])}
                            >
                                 <SvgCheckboxOff />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarSelectDropdown;
