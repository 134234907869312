import type { SVGProps } from "react";
export const SvgScheduleSession = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path
      fill="currentColor"
      d="M18.16 34.122c-6.088 1.962-8.95.5-10.93-5.595l-2.182-6.742c-1.97-6.112-.511-8.974 5.58-10.939l2.195-.723c-.015.05-2.084 8.707-2.084 8.707-1.842 7.861.727 12.012 8.592 13.88l1.803.43zm17.112-17.086-1.673 7.139c-1.301 5.593-3.601 7.61-8.24 7.156-.783-.062-5.436-1.112-5.436-1.112-6.451-1.533-8.202-4.36-6.692-10.806l1.67-7.152c.118-.5.676-2.344.843-2.758.104-.257.327-.735.327-.735 1.221-2.527 3.02-3.713 5.876-3.713 1.093 0 2.342.174 3.77.512l2.848.665c3.304.772 5.32 1.901 6.342 3.552 1.022 1.647 1.138 3.951.365 7.252"
    />
    <path
      fill="#fff"
      d="M30.231 18.628a1.28 1.28 0 0 0 .315-2.52L22.28 14c-.976-.249-1.443.512-1.555.924a1.28 1.28 0 0 0 .924 1.557s8.478 2.147 8.582 2.147M19.965 23.1s5.172 1.303 5.276 1.303c.413-.074 1.065-.276 1.24-.963a1.28 1.28 0 0 0-.926-1.558l-4.96-1.263a1.28 1.28 0 1 0-.63 2.48"
    />
  </svg>
);
