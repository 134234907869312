import React from "react"
import { Link } from "react-router-dom"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { ProfileRequests } from "../../api/app.service"

interface Props {
  selectedBlogPostId: number | undefined
  blogTag: any
}

const PublicProfileBlogPost: React.FC<Props> = ({
  selectedBlogPostId,
  blogTag,
}: Props) => {
  const [firstPost, setFirstPost] = React.useState<any | null>(null)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = React.useContext(CoachPublicProfileContext)
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )

  const getCoachBlogPosts = async () => {
    await ProfileRequests.getCoachBlogPosts({
      coach_profile_id: parseInt(coachPublicProfileData.coach_profile_id),
    })
      .then((data) => {
        const allBlogs = data.blog_posts
        let firstPost = data.blog_posts[0]
        const indexOfBlogPostId = allBlogs.findIndex(
          (blog: any) => blog.id === selectedBlogPostId
        )
        if (indexOfBlogPostId !== -1) {
          firstPost = allBlogs[indexOfBlogPostId]
        }
        setFirstPost(firstPost)
      })
      .catch((ex) => {
        renderError("An error occured while fetching blog posts")
        console.log(ex)
      })
  }

  React.useEffect(() => {
    getCoachBlogPosts()
  }, [coachPublicProfileData])

  const readTime = (blogPost: any) => {
    const fullString = blogPost?.content
      .flatMap(({ type, children }: any) => {
        if (type !== "bulleted-list" && type !== "numbered-list") {
          return children.flatMap((item: any) => item.text)
        }

        return children.flatMap((listItem: any) =>
          listItem.children.map((thing: any) => thing.text)
        )
      })
      .join(" ")

    const wpm = 225
    const words = fullString?.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    return time
  }

  return (
    <Link
      to={`/find-a-coach/${
        coachPublicProfileData?.profile_information?.endpointSlug
      }/blog/${firstPost?.id || ""}`}
      className={`aspect-square w-full h-full rounded-[20px] p-2.5 bg-cover bg-center flex flex-col justify-between hover:scale-102 transition-transform shadow-lg`}
      style={{
        backgroundImage: `url(https://blog-images-${
          process.env.REACT_APP_USER_ENVIRONMENT
        }.s3.amazonaws.com/${firstPost?.id}?${Date.now()})`,
        backgroundColor: `${textColor}`,
      }}
    >
      {blogTag && (
        <div className="font-bold bg-white/80 rounded-[15px] py-1 px-3 w-fit">
          Blog
        </div>
      )}
      <div className="flex flex-col gap-2 bg-white/80 rounded-[15px] p-3 mt-auto">
        <h3 className="font-bold text-lg text-black line-clamp-2 text-balance">
          {firstPost?.title}
        </h3>
        <span className="text-black text-base">
          {readTime(firstPost)} min read
        </span>
      </div>
    </Link>
  )
}

export default PublicProfileBlogPost
