import React from "react"

interface SubscriptionInterface {
  showCreatePayment: boolean
  hideSecondaryNav: boolean
  // setHideSecondaryNav: any
  showSubscription: boolean
  setShowSubscription: any
  showReceipts: boolean
  setShowReceipts: any
  trialActive: boolean
  paymentMethods: any[]
  setPaymentMethods: any
  selectedPaymentMethod: any
  setSelectedPaymentMethod: any
  paymentMethodModal: any
  setPaymentMethodModal: any
  subscription: any
  setSubscription: any
  showSubscriptionPlans: boolean
  setShowSubscriptionPlans: any
  showSubscriptionComponents: any
  showSubscriptionCheckout: boolean
  setShowSubscriptionCheckout: any
  createSetupIntent: any
  options: any
  setOptions: any
  selectedSubscription: any
  setSelectedSubscription: any
  plans: any
  setPlans: any
  isSubscriptionUpdate: boolean
  setIsSubscriptionUpdate: any
  getSubscription: any
  delayedGetPaymentMethods: any
  assignSelectedPaymentMethod: any
  stripeRedirectParams: string | null
  setStripeRedirectParams: any
}

export const SubscriptionContext = React.createContext<SubscriptionInterface>({
  showCreatePayment: true,
  hideSecondaryNav: false,
  // setHideSecondaryNav: {},
  showSubscription: true,
  setShowSubscription: {},
  showReceipts: false,
  setShowReceipts: {},
  trialActive: false,
  paymentMethods: [],
  setPaymentMethods: {},
  selectedPaymentMethod: {},
  setSelectedPaymentMethod: {},
  paymentMethodModal: {},
  setPaymentMethodModal: {},
  subscription: null,
  setSubscription: {},
  showSubscriptionPlans: false,
  setShowSubscriptionPlans: {},
  showSubscriptionComponents: {},
  showSubscriptionCheckout: false,
  setShowSubscriptionCheckout: {},
  createSetupIntent: {},
  options: {},
  setOptions: {},
  selectedSubscription: {},
  setSelectedSubscription: {},
  plans: null,
  setPlans: {},
  isSubscriptionUpdate: false,
  setIsSubscriptionUpdate: {},
  getSubscription: {},
  delayedGetPaymentMethods: {},
  assignSelectedPaymentMethod: {},
  stripeRedirectParams: "",
  setStripeRedirectParams: {},
})
