import React from "react";
import PaymentsDashboard from "./PaymentsDashboard";
import { ClientPaymentsItem } from "../../types/clientPaymentTypes";

type Props = {
  connectAccountData?: any;
  clientPaymentsData: Array<ClientPaymentsItem> | null;
  setActiveDates: React.Dispatch<React.SetStateAction<Date[] | null>>;
};

export default function Reports(
  { connectAccountData, setActiveDates, clientPaymentsData }: Props,
) {
  return (
    <section>
      <PaymentsDashboard
        setActiveDates={setActiveDates}
        clientPaymentsData={clientPaymentsData}
        connectAccountData={connectAccountData}
      />
    </section>
  );
}
