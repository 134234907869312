import React, { FC, Fragment, useEffect, useState, useContext } from "react"
import CustomDayPicker from "../date-time/CustomDayPicker"
import Loader from "../ui/loader"
import moment from "moment"
import TimeOptions from "../scheduling/new-meeting/step-views/TimeOptions"
import useLoading from "../../hooks/useLoading"
import { add } from "date-fns"
import { AvailabilityRulesData } from "../../types/availabilityTypes"
import { Button } from "../ui/button"
import { CalendarRequests, ServiceRequests } from "../../api/app.service"
import { cn } from "../../utils/cn"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CombinedConsultationForm } from "../auth/register-form/combined-consultation-form"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { FreeConsultationConfirm } from "../services/FreeConsultationConfirm"
import { GetAvailability } from "../../models/app.interface"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { getTextColor } from "../../utils/getTextColor"
import { RegisterFormProvider } from "../auth/register-form/context"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useAuthModal } from "../auth/use-auth-modal"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  consultation?: any
  disabled?: boolean
}

const ProfileSchedulerPopup: FC<Props> = ({
  consultation,
  disabled = false,
}) => {
  const { user, setUser } = useContext(UserCtx)
  const navigate = useNavigate()
  const [activeDate, setActiveDate] = useState<Date>(new Date())
  const { coachPublicProfileData, isOwner } = useContext(
    CoachPublicProfileContext
  )
  const [clientServices, setClientServices] = useState<any[]>([])
  const [servicesLoading, setServicesLoading] = useState<boolean>(false)

  const [availablePeriods, setAvailablePeriods] = useState<Array<any>>([])
  const [freeConsultConfirmModal, setFreeConsultConfirmModal] =
    useState<boolean>(false)
  const [combinedConsultationForm, setCombinedConsultationForm] =
    useState<boolean>(false)

  const [userAvailabilities, setUserAvailabilities] = useState<
    Array<AvailabilityRulesData>
  >([])
  const [loadingAvailablePeriods, setLoadingAvailablePeriods] =
    useState<boolean>(false)
  const { renderError } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  const authenticatedUser = useAuthenticatedUser(user)
  const { showRegister } = useAuthModal()
  const coachColor = coachPublicProfileData?.profile_information?.profileColor
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )

  const durationDefault = 60
  const duration = consultation?.service_details?.session?.duration
    ? parseInt(consultation?.service_details?.session?.duration) / 60
    : 60

  useEffect(() => {
    if (coachPublicProfileData) {
      getProfileServiceList()
    }
  }, [coachPublicProfileData])

  const getProfileServiceList = () => {
    if (user && coachPublicProfileData) {
      setServicesLoading(true)
      const coachProfileId = parseInt(coachPublicProfileData.coach_profile_id)
      const memberProfileId = parseInt(user.activeProfileId)
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coachProfileId,
        member_profile_id: memberProfileId,
      })
        .then((data) => {
          if (data && data.service_list) {
            setClientServices(data.service_list)
          } else {
            setClientServices([])
          }
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
        .finally(() => {
          setServicesLoading(false)
        })
    } else {
      console.warn("User or Coach Public Profile Data is missing.")
    }
  }

  const getConsultationAvailabilityId = () => {
    let activeAvailabilityIdArr: Array<string> = []

    // find availability set to consultation
    const activeConsultationAvailability = userAvailabilities.find(
      (availability) => {
        return (
          availability.id ===
          consultation?.service_details?.availability_rule_id
        )
      }
    )
    // if no availability found for consultation then use default availability
    const defaultAvailability = userAvailabilities.find(
      (availability) => availability.is_default
    )

    // get first one if others are not there
    const firstAvailability = userAvailabilities[0]

    if (activeConsultationAvailability?.id) {
      activeAvailabilityIdArr.push(activeConsultationAvailability.id.toString())
    } else if (defaultAvailability?.id) {
      activeAvailabilityIdArr.push(defaultAvailability.id.toString())
    } else if (firstAvailability?.id) {
      activeAvailabilityIdArr.push(firstAvailability.id.toString())
    }

    return activeAvailabilityIdArr
  }

  const getAvailability = () => {
    setLoadingAvailablePeriods(true)
    const profileIds = [coachPublicProfileData?.coach_profile_id]
    // if activeDate is today, make sure that the time sent to GetAvailability is not before now, otherwise the request will fail
    let now = new Date()
    let start_date: any = activeDate
    if (activeDate < now) {
      start_date = moment.utc(now).toISOString()
    } else {
      start_date = moment.utc(activeDate).toISOString()
    }

    const m = moment.tz(
      activeDate,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    const end = m.clone().endOf("day").utc().toISOString()

    const getAvailabilityRequest: GetAvailability = {
      profile_ids: profileIds,
      creator_profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
      availability_rule_ids: getConsultationAvailabilityId(),
      meeting_duration: duration || durationDefault,
      start_date: start_date,
      end_date: end,
      coach_user_id: parseInt(coachPublicProfileData?.user_id),
      show_all_times: true,
    }

    CalendarRequests.getAvailability(getAvailabilityRequest)
      .then((data) => {
        setAvailablePeriods(data.available_periods)
        setLoadingAvailablePeriods(false)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
        setLoadingAvailablePeriods(false)
      })
  }

  const getAvailabilityRules = async () => {
    await CalendarRequests.getAvailabilityRules({
      user_id: parseInt(coachPublicProfileData?.user_id),
    })
      .then((data) => {
        setUserAvailabilities(data.availability_rules)
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
  }

  const handleClientSwitch = async (user: any) => {
    startLoading()
    // const activeProfile: "coach" | "member" | null = "member"
    // const activeProfileId = user.memberProfile.profile_id
    const userObject = {
      ...user,
      // activeProfile: activeProfile,
      // activeProfileId: activeProfileId,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = (user: any) => {
    stopLoading()
    if (consultation?.service_details?.billing?.amount === "0") {
      showFreeConsultConfirm()
    } else {
      navigate(
        `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`,
        { state: {} }
      )
    }
  }

  const goToEnroll = (user: any) => {
    localStorage.setItem(
      "meetingToSchedule",
      JSON.stringify({ startTime: startTime, endTime: endTime })
    )
    if (isOwner) {
      return renderError(
        "Can't take action on profile page when logged into this account"
      )
    }
    handleClientSwitch(user).then(() => {
      connectAndRedirect(user)
    })
  }

  const showFreeConsultConfirm = () => {
    setFreeConsultConfirmModal(true)
  }

  const showRegisterModal = () => {
    if (authenticatedUser) {
      showRegister({
        accountType: "member",
        onSuccess: (user) => {
          goToEnroll(user)
        },
      })
    } else {
      setCombinedConsultationForm(true)
    }
  }

  const checkEmailOrRedirect = () => {
    if (user && clientServices) {
      const isEnrolled = clientServices.some(
        (clientService: any) =>
          clientService.service_details.type === "consultation" &&
          clientService.service_status === "active" &&
          clientService.service_id === consultation.service_id
      )
      if (isEnrolled) {
        renderError("You have already enrolled in this consultation.")
        return
      }
    }
    if (authenticatedUser) {
      goToEnroll(user)
    } else {
      showRegisterModal()
    }
  }

  useEffect(() => {
    if (startTime) {
      const end = add(startTime, {
        minutes: duration || durationDefault,
      })
      setEndTime(end)
    }
  }, [startTime])

  useEffect(() => {
    if (userAvailabilities) {
      getAvailability()
    }
  }, [activeDate, userAvailabilities, duration])

  useEffect(() => {
    getAvailabilityRules()
  }, [])

  return (
    <>
      <div
        className={cn(
          "main flex h-full max-h-[601px] min-h-[601px] min-w-[300px] flex-col rounded-xl bg-white shadow md:max-w-[300px]",
          {
            "pointer-events-none opacity-25": disabled,
          }
        )}
      >
        <div
          className="-mb-[8px] flex h-[20px] items-center justify-center rounded-t-[16px] px-[24px] text-[16px] font-bold"
          style={{
            backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
            color: textColor,
          }}
        ></div>
        <div className="px-[24px] py-[16px] sm:py-[5px] mb-3">
          <div className="-mb-[3px] pt-[7px] font-bold leading-tight mb-6 text-[15px]">
            Schedule a Consultation
            <br />
            <span
              className="text-[20px] font-bold"
              style={{
                color: `${coachPublicProfileData?.profile_information?.profileColor}`,
              }}
            >
              {consultation?.service_details?.title}
            </span>
          </div>

          <div className="mb-[5px] flex w-full items-center justify-between font-bold">
            <CustomDayPicker
              setActiveDate={setActiveDate}
              disablePastDates={true}
              dayColor={coachColor}
            />
          </div>
          {/* TIME */}
          <div className="flex h-[100px] w-full flex-col gap-[8px] overflow-y-auto">
            {loadingAvailablePeriods ? (
              <div className="px-[8px] pt-[24px] text-center font-bold">
                <Loader />
                <h3 className="text-center text-[16px] font-bold">
                  Loading available meeting times.
                </h3>
              </div>
            ) : (
              <>
                {availablePeriods?.length > 0 ? (
                  <>
                    {availablePeriods?.map((availablePeriod, idx) => {
                      return (
                        <Fragment key={idx}>
                          <TimeOptions
                            availabilityStart={moment(
                              availablePeriod.start
                            ).toDate()}
                            availabilityEnd={moment(
                              availablePeriod.end
                            ).toDate()}
                            duration={duration || durationDefault}
                            setStartTime={setStartTime}
                            startTime={startTime}
                            dayColor={coachColor}
                          />
                          <div className="bg-hover text-graySlate flex items-center justify-center rounded-[10px] p-[12px] text-base font-bold last:hidden">
                            Unavailable
                          </div>
                        </Fragment>
                      )
                    })}
                  </>
                ) : (
                  <div className="mb-2 px-[4px] pt-[5px] text-center text-sm font-bold">
                    No available meeting times for you and your meeting
                    participant(s) on this day. Please select another day.
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Button
          onClick={checkEmailOrRedirect}
          disabled={!startTime || !endTime}
          className={cn(
            "-mt-[20px] mb-2 mt-[2px] h-[50px] w-[85%] sm:-mt-0 font-bold shadow-md mx-auto",
            {
              "bg-grayFlash text-black hover:bg-white": !startTime || !endTime,
              [`bg-${coachColor} text-${getTextColor(coachColor)}`]:
                startTime && endTime,
            }
          )}
          style={{
            backgroundColor: coachColor,
            color: getTextColor(coachColor),
          }}
        >
          Book
        </Button>
      </div>
      {authenticatedUser && freeConsultConfirmModal && startTime && endTime && (
        <FreeConsultationConfirm
          startTime={startTime}
          endTime={endTime}
          consultation={consultation}
          showConfirm={freeConsultConfirmModal}
          setShowConfirm={setFreeConsultConfirmModal}
        />
      )}
      {!authenticatedUser &&
        combinedConsultationForm &&
        startTime &&
        endTime && (
          <RegisterFormProvider
            defaultEmail=""
            defaultAccountType={null}
            defaultFirstName=""
            defaultLastName=""
            defaultPassword=""
            defaultPhone=""
            onSuccess={() => true}
          >
            <CombinedConsultationForm
              startTime={startTime}
              endTime={endTime}
              consultation={consultation}
              showConfirm={combinedConsultationForm}
              setShowConfirm={setCombinedConsultationForm}
            />
          </RegisterFormProvider>
        )}
    </>
  )
}

export default ProfileSchedulerPopup
