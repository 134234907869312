import previewImage from "../../../assets/blog/coachesCornerChristyStuber.webp";
import { Post } from "../../../types/postTypes";

export const coachesCornerChristyStuber: Post = {
    metadata: {
        id: 10,
        title: "Give Yourself the Kindness You Deserve",
        subtitle: "Coaches Corner: An Interview with Christy Stuber",
        imgTag: "Coach's Corner",
        author: "Zoee",
        date: "December 1, 2022",
        urlSlug: "coaches-corner-christy-stuber",
        description:
            "Zoee sat down with Christy Stuber, a life coach based out of Pittsburgh, PA, to learn about intentionality, self-talk, and how we can support one another.",
        previewImg: previewImage,
        tags: ["Coach’s Corner", "Mental Health", "Life Coaching"],
    },
    content: [
        {
            type: "paragraph",
            text: "We often hear thought leaders talk about being “intentional.” Whether we’re scrolling through social media feeds, listening to podcasts, or turning the pages of a personal development book, intentionality comes up a lot—and for good reason.",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "normal",
                    text: "Intentional decision-making means being proactive, thoughtful, understanding the consequences of taking action (or in many cases,",
                },
                {
                    type: "italic",
                    text: "not",
                },
                {
                    type: "normal",
                    text: "taking action), and making choices accordingly. This may seem like common sense; however, living in a reactive world pushes us to look backward, instead of forward.",
                },
            ],
        },
        {
            type: "paragraph",
            text: "Clients often seek coaches because something doesn’t feel right in their lives. A coach is a person that will help them gain self-awareness and clarity around what’s bothering them. They also want to get help to see their “blind spots.” When clients can figure out what’s bothering them or what their blind spot is, they can address it. And addressing those issues is the heart of coaching work.",
        },
        {
            type: "paragraph",
            text: "Christy Stuber is a professional and personal development coach mainly working with women ages 35 to 55, trying to make intentional changes in their life. Whether their kids are grown, they are going through a divorce, moving to a new state, or changing careers, these women are generally in transition. It makes sense, as rarely do people seek out coaching because everything is going perfectly in their lives. So, they want a guide to help them navigate change.",
        },
        {
            type: "paragraph",
            text: "One of the best ways Christy knows how to help clients become more intentional is by helping them to slow down. Christy lives on the East Coast of the U.S., where life moves especially fast. We’re jumping from zoom call to zoom call, school pick-up to a soccer tournament, and then back home to make dinner. It feels like there’s no slowing down ever. But as a coach, Christy knows where to help clients ease up on the gas.",
        },
        {
            type: "paragraph",
            text: "“We often aren’t aware of how we talk to ourselves,” Christy says. “We need to be treating ourselves like we would a loved one.”",
        },
        {
            type: "paragraph",
            text: "If you’re a coach, you’re probably smiling right now. It’s true—positive self-talk is critical to creating an intentional life. Phrases like, “Gosh, how could I be so stupid?” or “Well, there I go, messing up dinner again!” get stamped into our psyche and become part of the narrative we believe about ourselves. Would we talk to our sister that way? Our spouse? Our mother? Probably not. So then, why do we speak to ourselves that way?",
        },
        {
            type: "paragraph",
            text: "Christy elaborated, “If kindness and respect weren’t something present in your childhood home, then you need to give yourself the kindness you didn’t get from others.”",
        },
        {
            type: "paragraph",
            text: "This act of changing self-talk taught from childhood is called “re-parenting work.” It requires us to slow our motions to focus on our emotions and thought patterns. It allows us to recognize how we are talking to ourselves and then crack open an opportunity to change. Instead of a sarcastic, “Great, I burnt grilled cheese again. My kids are so lucky to have me as a mom.” We can say, “I didn’t mean to burn the sandwiches. I just have so much going on right now that I was distracted. Let me start again and be present. I know I can do this.”",
        },
        {
            type: "paragraph",
            text: "While it’s tempting (especially for women) to be folding laundry, making dinner, responding to work emails, and talking to their kids simultaneously, slowing down and doing one task at a time lets us be in the moment and engaged. Slowing down and being thoughtful about our actions opens up space—and space is where the magic happens.",
        },
        {
            type: "paragraph",
            text: "We asked Christy the number one piece of advice she gives clients. Her answer was simple: “Breathe.” Clients come to her stressed out, anxious, and at a loss for what to do next. In that moment, the best thing you can do is inhale and then exhale (and maybe repeat a few times).",
        },
        {
            type: "paragraph",
            text: "Christy even told us that she often gets photos from clients with a sticky note with “Breathe” written on it stuck to their computer or work area. It’s a reminder we all need: Breathe.",
        },
        {
            type: "paragraph",
            text: "Self-talk relates to self-compassion. When we speak kindly to ourselves, we exercise self-compassion, opening ourselves to compassionate thinking for ourselves and others. One of the most fascinating things Christy said to us was, “The amount of compassion I can give others is limited to the amount I can give to myself.” This means if we have no compassion for our own experiences, we can’t give compassion to others either.",
        },
        {
            type: "paragraph",
            text: "Some clients really hate this topic because it inherently sounds selfish. It’s the same as when people don’t like self-care. However, it’s important to understand ourselves and our blind spots to grow",
        },
        {
            type: "paragraph",
            text: "Here are some other questions we got the chance to ask Christy:",
        },
        {
            type: "header2",
            text: "What have you learned about people from being a coach?",
        },
        {
            type: "paragraph",
            text: "“People know what they need. My job is simply to highlight it for them. I’ve also learned that people are amazing and fascinating. Oftentimes, they’re just moving too fast, and the voice in their head doesn’t let them see or believe the amazing qualities in themselves.”",
        },
        {
            type: "header2",
            text: "In your experience, how do you think the last two years have impacted our health?",
        },
        {
            type: "paragraph",
            text: "“People are re-prioritizing things. They’ve figured out what’s most important and what’s most meaningful. They were forced to stop or slow down during the lockdown of COVID, and that has changed things for a lot of people. For instance, I know a lot of people are only going out with people they want to go out with and not feeling obligated to spend their time with people that they don't want to. People are thinking about resources—time, money, love—and are hoping for the better. I think the last two years have helped us become more well-rounded in all of our decision-making.”",
        },
        {
            type: "header2",
            text: "What can we do to move forward in a way that supports ourselves, as well as each other?",
        },
        {
            type: "paragraph",
            text: "“With self-compassion. The world would be better if we had more self-compassion and kindness—if we were more kind and curious about other people. Maybe there would be less of a divide since the more people that practice self-compassion means there’s more compassion circulating in our world. It truly ripples out.”",
        },
        {
            type: "header2",
            text: "Similar to the other coaches in this series, we also asked Christy some fun questions!",
        },
        {
            type: "header2",
            text: "Favorite part about being a coach?",
        },
        {
            type: "paragraph",
            text: "Meeting new people",
        },
        {
            type: "header2",
            text: "Favorite product or activity that you feel “saves your life” day to day?",
        },
        {
            type: "paragraph",
            text: "Insight Timer Meditation App",
        },
        {
            type: "header2",
            text: "If you could eat one food for the rest of your life, what would it be?",
        },
        {
            type: "paragraph",
            text: "Chocolate",
        },
        {
            type: "header2",
            text: "Favorite day of the week?",
        },
        {
            type: "paragraph",
            text: "Saturday because I do morning yoga with my favorite group",
        },
        {
            type: "header2",
            text: "Last song you listened to?",
        },
        {
            type: "paragraph",
            text: "“Self Love” by Zen",
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Christy Stuber is a personal and professional development coach based in Pittsburgh, Pennsylvania. Her work is centered around mindfulness, self-compassion, and kindness.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "Ready to simplify your back-office? Join Zoee today. Interested in finding a coach? Sign up for the member waitlist here.",
                },
            ],
        },
        {
            type: "rich-text",
            items: [
                {
                    type: "italic",
                    text: "This interview was conducted and written by Valerie Lane.",
                },
            ],
        },
    ],
};
