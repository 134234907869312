import Loader from "../../ui/loader"

const CalendarLoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center gap-[12px] mt-[160px]">
      <Loader />
      <h3 className="font-bold text-[16px] mb-[4px] text-center">
        Loading your calendar information...
      </h3>
    </div>
  )
}

export default CalendarLoadingSpinner
